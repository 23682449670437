import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Item,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Table,
  Label,
  Progress,
  Pagination,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"
import RadialRechart from '../chart/RadialRechart';
import PieRechart from '../chart/PieRechart';


class CampaignWidget extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        isUnique: false,
      };
    }
    
    setUnique() {
      this.setState({
        isUnique: !this.state.isUnique,
      })
    }

    render() {
      const {screenWidth, screenHeight, item}= this.props
      const {isUnique}= this.state

      const pieAccuDelivery = [
        { name: 'Target', value: item.totTarget },
        { name: 'Attempt', value: item.totAttempt }, 
        { name: 'Delivered', value: item.totDelivered },
        { name: 'Contacted', value: item.totEngaged },
        { name: 'Achieved', value: item.totAchieved },
      ];
      const pieUqDelivery = [
        { name: 'Target', value: item.totTarget },
        { name: 'Attempt', value: item.uqAttempt }, 
        { name: 'Delivered', value: item.uqDelivered },
        { name: 'Contacted', value: item.uqEngaged },
        { name: 'Achieved', value: item.uqAchieved },
      ];
      const radialDelivery = [
        {name: 'Achieved', value: item.uqAchieved, fill: '#ffc658'},
        {name: 'Contacted', value: item.uqEngaged, fill: '#ffc658'},
        {name: 'Delivered', value: item.uqDelivered, fill: '#d0ed57'},
        {name: 'Attempt', value: item.uqAttempt, fill: '#a4de6c'},
        {name: 'Target', value: item.totTarget, fill: '#82ca9d'},
      ];
      const legendDelivery = [
        {id: 'value', name: 'Progress'}, 
      ];
      const pieAccuResponse = [
        { name: 'Paid', value: item.totPaid },
        { name: 'Promised', value: item.totPromised },
        { name: 'Reschedule', value: item.totRescheduled },
        { name: 'Verified', value: item.totVerified },
        { name: 'Ordered', value: item.totOrdered },
        { name: 'Interested', value: item.totInterested },
        
        { name: 'Reject', value: item.totReject },
        { name: 'Ignored', value: item.totIgnored }, 
        { name: 'Invalid', value: item.totInvalid }, 
        { name: 'Mailbox', value: item.totMailbox },
      ];
      const pieUqResponse = [
        { name: 'Paid', value: item.uqPaid },
        { name: 'Promised', value: item.uqPromised },
        { name: 'Reschedule', value: item.uqRescheduled },
        { name: 'Verified', value: item.uqVerified },
        { name: 'Ordered', value: item.uqOrdered },
        { name: 'Interested', value: item.uqInterested },

        { name: 'Reject', value: item.uqReject },
        { name: 'Ignored', value: item.uqIgnored }, 
        { name: 'Invalid', value: item.uqInvalid }, 
        { name: 'Mailbox', value: item.uqMailbox },
      ];
      
      return(
        <Item style={{margin: '1em 0 2em 0'}}>
          {/* <Icon style={{marginright: '1.5em', }} size='huge' name={item.isEnabled=='1'? 'calendar check outline' : 'calendar times outline'} color={(item.isEnabled=='0' && 'grey') || (item.isExpired=='-1' && 'yellow') || (item.isExpired=='1' && 'red') || 'teal'} /> */}
          {/* <Item.Image size='small' src='item.avatar' /> */}
          <Item.Content style={{background: '#fff', border: '1px dashed rgba(71, 185, 255, .8)', padding: '1.5em', }}>
            {/* <Progress indicating size='tiny' percent={item.totTarget>0 ? (item.totDelivered / item.totTarget) * 100 : 0}/> */}
            <div style={{display: 'flex'}}>
              <div style={{flex: '4', paddingRight: '1.5em'}}>
                <Item.Header>
                  <Header as='h2' color={((item.isEnabled=='0' || item.isExpired!='0') && 'brown') || 'black'}>
                    {this.props.seq && <Label style={{margin: '.01em .5em 0 0', padding: '.5em', minWidth: '2.5em', textAlign: 'center'}}>{this.props.seq}</Label>}
                    <Header.Content>
                      <Header.Subheader>{item.campaignType}  {item.client ? ' / '+ item.client : ''} <b>{(item.isEnabled=='0' && <i> (Disabled)</i>) || (item.isExpired=='-1' && <i> (Upcoming)</i>) || (item.isExpired=='1' && <i> (Expired)</i>)}</b></Header.Subheader>
                      <span style={{textDecoration: (item.isEnabled=='0' || item.isExpired!='0') ? 'line-through' : 'none'}}>{item.name}</span> {item.dialingMode && <b>{' - ' + item.dialingMode +' Mode'}</b>}
                    </Header.Content>
                  </Header>
                </Item.Header>
              </div>
              <div style={{background: '#fafafa', padding: '1em'}}>
                <Header as='h4'>
                  <Label color='grey' style={{fontSize: '1.5em', padding: '.9em .5em', margin: '-.45em .5em -.5em -.5em'}}>{Math.round(item.totTarget>0 && item.totComplete>0 ? ((item.totComplete / item.totTarget)>1 ? 1 : (item.totComplete / item.totTarget)) * 100 : 0)} <sup>%</sup></Label>
                  <Header.Content>
                    {/* <Header.Subheader>{item.startTime} - {item.endTime}</Header.Subheader> */}
                    {item.startTime} - {item.endTime}<br />
                    {item.startDate!=null ? format(parse(item.startDate, 'yyyy-MM-dd', new Date()), 'dd / MMM') : ''} - <b><i>{item.endDate!=null ? format(parse(item.endDate, 'yyyy-MM-dd', new Date()), 'dd / MMM, yyyy') : ''}</i></b>
                  </Header.Content>
                </Header>
              </div>
            </div>
            <Divider />
            <Item.Extra style={{background: '#fafafa', padding: '.3em 0'}}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '2', padding: '.5em 1em .5em 0'}}>
                  <Header as='h4' style={{cursor: 'pointer', fontWeight: 'bold'}} color='red' onClick={this.setUnique.bind(this)} >
                    <Icon name={isUnique ? 'toggle on' : 'toggle off'} style={{transform: 'rotate(90deg)'}}/>
                    <Header.Content>
                      {isUnique ? 'Unique Progress' : 'Accumulative'}
                      <Header.Subheader style={{color: '#444'}}>Data presentation</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div style={{padding: '.7em 1em'}}>
                  <Header as='h4' style={{fontWeight: 'bold'}} color='red'>
                    <Header.Content>
                      {numeral(item.totTarget).format('0,0')}
                      <Header.Subheader><sup>TARGET</sup></Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div style={{padding: '.7em 1em'}}>
                  <Header as='h4' style={{fontWeight: 'bold'}} color='red'>
                    <Header.Content>
                      {isUnique && numeral(item.uqAttempt).format('0,0')}{!isUnique && numeral(item.totAttempt).format('0,0')}
                      <Header.Subheader><sup>ATTEMPTS</sup></Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div style={{padding: '.7em 1em'}}>
                  <Header as='h4' style={{fontWeight: 'bold'}} color='red'>
                    <Header.Content>
                      {isUnique && numeral(item.uqDelivered).format('0,0')}{!isUnique && numeral(item.totDelivered).format('0,0')}
                      <Header.Subheader><sup>DELIVERED</sup></Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div style={{padding: '.7em 1em'}}>
                  <Header as='h4' style={{fontWeight: 'bold'}} color='red'>
                    <Header.Content>
                      {isUnique && numeral(item.uqEngaged).format('0,0')}{!isUnique && numeral(item.totEngaged).format('0,0')}
                      <Header.Subheader><sup>CONTACTED</sup></Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div style={{padding: '.7em 2em'}}>
                  <Header as='h4' style={{fontWeight: 'bold'}} color='red'>
                    <Header.Content>
                      {isUnique && numeral(item.uqAchieved).format('0,0')}{!isUnique && numeral(item.totAchieved).format('0,0')}
                      <Header.Subheader><sup>ACHIEVED</sup></Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                {screenWidth>800 && <>
                  <div style={{padding: '10px 1em 0 0', marginRight: '-1.3em'}}>
                    <Label size='medium' color='orange' style={{borderRadius: '1em 0 0 1em', height: '27px'}}>COMPLETENESS ({item.totComplete<1000 ? item.totComplete : numeral(item.totComplete).format('0.0a')})</Label>
                  </div>
                  <div style={{flex: '1', padding: '.8em 0 0 0'}}>
                    <Progress color='blue' size='medium' percent={Math.round(item.totTarget>0 ? ((item.totComplete / item.totTarget)>1 ? 1 : (item.totComplete / item.totTarget)) * 100 : 0)} progress style={{margin: '0'}}/>
                  </div>
                </>}
              </div>
            </Item.Extra>
            <Divider style={{margin: '1em 0'}}/>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Header as='h5'>
                  <Label color='yellow' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>
                    {!isUnique ? item.totTarget ? Math.ceil(item.totAttempt/item.totTarget * 100) : 0 : item.totTarget ? Math.ceil(item.uqAttempt/item.totTarget * 100) : 0}%
                  </Label>
                  <Header.Content>
                    ATTEMPTED
                    <Header.Subheader>Attempt  of target.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5'>
                  <Label color='olive' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>
                    {!isUnique ? item.totTarget!=0 ? Math.ceil(item.totDelivered/item.totTarget * 100) : 0 : item.totTarget!=0 ? Math.ceil(item.uqDelivered/item.totTarget * 100) : 0}%
                    </Label>
                  <Header.Content>
                    DELIVERED
                    <Header.Subheader>Delivery of target.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5'>
                  <Label color='green' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>
                    {!isUnique ? item.totTarget!=0 ? Math.ceil(item.totEngaged/item.totTarget * 100) : 0 : item.totTarget!=0 ? Math.ceil(item.uqEngaged/item.totTarget * 100) : 0}%
                  </Label>
                  <Header.Content>
                    CONTACTED
                    <Header.Subheader>Engagement of target.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5'>
                  <Label color='blue' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>
                    {!isUnique ? item.totTarget!=0 ? Math.ceil(item.totAchieved/item.totTarget * 100) : 0 : item.totTarget!=0 ? Math.ceil(item.uqAchieved/item.totTarget * 100) : 0}%
                  </Label>
                  <Header.Content>
                    ACHIEVED
                    <Header.Subheader>Completed of target.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              {screenWidth>800 && <div style={{flex: '1', textAlign: 'center'}}>
                <PieRechart data={isUnique ? pieUqDelivery : pieAccuDelivery} width={300} height={230} fill='#555'/>
                {/* <RadialRechart data={radialDelivery} legend={legendDelivery} width={300} height={250}/> */}
                <Header as='h5' color='red' style={{margin: '0'}}>
                  <Header.Content>
                    Campaign Delivery
                  </Header.Content>
                </Header>
              </div>}
              <div style={{flex: '1', textAlign: 'center'}}>
                <PieRechart data={isUnique ? pieUqResponse : pieAccuResponse} width={300} height={230} mode='active' fill='#555'/>
                <Header as='h5' color='red' style={{margin: '0'}}>
                  <Header.Content>
                    Customer Response
                  </Header.Content>
                </Header>
              </div>
            </div>

            <Divider style={{margin: '1em 0 1em 0'}}/>
            <div style={{margin: '.5em -1.5em -1.3em -1.5em', padding: '.5em .3em', borderRadius: '.3em', background: '#fafafa'}}>
              <div style={{display: 'flex', margin: '.5em 1em'}}>
                <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                  <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.49em 1em', width: '9em'}} size='medium' color='olive'><b>DELIVERED</b></Label>
                </div>
                <div style={{flex: '1'}}>
                  <Progress color='olive' size='medium' progress='value' value={isUnique ? item.uqDelivered : item.totDelivered} total={item.totTarget + item.totAttempt} style={{margin: '0'}} />
                </div>
              </div>

              <Divider />
              <div style={{display: 'flex', margin: '.5em 1em'}}>
                <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                  <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.49em 1em', width: '9em'}} size='medium' color='green'><b>CONTACTED</b></Label>
                </div>
                <div style={{flex: '1'}}>
                  <Progress color='green' size='medium' progress='value' value={isUnique ? item.uqContacted : item.totContacted} total={item.totTarget + item.totAttempt} style={{margin: '0'}} />
                </div>
              </div>
              <div style={{display: 'flex', margin: '.5em 1em'}}>
                <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                  <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.49em 1em', width: '9em'}} size='medium' color='yellow'>RESCHEDULED</Label>
                </div>
                <div style={{flex: '1'}}>
                  <Progress color='yellow' size='medium' progress='value' value={isUnique ? item.uqRescheduled : item.totRescheduled} total={item.totTarget + item.totAttempt} style={{margin: '0'}} />
                </div>
              </div>

              {item.totPaid>0 && <>
                <Divider />
                <div style={{display: 'flex', margin: '.5em 1em'}}>
                  <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                    <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.49em 1em', width: '9em'}} size='medium' color='teal'>PAID</Label>
                  </div>
                  <div style={{flex: '1'}}>
                    <Progress color='teal' size='medium' progress='value' value={isUnique ? item.uqPaid:item.totPaid} total={item.totTarget + item.totAttempt} style={{margin: '0'}} />
                  </div>
                </div>
              </>}
              {item.totPromised>0 && <>
                <div style={{display: 'flex', margin: '.5em 1em'}}>
                  <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                    <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.49em 1em', width: '9em'}} size='medium' color='orange'>PROMISED</Label>
                  </div>
                  <div style={{flex: '1'}}>
                    <Progress color='orange' size='medium' progress='value' value={isUnique ? item.uqPromised : item.totPromised} total={item.totTarget + item.totAttempt} style={{margin: '0'}} />
                  </div>
                </div>
              </>}
              
              {item.totInterested>0 && <>
                <Divider />
                <div style={{display: 'flex', margin: '.5em 1em'}}>
                  <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                    <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.49em 1em', width: '9em'}} size='medium' color='yellow'>INTERESTED</Label>
                  </div>
                  <div style={{flex: '1'}}>
                    <Progress color='yellow' size='medium' progress='value' value={isUnique ? item.uqInterested : item.totInterested} total={item.totTarget + item.totAttempt} style={{margin: '0'}} />
                  </div>
                </div>
              </>}
              {item.totOrdered>0 && <>
                <div style={{display: 'flex', margin: '.5em 1em'}}>
                  <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                    <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.49em 1em', width: '9em'}} size='medium' color='green'>ORDERED</Label>
                  </div>
                  <div style={{flex: '1'}}>
                    <Progress color='green' size='medium' progress='value' value={isUnique ? item.uqOrdered : item.totOrdered} total={item.totTarget + item.totAttempt} style={{margin: '0'}} />
                  </div>
                </div>
              </>}
              
              {item.totVerified>0 && <>
                <Divider />
                <div style={{display: 'flex', margin: '.5em 1em'}}>
                  <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                    <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.49em 1em', width: '9em'}} size='medium' color='teal'>VERIFIED</Label>
                  </div>
                  <div style={{flex: '1'}}>
                    <Progress color='teal' size='medium' progress='value' value={isUnique ? item.uqVerified : item.totVerified} total={item.totTarget + item.totAttempt} style={{margin: '0'}} />
                  </div>
                </div>
              </>}
            </div>
          </Item.Content>
        </Item>
      )
    }
}

export default CampaignWidget;
