
import React,{Component} from 'react';
import {ResponsiveContainer, PieChart, Pie, Legend, Tooltip, Cell, Sector} from 'recharts'

const schemes= [
  ['#0476E9', '#039AE2', '#008631', '#00B40D', '#a78100', '#F59D00', '#FA5D00', '#F101A8', '#C6D029', '#EE8223'],
  ['#8D5Ac8', '#799Cc2', '#48a98A', '#149EC2', '#5577a6', '#118866', '#0085A8', '#3F527F', '#116688', '#3D799C'],
  ['#cfa698', '#b59278', '#c39762', '#ae7f47', '#9a6c35', '#6d5800', '#a78700', '#d2aa00', '#ecbf00', '#efd522'],
  ['#c27b20', '#6f7a29', '#77ad13', '#cc3a9f', '#6aa2d5', '#5427c1', '#8e28ca', '#e56ad8', '#2c3e9e', '#d53212'],
  ['#fd776c', '#cc6e6e', '#7b5a6d', '#6d7b5a', '#c8d794', '#6c7481', '#e28274', '#f4d2c8', '#fca4cb', '#b8c85e'],
  ['#69c6a5', '#41a0aa', '#468429', '#3ed7e5', '#2f78a6', '#72a45f', '#d7737b', '#924eca', '#ba74a4', '#73a697'],
  ['#AD5AD8', '#796CA2', '#d849AA', '#745EC2', '#853766', '#b18836', '#3085A8', '#cF529F', '#8126a8', '#aD491C'],
  ['#5DCAD8', '#795CF2', '#8859AA', '#149AC2', '#557566', '#118566', '#0045A8', '#3e527F', '#116088', '#3D494a'],

  //REPETITION
  ['#cfa698', '#b59278', '#c39762', '#ae7f47', '#9a6c35', '#6d5800', '#a78700', '#d2aa00', '#ecbf00', '#efd522'],
  ['#fd776c', '#cc6e6e', '#7b5a6d', '#6d7b5a', '#c8d794', '#6c7481', '#e28274', '#f4d2c8', '#fca4cb', '#b8c85e'],
  ['#5DCAD8', '#795CF2', '#8859AA', '#149AC2', '#557566', '#118566', '#0045A8', '#3e527F', '#116088', '#3D494a'],
]

const schemeBg= ['#666666', '#6a6a6a', '#707070', '#757575', '#7a7a7a', '#808080', '#858585', '#8a8a8a', '#909090', '#959595']

class PieRechart extends Component {
  constructor(props) {
    super(props);
  	this.state = {
      activeIndex: 0,
    };
    
    this.dummy = [
      { name: 'Group A', value: 400 }, 
      { name: 'Group B', value: 300 },
      { name: 'Group C', value: 300 }, 
      { name: 'Group D', value: 200 },
      { name: 'Group E', value: 278 }, 
      { name: 'Group F', value: 189 },
    ];

    this.colors= schemes[Math.floor((Math.random() * 10))]
  }

  onPieEnter(data, index) {
    if (this!=undefined) {
      this.setState({
        activeIndex: index,
      });
    }
  }
  
  renderCustomizedLabel(props) {
    // console.log('!! CUSTOM LABEL',  props)
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, activeIndex }= props
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x  = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy  + radius * Math.sin(-midAngle * RADIAN);
  
   return (
     <text x={x} y={y} fill="#fff" textAnchor={x > cx ? 'start' : 'end'} 	dominantBaseline="central">
       {`${(percent * 100).toFixed(0)}%`}
     </text>
   );
  };
 
  renderActiveShape(props) {
    // console.log(props)
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, activeIndex } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
  
    return (
      <g>
        <text x={cx} y={cy} dy={4} textAnchor="middle" fill={fill}>{payload.name}</text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={this.colors[this.state.activeIndex % this.colors.length]}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 4}
          outerRadius={outerRadius + 8}
          fill={this.colors[this.state.activeIndex % this.colors.length]}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
        <text x={ex + (cos >= 0 ? 1 : -1) * 1.5} y={ey} textAnchor={textAnchor} fill="#333">{`${value}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 1.5} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`${(percent * 100).toFixed(2)}%`}
        </text>
      </g>
    );
  };

	render () {
    var { data, legend, width, height, mode, fill } = this.props
    
    if (mode=='active') { 
      return (
        <div>
          <div style={{ width: width, height: height, margin: '0em auto' }}>
            <ResponsiveContainer>
              <PieChart width={width} height={height}>
                <Pie
                  activeIndex={this.state.activeIndex}
                  activeShape={this.renderActiveShape.bind(this)} 
                  data={data || this.dummy} 
                  nameKey="name" 
                  dataKey="value" 
                  cx={width/2 - 2} 
                  cy={height/2 - 5}
                  innerRadius={(width-210)/2}  
                  outerRadius={(width-150)/2}
                  onMouseEnter={this.onPieEnter.bind(this)}
                  fill= {fill || "#666"}>
                    {
                      (data || this.dummy).map((entry, index) => <Cell key={index} fill={schemeBg[index % schemeBg.length]}/>)
                    }
                  </Pie>
                <Tooltip/>
                {legend && <Legend iconSize={10} />}
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div style={{ width: width, height: height, margin: '0em auto' }}>
            <ResponsiveContainer>
              <PieChart width={width} height={height} 
              onMouseEnter={this.onPieEnter.bind(this)}>
                <Pie
                  data={(data || this.dummy)} 
                  nameKey="name" 
                  dataKey="value" 
                  cx={width/2 - 2} 
                  cy={height/2 - 5}
                  labelLine={true}
                  // label={this.renderCustomizedLabel}
                  label={true}
                  innerRadius={(width-210)/2}  
                  outerRadius={(width-150)/2} 
                  // onMouseEnter={this.onPieEnter.bind(this)}
                  // paddingAngle={2}
                  fill= {fill || "#666"}>
                  {
                    (data || this.dummy).map((entry, index) => <Cell key={index} fill={this.colors[index % this.colors.length]}/>)
                  }
                </Pie>
                <Tooltip/>
                {legend && <Legend iconSize={10} />}
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    }
  }
}

export default PieRechart;
