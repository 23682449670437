import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider  } from 'semantic-ui-react'

import MenuHorizontal from '../../libraries/common/MenuHorizontal';

const options= [
  {
    key: '1',
    icon: 'code',
    caption: 'Token',
  },
  {
    key: '2',
    icon: 'calendar check outline',
    caption: 'Campaign',
  },
  {
    key: '3',
    icon: 'user circle outline',
    caption: 'Contacts',
  },
  {
    key: '4',
    icon: 'spy',
    caption: 'Agent',
  },
  {
    key: '5',
    icon: 'code',
    caption: 'Widget',
  },
  {
    key: '6',
    icon: 'phone',
    caption: 'Comm',
  },
  {
    key: '7',
    icon: 'file alternate outline',
    caption: 'Report',
  },
  {
    key: '8',
    icon: 'plug',
    caption: 'Webhook',
  },
]

class APIServiceBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      };
    }

    setMode(mode) {
      this.setState({mode: mode})
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          {!this.props.mode && 
          <div style={{margin: '-.1em -.5em'}}>
            <MenuHorizontal onClick={this.setMode.bind(this)} options= {options} color='red'/>
          </div>}
          
        </Container>
      )
    }
}

export default APIServiceBody
