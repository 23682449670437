import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import CampaignBrowse from '../../libraries/stream/CampaignBrowse'
import CampaignForm from '../../libraries/stream/CampaignForm'
import CampaignSearch from '../../libraries/stream/CampaignSearch'


class CampaignBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        campaignId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, campaignId: id, clientId: clientId})
    }

    render() {
      const {mode, campaignId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              <CampaignSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
              <div>
                {mode==1 && <CampaignBrowse setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
                {mode==2 && <CampaignForm mode={mode} campaignId={campaignId} clientId={clientId} setMode={this.setMode.bind(this)} />}
                {mode==3 && <CampaignForm mode={mode} campaignId={0} clientId={0} setMode={this.setMode.bind(this)} />}
              </div>
            </div>
          </div>
        </Container>
      )
    }
}

export default CampaignBody
