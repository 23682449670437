import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed } from 'semantic-ui-react'


import { connect } from "react-redux"
import { statProduct } from "../../actions/halo/statisticAction"
import BarRechart from '../chart/BarRechart';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    statisticStore: state.statisticStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    statProduct:(objParam) => dispatch(statProduct(objParam)),
  }
}

class SubscriptionWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {};
  }

  componentDidMount() {
    this.props.statProduct() 
  }
  
  render() {
    const { screenWidth,  screenHeight}= this.props
    const { data, search, paging }= (this.props.statisticStore.statProduct!==undefined ? this.props.statisticStore.statProduct : {})

    const barSubscriptions= [], legends= [];
    data && data.subscriptions && Object.entries(data.subscriptions).forEach(([key, value]) => {
      barSubscriptions.push({name: key, customer: value.customer, nettRevenue: value.nettRevenue});
    })
    legends.push({id: 'customer', name: 'Cust'});
    legends.push({id: 'nettRevenue', name: 'Nett Rev'});
    // console.log('!!! BARCHART DATA', barSubscriptions, legends)
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <Header as='h5'>
          <Icon name='chart bar' style={{fontSize: '1em'}} />
          <Header.Content>
            Product / Service Subscription
            <Header.Subheader></Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em'}}>
        {data!=undefined &&
        <BarRechart data={barSubscriptions} legend={legends} mode='simple' height={450} yAxis={false} dashed={true} label={true}/>}
      </Segment>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionWidget)