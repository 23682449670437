import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Icon,
  Statistic,
  Button,
  Modal,
  Item,
  Label,
  Form,
} from 'semantic-ui-react'
import OnboardingForm from '../../libraries/whatsapp/OnboardingForm'


class OnboardingBody extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      open: true, 
    } 
  }

  handleOpen() {
    this.setState({ open: true })
  }

  handleClose() {
    if (this.props.openWindow!=undefined) {
      this.props.openWindow('', true)
    }
    this.setState({ open: false })
  }
  
  render() {
    const { step }= this.state
    const { dialog }= this.props
    const open= this.props.open!=undefined ? this.props.open : this.state.open
    
    if (dialog) {
      return (
        <Modal style={{background: 'none'}}
          open={open}
          // centered={false}
          // onClose={this.handleClose.bind(this)}
          size={this.props.size || 'small'}>
          {/* <Modal.Content style={{padding: '1em 0 1em 0', background: 'none', borderRadius: '0', zIndex: '9999', }}>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1', padding: '.5em 0 0 1em'}}></div>
              <div><Icon name='window close' color='yellow' style={{cursor: 'pointer', padding: '0', margin: '0', fontSize: '2em'}} onClick={this.handleClose.bind(this)}/></div>
            </div>
          </Modal.Content> */}
          <Modal.Content style={{padding: '3em'}}>
            <OnboardingForm closeModal= {this.handleClose.bind(this)} dialog/>
          </Modal.Content>
        </Modal>
      )
    } else {
      return (
        <div style={{background: 'none', margin: '1em', padding: '1em .5em .5em .5em', border: '1px dashed rgba(71, 185, 255, .8)'}}>
          <OnboardingForm />
        </div>
      )
    }
  }
}

export default OnboardingBody