import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider  } from 'semantic-ui-react'

import MenuHorizontal from '../../libraries/common/MenuHorizontal';

import PerformanceCampaign from '../../libraries/stream/PerformanceCampaign';
import PerformanceAgent from '../../libraries/stream/PerformanceAgent';

const options= [
  {
    key: '1',
    icon: 'calendar check outline',
    caption: 'Perf. Campaign',
  },
  {
    key: '2',
    icon: 'chart bar',
    caption: 'Perf. Agent',
  },
]

class PerformanceBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      };
    }

    setMode(mode) {
      this.setState({mode: mode})
    }

    render() {
      const {mode, userId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          {!this.props.mode && 
          <div style={{margin: '-.1em -.5em'}}>
            <MenuHorizontal onClick={this.setMode.bind(this)} options= {options} color='red'/>
          </div>}
          {(this.props.mode==1 || (!this.props.mode && mode==1)) && <PerformanceCampaign screenWidth={screenWidth} screenHeight={screenHeight} />}
          {(this.props.mode==2 || (!this.props.mode && mode==2)) && <PerformanceAgent screenWidth={screenWidth} screenHeight={screenHeight} />}
        </Container>
      )
    }
}

export default PerformanceBody
