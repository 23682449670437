import React from 'react'
import { ReactSVG } from 'react-svg'
import bg_home  from '../assets/stream-bg.svg'

import AuthenticateHeader from "./AuthenticateHeader"
import AuthenticateFooter from "./AuthenticateFooter"
import AuthenticateBody from "./AuthenticateBody"
// import AuthenticateBody from "./whatsapp/AuthenticateBody"

class Authenticate extends React.Component {
    constructor(props) {
      super(props)
      this.state= {
        screenHeight: 800,
        screenWidth: 1024,
      }
      this.updateDimension = this.updateDimension.bind(this)
    }
  
    componentDidMount() {
      const { match }= this.props
      
      this.updateDimension()
      window.addEventListener('resize', this.updateDimension)

      const { token }= match.params
      if (token) {
        localStorage.setItem('tokenAuth', token);
        this.props.history.push('/realm');
      }
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimension)
    }
    
    updateDimension() {
      this.setState({ ...this.state, screenWidth: window.innerWidth, screenHeight: window.innerHeight })
    }
    
    render() {
      const {screenHeight, screenWidth}= this.state

      return(<div>
        {/* <div style={{paddingTop: (screenHeight - 600>100 ? screenHeight/6 : 100), minHeight: screenHeight, backgroundImage: 'url('+ bg_home +')', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom center', backgroundColor: 'rgba(220, 186, 0, 1)', backgroundSize: '100% auto'}}> */}
        <div style={{paddingTop: (screenHeight - 600>100 ? screenHeight/6 : 100), minHeight: screenHeight, backgroundImage: 'url('+ bg_home +')', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom center', backgroundColor: 'rgba(220, 186, 0, 1)', backgroundSize: 'cover'}}>
          <AuthenticateBody screenHeight={screenHeight} screenWidth={screenWidth}/>
          {/* <AuthenticateFooter screenHeight={screenHeight} screenWidth={screenWidth}/> */}
        </div>
      </div>)
    }
} export default Authenticate
