import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetTicket(objParam) {
  return {
      type: 'TICKET_RESET',
      payload: objParam
  }
}

export function getTicket(objParam) {
  return {
      type: 'TICKET_GET',
      payload:
        axios.post(
          urlAPI + 'service/service/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusTicket(objParam) {
  return {
      type: 'TICKET_STATUS',
      payload:
        axios.post(
          urlAPI + 'service/service/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseTicket(objParam) {
  return {
      type: 'TICKET_BROWSE',
      payload:
        axios.post(
          urlAPI + 'service/service/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createTicket(objParam) {
  return {
      type: 'TICKET_CREATE',
      payload:
        axios.post(
          urlAPI + 'service/service/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateTicket(objParam) {
  return {
      type: 'TICKET_UPDATE',
      payload:
        axios.post(
          urlAPI + 'service/service/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeTicket(objParam) {
  return {
      type: 'TICKET_REMOVE',
      payload:
        axios.post(
          urlAPI + 'service/service/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function handlingTicket(objParam) {
  return {
      type: 'TICKET_HANDLING',
      payload:
        axios.post(
          urlAPI + 'service/service/handling',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
