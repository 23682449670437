import React, { Component } from 'react';
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react';

import DismissableMessage from '../common/DismissableMessage';
import IconToggle from '../common/IconToggle';
import {genderOptions, maritalOptions, occupationOptions, revenueOptions, expenseOptions, homestayOptions, vehicleOptions}  from '../common/StaticMasterData';

import { SubmissionError, Field, reduxForm, getFormValues, formValueSelector } from 'redux-form';
import {ImageUploadField, LabelInputField, InputField, CheckboxField, NumberField, DatePickerField, SelectField, TextAreaField } from '../validation/componentrsui';
import { required, confirmedPassword, phoneNumberID, number, minValue, email } from '../validation/validation';

import { connect } from "react-redux";
import { resetTarget, getTarget,updateTarget, createTarget } from "../../actions/stream/targetAction";

//CONNECT REDUX STORE
const selector = formValueSelector('TargetForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    targetStore: state.targetStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetTarget:(objParam) => dispatch(resetTarget(objParam)),
    getTarget:(objParam) => dispatch(getTarget(objParam)),
    createTarget:(objParam) => dispatch(createTarget(objParam)),
    updateTarget:(objParam) => dispatch(updateTarget(objParam)),
  }
}

class TargetForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

      showContract:false,
      showOptional:false,
    }
  }

  componentDidMount(){
    this.props.resetTarget(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getTarget({targetId: this.props.targetId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.targetStore.updateTarget!=undefined ? this.props.targetStore.updateTarget : {}) : (this.props.targetStore.createTarget!=undefined ? this.props.targetStore.createTarget : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateTarget({...values}) : this.props.createTarget({...values, ...(this.props.campaignId ? {campaignId: this.props.campaignId} : {})})
    
  }

  showOptional(v) {
    if (isNaN(v)) {
      this.setState({
        showContract: v=='contract' ? !this.state.showContract : this.state.showContract,
      });
    } else {
      this.setState({
        showOptional: (v ==this.state.showOptional) ? 0 : v,
      });
    }
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.targetStore.updateTarget!=undefined ? this.props.targetStore.updateTarget : {}) : (this.props.targetStore.createTarget!=undefined ? this.props.targetStore.createTarget : {}))
    
    const channels= (this.props.campaign && this.props.campaign.channels) || this.props.channels || [];
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            {false && <div>
              <Form.Group widths='equal' >
                <Form.Field>
                  <Field name='avatar' component={ImageUploadField}
                  width={200} 
                  height={200} 
                  aspectRation={1}
                  />
                </Form.Field>
              </Form.Group>
              <Divider hidden/>
            </div>}

            <Form.Group widths='16'>
              <Field name='isEnabled' component={CheckboxField}
              width={8}
              toggle
              caption='Enabled'
              label='Enable Now !'
              placeholder='Status enable/disabled' />
              {/* <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' /> */}
            </Form.Group>
            <Form.Group widths='16'>
            {(!this.props.campaign || mode==2) && <>
              <Field name='campaign' component={InputField}
              readOnly
              width={8}
              label='Campaign'
              placeholder='Campaign' />
              <Field name='campaignType' component={InputField}
              readOnly
              width={4}
              label='Campaign Type'
              placeholder='Campaign Type' />
              <Field name='channels' component={InputField}
              readOnly
              width={4}
              label='Channels'
              placeholder='Channels' />
            </>}
            {mode==3 && this.props.campaign && this.props.campaign.name && <>
              <Form.Input value={this.props.campaign.name} readOnly fluid width={8} />
              <Form.Input value={this.props.campaign.campaignType} readOnly fluid width={4} />
              <Form.Input value={this.props.campaign.channels} readOnly fluid width={4} />
            </>}
            </Form.Group>
          </Segment>

          <Segment>
            <Header as='h5' style={{fontWeight: 'bold'}}>
              <Header.Content>
                Customer
                <Header.Subheader>Customer primary and additional information.</Header.Subheader>
              </Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              width={4}
              label='Customer Name'
              placeholder='Customer Name' />
              <Field name='descript' component={InputField}
              width={12}
              label='Contact Description'
              placeholder='Contact Description / Information' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='contractRef' component={InputField}
              width={4}
              label='Contract. Ref. Id'
              placeholder='Contract. Ref. Id' />
              <Field name='custRef' component={InputField}
              width={4}
              label='Cust. Ref. Id'
              placeholder='Cust. Ref. Id' />
              <Field name='dataOrigin' component={InputField}
              width={4}
              label='Data Source'
              placeholder='Data Source' />
              <Field name='dataMaturity' component={InputField}
              width={4}
              fluid
              label='Data Maturity'
              placeholder='Data Maturity' />
            </Form.Group>
            {/* <Form.Group widths='16'>
              <Field name='descript' component={InputField}
              width={16}
              label='Contact Description'
              placeholder='Contact Description / Information' />
            </Form.Group> */}
          </Segment>

          <Segment>
            <Header as='h5' style={{fontWeight: 'bold'}}>
              <Header.Content>Contacts Info</Header.Content>
            </Header>
            
            {(channels.indexOf('SMS')>=0 || channels.indexOf('Voice')>=0 || channels.indexOf('IVR')>=0 || channels.indexOf('Whatsapp')>=0) && <>
            <Form.Group widths='equal'>
              <Field name='whatsapp' component={InputField}
              validate={[phoneNumberID]}
              readOnly= {!(channels.indexOf('Whatsapp')>=0)}
              label='Whatsapp'
              placeholder='Whatsapp' />
              <Field name='mobile' component={InputField}
              validate={[phoneNumberID]}
              disabled= {!(channels.indexOf('SMS')>=0 || channels.indexOf('Voice')>=0 || channels.indexOf('IVR')>=0)}
              label='Mobile Phone'
              placeholder='Mobile Phone' />
              <Field name='homePhone' component={InputField}
              validate={[phoneNumberID]}
              disabled= {!(channels.indexOf('Voice')>=0)}
              label='Home Phone'
              placeholder='Home Phone' />
              <Field name='officePhone' component={InputField}
              validate={[phoneNumberID]}
              disabled= {!(channels.indexOf('Voice')>=0)}
              label='Office Phone'
              placeholder='Office Phone' />
            </Form.Group></>}

            {(channels.indexOf('Email')>=0 || channels.indexOf('Twitter')>=0 || channels.indexOf('Facebook')>=0 || channels.indexOf('Instagram')>=0) && <>
            <Form.Group widths='equal'>
              <Field name='email' component={InputField}
              disabled={!(channels.indexOf('Email')>=0)}
              label='Email'
              placeholder='Email' />
              <Field name='twitter' component={InputField}
              disabled={!(channels.indexOf('Twitter')>=0)}
              label='Twitter'
              placeholder='Twitter' />
              <Field name='facebook' component={InputField}
              disabled={!(channels.indexOf('Facebook')>=0)}
              label='Facebook'
              placeholder='Facebook' />
              <Field name='instagram' component={InputField}
              disabled={!(channels.indexOf('Instagram')>=0)}
              label='Instagram'
              placeholder='Instagram' />
            </Form.Group></>}

            {(channels.indexOf('Telegram')>=0) && <>
            <Form.Group widths='16'>
              <Field name='telegram' component={InputField}
              width={4}
              disabled= {!(channels.indexOf('Telegram'))}
              label='Telegram'
              placeholder='Telegram' />
            </Form.Group></>}

            {(channels.indexOf('SMS')>=0 || channels.indexOf('Voice')>=0 || channels.indexOf('IVR')>=0) && <>
            <Header as='h5' style={{fontWeight: 'bold'}}>
              <Header.Content>Emergency / Relatives</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='emergencyName' component={InputField}
              disabled= {!(channels.indexOf('SMS')>=0 || channels.indexOf('Voice')>=0 || channels.indexOf('IVR')>=0)}
              label='Emergency Contact / Name'
              placeholder='Emergency Contact / Name' />
              <Field name='otherName' component={InputField}
              disabled= {!(channels.indexOf('Voice')>=0)}
              label='Other Contact / Name'
              placeholder='Other Contact / Name' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='emergencyPhone' component={InputField}
              validate={[phoneNumberID]}
              disabled= {!(channels.indexOf('SMS')>=0 || channels.indexOf('Voice')>=0 || channels.indexOf('IVR')>=0)}
              label='Emergency Phone'
              placeholder='Emergency Phone' />
              <Field name='emergencyRelationship' component={InputField}
              label='Emergency Relationship'
              placeholder='Emergency contact relationship' />
              <Field name='otherPhone' component={InputField}
              validate={[phoneNumberID]}
              disabled= {!(channels.indexOf('Voice')>=0)}
              label='Other Phone'
              placeholder='Other Phone' />
              <Field name='otherRelationship' component={InputField}
              label='Other Relationship'
              placeholder='Other contact relationship' />
            </Form.Group>
            </>}
          </Segment>
          
          <Segment>
            <div style={{display: 'flex', marginBottom: '1.5em'}}>
              <div style={{flex: '1'}}>
                <Header as='h5' style={{fontWeight: 'bold'}}>
                  <Header.Content>
                    {this.props.campaignType} Information
                    <Header.Subheader>Contract and Service Information.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{width: '15em'}}>
                <Header as='h5' style={{margin: '0', fontWeight: 'bold'}}>
                  <IconToggle active={this.state.showContract=='1'} onClick={this.showOptional.bind(this, 'contract')} />
                  <Header.Content>
                    Show {this.props.campaignType}
                    <Header.Subheader>{this.props.campaignType} Information</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
            {this.state.showContract && (!this.props.campaignType || this.props.campaignType=='Collection' || this.props.campaignType=='Restructuring' || this.props.campaignType=='Telesales' || this.props.campaignType=='Verification') && <>
              {/* <Header as='h5' style={{fontWeight: 'bold'}}>
                <Header.Content>{this.props.campaignType}</Header.Content>
              </Header> */}

              {(this.props.campaignType=='Collection' || this.props.campaignType=='Restructuring') &&  <>
              <Form.Group widths='equal'>
                <Field name='billAmount' component={InputField}
                label='Bill Amount'
                placeholder='Bill Amount' />
                <Field name='paymentAmount' component={InputField}
                label='Payment Amount'
                placeholder='Payment Amount' />
                <Field name='billDate' component={InputField}
                fluid
                label='Bill Date'
                placeholder='Bill Date' />
                <Field name='dueDate' component={InputField}
                label='Payment Due Date'
                placeholder='Payment Due Date' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='overdueAmount' component={InputField}
                label='Overdue Amount'
                placeholder='Overdue Amount' />
                <Field name='penaltyfeeAmount' component={InputField}
                label='Penalty Fee'
                placeholder='Penalty Fee Amount' />
                <Field name='overdueDay' component={InputField}
                fluid
                label='Overdue Day'
                placeholder='Overdue Day' />
                <Field name='maxDPD' component={InputField}
                fluid
                label='Max Days Past Due'
                placeholder='Max Days Past Due' />
              </Form.Group>
              
              <Divider hidden />
              <Form.Group widths='equal'>
                <Field name='principalAmount' component={InputField}
                fluid
                label='Principal Amount'
                placeholder='Principal Amount' />
                <Field name='discountAmount' component={InputField}
                fluid
                label='Discount Amount'
                placeholder='Discount Amount' />
                <Field name='interestAmount' component={InputField}
                label='Interest Amount'
                placeholder='Interest Amount' />
                <Field name='servicefeeAmount' component={InputField}
                fluid
                label='Service Fee'
                placeholder='Service Fee Amount' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='service' component={InputField}
                label='Loan / Service Info'
                placeholder='Loan / Service Information' />
                <Field name='bucket' component={InputField}
                label='Bucket Info'
                placeholder='Bucket Info' />
              </Form.Group></>}
              {(this.props.campaignType=='Telesales' || this.props.campaignType=='Verification') && 
              <Form.Group widths='equal'>
                <Field name='product' component={InputField}
                label='Product / Service'
                placeholder='Product / Service' />
                <Field name='valuation' component={InputField}
                label='Amount / Valuation'
                placeholder='Amount / Valuation' />
                <Field name='information' component={InputField}
                label='Information'
                placeholder='Information' />
              </Form.Group>}
            </>}
          </Segment>

          <Segment>
            <div style={{display: 'flex', marginBottom: '1.5em'}}>
              <div style={{flex: '1'}}>
                <Header as='h5' style={{fontWeight: 'bold'}}>
                  <Header.Content>
                    Extended Information
                    <Header.Subheader>Extended information.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{width: '15em'}}>
                <Header as='h5' style={{margin: '0', fontWeight: 'bold'}}>
                  <IconToggle active={this.state.showOptional=='1'} onClick={this.showOptional.bind(this, 1)} />
                  <Header.Content>
                    Optional
                    <Header.Subheader>Show Extended Data</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
            {this.state.showOptional=='1' && <>
              <Form.Group widths='16'>
                <Field name='priority' component={InputField}
                width={4}
                label='Priority'
                placeholder='Priority' />
                <Field name='tagger' component={InputField}
                width={4}
                label='Tagger'
                placeholder='Tagger' />
                <Field name='keyword' component={InputField}
                width={8}
                label='Keyword / Labeling'
                placeholder='Data labeling separated by comma' />
              </Form.Group>
            </>}
          </Segment>

          <Segment attached='top'>
            <div style={{display: 'flex', marginBottom: '1.5em'}}>
              <div style={{flex: '1'}}>
                <Header as='h5' style={{fontWeight: 'bold'}}>
                  <Header.Content>
                    Personal Information
                    <Header.Subheader>Personal information.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{width: '15em'}}>
                <Header as='h5' style={{margin: '0', fontWeight: 'bold'}}>
                  <IconToggle active={this.state.showOptional=='2'} onClick={this.showOptional.bind(this, 2)} />
                  <Header.Content>
                    Optional
                    <Header.Subheader>Show Personal Data</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
            {this.state.showOptional=='2' && <>
              <Form.Group widths='equal'>
                <Field name='occupation' component={InputField}
                label='Occupation'
                placeholder='Occupation' />
                <Field name='jobTitle' component={InputField}
                fluid
                label='Job Title'
                placeholder='Job Title' />
                <Field name='jobDepartment' component={InputField}
                fluid
                label='Department'
                placeholder='Department' />
                <Field name='jobCompany' component={InputField}
                fluid
                label='Company'
                placeholder='Company' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='revenue' component={InputField}
                label='Revenue'
                placeholder='Revenue' />
                <Field name='expense' component={InputField}
                label='Expense'
                placeholder='Expense' />
                <Field name='homestay' component={InputField}
                label='Home Ownership'
                placeholder='Home Ownership' />
                <Field name='vehicle' component={InputField}
                label='Vehicle Ownerhsip'
                placeholder='Vehicle Ownerhsip' />
                {/* <Field name='creditcard' component={InputField}
                label='Credit Card'
                placeholder='Credit Card' /> */}
              </Form.Group>
            </>}
          </Segment>
          {this.state.showOptional=='2' && <>
            <Segment attached='bottom'>
              <Form.Group widths='equal'>
                <Field name='gender' component={InputField}
                fluid
                label='Gender'
                placeholder='L/P' />
                <Field name='birthDate' component={DatePickerField}
                label='Birth Date'
                placeholder='Birth Date' />
                <Field name='children' component={InputField}
                label='Children'
                placeholder='Children' />
                <Field name='marital' component={InputField}
                fluid
                label='Marital'
                placeholder='Marital' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='address' component={InputField}
                fluid
                label='Address'
                placeholder='Address' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='city' component={InputField}
                fluid
                placeholder='City' />
                <Field name='province' component={InputField}
                fluid
                placeholder='Province' />
                <Field name='country' component={InputField}
                fluid
                placeholder='Country' />
                <Field name='zipcode' component={InputField}
                fluid
                placeholder='Zipcode' />
              </Form.Group>

              {/* <Divider hidden />
              <Form.Group widths='equal'>
                <Field name='appointmentDate' component={SelectField}
                options={fields}
                label='Appointment Date'
                placeholder='Appointment Date' />
                <Field name='appointmentTime' component={SelectField}
                options={fields}
                label='Appointment Time'
                placeholder='Appointment Time' />
                <Field name='appointmentTopic' component={SelectField}
                options={fields}
                label='Appointment Topic'
                placeholder='Appointment Topic' />
              </Form.Group> */}
            </Segment>
          </>}

          <Segment attached='top'>
            <div style={{display: 'flex', marginBottom: '1.5em'}}>
              <div style={{flex: '1'}}>
                <Header as='h5' style={{fontWeight: 'bold'}}>
                  <Header.Content>
                    Merchant Information
                    <Header.Subheader>Personal information.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{width: '15em'}}>
                <Header as='h5' style={{margin: '0', fontWeight: 'bold'}}>
                  <IconToggle active={this.state.showOptional=='3'} onClick={this.showOptional.bind(this, 3)} />
                  <Header.Content>
                    Optional
                    <Header.Subheader>Show Merchant Data</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
            {this.state.showOptional=='3' && <>
              <Form.Group widths='equal'>
                <Field name='merchantCode' component={InputField}
                label='Merchant Code'
                placeholder='Merchant Code' />
                <Field name='merchantName' component={InputField}
                fluid
                label='Merchant Name'
                placeholder='Merchant Name' />
                <Field name='merchantWarehouse' component={InputField}
                fluid
                label='Merchant Warehouse'
                placeholder='Merchant Warehouse' />
                <Field name='merchantAcquisition' component={InputField}
                fluid
                label='Merchant Acquisition'
                placeholder='Merchant Acquisition' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='previousAov' component={InputField}
                label='Previous AOV'
                placeholder='Previous Average Order Value' />
                <Field name='previousFreqOrder' component={InputField}
                fluid
                label='Previous Freq. Order'
                placeholder='Previous Freq. Order' />
                <Field name='currentAov' component={InputField}
                fluid
                label='Current AOV'
                placeholder='Current Average Order Value' />
                <Field name='currentFreqOrder' component={InputField}
                fluid
                label='Current Freq. Order'
                placeholder='Current Freq. Order' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='f_limitMaximum' component={InputField}
                label='Limit Maximum'
                placeholder='Limit Maximum' />
                <Field name='f_limitRemaining' component={InputField}
                fluid
                label='Limit Remaining'
                placeholder='Limit Remaining' />
                <Field name='f_previousMonthTrx' component={InputField}
                fluid
                label='Previous Month Trx.'
                placeholder='Previous Month Trx.' />
                <Field name='f_currentMonthTrx' component={InputField}
                fluid
                label='Current Month Trx.'
                placeholder='Current Month Trx.' />
              </Form.Group>
            </>}
          </Segment>

          {this.state.showOptional=='3' && <>
            <Segment attached={'bottom'}>
              <Form.Group widths='equal'>
                <Field name='project' component={InputField}
                fluid
                label='Project'
                placeholder='Project' />
                <Field name='retentionGmvStatus' component={InputField}
                fluid
                label='Retention GMV'
                placeholder='Retention Gross Merchant Value' />
                <Field name='retentionOrderStatus' component={InputField}
                fluid
                label='Retention Order Status'
                placeholder='Retention Order Status' />
                <Field name='retentionAppStatus' component={InputField}
                fluid
                label='Retention App Status'
                placeholder='Retention App Status' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='retentionPhysicalSegment' component={InputField}
                fluid
                label='Retention Physical Segment'
                placeholder='Retention Physical Segment' />
                <Field name='retentionJourney' component={InputField}
                fluid
                label='Retention Journey'
                placeholder='Retention Journey' />
                <Field name='retentionAccess' component={InputField}
                fluid
                label='Retention Access'
                placeholder='Retention Access' />
                <Field name='retentionSegment' component={InputField}
                fluid
                label='Retention Segment'
                placeholder='Retention Segment' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='retentionCountTrx' component={InputField}
                fluid
                label='Retention Count Trx'
                placeholder='Retention Count Trx' />
                <Field name='retentionGmvAccumulated' component={InputField}
                fluid
                label='Retention Accumulated GMV'
                placeholder='Retention Accumulated Gross Merchant Value' />
                <Field name='retentionCountTrxUngroup' component={InputField}
                fluid
                label='Retention Count Trx Ungroup'
                placeholder='Retention Count Trx Ungroup' />
                <Field name='retentionUninstallStatus' component={InputField}
                fluid
                label='Retention Uninstall Status'
                placeholder='Retention Uninstall Status' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='retentionAppEngine' component={InputField}
                fluid
                label='Retention App Engine'
                placeholder='Retention App Engine' />
                <Field name='retentionOutboundCall' component={InputField}
                fluid
                label='Retention Outbound Called'
                placeholder='Retention Outbound Called' />
                <Field name='retentionRfm' component={InputField}
                fluid
                label='Retention RFM'
                placeholder='Retention Recency, Frequency, and Monetary' />
                <Field name='retentionChurnProgress' component={InputField}
                fluid
                label='Retention Churn Progress'
                placeholder='Retention Churn Progress' />
              </Form.Group>
            </Segment>
          </>}

          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Contact' : 'Insert Contact') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.targetStore.getTarget!=undefined && state.targetStore.getTarget.progress==1 ? state.targetStore.getTarget.data : {}),
      formValues: getFormValues('TargetForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      campaignType: selector(state, 'campaignType'),
      channels: selector(state, 'channels'),
    }),
    { load: getTarget }
  )
  (reduxForm({
    form: 'TargetForm',	// a unique identifier for this form
  })(TargetForm)
  )
)
