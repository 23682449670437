import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
} from 'semantic-ui-react'



class EmulatorOnline extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { responsive, layout }= this.props
    return (
      <>
        <Segment style={{padding: '1em .5em', background: '#eee', border: 'none', textAlign: !responsive && layout!='mini' ? 'center' : 'left'}}>
          <Header as='h3' icon={!responsive && layout!='mini'} style={{margin: '1em .5em 0 .5em', display: 'inline'}} color='blue'>
            <Icon name='linkify' style={{fontSize: layout!='mini' ? '3em' : '1.5em'}} />
            <Header.Content>
              <b>Online !</b>
              <Header.Subheader style={{fontSize: layout!='mini' ? '.8em' : '.6em'}}>
                You 've been registered.
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Segment>
      </>
    )
  }
}

export default EmulatorOnline