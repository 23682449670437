import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'

import { socialOptions, channelOptions }  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField, DatePickerField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { browseSocial } from "../../actions/stream/socialAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socialStore: state.socialStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseSocial:(objParam) => dispatch(browseSocial(objParam)),
  }
}


class SocialSearch extends Component {
  constructor(props) {
    super(props)
    this.state= {
      searchMode: true,
    }
  }

  submitForm(values) {
    const { search }= this.state
    //console.log('!!! SEARCH FORM VALUES: ', values)
    const searchValues= {...values, ...search}
    const objParam= {search: searchValues}
    //console.log('!!! SEARCH FORM SUBMIT OBJECT: ', objParam)
    //console.log('!!! SEARCH FORM SUBMIT JSON: ', JSON.stringify(objParam))
    
    this.props.browseSocial(objParam)
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeDate(key, v) {
    const { search }= (this.props.socialStore.browseSocial!==undefined ? this.props.socialStore.browseSocial : {})
    var values= []
    values[key]= v
    this.props.browseSocial({search: {...search, ...values}})
  }

  onChangeInput(e, v) {
    const { search }= (this.props.socialStore.browseSocial!==undefined ? this.props.socialStore.browseSocial : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.props.browseSocial({search: {...search, keyword: v}})
      }
    }, 500)
  }

  onChangeSelect(k, e, v) {
    const { search }= (this.props.socialStore.browseSocial!==undefined ? this.props.socialStore.browseSocial : {})
    if (k=='status') {
      this.props.browseSocial({search: {...search, status: v}})
    } else {
      this.props.browseSocial({search: {...search, channelType: v}})
    }
  }
  
  render() {
    ///console.log(this.props)
    //console.log('!!! RENDER STATE: ', this.state)
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode }=this.props
    //const data= (this.props.socialStore.browseSocial!==undefined ? this.props.socialStore.browseSocial.data : undefined)
    
    
    return (
      <div style={{position: 'static', width: '100%', zIndex: '1000'}}>
        <div style={{padding: '1em 1em', background: 'rgba(230, 230, 230, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
          <div style={{display: 'flex', padingTop: '1em'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' style={{marginTop: '.25em'}}>
                <Icon name='share alternate' style={{fontSize: '1em'}}/>
                <Header.Content>
                  Social Media / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
                </Header.Content>
              </Header>
            </div>
            <div>
              {mode !=1 &&
              <Button size='mini' color='blue' onClick={this.props.setMode.bind(this,1)} icon style={{padding: '.5em'}}><Icon name='arrow left' /></Button>}
              {mode !=2 && mode !=3 &&
              <Button size='mini' color='red' onClick={this.props.setMode.bind(this,3)} icon style={{padding: '.5em'}}><Icon name='plus' /></Button>}
              {mode ==1 &&
              <Button size='mini' color='grey' onClick={this.showSearch.bind(this)} icon style={{padding: '.5em'}}><Icon name={searchMode==true? 'angle up' : 'angle down'} /></Button>}
            </div>
          </div>

          {mode==1 && searchMode && 
          <div style={{margin: '1em 0 0 0', border: '0px solid rgba(100,100,100,.2)'}}>
            <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
            <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Form.Group widths='16'>
                    <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)}
                    width={7} 
                    placeholder= 'Search social ... <ENTER>' />
                    <Field name='channelType' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'channelType')}
                    options={channelOptions('social')}
                    width={3} 
                    placeholder= 'Channel Type' />
                    <Field name='status' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'status')}
                    options={socialOptions}
                    width={3} 
                    placeholder= 'Status' />
                    <Field name='transactDate' component={DatePickerField} onChange= {this.onChangeDate.bind(this, 'transactDate')}
                    width={3} 
                    type='date'
                    placeholder= 'Trx. Date' />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.socialStore.browseSocial!=undefined && state.socialStore.browseSocial.progress==1 ? state.socialStore.browseSocial.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'SocialSearch',	// a unique identifier for this form
  })(SocialSearch)
  )
)