import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Grid, Label, Progress, Input, Dropdown, Card, Checkbox, Form } from 'semantic-ui-react'

import numeral from "numeral"

import IconToggle from '../common/IconToggle'
import PieRechart from '../chart/PieRechart';

import { connect } from "react-redux"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus } from "../../actions/socket/socketAction"
import { statConversation } from "../../actions/halo/statisticAction"

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    statisticStore: state.statisticStore,
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    statConversation:(objParam) => dispatch(statConversation(objParam)),
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),
  }
}

class OmniTrafficWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      pieChart: null,
      // [
      //   { name: 'Group A', value: 400 }, 
      //   { name: 'Group B', value: 300 },
      //   { name: 'Group C', value: 300 }, 
      //   { name: 'Group D', value: 200 },
      //   { name: 'Group E', value: 278 }, 
      //   { name: 'Group F', value: 189 },
      // ]
    };
  }

  componentDidMount() {
    this.props.statConversation() 
  }

  componentDidUpdate() {
    const { data, search, paging }= (this.props.statisticStore.statConversation!==undefined ? this.props.statisticStore.statConversation : {})

    if (data) {
      var totIncoming= 0, totResponsed= 0, totUnresponsed= 0, totAutomated= 0;
      const values= new Map(), barData= [];

      data && data.map((item, i)=>{
        if (i==0) {
          totIncoming+= parseInt(item.totIncoming);
          totResponsed+= parseInt(item.totResponsed);
          totUnresponsed+= parseInt(item.totUnresponsed);
          totAutomated+= parseInt(item.totAutomated);
        }
      })

      !this.state.pieChart && this.setState({
        totIncoming: totIncoming, 
        totResponsed: totResponsed, 
        totUnresponsed: totUnresponsed, 
        totAutomated: totAutomated,
        pieChart: 
        [
          { name: 'Incoming', value: totIncoming }, 
          { name: 'Responsed', value: totResponsed },
          { name: 'Unresponsed', value: totUnresponsed }, 
          { name: 'Automated', value: totAutomated },
        ]
      })
    }

  }

  render() {
    const { mode, screenWidth,  screenHeight, responsive, agent }= this.props;
    const { totIncoming, totResponsed, totUnresponsed, totAutomated }= this.state;
    
    return (<>
      {!agent && <Segment attached='top' style={{padding: '1em .5em'}}>
        <Header as='h5'>
          <Icon name='chart area' style={{fontSize: '1em'}} />
          <Header.Content>
            <i>Today!</i> Inbound Traffics
            <Header.Subheader></Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>}
      <Segment style={{padding: '1em 1.5em 3em 1.5em'}} attached='bottom'>
        {agent && <div style={{display: 'flex', marginBottom: '1em'}}>
          <div style={{flex: '1'}}>
            <Label color='purple' style={{borderRadius: '2em', padding: '.1em 1em .5em 1em'}}>
              <Header as='h4' style={{marginTop: '.25em'}} inverted>
                <Icon name='chart area' style={{fontSize: '1em'}} />
                <Header.Content style={{fontWeight: 'bold'}}>
                  <i>Today!</i> Inbound Traffics
                </Header.Content>
              </Header>
            </Label>
          </div>
          <div style={{marginLeft: '1em'}}>
          </div>
        </div>}

        <Grid columns={screenWidth<1024 || responsive ? 2 : 4} style={{margin: '0'}}>
          <Grid.Column>
            <Header as='h3' color='black' style={{margin: '0'}}>
              <Icon name='wifi' style={{transform: 'rotate(90deg)', fontSize: '1.4em'}} circular inverted color='orange' />
              <Header.Content>
                <b style={{fontSize: '1.6em'}}>{(totIncoming>1000 ? numeral(totIncoming).format(totIncoming<1000 ? '0,0' : totIncoming%1000==0 ? '0a' : '0.0a') : numeral(totIncoming).format('0,0'))}</b>
                <Header.Subheader style={{fontSize: '1em'}}>Incoming</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as='h3' color='black' style={{margin: '0'}}>
              <Icon name='comments outline' style={{fontSize: '1.4em'}} circular inverted color='blue' />
              <Header.Content>
                <b style={{fontSize: '1.6em'}}>{(totResponsed>1000 ? numeral(totResponsed).format(totResponsed<1000 ? '0,0' : totResponsed%1000==0 ? '0a' : '0.0a') : numeral(totResponsed).format('0,0'))}</b>
                <Header.Subheader style={{fontSize: '1em'}}>Answered</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as='h3' color='black' style={{margin: '0'}}>
              <Icon name='warning sign' style={{fontSize: '1.4em'}} circular inverted color='red' />
              <Header.Content>
                <b style={{fontSize: '1.6em'}}>{(totUnresponsed>1000 ? numeral(totUnresponsed).format(totUnresponsed<1000 ? '0,0' : totUnresponsed%1000==0 ? '0a' : '0.0a') : numeral(totUnresponsed).format('0,0'))}</b>
                <Header.Subheader style={{fontSize: '1em'}}>Unresponsed</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as='h3' color='black' style={{margin: '0'}}>
              <Icon name='window restore outline' style={{fontSize: '1.4em'}} circular inverted color='green' />
              <Header.Content>
                <b style={{fontSize: '1.6em'}}>{(totAutomated>1000 ? numeral(totAutomated).format(totAutomated<1000 ? '0,0' : totAutomated%1000==0 ? '0a' : '0.0a') : numeral(totAutomated).format('0,0'))}</b>
                <Header.Subheader style={{fontSize: '1em'}}>Automated</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
        </Grid>

        <Divider />
        <Grid columns={16} stackable>
          <Grid.Column style={{textAlign: 'center'}} width={7}>
            {this.state.pieChart!=undefined && screenWidth>800 && 
            <PieRechart data={this.state.pieChart} legend width={300} height={300} mode='circle' fill='#555'/>}
            {/* <Header as='h5'>
              PERFORMANCE
              <Header.Subheader>Profiling based on personal</Header.Subheader>
            </Header> */}
          </Grid.Column>
          <Grid.Column width={9}>
            <div style={{display: 'flex', flexDirection: 'column', minHeight: '100%'}}>
              <div style={{flex: '1'}}>
                
              </div>
              <div>
                <Header as='h5'>
                  <Label color='yellow' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>{(totIncoming ? Math.ceil(totIncoming/totIncoming * 100) : 0)}%</Label>
                  <Header.Content>
                    Incoming
                    <Header.Subheader>Incoming engagement request.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5'>
                  <Label color='olive' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>{(totIncoming ? Math.ceil(totResponsed/totIncoming * 100) : 0)}%</Label>
                  <Header.Content>
                    Responsed
                    <Header.Subheader>Responsed by Agent.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5'>
                  <Label color='green' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>{(totIncoming ? Math.ceil(totUnresponsed/totIncoming * 100) : 0)}%</Label>
                  <Header.Content>
                    Unresponsed
                    <Header.Subheader>Missed or unavailable agents.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5'>
                  <Label color='blue' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>{(totIncoming ? Math.ceil(totAutomated/totIncoming * 100) : 0)}%</Label>
                  <Header.Content>
                    Automated
                    <Header.Subheader>Response by Automation / AI.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1'}}>
                
              </div>
            </div>
            
          </Grid.Column>
        </Grid>
      </Segment>
    </>);
  }
}

OmniTrafficWidget.contextType= WebSocketContext;

export default connect(mapStateToProps, mapDispatchToProps)(OmniTrafficWidget)
