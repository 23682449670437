import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Grid, Progress, Statistic, Label } from 'semantic-ui-react'

import numeral from "numeral"

import { connect } from "react-redux"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus, socketSystemHeartbeat, socketSystemOccupancy, socketSystemMonitoring } from "../../../actions/socket/socketAction"
import WebSocketProvider, { WebSocketContext } from '../../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketSystemMonitoring:(objParam) => dispatch(socketSystemMonitoring(objParam)),
  }
}

class BillingWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      isUsage: false,
      isDeposit: false,
    };
    this.billings= {};
  }

  expandContent(v) {
    if (v=='usage') {
      this.setState({
        isUsage: !this.state.isUsage
      })
    } else {
      this.setState({
        isDeposit: !this.state.isDeposit
      })
    }
  }

  openModal(v) {
    if (this.props.openModal) {
      this.props.openModal(v)
    }
  }


  
  render() {
    const { isUsage, isDeposit }= this.state
    const { screenWidth,  screenHeight}= this.props
    const { agents, omnis, providers, billings }= this.props.socketStore.socketSystemMonitoring!=undefined && this.props.socketStore.socketSystemMonitoring.data && this.props.socketStore.socketSystemMonitoring.data.payload && this.props.socketStore.socketSystemMonitoring.data.payload.data ? this.props.socketStore.socketSystemMonitoring.data.payload.data: {};
    
    if (billings) {
      this.billings= billings;
    } 
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <div style={{display: 'flex'}}>
          <div style={{flex: '1'}}>
            <Header as='h5' style={{margin: '0'}}>
              <Icon name='fire' style={{fontSize: '1em'}} />
              <Header.Content>
                Hot Billing
                <Header.Subheader></Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div>
            <Popup  content='Click for detail bill item records.' position='left center'
              trigger={
                <Icon name='list ul' onClick={this.openModal.bind(this, this.billings && this.billings.billingType=='postpaid' ? 'record' : 'deduct')}  style={{fontSize: '1.25em', cursor: 'pointer'}} />
              }
              inverted
              on={['hover']}
            />
          </div>
        </div>
        
      </Segment>
      <Segment attached='bottom'>
        <Header as='h5' style={{margin: '0'}} color='blue'>
          <Header.Content style={{fontWeight: 'bold'}}>
            {this.billings.company}
            <Header.Subheader>{(this.billings.billingType=='postpaid' && 'Postpaid') || 'Prepaid'} Customer</Header.Subheader>
          </Header.Content>
        </Header>
        {this.billings.billingType=='prepaid' && <>
          <Divider style={{margin: '1.5em 0 0em 0'}}/>
          <div style={{textAlign: 'center'}}>
            {false && this.billings && this.billings.prepaid && !(parseFloat(this.billings.prepaid.depositTelco)>0 || parseFloat(this.billings.prepaid.depositWhatsapp)>0 || parseFloat(this.billings.prepaid.depositEmail)>0 || parseFloat(this.billings.prepaid.depositSocmed)>0) && 
            <Label as='a' floating style={{background: '#fff', padding: '.5em 0', top: '4.5em'}} circular><Icon color='red' name='warning sign' style={{margin: '.1em 0 0 0', fontSize: '1.8em'}}/></Label>
            }
            <Popup  header={this.billings.prepaid.depositCredit<10000 ? 'Topup Account' : 'Available Deposit'} content={this.billings.prepaid.depositCredit<10000 ? 'Click to topup your deposit, to makesure your campaign is not interupted.' : 'Your available deposit is deducted when transaction is closed.'} position='left center'
              trigger={
                <Statistic>
                  <Statistic.Value style={{cursor: 'pointer'}} onClick={this.openModal.bind(this, 'topup')}>
                    <span  style={{fontSize: isDeposit ? '.7em' : '1em', color: this.billings.prepaid.depositCredit<10000 ? '#c31' : '#333'}} className={this.billings.prepaid.depositCredit<10000 ? 'coloranimatered' : ''}>
                      {this.billings && this.billings.prepaid && (this.billings.prepaid.depositCredit>1000 ? numeral(this.billings.prepaid.depositCredit).format(isDeposit ? '0,0' : this.billings.prepaid.depositCredit%1000==0 ? '0a' : '0.0a') : numeral(this.billings.prepaid.depositCredit).format('0,0'))}
                    </span>
                  </Statistic.Value>
                  <Statistic.Label>Credit Deposit</Statistic.Label>
                </Statistic>
              }
              inverted
              on={['hover']}
            />
          </div>
          <div style={{display: 'flex'}}>
            <Divider style={{flex: '1'}}/>
            {/* <div>
              <Icon name={isDeposit ? 'toggle on' : 'toggle off'} style={{marginTop: '.3em', transform: 'rotate(90deg)', fontSize: '1.5em', cursor: 'pointer'}} onClick={this.expandContent.bind(this, 'deposit')}/>
            </div> */}
            <Divider style={{flex: '1'}} />
          </div>
          
          {isDeposit && <div>
            <Header as='h4' style={{margin: '.5em 0 1em 0', textAlign: 'center'}} color='blue'>
              <Header.Content style={{fontWeight: 'bold'}}>
                Sub Credit Deposit
                <Header.Subheader></Header.Subheader>
              </Header.Content>
            </Header>
            <div style={{display: 'flex', textAlign: 'center', margin: '.3em 0'}}>
              <div style={{flex: '1', background: '#fafafa', padding: '.25em 0', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc'}}>
                <Popup  header='Omni Credit' content='Credit is applicable for all available channels.' position='left center'
                  trigger={
                    <Header as='h5' style={{margin: '0', cursor: 'pointer'}}>
                      <Header.Content>
                        {this.billings && this.billings.prepaid && (this.billings.prepaid.depositOmni>1000 ? numeral(this.billings.prepaid.depositOmni).format(this.billings.prepaid.depositOmni%1000==0 ? '0a' : '0.0a') : numeral(this.billings.prepaid.depositOmni).format('0,0'))}
                        <Header.Subheader>All Channels</Header.Subheader>
                      </Header.Content>
                    </Header>
                  }
                  inverted
                  on={['hover']}
                />
              </div>
            </div>
            <div style={{display: 'flex', textAlign: 'center', margin: '.3em 0'}}>
              <div style={{flex: '1', background: '#fafafa', padding: '.25em 0'}}>
                <Popup  header='Voice + SMS Credit' content='Credit is only applicable for voice and sms services.' position='left center'
                  trigger={
                  <Header as='h5' style={{margin: '0', cursor: 'pointer'}}>
                    <Header.Content>
                      {this.billings && this.billings.prepaid && (this.billings.prepaid.depositTelco>1000 ? numeral(this.billings.prepaid.depositTelco).format(this.billings.prepaid.depositTelco%1000==0 ? '0a' : '0.0a') : numeral(this.billings.prepaid.depositTelco).format('0,0'))}
                      <Header.Subheader>Voice + SMS</Header.Subheader>
                    </Header.Content>
                  </Header>
                  }
                  inverted
                  on={['hover']}
                />
              </div>
              <div style={{flex: '1', background: '#fafafa', padding: '.25em 0', borderLeft: '1px solid #ccc'}}>
                <Popup  header='Whatsapp Credit' content='Credit is applicable only for Whatsapp content delivery.' position='left center'
                  trigger={
                    <Header as='h5' style={{margin: '0', cursor: 'pointer'}}>
                      <Header.Content>
                        {this.billings && this.billings.prepaid && (this.billings.prepaid.depositWhatsapp>1000 ? numeral(this.billings.prepaid.depositWhatsapp).format(this.billings.prepaid.depositWhatsapp%1000==0 ? '0a' : '0.0a') : numeral(this.billings.prepaid.depositWhatsapp).format('0,0'))}
                        <Header.Subheader>Whatsapp</Header.Subheader>
                      </Header.Content>
                    </Header>
                  }
                  inverted
                  on={['hover']}
                />
              </div>
            </div><div style={{display: 'flex', textAlign: 'center', margin: '.3em 0'}}>
              <div style={{flex: '1', background: '#fafafa', padding: '.25em 0'}}>
                <Popup  header='Email Credit' content='Credit is applicable only for email content delivery.' position='left center'
                  trigger={
                    <Header as='h5' style={{margin: '0', cursor: 'pointer'}}>
                      <Header.Content>
                        {this.billings && this.billings.prepaid && (this.billings.prepaid.depositEmail>1000 ? numeral(this.billings.prepaid.depositEmail).format(this.billings.prepaid.depositEmail%1000==0 ? '0a' : '0.0a') : numeral(this.billings.prepaid.depositEmail).format('0,0'))}
                        <Header.Subheader>Email</Header.Subheader>
                      </Header.Content>
                    </Header>
                  }
                  inverted
                  on={['hover']}
                />
              </div>
              <div style={{flex: '1', background: '#fafafa', padding: '.25em 0', borderLeft: '1px solid #ccc'}}>
                <Popup  header='Socmed Credit' content='Credit is applicable only for social media content delivery.' position='left center'
                  trigger={
                    <Header as='h5' style={{margin: '0', cursor: 'pointer'}}>
                      <Header.Content>
                        {this.billings && this.billings.prepaid && (this.billings.prepaid.depositSocmed>1000 ? numeral(this.billings.prepaid.depositSocmed).format(this.billings.prepaid.depositSocmed%1000==0 ? '0a' : '0.0a') : numeral(this.billings.prepaid.depositSocmed).format('0,0'))}
                        <Header.Subheader>Socmed</Header.Subheader>
                      </Header.Content>
                    </Header>
                  }
                  inverted
                  on={['hover']}
                />
              </div>
            </div>
          </div>}
        </>}
        {this.billings.billingType=='postpaid' && <>
          <Divider />
            <div style={{textAlign: 'center'}}>
            <Popup  header='Est. Bill Amount' content='Total usage of current month is summed up, for your consideration make sure rating is correctly setup, false rating setup returns wrong value.' position='left center'
              trigger={
                <Statistic>
                  <Statistic.Value style={{cursor: 'pointer'}} _onClick={this.openModal.bind(this, 'topup')}>
                    <span  style={{fontSize: isUsage ? '.7em' : '1em'}}>
                      {this.billings && this.billings.postpaid && (this.billings.postpaid.billNettAmount>1000 ? numeral(this.billings.postpaid.billNettAmount).format(this.billings.prepaid.billNettAmount%1000==0 ? '0a' : '0.0a') : numeral(this.billings.postpaid.billNettAmount).format('0,0'))}
                    </span>
                  </Statistic.Value>
                  <Statistic.Label>Est. Billing</Statistic.Label>
                </Statistic>
              }
              inverted
              on={['hover']}
            />
            </div>
          <div style={{display: 'flex'}}>
            <Divider style={{flex: '1'}} />
            {/* <div>
              <Icon name={isUsage ? 'toggle on' : 'toggle off'} style={{marginTop: '.3em', transform: 'rotate(90deg)', fontSize: '1.5em', cursor: 'pointer'}} onClick={this.expandContent.bind(this, 'usage')}/>
            </div> */}
            <Divider style={{flex: '1'}} />
          </div>
          
          {isUsage && <div>
            <Header as='h5' style={{margin: '.5em 0 1em 0', textAlign: 'center'}} color='blue'>
              <Header.Content style={{fontWeight: 'bold'}}>
                EST. USAGE
                <Header.Subheader>Channel's Usage</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />

            <Popup  header='Voice Call' content='Show your current call quantity and duration in seconds.' position='left center'
              trigger={
                <div style={{cursor: 'pointer'}}>
                  <Header as='h5' style={{margin: '0'}}>
                    <Icon name='microphone' style={{fontSize: '1em'}}/>
                    <Header.Content>
                      Voice
                      <Header.Subheader></Header.Subheader>
                    </Header.Content>
                  </Header>
                  <div style={{display: 'flex', textAlign: 'center', margin: '.3em 0'}}>
                    <div style={{flex: '1', background: '#fafafa', padding: '.25em 0'}}>
                      {this.billings && this.billings.postpaid && (this.billings.postpaid.qtyVoiceCall>1000 ? numeral(this.billings.postpaid.qtyVoiceCall).format(this.billings.prepaid.qtyVoiceCall%1000==0 ? '0a' : '0.0a') : numeral(this.billings.postpaid.qtyVoiceCall).format('0,0'))} <span style={{fontSize: '.9em', color: '#666'}}>Call</span>
                    </div>
                    <div style={{flex: '1', background: '#fafafa', padding: '.25em 0', borderLeft: '1px solid #ccc'}}>
                    {this.billings && this.billings.postpaid && (this.billings.postpaid.qtyVoiceSecond>1000 ? numeral(this.billings.postpaid.qtyVoiceSecond).format(this.billings.prepaid.qtyVoiceSecond%1000==0 ? '0a' : '0.0a') : numeral(this.billings.postpaid.qtyVoiceSecond).format('0,0'))} <span style={{fontSize: '.9em', color: '#666'}}>Second</span>
                    </div>
                  </div>
                </div>
              }
              inverted
              on={['hover']}
            />

            <Divider />
            <Popup  header='IVR Blast' content='Show your current usage of IVR Call Automation,  call quantity and duration in seconds.' position='left center'
              trigger={
                <div style={{cursor: 'pointer'}}>
                  <Header as='h5' style={{margin: '0'}}>
                    <Icon name='volume down' style={{fontSize: '1em'}}/>
                    <Header.Content>
                      IVR / Blast
                      <Header.Subheader></Header.Subheader>
                    </Header.Content>
                  </Header>
                  <div style={{display: 'flex', textAlign: 'center', margin: '.3em 0'}}>
                    <div style={{flex: '1', background: '#fafafa', padding: '.25em 0'}}>
                      {this.billings && this.billings.postpaid && (this.billings.postpaid.qtyIVRCall>1000 ? numeral(this.billings.postpaid.qtyIVRCall).format(this.billings.prepaid.qtyIVRCall%1000==0 ? '0a' : '0.0a') : numeral(this.billings.postpaid.qtyIVRCall).format('0,0'))} <span style={{fontSize: '.9em', color: '#666'}}>Call</span>
                    </div>
                    <div style={{flex: '1', background: '#fafafa', padding: '.25em 0', borderLeft: '1px solid #ccc'}}>
                      {this.billings && this.billings.postpaid && (this.billings.postpaid.qtyIVRSecond>1000 ? numeral(this.billings.postpaid.qtyIVRSecond).format(this.billings.prepaid.qtyIVRSecond%1000==0 ? '0a' : '0.0a') : numeral(this.billings.postpaid.qtyIVRSecond).format('0,0'))} <span style={{fontSize: '.9em', color: '#666'}}>Second</span>
                    </div>
                  </div>
                </div>
              }
              inverted
              on={['hover']}
            />

            <Divider />
            <Popup  header='Messaging' content='Messaging transaction is shown here, as your current usage / traffic.' position='left center'
              trigger={
                <div style={{cursor: 'pointer'}}>
                  <Header as='h5' style={{margin: '0'}}>
                    <Icon name='envelope outline' style={{fontSize: '1em'}}/>
                    <Header.Content>
                      Messaging
                      <Header.Subheader></Header.Subheader>
                    </Header.Content>
                  </Header>
                  <div style={{display: 'flex', textAlign: 'center', margin: '.3em 0'}}>
                    <div style={{flex: '1', background: '#fafafa', padding: '.25em 0'}}>
                      {this.billings && this.billings.postpaid && (this.billings.postpaid.qtySMSDelivery>1000 ? numeral(this.billings.postpaid.qtySMSDelivery).format(this.billings.prepaid.qtySMSDelivery%1000==0 ? '0a' : '0.0a') : numeral(this.billings.postpaid.qtySMSDelivery).format('0,0'))} <span style={{fontSize: '.9em', color: '#666'}}>SMS</span>
                    </div>
                    <div style={{flex: '1', background: '#fafafa', padding: '.25em 0', borderLeft: '1px solid #ccc'}}>
                      {this.billings && this.billings.postpaid && (this.billings.postpaid.qtyEmailDelivery>1000 ? numeral(this.billings.postpaid.qtyEmailDelivery).format(this.billings.prepaid.qtyEmailDelivery%1000==0 ? '0a' : '0.0a') : numeral(this.billings.postpaid.qtyEmailDelivery).format('0,0'))} <span style={{fontSize: '.9em', color: '#666'}}>Email</span>
                    </div>
                  </div>
                  <div style={{display: 'flex', textAlign: 'center', margin: '.3em 0'}}>
                    <div style={{flex: '1', background: '#fafafa', padding: '.25em 0'}}>
                      {this.billings && this.billings.postpaid && (this.billings.postpaid.qtyWhatsappDelivery>1000 ? numeral(this.billings.postpaid.qtyWhatsappDelivery).format(this.billings.prepaid.qtyWhatsappDelivery%1000==0 ? '0a' : '0.0a') : numeral(this.billings.postpaid.qtyWhatsappDelivery).format('0,0'))} <span style={{fontSize: '.9em', color: '#666'}}>Whatsapp</span>
                    </div>
                    <div style={{flex: '1', background: '#fafafa', padding: '.25em 0', borderLeft: '1px solid #ccc'}}>
                      {this.billings && this.billings.postpaid && (this.billings.postpaid.qtyTelegramDelivery>1000 ? numeral(this.billings.postpaid.qtyTelegramDelivery).format(this.billings.prepaid.qtyTelegramDelivery%1000==0 ? '0a' : '0.0a') : numeral(this.billings.postpaid.qtyTelegramDelivery).format('0,0'))} <span style={{fontSize: '.9em', color: '#666'}}>Telegram</span>
                    </div>
                  </div>
                </div>
              }
              inverted
              on={['hover']}
            />

            <Divider />
            <Popup  header='Social Media' content='Current usage of social traffic, private message through social media platform.' position='left center'
              trigger={
                <div style={{cursor: 'pointer'}}>
                  <Header as='h5' style={{margin: '0'}}>
                    <Icon name='share alternate' style={{fontSize: '1em'}}/>
                    <Header.Content>
                      Social Media
                      <Header.Subheader></Header.Subheader>
                    </Header.Content>
                  </Header>
                  <div style={{display: 'flex', textAlign: 'center', margin: '.3em 0'}}>
                    <div style={{flex: '1', background: '#fafafa', padding: '.25em 0'}}>
                      {this.billings && this.billings.postpaid && (this.billings.postpaid.qtyTwitterDelivery>1000 ? numeral(this.billings.postpaid.qtyTwitterDelivery).format(this.billings.prepaid.qtyTwitterDelivery%1000==0 ? '0a' : '0.0a') : numeral(this.billings.postpaid.qtyTwitterDelivery).format('0,0'))} <span style={{fontSize: '.9em', color: '#666'}}>Twitter</span>
                    </div>
                    <div style={{flex: '1', background: '#fafafa', padding: '.25em 0', borderLeft: '1px solid #ccc'}}>
                      {this.billings && this.billings.postpaid && (this.billings.postpaid.qtyFacebookDelivery>1000 ? numeral(this.billings.postpaid.qtyFacebookDelivery).format(this.billings.prepaid.qtyFacebookDelivery%1000==0 ? '0a' : '0.0a') : numeral(this.billings.postpaid.qtyFacebookDelivery).format('0,0'))} <span style={{fontSize: '.9em', color: '#666'}}>Facebook</span>
                    </div>
                  </div>
                  <div style={{display: 'flex', textAlign: 'center', margin: '.3em 0'}}>
                    <div style={{flex: '1', background: '#fafafa', padding: '.25em 0'}}>
                      {this.billings && this.billings.postpaid && (this.billings.postpaid.qtyInstagramDelivery>1000 ? numeral(this.billings.postpaid.qtyInstagramDelivery).format(this.billings.prepaid.qtyInstagramDelivery%1000==0 ? '0a' : '0.0a') : numeral(this.billings.postpaid.qtyInstagramDelivery).format('0,0'))} <span style={{fontSize: '.9em', color: '#666'}}>Instagram</span>
                    </div>
                  </div>
                </div>
              }
              inverted
              on={['hover']}
            />
          </div>}
        </>}
      </Segment>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingWidget)