import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, Label } from 'semantic-ui-react'
import Omniwidget from '../../../libraries/omniwidget/Omniwidget';

class WebwidgetBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, coachId: id})
    }
    
    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid style={{minHeight: screenHeight}}>
          <div style={{margin: '1.5em 1em'}}>
            <Header as='h4'>
              <Icon name='phone square' style={{fontSize: '1em'}}/>
              <Header.Content>
                Phone Widget
                {/* <Header.Subheader>Sample widget phone which embedded to your website/microsite.</Header.Subheader> */}
              </Header.Content>
            </Header>
            <Divider hidden />

            <div style={{display: 'flex', textAlign: 'center'}}>
              <div style={{flex: '1', padding: '2em'}}>
                <Card style={{margin: 'auto'}}>
                  <Card.Content>
                    <Header as='h2' icon>
                      <Header.Content>
                        <Label circular color='olive' style={{fontSize: '1em'}}>1</Label>
                      </Header.Content>
                    </Header>
                    
                    <div style={{minHeight: '12em', padding: '1em', fontSize: '1.3em'}}>
                      Customize widget phone user interface experience, through simply theme setting.
                    </div>

                    <Divider />
                    <Card.Header style={{fontSize: '2em'}}>Widget Theme</Card.Header>
                    <Card.Meta>Customize widget interface and experience from setting / configuratin page.</Card.Meta>
                  </Card.Content>
                </Card>
              </div>
              <div style={{flex: '1', padding: '2em'}}>
                <Card style={{margin: 'auto'}}>
                  <Card.Content>
                    <Header as='h2' icon>
                      <Header.Content>
                        <Label circular color='teal' style={{fontSize: '1em'}}>2</Label>
                      </Header.Content>
                    </Header>
                    
                    <div style={{minHeight: '12em', padding: '1em', fontSize: '1.3em'}}>
                      Makesure your Desk Agent is online and available. 
                      <Divider />
                      When widget phone starts request chat and call conversation, system will book Agent for future allocated dispatching.
                    </div>

                    <Divider />
                    <Card.Header style={{fontSize: '2em'}}>Desk Agent</Card.Header>
                    <Card.Meta>Start desk Agent  to do call or chat conversation simulation.</Card.Meta>
                  </Card.Content>
                </Card>
              </div>
              <div style={{flex: '1', padding: '2em'}}>
                <Card style={{margin: 'auto'}}>
                  <Card.Content>
                    <Header as='h2' icon>
                      <Header.Content>
                        <Label circular color='yellow' style={{fontSize: '1em'}}>3</Label>
                      </Header.Content>
                    </Header>
                    
                    <div style={{minHeight: '12em', padding: '1em', fontSize: '1.3em'}}>
                      Real time conversation recording from telephony to messaging.
                    </div>

                    <Divider />
                    <Card.Header style={{fontSize: '2em'}}>Conversation</Card.Header>
                    <Card.Meta>Check your conversation from historical telephony or messaging.</Card.Meta>
                  </Card.Content>
                </Card>
              </div>
            </div>
          </div>
          
          <Omniwidget params={{
            style: {
              width: 275, margin: '2em 1em', right: '0', bottom: '4em', position: 'fixed' 
            },
            mode: 'mini'
          }} />
        </Container>
      )
    }
}

export default WebwidgetBody
