import React, { Component } from "react"
import PropTypes from 'prop-types';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell } from 'recharts'

const schemes= [
  ['#7b4fb7', '#677bb1', '#47f77f', '#f47bb1', '#4466f4', '#ff7744', '#ff74f7', '#3b416b', '#ff4477', '#3b677b'],
  ['#bbf477', '#f47167', '#b37641', '#fb6b46', '#7f4b34', '#4b47ff', '#f676ff', '#b1ffff', '#bbfbff', '#bbb411'],
  ['#b16f1f', '#4b6f17', '#66fbf3', '#bb3f7b', '#4ff1b4', '#4416bf', '#7b17bf', '#b44fb7', '#1b3b7b', '#b431f1'],
  ['#bb664b', '#bb4b4b', '#6f4f4b', '#4b6f4f', '#b7b674', '#4b647f', '#b17164', '#b4b1b7', '#bbf4bf', '#f7b74b'],
  ['#47b4f4', '#4fffff', '#447417', '#3bb6b4', '#1b67f4', '#61f44b', '#b6636f', '#714bbf', '#ff64f4', '#63f476'],
  ['#fb4fb7', '#674bf1', '#b747ff', '#644bb1', '#743644', '#ff7734', '#3f74f7', '#bb417b', '#7f14f7', '#fb47fb'],
  ['#4bbfb7', '#674bb1', '#7747ff', '#f47fb1', '#446444', '#ff7444', '#ff44f7', '#3b416b', '#ff4f77', '#3b474f'],

  ['#bbf477', '#f47167', '#b37641', '#fb6b46', '#7f4b34', '#4b47ff', '#f676ff', '#b1ffff', '#bbfbff', '#bbb411'],
  ['#bb664b', '#bb4b4b', '#6f4f4b', '#4b6f4f', '#b7b674', '#4b647f', '#b17164', '#b4b1b7', '#bbf4bf', '#f7b74b'],
  ['#fb4fb7', '#674bf1', '#b747ff', '#644bb1', '#743644', '#ff7734', '#3f74f7', '#bb417b', '#7f14f7', '#fb47fb'],
]

const dataDummy = [
  {name: 'Group A', value: 400, amount: 100, qty: 150}, 
  {name: 'Group B', value: -300, amount: 100, qty: 150},
  {name: 'Group C', value: 100, amount: -100, qty: 150}, 
  {name: 'Group D', value: 200, amount: 100, qty: 150}
]
const legendDummy = [
  {id: 'value', name: 'Value'}, 
  {id: 'amount', name: 'Amount'}, 
  {id: 'qty', name: 'Qty'}, 
]

const getPath = (x, y, width, height) => {
  return `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + 2 * width / 3},${y + height} ${x + width}, ${y + height}
          Z`;
};

class TriangleBar extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  render() {
    const { fill, x, y, width, height } = this.props;
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill}/>;
  }
}

TriangleBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

class CustomizedLabel extends React.Component {
  constructor(props) {
    super(props)
    this.state={}
  }
  render () {
    // console.log(this.props)
    const {x, y, height, width, value} = this.props;
   	return <>
     <text x={x+width/2} y={y} dy={value<0 ? -15 : 20} fill={'#000'} fontSize={12} textAnchor="middle">{value}</text>
     </>
  }
};

class CustomizedAxisTick extends React.Component{
  constructor(props) {
    super(props)
    this.state={}
  }

  render () {
    const {x, y, stroke, payload} = this.props;
   	return (
    	<g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-65)">{payload.value}</text>
      </g>
    );
  }
};

class BarRechart extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
	render () {
    const { data, dataKey, legend, width, height, mode, fill, label, yAxis }= this.props
    const colors= schemes[Math.floor((Math.random() * 10))]

    let i= 0;
    if (mode=='tiny') { 
      return(
        <div>
          <div style={{ width: width || 'auto', height: height || 250, margin: '.5em auto' }}>
            <ResponsiveContainer>
              <BarChart data={data || dataDummy} width={width || 150} height={ height || 50} >
                <XAxis dataKey="name"/>
                <Tooltip />
                {
                  (legend || legendDummy).map((value, i)=>{
                    return(
                      <Bar key={i} dataKey={value.id} name={value.name} fill={colors[i++]}/>
                    )
                  })
                }
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    } else if (mode=='simple') { 
      return(
        <div>
          <div style={{ width: width || 'auto', height: height || 250, margin: '.5em auto' }}>
            <ResponsiveContainer>
              <BarChart data={data || dataDummy} width={width || 150} height={ height || 250} margin={{top: 5, right: 30, left: yAxis!==false ? 5 : 30, bottom: 20}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name" tick={<CustomizedAxisTick/>}/>
                {(yAxis!==false) && <YAxis/>}
                <Tooltip/>
                <Legend />
                {
                  (legend || legendDummy).map((value, i)=>{
                    return(
                      <Bar key={i} dataKey={value.id} name={value.name} fill={colors[i++]} label={label && <CustomizedLabel />}/>
                    )
                  })
                }
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    } else if (mode=='stack') { 
      return(
        <div>
          <div style={{ width: width || 'auto', height: height || 250, margin: '.5em auto' }}>
            <ResponsiveContainer>
              <BarChart data={data || dataDummy} width={width || 150} height={ height || 250} margin={{top: 5, right: 30, left: yAxis!==false ? 5 : 30, bottom: 20}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name" tick={<CustomizedAxisTick/>}/>
                {(yAxis!==false) && <YAxis/>}
                <Tooltip/>
                <Legend />
                {
                  (legend || legendDummy).map((value, i)=>{
                    return(
                      <Bar key={i} dataKey={value.id} stackId='a' name={value.name} fill={colors[i++]} label={label && <CustomizedLabel />}/>
                    )
                  })
                }
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    } else if (mode=='shape') { 
      return(
        <div>
          <div style={{ width: width || 'auto', height: height || 250, margin: '.5em auto' }}>
            <ResponsiveContainer>
              <BarChart data={data || dataDummy} width={width || 150} height={ height || 250} margin={{top: 5, right: 30, left: yAxis!==false ? 5 : 30, bottom: 20}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name" tick={<CustomizedAxisTick/>}/>
                {(yAxis!==false) && <YAxis/>}
                <Tooltip/>
                <Legend />
                {
                  (legend || legendDummy).map((value, i)=>{
                    return(
                      <Bar key={i} dataKey={value.id} name={value.name} fill={colors[i++]} shape={<TriangleBar/>} label={label && <CustomizedLabel />}/>
                    )
                  })
                }
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    }
  }
}

export default BarRechart;
