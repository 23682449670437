import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { connect } from "react-redux"
import { browseMessage } from "../../actions/halo/messageAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    messageStore: state.messageStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseMessage:(objParam) => dispatch(browseMessage(objParam)),
  }
}

class NotificationWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {};
  }

  componentDidMount() {
    const { search, paging }= (this.props.messageStore.browseMessage!==undefined ? this.props.messageStore.browseMessage : {})
    this.props.browseMessage({search: {...search, stamp: format(new Date(), 'yyyy-MM-dd')}, paging: paging})
  }
  
  render() {
    const { screenWidth,  screenHeight}= this.props
    const { data, search, paging }= (this.props.messageStore.browseMessage!==undefined ? this.props.messageStore.browseMessage : {})

    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <Header as='h5'>
          <Icon name='bell outline' style={{fontSize: '1em'}} />
          <Header.Content>
            Notification
          </Header.Content>
        </Header>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em'}}>
        <Feed>
          {data && data.length==0 &&
            <Header as='h5' color='red'>
              <Header.Content>
                No message available
                <Header.Subheader></Header.Subheader>
              </Header.Content>
            </Header>
          }
          {data && data.map((item, i)=>{
            if (i==0 || (format(new Date(new Date().getTime()-(1000*24*60*60)), 'yyyy-MM-dd')<=item.stamp)) { 
              return(
                <Feed.Event key={i}>
                  <Feed.Label style={{marginTop: '.5em'}}><Icon name='bell outline' circular inverted color={(format(new Date(new Date().getTime()), 'yyyy-MM-dd')>item.stamp && 'grey') || (item.priority=='1' && 'red') || (item.priority=='2' && 'orange') || (item.priority=='3' && 'yellow') || 'teal'}/></Feed.Label>
                  <Feed.Content style={{marginTop: '-.25em'}}>
                    <Feed.Summary>
                      <Feed.Event style={{fontSize: '.9em', margin: '0', color: '#000'}}>
                        <b>{item.stamp!=null ? format(parse(item.stamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'hh:mm, dd / MMM yy') : 'No schedule'}</b>
                      </Feed.Event>
                      <Feed.Meta style={{fontSize: '1.5em', margin: '.1em 0 0 -.3em', color: '#0069b8', background: '#f3ff9f', padding: '0.3em 0.5em 0.4em 0.5em', borderRadius: '1em'}}>
                        {item.title}
                      </Feed.Meta>
                    </Feed.Summary>
                    <Feed.Extra text>
                      {item.content || 'No info'}
                      {/* {format(new Date(new Date().getTime()-(1000*24*60*60)), 'yyyy-MM-dd')} / {item.stamp} */}
                    </Feed.Extra>
                    {/* <Feed.Meta><Feed.Like><Icon name='like' />5 Likes</Feed.Like></Feed.Meta> */}
                  </Feed.Content>
                </Feed.Event>
              )
            } else {
              return(<div key={i}></div>);
            }
          })}
        </Feed>
      </Segment>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationWidget)