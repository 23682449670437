import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider } from 'semantic-ui-react'

import ReportingSearch from '../../libraries/stream/ReportingSearch';
import ReportingProcess from '../../libraries/stream/ReportingProcess';
import ReportingDownload from '../../libraries/stream/ReportingDownload';


class ReportingBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        userId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, userId: id})
    }

    render() {
      const {mode, userId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid  style={{minHeight: '100%'}}>
          <div style={{display: 'flex', minHeight: '100%'}}>
            <div style={{flex: '1', minHeight: '100%', background: '#fcfcfc'}}>
              <ReportingSearch setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
              <ReportingDownload setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
            </div>
            {/* <div style={{width: '350px', padding: '1em', margin: '0 -.5em 0 0', minHeight: '100%', background: '#f5f5f5', borderLeft: '1px solid #ccc'}}>
              <ReportingProcess setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
            </div> */}
          </div>
        </Container>
      )
    }
}

export default ReportingBody
