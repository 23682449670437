import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import TargetBrowse from '../../libraries/stream/TargetBrowse'
import TargetForm from '../../libraries/stream/TargetForm'
import TargetSearch from '../../libraries/stream/TargetSearch'


class TargetBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        targetId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, targetId: id})
    }

    render() {
      const {mode, targetId}= this.state
      const {screenWidth, screenHeight, campaignId, campaign, clientId}= this.props
      
      return(
        <Container fluid>
            <TargetSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <TargetBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} campaignId={campaignId} campaign={campaign}  clientId={clientId} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
              {mode==2 && <TargetForm mode={mode} targetId={targetId} setMode={this.setMode.bind(this)} campaignId={campaignId} campaign={campaign}  clientId={clientId} />}
              {mode==3 && <TargetForm mode={mode} targetId={0} setMode={this.setMode.bind(this)} campaignId={campaignId} campaign={campaign} clientId={clientId} />}
            </div>
        </Container>
      )
    }
}

export default TargetBody
