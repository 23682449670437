import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid  , Icon } from 'semantic-ui-react'

import { accessOptions, channelOptions, packageOptions, billingOptions, yesnoOptions, durationOptions, campaignOptions, ratioOptions, modulesOptions, runningOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { ImageUploadField, LabelInputField, NumberField, InputField, CheckboxField, SelectField, DatePickerField, LabelNumberField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
import { connect } from "react-redux"
import { resetClient, getClient,updateClient, createClient } from "../../actions/halo/clientAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    clientStore: state.clientStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetClient:(objParam) => dispatch(resetClient(objParam)),
    getClient:(objParam) => dispatch(getClient(objParam)),
    createClient:(objParam) => dispatch(createClient(objParam)),
    updateClient:(objParam) => dispatch(updateClient(objParam)),
  }
}

class ClientForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    this.props.resetClient(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getClient({clientId: this.props.clientId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.clientStore.updateClient!=undefined ? this.props.clientStore.updateClient : {}) : (this.props.clientStore.createClient!=undefined ? this.props.clientStore.createClient : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    mode==2 ? this.props.updateClient({...values}) : this.props.createClient({...values})
  }
  

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.clientStore.updateClient!=undefined ? this.props.clientStore.updateClient : {}) : (this.props.clientStore.createClient!=undefined ? this.props.clientStore.createClient : {}))

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='equal' >
              <Form.Field>
                <Field name='avatar' component={ImageUploadField}
                width={300} 
                height={300} 
                aspectRation={3/3} 
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='isEnabled' component={CheckboxField}
              toggle
              width={4}
              label='Enable Now !'
              placeholder='Status enable/disabled' />
            </Form.Group>
          </Segment>

          <Segment>
            <Header as='h5'>
              <Icon name='calendar alternate outline' style={{fontSize: '1em'}}/>
              <Header.Content>Contract / Legal</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='contractRecurring' component={SelectField}
              options={yesnoOptions()}
              label='Contract Recurring'
              placeholder='Contract Recurring' />
              <Field name='contractDuration' component={SelectField}
              options={durationOptions()}
              label='Contract Duration'
              placeholder='Contract Duration' />
              <Field name='contractDate' component={DatePickerField}
              label='Contract Date'
              placeholder='Contract Date' />
              <Field name='contractExpired' component={DatePickerField}
              label='Contract Expired'
              placeholder='Contract Expired' />
            </Form.Group>
          </Segment>
          
          <Segment>
            <Header as='h5'>
              <Icon name='file alternate outline' style={{fontSize: '1em'}}/>
              <Header.Content>Subscription Package</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='packageId' component={SelectField}
              width={4}
              options={packageOptions}
              label='Service Package'
              placeholder='Service Package' />
              <Field name='billingType' component={SelectField}
              width={4}
              options={billingOptions}
              label='Billing Type'
              placeholder='Billing Type' />
              <Field name='svcAccess' component={SelectField}
              options={accessOptions}
              multiple
              width={4}
              label='Application Access'
              placeholder='App Access' />
              <Field name='svcRunning' component={SelectField}
              options={runningOptions()}
              width={4}
              label='Running Mode'
              placeholder='Service Running Mode' />
            </Form.Group>
          </Segment>
          <Segment>
            <Form.Group widths='16'>
              <Field name='mauLicense' component={LabelNumberField}
              width={4}
              caption='MAU License'
              label= 'Users'
              placeholder='License of Monthly Active User' />
              <Field name='mauReserved' component={LabelNumberField}
              width={4}
              caption='MAU Reserved'
              label= 'Users'
              placeholder='Reserved of Monthly Active User' />
              <Field name='callConcurrent' component={LabelNumberField}
              width={4}
              caption='Call Lines / Tunnel'
              label= 'Lines'
              placeholder='Concurrent Call Lines' />
              <Field name='waitingConcurrent' component={LabelNumberField}
              width={4}
              caption='Waiting Lines / Tunnel'
              label= 'Lines'
              placeholder='Concurrent Call Waiting Lines' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='halopagiModules' component={SelectField}
              multiple
              options= {modulesOptions('halopagi')}
              label='HaloPagi! Modules'
              placeholder='HaloPagi! Modules' />
              <Field name='ovisoneModules' component={SelectField}
              multiple
              options= {modulesOptions('ovisone')}
              label='Ovisone Modules'
              placeholder='Ovisone Modules' />
            </Form.Group>
          </Segment>

          <Segment>
            <Header as='h5'>
              <Icon name='share alternate' style={{fontSize: '1em'}}/>
              <Header.Content>Service Delivery</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='svcChannels' component={SelectField}
              multiple
              options= {channelOptions()}
              label='Media Channels'
              placeholder='Media Channels' />
              <Field name='svcRatios' component={SelectField}
              multiple
              options= {ratioOptions}
              label='Attempt Ratios'
              placeholder='Attempt Ratios' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='svcCampaigns' component={SelectField}
              multiple
              width={8}
              options= {campaignOptions}
              label='Campaign Types'
              placeholder='Campaign Types' />
              <Form.Field width={8}>
                <Form.Group widths='equal'>
                  <Field name='svcPredialed' component={SelectField}
                  options={yesnoOptions()}
                  width={4}
                  label='Preview'
                  placeholder='Manual' />
                  <Field name='svcProgressive' component={SelectField}
                  options={yesnoOptions()}
                  width={4}
                  label='Progressive'
                  placeholder='Progressive' />
                  <Field name='svcPredictive' component={SelectField}
                  options={yesnoOptions()}
                  width={4}
                  label='Predictive'
                  placeholder='Predictive' />
                </Form.Group>
              </Form.Field>
            </Form.Group>
          </Segment>
          
          <Segment>
            <Header as='h5'>
              <Icon name='user outline' style={{fontSize: '1em'}}/>
              <Header.Content>Customer Contact</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='name' component={InputField}
              label='Name'
              placeholder='Full Name' />
              <Field name='quote' component={InputField}
              label='Tagline'
              placeholder='Quote / Tagline' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='mobile' component={InputField}
              label='Mobile'
              placeholder='Handphone' />
              <Field name='email' component={InputField}
              label='Email'
              placeholder='Email' />
            </Form.Group>
          </Segment>
          
          <Segment>
            <Header as='h5'>
              <Icon name='building outline' style={{fontSize: '1em'}}/>
              <Header.Content>Corporate Information</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='company' component={InputField}
              fluid
              width={8}
              label='Name'
              placeholder='Company' />
              <Field name='brand' component={InputField}
              fluid
              width={4}
              label='Brand / Code'
              placeholder='Brand / Code' />
              <Field name='business' component={InputField}
              fluid
              width={4}
              label='Business Stream'
              placeholder='Business Stream' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='address' component={InputField}
              fluid
              label='Address'
              placeholder='Address' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='city' component={InputField}
              width={6}
              placeholder='City' />
              <Field name='province' component={InputField}
              width={6}
              placeholder='Province' />
              <Field name='country' component={InputField}
              width={4}
              placeholder='Country' />
            </Form.Group>
          </Segment>
          
          {/* <Segment>
            <Header as='h5'>
              <Icon name='qrcode' style={{fontSize: '1em'}}/>
              <Header.Content>Credential</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='account' component={InputField}
              label= 'Account'
              placeholder='Account' />
              <Field name='groupId' component={InputField}
              readOnly
              type='Group'
              label= 'Group'
              placeholder='Group' />
            </Form.Group>
          </Segment> */}
          
          <Segment>
            <Header as='h5'>
              <Icon name='th' style={{fontSize: '1em'}}/>
              <Header.Content>Joining PIN Credential</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='pin' component={InputField}
              label='PIN For Agents To Join' />
              <Field name='pinExpired' component={DatePickerField}
              label='PIN Expired Date' />
            </Form.Group>
          </Segment>

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Client' : 'Insert Client') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.clientStore.getClient!=undefined && state.clientStore.getClient.progress==1 ? state.clientStore.getClient.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'ClientForm',	// a unique identifier for this form
  })(ClientForm)
  )
)