export default function campaignReducer (state = {}, action) {
  switch (action.type) {
    case 'CAMPAIGN_RESET': {
        return({
            ...state,
            getCampaign: {},
            updateCampaign: {},
            createCampaign: {},
        })
    }

    case 'CAMPAIGN_BROWSE_PENDING': {
      return({...state, browseCampaign: {progress: 0}})
    }
    case 'CAMPAIGN_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseCampaign: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseCampaign: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CAMPAIGN_BROWSE_REJECTED': {
        return({...state, browseCampaign: {progress: 2, payload: action.payload}})
    }
    
    case 'CAMPAIGN_GET_PENDING': {
        return({...state, getCampaign: {progress: 0}})
    }
    case 'CAMPAIGN_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getCampaign: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getCampaign: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CAMPAIGN_GET_REJECTED': {
        return({...state, getCampaign: {progress: 2, payload: action.payload}})
    }

    case 'CAMPAIGN_SUMMARYUS_PENDING': {
      return({...state, statusCampaign: {progress: 0}})
    }
    case 'CAMPAIGN_SUMMARYUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusCampaign: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusCampaign: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CAMPAIGN_SUMMARYUS_REJECTED': {
        return({...state, statusCampaign: {progress: 2, payload: action.payload}})
    }

    case 'CAMPAIGN_CREATE_PENDING': {
      return({...state, createCampaign: {progress: 0}})
    }
    case 'CAMPAIGN_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createCampaign: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createCampaign: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CAMPAIGN_CREATE_REJECTED': {
        return({...state, createCampaign: {progress: 2, payload: action.payload}})
    }

    case 'CAMPAIGN_UPDATE_PENDING': {
      return({...state, updateCampaign: {progress: 0}})
    }
    case 'CAMPAIGN_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateCampaign: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateCampaign: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CAMPAIGN_UPDATE_REJECTED': {
        return({...state, updateCampaign: {progress: 2, payload: action.payload}})
    }

    case 'CAMPAIGN_REMOVE_PENDING': {
      return({...state, removeCampaign: {progress: 0}})
    }
    case 'CAMPAIGN_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeCampaign: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeCampaign: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CAMPAIGN_REMOVE_REJECTED': {
        return({...state, removeCampaign: {progress: 2, payload: action.payload}})
    }
    
    case 'CAMPAIGN_SUMMARY_PENDING': {
      return({...state, summaryCampaign: {progress: 0}})
    }
    case 'CAMPAIGN_SUMMARY_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, summaryCampaign: {progress: 2, payload: action.payload}})
      } else {
          return({...state, summaryCampaign: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'CAMPAIGN_SUMMARY_REJECTED': {
      return({...state, summaryCampaign: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}