import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, Label } from 'semantic-ui-react'

import AgentModeBody from './AgentModeBody';

class RealmAgentModeBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, coachId: id})
    }
    
    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid style={{minHeight: screenHeight}}>
          <div style={{padding: '1em .5em'}}>
            <AgentModeBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} module={module} />
          </div>

          <div style={{padding: '3em 1.5em', background: '#f5f5f5', borderTop: '1px solid #ccc'}}>
            <div style={{display: 'flex', textAlign: 'center'}}>
              <div style={{flex: '1', padding: '2em 2em'}}>
                <Card style={{margin: 'auto'}}>
                  <Card.Content>
                    <Header as='h2' icon>
                      <Header.Content>
                        <Label circular color='olive' style={{fontSize: '1em'}}>1</Label>
                      </Header.Content>
                    </Header>
                    
                    <div style={{minHeight: '6em', padding: '1em', fontSize: '1.3em'}}>
                      Available agents which configured in your account's realm.
                    </div>

                    <Divider />
                    <Card.Header style={{fontSize: '2em'}}>List Agent</Card.Header>
                    <Card.Meta>Show and list available agents.</Card.Meta>
                  </Card.Content>
                </Card>
              </div>
              <div style={{flex: '1', padding: '2em 2em'}}>
                <Card style={{margin: 'auto'}}>
                  <Card.Content>
                    <Header as='h2' icon>
                      <Header.Content>
                        <Label circular color='teal' style={{fontSize: '1em'}}>2</Label>
                      </Header.Content>
                    </Header>
                    
                    <div style={{minHeight: '6em', padding: '1em', fontSize: '1.3em'}}>
                      Click to transfer your account to destination agent for simulation.
                    </div>

                    <Divider />
                    <Card.Header style={{fontSize: '2em'}}>Pick Agent</Card.Header>
                    <Card.Meta>Choose selected agent's account.</Card.Meta>
                  </Card.Content>
                </Card>
              </div>
              <div style={{flex: '1', padding: '2em 2em'}}>
                <Card style={{margin: 'auto'}}>
                  <Card.Content>
                    <Header as='h2' icon>
                      <Header.Content>
                        <Label circular color='yellow' style={{fontSize: '1em'}}>3</Label>
                      </Header.Content>
                    </Header>
                    
                    <div style={{minHeight: '6em', padding: '1em', fontSize: '1.3em'}}>
                      Your account will be changed to certain active agent.
                    </div>

                    <Divider />
                    <Card.Header style={{fontSize: '2em'}}>Simulate</Card.Header>
                    <Card.Meta>Click to simulate as certain agent.</Card.Meta>
                  </Card.Content>
                </Card>
              </div>
            </div>
          </div>
        </Container>
      )
    }
}

export default RealmAgentModeBody
