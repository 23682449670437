import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'

import MenuLeftMiddleware from '../../../libraries/realm/halo/MenuLeftMiddleware'
import ModalContainer from '../../../libraries/common/ModalContainer';

import DashboardBody from '../middleware/DashboardBody';

import ConversationBody from '../middleware/ConversationBody'
import ApilogBody from '../middleware/ApilogBody';

import OTPServiceBody from '../middleware/OTPServiceBody';
import CommServiceBody from '../middleware/CommServiceBody';

import APIInventoryBody from '../middleware/APIInventoryBody';
// import APIServiceBody from '../../middleware/APIServiceBody';


import { connect } from "react-redux"
import { checkToken } from "../../../actions/auth/authAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),
  }
}

class RealmMiddlewareBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
      mode: 'dashboard',
    }
  }

  componentDidMount() {
    this.props.checkToken();
  }

  componentDidUpdate() {
    
  }

  render() {
    const { mode, module, screenWidth, screenHeight, mini, modal }= this.props
    const { data }= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken : {}
    
    return (
      <div>
        <div>
            <div style={{display: 'flex'}}>
              <div style={{background: '#ccc', minHeight: screenHeight-300, borderRight: '1px solid #fff'}}>
                <MenuLeftMiddleware openWindow={this.props.openWindow.bind(this)} mode={this.props.mode} screenHeight={screenHeight} screenWidth={screenWidth} module={module} mini={mini} setModule={this.props.setModule.bind(this)}/>
              </div>
              <div style={{flex: '1', marginLeft: mini ? '2px' : '80px', marginRight: '2px', padding: '.85em .5em'}}>
              
                {mode==='dashboard' && <DashboardBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}

                {mode==='traffic' && <ConversationBody  openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} checkToken={data} />}
                {mode==='API logger' && <ApilogBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}

                {mode==='OTP service' && <OTPServiceBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
                {mode==='Comm service' && <CommServiceBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}

                {mode==='API inventory' && <APIInventoryBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
                {/* {mode==='API service' && <APIServiceBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />} */}
              </div>
            </div>
        </div>
      </div>
    )
  }
}

// export default RealmMiddlewareBody
export default connect(mapStateToProps, mapDispatchToProps)(RealmMiddlewareBody)