import React, { Component } from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Label, Dropdown } from 'semantic-ui-react'

import { Link } from 'react-router-dom'
import {withRouter} from "react-router-dom"

import { connect } from "react-redux"
import { signOut } from "../../actions/auth/authAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut:(objParam) => dispatch(signOut(objParam))
  }
}

const styleButton={
  margin: '.3em .1em',
  border: 'none',
}

class AdminHeader extends Component {
  constructor(props) {
    super(props);
    this.state={
      
    }
  }

  openDrawer() {
    this.props.openDrawer()
  }

  doSignOut(values) {
    this.props.signOut(values)
    this.props.history.push('/signin')
  }

  openWindow(value) {
    const { screenWidth,  screenHeight, mini }= this.props

    this.props.openWindow(value)
    if (!mini) {
      this.props.openDrawer()
    }
  }

  componentDidMount() {
    const tokenAuth= localStorage.getItem('tokenAuth');
    if (tokenAuth==undefined || tokenAuth=='') {
      this.props.history.push('/signin')
    }
  }

  componentDidUpdate() {
    if (localStorage.getItem('tokenAuth')=='') {
      this.props.history.push('/signin');
    }
  }
  
  render() {
    const { screenWidth,  screenHeight, mini }= this.props
    
    return (
      <div style={{marginLeft: '5em'}}>
        <Menu fixed='top'  style={{padding: '0', border: 'none', boxShadow: 'none', background : 'none'}}>
          <div style={{display: 'flex', width: '100%', background : 'rgb(0, 0, 0)'}}>
            <div style={{padding: '.3em 0', textAlign: 'center'}}>
              <Label as='a' style={{border: 'none', background: 'none', padding: '.2 .75em', border: '0px solid rgba(100, 100, 100, .3)'}} onClick={this.openDrawer.bind(this)}>
                <Icon name='bars' style={{fontSize: '1.75em', margin: '0'}} color={mini==true ? 'grey' : 'yellow'} />
              </Label>
            </div>
            <div style={{padding: '1em 0 0 1em'}}>
              <Header as='h5' color='grey' inverted>
                <Header.Content>
                  Super User Console
                </Header.Content>
              </Header>
            </div>
            <div style={{flex: '1', textAlign: 'right', paddingTop: '.25em', marginRight: '1em'}}>
              {/* <Button onClick={this.openWindow.bind(this,'setting')} basic={this.props.mode!='setting'} color={this.props.mode=='setting' ? 'yellow' : null} style={styleButton} icon><Icon name='setting' style={{color: this.props.mode=='setting' ? '#000' : '#fff'}} /></Button> */}
              <Dropdown className='icon' style={{marginRight: '1em', color: '#fff', fontSize: '1.1em'}} text='Setting'>
                <Dropdown.Menu style={{background: '#fafafa', fontWeight: 'bold', padding: '.5em .1em .5em .1em', margin: '.5em, 0'}}>
                  <Dropdown.Item onClick={this.props.openModal.bind(this,'rates')}><Icon name='file alternate outline' color='red'/>Rates</Dropdown.Item>
                  <Divider />
                  <Dropdown.Item onClick={this.props.openModal.bind(this,'client')}><Icon name='building outline' color='red'/>Client</Dropdown.Item>
                  <Dropdown.Item onClick={this.props.openModal.bind(this,'setting')}><Icon name='setting' color='red'/>Setting</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Popup position= 'bottom right' 
                trigger={<Button onClick={this.props.openModal.bind(this,'profile')} basic={this.props.modal!='profile'} color={this.props.modal=='profile'? 'yellow' : null} style={styleButton} icon><Icon name='user outline' style={{color: this.props.modal=='profile' ? '#000' : '#fff'}}/></Button>}
                content='About Me !'
                inverted
                on={['hover']}
              />
              <Popup position= 'bottom right' 
                trigger={<Button onClick={this.props.openModal.bind(this,'account')} basic={this.props.mode!='account'} color={this.props.mode=='account'? 'yellow' : null} style={styleButton} icon><Icon name='user circle outline' style={{fontSize: '1.3em', color: this.props.mode=='account' ? '#000' : '#fff'}}/></Button>}
                content='Account'
                inverted
                on={['hover']}
              />
              <Popup position= 'bottom right' 
                trigger={<Button onClick={this.props.openModal.bind(this,'message')} basic={this.props.mode!='message'} color={this.props.mode=='message'? 'yellow' : null} style={styleButton} icon><Icon name='bell' style={{color: this.props.mode=='message' ? '#000' : '#fff'}} /></Button>}
                content='Notification'
                inverted
                on={['hover']}
              />
              {/* <Popup
                trigger={<Button onClick={this.openWindow.bind(this,'provider')} basic={this.props.mode!='provider'} color={this.props.mode=='provider'? 'yellow' : null} style={styleButton} icon><Icon name='heart outline'/></Button>}
                content='Provider'
                inverted
                on={['hover']}
              />
              <Popup
                trigger={<Button onClick={this.openWindow.bind(this,'api')} basic={this.props.mode!='api'} color={this.props.mode=='api'? 'yellow' : null} style={styleButton} icon><Icon name='rocket' /></Button>}
                content='API'
                inverted
                on={['hover']}
              /> */}
            </div>
            <div style={{paddingTop: '.25em', marginRight: '1em'}}>
              <Popup position= 'bottom right' 
                trigger={<Button onClick={()=>this.doSignOut()} basic={this.props.mode!='signout'}  color={this.props.mode=='signout'? 'yellow' : null} style={styleButton} icon><Icon name='sign-out' style={{fontSize: '1.3em', color: this.props.mode=='signout' ? '#000' : '#fff'}} /></Button>}
                content='Signout'
                inverted
                on={['hover']}
              />
              {/* <Button onClick={()=>this.doSignOut()} style={{marginRight: '1.15em'}} size='tiny' color='red'>Sign Out</Button> */}
            </div>
          </div>
        </Menu>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminHeader))