import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import TrespassingBrowse from '../../libraries/halo/TrespassingBrowse'
import TrespassingForm from '../../libraries/halo/TrespassingForm'
import TrespassingSearch from '../../libraries/halo/TrespassingSearch'


class TrespassingBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        trespassingId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, trespassingId: id})
    }

    closeModal() {
      this.setState({
        mode: 1,
        trespassingId: 0,
      });
      this.props.closeModal() 
    }

    render() {
      const {mode, trespassingId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div>
            <TrespassingSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {<TrespassingBrowse setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}/>}
              {/* {mode==2 && <TrespassingForm mode={mode} trespassingId={trespassingId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <TrespassingForm mode={mode} trespassingId={0} setMode={this.setMode.bind(this)} />} */}
              {mode==2 && <ModalContainer size='large' content={<><TrespassingSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} /><TrespassingForm mode={mode} trespassingId={trespassingId} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} /></>} closeModal={this.closeModal.bind(this)} /> }
              {mode==3 && <ModalContainer size='large' content={<><TrespassingSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} /><TrespassingForm mode={mode} trespassingId={0} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} /></>} closeModal={this.closeModal.bind(this)} /> }
            </div>
          </div>
        </Container>
      )
    }
}

export default TrespassingBody
