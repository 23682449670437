import React,{Component} from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
  ModalDescription,
  Modal,
  Progress,
} from 'semantic-ui-react'

import JsSIP from 'jssip'

import dialtone from '../../sound/tone.mp3'
import busytone from '../../sound/busy.mp3'
import ringtone from '../../sound/ring.mp3'
import jointone from '../../sound/join.mp3'

import EmulatorHeader from './EmulatorHeader';
import EmulatorFooter from './EmulatorFooter';

import EmulatorOncall from './EmulatorOncall';
import EmulatorOnline from './EmulatorOnline';
import EmulatorOffline from './EmulatorOffline';
import EmulatorIncoming from './EmulatorIncoming';

import { connect } from "react-redux"
import { socketStatus, socketAgentStatus } from "../../actions/socket/socketAction"

const callOptions = {
  extraHeaders: [],
  mediaConstraints: { audio: true, video: false },
  // mediaConstraints: { audio: true, video: true },
}

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketStatus:(objParam) => dispatch(socketStatus(objParam)),
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),
  }
}

var ua;
var sessions= new Map();
const dial= new Audio(dialtone);
const busy= new Audio(busytone);
const ring= new Audio(ringtone);
const join= new Audio(jointone);


class DialTone {
  play() {
    dial.loop= true
    dial.muted= false;
    dial.play();  
  }
  
  stop() {
    dial.pause();
  }

  mute() {
    dial.muted= true;
  }
}

class BusyTone {
  play() {
    busy.muted= false;
    busy.play();
  }
  
  stop() {
    busy.pause();
  }

  mute() {
    busy.muted= true;
  }
}

class RingTone {
  play() {
    ring.loop= true;
    ring.muted= false;
    ring.play();  
  }
  
  stop() {
    ring.muted= true;
    ring.pause();
  }
}

class JoinTone {
  play() {
    join.loop= false;
    join.muted= false;
    join.play();  
  }
  
  stop() {
    join.muted= true;
    join.pause();
  }
}

class Emulator extends Component {
  constructor(props){
    super(props)
    this.state = {
      // sipAccount: '1001',
      // sipPasswd: '1001@d4nk0m2020',
      // sipUri: 'sip:?@174.138.19.28:4063', DISCARD
      // sipHost: '174.138.19.28:4061/', //ws://174.138.19.28:4061/
      // sipHost: 'call.halopagi.com:4063/', //wss://call.halopagi.com:4063/
      // sipRealm: 'halopagi',

      init: false,
      uiMode: [],
      statusUA: 0, //0: disconnected, 1: connecting, 2: connected, 3: registered, 4: no access / account not valid / FAILED
      dialMode: false,
      phoneMode: 'offline', //offline, registered, online, occupied
      phoneState: 'disconnected', //disconnected, connecting, connected, registered, incoming, online, dialing, occupied, 
      text: null,

      layout: 'mini', //'mini', 'normal', 'floating'
      ringDuration: 5,
    }

    this.dial= new DialTone();
    this.busy= new BusyTone();
    this.ring= new RingTone();
    this.join= new JoinTone();
  }

  setSkinMode(layout) {
    this.setState({
      layout: layout
    })
  }
  
  sipRegister(account, passwd, host, realm) {
    var sipAccount, sipPasswd, sipHost, sipRealm;

    if (account!=null && passwd!=null && host!=null && realm!=null) {
      sipAccount= account
      sipPasswd= passwd
      sipHost= host
      sipRealm= realm
    } else {
      sipAccount= this.state.sipAccount
      sipPasswd= this.state.sipPasswd
      sipHost= this.state.sipHost
      sipRealm= this.state.sipRealm
    }

    this.setState({
      uiMode: [],
      statusUA: 1,
      phoneMode: 'offline',
    })
    this.props.saveLog({
      stamp: new Date(),
      text: 'Phone is on OFFLINE'
    })
    
    var socket = new JsSIP.WebSocketInterface("wss://"+ sipHost +"/");
    socket.via_transport = "udp";
    // console.log(socket)
    // this.socketEventHandler(socket)

    var configuration = {
      sockets  : [ socket ],
      uri      : "sip:"+ sipAccount +"@"+sipHost,
      password : sipPasswd,
      session_timers: false,
      iceServers: [],
      use_preloaded_route: true,


    };
    
    if (ua!=undefined) {
      ua.stop();
    } 

    ua = new JsSIP.UA(configuration);
    // console.log('!!! UA', ua)
    
    ua.start();
    this.sipEventHandler(ua)
  }

  sipUnregister() {
    // console.log('!!! UA', ua.isRegistered(), ua.isConnected(), ua)
    if (ua!=undefined) {
      // console.log('!!! UA', 'STOP')
      ua.stop()
    }
  }

  socketEventHandler(socket) {
    // socket.on('onconnect', function () {
    //   // console.log('!!! SOCKET ON CONNECT: ');
    // });
    // socket.on('ondisconnect', function (error, code, reason) {
    //   // console.log('!!! SOCKET ON DISCONNECT: ', error);
    // });
    // socket.on('ondata', function (data) {
    //   // console.log('!!! SOCKET ON DATA: ', data);
    // });
  }

  sipEventHandler(ua) {
    const parent= this;

    //CONNECTION EVENT
    ua.on("connecting", function(data){
      // console.log("!!! CONNECTING", data);
      parent.setState({
        statusUA: 1,
        phoneState: 'connecting',
      })
      parent.props.saveLog({
        stamp: new Date(),
        text: 'Phone is on connecting'
      })
    });
    ua.on("connected", function(data){
      // console.log("!!! CONNECTED", data);
      parent.setState({
        statusUA: 2,
        phoneState: 'connected',
      })
      parent.props.saveLog({
        stamp: new Date(),
        text: 'Phone is on connected'
      })
    });
    ua.on("disconnected", function(data){
      // console.log("!!! DISCONNECTED", data);
      parent.setState({
        statusUA: 0,
        phoneState: 'disconnected',
        phoneMode: 'offline',
      })
      parent.props.saveLog({
        stamp: new Date(),
        text: 'Phone is on disconnected'
      })
    });
    
    //CALL EVENT
    ua.on("newRTCSession", function(data){
      // console.log("!!! RTC SESSION EVENT ", data.session);
      // incoming call here
      const session= data.session;
      sessions.set(session.id, session)

      if (session.direction === "incoming") {
        // console.log("!!! RTC SESSION INCOMING ");
        
        session.on("progress",function(e){
          // the call has answered
          // console.log("!!! RTC PROGRESS");
          parent.setState({
            phoneState: 'incoming',
          })
          parent.props.saveLog({
            stamp: new Date(),
            text: 'Phone is on incoming call'
          })
          parent.ring.play()
          setTimeout(()=>{
            session.answer(callOptions);
          }, (parent.state.ringDuration * 1000))
          
        });
        session.on("accepted",function(e){
            // the call has answered
            // console.log("!!! RTC ACCEPTED");
            parent.setState({
              phoneMode: 'oncall',
              phoneState: 'online',
            })
            parent.props.saveLog({
              stamp: new Date(),
              text: 'Phone is on accepted call'
            })
        });
        session.on("confirmed",function(){
            // this handler will be called for incoming calls too
            // console.log("!!! RTC CONFIRMED");
            parent.setState({
              phoneMode: 'oncall',
              phoneState: 'online',
            })
            parent.props.saveLog({
              stamp: new Date(),
              text: 'Phone is on confirmed / accepted call'
            })
            parent.ring.stop();
        });
        session.on("peerconnection", function(e) {
          // console.log('!!! CALL PEERCONNECTION: ', e)
          
          session.connection.addEventListener('addstream',function(e) {
            // console.log("!!! CALL ADD STREAM");
            var remoteAudio = new window.Audio()
            remoteAudio.srcObject = e.stream
            remoteAudio.play();
      
            var localView = document.getElementById('localView');
            var remoteView = document.getElementById('remoteView');
            
            if (remoteView) {
              remoteView.srcObject = (e.stream);
            }
            if (localView) {
              localView.srcObject = (session.connection.getLocalStreams()[0]);
            }
          })

          parent.props.saveLog({
            stamp: new Date(),
            text: 'Phone is on streaming'
          })
        });
        session.on("icecandidate", function(e) {
          // console.log('!!! CALL ICE CANDIDATE: ', e)
          // n(e.candidate.candidate);
          // if (e.Last) {
          //   e.ready();
          // }
        });
        session.on("sdp", function(e) {
          // console.log('!!! CALL SDP: ', e)
        });
        session.on("newInfo", function(e) {
          // console.log('!!! CALL INFO: ', e)
        });
        session.on("newDTMF", function(e) {
          // console.log('!!! CALL DTMF: ', e)
        });
        session.on("failed",function(e) {
            // unable to establish the call
            // console.log("!!! RTC FAILED", e);
            parent.setState({
              phoneMode: 'registered',
              // phoneState: 'registered',
            })
            parent.props.saveLog({
              stamp: new Date(),
              text: 'Phone is on failure, '+ e
            })
            parent.ring.stop();
        });
        session.on("ended",function(){
            // the call has ended
            // console.log("!!! RTC ENDED");
            parent.setState({
              phoneMode: 'registered',
              phoneState: 'registered',
            })
            parent.props.saveLog({
              stamp: new Date(),
              text: 'Phone is on hungup'
            })
            parent.ring.stop();
            parent.busy.play()
        });
      } else if (session.direction === "outgoing") {
        // console.log("!!! RTC SESSION OUTGOING ");
        session.connection.addEventListener('addstream',function(e) {
          // console.log("!!! CALL ADD STREAM");
          var remoteAudio = new window.Audio()
          remoteAudio.srcObject = e.stream
          remoteAudio.play();
    
          var localView = document.getElementById('localView');
          var remoteView = document.getElementById('remoteView');
          
          if (remoteView) {
            remoteView.srcObject = (e.stream);
          }
          if (localView) {
            localView.srcObject = (session.connection.getLocalStreams()[0]);
          }
        })
      }
    });

    //MESSAGE EVENT
    ua.on("newMessage", function(data){
      // console.log("!!! TEXTING EVENT", data);
      if (data.request=='OutgoingRequest') { //OUTGOING

      } else { //INCOMING

      }
    });

    //SIP EVENT
    ua.on("sipEvent", function(data){
      // console.log("!!! SIP EVENT", data);
    });

    //REGISTERED EVENT
    ua.on("registered", function(){
      // console.log("!!! REGISTERED");
      parent.setState({
        statusUA: 3,
        phoneMode: 'registered',
      })
    });
    ua.on("unregistered", function(data){
      // console.log("!!! UNREGISTERED", data);
      parent.setState({
        statusUA: 0,
        phoneMode: 'offline',
      })
    });
    ua.on("registrationFailed", function(data){
      // console.log("!!! REGISTRATION FAILED", data);
      parent.setState({
        statusUA: 4,
        phoneMode: 'offline',
        phoneState: 'disconnected',
      })
    });
  }

  callAnswer() {
    sessions.forEach(session=>{
      if (session.isInProgress()) {
        session.answer(callOptions);
      }
    })
  }

  callDial(ua, options) {
    const { terminating }= this.state
    const parent= this;
    
    //CALLING
    this.dial.play()

    const callEventHandlers = {
      'connecting': function(e) {
        // console.log('!!! CALL ON CONNECTING: ', e);
        parent.setState({
          phoneState: 'dialing',
        })
      },
      'progress': function(e) {
        // console.log('!!! CALL ON PROGRESS: ', e);
        parent.setState({
          phoneState: 'dialing',
        })
      },
      'confirmed': function(e) {
        // console.log('!!! CALL CONFIRMED: ', e);
        parent.setState({
          phoneState: 'occupied',
          phoneMode: 'occupied',
        })

        parent.dial.stop()
      },
      'icecandidate': function(e) {
        // console.log('!!! CALL ICE CANDIDATE: ', e)
        // n(e.candidate.candidate);
        // if (e.Last) {
        //   e.ready();
        // }
      },
      'sdp': function(e) {
        // console.log('!!! CALL SDP: ', e)
        // n(e.candidate.candidate);
        // if (e.Last) {
        //   e.ready();
        // }
      },
      'newInfo': function(e) {
        // console.log('!!! CALL INFO: ', e)
        // n(e.candidate.candidate);
        // if (e.Last) {
        //   e.ready();
        // }
      },
      'newDTMF': function(e) {
        // console.log('!!! CALL DTMF: ', e)
        // n(e.candidate.candidate);
        // if (e.Last) {
        //   e.ready();
        // }
      },
      'ended': function(e) {
        // console.log('!!! CALL ENDED: ', e);
        parent.setState({
          phoneState: 'registered',
          phoneMode: 'registered',
        })
        parent.ring.stop();
        parent.busy.play()
      },
      'failed': function(e) {
        // console.log('!!! CALL FAILED: ', e);
        const {originator, cause}= e
        parent.setState({
          phoneState: cause.toLowerCase(),
          phoneMode: 'registered',
        })
        setTimeout(()=>{
          if (parent.state.phoneState!='dialing' && parent.state.phoneState!='incoming' && parent.state.phoneState!='occupied'  && parent.state.phoneState!='offline') { 
            parent.setState({
              phoneState: 'registered',
            })
          }
        },5000)

        parent.dial.mute()
        parent.busy.play();
      },
    };
    
    // session = ua.call('sip:'+ terminating +'@174.138.19.28:5060', callOptions);
    // session = ua.call('sip:'+ terminating +'@202.52.48.75:5160', callOptions);
    // const session = ua.call('sip:'+ terminating , callOptions);
    // sessions.set(session.id, session);
  }

  callHangup() {
    let i= 0;

    sessions.forEach((session, key)=>{
      i++;
      setTimeout(()=>{
        session.terminate();
        sessions.delete(key)
      }, 300 * i )
    })
  }

  textSend() {
    var textingEventHandlers = {
      'succeeded': function(e){
        // console.log('!!! TEXTING SUCCEED: ',e);
      },
      'failed':    function(e){ 
        // console.log('!!! TEXTING FAILED: ',e);
      }
    };

    var textingOptions = {
      'eventHandlers': textingEventHandlers
    };
    const { text, terminating }= this.state
    // ua.sendMessage(terminating +'@174.138.19.28', text, textingOptions);
    ua.sendMessage(terminating, text, textingOptions);
  }

  textAccept() {

  }
  
  openWindow(value) {
    this.props.openWindow(value)
    this.setState({
      openDrawer: false
    })
  }

  setPhoneUI(layout) {
    const { uiMode, dialMode }= this.state
    if (layout=='dial') {
      for( var i = 0; i < uiMode.length; i++){ if ( uiMode[i] === 'texting') { uiMode.splice(i, 1); i--; }}
      this.setState({
        dialMode: !dialMode,
        uiMode: uiMode,
      })
    } else {
      if (layout=='texting') {
        if (uiMode.includes(layout)) {
          for( var i = 0; i < uiMode.length; i++){ if ( uiMode[i] === layout) { uiMode.splice(i, 1); i--; }}
        } else {
          uiMode.push(layout)
        }
        this.setState({
          dialMode: false,
          uiMode: uiMode,
        }) 
      } else if (uiMode.includes(layout)) {
        for( var i = 0; i < uiMode.length; i++){ if ( uiMode[i] === layout) { uiMode.splice(i, 1); i--; }}
        this.setState({
          uiMode: uiMode
        })
      } else {
        uiMode.push(layout)
        this.setState({
          uiMode: uiMode
        })
      }
    }
  }

  onChangeText(k, e, v) {
    if (k=='account') {
      this.setState({sipAccount: v.value})
    } else if (k=='passwd') {
      this.setState({sipPasswd: v.value})
    } else if (k=='host') {
      this.setState({sipHost: v.value})
    } else if (k=='uri') {
      this.setState({sipUri: v.value})
    } else if (k=='number') {
      this.setState({terminating: v.value})
    } else if (k=='text') {
      this.setState({text: v.value})
    }
  }

  onClickReconnect() {
    this.sipRegister();
  }

  dialNumber() {
    this.callDial(ua);
  }

  componentDidMount() {
    const {sipAccount, sipPasswd,sipHost,sipRealm }= this.props 
    this.setState({
      init: true,
      sipAccount: sipAccount,
      sipPasswd: sipPasswd, 
      sipHost: sipHost, 
      sipRealm: sipRealm,
    })

    this.sipRegister(sipAccount, sipPasswd, sipHost, sipRealm);
  }

  componentWillUnmount() {
    this.sipUnregister();
  }

  setDelay(v) {
    this.setState({
      ringDuration: v,
    })
  }

  render() {
    const { layout, windowPos, uiMode, sipAccount, phoneMode, phoneState, dialMode, statusUA }= this.state;
    const { screenWidth, screenHeight, responsive }= this.props

    // console.log('!!!! WEBPHONE', phoneMode, phoneState)

    return (
      <div>
        {/* <EmulatorHeader mode={phoneMode} status={phoneState}  dialMode={dialMode} phoneMode={phoneMode} uiMode={uiMode} layout={layout} sipAccount={sipAccount} setSkinMode={this.setSkinMode.bind(this)} setPhoneUI={this.setPhoneUI.bind(this)}/> */}
        
        <div style={{display: 'flex'}}>
          <div style={{flex: '2', background: '#eee'}}>
            {(phoneState=='registered' || phoneState=='connected') &&
            <EmulatorOnline mode={phoneMode} layout={layout} />}

            {phoneState=='incoming' &&
            <EmulatorIncoming mode={phoneMode} callAnswer={this.callAnswer.bind(this)} callHangup={this.callHangup.bind(this)}  layout={layout} />}

            {phoneState=='online' && 
            <EmulatorOncall mode={phoneMode} callHangup={this.callHangup.bind(this)}  layout={layout} status={phoneMode} />}

            {(phoneMode=='offline' || phoneState=='disconnected') && 
            <EmulatorOffline mode={phoneMode} statusUA={statusUA} layout={layout} />}
          </div>
          <div style={{flex: '1', padding: '1.5em 1.5em 0em .5em', background: '#e5e5e5'}}>
            <Dropdown 
            text={
              <Header as='h3' color='grey'>
                {/* <Icon name='angle double down' /> */}
                <Header.Content>
                  Answering
                  <Header.Subheader>In <b>{this.state.ringDuration} seconds</b></Header.Subheader>
                </Header.Content>
              </Header>
            } direction='left' icon={null}>
              <Dropdown.Menu>
                <Dropdown.Item text='1 Second' onClick={this.setDelay.bind(this, 1)} />
                <Dropdown.Item text='5 Seconds' onClick={this.setDelay.bind(this, 5)} />
                <Dropdown.Item text='10 Seconds' onClick={this.setDelay.bind(this, 10)} />
                <Dropdown.Item text='15 Seconds' onClick={this.setDelay.bind(this, 15)} />
                <Dropdown.Item text='20 Seconds' onClick={this.setDelay.bind(this, 20)} />
                <Dropdown.Item text='30 Seconds' onClick={this.setDelay.bind(this, 30)} />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        
        
        {/* <EmulatorFooter mode={phoneMode} layout={layout} phoneState={phoneState}/> */}
        <Divider />
        <div style={{padding: '1em'}}>
          {
            [...sessions.keys()].map((key, index)=>{
              console.log(sessions.get(key))
              return(
                (sessions.get(key).isEstablished() || sessions.get(key).isInProgress()) && 
                <Header as='h5' color={(sessions.get(key).isEstablished() && 'red') || (sessions.get(key).isInProgress() && 'black') } style={{margin: '.5em 0'}}>
                  <Header.Content>
                    {index+1}. {(sessions.get(key).isEstablished() && 'Established !') || (sessions.get(key).isInProgress() && 'In Progress !') }
                    <Header.Subheader>{sessions.get(key).id.substr(0,30)}</Header.Subheader>
                  </Header.Content>
                </Header>
              )
            })
          }
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Emulator)