import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Modal,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"
import { connect } from "react-redux"
import { browseContact, statusContact } from "../../actions/halo/contactAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    contactStore: state.contactStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseContact:(objParam) => dispatch(browseContact(objParam)),
    statusContact:(objParam) => dispatch(statusContact(objParam)),
  }
};


const ModalImage=(props)=>{
  return(
    <Modal size='mini' open={props.open} onClose={props.onClose} data={props.data}>
      <Modal.Content>
        <div style={{height: '25em', width: '100%', backgroundImage: 'url("'+ props.avatar +'")', backgroundSize: 'cover'}} />
      </Modal.Content>
    </Modal>
  )
}

class ContactBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.contactStore.browseContact!==undefined ? this.props.contactStore.browseContact : {})
    this.props.browseContact(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.contactStore.browseContact!==undefined ? this.props.contactStore.browseContact : {})
    this.props.browseContact({search: search, paging: paging})
  }

  onChangeStatus(id, status, field) {
    if (field=='invalid') {
      this.props.statusContact({contactId:id, isInvalid: status})
    } else if (field=='blocked') {
      this.props.statusContact({contactId:id, isBlocked: status})
    }
  }

  showImage(avatar) {
    this.setState({
      showImage: true,
      avatar: avatar,
    })
  }

  closeModal() {
    this.setState({
      showImage: false,
      avatar: null,
    })
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.contactStore.browseContact!==undefined ? this.props.contactStore.browseContact : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1'}}>
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                {this.props.setMode!=undefined &&
                <Table.HeaderCell></Table.HeaderCell>
                }
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>CONTACT</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='4'>
                  <Header as='h5' color='blue'>NAME</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='6'>
                  <Header as='h5' color='blue'>INFORMATION</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='1'>
                  <Header as='h5' color='blue'>INVALID</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='1'>
                  <Header as='h5' color='blue'>UNSUBS</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='1'>
                  <Header as='h5' color='blue'>ENABLED</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='1'>
                  <Header as='h5' color='blue'>BLOCKED</Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={5} style={{padding: '1em 2em'}}><Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!=undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      {this.props.setMode!=undefined &&
                      <Table.Cell textAlign='center'>
                        <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.contactId)}/>
                      </Table.Cell>
                      }
                      <Table.Cell>
                        {item.avatar && <div style={{cursor: 'pointer', borderRadius: '3em', height: '3em', width: '3em', backgroundImage: 'url("'+ item.avatar +'")', backgroundSize: 'cover'}} onClick={this.showImage.bind(this, item.avatar)}/>}
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            <Header.Subheader>{item.contactType.toUpperCase()}</Header.Subheader>
                            {item.contact} 
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                          <b style={{color: '#c31'}}>{item.name}</b> / {item.gender}
                            <Header.Subheader>{item.client || '-'}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.occupation || item.jobTitle}
                            <Header.Subheader>{item.jobCompany}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Form.Checkbox name='invalidStatus_1' 
                          toggle
                          defaultChecked={item.isInvalid=='1' ? true : false}
                          onChange={this.onChangeStatus.bind(this, item.contactId, (item.isInvalid=='1' ? '0' : '1'), 'invalid')} />
                      </Table.Cell>
                      <Table.Cell textAlign='center'>
                        <Icon name={item.isUnsub ? 'toggle on' : 'toggle off'} color={item.isUnsub ? 'red' : 'grey'} style={{fontSize: '1.5em'}}/>
                      </Table.Cell>
                      <Table.Cell textAlign='center'>
                        <Icon name={item.isEnabled ? 'toggle on' : 'toggle off'} color={item.isEnabled ? 'teal' : 'grey'} style={{fontSize: '1.5em'}}/>
                      </Table.Cell>
                      <Table.Cell>
                        {/* <Icon name={item.isBlocked ? 'toggle on' : 'toggle off'} color={item.isBlocked ? 'red' : 'grey'} style={{fontSize: '1.5em'}}/> */}
                        <Form.Checkbox name='blockedStatus_1' 
                          toggle
                          defaultChecked={item.isBlocked=='1' ? true : false}
                          onChange={this.onChangeStatus.bind(this, item.contactId, (item.isBlocked=='1' ? '0' : '1'), 'blocked')} />
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        {this.state.showImage && <ModalImage onClose={this.closeModal.bind(this)} open={this.state.showImage} avatar={this.state.avatar}/>}
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactBrowse)
