import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Grid } from 'semantic-ui-react'


import { connect } from "react-redux"
import { statCustomer } from "../../actions/halo/statisticAction"
import PieRechart from '../chart/PieRechart';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    statisticStore: state.statisticStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    statCustomer:(objParam) => dispatch(statCustomer(objParam)),
  }
}

class CustomerWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {};
  }

  componentDidMount() {
    this.props.statCustomer() 
  }
  
  render() {
    const { screenWidth,  screenHeight}= this.props
    const { data, search, paging }= (this.props.statisticStore.statCustomer!==undefined ? this.props.statisticStore.statCustomer : {})
    
    const pieProfiles= [], pieDistributions= [], pieSpendings= [];
    data && data.profiles && Object.entries(data.profiles).forEach(([key, value]) => {
      pieProfiles.push({name: key, value: value});
    })
    data && data.distributions && Object.entries(data.distributions).forEach(([key, value]) => {
      pieDistributions.push({name: key, value: value});
    })
    data && data.spendings && Object.entries(data.spendings).forEach(([key, value]) => {
      pieSpendings.push({name: key, value: value});
    })
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <Header as='h5'>
          <Icon name='chart bar' style={{fontSize: '1em'}} />
          <Header.Content>
            Customer / Client Demography
            <Header.Subheader></Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em'}}>
        <Grid columns={screenWidth<900 ? 1 : 3} stackable>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as='h5'>
              PROFILE
              <Header.Subheader>Profiling / personal background</Header.Subheader>
            </Header>
            {data!=undefined &&
            <PieRechart data={pieProfiles} width={300} height={300} mode='active' fill='#555'/>}
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as='h5'>
              DISTRIBUTION
              <Header.Subheader>Location / geography distribution</Header.Subheader>
            </Header>
            {data!=undefined &&
            <PieRechart data={pieDistributions} width={300} height={300} mode='active' fill='#666'/>}
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as='h5'>
              EXPENSE
              <Header.Subheader>Expense / spending category </Header.Subheader>
            </Header>
            {data!=undefined &&
            <PieRechart data={pieSpendings} width={300} height={300} mode='active' fill='#555'/>}
          </Grid.Column>
        </Grid>
      </Segment>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerWidget)
