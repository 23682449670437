import React from 'react'
import { Container, Segment, Label, Icon, List, Header } from 'semantic-ui-react'

import ModalMessage from '../libraries/common/ModalMessage'

class AuthenticateFooter extends React.Component {
    constructor(props) {
      super(props)
      this.state= {
        content: undefined,
      }
    }
  
    closeModal() {
      this.setState({open: false, content: undefined })
    }
  
    openModal(key) {
      this.setState({open: true, content: key,})
    }

    render() {
      const { open, provider }= this.state
      
      return(
        <div>
          
        </div>
      )
    }
} export default AuthenticateFooter
