import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react'

import {priorityOptions} from '../../common/StaticMasterData'
import DismissableMessage from '../../common/DismissableMessage'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { ImageUploadField, SelectField, InputField, CheckboxField, NumberField, TextAreaField, DatePickerField } from '../../validation/componentrsui'
import { required, number, minValue, email } from '../../validation/validation'
import { connect } from "react-redux"
import { browseMessage, resetMessage, createMessage } from "../../../actions/halo/messageAction"
import { listWorkgroup, listAgent } from "../../../actions/halo/masterAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    messageStore: state.messageStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createMessage:(objParam) => dispatch(createMessage(objParam)),
    resetMessage:(objParam) => dispatch(resetMessage(objParam)),
    browseMessage:(objParam) => dispatch(browseMessage(objParam)),

    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    listAgent:(objParam) => dispatch(listAgent(objParam)),
  }
}

class MessageForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      toAgent: false,
    }
  }

  componentDidMount() {
    this.props.listAgent({search:{groupId: 'Agent'}})
    this.props.listWorkgroup({search: {clientId: this.props.clientId!=undefined ? this.props.clientId : null}})
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  componentDidUpdate() {
    const { mode, reset } = this.props
    
    const { progress, code, text }= (mode==2 ? (this.props.messageStore.updateMessage!=undefined ? this.props.messageStore.updateMessage : {}) : (this.props.messageStore.createMessage!=undefined ? this.props.messageStore.createMessage : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{
        this.props.resetMessage(3)
        reset()
        this.props.setMode(1);
      }, 1000 )
    }
  }

  onSubmit(values) {
    // console.log("FORM SUBMITTED:", values)
    this.props.createMessage({...values})
  }

  sendTo() {
    this.setState({
      toAgent: !this.state.toAgent,
    })
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.messageStore.updateMessage!=undefined ? this.props.messageStore.updateMessage : {}) : (this.props.messageStore.createMessage!=undefined ? this.props.messageStore.createMessage : {}))

    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    const listAgent= (this.props.masterStore.listAgent!=undefined && this.props.masterStore.listAgent.progress=='1' ? this.props.masterStore.listAgent.data : [])
    

    return (
      <div style={{padding: '1em', background: '#fff', border: '1px solid rgba(100,100,100,.2)', borderRadius: '.5em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Header as='h4'>
            <Icon name='bell outline' />
            <Header.Content>
              {this.props.messageId==undefined | this.props.messageId==0 ? 'Send Message' : 'Update Messsage'}
            </Header.Content>
          </Header>

          <Divider />
          <Form.Group widths='equal'>
            <Field name='title' component={InputField}
            width='8'
            label={<b>Title / Subject</b>}
            placeholder='Title'
            validate={[required]} />
            <Field name='stamp' component={DatePickerField}
            fluid
            width='4'
            label={<b>Valid Stamp</b>}
            placeholder='Valid Stamp'
            validate={[required]} />
            <Field name='priority' component={SelectField}
            fluid
            width='4'
            label={<b>Priority</b>}
            options={priorityOptions()}
            placeholder='Priority'
            validate={[required]} />
          </Form.Group>
          {/* <Form.Group widths='equal'>
            <Field name='provider' component={InputField}
            label='Provider'
            placeholder='Provider'
            validate={[required]} />
          </Form.Group> */}
          {this.state.toAgent &&
          <Form.Group widths='equal'>
            <Field name='users' component={SelectField}
            fluid
            multiple
            label={
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <b>Send To <i>(Agent)</i></b>
                </div>
                <div>
                  <Icon name='arrow circle left' color='red' onClick={this.sendTo.bind(this)} style={{cursor: 'pointer', fontSize: '1.2em'}} /> 
                </div>
              </div>
            }
            options={listAgent}
            placeholder='Send message to certain agent or blank to all accounts' />
          </Form.Group>}
          {!this.state.toAgent &&
          <Form.Group widths='equal'>
            <Field name='groups' component={SelectField}
            fluid
            multiple
            label={
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <b>Send To <i>(Workgroup)</i></b>
                </div>
                <div>
                  <Icon name='arrow circle right' color='red' onClick={this.sendTo.bind(this)} style={{cursor: 'pointer', fontSize: '1.2em'}} /> 
                </div>
              </div>
            }
            options={listWorkgroup}
            placeholder='Send message to certain workgroup or blank to all accounts' />
          </Form.Group>}
          
          <Divider hidden/>
          <Form.Group widths='equal'>
            <Field name='content' component={TextAreaField}
            label={<b>Content Message</b>}
            placeholder='Content message required to send'
            validate={[required]} />
          </Form.Group>

          <Divider hidden/>
          <div style={{textAlign: 'right'}}>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Message' : 'Insert Message') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
                
            <Button size='small' type='reset' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
            <Button size='small' type='submit' content="Send" color='red' disabled={this.props.pristine || this.props.submitting} />
          </div>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'MessageForm',	// a unique identifier for this form
  })(MessageForm)
  )
)