import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import RatesBrowse from '../../libraries/halo/RatesBrowse'
import RatesForm from '../../libraries/halo/RatesForm'
import RatesSearch from '../../libraries/halo/RatesSearch'


class RatesBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        ratesId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, ratesId: id})
    }

    render() {
      const {mode, ratesId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div>
            <RatesSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <RatesBrowse modal={this.props.modal} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}/>}
              {mode==2 && <RatesForm mode={mode} ratesId={ratesId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <RatesForm mode={mode} ratesId={0} setMode={this.setMode.bind(this)} />}
            </div>
          </div>
        </Container>
      )
    }
}

export default RatesBody
