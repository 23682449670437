import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { connect } from "react-redux"
import { browseAppointment, statusAppointment } from "../../actions/halo/appointmentAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    appointmentStore: state.appointmentStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseAppointment:(objParam) => dispatch(browseAppointment(objParam)),
    statusAppointment:(objParam) => dispatch(statusAppointment(objParam)),
  }
}

class AppointmentBrowse extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.appointmentStore.browseAppointment!==undefined ? this.props.appointmentStore.browseAppointment : {})
    this.props.browseAppointment(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.appointmentStore.browseAppointment!==undefined ? this.props.appointmentStore.browseAppointment : {})
    this.props.browseAppointment({search: {eventDate: format(new Date(), 'yyyy-MM-dd'), ...search}, paging: paging})
  }

  onChangeStatus(id, status, e) {
    // console.log(id, status, e)
    this.props.statusAppointment({appointmentId:id, isEnabled: status})
  }

  render() {
    //console.log(this.props.appointmentStore.browseAppointment)
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.appointmentStore.browseAppointment!==undefined ? this.props.appointmentStore.browseAppointment : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1', padding: '0'}}>
        <Table unstackable selectable columns='16' celled striped basic='very' style={{border: '1px dashed #ddd'}}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                {/* <Table.HeaderCell></Table.HeaderCell> */}
                <Table.HeaderCell width='4'>
                  <Header as='h5' color='blue'>
                    APPOINTMENT
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='6'>
                  <Header as='h5' color='blue'>
                    PARTICIPANT
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='6'>
                  <Header as='h5' color='blue'>
                    CONTENT / DISCUSSION
                  </Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={5} style={{padding: '1em 2em'}}><Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      {/* <Table.Cell textAlign='center'>
                        <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.appointmentId)}/>
                      </Table.Cell> */}
                      <Table.Cell>
                        <Header as='h5'>
                          <Icon color='grey' name={(item.channelType=='Voice' && 'microphone') || (item.channelType=='IVR' && 'volume down') || (item.channelType=='SMS' && 'sticky note outline')
                           || (item.channelType=='Email' && 'envelope outline') || (item.channelType=='Whatsapp' && 'whatsapp') || (item.channelType=='Telegram' && 'telegram plane')
                           || (item.channelType=='Twitter' && 'twitter') || (item.channelType=='Facebook' && 'facebook') || (item.channelType=='Instagram' && 'instagram') || 'share alternate'}
                           />
                          <Header.Content>
                            <Header.Subheader><b>{item.channelType  || 'Voice'}</b> / {item.script || 'No template'}</Header.Subheader>
                            <b>{item.topic || 'Reschedule'}</b>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Subheader>{item.session} {item.startTime}, {format(parse(item.startEvent, 'yyyy-MM-dd', new Date()), 'dd / MMM, yyyy')}, {item.venue  || 'Online meeting only'}</Header.Subheader>
                          <mark style={{fontWeight: 'bold'}}>{item.target && item.target.name}</mark>, {item.participants || 'No participant'}
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            <Header.Subheader>By {item.client || 'Unknown'}</Header.Subheader>
                            {item.content}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentBrowse)
