import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider } from 'semantic-ui-react'
import StatisticDatafeeding from '../../libraries/middleware/StatisticDatafeeding';

class DashboardBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      userId: 0,
    };
  }

  setMode(mode, id) {
    this.setState({mode: mode, userId: id})
  }

  render() {
    const {screenWidth, screenHeight}= this.props
    
    return(
      <Container fluid>
          <Header as='h4' style={{margin: '1.3em 1em 0 1em'}}>
            <Icon name='chart line' style={{fontSize: '1em'}} />
            <Header.Content>
              Statistic Data Feeding
            </Header.Content>
          </Header>

          <StatisticDatafeeding screenWidth={screenWidth} screenHeight={screenHeight} setMode={this.setMode.bind(this)} />
      </Container>
    )
  }
}

export default DashboardBody
