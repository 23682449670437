import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
} from 'semantic-ui-react'

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse } from "date-fns"
import { messagingStatus } from "../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { browseMessaging, statusMessaging } from "../../actions/stream/messagingAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    messagingStore: state.messagingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseMessaging:(objParam) => dispatch(browseMessaging(objParam)),
    statusMessaging:(objParam) => dispatch(statusMessaging(objParam)),
  }
}

class MessagingBrowse extends Component {
  constructor(props) {
    super(props)
    this.state= {
      content: false,
      expand: false,
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.messagingStore.browseMessaging!==undefined ? this.props.messagingStore.browseMessaging : {})
    this.props.browseMessaging(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.messagingStore.browseMessaging!==undefined ? this.props.messagingStore.browseMessaging : {})
    this.props.browseMessaging({search: {
      // transactDate: format(new Date(), 'yyyy-MM-dd 00:00:00'), 
    ...search, campaignId: this.props.campaignId}, paging: paging})
  }

  onChangeStatus(id, status, e) {
    // console.log(id, status, e)
    this.props.statusMessaging({messagingId:id, isEnabled: status})
  }
  setExpand() {
    const { expand }= this.state
    this.setState({
      expand: !expand
    })
  }
  setContent() {
    const { content }= this.state
    this.setState({
      content: !content
    })
  }

  expandEmail(id) {
    // console.log(id)
    this.setState({
      messagingId: (this.state.messagingId==id ? 0 : id),
    })
  }

  render() {
    //console.log(this.props.messagingStore.browseMessaging)
    const { expand, content }= this.state
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.messagingStore.browseMessaging!==undefined ? this.props.messagingStore.browseMessaging : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1', padding: '0'}}>
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell width={expand ? 8 : 6}>
                  <Header as='h5' color='blue'>TARGET</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={expand ? 2 : 2}>
                  <Header as='h5' color='blue'>
                    <Icon name={expand ? 'angle double right' : 'angle double left'} color={expand ? 'red' : 'red'} style={{fontSize: '1.5em', cursor: 'pointer'}} onClick={this.setExpand.bind(this)}/>
                    <Header.Content>SENT</Header.Content>
                  </Header>
                </Table.HeaderCell>
                {expand && <>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>DLVRD</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2' style={{background: '#fffee0'}}>
                  <Header as='h5' color='blue'>READ</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>CLICK</Header>
                </Table.HeaderCell>
                </>}
                {!expand && 
                <Table.HeaderCell width={7} style={{background: content ? '#e7feff' : '#fff2f2'}}>
                  <Header as='h5' color='blue'><Icon name={content ? 'toggle on' : 'toggle off'} color={content ? 'blue' : 'red'} style={{fontSize: '1.5em', cursor: 'pointer'}} onClick={this.setContent.bind(this)}/><Header.Content>RESULT</Header.Content></Header>
                </Table.HeaderCell>}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={7} style={{padding: '1em 2em'}}><Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          {item.visitor==null && 
                            <Header.Content>
                              <b>{item.terminating}</b>
                              <Header.Subheader>{item.target.name || 'No info'}</Header.Subheader>
                              {/* {item.campaign!=null && <Header.Subheader> {item.campaign.name}</Header.Subheader>} */}
                              {/* {item.campaign!=null && <Header.Subheader>{format(parse(item.campaign.startDate, 'yyyy-MM-dd', new Date()), 'dd / MMM')} - {format(parse(item.campaign.endDate, 'yyyy-MM-dd', new Date()), 'dd / MMM')}</Header.Subheader>} */}
                            </Header.Content>
                          }
                          {item.visitor!=null &&
                            <Header.Content>
                              {item.visitor.name || 'Guest / Visitor'}
                              <Header.Subheader> {item.visitor.mobile || '-'}</Header.Subheader>
                              {/* {item.campaign!=null && <Header.Subheader>{format(parse(item.campaign.startDate, 'yyyy-MM-dd', new Date()), 'dd / MMM')} - {format(parse(item.campaign.endDate, 'yyyy-MM-dd', new Date()), 'dd / MMM')}</Header.Subheader>} */}
                            </Header.Content>
                          }
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.sentStamp!=null && format(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm:ss')}
                            <Header.Subheader>{item.sentStamp!=null && format(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      {expand && <>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.deliveredStamp!=null && format(parse(item.deliveredStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.deliveredStamp!=null && format(parse(item.deliveredStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell style={{background: '#fffee0'}}>
                        <Header as='h5'>
                          <Header.Content>
                            {item.readStamp!=null && format(parse(item.readStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.readStamp!=null && format(parse(item.readStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.clickStamp!=null && format(parse(item.clickStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.clickStamp!=null && format(parse(item.clickStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      </>}
                      {!expand && 
                      <Table.Cell style={{background: content ? '#e7feff' : '#fff2f2'}}>
                        {!content && <Header as='h5'>
                          <Icon name={(item.dropStamp && 'exclamation circle') || (item.status<0 && 'times circle outline') || (item.status>0 && 'check circle') || 'dot circle outline'} color={(item.dropStamp && 'orange') || (item.status<0 && 'red') || (item.status>0 && 'blue') || 'teal'} style={{fontSize: '1.25em'}}/> 
                          <Header.Content>
                            {(item.dropStamp && ' Dropped') || messagingStatus[item.status] || 'No reason'}
                            <Header.Subheader>{item.reason} / from: {item.originating}</Header.Subheader>
                          </Header.Content>
                        </Header>}
                        {content && item.target!=null && 
                          <>
                            {(item.channelType=='WA' || item.channelType=='SMS') && <Header as='h5'>
                              <Header.Content>
                                <b>t:</b> {item.terminating} <br />
                                <b>f:</b> {item.originating}<br />
                                {item.content}
                              </Header.Content>
                            </Header>}
                            
                          </>
                        }
                      </Table.Cell>}
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagingBrowse)
