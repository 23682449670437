import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import TopupBrowse from '../../libraries/halo/TopupBrowse'
import TopupForm from '../../libraries/halo/TopupForm'
import TopupSearch from '../../libraries/halo/TopupSearch'


class TopupBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        topupId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, topupId: id})
    }

    render() {
      const {mode, topupId}= this.state
      const {screenWidth, screenHeight, params}= this.props
      
      return(
        <Container fluid>
          <div>
            <TopupSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <TopupBrowse params={params} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}/>}
              {mode==2 && <TopupForm mode={mode} params={params} topupId={topupId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <TopupForm mode={mode} params={params} topupId={0} setMode={this.setMode.bind(this)} />}
            </div>
          </div>
        </Container>
      )
    }
}

export default TopupBody
