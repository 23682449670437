import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react'

import {priorityOptions} from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { ImageUploadField, SelectField, InputField, CheckboxField, NumberField, TextAreaField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
import { connect } from "react-redux"
import { browseMessage, resetMessage, createMessage } from "../../actions/halo/messageAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    messageStore: state.messageStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createMessage:(objParam) => dispatch(createMessage(objParam)),
    resetMessage:(objParam) => dispatch(resetMessage(objParam)),
    browseMessage:(objParam) => dispatch(browseMessage(objParam)),
  }
}

class MessageForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  componentDidUpdate() {
    const { mode, reset } = this.props
    
    const { progress, code, text }= (mode==2 ? (this.props.messageStore.updateMessage!=undefined ? this.props.messageStore.updateMessage : {}) : (this.props.messageStore.createMessage!=undefined ? this.props.messageStore.createMessage : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{
        this.props.resetMessage(3)
        this.props.browseMessage()
        reset()
      }, 1000 )
    }
  }

  onSubmit(values) {
    // console.log("FORM SUBMITTED:", values)
    this.props.createMessage({...values})
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.messageStore.updateMessage!=undefined ? this.props.messageStore.updateMessage : {}) : (this.props.messageStore.createMessage!=undefined ? this.props.messageStore.createMessage : {}))

    return (
      <div style={{padding: '1em', background: '#fff', border: '1px solid rgba(100,100,100,.2)', borderRadius: '.5em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Header as='h4'>
            <Icon name='bell outline' />
            <Header.Content>
              {this.props.messageId==undefined | this.props.messageId==0 ? 'Send Message' : 'Update Messsage'}
            </Header.Content>
          </Header>
          <Divider />
          <Form.Group widths='equal'>
            <Field name='title' component={InputField}
            width='10'
            label='Title'
            placeholder='Title'
            validate={[required]} />
            <Field name='priority' component={SelectField}
            fluid
            width='6'
            label='Priority'
            options={priorityOptions()}
            placeholder='Priority'
            validate={[required]} />
          </Form.Group>
          {/* <Form.Group widths='equal'>
            <Field name='provider' component={InputField}
            label='Provider'
            placeholder='Provider'
            validate={[required]} />
          </Form.Group> */}
          <Form.Group widths='equal'>
            <Field name='content' component={TextAreaField}
            label='Message'
            placeholder='Message'
            validate={[required]} />
          </Form.Group>

          <Divider hidden/>
          <div style={{textAlign: 'right'}}>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Message' : 'Insert Message') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
                
            <Button size='small' type='reset' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
            <Button size='small' type='submit' content="Send" color='red' disabled={this.props.pristine || this.props.submitting} />
          </div>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'MessageForm',	// a unique identifier for this form
  })(MessageForm)
  )
)