import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetClient(objParam) {
  return {
      type: 'CLIENT_RESET',
      payload: objParam
  }
}

export function getClient(objParam) {
  return {
      type: 'CLIENT_GET',
      payload:
        axios.post(
          urlAPI + 'client/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusClient(objParam) {
  return {
      type: 'CLIENT_STATUS',
      payload:
        axios.post(
          urlAPI + 'client/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseClient(objParam) {
  return {
      type: 'CLIENT_BROWSE',
      payload:
        axios.post(
          urlAPI + 'client/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createClient(objParam) {
  return {
      type: 'CLIENT_CREATE',
      payload:
        axios.post(
          urlAPI + 'client/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateClient(objParam) {
  return {
      type: 'CLIENT_UPDATE',
      payload:
        axios.post(
          urlAPI + 'client/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeClient(objParam) {
  return {
      type: 'CLIENT_REMOVE',
      payload:
        axios.post(
          urlAPI + 'client/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function realmClient(objParam) {
  return {
      type: 'CLIENT_REALM',
      payload:
        axios.post(
          urlAPI + 'client/realm',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}