import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Table } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { resultOptions, timeOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
import { connect } from "react-redux"
import { resetTransact, getTransact,updateTransact, createTransact } from "../../actions/stream/transactAction"
import { listRemark } from "../../actions/halo/masterAction"


//CONNECT REDUX STORE
const selector = formValueSelector('TransactForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    transactStore: state.transactStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listRemark:(objParam) => dispatch(listRemark(objParam)),
    
    resetTransact:(objParam) => dispatch(resetTransact(objParam)),
    getTransact:(objParam) => dispatch(getTransact(objParam)),
    createTransact:(objParam) => dispatch(createTransact(objParam)),
    updateTransact:(objParam) => dispatch(updateTransact(objParam)),
  }
}

class TransactForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount(){
    this.props.resetTransact(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getTransact({transactId: this.props.transactId})
    }

    this.props.campaignId!=undefined && this.props.listRemark({campaignId: this.props.campaignId})
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.transactStore.updateTransact!=undefined ? this.props.transactStore.updateTransact : {}) : (this.props.transactStore.createTransact!=undefined ? this.props.transactStore.createTransact : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, transactId } = this.props
    
    if (mode==3) { //INSERT
      this.props.createTransact({...values, voices: null, appointments: null})
    } else if(mode==2) { //UPDATE
      this.props.updateTransact({...values, voices: null, appointments: null })
    }
    
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    
    const { data }= (this.props.transactStore.getTransact!=undefined && this.props.transactStore.getTransact.progress=='1' ? this.props.transactStore.getTransact : {})
    const { progress, code, text }= (mode==2 ? (this.props.transactStore.updateTransact!=undefined ? this.props.transactStore.updateTransact : {}) : (this.props.transactStore.createTransact!=undefined ? this.props.transactStore.createTransact : {}))

    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Header as='h5'>
              <Icon name='edit outline' style={{fontSize: '1em'}}/>
              <Header.Content>Customer Remarks</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='result' component={SelectField} 
              width={4}
              // options={resultOptions('transact')}
              options={listRemark}
              label= 'Result'
              placeholder='Transact Result' />
              {this.props.result=='Rescheduled' && <>
              <Field name='appointmentDate' component={DatePickerField}
              width={4}
              label= 'Appointment Date'
              placeholder='Appointment Date' />
              <Field name='appointmentSession' component={SelectField}
              options={timeOptions}
              width={4}
              label= 'Appointment Time'
              placeholder='Appointment Time' />
              </>}
              {this.props.result=='Promised' &&
              <Field name='promisedDate' component={DatePickerField}
              width={4}
              label= 'Promised Date'
              placeholder='Promised Date' />}
              {this.props.result=='Paid' &&
              <Field name='paidDate' component={DatePickerField}
              width={4}
              label= 'Payment Date'
              placeholder='Payment Date' /> }
            </Form.Group>
            <Form.Group widths='16'>
            <Field name='reason' component={InputField}
              width={16}
              label= 'Reason'
              placeholder='Remarks Note / Reason' />  
            </Form.Group>
          </Segment>
          {data!=undefined && data.subscriptions!=null &&
          <Segment>
            <Header as='h5'>
              <Icon name='envelope open outline' style={{fontSize: '1em'}}/>
              <Header.Content>Bill Information</Header.Content>
            </Header>
            <div style={{display: 'flex', padding: '.5em 0'}}>
              <div style={{flex: '2', padding: '1em 0'}}>
                <Header as='h2' color='red'>
                  <Icon name='calendar times outline' style={{color: '#aaa'}}/>
                  <Header.Content>
                    <b>{numeral(data.subscriptions.overdueAmt).format('0,0')}</b>
                    <Header.Subheader>Due date {data.subscriptions.dueDate!=null ? format(new Date(data.subscriptions.dueDate), 'dd / MMM, yyyy') : 'No duedate'} / {data.subscriptions.overdueDay || 'Uncertain'} Days</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '3', borderLeft: '1px dashed rgb(112 200 255)', padding: '.5em 1em'}}>
                <Header as='h2' style={{margin: '0'}}>
                  <Icon name='window restore outline' style={{color: '#aaa'}}/>
                  <Header.Content>
                    {data.subscriptions.bucket}
                    <Header.Subheader>{data.subscriptions.product}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
          </Segment>}
          {data!=undefined && data.appointments!=null && 
          <Segment>
            <Header as='h5'>
              <Icon name='bell outline' style={{fontSize: '1em'}}/>
              <Header.Content>Appointment</Header.Content>
            </Header>
            {data.appointments.map((item, i)=>{
              if (i==0) { 
                return(
                  <div style={{display: 'flex', padding: '.5em 0'}}>
                    <div style={{flex: '1', padding: '1em'}}>
                      <Header as='h3' color='red'>
                        <Icon name='calendar check outline' style={{color: '#aaa'}}/>
                        <Header.Content>
                          {format(parse(item.startEvent, 'yyyy-MM-dd', new Date()), 'dd / MMM, yyyy')}
                          <Header.Subheader>{item.session || 'Pagi'} via {item.channelType || 'Voice'}</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>
                    <div style={{flex: '2', borderLeft: '1px dashed rgb(112 200 255)', padding: '.5em 1em'}}>
                      <Header as='h5' style={{margin: '0'}}>
                      <Header.Subheader>TOPIC</Header.Subheader>
                      <i>{item.topic}</i></Header>
                      <Header as='h5' style={{margin: '.5em 0'}}>
                      <Header.Subheader>VENUE</Header.Subheader>
                        <i>{item.venue}</i></Header>
                      <Divider />
                      <Header as='h5' style={{margin: '0'}}>
                        <Header.Content>
                          <Header.Subheader>CONTENT</Header.Subheader>
                          {item.content}
                        </Header.Content>
                      </Header>
                    </div>
                  </div>
                )
              }
            })}
          </Segment>}
          <Segment>
            <Header as='h5'>
              <Icon name='user circle' style={{fontSize: '1em'}}/>
              <Header.Content>Target / Campaign</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              readOnly
              width={8}
              placeholder='Name' />
              <Field name='mobile' component={InputField}
              readOnly
              width={4}
              placeholder='Mobile' />
              <Field name='email' component={InputField}
              readOnly
              width={4}
              placeholder='Email' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='information' component={InputField}
              readOnly
              width={16}
              placeholder='Information' />
            </Form.Group>
            {/* <Form.Group widths='equal'>
              <Field name='city' component={InputField}
              width={4}
              placeholder='City' />
              <Field name='province' component={InputField}
              width={4}
              placeholder='Province' />
              <Field name='address' component={InputField}
              width={8}
              placeholder='Address' />
            </Form.Group> */}
          
            <Divider hidden />
            <Form.Group widths='16'>
              <Field name='campaign' component={InputField}
              readOnly
              width={16}
              placeholder='Campaign' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='campaignType' component={InputField}
              readOnly
              width={4}
              placeholder='Campaign Type' />
              <Field name='startDate' component={InputField}
              readOnly
              width={4}
              placeholder='StartDate' />
              <Field name='endDate' component={InputField}
              readOnly
              width={4}
              placeholder='End Date' />
              <Field name='startTime' component={InputField}
              readOnly
              width={2}
              placeholder='Start Time' />
              <Field name='endTime' component={InputField}
              readOnly
              width={2}
              placeholder='End Time' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Icon name='file alternate outline' style={{fontSize: '1em'}}/>
              <Header.Content>Engagement</Header.Content>
            </Header>
            <Table unstackable selectable columns='16' celled striped basic='very' style={{border: 'none'}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell width='2'><Header color='blue' as='h5'>STAMP</Header></Table.HeaderCell>
                  <Table.HeaderCell width='1'><Header color='blue' as='h5'>CHANNEL</Header></Table.HeaderCell>
                  <Table.HeaderCell width='8'><Header color='blue' as='h5'>REMARKS</Header></Table.HeaderCell>
                  <Table.HeaderCell width='3'><Header color='blue' as='h5'>AGENT</Header></Table.HeaderCell>
                  <Table.HeaderCell width='2'><Header color='blue' as='h5'>RESULT</Header></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data!=undefined && data.voices!=undefined && data.voices.map((item, i)=>{
                  return(
                    <Table.Row>
                      <Table.Cell>{i+1}.</Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          {item.dialStamp!=null ? format(parse(item.dialStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm:ss') : ''}
                          <Header.Subheader>{item.dialStamp!=null ? format(parse(item.dialStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM, yyyy') : null}</Header.Subheader>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        {item.channelType || 'Voice'}
                      </Table.Cell>
                      <Table.Cell>
                        {item.results || 'No remarks saved'}
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          {item.voiceagents!=null ? item.voiceagents.name : 'No agent info'}
                          <Header.Subheader>{item.voiceagents!=null ? item.voiceagents.tagger || 'No tags' : ''} / {item.voiceagents!=null ? item.voiceagents.company || 'Posted by nobody' : ''}</Header.Subheader>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          {item.result || 'No result'}
                          <Header.Subheader>{item.status || 'No status description'}</Header.Subheader>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </Segment>

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Transact' : 'Insert Transact') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.transactStore.getTransact!=undefined && state.transactStore.getTransact.progress==1 ? state.transactStore.getTransact.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
      result: selector(state, 'result'),
    }),
  )
  (reduxForm({
    form: 'TransactForm',	// a unique identifier for this form
  })(TransactForm)
  )
)