import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'
import ModalContainer from '../../libraries/common/ModalContainer';

//SYSTEM
import AudittrailBody from '../system/AudittrailBody'
import ErrorBody from '../system/ErrorBody'

//RESOURCE
import SettingBody from './SettingBody'

import AgentBody from './AgentBody'
import WorkgroupBody from './WorkgroupBody'
import ScriptBody from './ScriptBody'

import BillingBody from './BillingBody'
import ClientBody from './ClientBody'

import CustomerBody from './CustomerBody'
import VisitorBody from './VisitorBody'
import ContactBody from './ContactBody'

import ProductBody from './ProductBody'
import AppointmentBody from './AppointmentBody'


//ECOSYSTEM
import BloggingBody from './BloggingBody'
import DocumentBody from './DocumentBody'

import WaserverBody from './WaserverBody'
import VoipBody from './VoipBody'
import TrespassingBody from './TrespassingBody'
import WhitelistBody from './WhitelistBody'
import PostpaidBody from './PostpaidBody'
import RecordBody from './RecordBody'
import PrepaidBody from './PrepaidBody'
import DeductBody from './DeductBody'
import RatesBody from './RatesBody'
import TopupBody from './TopupBody';


class AdminBodyHalo extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  closeModal(params) {
    this.props.closeModal(params)
  }
  
  openModal(value, params) {
    this.props.openModal(value, params)
  }


  openWindow(value) {
    this.props.openWindow(value)
  }

  render() {
    const { mode, module, screenWidth, screenHeight, mini, modal, params }= this.props

    return (
      <div>
          {/* RESOURCE */}
          {mode==='client' && <ClientBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
          {mode==='billing' && <BillingBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
          {mode==='setting' && <SettingBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
                
          {mode==='agent' && <AgentBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}                
          {mode==='workgroup' && <WorkgroupBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
          {mode==='script' && <ScriptBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
          
          {mode==='customer' && <CustomerBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} openModal={this.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
          {mode==='visitor' && <VisitorBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} openModal={this.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
          {mode==='contact' && <ContactBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
          
          {mode==='appointment' && <AppointmentBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
          {mode==='product' && <ProductBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} openModal={this.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
          

          {/* ECOSYSTEM */}
          {mode==='blogging' && <BloggingBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
          {mode==='document' && <DocumentBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}

          {mode==='waserver' && <WaserverBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
          
          {mode==='voip' && <VoipBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.closeModal.bind(this)} /> }
          {mode==='trespassing' && <TrespassingBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.closeModal.bind(this)} /> }
          {mode==='whitelist' && <WhitelistBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.closeModal.bind(this)} /> }
          {mode==='postpaid' && <PostpaidBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
          {mode==='record' && <RecordBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
          {mode==='prepaid' && <PrepaidBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} openModal={this.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
          {modal==='topup' && <ModalContainer size='small' content={<TopupBody params={params} openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} openModal={this.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />} closeModal={this.props.closeModal.bind(this)} />}
          {mode==='deduct' && <DeductBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
          {mode==='rates' && <RatesBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}

          {mode==='audit' && <AudittrailBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} openModal={this.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
          {mode==='error' && <ErrorBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} openModal={this.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
      </div>
    )
  }
}

export default AdminBodyHalo