import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image } from 'semantic-ui-react'

export default class MenuLeft extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      isSubMenu: 'service', //service addon systen
      isExpand: 'dashboard',
    };
  }

  expandMenu(v) {
    this.setState({
      isExpand: this.state.isExpand==v ? '' : v
    })
  }

  expandSubMenu(v) {
    this.setState({
      isSubMenu: this.state.isSubMenu==v ? '' : v
    })
  }

  render() {
    const { isExpand, isSubMenu }= this.state
    const { module, screenWidth,  screenHeight, mini }= this.props
    
    if (mini) {
      return(
        <></>
      )
    } else {
      return (
        <div style={{display: 'flex', flexDirection: 'column', minHeight: screenHeight, height: '100%', width: '230px'}}>
          <div style={{flex: '1', padding: '1.5em 1.5em 1.5em 1em', }}>
            <div style={{borderRadius: '5em', display: 'flex', margin: '.2em -1.5em 0em -1em', padding: '1em', cursor: 'pointer', background: isSubMenu=='service' ? 'rgba(255, 255, 255, .1)' : 'rgba(255, 255, 255, .05)'}} onClick={this.expandSubMenu.bind(this, 'service')}>
              <div style={{flex: '1', borderRight: '1px solid rgba(10, 10, 10, .3)'}}>
                <Header as ='h4'>
                  <Header.Content>
                    <b>Digital Service</b>
                  </Header.Content>
                </Header>
              </div>
              <div style={{borderLeft: '1px solid rgba(255, 255, 255, .9)', paddingLeft: '.5em'}}>
                <Icon name={isSubMenu=='service' ? 'arrow left' : 'arrow right'} color={isSubMenu=='service' ? 'red' : null} style={{fontSize: '1.1em' }} />
              </div>
            </div>
            {isSubMenu=='service' && <>
              <div style={{display: 'flex', margin: '1em 0em 0em -.1em'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4' style={{fontWeight: 'bold', margin: '0'}} color={isExpand=='dashboard' ? 'red' : 'black'}>
                    <Icon name='tv' style={{fontSize: '1.1em'}} circular inverted color={isExpand=='dashboard' ? 'red' : 'yellow'} />
                    <Header.Content>
                      Dashboard
                      <Header.Subheader>Real Time</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div>
                  <Icon name={isExpand=='dashboard' ? 'toggle on' : 'toggle off'} color={isExpand=='dashboard' ? 'red' : null} style={{fontSize: '1.3em', transform: 'rotate(90deg)', cursor: 'pointer'}} onClick={this.expandMenu.bind(this, 'dashboard')}/>
                </div>
              </div>
              {isExpand=='dashboard' && <>
                <Divider style={{margin: '.5em'}} />
                
                {/* <Header as='h4' style={{margin: '.5em 0em 0em .5em'}} color='blue'>
                  <Icon name='chart area' style={{fontSize: '1.1em'}} />
                  <Header.Content>Performance</Header.Content>
                </Header> */}
                <Divider style={{margin: '.5em'}} hidden />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'monitoring', 'dashboard')}
                  color={this.props.mode=='monitoring' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='monitoring' ? '600' : '100'}}>
                    <Icon name='feed' style={{fontSize: '1.2em'}} color={this.props.mode=='monitoring' ? 'red' : 'yellow'}/>
                    <Header.Content>Monitoring</Header.Content>
                    {this.props.mode=='monitoring' && <div className='arrowmenuadmin' />}
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'statistic', 'dashboard')}
                  color={this.props.mode=='statistic' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='statistic' ? '600' : '100'}}>
                    <Icon name='chart bar' style={{fontSize: '1.2em'}} color={this.props.mode=='statistic' ? 'red' : 'yellow'}/>
                    <Header.Content>Statistic</Header.Content>
                    {this.props.mode=='statistic' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'occupancy', 'dashboard')}
                  color={this.props.mode=='occupancy' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='occupancy' ? '600' : '100'}}>
                    <Icon name='chart area' style={{fontSize: '1.2em'}} color={this.props.mode=='occupancy' ? 'red' : 'yellow'}/>
                    <Header.Content>Occupancy</Header.Content>
                    {this.props.mode=='occupancy' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'datafeeding', 'dashboard')}
                  color={this.props.mode=='datafeeding' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='datafeeding' ? '600' : '100'}}>
                    <Icon name='chart line' style={{fontSize: '1.2em'}} color={this.props.mode=='datafeeding' ? 'red' : 'yellow'}/>
                    <Header.Content>Data Feeding</Header.Content>
                    {this.props.mode=='datafeeding' && <div className='arrowmenuadmin' />}
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'performance', 'dashboard')}
                  color={this.props.mode=='performance' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='performance' ? '600' : '100'}}>
                    <Icon name='star outline' style={{fontSize: '1.2em'}} color={this.props.mode=='performance' ? 'red' : 'yellow'}/>
                    <Header.Content>Performance</Header.Content>
                    {this.props.mode=='performance' && <div className='arrowmenuadmin' />}
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'reporting', 'dashboard')}
                  color={this.props.mode=='reporting' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='reporting' ? '600' : '100'}}>
                    <Icon name='file alternate outline' style={{fontSize: '1.2em'}} color={this.props.mode=='reporting' ? 'red' : 'yellow'}/>
                    <Header.Content>Reporting</Header.Content>
                    {this.props.mode=='reporting' && <div className='arrowmenuadmin' />}
                </Header>

                <Header as='h4' style={{margin: '1.5em 0em 0em .5em'}} color='blue'>
                  <Icon name='comments' style={{fontSize: '1.1em'}} />
                  <Header.Content>Conversation</Header.Content>
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'telephony', 'dashboard')}
                  color={this.props.mode=='telephony' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='telephony' ? '600' : '100'}}>
                    <Icon name='call' style={{fontSize: '1.1em'}} color={this.props.mode=='telephony' ? 'red' : 'yellow'}/>
                    <Header.Content>Telephony</Header.Content>
                    {this.props.mode=='telephony' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5' 
                  // disabled
                  onClick={this.props.openWindow.bind(this, 'messaging', 'dashboard')}
                  color={this.props.mode=='messaging' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='messaging' ? '600' : '100'}}>
                    <Icon name='comment alternate outline' style={{fontSize: '1.2em'}} color={this.props.mode=='messaging' ? 'red' : 'yellow'}/>
                    <Header.Content>Messaging</Header.Content>
                    {this.props.mode=='messaging' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5' 
                  // disabled
                  onClick={this.props.openWindow.bind(this, 'social', 'dashboard')}
                  color={this.props.mode=='social' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='social' ? '600' : '100'}}>
                    <Icon name='share alternate' style={{fontSize: '1.2em'}} color={this.props.mode=='social' ? 'red' : 'yellow'}/>
                    <Header.Content>Social Media</Header.Content>
                    {this.props.mode=='social' && <div className='arrowmenuadmin' />}
                </Header>
              </>}
              
              <Divider style={{margin: '1em .5em 1em .5em'}} />
              <div style={{display: 'flex', margin: '1em 0em 0em -.1em'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4' style={{fontWeight: 'bold', margin: '0'}} color={isExpand=='stream' ? 'red' : 'black'}>
                    <Icon name='arrow left' style={{fontSize: '1.1em'}} circular inverted color={isExpand=='stream' ? 'red' : 'yellow'} />
                    <Header.Content>
                      Stream!
                      <Header.Subheader>Multi Channel</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div>
                  <Icon name={isExpand=='stream' ? 'toggle on' : 'toggle off'} color={isExpand=='stream' ? 'red' : null} style={{fontSize: '1.3em', transform: 'rotate(90deg)', cursor: 'pointer'}} onClick={this.expandMenu.bind(this, 'stream')}/>
                </div>
              </div>
              {isExpand=='stream' && <>
                <Divider style={{margin: '.5em'}} />
                {/* <Header as='h4' style={{margin: '.5em 0em 0em .5em'}} color='blue'>
                    <Icon name='calendar alternate outline' style={{fontSize: '1em'}} />
                    <Header.Content>Event</Header.Content>
                </Header> */}
                <Divider style={{margin: '.5em'}} hidden/>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'campaign', 'stream')}
                  color={this.props.mode=='campaign' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='campaign' ? '600' : '100'}}>
                    <Icon name='calendar check outline' style={{fontSize: '1.2em'}} color={this.props.mode=='campaign' ? 'red' : 'yellow'}/>
                    <Header.Content>Campaign</Header.Content>
                    {this.props.mode=='campaign' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'target', 'stream')}
                  color={this.props.mode=='target' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='target' ? '600' : '100'}}>
                    <Icon name='user circle' style={{fontSize: '1.2em'}} color={this.props.mode=='target' ? 'red' : 'yellow'}/>
                    <Header.Content>Target / Audience</Header.Content>
                    {this.props.mode=='target' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'assignment', 'stream')}
                  color={this.props.mode=='assignment' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='assignment' ? '600' : '100'}}>
                    <Icon name='bookmark outline' style={{fontSize: '1.2em'}} color={this.props.mode=='assignment' ? 'red' : 'yellow'}/>
                    <Header.Content>Target Assignment</Header.Content>
                    {this.props.mode=='assignment' && <div className='arrowmenuadmin' />}
                </Header>

                <Header as='h4' style={{margin: '1.5em 0em 0em .5em'}} color='blue'>
                  <Icon name='envelope open outline' style={{fontSize: '1.2em'}} />
                  <Header.Content>Achievement</Header.Content>
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'collection', 'stream')}
                  color={this.props.mode=='collection' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='collection' ? '600' : '100'}}>
                    <Icon name='copy outline' style={{fontSize: '1.2em'}} color={this.props.mode=='collection' ? 'red' : 'yellow'}/>
                    <Header.Content>Collection</Header.Content>
                    {this.props.mode=='collection' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'restructuring', 'stream')}
                  color={this.props.mode=='restructuring' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='restructuring' ? '600' : '100'}}>
                    <Icon name='cubes' style={{fontSize: '1.2em'}} color={this.props.mode=='restructuring' ? 'red' : 'yellow'}/>
                    <Header.Content>Restructuring</Header.Content>
                    {this.props.mode=='restructuring' && <div className='arrowmenuadmin' />}
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'telesale', 'stream')}
                  color={this.props.mode=='telesale' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='telesale' ? '600' : '100'}}>
                    <Icon name='closed captioning outline' style={{fontSize: '1.2em'}} color={this.props.mode=='telesale' ? 'red' : 'yellow'}/>
                    <Header.Content>Telesales</Header.Content>
                    {this.props.mode=='telesale' && <div className='arrowmenuadmin' />}
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'verification', 'stream')}
                  color={this.props.mode=='verification' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='verification' ? '600' : '100'}}>
                    <Icon name='lab' style={{fontSize: '1.2em'}} color={this.props.mode=='verification' ? 'red' : 'yellow'}/>
                    <Header.Content>Verification</Header.Content>
                    {this.props.mode=='verification' && <div className='arrowmenuadmin' />}
                </Header>
                {/* <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'tourism', 'stream')}
                  color={this.props.mode=='tourism' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer'}}>
                    <Icon name='image outline' style={{fontSize: '1.2em'}} color={this.props.mode=='tourism' ? 'red' : 'yellow'}/>
                    <Header.Content>Tourism</Header.Content>
                    {this.props.mode=='student' && <div className='arrowmenuadmin' />}
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'policy', 'stream')}
                  color={this.props.mode=='policy' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer'}}>
                    <Icon name='stethoscope' style={{fontSize: '1.2em'}} color={this.props.mode=='insurance' ? 'red' : 'yellow'}/>
                    <Header.Content>Insurance</Header.Content>
                    {this.props.mode=='student' && <div className='arrowmenuadmin' />}
                </Header> */}
              </>}

              {true && <>
              <Divider style={{margin: '1em .5em 1em .5em'}} />
              <div style={{display: 'flex', margin: '1em 0em 0em -.1em'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4' style={{fontWeight: 'bold', margin: '0'}} color={isExpand=='flow' ? 'red' : 'black'}>
                    <Icon name='arrow right' style={{fontSize: '1.1em'}} circular inverted color={isExpand=='flow' ? 'red' : 'yellow'} />
                    <Header.Content>
                      Flow!
                      <Header.Subheader>Omni Channel</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div>
                  <Icon name={isExpand=='flow' ? 'toggle on' : 'toggle off'} color={isExpand=='flow' ? 'red' : null} style={{fontSize: '1.3em', transform: 'rotate(90deg)', cursor: 'pointer'}} onClick={this.expandMenu.bind(this, 'flow')}/>
                </div>
              </div>
              {isExpand=='flow' && <>
                <Divider style={{margin: '.5em'}} />
                {/* <Header as='h4' style={{margin: '.5em 0em 0em .5em'}} color='blue'>
                    <Icon name='camera retro' style={{fontSize: '1em'}} />
                    <Header.Content>Interactives</Header.Content>
                </Header> */}
                <Divider style={{margin: '.5em'}} hidden />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'interactive', 'flow')}
                  color={this.props.mode=='interactive' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='interactive' ? '600' : '100'}}>
                    <Icon name='window restore outline' style={{fontSize: '1.2em'}} color={this.props.mode=='interactive' ? 'red' : 'yellow'}/>
                    <Header.Content>IVR Menu</Header.Content>
                    {this.props.mode=='interactive' && <div className='arrowmenuadmin' />}
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'widget', 'flow')}
                  color={this.props.mode=='widget' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='widget' ? '600' : '100'}}>
                    <Icon name='phone square' style={{fontSize: '1.2em'}} color={this.props.mode=='widget' ? 'red' : 'yellow'}/>
                    <Header.Content>Phone Widget</Header.Content>
                    {this.props.mode=='widget' && <div className='arrowmenuadmin' />}
                </Header>
              </>}
              </>}

              {true && <>
              <Divider style={{margin: '1em .5em 1em .5em'}} />
              <div style={{display: 'flex', margin: '1em 0em 0em -.1em'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4' style={{fontWeight: 'bold', margin: '0'}} color={isExpand=='talk' ? 'red' : 'black'}>
                    <Icon name='mobile' style={{fontSize: '1.1em'}} circular inverted color={isExpand=='talk' ? 'red' : 'yellow'} />
                    <Header.Content>
                      Talk!
                      <Header.Subheader>In-App Call</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div>
                  <Icon name={isExpand=='talk' ? 'toggle on' : 'toggle off'} color={isExpand=='talk' ? 'red' : null} style={{fontSize: '1.3em', transform: 'rotate(90deg)', cursor: 'pointer'}} onClick={this.expandMenu.bind(this, 'talk')}/>
                </div>
              </div>
              {isExpand=='talk' && <>
                <Divider style={{margin: '.5em'}} />
                {/* <Header as='h4' style={{margin: '.5em 0em 0em .5em'}} color='blue'>
                  <Icon name='code' style={{fontSize: '1.1em'}} />
                  <Header.Content>Phone Widget</Header.Content>
                </Header> */}
                <Divider style={{margin: '.5em'}} hidden />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'tunnel', 'talk')}
                  color={this.props.mode=='tunnel' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='tunnel' ? '600' : '100'}}>
                    <Icon name='train' style={{fontSize: '1.2em'}} color={this.props.mode=='tunnel' ? 'red' : 'yellow'}/>
                    <Header.Content>Call Tunnel / Lines</Header.Content>
                    {this.props.mode=='tunnel' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'mau', 'talk')}
                  color={this.props.mode=='mau' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='mau' ? '600' : '100'}}>
                    <Icon name='users' style={{fontSize: '1.2em'}} color={this.props.mode=='mau' ? 'red' : 'yellow'}/>
                    <Header.Content>Customer License</Header.Content>
                    {this.props.mode=='mau' && <div className='arrowmenuadmin' />}
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'call link', 'talk')}
                  color={this.props.mode=='call link' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='call link' ? '600' : '100'}}>
                    <Icon name='at' style={{fontSize: '1.2em'}} color={this.props.mode=='call link' ? 'red' : 'yellow'}/>
                    <Header.Content>Call Link</Header.Content>
                    {this.props.mode=='call link' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'conference', 'talk')}
                  color={this.props.mode=='conference' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='conference' ? '600' : '100'}}>
                    <Icon name='browser' style={{fontSize: '1.2em'}} color={this.props.mode=='conference' ? 'red' : 'yellow'}/>
                    <Header.Content>Conference</Header.Content>
                    {this.props.mode=='conference' && <div className='arrowmenuadmin' />}
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'in-app widget', 'talk')}
                  color={this.props.mode=='in-app widget' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='in-app widget' ? '600' : '100'}}>
                    <Icon name='tablet alternate' style={{fontSize: '1.2em'}} color={this.props.mode=='in-app widget' ? 'red' : 'yellow'}/>
                    <Header.Content>In-App Widget</Header.Content>
                    {this.props.mode=='in-app widget' && <div className='arrowmenuadmin' />}
                </Header>
                {/* <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'web widget', 'talk')}
                  color={this.props.mode=='web widget' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='web widget' ? '600' : '100'}}>
                    <Icon name='chrome' style={{fontSize: '1.2em'}} color={this.props.mode=='web widget' ? 'red' : 'yellow'}/>
                    <Header.Content>Web Widget</Header.Content>
                    {this.props.mode=='web widget' && <div className='arrowmenuadmin' />}
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'IOS widget', 'talk')}
                  color={this.props.mode=='IOS widget' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='IOS widget' ? '600' : '100'}}>
                    <Icon name='apple' style={{fontSize: '1.3em'}} color={this.props.mode=='IOS widget' ? 'red' : 'yellow'}/>
                    <Header.Content>IOS Widget</Header.Content>
                    {this.props.mode=='IOS widget' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'android widget', 'talk')}
                  color={this.props.mode=='android widget' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='android widget' ? '600' : '100'}}>
                    <Icon name='android' style={{fontSize: '1.3em'}} color={this.props.mode=='android widget' ? 'red' : 'yellow'}/>
                    <Header.Content>Android Widget</Header.Content>
                    {this.props.mode=='android widget' && <div className='arrowmenuadmin' />}
                </Header> */}
              </>}
              </>}

              {true && <>
              <Divider style={{margin: '1em .5em 1em .5em'}} />
              <div style={{display: 'flex', margin: '1em 0em 0em -.1em'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4' style={{fontWeight: 'bold', margin: '0'}} color={isExpand=='ring' ? 'red' : 'black'}>
                    <Icon name='fax' style={{fontSize: '1.1em'}} circular inverted color={isExpand=='ring' ? 'red' : 'yellow'} />
                    <Header.Content>
                      Ring!
                      <Header.Subheader>Office Phone</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div>
                  <Icon name={isExpand=='ring' ? 'toggle on' : 'toggle off'} color={isExpand=='ring' ? 'red' : null} style={{fontSize: '1.3em', transform: 'rotate(90deg)', cursor: 'pointer'}} onClick={this.expandMenu.bind(this, 'ring')}/>
                </div>
              </div>
              {isExpand=='ring' && <>
                <Divider style={{margin: '.5em'}} />
                {/* <Header as='h4' style={{margin: '.5em 0em 0em .5em'}} color='blue'>
                  <Icon name='code' style={{fontSize: '1.1em'}} />
                  <Header.Content>Office Phone</Header.Content>
                </Header> */}
                <Divider style={{margin: '.5em'}} hidden />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'tunnel', 'ring')}
                  color={this.props.mode=='tunnel' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='tunnel' ? '600' : '100'}}>
                    <Icon name='train' style={{fontSize: '1.2em'}} color={this.props.mode=='tunnel' ? 'red' : 'yellow'}/>
                    <Header.Content>Call Tunnel / Lines</Header.Content>
                    {this.props.mode=='tunnel' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'mau', 'ring')}
                  color={this.props.mode=='mau' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='mau' ? '600' : '100'}}>
                    <Icon name='users' style={{fontSize: '1.2em'}} color={this.props.mode=='mau' ? 'red' : 'yellow'}/>
                    <Header.Content>Extension License</Header.Content>
                    {this.props.mode=='mau' && <div className='arrowmenuadmin' />}
                </Header>
                <Divider style={{margin: '.5em'}} hidden />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'desk phone', 'ring')}
                  color={this.props.mode=='desk phone' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='desk phone' ? '600' : '100'}}>
                    <Icon name='phone square' style={{fontSize: '1.2em'}} color={this.props.mode=='desk phone' ? 'red' : 'yellow'}/>
                    <Header.Content>Desk Phone Simulator</Header.Content>
                    {this.props.mode=='desk phone' && <div className='arrowmenuadmin' />}
                </Header>
              </>}
              </>}

              {true && <>
              <Divider style={{margin: '1em .5em 1em .5em'}} />
              <div style={{display: 'flex', margin: '1em 0em 0em -.1em'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4' style={{fontWeight: 'bold', margin: '0'}} color={isExpand=='middleware' ? 'red' : 'black'}>
                    <Icon name='code' style={{fontSize: '1.1em'}} circular inverted color={isExpand=='middleware' ? 'red' : 'yellow'} />
                    <Header.Content>
                      Middleware
                      <Header.Subheader>Autonomous service</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div>
                  <Icon name={isExpand=='middleware' ? 'toggle on' : 'toggle off'} color={isExpand=='middleware' ? 'red' : null} style={{fontSize: '1.3em', transform: 'rotate(90deg)', cursor: 'pointer'}} onClick={this.expandMenu.bind(this, 'middleware')}/>
                </div>
              </div>
              {isExpand=='middleware' && <>
                <Divider style={{margin: '.5em'}} />
                <Divider style={{margin: '.5em'}} hidden />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'OTP service', 'middleware')}
                  color={this.props.mode=='OTP service' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='OTP service' ? '600' : '100'}}>
                    <Icon name='hashtag' style={{fontSize: '1.3em'}} color={this.props.mode=='OTP service' ? 'red' : 'yellow'}/>
                    <Header.Content>OTP Service</Header.Content>
                    {this.props.mode=='OTP service' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'Comm service', 'middleware')}
                  color={this.props.mode=='Comm service' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='Comm service' ? '600' : '100'}}>
                    <Icon name='phone volume' style={{fontSize: '1.3em'}} color={this.props.mode=='Comm service' ? 'red' : 'yellow'}/>
                    <Header.Content>Comm Service</Header.Content>
                    {this.props.mode=='Comm service' && <div className='arrowmenuadmin' />}
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'API sandbox', 'middleware')}
                  color={this.props.mode=='API sandbox' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='API sandbox' ? '600' : '100'}}>
                    <Icon name='cube' style={{fontSize: '1.3em'}} color={this.props.mode=='API sandbox' ? 'red' : 'yellow'}/>
                    <Header.Content>API Sandbox</Header.Content>
                    {this.props.mode=='API sandbox' && <div className='arrowmenuadmin' />}
                </Header>
                <Divider style={{margin: '.5em'}} />
                {/* <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'API service', 'middleware')}
                  color={this.props.mode=='API service' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='API service' ? '600' : '100'}}>
                    <Icon name='code' style={{fontSize: '1.2em'}} color={this.props.mode=='API service' ? 'red' : 'yellow'}/>
                    <Header.Content>API Service</Header.Content>
                    {this.props.mode=='API service' && <div className='arrowmenuadmin' />}
                </Header> */}
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'API inventory', 'middleware')}
                  color={this.props.mode=='API inventory' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='API inventory' ? '600' : '100'}}>
                    <Icon name='fire' style={{fontSize: '1.2em'}} color={this.props.mode=='API inventory' ? 'red' : 'yellow'}/>
                    <Header.Content>API Inventory</Header.Content>
                    {this.props.mode=='API inventory' && <div className='arrowmenuadmin' />}
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'API logger', 'middleware')}
                  color={this.props.mode=='API logger' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='API logger' ? '600' : '100'}}>
                    <Icon name='file alternate outline' style={{fontSize: '1.2em'}} color={this.props.mode=='API logger' ? 'red' : 'yellow'}/>
                    <Header.Content>API Logger / Activity</Header.Content>
                    {this.props.mode=='API logger' && <div className='arrowmenuadmin' />}
                </Header>
                {/* <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'API webhook', 'middleware')}
                  color={this.props.mode=='API webhook' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='API webhook' ? '600' : '100'}}>
                    <Icon name='chrome' style={{fontSize: '1.2em'}} color={this.props.mode=='API webhook' ? 'red' : 'yellow'}/>
                    <Header.Content>API webhook</Header.Content>
                    {this.props.mode=='API webhook' && <div className='arrowmenuadmin' />}
                </Header> */}
              </>}
              </>}

              <Divider style={{margin: '1em .5em 1em .5em'}} hidden />
            </>}

            {false && <>
            <div style={{borderRadius: '5em', display: 'flex', margin: '.2em -1.5em 0em -1em', padding: '1em', cursor: 'pointer', background: isSubMenu=='addon' ? 'rgba(255, 255, 255, .1)' : 'rgba(255, 255, 255, .05)'}} onClick={this.expandSubMenu.bind(this, 'addon')}>
              <div style={{flex: '1', borderRight: '1px solid rgba(10, 10, 10, .3)'}}>
                <Header as ='h4'>
                  <Header.Content>
                    <b>Addon Module</b>
                  </Header.Content>
                </Header>
              </div>
              <div style={{borderLeft: '1px solid rgba(255, 255, 255, .9)', paddingLeft: '.5em'}}>
                <Icon name={isSubMenu=='addon' ? 'arrow left' : 'arrow right'} color={isSubMenu=='addon' ? 'red' : null} style={{fontSize: '1.1em' }} />
              </div>
            </div>
            {isSubMenu=='addon' && <>
              <div style={{display: 'flex', margin: '1em 0em 0em 0em'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4' style={{fontWeight: 'bold', margin: '0'}}  color={isExpand=='ticket' ? 'red' : 'grey'}>
                    <Icon name='ticket' style={{fontSize: '1em'}} circular inverted color={isExpand=='ticket' ? 'red' : 'yellow'} />
                    <Header.Content>Ticket</Header.Content>
                  </Header>
                </div>
                <div>
                  <Icon name={isExpand=='ticket' ? 'toggle on' : 'toggle off'} color={isExpand=='ticket' ? 'red' : null} style={{fontSize: '1.3em', transform: 'rotate(90deg)', cursor: 'pointer'}} onClick={this.expandMenu.bind(this, 'ticket')}/>
                </div>
              </div>
              {isExpand=='ticket' && <>
                <Divider style={{margin: '.5em'}} />
                <Divider style={{margin: '.5em'}} hidden />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'monitoring', 'ticket')}
                  color={this.props.mode=='monitoring' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='monitoring' ? '600' : '100'}}>
                    <Icon name='chart area' style={{fontSize: '1.2em'}} color={this.props.mode=='monitoring' ? 'red' : 'yellow'}/>
                    <Header.Content>Monitoring</Header.Content>
                    {this.props.mode=='monitoring' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h4' style={{margin: '1.5em 0em 0em .5em'}} color='blue'>
                  <Icon name='columns' style={{fontSize: '1.1em'}} />
                  <Header.Content>Helpdesk</Header.Content>
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'ticket', 'ticket')}
                  color={this.props.mode=='ticket' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='ticket' ? '600' : '100'}}>
                    <Icon name='ticket alternate' style={{fontSize: '1.2em'}} color={this.props.mode=='ticket' ? 'red' : 'yellow'}/>
                    <Header.Content>Ticket</Header.Content>
                    {this.props.mode=='ticket' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'handling', 'ticket')}
                  color={this.props.mode=='handling' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='handling' ? '600' : '100'}}>
                    <Icon name='magic' style={{fontSize: '1.2em'}} color={this.props.mode=='handling' ? 'red' : 'yellow'}/>
                    <Header.Content>Handlings</Header.Content>
                    {this.props.mode=='handling' && <div className='arrowmenuadmin' />}
                </Header>

                <Header as='h4' style={{margin: '1.5em 0em 0em .5em'}} color='blue'>
                  <Icon name='band aid' style={{fontSize: '1.1em'}} />
                  <Header.Content>Automation</Header.Content>
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'segment', 'ticket')}
                  color={this.props.mode=='segment' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='segment' ? '600' : '100'}}>
                    <Icon name='clone outline' style={{fontSize: '1.2em'}} color={this.props.mode=='segment' ? 'red' : 'yellow'}/>
                    <Header.Content>Segments</Header.Content>
                    {this.props.mode=='segment' && <div className='arrowmenuadmin' />}
                </Header>

                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'acknowledge', 'ticket')}
                  color={this.props.mode=='acknowledge' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='acknowledge' ? '600' : '100'}}>
                    <Icon name='send' style={{fontSize: '1.2em'}} color={this.props.mode=='acknowledge' ? 'red' : 'yellow'}/>
                    <Header.Content>Notification</Header.Content>
                    {this.props.mode=='acknowledge' && <div className='arrowmenuadmin' />}
                </Header>
              </>}
              <Divider style={{margin: '1em .5em 1em .5em'}} />
              <div style={{display: 'flex', margin: '.7em 0em 0em 0em'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4' style={{fontWeight: 'bold', margin: '0'}} color={isExpand=='kms' ? 'red' : 'grey'}>
                    <Icon name='window restore' style={{fontSize: '1em'}} circular inverted color={isExpand=='kms' ? 'red' : 'yellow'} />
                    <Header.Content>Knowledge</Header.Content>
                  </Header>
                </div>
                <div>
                  <Icon name={isExpand=='kms' ? 'toggle on' : 'toggle off'} color={isExpand=='kms' ? 'red' : null} style={{fontSize: '1.3em', transform: 'rotate(90deg)', cursor: 'pointer'}} onClick={this.expandMenu.bind(this, 'kms')}/>
                </div>
              </div>
              {isExpand=='kms' && <>
                <Divider style={{margin: '.5em'}} />
                <Divider style={{margin: '.5em'}} hidden />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'dashboard', 'knowledge')}
                  color={this.props.mode=='dashboard' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='dashboard' ? '600' : '100'}}>
                    <Icon name='language' style={{fontSize: '1.2em'}} color={this.props.mode=='dashboard' ? 'red' : 'yellow'}/>
                    <Header.Content>Explore</Header.Content>
                    {this.props.mode=='dashboard' && <div className='arrowmenuadmin' />}
                </Header>

                <Header as='h4' style={{margin: '1.5em 0em 0em .5em'}} color='blue'>
                  <Icon name='compass outline' style={{fontSize: '1.1em'}} />
                  <Header.Content>Personal</Header.Content>
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'project', 'knowledge')}
                  color={this.props.mode=='project' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='project' ? '600' : '100'}}>
                    <Icon name='space shuttle' style={{fontSize: '1.2em'}} color={this.props.mode=='project' ? 'red' : 'yellow'}/>
                    <Header.Content>Project</Header.Content>
                    {this.props.mode=='project' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'folder', 'knowledge')}
                  color={this.props.mode=='folder' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='folder' ? '600' : '100'}}>
                    <Icon name='folder' style={{fontSize: '1.2em'}} color={this.props.mode=='folder' ? 'red' : 'yellow'}/>
                    <Header.Content>Drive / Folder</Header.Content>
                    {this.props.mode=='folder' && <div className='arrowmenuadmin' />}
                </Header>
                
                <Header as='h4' style={{margin: '1.5em 0em 0em .5em'}} color='blue'>
                  <Icon name='hdd outline' style={{fontSize: '1em'}} />
                  <Header.Content>Publishing</Header.Content>
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'submission', 'knowledge')}
                  color={this.props.mode=='submission' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='submission' ? '600' : '100'}}>
                    <Icon name='paperclip' style={{fontSize: '1.2em'}} color={this.props.mode=='submission' ? 'red' : 'yellow'}/>
                    <Header.Content>Submission</Header.Content>
                    {this.props.mode=='submission' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'revision', 'knowledge')}
                  color={this.props.mode=='revision' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='revision' ? '600' : '100'}}>
                    <Icon name='keyboard outline' style={{fontSize: '1.2em'}} color={this.props.mode=='revision' ? 'red' : 'yellow'}/>
                    <Header.Content>Drafting / Revision</Header.Content>
                    {this.props.mode=='revision' && <div className='arrowmenuadmin' />}
                </Header>
              
              </>}
              <Divider style={{margin: '1em .5em 1em .5em'}} />
              <div style={{display: 'flex', margin: '.7em 0em 0em 0em'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4' style={{fontWeight: 'bold', margin: '0'}} color={isExpand=='crm' ? 'red' : 'grey'}>
                    <Icon name='address card' style={{fontSize: '1em'}} circular inverted color={isExpand=='crm' ? 'red' : 'yellow'} />
                    <Header.Content>Customer</Header.Content>
                  </Header>
                </div>
                <div>
                  <Icon name={isExpand=='crm' ? 'toggle on' : 'toggle off'} color={isExpand=='crm' ? 'red' : null} style={{fontSize: '1.3em', transform: 'rotate(90deg)', cursor: 'pointer'}} onClick={this.expandMenu.bind(this, 'crm')}/>
                </div>
              </div>
              {isExpand=='crm' && <>
                <Divider style={{margin: '.5em'}} />
                <Divider style={{margin: '.5em'}} hidden />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'dashboard', 'customer')}
                  color={this.props.mode=='dashboard' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='dashboard' ? '600' : '100'}}>
                    <Icon name='chart line' style={{fontSize: '1.2em'}} color={this.props.mode=='dashboard' ? 'red' : 'yellow'}/>
                    <Header.Content>Dashboard</Header.Content>
                    {this.props.mode=='dashboard' && <div className='arrowmenuadmin' />}
                </Header>
                
                <Header as='h4' style={{margin: '1.5em 0em 0em .5em'}} color='blue'>
                  <Icon name='calendar alternate outline' style={{fontSize: '1.1em'}} />
                  <Header.Content>Business</Header.Content>
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'appointment', 'customer')}
                  color={this.props.mode=='appointment' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='appointment' ? '600' : '100'}}>
                    <Icon name='bell outline' style={{fontSize: '1.2em'}} color={this.props.mode=='appointment' ? 'red' : 'yellow'}/>
                    <Header.Content>Appointment</Header.Content>
                    {this.props.mode=='appointment' && <div className='arrowmenuadmin' />}
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'prospect', 'customer')}
                  color={this.props.mode=='prospect' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='prospect' ? '600' : '100'}}>
                    <Icon name='shopping basket' style={{fontSize: '1.2em'}} color={this.props.mode=='prospect' ? 'red' : 'yellow'}/>
                    <Header.Content>Lead / Prospect</Header.Content>
                    {this.props.mode=='prospect' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'closing', 'customer')}
                  color={this.props.mode=='closing' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='closing' ? '600' : '100'}}>
                    <Icon name='cart plus' style={{fontSize: '1.2em'}} color={this.props.mode=='closing' ? 'red' : 'yellow'}/>
                    <Header.Content>Closing / Sales</Header.Content>
                    {this.props.mode=='closing' && <div className='arrowmenuadmin' />}
                </Header>
                
                

                <Header as='h4' style={{margin: '1.5em 0em 0em .5em'}} color='blue'>
                  <Icon name='credit card outline' style={{fontSize: '1em'}} />
                  <Header.Content>Transaction</Header.Content>
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'voucher', 'customer')}
                  color={this.props.mode=='voucher' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='voucher' ? '600' : '100'}}>
                    <Icon name='qrcode' style={{fontSize: '1.2em'}} color={this.props.mode=='voucher' ? 'red' : 'yellow'}/>
                    <Header.Content>Voucher Usage</Header.Content>
                    {this.props.mode=='voucher' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'discount', 'customer')}
                  color={this.props.mode=='discount' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='discount' ? '600' : '100'}}>
                    <Icon name='percent' style={{fontSize: '1.2em'}} color={this.props.mode=='discount' ? 'red' : 'yellow'}/>
                    <Header.Content>Discount / Voucher</Header.Content>
                    {this.props.mode=='discount' && <div className='arrowmenuadmin' />}
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'purchase', 'customer')}
                  color={this.props.mode=='purchase' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .8em', cursor: 'pointer', fontWeight: this.props.mode=='purchase' ? '600' : '100'}}>
                    <Icon name='credit card outline' style={{fontSize: '1.1em'}} color={this.props.mode=='purchase' ? 'red' : 'yellow'}/>
                    <Header.Content>Purchase</Header.Content>
                    {this.props.mode=='purchase' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'subscribe', 'customer')}
                  color={this.props.mode=='subscribe' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='subscribe' ? '600' : '100'}}>
                    <Icon name='calendar check outline' style={{fontSize: '1.2em'}} color={this.props.mode=='subscribe' ? 'red' : 'yellow'}/>
                    <Header.Content>Subscription</Header.Content>
                    {this.props.mode=='subscribe' && <div className='arrowmenuadmin' />}
                </Header>
                
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'custbill', 'customer')}
                  color={this.props.mode=='custbill' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='custbill' ? '600' : '100'}}>
                    <Icon name='file alternate outline' style={{fontSize: '1.2em'}} color={this.props.mode=='custbill' ? 'red' : 'yellow'}/>
                    <Header.Content>Cust. Billing</Header.Content>
                    {this.props.mode=='custbill' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'custpayment', 'customer')}
                  color={this.props.mode=='custpayment' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='custpayment' ? '600' : '100'}}>
                    <Icon name='file alternate outline' style={{fontSize: '1.2em'}} color={this.props.mode=='custpayment' ? 'red' : 'yellow'}/>
                    <Header.Content>Cust. Payment</Header.Content>
                    {this.props.mode=='custpayment' && <div className='arrowmenuadmin' />}
                </Header>
                
                <Header as='h4' style={{margin: '1.5em 0em 0em .5em'}} color='blue'>
                  <Icon name='tags' style={{fontSize: '1em'}} />
                  <Header.Content>Lifecycle</Header.Content>
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'reach', 'customer')}
                  color={this.props.mode=='reach' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='reach' ? '600' : '100'}}>
                    <Icon name='heart outline' style={{fontSize: '1.2em'}} color={this.props.mode=='reach' ? 'red' : 'yellow'}/>
                    <Header.Content>Reach / Awareness</Header.Content>
                    {this.props.mode=='reach' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'acquisition', 'customer')}
                  color={this.props.mode=='acquisition' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='acquisition' ? '600' : '100'}}>
                    <Icon name='coffee' style={{fontSize: '1.2em'}} color={this.props.mode=='acquisition' ? 'red' : 'yellow'}/>
                    <Header.Content>Acquisition / Engagement</Header.Content>
                    {this.props.mode=='acquisition' && <div className='arrowmenuadmin' />}
                </Header>
                
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'conversion', 'customer')}
                  color={this.props.mode=='conversion' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='conversion' ? '600' : '100'}}>
                    <Icon name='handshake outline' style={{fontSize: '1.2em'}} color={this.props.mode=='conversion' ? 'red' : 'yellow'}/>
                    <Header.Content>Conversion / Purchase</Header.Content>
                    {this.props.mode=='conversion' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'retention', 'customer')}
                  color={this.props.mode=='retention' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='retention' ? '600' : '100'}}>
                    <Icon name='unlock alternate' style={{fontSize: '1.2em'}} color={this.props.mode=='retention' ? 'red' : 'yellow'}/>
                    <Header.Content>Retention / Product and support experience</Header.Content>
                    {this.props.mode=='retention' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'loyalty', 'customer')}
                  color={this.props.mode=='loyalty' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='loyalty' ? '600' : '100'}}>
                    <Icon name='birthday cake' style={{fontSize: '1.2em'}} color={this.props.mode=='loyalty' ? 'red' : 'yellow'}/>
                    <Header.Content>Loyalty / Bonding/Advocacy</Header.Content>
                    {this.props.mode=='loyalty' && <div className='arrowmenuadmin' />}
                </Header>
                
                <Header as='h4' style={{margin: '1.5em 0em 0em .5em'}} color='blue'>
                  <Icon name='address card outline' style={{fontSize: '1.2em'}} />
                  <Header.Content>Customer</Header.Content>
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'visitor', 'customer')}
                  color={this.props.mode=='visitor' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='visitor' ? '600' : '100'}}>
                    <Icon name='id badge outline' style={{fontSize: '1.2em'}} color={this.props.mode=='visitor' ? 'red' : 'yellow'}/>
                    <Header.Content>Visitor</Header.Content>
                    {this.props.mode=='visitor' && <div className='arrowmenuadmin' />}
                </Header>
                <Divider style={{margin: '.5em'}} />
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'customer', 'customer')}
                  color={this.props.mode=='customer' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='customer' ? '600' : '100'}}>
                    <Icon name='address card outline' style={{fontSize: '1.2em'}} color={this.props.mode=='customer' ? 'red' : 'yellow'}/>
                    <Header.Content>Customer</Header.Content>
                    {this.props.mode=='customer' && <div className='arrowmenuadmin' />}
                </Header>
                <Header as='h5'
                  onClick={this.props.openWindow.bind(this, 'product', 'customer')}
                  color={this.props.mode=='product' ? null : 'grey'}
                  style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='product' ? '600' : '100'}}>
                    <Icon name='folder outline' style={{fontSize: '1.2em'}} color={this.props.mode=='product' ? 'red' : 'yellow'}/>
                    <Header.Content>Product / Service</Header.Content>
                    {this.props.mode=='product' && <div className='arrowmenuadmin' />}
                </Header>
              </>}
              <Divider style={{margin: '1em .5em 1em .5em'}} hidden />
            </>}
            </>}

            <div style={{borderRadius: '5em', display: 'flex', margin: '.2em -1.5em 0em -1em', padding: '1em', cursor: 'pointer', background: isSubMenu=='system' ? 'rgba(255, 255, 255, .1)' : 'rgba(255, 255, 255, .05)'}} onClick={this.expandSubMenu.bind(this, 'system')}>
              <div style={{flex: '1', borderRight: '1px solid rgba(10, 10, 10, .3)'}}>
                <Header as ='h4'>
                  <Header.Content>
                    <b>System Module</b>
                  </Header.Content>
                </Header>
              </div>
              <div style={{borderLeft: '1px solid rgba(255, 255, 255, .9)', paddingLeft: '.5em'}}>
                <Icon name={isSubMenu=='system' ? 'arrow left' : 'arrow right'} color={isSubMenu=='system' ? 'red' : null} style={{fontSize: '1.1em' }} />
              </div>
            </div>
            {isSubMenu=='system' && <>
              <div style={{display: 'flex', margin: '1em 0em 0em 0em'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4' style={{fontWeight: 'bold', margin: '0'}} color={isExpand=='resource' ? 'red' : 'grey'}>
                    <Icon name='server' style={{fontSize: '1em'}} circular inverted color={isExpand=='resource' ? 'red' : 'yellow'} />
                    <Header.Content>Resource</Header.Content>
                  </Header>
                </div>
                <div>
                  <Icon name={isExpand=='resource' ? 'toggle on' : 'toggle off'} color={isExpand=='resource' ? 'red' : null} style={{fontSize: '1.3em', transform: 'rotate(90deg)', cursor: 'pointer'}} onClick={this.expandMenu.bind(this, 'resource')}/>
                </div>
              </div>
              {isExpand=='resource' && <>
              <Divider style={{margin: '.5em'}} />
              <Divider style={{margin: '.5em'}} hidden />
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'appointment', 'halo')}
                color={this.props.mode=='appointment' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='appointment' ? '600' : '100'}}>
                  <Icon name='bell outline' style={{fontSize: '1.2em'}} color={this.props.mode=='appointment' ? 'red' : 'yellow'}/>
                  <Header.Content>Appointment</Header.Content>
                  {this.props.mode=='appointment' && <div className='arrowmenuadmin' />}
              </Header>
              <Divider style={{margin: '.5em'}} />
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'product', 'halo')}
                color={this.props.mode=='product' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='product' ? '600' : '100'}}>
                  <Icon name='folder outline' style={{fontSize: '1.2em'}} color={this.props.mode=='product' ? 'red' : 'yellow'}/>
                  <Header.Content>Product / Service</Header.Content>
                  {this.props.mode=='product' && <div className='arrowmenuadmin' />}
              </Header>

              <Header as='h4' style={{margin: '1.5em 0em 0em .5em'}} color='blue'>
                <Icon name='address card outline' style={{fontSize: '1.2em'}} />
                <Header.Content>Customer</Header.Content>
              </Header>
              <Divider style={{margin: '.5em'}} />
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'visitor', 'halo')}
                color={this.props.mode=='visitor' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='visitor' ? '600' : '100'}}>
                  <Icon name='id badge outline' style={{fontSize: '1.2em'}} color={this.props.mode=='visitor' ? 'red' : 'yellow'}/>
                  <Header.Content>Visitor</Header.Content>
                  {this.props.mode=='visitor' && <div className='arrowmenuadmin' />}
              </Header>
              <Divider style={{margin: '.5em'}} />
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'customer', 'halo')}
                color={this.props.mode=='customer' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='customer' ? '600' : '100'}}>
                  <Icon name='address card outline' style={{fontSize: '1.2em'}} color={this.props.mode=='customer' ? 'red' : 'yellow'}/>
                  <Header.Content>Customer</Header.Content>
                  {this.props.mode=='customer' && <div className='arrowmenuadmin' />}
              </Header>
              <Divider style={{margin: '.5em'}} />
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'contact', 'halo')}
                color={this.props.mode=='contact' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='contact' ? '600' : '100'}}>
                  <Icon name='address card outline' style={{fontSize: '1.2em'}} color={this.props.mode=='contact' ? 'red' : 'yellow'}/>
                  <Header.Content>Unique Contact</Header.Content>
                  {this.props.mode=='contact' && <div className='arrowmenuadmin' />}
              </Header>
              <Header as='h4' style={{margin: '1.5em 0em 0em .5em'}} color='blue'>
                <Icon name='folder outline' style={{fontSize: '1.2em'}} />
                <Header.Content>Reference</Header.Content>
              </Header>
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'agent', 'halo')}
                color={this.props.mode=='agent' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='agent' ? '600' : '100'}}>
                  <Icon name='user circle' style={{fontSize: '1.2em'}} color={this.props.mode=='agent' ? 'red' : 'yellow'}/>
                  <Header.Content>Desk Agent</Header.Content>
                  {this.props.mode=='agent' && <div className='arrowmenuadmin' />}
              </Header>
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'workgroup', 'halo')}
                color={this.props.mode=='workgroup' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer'}}>
                  <Icon name='object ungroup outline' style={{fontSize: '1.2em'}} color={this.props.mode=='workgroup' ? 'red' : 'yellow'}/>
                  <Header.Content>Team / Workgroup</Header.Content>
                  {this.props.mode=='workgroup' && <div className='arrowmenuadmin' />}
              </Header>
              <Divider style={{margin: '.5em'}} />
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'script', 'halo')}
                color={this.props.mode=='script' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='script' ? '600' : '100'}}>
                  <Icon name='file code outline' style={{fontSize: '1.2em'}} color={this.props.mode=='script' ? 'red' : 'yellow'}/>
                  <Header.Content>Template Script</Header.Content>
                  {this.props.mode=='script' && <div className='arrowmenuadmin' />}
              </Header>
              </>}
              <Divider style={{margin: '1em .5em 1em .5em'}} />
              <div style={{display: 'flex', margin: '1em 0em 0em 0em'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4' style={{fontWeight: 'bold', margin: '0'}} color={isExpand=='ecosystem' ? 'red' : 'grey'}>
                    <Icon name='shield alternate' style={{fontSize: '1em'}} circular inverted color={isExpand=='ecosystem' ? 'red' : 'yellow'} />
                    <Header.Content>Ecosystem</Header.Content>
                  </Header>
                </div>
                <div>
                  <Icon name={isExpand=='ecosystem' ? 'toggle on' : 'toggle off'} color={isExpand=='ecosystem' ? 'red' : null} style={{fontSize: '1.3em', transform: 'rotate(90deg)', cursor: 'pointer'}} onClick={this.expandMenu.bind(this, 'ecosystem')}/>
                </div>
              </div>
              {isExpand=='ecosystem' && <>
              <Divider style={{margin: '.5em'}} />
              {/* <Header as='h4' style={{margin: '1.5em 0em 0em .5em'}} color='blue'>
                  <Icon name='building' style={{fontSize: '1em'}} />
                  <Header.Content>Client</Header.Content>
              </Header> */}
              <Divider style={{margin: '.5em'}} hidden />
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'billing', 'halo')}
                color={this.props.mode=='billing' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='billing' ? '600' : '100'}}>
                  <Icon name='file alternate outline' style={{fontSize: '1.2em'}} color={this.props.mode=='billing' ? 'red' : 'yellow'}/>
                  <Header.Content>Cloud Billing</Header.Content>
                  {this.props.mode=='billing' && <div className='arrowmenuadmin' />}
              </Header>
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'client', 'halo')}
                color={this.props.mode=='client' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='client' ? '600' : '100'}}>
                  <Icon name='building outline' style={{fontSize: '1.2em'}} color={this.props.mode=='client' ? 'red' : 'yellow'}/>
                  <Header.Content>Cloud Client</Header.Content>
                  {this.props.mode=='client' && <div className='arrowmenuadmin' />}
              </Header>
              <Divider style={{margin: '.5em'}} />
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'setting', 'halo')}
                color={this.props.mode=='setting' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='setting' ? '600' : '100'}}>
                  <Icon name='setting' style={{fontSize: '1.2em'}} color={this.props.mode=='setting' ? 'red' : 'yellow'}/>
                  <Header.Content>Realm Setting</Header.Content>
                  {this.props.mode=='setting' && <div className='arrowmenuadmin' />}
              </Header>
              
              <Header as='h4' style={{margin: '1.5em 0em 0em .5em'}} color='blue'>
                  <Icon name='heart' style={{fontSize: '1em'}} />
                  <Header.Content>Content</Header.Content>
              </Header>
              <Divider style={{margin: '.5em'}} />
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'blogging', 'halo')}
                color={this.props.mode=='blogging' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='blogging' ? '600' : '100'}}>
                  <Icon name='heart outline' style={{fontSize: '1.2em'}} color={this.props.mode=='blogging' ? 'red' : 'yellow'}/>
                  <Header.Content>Site's Blogging</Header.Content>
                  {this.props.mode=='blogging' && <div className='arrowmenuadmin' />}
              </Header>
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'document', 'halo')}
                color={this.props.mode=='document' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='document' ? '600' : '100'}}>
                  <Icon name='folder outline' style={{fontSize: '1.2em'}} color={this.props.mode=='document' ? 'red' : 'yellow'}/>
                  <Header.Content>Site's Document</Header.Content>
                  {this.props.mode=='document' && <div className='arrowmenuadmin' />}
              </Header>
              
              <Header as='h4' style={{margin: '1.5em 0em 0em .5em'}} color='blue'>
                  <Icon name='microchip' style={{fontSize: '1em'}} />
                  <Header.Content>System</Header.Content>
              </Header>
              {/* <Divider style={{margin: '.5em'}} />
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'syslog')}
                color={this.props.mode=='syslog' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='syslog' ? '600' : '100'}}>
                  <Icon name='paw' style={{fontSize: '1.2em'}} color={this.props.mode=='syslog' ? 'red' : 'yellow'}/>
                  <Header.Content>System Logs</Header.Content>
                  {this.props.mode=='syslog' && <div className='arrowmenuadmin' />}
              </Header> */}
              <Divider style={{margin: '.5em'}} />
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'waserver', 'halo')}
                color={this.props.mode=='waserver' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='waserver' ? '600' : '100'}}>
                  <Icon name='whatsapp' style={{fontSize: '1.2em'}} color={this.props.mode=='waserver' ? 'red' : 'yellow'}/>
                  <Header.Content>WA Server</Header.Content>
                  {this.props.mode=='waserver' && <div className='arrowmenuadmin' />}
              </Header>
              
              <Divider style={{margin: '.5em'}} />
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'voip', 'halo')}
                color={this.props.mode=='voip' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='voip' ? '600' : '100'}}>
                  <Icon name='save outline' style={{fontSize: '1.2em'}} color={this.props.mode=='voip' ? 'red' : 'yellow'}/>
                  <Header.Content>Voip Account</Header.Content>
                  {this.props.mode=='voip' && <div className='arrowmenuadmin' />}
              </Header>
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'whitelist', 'halo')}
                color={this.props.mode=='whitelist' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='whitelist' ? '600' : '100'}}>
                  <Icon name='check circle' style={{fontSize: '1.2em'}} color={this.props.mode=='whitelist' ? 'red' : 'yellow'}/>
                  <Header.Content>Net Whitelist</Header.Content>
                  {this.props.mode=='whitelist' && <div className='arrowmenuadmin' />}
              </Header>
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'trespassing', 'halo')}
                color={this.props.mode=='trespassing' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='trespassing' ? '600' : '100'}}>
                  <Icon name='warning circle' style={{fontSize: '1.2em'}} color={this.props.mode=='trespassing' ? 'red' : 'yellow'}/>
                  <Header.Content>Net Intrussion</Header.Content>
                  {this.props.mode=='trespassing' && <div className='arrowmenuadmin' />}
              </Header>
              <Divider style={{margin: '.5em'}} />
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'postpaid', 'halo')}
                color={this.props.mode=='postpaid' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='postpaid' ? '600' : '100'}}>
                  <Icon name='fire' style={{fontSize: '1.2em'}} color={this.props.mode=='postpaid' ? 'red' : 'yellow'}/>
                  <Header.Content>Postpaid Billing</Header.Content>
                  {this.props.mode=='postpaid' && <div className='arrowmenuadmin' />}
              </Header>
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'record', 'halo')}
                color={this.props.mode=='record' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='record' ? '600' : '100'}}>
                  <Icon name='file alternate outline' style={{fontSize: '1.2em'}} color={this.props.mode=='record' ? 'red' : 'yellow'}/>
                  <Header.Content>Billing Record</Header.Content>
                  {this.props.mode=='record' && <div className='arrowmenuadmin' />}
              </Header>
              <Divider style={{margin: '.5em'}} />
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'prepaid', 'halo')}
                color={this.props.mode=='prepaid' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='prepaid' ? '600' : '100'}}>
                  <Icon name='dot circle' style={{fontSize: '1.2em'}} color={this.props.mode=='prepaid' ? 'red' : 'yellow'}/>
                  <Header.Content>Prepaid Credit</Header.Content>
                  {this.props.mode=='prepaid' && <div className='arrowmenuadmin' />}
              </Header>
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'deduct', 'halo')}
                color={this.props.mode=='deduct' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='deduct' ? '600' : '100'}}>
                  <Icon name='file alternate outline' style={{fontSize: '1.2em'}} color={this.props.mode=='deduct' ? 'red' : 'yellow'}/>
                  <Header.Content>Credit Deduction</Header.Content>
                  {this.props.mode=='deduct' && <div className='arrowmenuadmin' />}
              </Header>
              <Divider style={{margin: '.5em'}} />
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'rates', 'halo')}
                color={this.props.mode=='rates' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='rates' ? '600' : '100'}}>
                  <Icon name='file alternate outline' style={{fontSize: '1.2em'}} color={this.props.mode=='rates' ? 'red' : 'yellow'}/>
                  <Header.Content>Service Rate/Cost</Header.Content>
                  {this.props.mode=='rates' && <div className='arrowmenuadmin' />}
              </Header>
              <Divider style={{margin: '.5em'}} />
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'audit', 'halo')}
                color={this.props.mode=='audit' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='audit' ? '600' : '100'}}>
                  <Icon name='paw' style={{fontSize: '1.2em'}} color={this.props.mode=='audit' ? 'red' : 'yellow'}/>
                  <Header.Content>User Audit Trail</Header.Content>
                  {this.props.mode=='audit' && <div className='arrowmenuadmin' />}
              </Header>
              <Header as='h5'
                onClick={this.props.openWindow.bind(this, 'error', 'halo')}
                color={this.props.mode=='error' ? null : 'grey'}
                style={{margin: '.5em 0 0 .7em', cursor: 'pointer', fontWeight: this.props.mode=='error' ? '600' : '100'}}>
                  <Icon name='bug' style={{fontSize: '1.2em'}} color={this.props.mode=='error' ? 'red' : 'yellow'}/>
                  <Header.Content>Error / Bug Control</Header.Content>
                  {this.props.mode=='error' && <div className='arrowmenuadmin' />}
              </Header>
              </>}
            </>}
          </div>

          <div style={{padding: '0 0', background: 'rgb(105, 99, 99)'}}>
            <Header as='h5' inverted style={{padding: '1em 1em'}}>
              <Header.Content>
              Halo Pagi!
              <Header.Subheader>Digital Communication 2.3.01</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
        </div>
      );
    }
  }
}
