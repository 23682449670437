import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Label, Form, TextArea } from 'semantic-ui-react'

import socketIOClient from "socket.io-client";
import ModalContainer from '../../libraries/common/ModalContainer';

import Emulator from '../../libraries/emulator/Emulator';
import { format, parse } from "date-fns"

import { connect } from "react-redux"
import { accountOmniphone, contactOmniphone } from "../../actions/stream/omniphoneAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omniphoneStore: state.omniphoneStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    accountOmniphone:(objParam) => dispatch(accountOmniphone(objParam)),
    contactOmniphone:(objParam) => dispatch(contactOmniphone(objParam)),
  }
}

const chromesocket=(config, comp)=>{
  const parent= comp;

  const socket = socketIOClient(
    // 'http://127.0.0.1:4001', {
    'wss://crm.halopagi.com', // urlSocket, 
    {
        path: '/wasap',
        transports: ['websocket'],
        // transports: ['polling'],
        reconnection: false,
        // reconnectionDelay: 1000,
        // reconnectionAttempts: 10,
        query: {
            // token: localStorage.getItem('tokenAuthChrome'),
            // apiKey: config.apiKey, //'1111-3434-9085-3227', //API Key Client
            // secretKey: config.secretKey, //'WA-DEV-SECRET', //credential WA
            // mobile: config.mobile, //'6281296272484', //mobile number sender
            apiKey: '1111-3434-9085-3227', //API Key Client
            secretKey: 'WA-DEV-SECRET', //credential WA
            mobile: '6281296272484', //mobile number sender
        }
  });
  
  socket.on('connect', () => {
    const {socketLogs}= parent.state;

    console.log('!!! WS CONNECT', socket.connected); // true
    socketLogs.push('WS Connected');
    parent.setState({
      isConnected: true,
      socketLogs: socketLogs
    })
  });
  socket.on('disconnect', (reason) => {
    const {socketLogs}= parent.state;
    
    console.log('!!! WS DISCONNECT', socket.connected); // true
    socketLogs.push('WS Disconnected');
    parent.setState({
      isConnected: false,
      socketLogs: socketLogs
    })
    if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
    }
  });
  socket.on('connect_error', (error) => {
    const {socketLogs}= parent.state;
    
    console.log('!!! WS CONNECT ERROR', error); // true
    socketLogs.push('WS connect error')
    parent.setState({
      isError: true,
      socketLogs: socketLogs
    })
    // parent.props.socketStatus({isConnected: false, error: error})
  });
  socket.onAny((event, ...args) => {
    const {socketLogs}= parent.state;
    
      console.log(`!!! WS EVENT ANY`, event, args);
      socketLogs.push('WS on event '+ event +' / '+ JSON.stringify(args))
      parent.setState({
        socketLogs: socketLogs
      })
  });

  return socket;
}

//REQUEST WITH TIMEOUT
const socketRequest=(event, payload, socket, parent)=>{
  const timeout= 20000;
  const {socketLogs}= parent.state;
      
  return new Promise((resolve, reject)=>{
    try {
      //NO TIME OUT MECHANISM
      socket.emit(event, payload, 
      // (response) => {
      //   // console.log('!!! RESPONSE', response); // ok
      //   resolve(response);
      // });

      //WITH TIMEOUT MECHANISM
      withTimeout(
        (response) => {
          console.log('!!! WS REQUEST OK', response);
          socketLogs.push('Send Request OK '+ JSON.stringify(response));
          parent.setState({
            socketLogs: socketLogs
          })
          resolve(response);
          // resolve({code:'00', error: 'Succeed'}) 
        }, 
        () => {
          console.log('!!! WS REQUEST RTO', );
          socketLogs.push('Send Request RTO')
          parent.setState({
            socketLogs: socketLogs
          })
          resolve({code:'01', error: 'Timeout request'}) 
        }
        , timeout));
    } catch(error) {
      console.log('!!! WS TRANSMIT ERROR', error); // true
      socketLogs.push('Send Request error')
      parent.setState({
        socketLogs: socketLogs
      })
      reject({code:'01', error: error}) 
    }
  });
}

//TRANSMIT / PULL SERVICE, SEND EVENT AND RECEIVE REPLY FROM SOCKET
const socketTransmit= (event, payload, socket, parent)=>{
  const {socketLogs}= parent.state;

  return new Promise((resolve, reject)=>{
    try {
      socket.emit(event, payload);
      console.log('!!! WS TRANSMIT OK')
      socketLogs.push('Send Transmit OK')
      parent.setState({
        socketLogs: socketLogs
      })
      resolve({code:'00', text: 'Transmitted'});
    } catch(error) {
      console.log('!!! WS TRANSMIT ERROR', error); // true
      socketLogs.push('Send Transmit error')
      parent.setState({
        socketLogs: socketLogs
      })
      reject({code:'01', error: error}) 
    }
  });
}

const withTimeout = (onSuccess, onTimeout, timeout) => {
  let isTimeout = false;

  const timer = setTimeout(() => {
    if (isTimeout) return;
    isTimeout = true;
    onTimeout();
  }, timeout);

  return (...args) => {
    if (isTimeout) return;
    isTimeout = true;
    clearTimeout(timer);
    onSuccess.apply(this, args);
  }
}


class EmulatorBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
      callLogs: [],

      socket: null,
      socketLogs:[],
    }
  }

  componentDidMount() {
    this.setState({
      socket: chromesocket({
        apiKey: '1111-3434-9085-3227', //API Key Client
        secretKey: 'WA-DEV-SECRET', //credential WA
        mobile: '6281296272484', //mobile number sender
      }, this),
    })
    
    this.props.accountOmniphone();
    navigator.mediaDevices.getUserMedia({ audio: true, video: false });
  }

  saveLog(log) {
  const { callLogs }= this.state
    callLogs.push(log);

    this.setState({
      callLogs: callLogs.length>50 ? callLogs.slice(25) : callLogs
    })
  }

  sendRequest(event, payload) {
    const {socketLogs}= this.state
    socketLogs.push('Send Request '+ event +' / '+ JSON.stringify(payload));

    this.setState({
      socketLogs: socketLogs
    })
    //req://wa:token, req://wa:tokenExt, req://wa:stat
    if (event=='req://wa:token') {
      socketRequest(event, payload, this.state.socket, this)
      .then(response=>{
        localStorage.setItem('tokenAuthChrome', response.token)
      });
    } else {
      socketRequest(event, payload, this.state.socket, this);
    }
  }

  sendTransmit(event, payload) {
    const {socketLogs}= this.state
    socketLogs.push('Send Transmit '+ event +' / '+ JSON.stringify(payload))
    this.setState({
      socketLogs: socketLogs
    })
    socketTransmit(event, payload, this.state.socket, this);
  }

  clearLog() {
    this.setState({
      socketLogs: [],
      callLogs: [],
    })
  }

  render() {
    const { callLogs }=this.state
    const { mode, screenWidth, screenHeight, mini }= this.props
    const { data }= (this.props.omniphoneStore.accountOmniphone!==undefined ? this.props.omniphoneStore.accountOmniphone : {})

    return (
      <div>
        {screenWidth>1024 && 
          <div style={{marginTop: '3em'}}>
            <div style={{padding: '1.5em'}}>
              <Segment style={{overflow: 'scroll'}}>
                <Header as='h3'>
                  <Icon name='file alternate outline' color={(this.state.isConnected && 'blue') || 'red'}/>
                  <Header.Content>
                    SOCKET LOG !
                    <Header.Subheader>Phone activity log is written down here.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Divider horizontal>
                  <Button onClick={
                    this.sendRequest.bind(this, 'req://wa:token', {
                      apiKey: '1111-3434-9085-3227', //API Key Client
                      secretKey: 'WA-DEV-SECRET', //credential WA
                      mobile: '6281296272484', //mobile number sender
                    })
                  }>Request Token</Button>
                  <Button onClick={
                    this.sendRequest.bind(this, 'req://wa:tokenExt', {
                      token: localStorage.getItem('tokenAuthChrome'), //token existing
                    })
                  }>Refresh Token</Button>
                  <Button onClick={
                    this.sendRequest.bind(this, 'req://wa:stat', {
                      token: localStorage.getItem('tokenAuthChrome'), //token existing
                    })
                  }>Request Stat</Button>
                  <Button onClick={
                    this.sendTransmit.bind(this,'pull://wa:ack', {
                      ack:'sent', //sent, read, failed, incoming
                      message: {
                        id: 'id',
                        waId: 'waId',
                        type: 'text', //text, media, link
                        P: '62xxxx',
                        message: 'text message',
                        template: 'ref. template',
                      },
                    })
                  }>Transmit Event</Button>
                  <Button onClick={
                    this.clearLog.bind(this)
                  }>Clear Logs!</Button>
                </Divider>

                {
                  this.state.socketLogs && this.state.socketLogs.map((item, index)=>{
                    return(
                      <Header key={index} as='h5' style={{margin: '.2em -.1em'}}>
                        <Header.Content>
                        <Label color='teal' style={{width: '2.5em', margin: '0 .1em', padding: '.5em .5em'}}>{index+1}.</Label><Label color='grey' style={{margin: '0em .1em', padding: '.5em .5em'}}>{item.length>200 ? item.substr(0,150)+' ...' : item}</Label>
                        </Header.Content>
                      </Header>
                    )
                  })
                }
              </Segment>
            </div>
          </div>
        }
        
        <div style={{background: '#f5f5f5', display: 'flex', marginTop: screenWidth>1024 ? '2em' : '4em', minHeight: screenHeight, padding: '1.5em', overflow: 'hidden'}}>
          {screenWidth>1024 && 
            <div style={{flex: '3', marginRight: '2em'}}>
              <Segment>
                <Header as='h3'>
                  <Icon name='file alternate outline' />
                  <Header.Content>
                    CALL LOG !
                    <Header.Subheader>Phone activity log is written down here.</Header.Subheader>
                  </Header.Content>
                </Header>
                
                <Divider />
                {
                  callLogs && callLogs.map((item, index)=>{
                    // console.log(item.stamp, item.text)
                    return(
                      <Header key={index} as='h5' style={{margin: '.2em -.1em'}}>
                        <Header.Content>
                        <Label color='teal' style={{width: '2.5em', margin: '0 .1em', padding: '.5em .5em'}}>{index+1}.</Label><Label color='orange' style={{margin: '0 .1em', padding: '.5em .5em'}}>{item.stamp && format(new Date(item.stamp), 'HH:mm:ss')}</Label><Label color='grey' style={{margin: '0em .1em', padding: '.5em .5em'}}>{item.text}</Label>
                        </Header.Content>
                      </Header>
                    )
                  })
                }
              </Segment>
            </div>
          }

          <div style={{flex: 1, background: '#f0f0f0', borderRadius: '1.5em'}}>
            {/* <MessagingWidget /> */}
            {/* <OmniphoneWidget /> */}
            {data!==undefined && data.voipAccount!==undefined &&
              // <Emulator
              // mode='mini'
              // sipAccount='7000000001' 
              // sipPasswd='111111'
              // sipHost='call.halopagi.com:4063' 
              // sipRealm='halopagi'/>
              <Emulator
              mode='mini'
              sipAccount={data.voipAccount}
              sipPasswd={data.voipPasswd}
              sipHost={data.voipHost +':'+ data.voipPort}
              sipRealm={data.voipRealm}
              screenHeight= {screenHeight}

              saveLog={this.saveLog.bind(this)}
              />
            }

           {false && <div style={{padding: '1em', position: 'relative'}}>
              <div style={{background: '#eaeaea', border: 'none'}}>
                <div style={{position: 'absolute', right: 0, bottom: 0, width: this.props.width/4 || 80, height: this.props.height/4 || 60, overflow: 'hidden', background: 'rgba(255, 255, 0, 1)', margin: '.5em 3em 1em .5em'}}>
                  <video id="localView" autoPlay muted={true} width={this.props.width/4 || 80} height={this.props.height/4 || 60}></video>
                </div>
                <div style={{textAlign: 'center', margin: '-.5em 0 -1em 0', height: this.props.height || 240, overflow: 'hidden'}}>
                  <video id="remoteView" autoPlay  width={this.props.width || 320} height={this.props.height || 240}></video>
                </div>
              </div>
            </div>}
            
          </div>
        </div>
        
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmulatorBody)