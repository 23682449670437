import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import AppointmentBrowse from '../../libraries/halo/AppointmentBrowse'
import AppointmentForm from '../../libraries/halo/AppointmentForm'
import AppointmentSearch from '../../libraries/halo/AppointmentSearch'
import AppointmentWidget from '../../libraries/widget/AppointmentWidget'


class AppointmentBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        appointmentId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, appointmentId: id})
    }

    render() {
      const {mode, appointmentId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              <AppointmentSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
              <div>
                {mode==1 && <AppointmentBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
                {mode==2 && <AppointmentForm mode={mode} appointmentId={appointmentId} setMode={this.setMode.bind(this)} />}
                {mode==3 && <AppointmentForm mode={mode} appointmentId={0} setMode={this.setMode.bind(this)} />}
              </div>
            </div>
            {/* <div style={{width: '175px', minHeight: screenHeight}}>
              <div style={{padding: '1em'}}>
                <AppointmentWidget />
              </div>
            </div> */}
          </div>
        </Container>
      )
    }
}

export default AppointmentBody
