import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Progress } from 'semantic-ui-react'

import OmniAccountLinked from '../../../libraries/widget/OmniAccountLinked'
import AchievementWidget from '../../../libraries/widget/AchievementWidget'
import OmniTimelinesWidget from '../../../libraries/widget/OmniTimelinesWidget'
import OmniTrafficWidget from '../../../libraries/widget/OmniTrafficWidget'

class StatisticBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { mode, screenWidth, screenHeight }= this.props

    return (
      <div style={{padding: '1em'}}>
        <div style={{paddingBottom: '2em'}}>
          <OmniTrafficWidget agent screenWidth={screenWidth} screenHeight={screenHeight}/>
          <OmniAccountLinked screenWidth={screenWidth} screenHeight={screenHeight}/>
        </div>
        {/* <OmniTimelinesWidget screenWidth={screenWidth} screenHeight={screenHeight}/> */}
        
        {/* <AchievementWidget screenWidth={screenWidth} screenHeight={screenHeight}/> */}
      </div>
    )
  }
}

export default StatisticBody