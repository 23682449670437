import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Icon } from 'semantic-ui-react'

import { parse, format, addSeconds } from "date-fns"

import { packageOptions, yesnoOptions, channelOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'

import { SubmissionError, Field, change, reduxForm, formValueSelector } from 'redux-form'
import { ImageUploadField, LabelInputField, NumberField, InputField, CheckboxField, SelectField, DatePickerField, LabelNumberField, TextAreaField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
import { connect } from "react-redux"
import { resetTopup, getTopup,updateTopup, createTopup } from "../../actions/halo/topupAction"

//CONNECT REDUX STORE
const selector = formValueSelector('TopupForm')

const mapStateToProps = (state, props) => {
  return {
    topupStore: state.topupStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetTopup:(objParam) => dispatch(resetTopup(objParam)),
    getTopup:(objParam) => dispatch(getTopup(objParam)),
    createTopup:(objParam) => dispatch(createTopup(objParam)),
    updateTopup:(objParam) => dispatch(updateTopup(objParam)),
  }
}

class TopupForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      validCredit: false,
    }
  }

  componentDidMount(){
    this.props.resetTopup(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getTopup({topupId: this.props.topupId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code }= (mode==2 ? (this.props.topupStore.updateTopup!=undefined ? this.props.topupStore.updateTopup : {}) : (this.props.topupStore.createTopup!=undefined ? this.props.topupStore.createTopup : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }

    if (this.props.depositAmount!=this.props.depositCredit) {
      this.props.dispatch(change('TopupForm', 'depositCredit', this.props.depositAmount))
    }

    const totCredit= parseInt(this.props.depositOmni || '0') + parseInt(this.props.depositTelco || '0') + parseInt(this.props.depositWhatsapp || '0') + parseInt(this.props.depositEmail || '0') + parseInt(this.props.depositSocmed || '0');
    if (this.props.depositCredit==totCredit && !this.state.validCredit) {
      this.setState({
        validCredit: true,
      })
    } else if (this.props.depositCredit!=totCredit && this.state.validCredit) {
      this.setState({
        validCredit: false,
      })
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, topupId } = this.props
    if (this.state.validCredit) {
      mode==2 ? this.props.updateTopup({...values, userId: (this.props.params && this.props.params.userId), bill_prepaidId: (this.props.params && this.props.params.prepaidId)}) : this.props.createTopup({...values, userId: (this.props.params && this.props.params.userId), bill_prepaidId: (this.props.params && this.props.params.prepaidId)})
    }
  }
  

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.topupStore.updateTopup!=undefined ? this.props.topupStore.updateTopup : {}) : (this.props.topupStore.createTopup!=undefined ? this.props.topupStore.createTopup : {}))

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1', borderRight: '1px solid #ccc', paddingRight: '1em'}}>
                <Header as='h4'>
                  <Header.Content>
                    {this.props.params && this.props.params.user && this.props.params.user.company}
                    <Header.Subheader>{this.props.params && this.props.params.packageId}/ {((this.props.params && this.props.params.billType)||'').toUpperCase()}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1', borderLeft: '1px solid #fff', paddingLeft: '1em'}}>
                <Header as='h4'>
                  <Header.Content>
                    {this.props.params && this.props.params.user && this.props.params.user.name}
                    <Header.Subheader>{this.props.params && this.props.params.user && (this.props.params.user.mobile || this.props.params.user.email)}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
          </Segment>
          <Segment>
            <Form.Group widths='equal'>
              <Field name='depositAmount' component={LabelNumberField}
              readOnly={mode==2}
              label= 'Rp.'
              caption='Deposit Amount'
              placeholder='Deposit Amount'
              validate={[required]} />
              <Field name='depositCredit' component={LabelNumberField}
              readOnly
              label='Credit'
              caption='Deposit Credit'
              placeholder='Deposit Credit'
              validate={[required]} />
            </Form.Group>
          </Segment>
          <Segment>
            {!this.state.validCredit && 
              <div style={{background: '#ff0', border: '1px solid rgba(100, 100, 100, .3)', padding: '1em .5em', marginBottom: '2em'}}>
                <Header as='h4' color='red'>
                  <Icon name='warning sign' />
                  <Header.Content>
                    Total Credit Is Not Valid
                    <Header.Subheader>Total credit of Omni and all channels must have same value to Deposit Credit.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            }
            <Form.Group widths='16'>
              <Field name='depositOmni' component={LabelNumberField}
              readOnly={mode==2}
              width={8}
              label='Credit'
              icon='cube'
              // caption='Dep. Omni'
              placeholder='Omni Deposit for All Channels'
              validate={[required]} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='depositTelco' component={LabelNumberField}
              readOnly={mode==2}
              label='Credit'
              icon='text telephone'
              // caption='Dep. Telco'
              placeholder='Deposit Telco for Voice , IVR and SMS'
              validate={[required]} />
              <Field name='depositWhatsapp' component={LabelNumberField}
              readOnly={mode==2}
              label='Credit'
              icon='whatsapp'
              // caption='Dep. Whatsapp'
              placeholder='Whatsapp'
              validate={[required]} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='depositEmail' component={LabelNumberField}
              readOnly={mode==2}
              label='Credit'
              icon='mail outline'
              // caption='Dep. Email'
              placeholder='Email'
              validate={[required]} />
              <Field name='depositSocmed' component={LabelNumberField}
              readOnly={mode==2}
              label='Credit'
              icon='facebook'
              // caption='Dep. Socmed'
              placeholder='Social Media, Twitter, FB, IG'
              validate={[required]} />
            </Form.Group>
          </Segment>

          <Segment>
            <Header as='h5'>
              <Icon name='file alternate outline' style={{fontSize: '1em'}}/>
              <Header.Content>Topup Transaction</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='topupType' component={SelectField}
              options={channelOptions('payment')}
              label='Topup Type'
              validate={[required]} />
              <Field name='topupStamp' component={DatePickerField}
              showTimeSelect
              label='Topup Stamp'
              validate={[required]} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='topupNote' component={TextAreaField}
              label='Transct. Note'
              validate={[required]} />
            </Form.Group>
          </Segment>

          <Segment>
            <Header as='h5'>
              <Icon name='file alternate outline' style={{fontSize: '1em'}}/>
              <Header.Content>Transact. Verification</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='isVerified' component={SelectField}
              options={yesnoOptions('verified')}
              label='Verification Status'
              validate={[required]} />
              <Field name='verifiedStamp' component={DatePickerField}
              showTimeSelect
              label='Verified At'
              validate={[required]} />
              {/* <Field name='verifiedBy' component={InputField}
              readOnly
              label='Verified By' /> */}
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='verifiedNote' component={TextAreaField}
              label='Verification Note'
              validate={[required]} />
            </Form.Group>
          </Segment>

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Topup' : 'Insert Topup') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.topupStore.getTopup!=undefined && state.topupStore.getTopup.progress==1 ? state.topupStore.getTopup.data : {topupStamp: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), verifiedStamp: format(new Date(), 'yyyy-MM-dd HH:mm:ss')}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,

      depositAmount: selector(state, 'depositAmount'),
      depositCredit: selector(state, 'depositCredit'),
      
      depositOmni: selector(state, 'depositOmni'),
      depositTelco: selector(state, 'depositTelco'),
      depositWhatsapp: selector(state, 'depositWhatsapp'),
      depositEmail: selector(state, 'depositEmail'),
      depositSocmed: selector(state, 'depositSocmed'),
    }),
  )
  (reduxForm({
    form: 'TopupForm',	// a unique identifier for this form
  })(TopupForm)
  )
)