import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
  Popup,
} from 'semantic-ui-react'


class EmulatorOncall extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { mode, status, terminating, responsive, layout }= this.props

    return (
      <>
        <Segment attached={true} style={{padding: '1em .5em', background: '#eee', border: 'none', textAlign: (responsive && layout=='mini') ? 'center' : 'left'}}>
          <Header as='h3' color='blue' icon={!responsive && layout!='mini'} style={{margin: '1em .5em 0 .5em', display: 'inline'}} >
            <Icon name='phone' className='bganimateiconborder' style={{transform: 'rotate(-135deg)', fontSize: '1em', cursor: 'pointer'}} circular inverted color='red' onClick={this.props.callHangup.bind(this)}/>
            <Header.Content style={{paddingLeft: '.5em'}}>
              {mode=='predictive' ? 'Online ...' : 'On call ...'}
              <Header.Subheader>{mode=='predictive' ? 'Waiting for dispatch call' : 'Established'}</Header.Subheader>
            </Header.Content>
          </Header>
        </Segment>
      </>
    )
  }
}

export default EmulatorOncall