import React, { createContext } from 'react'
// import io from 'socket.io-client';
import socketIOClient from "socket.io-client";
// import { WS_BASE } from './config';
// import { useDispatch } from 'react-redux';
// import { updateChatLog } from './actions';

import { urlSocket } from "../_api"
import { connect, useDispatch } from "react-redux"
import { socketStatus, socketBroadcast, socketAgentStatus, socketAgentStatistic, socketAgentNotification, socketSystemMonitoring, socketCampaignMonitoring, socketSystemOccupancy, socketSystemHeartbeat, socketWhatsappConnection, socketInappConnection } from "./socketAction"

const WebSocketContext = createContext(null)
const pushEvents=[
    'push://agent:status',
    'push://agent:statistic',
    'push://agent:notification',

    'push://system:occupancy',
    'push://system:monitoring',
    'push://system:campaign',
    'push://system:heartbeat',
    'push://whatsapp:connection',

    'push://inapp:connection',
    
]; //<= event will be catched by socket

export { WebSocketContext }

export default ({children}) => {
    let socket;
    let ws= {
        socket: null,
        info: 'Default value of context'
    };
    const dispatch = useDispatch();
    // socket connectino handler
    if (!socket) {
        // socket = io.connect('http://127.0.0.1:4001')
        const socket = socketIOClient(
            // 'http://127.0.0.1:4001', {
            urlSocket, {
            path: '/stream',
            transports: ['websocket'],
            // transports: ['polling'],
            // reconnection: false,
            reconnectionDelay: 2000,
            reconnectionAttempts: 3,
            query: {
                token: localStorage.getItem('tokenAuth'),
            }
        });

        socket.on('connect', () => {
            // console.log('!!! WS CONNECT', socket.connected); // true
            dispatch(socketStatus({isConnected: true}))
        });
        socket.on('disconnect', (reason) => {
            console.log('!!! WS DISCONNECT', socket.connected, reason); // true
            if (reason === 'io server disconnect') {
                // the disconnection was initiated by the server, you need to reconnect manually
                // socket.connect();
            }
            dispatch(socketStatus({isConnected: false, reason: reason}))
        });
        socket.on('connect_error', (error) => {
            console.log('!!! WS CONNECT ERROR', error); // true
            dispatch(socketStatus({isConnected: false, reason: error}))
        });

        // event handler
        pushEvents.map(event=>{
            socket.on(event, (payload) => {
                switch (event) {
                    case 'push://agent:status':
                        dispatch(socketAgentStatus({event: event, payload: payload}));
                    break;
                    case 'push://agent:statistic':
                        dispatch(socketAgentStatistic({event: event, payload: payload}));
                    break;
                    case 'push://agent:notification':
                        dispatch(socketAgentNotification({event: event, payload: payload}));
                    break;
                    case 'push://system:monitoring':
                        dispatch(socketSystemMonitoring({event: event, payload: payload}));
                    break;
                    case 'push://system:campaign':
                        dispatch(socketCampaignMonitoring({event: event, payload: payload}));
                    break;
                    case 'push://system:heartbeat':
                        dispatch(socketSystemHeartbeat({event: event, payload: payload}));
                    break;
                    case 'push://whatsapp:connection':
                        dispatch(socketWhatsappConnection({event: event, payload: payload}));
                    break;
                    case 'push://inapp:connection':
                        dispatch(socketInappConnection({event: event, payload: payload}));
                    break;
                    default:
                        dispatch(socketBroadcast({event: event, payload: payload}));
                }
                // dispatch(socketBroadcast({event: event, payload: payload}));
            })
            // socket.onAny((event, ...args) => {
            //     // console.log(`!!! WS EVENT ANY`, event, args);
            // });
        })
        
        // context value
        ws = {
            socket: socket,
            info: 'Socket has been assigned',
        }
    }

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    )
}