import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Icon } from 'semantic-ui-react'

import { packageOptions, billingOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { ImageUploadField, LabelInputField, NumberField, InputField, CheckboxField, SelectField, DatePickerField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
import { connect } from "react-redux"
import { resetPrepaid, getPrepaid,updatePrepaid, createPrepaid } from "../../actions/halo/prepaidAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    prepaidStore: state.prepaidStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPrepaid:(objParam) => dispatch(resetPrepaid(objParam)),
    getPrepaid:(objParam) => dispatch(getPrepaid(objParam)),
    createPrepaid:(objParam) => dispatch(createPrepaid(objParam)),
    updatePrepaid:(objParam) => dispatch(updatePrepaid(objParam)),
  }
}

class PrepaidForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    this.props.resetPrepaid(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getPrepaid({prepaidId: this.props.prepaidId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.prepaidStore.updatePrepaid!=undefined ? this.props.prepaidStore.updatePrepaid : {}) : (this.props.prepaidStore.createPrepaid!=undefined ? this.props.prepaidStore.createPrepaid : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, prepaidId } = this.props
    mode==2 ? this.props.updatePrepaid({...values}) : this.props.createPrepaid({...values})
  }
  

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.prepaidStore.updatePrepaid!=undefined ? this.props.prepaidStore.updatePrepaid : {}) : (this.props.prepaidStore.createPrepaid!=undefined ? this.props.prepaidStore.createPrepaid : {}))

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='16'>
              <Field name='depositAmount' component={NumberField}
              width={4}
              readOnly
              label= 'Deposit Amount'
              placeholder='Deposit Amount'
              validate={[required]} />
              <Field name='depositCredit' component={NumberField}
              width={4}
              readOnly
              label= 'Deposit Credit'
              placeholder='Deposit Credit'
              validate={[required]} />
              <Form.Field width={4}></Form.Field>
              <Field name='depositOmni' component={NumberField}
              width={4}
              readOnly
              label='Dep. Omni'
              placeholder='Omni Deposit for All Channels' />
            </Form.Group>
            
            <Divider />
            <Form.Group widths='equal'>
              <Field name='depositTelco' component={NumberField}
              readOnly
              label='Dep. Telco'
              placeholder='Deposit Telco for Voice , IVR and SMS' />
              <Field name='depositEmail' component={NumberField}
              readOnly
              label='Dep. Email'
              placeholder='Email' />
              <Field name='depositWhatsapp' component={NumberField}
              readOnly
              label='Dep. Whatsapp'
              placeholder='Whatsapp' />
              <Field name='depositSocmed' component={NumberField}
              readOnly
              label='Dep. Socmed'
              placeholder='Social Media, Twitter, FB, IG' />
            </Form.Group>
          </Segment>
          
          <Segment attached='top'>
            <Header as='h5'>
              <Icon name='address card outline' style={{fontSize: '1em'}}/>
              <Header.Content>Customer</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='packageId' component={SelectField}
              readOnly
              options={packageOptions}
              width={8}
              label='Package'
              placeholder='Package' />
              <Field name='company' component={InputField}
              readOnly
              width={8}
              label='Company'
              placeholder='Company' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              readOnly
              width={8}
              label='Contact'
              placeholder='Contact' />
              <Field name='mobile' component={InputField}
              readOnly
              fluid
              width={4}
              label='Mobile'
              placeholder='Mobile' />
              <Field name='email' component={InputField}
              readOnly
              fluid
              width={4}
              label='Email'
              placeholder='Email' />
            </Form.Group>
          </Segment>
          {/* <Segment attached='bottom'>
            <Form.Group widths='equal'>
              <Field name='address' component={InputField}
              fluid
              label='Address'
              placeholder='Address' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='city' component={InputField}
              width={8}
              placeholder='City' />
              <Field name='province' component={InputField}
              width={4}
              placeholder='Province' />
              <Field name='country' component={InputField}
              width={4}
              placeholder='Country' />
            </Form.Group>
          </Segment> */}

          <Segment>
            <Header as='h5'>
              <Icon name='file alternate outline' style={{fontSize: '1em'}}/>
              <Header.Content>Activity</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='deductStamp' component={InputField}
              readOnly
              label='Last Deduction' />
              <Field name='topupStamp' component={InputField}
              readOnly
              label='Last Topup' />
              <Field name='gracedStamp' component={InputField}
              readOnly
              label='Graced Period' />
              <Field name='expiredStamp' component={InputField}
              readOnly
              label='Expired Stamp' />
            </Form.Group>
          </Segment>

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Prepaid' : 'Insert Prepaid') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.prepaidStore.getPrepaid!=undefined && state.prepaidStore.getPrepaid.progress==1 ? state.prepaidStore.getPrepaid.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'PrepaidForm',	// a unique identifier for this form
  })(PrepaidForm)
  )
)