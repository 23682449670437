import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetTopup(objParam) {
  return {
      type: 'TOPUP_RESET',
      payload: objParam
  }
}

export function getTopup(objParam) {
  return {
      type: 'TOPUP_GET',
      payload:
        axios.post(
          urlAPI + 'topup/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusTopup(objParam) {
  return {
      type: 'TOPUP_STATUS',
      payload:
        axios.post(
          urlAPI + 'topup/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseTopup(objParam) {
  return {
      type: 'TOPUP_BROWSE',
      payload:
        axios.post(
          urlAPI + 'topup/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createTopup(objParam) {
  return {
      type: 'TOPUP_CREATE',
      payload:
        axios.post(
          urlAPI + 'topup/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateTopup(objParam) {
  return {
      type: 'TOPUP_UPDATE',
      payload:
        axios.post(
          urlAPI + 'topup/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeTopup(objParam) {
  return {
      type: 'TOPUP_REMOVE',
      payload:
        axios.post(
          urlAPI + 'topup/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
