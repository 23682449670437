// import axios from "axios"
// import { urlAdmin as urlAPI } from "../_api"

// import socketIOClient from "socket.io-client";
// const socket = socketIOClient('http://127.0.0.1:4001');

export function socketStatus(objParam) {
  return {
      type: 'SOCKET_STATUS',
      payload: 
        {...objParam}
  }
}

//REQUEST / REQUEST WHICH WAITING REQUIRED RESPONSE
export function socketRequest(objParam) {
  return {
      type: 'SOCKET_REQUEST',
      payload: 
        // serviceRequest(objParam.event, objParam.payload, objParam.socket),
        serviceRequestWithTimeout(objParam.event, objParam.payload, objParam.socket), 
  }
}

//TRANSMIT / REQUEST WITH NO RESPONSE REQUIRED
export function socketTransmit(objParam) {
  return {
      type: 'SOCKET_TRANSMIT',
      payload: 
        serviceTransmit(objParam.event, objParam.payload, objParam.socket),
  }
}

//BROADCAST / PUSH SERVICE WITHOUT REQUEST
export function socketBroadcast(objParam) {
  return {
      type: 'SOCKET_BROADCAST',
      payload: 
        {...objParam}
  }
}

export function socketAgentStatus(objParam) {
  return {
      type: 'SOCKET_AGENT_STATUS',
      payload: 
        {...objParam}
  }
}
export function socketAgentStatistic(objParam) {
  return {
      type: 'SOCKET_AGENT_STATISTIC',
      payload: 
        {...objParam}
  }
}
export function socketAgentNotification(objParam) {
  return {
      type: 'SOCKET_AGENT_NOTIFICATION',
      payload: 
        {...objParam}
  }
}

export function socketSystemHeartbeat(objParam) {
  return {
      type: 'SOCKET_SYSTEM_HEARTBEAT',
      payload: 
        {...objParam}
  }
}
export function socketSystemMonitoring(objParam) {
  return {
      type: 'SOCKET_SYSTEM_MONITORING',
      payload: 
        {...objParam}
  }
}
export function socketCampaignMonitoring(objParam) {
  return {
      type: 'SOCKET_CAMPAIGN_MONITORING',
      payload: 
        {...objParam}
  }
}
export function socketSystemOccupancy(objParam) {
  return {
      type: 'SOCKET_SYSTEM_OCCUPANCY',
      payload: 
        {...objParam}
  }
}
export function socketWhatsappConnection(objParam) {
  return {
      type: 'SOCKET_WHATSAPP_CONNECTION',
      payload: 
        {...objParam}
  }
}
export function socketInappConnection(objParam) {
  return {
      type: 'SOCKET_INAPP_CONNECTION',
      payload: 
        {...objParam}
  }
}

//REFERENCE FUNCTION
function serviceTransmit(event, payload, socket) {
  return new Promise((resolve, reject)=>{
    try {
      socket.emit(event, payload);
      resolve({code:'00', text: 'Transmitted'});
    } catch(error) {
      reject({code:'01', error: error}) 
    }
  });
}

function serviceRequest(event, payload, socket) {
  return new Promise((resolve, reject)=>{
    try {
      socket.emit(event, payload, (response) => {
        // console.log('!!! RESPONSE', response); // ok
        resolve(response);
      })
    } catch(error) {
      reject({code:'01', error: error}) 
    }
  });
}

function serviceRequestWithTimeout(event, payload, socket) {
  const timeout= 20000;
  
  return new Promise((resolve, reject)=>{
    try {
      socket.emit(event, payload, 
      // (response) => {
      //   // console.log('!!! RESPONSE', response); // ok
      //   resolve(response);
      // });
      withTimeout(
        (response) => {
          // console.log(response);
          resolve(response);
          // resolve({code:'00', error: 'Succeed'}) 
        }, 
        () => {
          // console.log("timeout!");
          resolve({code:'01', error: 'Timeout request'}) 
        }
        , timeout));
    } catch(error) {
      reject({code:'01', error: error}) 
    }
  });
}

const withTimeout = (onSuccess, onTimeout, timeout) => {
  let isTimeout = false;

  const timer = setTimeout(() => {
    if (isTimeout) return;
    isTimeout = true;
    onTimeout();
  }, timeout);

  return (...args) => {
    if (isTimeout) return;
    isTimeout = true;
    clearTimeout(timer);
    onSuccess.apply(this, args);
  }
}