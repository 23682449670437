import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider  } from 'semantic-ui-react'

import MenuHorizontal from '../../libraries/common/MenuHorizontal';

import MonitoringWidget from '../../libraries/widget/MonitoringWidget'
import OccupancyWidget from '../../libraries/widget/OccupancyWidget'
import HeartbeatWidget from '../../libraries/widget/HeartbeatWidget'

class MonitoringRealtime extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        userId: 0,
      };
    }

    render() {
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1', padding: '.8em 0em 1em 0em'}}>
              <div style={{padding: '1em'}}>
                <MonitoringWidget mode='admin' screenWidth={screenWidth} />
              </div>
              <div style={{padding: '1em'}}>
                <OccupancyWidget screenWidth={screenWidth} />
              </div>
              <div style={{padding: '1em'}}>
                <HeartbeatWidget screenWidth={screenWidth} />
              </div>
            </div>
          </div>
        </Container>
      )
    }
}

export default MonitoringRealtime
