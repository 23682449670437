import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
} from 'semantic-ui-react'

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { parse, format, addSeconds } from "date-fns"
import { voiceStatus } from "../../libraries/common/StaticMasterData"

import { connect } from "react-redux"
import { browseVoice, statusVoice } from "../../actions/stream/voiceAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    voiceStore: state.voiceStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseVoice:(objParam) => dispatch(browseVoice(objParam)),
    statusVoice:(objParam) => dispatch(statusVoice(objParam)),
  }
}
class VoiceBrowse extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remark: false,
      expand: false,
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.voiceStore.browseVoice!==undefined ? this.props.voiceStore.browseVoice : {})
    this.props.browseVoice(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.voiceStore.browseVoice!==undefined ? this.props.voiceStore.browseVoice : {})
    this.props.browseVoice({search: {transactDate: format(new Date(), 'yyyy-MM-dd 00:00:00'), initiator: null, flow: 1, ...search}, paging: paging})
  }

  onChangeStatus(id, status, e) {
    // console.log(id, status, e)
    this.props.statusVoice({voiceId:id, isEnabled: status})
  }
  setExpand() {
    const { expand }= this.state
    this.setState({
      expand: !expand
    })
  }
  setRemark() {
    const { remark }= this.state
    this.setState({
      remark: !remark
    })
  }

  formatSeconds(seconds) {
    const date= addSeconds(new Date(0, 0, 0, 0, 0, 0), seconds);
    // console.log(date)
    return format(date, 'HH:mm:ss');
  }

  render() {
    //console.log(this.props.voiceStore.browseVoice)
    const { expand, remark }= this.state
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.voiceStore.browseVoice!==undefined ? this.props.voiceStore.browseVoice : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1', padding: '0'}}>
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                {/* <Table.HeaderCell></Table.HeaderCell> */}
                <Table.HeaderCell width={expand ? 6 : 6}>
                  <Header as='h5' color='blue'>TARGET</Header>
                </Table.HeaderCell>
                {!expand &&<>
                <Table.HeaderCell width={3}>
                  <Header as='h5' color='blue'>CONTACT</Header>
                </Table.HeaderCell>
                </>}
                {!expand &&<>
                <Table.HeaderCell width={2}>
                  <Header as='h5' color='blue'>DUR.</Header>
                </Table.HeaderCell>
                </>}
                <Table.HeaderCell width={expand ? 2 : 2}>
                  <Header as='h5' color='blue'>
                    <Icon name={expand ? 'angle double right' : 'angle double left'} color={expand ? 'red' : 'red'} style={{fontSize: '1.5em', cursor: 'pointer'}} onClick={this.setExpand.bind(this)}/>
                    <Header.Content>DIALED</Header.Content>
                  </Header>
                </Table.HeaderCell>
                {expand && <>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>PICKED</Header>
                </Table.HeaderCell>
                {/* <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>QUEUED</Header>
                </Table.HeaderCell> */}
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>TALKED</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>DROPPED</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>HANGUP</Header>
                </Table.HeaderCell>
                </>}
                {!expand &&
                <Table.HeaderCell width={3} style={{background: remark ? '#e7feff' : '#fff2f2'}}>
                  <Header as='h5' color='blue'><Icon name={remark ? 'toggle on' : 'toggle off'} color={remark ? 'blue' : 'red'} style={{fontSize: '1.5em', cursor: 'pointer'}} onClick={this.setRemark.bind(this)}/><Header.Content>RESULT</Header.Content></Header>
                </Table.HeaderCell>}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      {/* <Table.Cell textAlign='center'>
                        <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.voiceId)}/>
                      </Table.Cell> */}
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            <b>{(item.target && item.target.name) || (item.customer && item.customer.name) || (item.visitor && item.visitor.name) || 'No Name'}</b>
                            <Header.Subheader><b>{(item.target && 'Campaign\'s Target') || (item.customer && 'Customer') || (item.visitor && 'Visitor')}</b> {item.campaign ? ', '+ item.campaign.name : ''}</Header.Subheader>
                            {/* {item.campaign!=null && <Header.Subheader>{format(parse(item.campaign.startDate, 'yyyy-MM-dd', new Date()), 'dd / MMM')} - {format(parse(item.campaign.endDate, 'yyyy-MM-dd', new Date()), 'dd / MMM')}</Header.Subheader>} */}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      {!expand &&<>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.terminating || '-'} 
                            <Header.Subheader>Dialed by <mark>{item.dialBy=='predialed' ? 'preview' : item.dialBy }</mark></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      </>}
                      {!expand &&<>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.callDuration!=null && (this.formatSeconds(item.callDuration || 0))}
                            <Header.Subheader></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      </>}
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.dialStamp!=null && format(parse(item.dialStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm:ss')}
                            <Header.Subheader>{item.dialStamp!=null && format(parse(item.dialStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      {expand && <>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.callStamp!=null && format(parse(item.callStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.callStamp!=null && format(parse(item.callStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      {/* <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.queueStamp!=null && format(parse(item.queueStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.queueStamp!=null && format(parse(item.queueStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell> */}
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.talkStamp!=null && format(parse(item.talkStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.talkStamp!=null && format(parse(item.talkStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.dropStamp!=null && format(parse(item.dropStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.dropStamp!=null && format(parse(item.dropStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.hangupStamp!=null && format(parse(item.hangupStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.hangupStamp!=null && format(parse(item.hangupStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      </>}
                      {!expand &&
                      <Table.Cell style={{background: remark ? '#e7feff' : '#fff2f2'}}>
                        {!remark && <Header as='h5'>
                          <Icon name={(item.dropStamp && 'exclamation circle') || (item.status<0 && 'times circle outline') || (item.status>0 && 'check circle') || 'dot circle outline'} color={(item.dropStamp && 'orange') || (item.status<0 && 'red') || (item.status>0 && 'blue') || 'teal'} style={{fontSize: '1.25em'}}/> 
                          <Header.Content>
                            {(item.dropStamp && ' Dropped') || voiceStatus[item.status] || 'No reason'}
                            <Header.Subheader>{item.reason}</Header.Subheader>
                          </Header.Content>
                        </Header>}
                        {remark && <Header as='h5'>
                        <Icon name={(item.remark=='Rescheduled' && 'calendar alternate outline') || (item.remark=='Promised' && 'calendar check') || (item.remark=='Paid' && 'calendar check') || 'exclamation circle'} color={(item.remark=='Rescheduled' && 'red') || (item.remark=='Promised' && 'blue') || (item.remark=='Paid' && 'red') || 'grey'} style={{fontSize: '1.25em'}}/> 
                          <Header.Content>
                            {item.remark  || 'No remark'}
                            {item.remark=='Rescheduled' && <Header.Subheader>{item.remarks.appointmentSession}, {item.remarks.appointmentDate!=null && format(parse(item.remarks.appointmentDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy')}</Header.Subheader>}
                            {item.remark=='Promised' && <Header.Subheader>{item.remarks.promisedDate!=null && format(parse(item.remarks.promisedDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy')}</Header.Subheader>}
                            {item.remark=='Paid' && <Header.Subheader>{item.remarks.paidDate!=null && format(parse(item.remarks.paidDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy')}</Header.Subheader>}
                          </Header.Content>
                        </Header>}
                      </Table.Cell>}
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VoiceBrowse)
