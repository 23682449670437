import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetWhitelist(objParam) {
  return {
      type: 'WHITELIST_RESET',
      payload: objParam
  }
}

export function getWhitelist(objParam) {
  return {
      type: 'WHITELIST_GET',
      payload:
        axios.post(
          urlAPI + 'whitelist/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusWhitelist(objParam) {
  return {
      type: 'WHITELIST_STATUS',
      payload:
        axios.post(
          urlAPI + 'whitelist/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseWhitelist(objParam) {
  return {
      type: 'WHITELIST_BROWSE',
      payload:
        axios.post(
          urlAPI + 'whitelist/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createWhitelist(objParam) {
  return {
      type: 'WHITELIST_CREATE',
      payload:
        axios.post(
          urlAPI + 'whitelist/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateWhitelist(objParam) {
  return {
      type: 'WHITELIST_UPDATE',
      payload:
        axios.post(
          urlAPI + 'whitelist/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeWhitelist(objParam) {
  return {
      type: 'WHITELIST_REMOVE',
      payload:
        axios.post(
          urlAPI + 'whitelist/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
