import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Grid, Label, Progress } from 'semantic-ui-react'

import numeral from "numeral"

import IconToggle from '../common/IconToggle'
import PieRechart from '../chart/PieRechart';

import { connect } from "react-redux"
import { checkToken } from "../../actions/auth/authAction"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus } from "../../actions/socket/socketAction"

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),
    checkToken:(objParam) => dispatch(checkToken(objParam)),
  }
}

class AchievementWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      isPiechart: false,
    };
  }

  setMode() {
    this.setState({
      isPiechart: !this.state.isPiechart
    })
  }

  render() {
    const { mode, screenWidth,  screenHeight, responsive }= this.props
    const agentStatus= (this.props.socketStore.socketAgentStatus!==undefined ? this.props.socketStore.socketAgentStatus.data : undefined)
    const token= this.props.authStore.checkToken && this.props.authStore.checkToken.data ? this.props.authStore.checkToken.data : {};

    const { performance }= (agentStatus && agentStatus.payload.data) || {status: 'connect'}
    const data= performance && [
      { name: 'Contacted', value: performance && performance.totEngaged }, 
      { name: 'Achieved', value: performance && performance.totAchieved },
    ];

    const pieResponse = [
      { name: 'Contacted', value: performance && performance.totEngaged },
      { name: 'Achieved', value: performance && performance.totAchieved },
      { name: 'Dropped', value: performance && performance.totDropped }, 
      { name: 'Invalid', value: performance && performance.totInvalid }, 
      { name: 'Mailbox', value: performance && performance.totMailbox },
    ];

    return (
      <Segment style={{padding: '1em 1.5em 1em 1.5em'}}>
        <div style={{display: 'flex', marginBottom: '1em'}}>
          <div style={{flex: '1'}}>
            <Label color='pink' style={{borderRadius: '2em', padding: '.1em 1em .5em 1em'}}>
              <Header as='h4' style={{marginTop: '.25em'}} inverted>
                <Icon name='share alternate' style={{fontSize: '1em'}} />
                <Header.Content style={{fontWeight: 'bold'}}>
                  <i>Upcoming!</i> Channels
                </Header.Content>
              </Header>
            </Label>
          </div>
          {/* <div style={{marginLeft: '1em'}}>
            <Button circular size='small' color='red' onClick={this.setMode.bind(this,1)} icon style={{padding: '.7em .5em'}}><Icon name='plus' style={{fontSize: '1.4em'}}/></Button>
          </div> */}
        </div>
        
        <Grid columns={screenWidth<1024 || responsive ? 2 : 4} style={{margin: '0'}}>
          <Grid.Column>
            <Header as='h3' color='green' style={{fontWeight: '100', margin: '0'}} disabled={token.isWhatsapp!='1'}>
              <Icon name='whatsapp' />
              <Header.Content>
                Whatsapp
                <Header.Subheader>Business Account</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as='h3' color='blue' style={{fontWeight: '100', margin: '0'}} disabled={token.isTwitter!='1'}>
              <Icon name='twitter' />
              <Header.Content>
                Twitter
                <Header.Subheader>Direct Message</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as='h3' color='orange' style={{fontWeight: '100', margin: '0'}} disabled={token.isInstagram!='1'}>
              <Icon name='instagram' />
              <Header.Content>
                Instagram
                <Header.Subheader>Direct Message</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as='h3' color='blue' style={{fontWeight: '100', margin: '0'}} disabled={token.isFacebook!='1'}>
              <Icon name='facebook' />
              <Header.Content>
                Facebook
                <Header.Subheader>Facebook Messenger</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

AchievementWidget.contextType= WebSocketContext;

export default connect(mapStateToProps, mapDispatchToProps)(AchievementWidget)
