import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import MenuHorizontal from '../../../libraries/common/MenuHorizontal';

import TicketBody from './TicketBody'
import HandlingBody from './HandlingBody'
import SegmentBody from './SegmentBody'

const options= [
  {
    key: '1',
    icon: 'ticket alternate',
    caption: 'Ticket',
  },
  {
    key: '2',
    icon: 'magic',
    caption: 'Handling',
  },
  {
    key: '3',
    icon: 'clone outline',
    caption: 'Segments',
  },
]

class TicketingBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        // dashboardId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode})
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props

      return(
        <Container fluid>
          <div style={{margin: '0em 1em 0 1em'}}>
            <MenuHorizontal onClick={this.setMode.bind(this)} options= {options} color='red'/>
          </div>
          <div>
            {mode==1 && <TicketBody openWindow={this.props.openWindow.bind(this)} screenHeight={screenHeight} screenWidth={screenWidth} />}
            {mode==2 && <HandlingBody openWindow={this.props.openWindow.bind(this)} screenHeight={screenHeight} screenWidth={screenWidth} />}
            {mode==3 && <SegmentBody openWindow={this.props.openWindow.bind(this)} screenHeight={screenHeight} screenWidth={screenWidth} />}
          </div>
        </Container>
      )
    }
}

export default TicketingBody
