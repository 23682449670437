import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetCampaign(objParam) {
  return {
      type: 'CAMPAIGN_RESET',
      payload: objParam
  }
}

export function getCampaign(objParam) {
  return {
      type: 'CAMPAIGN_GET',
      payload:
        axios.post(
          urlAPI + 'campaign/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusCampaign(objParam) {
  return {
      type: 'CAMPAIGN_SUMMARYUS',
      payload:
        axios.post(
          urlAPI + 'campaign/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseCampaign(objParam) {
  return {
      type: 'CAMPAIGN_BROWSE',
      payload:
        axios.post(
          urlAPI + 'campaign/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createCampaign(objParam) {
  return {
      type: 'CAMPAIGN_CREATE',
      payload:
        axios.post(
          urlAPI + 'campaign/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateCampaign(objParam) {
  return {
      type: 'CAMPAIGN_UPDATE',
      payload:
        axios.post(
          urlAPI + 'campaign/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeCampaign(objParam) {
  return {
      type: 'CAMPAIGN_REMOVE',
      payload:
        axios.post(
          urlAPI + 'campaign/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function summaryCampaign(objParam) {
  return {
      type: 'CAMPAIGN_SUMMARY',
      payload:
        axios.post(
          urlAPI + 'campaign/stat',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}