import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Popup,
} from 'semantic-ui-react'

import MessagingInboundRecent from '../../libraries/whatsapp/MessagingInboundRecent';
import MessagingOutboundRecent from '../../libraries/whatsapp/MessagingOutboundRecent';
import BillingWidget from '../../libraries/widget/whatsapp/BillingWidget';
import CampaignWidget from '../../libraries/widget/whatsapp/CampaignWidget';
import MessagingWidget from '../../libraries/widget/whatsapp/MessagingWidget';
import CampaignBody from './CampaignBody';


class DashboardBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, campaignId: id})
    }

    render() {
      const {mode, campaignId}= this.state
      const {screenWidth, screenHeight, module}= this.props

      return(
        <Container fluid>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1', background: '#f8f8f8', padding: '2em 1.5em'}}>
              <div style={{display: 'flex'}}>
                <div className='animatewidth' style={{...(this.props.modal!='expandOutbound' ? {flex: 1} : {width: 300}), background: '#f9f9f9', marginRight: '1.5em'}}>
                  <Segment>
                    <div style={{display: 'flex', margin: '-1.5em -1.5em 1em -1.5em', padding: '1em .7em', borderBottom: '1px dashed #47b9ffcc'}}>
                      <div style={{flex: '1'}}>
                        <Header as='h5' style={{margin: '-.2em 0  0 0'}}>
                          <Icon name='arrow circle right' style={{fontSize: '1.5em', paddingRight: '.3em'}} color='blue' />
                          <Header.Content style={{background: '#d1f1f8', borderRadius: '1em', padding: '.2em .8em'}}>
                            <b>Incoming Message</b>
                            <Header.Subheader></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </div>
                      <div>
                        <Popup  content='Browse or view all available incoming messages.' position='left center'
                          trigger={
                            <Icon name='window restore outline' onClick={this.props.openModal.bind(this, 'inbound')}  style={{fontSize: '1.2em', cursor: 'pointer'}} />
                          }
                          inverted
                          on={['hover']}
                        />
                      </div>
                    </div>
                    <div className='noscroll' style={{margin: '0 -1.5em  -.5em  -1.5em  ', height: 525, overflowY: 'auto'}}>
                      <div style={{margin: '0 1.5em 1.5em 1.5em'}}>
                        <MessagingInboundRecent />
                      </div>
                    </div>
                  </Segment>
                </div>
                <div className='animatewidth' style={{...(this.props.modal=='expandOutbound' ? {flex: 1} : {width: 300} ), background: '#f9f9f9'}}>
                  <Segment>
                    <div style={{display: 'flex', margin: '-1.5em -1.5em 1em -1.5em', padding: '1em .7em', borderBottom: '1px dashed #47b9ffcc'}}>
                      <div style={{flex: '1'}}>
                        <Header as='h5' style={{margin: '-.2em 0  0 0'}}>
                          <Icon name='arrow circle left' style={{fontSize: '1.5em', paddingRight: '.3em'}} color='red' />
                          <Header.Content style={{background: '#fcdae5', borderRadius: '1em', padding: '.2em .8em'}}>
                            <b>Blast Message</b>
                            <Header.Subheader></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </div>
                      <div>
                        <Popup  content='Browse and view all available blast / push messages.' position='left center'
                          trigger={
                            <Icon name='window restore outline' onClick={this.props.openModal.bind(this, 'outbound')}  style={{fontSize: '1.2em', cursor: 'pointer'}} />
                          }
                          inverted
                          on={['hover']}
                        />
                      </div>
                    </div>
                    <div className='noscroll' style={{margin: '0 -1.5em -.5em  -1.5em  ', height: 525, overflowY: 'auto'}}>
                      <div style={{margin: '0 1.5em 1.5em 1.5em'}}>
                        <MessagingOutboundRecent />
                      </div>
                    </div>
                  </Segment>
                </div>
              </div>
            </div>
            <div style={{width: 350, background: '#f5f5f5', padding: '2em 1.5em'}}>
              <MessagingWidget openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
              <CampaignWidget openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
              <BillingWidget openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
            </div>
          </div>

          <div style={{background: '#fcfcfc', padding: '2em 1.5em'}}>
            <CampaignBody mode={1} openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
          </div>
        </Container>
      )
    }
}

export default DashboardBody
