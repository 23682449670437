import React,{Component} from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
  ModalDescription,
  Modal,
  Progress,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse, addSeconds } from "date-fns"

import OmniphoneContentOutbound from '../agent/OmniphoneContentOutbound';

import JsSIP from 'jssip'

import dialtone from '../../sound/tone.mp3'
import busytone from '../../sound/busy.mp3'
import ringtone from '../../sound/ring.mp3'
import jointone from '../../sound/join.mp3'

import { connect } from "react-redux"


import { checkToken } from "../../actions/auth/authAction"
import { updateBridging } from "../../actions/stream/bridgingAction"
import { loggingError } from "../../actions/system/errorAction"
import { contactOmniphone,resetOmniphone, hangupOmniphone, chatOmniphone, mesgOmniphone } from "../../actions/stream/omniphoneAction"
import { socketStatus, socketAgentStatus, socketAgentStatistic, socketAgentNotification } from "../../actions/socket/socketAction"

import { WebSocketContext } from '../../actions/socket/Websocket';

import TimerProgressBar from '../common/TimerProgressBar';
import ModalContainer from '../common/ModalContainer';
import LockConfirmation from '../agent/LockConfirmation';
import SessionAutostart from '../agent/SessionAutostart';

import OmniphoneChat from './OmniphoneChat';

import OmniphoneSms from './OmniphoneSms';
import OmniphoneEmail from './OmniphoneEmail';
import OmniphoneWhatsapp from './OmniphoneWhatsapp';
import OmniphoneTwitter from './OmniphoneTwitter';
import OmniphoneFacebook from './OmniphoneFacebook';
import OmniphoneInstagram from './OmniphoneInstagram';

import OmniphoneVideo from './OmniphoneVideo';

import OmniphoneContentInbound from '../agent/OmniphoneContentInbound';
import OmniphoneCall from './OmniphoneCall';

const dial= new Audio(dialtone);
const busy= new Audio(busytone);
const ring= new Audio(ringtone);
const join= new Audio(jointone);

const mediaSource = new MediaSource();

const silentCallback= false;
const popups= new Map(); 

popups.set('callback', {
  icon: 'call',
  label: 'Answer',
  header: 'Verify Account',
  content: 'Verify as active agent for receiving dispatch and having interaction to customers.',
}).set('quit', {
  icon: 'unlink',
  label: 'Quit',
  header: 'Close Session',
  content: 'Terminate session as agent, you will be recorded as inactive agent.',
}).set('start', {
  icon: 'microphone',
  label: 'Start',
  header: 'Start Session',
  content: 'Set your self as online agent, next you will be verified and start receiving conversation dispatch.',
}).set('lock', {
  icon: 'lock',
  label: 'Lock',
  header: 'Lock / Away',
  content: 'Set your status as LOCKED or AWAY, session still active but no dispatched conversation and recorded as inactive agent.',
}).set('unlock', {
  icon: 'lock',
  label: 'Unlock',
  header: 'Unlock Me!',
  content: 'Welcome back! set as available or active agent to start receive dispatched conversation.',
}).set('hangup', {
  icon: 'unlink',
  label: 'Hangup',
  header: 'End Call',
  content: 'Hangup to end call / conversation between contact and agent.',
})

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
    errorStore: state.errorStore,

    socketStore: state.socketStore,

    bridgingStore: state.bridgingStore,
    omniphoneStore: state.omniphoneStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),

    loggingError:(objParam) => dispatch(loggingError(objParam)),

    socketStatus:(objParam) => dispatch(socketStatus(objParam)),
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),
    
    updateBridging:(objParam) => dispatch(updateBridging(objParam)),

    hangupOmniphone:(objParam) => dispatch(hangupOmniphone(objParam)),
    chatOmniphone:(objParam) => dispatch(chatOmniphone(objParam)),
    mesgOmniphone:(objParam) => dispatch(mesgOmniphone(objParam)),
  }
}

var ua;
var session;
var sessions= new Map(), localVideoStream, remoteVideoStream;

class DialTone {
  play() {
    console.log('!!! PLAY DIAL TONE')
    dial.loop= true
    // dial.muted= false;
    dial.play();
  }
  
  stop() {
    dial.pause();
  }

  mute() {
    dial.muted= true;
  }
}

class BusyTone {
  play() {
    console.log('!!! PLAY BUSY TONE')
    // busy.muted= false;
    busy.play();
  }
  
  stop() {
    busy.pause();
  }

  mute() {
    busy.muted= true;
  }
}

class RingTone {
  play() {
    console.log('!!! PLAY RING TONE')
    ring.loop= true;
    // ring.muted= false;
    ring.play();
  }
  
  stop() {
    ring.pause();
  }

  mute() {
    busy.muted= true;
  }
}

class JoinTone {
  play() {
    console.log('!!! PLAY JOIN TONE')
    // join.loop= false;
    // join.muted= false;
    join.play();
  }
  
  stop() {
    // join.muted= true;
    join.pause();
  }
}

class Omniphone extends Component {
  constructor(props){
    super(props)
    this.pos={
      x: 0,
      y: 0,
    }
  	this.state = {
      // sipAccount: '1001',
      // sipPasswd: '1001@d4nk0m2020',
      // sipUri: 'sip:?@174.138.19.28:4063', DISCARD
      // sipHost: '174.138.19.28:4061/', //ws://174.138.19.28:4061/
      // sipHost: 'call.halopagi.com:4063/', //wss://call.halopagi.com:4063/
      // sipRealm: 'halopagi',

      init: false,
      uiMode: [], //texting, dial, call
      statusUA: 0, //0: disconnected, 1: connecting, 2: connected, 3: registered, 4: no access / account not valid / FAILED
      dialMode: false,
      phoneState: 'offline', //offline, connecting, online, incoming, dialing, oncall
      text: null,

      layout: 'mini', //'mini', 'normal', 'floating'
      lastLayout: null,
      windowPos: {x: 0, y: 0},

      callOptions: {
        mediaConstraints : { audio: true, video: false },
        // mediaConstraints : { audio: true, video: true },
        // mediaConstraints : { audio: true, video: true, screen: true },
      },

      targetId: 0,
      visitorId: 0,
      customerId: 0,
      constactId: 0,
      voiceId: 0,
      messagingId: 0,
      flow: 0,
      isMute: false,

      chats: [],

      lastStatus: null,

      lockModal: false,
      lockReason: null,

      startModal: false,

      latency: 0,

      buttons: {
        left: null,
        right: null,
      },
    }

    this.dial= new DialTone();
    this.busy= new BusyTone();
    this.ring= new RingTone();
    this.join= new JoinTone();
  }

  setSkinMode(layout) {
    this.setState({
      layout: layout
    })
  }
  
  sipRegister(account, passwd, host, realm) {
    var sipAccount, sipPasswd, sipHost, sipRealm;

    try {
      if (account!=null && passwd!=null && host!=null && realm!=null) {
        sipAccount= account
        sipPasswd= passwd
        sipHost= host
        sipRealm= realm
      } else {
        sipAccount= this.state.sipAccount
        sipPasswd= this.state.sipPasswd
        sipHost= this.state.sipHost
        sipRealm= this.state.sipRealm
      }

      this.setState({
        uiMode: [],
        statusUA: 1,
        phoneState: 'offline',
      })
      var socket = new JsSIP.WebSocketInterface("wss://"+ sipHost +"/");
      socket.via_transport = "udp";

      var configuration = { //https://jssip.net/documentation/3.3.x/api/ua_configuration_parameters/
        sockets  : [ socket ],
        uri      : "sip:"+ sipAccount +"@"+sipHost,
        password : sipPasswd,

        session_timers: false,
        // session_timers_refresh_method: 'invite',

        iceServers: [
          {'urls': ['stun:stun.l.google.com:19302']}
        ],
        use_preloaded_route: true,
        
        // realm: sipRealm, //'crm.halopagi.com', //SIP Authentication realm
        // register: false, //Indicate if JsSIP User Agent should register automatically when starting
        // register_expires: 600, //Registration expiry time (in seconds) 
        // connection_recovery_max_interval: 10, //Maximum interval (Number) in seconds between WebSocket reconnection attemps
      };
      
      if (ua!=undefined) {
        ua.stop();
      } 

      ua = new JsSIP.UA(configuration);
      // console.log('!!! UA', ua)
      
      ua.start();
      this.sipEventHandler(ua)
    } catch(err) {
      this.props.loggingError({level: 'error', actor: 'APP SIP', scope: 'Omniphone', event: 'On sipRegister', message: err });
    }
  }

  sipUnregister() {
    try {
      // console.log('!!! UA', ua.isRegistered(), ua.isConnected(), ua)
      if (ua!=undefined) {
        // console.log('!!! UA', 'STOP')
        ua.stop()
      }
    } catch(err) {
      this.props.loggingError({level: 'error', actor: 'APP SIP', scope: 'Omniphone', event: 'On sipUnregister', message: err });
    }
  }

  sipEventHandler(ua) { //https://jssip.net/documentation/3.8.x/api/ua/#section_events
    const parent= this;

    try {
      //CONNECTION EVENT
      ua.on("connecting", function(e){
        console.log("!!! UA ON CONNECTING", e);
        parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Connecting', message: e, })

        parent.setState({
          statusUA: 1,
          phoneState: 'connecting',
        })
      });
      ua.on("connected", function(e){
        console.log("!!! UA ON CONNECTED", e);
        parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Connected', message: e, })

        parent.setState({
          statusUA: 2,
          phoneState: 'connected',
        })
      });
      ua.on("disconnected", function(e){
        console.log("!!! UA ON DISCONNECTED", e);
        parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Disconnected', message: e, })

        parent.setState({
          statusUA: 0,
          phoneState: 'offline',
        })
      });
      
      
      //CALL EVENT
      ua.on("newRTCSession", function(e){
        console.log("!!! UA ON RTC SESSION EVENT ", e.session);
        parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On newRTCSession', message: e, })

        // incoming call here
        session= e.session;
        // sessions.set(session.id, session)

        if (session.direction === "incoming") {
          console.log("!!! RTC SESSION INCOMING ");
          
          session.on("progress",function(e){
            // the call has answered
            console.log("!!! UA ON INCOMING - RTC PROGRESS");
            parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Incoming - newRTCSession Progress', message: e, })

            parent.ring.play();
            parent.setState({
              phoneState: 'incoming',
            })

            //AUTO ANSWER MODE
            if (silentCallback) {
              setTimeout(()=>{
                parent.callAnswer();
              }, 2000)
            }
          });
          session.on("accepted",function(e){
              // the call has answered
              console.log("!!! UA ON INCOMING - RTC ACCEPTED");
              parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Incoming - newRTCSession Accepted', message: e, })

              parent.setState({
                phoneState: 'oncall',
              })
          });
          session.on("confirmed",function(){
              // this handler will be called for incoming calls too
              console.log("!!! UA ON INCOMING - RTC CONFIRMED");
              parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Incoming - newRTCSession Confirmed', message: e, })

              parent.setState({
                phoneState: 'oncall',
              })
              parent.ring.stop();
          });
          session.on("peerconnection", function(e) {
            console.log('!!! UA ON INCOMING - RTC PEERCONNECTION: ', e)
            parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Incoming - newRTCSession peerConnection', message: e, })

            parent.addStream();
          });
          session.on("icecandidate", function(e) {
            // n(e.candidate.candidate);
            if (e.Last) {
              console.log('!!! UA ON INCOMING - RTC ICE CANDIDATE: ', e)
              parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Incoming - newRTCSession iceCandidate', message: e, })

              e.ready();
            }
          });
          session.on("sdp", function(e) {
            console.log('!!! UA ON INCOMING - RTC SDP: ', e)
            parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Incoming - newRTCSession sdp', message: e, })

          });
          session.on("newInfo", function(e) {
            console.log('!!! UA ON INCOMING - RTC INFO: ', e)
            parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Incoming - newRTCSession newInfo', message: e, })
          });
          session.on("newDTMF", function(e) {
            console.log('!!! UA ON INCOMING - RTC DTMF: ', e)
            parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Incoming - newRTCSession newDTMF', message: e, })
          });
          session.on("failed",function(e){
              // unable to establish the call
              console.log("!!! UA ON INCOMING - RTC FAILED", e);
              parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Incoming - newRTCSession RTC Failed', message: e, })

              if (e.originator=='remote' && e.cause=="Canceled") {
                parent.setState({
                  statusUA: 2,
                  phoneState: 'online',
                })
              } else if (e.originator=='local' && e.cause=="Rejected") {
                parent.setState({
                  statusUA: 2,
                  phoneState: 'online',
                })
              } else if (e.originator=='local' && e.cause=="User Denied Media Access") {
                parent.setState({
                  statusUA: 2,
                  phoneState: 'online',
                  errorMedia: true,
                })
              }
              // parent.setState({
              //   // phoneState: 'online',
              // })

              session= undefined
              parent.ring.stop();
          });
          session.on("ended",function(){
              // the call has ended
              console.log("!!! UA ON INCOMING - RTC ENDED");
              parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Incoming - newRTCSession RTC Ended', message: e, })

              parent.setState({
                phoneState: 'online',
              })
              session= undefined
              parent.ring.stop();
              parent.busy.play()
          });
        } else if (session.direction === "outgoing") {
          console.log("!!! RTC SESSION OUTGOING ");
          console.log("!!! UA ON OUTGOING - ADD STREAM");
          parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Outgoing - newRTCSession RTC Outgoing', message: e, })

          parent.addStream();
        }
      });

      //MESSAGE EVENT
      ua.on("newMessage", function(e){
        console.log("!!! UA ON TEXTING EVENT", e.message.direction);
        parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On newMessage', message: e, })

        if (e.message.direction=='incoming') { //INCOMING
          parent.textReceive(e.message, e.request);
        } else { //OUTGOING

        }
      });

      //SIP EVENT
      ua.on("sipEvent", function(e, p){
        console.log("!!! UA ON SIP EVENT", e);
        parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On sipEvent', message: e, })
      });

      //REGISTERED EVENT
      ua.on("registered", function(e){
        console.log("!!! UA ON REGISTERED");
        parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Registered', message: e, })
        parent.props.updateBridging({
          registered: 1,
        });
        parent.setState({
          statusUA: 3,
          phoneState: 'online',
        })
      });
      ua.on("unregistered", function(e){
        console.log("!!! UA ON UNREGISTERED", e);
        parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On unregistered', message: e, })

        parent.props.updateBridging({
          registered: 0,
        });
        parent.setState({
          statusUA: 0,
          phoneState: 'offline',
        })
      });
      ua.on("registrationFailed", function(e){
        console.log("!!! UA ON REGISTRATION FAILED", e);
        parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On registrationFailed', message: e, })

        parent.setState({
          statusUA: 4,
          phoneState: 'offline',
        })
      });
    } catch(err) {
      this.props.loggingError({level: 'error', actor: 'APP SIP', scope: 'Omniphone', event: 'On sipEventHandler', message: err });
    }
  }

  addStream() {
    // session.connection.onaddstream = function(e) {
    session.connection.addEventListener('addstream',function(e) {
      // console.log("!!! CALL ADD STREAM");
      const remoteAudio = new window.Audio()
      remoteAudio.srcObject = e.stream
      remoteAudio.play();

      // const localView = document.getElementById('localView');
      // const remoteView = document.getElementById('remoteView');
      
      // if (remoteView) {
      //   remoteView.srcObject = (e.stream);
      // }
      // if (localView) {
      //   localView.srcObject = (session.connection.getLocalStreams()[0]);
      // }

      remoteVideoStream= (e.stream);
      localVideoStream= (session.connection.getLocalStreams()[0]);
    })
  }

  callAnswer() {
    const callOptions = {
      extraHeaders: [],
      // mediaConstraints : { audio: true, video: false },
      // mediaConstraints : { audio: true, video: true },
      // mediaConstraints : { audio: true, video: true, screen: true },
      ...this.state.callOptions
    }
    
    try {
      if (session && !session.isEnded())  {
        session.answer(callOptions)
        // session.answer()
        this.setState({
          phoneState: 'oncall',
        })
        console.log('!!! INCOMING - SESSION ANSWER')
        this.state.svcRunning=='Debugging' && this.props.loggingError({level: 'debug', actor: 'APP SIP', scope: 'Omniphone', event: 'On callAnswer', message: 'Incoming - Session Answered' })
      } else {
        this.setState({
          phoneState: 'online',
        })
      }
    }
    catch(err) {
      console.log('ERROR ANSWER CALL', err)
      this.props.loggingError({level: 'error', actor: 'APP SIP', scope: 'Omniphone', event: 'On callAnswer', message: err })
    }
  }

  callDial() {
    const { terminating }= this.state
    const parent= this;
    
    try {
      //CALLING
      this.dial.play()

      const callEventHandlers = {
        'connecting': function(e) {
          console.log('!!! UA ON OUTGOING - CONNECTING: ', e);
          this.state.svcRunning=='Debugging' && this.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Outgoing - Connecting', message: e });

          parent.setState({
            phoneState: 'connecting',
          })
        },
        'progress': function(e) {
          console.log('!!! UA ON OUTGOING - PROGRESS: ', e);
          this.state.svcRunning=='Debugging' && this.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Outgoing - Progress', message: e });

          parent.setState({
            phoneState: 'dialing',
          })
        },
        'confirmed': function(e) {
          console.log('!!! UA ON OUTGOING - CONFIRMED: ', e);
          this.state.svcRunning=='Debugging' && this.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Outgoing - Confirmed', message: e });

          parent.setState({
            phoneState: 'oncall',
          })

          parent.dial.stop()
        },
        'icecandidate': function(e) {
          // n(e.candidate.candidate);
          if (e.Last) {
            console.log('!!! UA ON OUTGOING - ICECANDIDATE: ', e);
            this.state.svcRunning=='Debugging' && this.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Outgoing - iceCandidate', message: e });

            e.ready();
          }
        },
        'sdp': function(e) {
          console.log('!!! UA ON OUTGOING - SDP: ', e);
            this.state.svcRunning=='Debugging' && this.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Outgoing - sdp', message: e });

          // n(e.candidate.candidate);
          // if (e.Last) {
          //   e.ready();
          // }
        },
        'newInfo': function(e) {
          console.log('!!! UA ON OUTGOING - NEW INFO: ', e);
          this.state.svcRunning=='Debugging' && this.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Outgoing - newInfo', message: e });

          // n(e.candidate.candidate);
          // if (e.Last) {
          //   e.ready();
          // }
        },
        'newDTMF': function(e) {
          console.log('!!! UA ON OUTGOING - NEW DTMF: ', e);
          this.state.svcRunning=='Debugging' && this.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Outgoing - newDTMF', message: e });

          // n(e.candidate.candidate);
          // if (e.Last) {
          //   e.ready();
          // }
        },
        'ended': function(e) {
          console.log('!!! UA ON OUTGOING - ENDED: ', e);
          this.state.svcRunning=='Debugging' && this.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Outgoing - Ended', message: e });

          parent.setState({
            phoneState: 'online',
          })
          session= undefined;
          parent.ring.stop();
          parent.busy.play();
        },
        'failed': function(e) {
          console.log('!!! UA ON OUTGOING - FAILED: ', e);
          this.state.svcRunning=='Debugging' && this.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'On Outgoing - Failed', message: e });
          
          const {originator, cause}= e
          
          parent.setState({
            phoneState: 'online',
          })
          session= undefined
          
          setTimeout(()=>{
            if (parent.state.phoneState!='dialing' && parent.state.phoneState!='incoming' && parent.state.phoneState!='oncall'  && parent.state.phoneState!='offline') { 
              parent.setState({
                phoneState: 'online',
              })
            }
          },5000)

          parent.dial.mute()
          parent.busy.play();
        },
      };
      const callOptions = {
        eventHandlers: callEventHandlers,
        // mediaConstraints: { audio: true, video: false },
        // mediaConstraints : { audio: true, video: true },
        // mediaConstraints : { audio: true, video: true, screen: true },
        ...this.state.callOptions
      }
      
      // session = ua.call('sip:'+ terminating +'@174.138.19.28:5060', callOptions);
      // session = ua.call('sip:'+ terminating +'@202.52.48.75:5160', callOptions);
      session = ua.call('sip:'+ terminating , callOptions);
    }
    catch(err) {
      console.log('ERROR ANSWER CALL', err)
      this.props.loggingError({level: 'error', actor: 'APP SIP', scope: 'Omniphone', event: 'On callDial', message: err })
    }
  }

  callHangup() {
    console.log('!!! HANGUP CALL');
    this.state.svcRunning=='Debugging' && this.props.loggingError({level: 'debug', actor: 'APP SIP', scope: 'Omniphone', event: 'On callHangup', message: '' });
    
    try {
      if (session) {
        session.terminate();
      }
    }
    catch(err) {
      console.log('!!! HANGUP ERROR: ', err);
      this.props.loggingError({level: 'error', actor: 'APP SIP', scope: 'Omniphone', event: 'On callHangup', message: err });
    }

    this.setState({
      phoneState: 'online',
    })
    
    // sessions && sessions.forEach((session, key)=>{
    //   session.terminate();
    // })
  }

  callMute() {
    console.log('!!! MUTE CALL');
    this.state.svcRunning=='Debugging' && this.props.loggingError({level: 'error', actor: 'APP SIP', scope: 'Omniphone', event: 'On callMute', message: '' });

    try {
      const {isMute}= this.state
      if (session && !isMute) {
        session.mute({audio: true, video: true});
      } else if(session) {
        session.unmute({audio: true, video: true});
      }

      this.setState({
        isMute: !isMute,
      })
    } catch(err) {
      console.log('!!! MUTE ERROR: ', err);
      this.props.loggingError({level: 'error', actor: 'APP SIP', scope: 'Omniphone', event: 'On callMute', message: err });
    }
  }

  textSend(message, type) {
    try {
      const parent= this
      const { chats }= this.state
      const agentStatus= (this.props.socketStore.socketAgentStatus!=undefined ? this.props.socketStore.socketAgentStatus.data.payload.data : {})
      // agentStatus.status=='occupied' && console.log('!!! AGENT STATUS', agentStatus )
      console.log('!!! TEXT SEND', message, type)
      this.state.svcRunning=='Debugging' && this.props.loggingError({level: 'debug', actor: 'APP SIP', scope: 'Omniphone', event: 'On textSend', message: message });

      var textingEventHandlers = {
        'succeeded': function(e){
          console.log('!!! UA TEXTING ON SUCCEEDED: ',e);
          parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'sendMessage - On Succeeded', message: e });

          if (type=='command') {
            chats.push({
              stamp_: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), //'2019-01-01 20:00',
              stamp: format(new Date(), 'HH:mm:ss'),
              sender: 'agent',
              message: 'Executing '+ message,
              status: '1',
            })
          } else if (type=='variable') {
            chats.push({
              stamp_: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), //'2019-01-01 20:00',
              stamp: format(new Date(), 'HH:mm:ss'),
              sender: 'agent',
              message: 'Requesting '+ message,
              status: '1',
            })
          } else {
            chats.push({
              stamp_: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), //'2019-01-01 20:00',
              stamp: format(new Date(), 'HH:mm:ss'),
              sender: 'agent',
              message: message,
              status: '1',
            })
          }
          parent.setState({
            chats: chats,
          })
        },
        'failed':    function(e){ 
          console.log('!!! UA TEXTING ON FAILED: ',e);
          parent.state.svcRunning=='Debugging' && parent.props.loggingError({level: 'debug', actor: 'UA SIP', scope: 'Omniphone', event: 'sendMessage - On Failed', message: e });

          if (type=='command') {
            chats.push({
              stamp_: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), //'2019-01-01 20:00',
              stamp: format(new Date(), 'HH:mm:ss'),
              sender: 'agent',
              message: 'Executing '+ message,
              status: '-1',
            })
          } else if (type=='variable') {
            chats.push({
              stamp_: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), //'2019-01-01 20:00',
              stamp: format(new Date(), 'HH:mm:ss'),
              sender: 'agent',
              message: 'Requesting '+ message,
              status: '-1',
            })
          } else {
            chats.push({
              stamp_: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), //'2019-01-01 20:00',
              stamp: format(new Date(), 'HH:mm:ss'),
              sender: 'agent',
              message: message,
              status: '-1',
            })
          }
          parent.setState({
            chats: chats,
          })
        }
      };
      var textingOptions = {
        'eventHandlers': textingEventHandlers
      };
      // chats.push({
      //   stamp_: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), //'2019-01-01 20:00',
      //   stamp: format(new Date(), 'HH:mm:ss'),
      //   sender: 'cust',
      //   message: message,
      //   status: '0',
      // })
      // this.setState({
      //   chats: chats,
      // })
      // ua.sendMessage(terminating +'@174.138.19.28', text, textingOptions);
      let jsonMessage;
      if (type=='command') {
        jsonMessage= {
          type: '0x04', //0x01 => plain, 0x02=> plain automation, 0X03=>variable, 0x04=>command
          session: '',
          body: message,
          descript: 'Executing '+ message,
        }
      } else if (type=='variable') {
        jsonMessage= {
          type: '0x03', //0x01 => plain, 0x02=> plain automation, 0X03=>variable,
          session: '',
          body: message,
          descript: 'Requesting '+ message,
        }
      } else {
        jsonMessage= {
          type: '0x01', //0x01 => plain, 0x02=> plain automation, 0X03=>variable,
          session: '',
          body: message,
        }
      }
      
      if (agentStatus.status=='occupied' && agentStatus.conversation) {
        // ua.sendMessage('62999333002', JSON.stringify(jsonMessage), textingOptions);
        if (agentStatus.conversation.flow=='0') {
          ua.sendMessage(agentStatus.conversation.originating, JSON.stringify(jsonMessage), textingOptions);
        } else {
          ua.sendMessage(agentStatus.conversation.terminating, JSON.stringify(jsonMessage), textingOptions);
        }
      }

      // var textingEventHandlers = {
      //   'succeeded': function(e){
      //     // console.log('!!! TEXTING SUCCEED: ',e);
      //   },
      //   'failed':    function(e){ 
      //     // console.log('!!! TEXTING FAILED: ',e);
      //   }
      // };

      // var textingOptions = {
      //   'eventHandlers': textingEventHandlers
      // };
      // const { text, terminating }= this.state
      // ua.sendMessage(terminating +'@174.138.19.28', text, textingOptions);
      // ua.sendMessage(terminating, text, textingOptions);
    } catch(err) {
      console.log('!!! TEXT SEND ERROR: ', err);
      this.props.loggingError({level: 'error', actor: 'APP SIP', scope: 'Omniphone', event: 'On textSend', message: err });
    }
  }

  textReceive(message, request) {
    try {
      const { chats }= this.state
      const mesg= JSON.parse(request.body);
      // console.log('!!! TEXT RECEIVE', mesg)
      this.state.svcRunning=='Debugging' && this.props.loggingError({level: 'debug', actor: 'APP SIP', scope: 'Omniphone', event: 'On textReceive', message: request });
    
      chats.push({
        stamp_: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), //'2019-01-01 20:00',
        stamp: format(new Date(), 'HH:mm:ss'),
        sender: 'cust',
        message: mesg.body,
        status: '1',
      })
      this.setState({
        chats: chats,
      })
    } catch(err) {
      console.log('!!! TEXT RECV ERROR: ', err);
      this.props.loggingError({level: 'error', actor: 'APP SIP', scope: 'Omniphone', event: 'On textReceive', message: err });
    }
  }

  setPhoneUI(layout) {
    const { uiMode, dialMode }= this.state
    if (layout=='dial') {
      for( var i = 0; i < uiMode.length; i++){ if ( uiMode[i] === 'texting') { uiMode.splice(i, 1); i--; }}
      this.setState({
        dialMode: !dialMode,
        uiMode: uiMode,
      })
    } else {
      if (layout=='texting') {
        if (uiMode.includes(layout)) {
          for( var i = 0; i < uiMode.length; i++){ if ( uiMode[i] === layout) { uiMode.splice(i, 1); i--; }}
        } else {
          uiMode.push(layout)
        }
        this.setState({
          dialMode: false,
          uiMode: uiMode,
        }) 
      } else if (uiMode.includes(layout)) {
        for( var i = 0; i < uiMode.length; i++){ if ( uiMode[i] === layout) { uiMode.splice(i, 1); i--; }}
        this.setState({
          uiMode: uiMode
        })
      } else {
        uiMode.push(layout)
        this.setState({
          uiMode: uiMode
        })
      }
    }
  }

  onChangeText(k, e, v) {
    if (k=='account') {
      this.setState({sipAccount: v.value})
    } else if (k=='passwd') {
      this.setState({sipPasswd: v.value})
    } else if (k=='host') {
      this.setState({sipHost: v.value})
    } else if (k=='uri') {
      this.setState({sipUri: v.value})
    } else if (k=='number') {
      this.setState({terminating: v.value})
    } else if (k=='text') {
      this.setState({text: v.value})
    }
  }

  onClickReconnect() {
    try {
      this.sipRegister();
    } catch(err) {
      this.props.loggingError({level: 'error', actor: 'APP SIP', scope: 'Omniphone', event: 'On onClickReconnect', message: err });
    }
  }

  hasSaved() {
    // console.log(this.state)
    this.setState({
      // targetId: 0,
      // visitorId: 0,
      // customerId: 0,
      // contactId: 0,
      layout: 'mini',
    })
  }

  startConversation(media, params) {
    if (media=='Voice') {

    } else if (media=='Chat') {

    } else {

    }
  }

  endConversation(media, params) {
    
    if (media=='Voice') {
      this.props.hangupOmniphone(); 
    } else if (media=='Chat') {
      this.textSend('CLOSE', 'command');
      this.props.chatOmniphone({
        command: params.command, //'end_by_agent',
        visitorId: params.visitorId,
        agentId: params.agentId,
        clientId: params.clientId,

        conversations: this.state.chats,
      })
    } else { //SMS, Email, Whatsapp, Twitter, Facebook, Instagram
      this.props.mesgOmniphone({
        media: media,
        command: params.command, //'end_by_agent',
        visitorId: params.visitorId,
        agentId: params.agentId,
        clientId: params.clientId,

        messagingId: params.messagingId,
        
        conversations: this.state.chats,
      })
    }
  }

  componentDidMount() {
    const {sipAccount, sipPasswd,sipHost,sipRealm, channel }= this.props 
    this.setState({
      init: true,
      sipAccount: sipAccount,
      sipPasswd: sipPasswd, 
      sipHost: sipHost, 
      sipRealm: sipRealm,

      callOptions: {
        mediaConstraints : { audio: true, video: channel=='Video' ? true : false },
      }
    })

    this.sipRegister(sipAccount, sipPasswd, sipHost, sipRealm);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    try {
      const { lastStatus, phoneState }= this.state
      const prevagentStatus= (prevProps.socketStore.socketAgentStatus!=undefined ? prevProps.socketStore.socketAgentStatus.data.payload.data : {})
      const agentStatus= (this.props.socketStore.socketAgentStatus!=undefined ? this.props.socketStore.socketAgentStatus.data.payload.data : {})
      const prevUpdateBridging= (prevProps.bridgingStore.updateBridging!=undefined ? prevProps.bridgingStore.updateBridging : undefined)
      const updateBridging= (this.props.bridgingStore.updateBridging!=undefined ? this.props.bridgingStore.updateBridging : undefined)

      const svcRunning= this.props.authStore.checkToken && this.props.authStore.checkToken.data ? this.props.authStore.checkToken.data.svcRunning : 'Production';

      // agentStatus.status=='occupied' && console.log('!!! AGENT STATUS', agentStatus )
    
      if (this.state.svcRunning!=svcRunning) {
        this.setState({
          svcRunning: svcRunning,
        })
      }
      if (prevagentStatus.status=='occupied' && agentStatus.status!='occupied') {
        this.busy.play();
      } else if (prevagentStatus.status!='occupied' && agentStatus.status=='occupied' && agentStatus.conversation
        && (
          this.state.voiceId!=agentStatus.conversation.voiceId || this.state.messagingId!=agentStatus.conversation.messagingId
          || this.state.targetId!=agentStatus.conversation.targetId || this.state.visitorId!=agentStatus.conversation.visitorId || this.state.customerId!=agentStatus.conversation.customerId || this.state.contactId!=agentStatus.conversation.contactId) 
        ) {
        this.join.play();
        
        this.setState({
          targetId: agentStatus.conversation.targetId,
          visitorId: agentStatus.conversation.visitorId,
          customerId: agentStatus.conversation.customerId,
          contactId: agentStatus.conversation.contactId,
          voiceId: agentStatus.conversation.voiceId,
          messagingId: agentStatus.conversation.messagingId,
          flow: agentStatus.conversation.flow,
          media: agentStatus.conversation.media,
          layout: 'normal',
          lastLayout: this.state.layout,
          uiMode: agentStatus.conversation.media=='Voice' ? ['call'] : ['texting'], //texting, dial, call

          ...(lastStatus!= agentStatus.status ? {chats: [], lastStatus: agentStatus.status} : {}),
        })
      } else if (agentStatus.status=='occupied' && this.state.layout!='normal') {
        this.setState({
          lastStatus: agentStatus.status,
          layout: 'normal',
          lastLayout: this.state.layout,
        })
      } else if (agentStatus.status=='connect' && lastStatus!='connect') {
        if (!this.state.startModal && agentStatus.session && agentStatus.session.toLowerCase()=='auto') {
          this.setState({
            startModal: true,
            lastStatus: agentStatus.status,
          })
        }
      } else if (lastStatus!=agentStatus.status) {
        this.setState({
          lastStatus: agentStatus.status,
        })
      }

      //BUTTON VISIBILITY
      if (prevState.phoneState!=phoneState || prevagentStatus.status!=agentStatus.status) {
        console.log('!!! PHONESTATE - AGENTSTATUS', prevState.phoneState, phoneState, prevagentStatus.status, agentStatus.status)
        this.setState({
          buttons: {
            left: ((phoneState=='oncall' && agentStatus.status=='occupied') && 'hangup') || ((phoneState=='incoming') && 'callback') || ((phoneState=='oncall' || agentStatus.status=='online') && 'quit') || null,
            right: ((agentStatus.status=='locked') && 'unlock') || ((phoneState=='online') && 'start') || ((phoneState=='oncall') && 'lock') || null,
          }
        })
      }

      //SLOW NETWORK DETECTION
      if (prevUpdateBridging && updateBridging && prevUpdateBridging.progress!=updateBridging.progress && updateBridging.start && updateBridging.finish) {
        // console.log('!!!! UPDATE BRIDGING', prevUpdateBridging, updateBridging, updateBridging.finish - updateBridging.start)
        this.setState({
          latency: updateBridging.finish - updateBridging.start
        })
      }

      //WEBSOCKET DISCONNECTED DETECTION
      const socketStatus= (this.props.socketStore.socketStatus!==undefined ? this.props.socketStore.socketStatus.data : undefined)
      if (socketStatus && !socketStatus.isConnected && (!this.state.socketStatus || socketStatus.isConnected!=this.state.socketStatus.isConnected)) {
        this.setState({
          socketStatus: socketStatus,
        })

        if (socketStatus && !socketStatus.isConnected) {
          this.callHangup();
        }
      }
    } catch(err) {
      this.props.loggingError({level: 'error', actor: 'APP SIP', scope: 'Omniphone', event: 'On componentDidUpdate', message: err });
    }
  }

  componentWillUnmount() {
    try {
      if (this.pausedInterval) {
        clearInterval(this.pausedInterval)
      }
      this.sipUnregister();
    } catch(err) {
      this.props.loggingError({level: 'error', actor: 'APP SIP', scope: 'Omniphone', event: 'On componentWillUnmount', message: err });
    }
  }

  startMode(cmd) {
    if (this.pausedInterval) {
      clearInterval(this.pausedInterval)
    }

    switch(cmd) { //callback quit start  lock unlock  hangup
      case 'start':
        this.props.updateBridging({
          online: 1,
        })
        break;
      case 'callback':
        this.callAnswer()
        break;
      case 'lock':
        this.openLockModal();
        break;
      case 'unlock':
        this.props.updateBridging({
          locked: 0,
        })
        break;
      case 'quit':
        this.callHangup();
        break;
      case 'hangup':
        break;
      case 'predialed':
        this.props.updateBridging({
          predialed: 0,
        })
        break;
      default:
        // code block
    }
  }

  openLockModal() {
    this.setState({
      lockModal: true,
      lockReason: null,
    })
  }
  closeLockModal() {
    const agentStatus= (this.props.socketStore.socketAgentStatus!=undefined ? this.props.socketStore.socketAgentStatus.data.payload.data : {})

    if (agentStatus.locked=='Modal' && agentStatus.status=='locked') {
      this.props.updateBridging({
        locked: 0,
        actionReason: null,
      })
    }
    this.setState({
      lockModal: false,
      lockReason: agentStatus.locked=='Modal' ? null : this.state.lockReason,
    })
  }
  onChangeSelect(e, v) {
    console.log(v.value)
    this.setState({
      lockReason: v.value,
    })
  }
  doLockMode() {
    const agentStatus= (this.props.socketStore.socketAgentStatus!=undefined ? this.props.socketStore.socketAgentStatus.data.payload.data : {})
    
    this.props.updateBridging({
      locked: 1,
      actionReason: this.state.lockReason,
    })
    if (agentStatus.locked!='Modal') {
      this.setState({
        lockModal: false,
      })
    }
  }
  doStartSession() {
    this.setState({
      startModal: false,
    })
    this.props.updateBridging({
      online: 1,
    })
  }
  doQuitSession() {
    this.setState({
      startModal: false,
    })
    this.pausedInterval= setTimeout(()=>{
      this.doStartSession();
      this.pausedInterval= null;
    }, 60000)
    // this.callHangup();
  }

  render() {
    const { layout, windowPos, uiMode, sipAccount, phoneState, dialMode, statusUA, chats }= this.state;
    const { screenWidth, screenHeight, responsive, agent }= this.props

    const agentStatus= (this.props.socketStore.socketAgentStatus ? this.props.socketStore.socketAgentStatus.data.payload.data : {})
    const updateBridging= (this.props.bridgingStore.updateBridging ? this.props.bridgingStore.updateBridging : {})
    
    // const { status }= (agentStatus && {status: agentStatus.status}) || {status: 'connect'}

    // console.log('!!!! OMNIPHONE', agentStatus, this.state.targetId)
    // console.log('!!!! CHATS', chats)
    try {
      if (this.state.errorMedia==true) {
        return (
          <Modal style={{background: '#f5f5f5'}} open={true} size='mini'>
            <Segment style={{background: '#710', pading: '3em', textAlign: 'center', borderColor: '#f00'}}>
              <Header as='h1' icon inverted>
                <Icon name='microphone slash' style={{fontSize: '2em', padding: '.3em'}}/>
                <Header.Content>
                  <b>Media Access Error !</b>
                  <Divider />
                  <Header.Subheader>Makesure your microphone access is granted</Header.Subheader>
                </Header.Content>
              </Header>

              <Divider />
              <Button onClick={()=>{window.location.reload()}} size='large' color='yellow' style={{margin: '2em'}}>
                <Header as='h3'><b>Reload Page</b></Header>
              </Button>
            </Segment>
          </Modal>
        )
      }

      if (agentStatus) {
        if (layout=='normal') {
          return (
            <Modal style={{background: '#f5f5f5'}}
            open={layout=='normal'}
            // centered={false}
            // onClose={this.handleClose.bind(this)}
            size= 'fullscreen'>
              <Segment style={{border: 'none', padding: '0', background: 'rgb(250, 250, 250)'}}>
                <div style={{display: 'flex', flexDirection: responsive ? 'column' : ''}}>
                  <div style={{width: responsive ? 'auto' : (uiMode.indexOf('texting')>=0 && agentStatus.conversation.media=='Email' ? 460 : 334), background: '#444444', padding: '0', alignItems: 'stretch', overflow: 'hidden'}}>
                    {uiMode.indexOf('texting')>=0 && agentStatus.conversation.media=='Chat' && 
                      <OmniphoneChat 
                        screenWidth={screenWidth} screenHeight={screenHeight-200} 
                        agent={agent}
                        level={this.state.checkedLevel} chats={chats} phoneState={phoneState} visitor={agentStatus.visitor} conversation={agentStatus.conversation} status={agentStatus.status} 
                        textSend={this.textSend.bind(this)} 
                        endConversation={this.endConversation.bind(this)}
                      />
                    }
                    {uiMode.indexOf('texting')<0 && 
                      <OmniphoneCall
                        channel={this.props.channel} 
                        remoteVideoStream= {remoteVideoStream} localVideoStream={localVideoStream}
                        screenWidth={screenWidth} screenHeight={screenHeight-200} 
                        responsive={responsive} layout={layout} windowPos={windowPos}  agent={agent}
                        uiMode={uiMode} sipAccount={sipAccount} phoneState={phoneState} dialMode={dialMode} statusUA={statusUA}
                        setSkinMode={this.setSkinMode.bind(this)} 
                        setPhoneUI={this.setPhoneUI.bind(this)} 
                        callAnswer={this.callAnswer.bind(this)} 
                        callHangup={this.callHangup.bind(this)} 
                        endConversation={this.endConversation.bind(this)}
                      />
                    }

                    {uiMode.indexOf('texting')>=0 && agentStatus.conversation.media=='SMS' && 
                      <OmniphoneSms 
                        channel={this.props.channel} 
                        screenWidth={screenWidth} screenHeight={screenHeight-200} 
                        agent={agent}
                        level={this.state.checkedLevel} chats={chats} phoneState={phoneState} visitor={agentStatus.visitor} conversation={agentStatus.conversation} status={agentStatus.status} 
                        textSend={this.textSend.bind(this)} 
                        endConversation={this.endConversation.bind(this)}
                      />
                    }
                    {uiMode.indexOf('texting')>=0 && agentStatus.conversation.media=='Email' && 
                      <OmniphoneEmail 
                        channel={this.props.channel}
                        screenWidth={screenWidth} screenHeight={screenHeight-200} 
                        agent={agent}
                        level={this.state.checkedLevel} chats={chats} phoneState={phoneState} visitor={agentStatus.visitor} conversation={agentStatus.conversation} status={agentStatus.status} 
                        textSend={this.textSend.bind(this)} 
                        endConversation={this.endConversation.bind(this)}
                      />
                    }
                    {uiMode.indexOf('texting')>=0 && agentStatus.conversation.media=='Whatsapp' && 
                      <OmniphoneWhatsapp 
                        channel={this.props.channel}
                        screenWidth={screenWidth} screenHeight={screenHeight-200} 
                        agent={agent}
                        level={this.state.checkedLevel} chats={chats} phoneState={phoneState} visitor={agentStatus.visitor} conversation={agentStatus.conversation} status={agentStatus.status} 
                        textSend={this.textSend.bind(this)} 
                        endConversation={this.endConversation.bind(this)}
                      />
                    }
                    {uiMode.indexOf('texting')>=0 && agentStatus.conversation.media=='Twitter' && 
                      <OmniphoneTwitter 
                        channel={this.props.channel}
                        screenWidth={screenWidth} screenHeight={screenHeight-200} 
                        agent={agent}
                        level={this.state.checkedLevel} chats={chats} phoneState={phoneState} visitor={agentStatus.visitor} conversation={agentStatus.conversation} status={agentStatus.status} 
                        textSend={this.textSend.bind(this)} 
                        endConversation={this.endConversation.bind(this)}
                      />
                    }
                    {uiMode.indexOf('texting')>=0 && agentStatus.conversation.media=='Facebook' && 
                      <OmniphoneFacebook 
                        channel={this.props.channel}
                        screenWidth={screenWidth} screenHeight={screenHeight-200} 
                        agent={agent}
                        level={this.state.checkedLevel} chats={chats} phoneState={phoneState} visitor={agentStatus.visitor} conversation={agentStatus.conversation} status={agentStatus.status} 
                        textSend={this.textSend.bind(this)} 
                        endConversation={this.endConversation.bind(this)}
                      />
                    }
                    {uiMode.indexOf('texting')>=0 && agentStatus.conversation.media=='Instagram' && 
                      <OmniphoneInstagram 
                        channel={this.props.channel}
                        screenWidth={screenWidth} screenHeight={screenHeight-200} 
                        agent={agent}
                        level={this.state.checkedLevel} chats={chats} phoneState={phoneState} visitor={agentStatus.visitor} conversation={agentStatus.conversation} status={agentStatus.status} 
                        textSend={this.textSend.bind(this)} 
                        endConversation={this.endConversation.bind(this)}
                      />
                    }
                  </div>
                  <div style={{flex: '1', padding: '1em'}}>
                    {(agentStatus.status=='occupied' && (this.state.targetId!=0 || this.state.visitorId!=0 || this.state.customerId!=0 || this.state.contactId!=0)) &&
                      <div style={{display: 'flex', background: '#dedede', margin: '-1em', padding: '1em', height: '4.5em'}}>
                        <div>
                          <Button icon={this.state.isMute ? 'microphone slash' : 'microphone'} content={this.state.isMute ? 'Unmute' : 'Mute'} color={this.state.isMute ? 'red' : 'blue'} onClick={this.callMute.bind(this)} />
                        </div>
                        <div style={{flex: '1'}}>
                          <TimerProgressBar maxSecond={60}/>
                        </div>
                        <div>
                          <Icon name='times' style={{float: 'right', color: '#fff', cursor: 'pointer', padding: '0', fontSize: '1.8em'}} onClick={this.setSkinMode.bind(this, 'mini')} color={agentStatus.status=='occupied' ? 'grey' : 'red'} disabled={agentStatus.status=='occupied'} />
                        </div>
                      </div>
                    }
                    {(agentStatus.status!='occupied') &&
                      <div style={{display: 'flex', background: '#dedede', margin: '-1em', padding: '1em', height: '4.5em'}}>
                        <div style={{flex: '1', margin: '.1em 2em 0 0'}}>
                        </div>
                        <div>
                          <Icon name='times' style={{float: 'right', color: '#fff', cursor: 'pointer', padding: '0', fontSize: '1.8em'}} onClick={this.setSkinMode.bind(this, 'mini')} color='red'/>
                        </div>
                      </div>
                    }

                    {((this.state.targetId!=0 || this.state.visitorId!=0 || this.state.customerId!=0 || this.state.contactId!=0)) && <>
                      {agentStatus.conversation && agentStatus.conversation.flow=='0' && 
                      <OmniphoneContentInbound 
                        channel={this.props.channel}
                        screenWidth={screenWidth} screenHeight={screenHeight} 
                        mode={agentStatus.mode||'predictive'} status={agentStatus.status} conversation={agentStatus.conversation} visitor={agentStatus.visitor}
                        textSend={this.textSend.bind(this)} 
                        hasSaved={this.hasSaved.bind(this)}
                        endConversation={this.endConversation.bind(this)}
                      />
                      }
                      {agentStatus.conversation && agentStatus.conversation.flow!='0' && 
                        <OmniphoneContentOutbound
                          channel={this.props.channel}
                          screenWidth={screenWidth} screenHeight={screenHeight} 
                          mode={agentStatus.mode||'predictive'} status={agentStatus.status} conversation={agentStatus.conversation}
                          hasSaved={this.hasSaved.bind(this)}
                          endConversation={this.endConversation.bind(this)}
                        />
                      }
                    </>}
                    
                    {/* {agentStatus.status!='occupied' && this.state.targetId==0 && 
                    <div style={{marginTop: '2em', textAlign: 'center', minHeight: '100%'}}>
                      
                        {agentStatus.status!='locked' && phoneState=='online' &&
                        <Header as='h2' icon style={{maxWidth: '15em', padding: '3em  0 2em 0'}}>
                          <Icon name='warning sign' color='teal'/>
                          <Header.Content>
                            You are registered !
                            <Header.Subheader>Please click <Label color='grey' style={{padding: '.2em .5em'}}>START</Label> !. It will run your performance timer.</Header.Subheader>
                          </Header.Content>
                        </Header>}
                        {phoneState=='incoming' &&
                        <Header as='h2' icon style={{maxWidth: '15em', padding: '3em  0 2em 0'}}>
                          <Icon name='warning sign' color='teal'/>
                          <Header.Content>
                            Picked up your phone !
                            <Header.Subheader>Our engine is calling you back, please pick it up.</Header.Subheader>
                          </Header.Content>
                        </Header>}
                        {agentStatus.status!='locked' && phoneState=='online' && 
                        <Header as='h2' icon style={{maxWidth: '15em', padding: '3em  0 2em 0'}}>
                          <Icon name='warning sign' color='orange'/>
                          <Header.Content>
                            You are online !
                            <Header.Subheader>Please standing by while system execute auto dialing or calling your customer's number.</Header.Subheader>
                          </Header.Content>
                        </Header>}
                        {agentStatus.status=='locked' && 
                        <Header as='h2' icon style={{maxWidth: '15em', padding: '3em  0 2em 0'}}>
                          <Icon name='warning sign' color='red'/>
                          <Header.Content>
                            You are leaving / locked from system.
                            <Header.Subheader>You won't receive any dispatched call after your last call while on locked mode.</Header.Subheader>
                          </Header.Content>
                        </Header>}
                    </div>} */}
                  </div>
                </div>
              </Segment>
            </Modal>
          )
        } else if (layout==='mini') {
          return (<div style={{background: '#b0a697', borderRadius: '2.5em 0em .5em .5em', marginBottom: '3em'}}>
            <OmniphoneCall 
              channel={this.props.channel}
              screenWidth={screenWidth} screenHeight={screenHeight} 
              responsive={responsive} layout={layout} windowPos={windowPos}  agent={agent}
              uiMode={uiMode} sipAccount={sipAccount} phoneState={phoneState} dialMode={dialMode} statusUA={statusUA} 
              callAnswer={this.callAnswer.bind(this)} 
              callHangup={this.callHangup.bind(this)} 
              setSkinMode={this.setSkinMode.bind(this)} 
              setPhoneUI={this.setPhoneUI.bind(this)} 
              endConversation={this.endConversation.bind(this)}
            />
            {(phoneState=='connecting' || phoneState=='offline') && <div style={{background: '#f00', padding: '3.5em .5em 2em 1em', marginTop: '-1.5em'}}>
              <Header as='h4' color='yellow' inverted>
                <Header.Content>
                  <b>Your Device is Blocked!</b>
                  <Header.Subheader style={{color: '#fff'}}>Contact your Admin for IP Registration.</Header.Subheader>
                </Header.Content>
              </Header>
            </div>}
            
            {['oncall'].includes(phoneState) && this.props.channel=='Video' &&
              <div style={{minHeight: 220, marginTop: '-1.5em'}}>
                  <div style={{height: 230, width: 258, position: 'fixed', background: '#444444e6', padding: '3.8em 0 2em 0'}}>
                    {localVideoStream && <div style={{zIndex: 99, position: 'fixed',  cursor: 'pointer', marginLeft: 120, marginTop: '-1.5em'}}>
                      <Icon color={this.state.isMute ? 'red' : 'yellow'} name={this.state.isMute ? 'eye slash' : 'eye'} circular inverted style={{margin: '0'}} onClick={this.callMute.bind(this)}/>
                    </div>}
                    <div style={{position: 'fixed', minHeight: 200}}>
                      <OmniphoneVideo local width={258} height={165} remoteVideoStream= {remoteVideoStream} localVideoStream={localVideoStream} /> 
                    </div>
                  </div>
              </div>
            }

            {['online', 'incoming', 'oncall'].includes(phoneState) && <div style={{display: 'flex', background: '#c0b1a0', padding: '2.5em 0 0 0', marginTop: '-1.5em'}}>
              <div style={{flex: '1', padding: '1em .5em 1em 1em'}}>
                {this.state.buttons.left && 
                  <Popup  position='bottom left' header={popups.get(this.state.buttons.left).header} content={popups.get(this.state.buttons.left).content}
                    trigger={
                      <Button size='medium' style={{minWidth: '7.5em'}} disabled={!this.state.buttons.left || (this.state.buttons.left=='callback' && silentCallback==true) || agentStatus.status=='locked' || (updateBridging && updateBridging.progress==0)} color={(this.state.buttons.left=='callback' && 'orange') || (this.state.buttons.left=='quit' && 'red') || 'blue'} onClick={this.startMode.bind(this, this.state.buttons.left)}>
                        <Header as='h5' style={{margin: '0', textTransform: 'uppercase'}} inverted>
                          <Icon name={popups.get(this.state.buttons.left).icon || 'microphone'} style={{fontSize: '1em'}} />
                          <Header.Content>{popups.get(this.state.buttons.left).label || 'Quit'}</Header.Content>
                        </Header>
                      </Button>
                    }
                  />
                }
              </div>
              <div style={{flex: '1', padding: '1em 1em 1em .5em', textAlign: 'right'}}>
                {this.state.buttons.right && 
                  <Popup position='bottom right' header={popups.get(this.state.buttons.right).header} content={popups.get(this.state.buttons.right).content}
                    trigger={
                      <Button size='medium' style={{minWidth: '7.5em'}} className={agentStatus.status=='connect' ? 'bganimateblue' : ''} disabled= {this.state.buttons.left=='hangup' || this.state.buttons.left=='callback' || (updateBridging && updateBridging.progress==0)} color= {(this.state.buttons.right=='lock' && 'brown') || (this.state.buttons.right=='unlock' && 'red') || 'blue'} onClick={this.startMode.bind(this, this.state.buttons.right)}>
                        <Header as='h5' style={{margin: '0', textTransform: 'uppercase'}} inverted>
                          <Icon color={this.props.channel=='Video' ? 'yellow' : null} name={(this.props.channel=='Video' && this.state.buttons.right=='start' && 'video') || popups.get(this.state.buttons.right).icon || 'microphone'} style={{fontSize: '1em'}} />
                          <Header.Content>{popups.get(this.state.buttons.right).label || 'Start'}</Header.Content>
                        </Header>
                      </Button>
                    }
                  />
                }
              </div>
            </div>}

            {this.state.buttons.right=='unlock' && this.state.lockReason && <div style={{background: '#c0b1a0', borderTop: '1px solid #fff', padding: '1em .5em'}}>
              <Header as='h5' style={{margin: '0', textTransform: 'uppercase'}}>
                <Header.Content>
                  <Icon name='lock' /> "<b>{this.state.lockReason}</b>"
                  {/* <Header.Subheader>Locked Reason !</Header.Subheader> */}
                </Header.Content>
              </Header>
            </div>}
            <div style={{padding: '.5em .5em'}}>
              <span style={{fontSize: '.8em', color: '#eee'}}>P:{phoneState} - A:{agentStatus && agentStatus.status}/{agentStatus && agentStatus.mode}</span>
            </div>
            {this.state.lockModal && <ModalContainer size='mini' content={
              <LockConfirmation modal={agentStatus.locked=='Modal'} locked={agentStatus.status=='locked'} reason={this.state.lockReason} onClick={()=>this.doLockMode({reason: this.state.signoutReason})} onChangeSelect={this.onChangeSelect.bind(this)} state={this.state} closeModal={this.closeLockModal.bind(this)}/>
            } closeModal={this.closeLockModal.bind(this)} /> }
            {this.state.startModal && <ModalContainer size='mini' content={<SessionAutostart quitSession={()=>{this.doQuitSession()}} startSession={()=>this.doStartSession()} state={this.state} />} closeModal={this.doStartSession.bind(this)} />}

            {this.state.latency>5000 && <ModalContainer size='small' title='Slow Network Detected' 
              content={
              <Header as='h2' style={{margin: '1.5em .5em'}} color='red'>
                <Icon name='bug' style={{fontSize: '2em'}} inverted circular color='yellow' />
                <Header.Content>
                  <b>Network Too Slow!</b>
                  <Header.Subheader style={{color: '#333'}}>Your network is too slow to deliver proper conversation service, contact your network Admin!</Header.Subheader>
                </Header.Content>
              </Header>}  
            /> }
          </div>);
        } else if (layout==='floating') {
          return (<>
            {uiMode.indexOf('texting')>=0 && 
              <OmniphoneChat 
                level={this.state.checkedLevel} chats={chats} phoneState={phoneState}  agent={agent}
                textSend={this.textSend.bind(this)} 
                endConversation={this.endConversation.bind(this)}
              />
            }
            {uiMode.indexOf('texting')<0 && 
            <OmniphoneCall 
              channel={this.props.channel}
              screenWidth={screenWidth} screenHeight={screenHeight} 
              responsive={responsive} layout={layout} windowPos={windowPos} agent={agent}
              uiMode={uiMode} sipAccount={sipAccount} phoneState={phoneState} dialMode={dialMode} statusUA={statusUA}
              callAnswer={this.callAnswer.bind(this)} 
              callHangup={this.callHangup.bind(this)} 
              setSkinMode={this.setSkinMode.bind(this)} 
              setPhoneUI={this.setPhoneUI.bind(this)} 
              endConversation={this.endConversation.bind(this)}
              />}
          </>);
        }
      }
    } catch(err) {
      this.props.loggingError({level: 'error', actor: 'APP SIP', scope: 'Omniphone', event: 'On render', message: err });
    }
  }
}

Omniphone.contextType= WebSocketContext;

export default connect(mapStateToProps, mapDispatchToProps)(Omniphone)