import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
} from 'semantic-ui-react'

import { connect } from "react-redux"
import { browseSetting } from "../../actions/halo/settingAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    settingStore: state.settingStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseSetting:(objParam) => dispatch(browseSetting(objParam)),
  }
};


class SettingBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.settingStore.browseSetting!==undefined ? this.props.settingStore.browseSetting : {})
    this.props.browseSetting(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.settingStore.browseSetting!==undefined ? this.props.settingStore.browseSetting : {})
    this.props.browseSetting({search: {...search, groupId: 'Client'}, paging: paging})
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.settingStore.browseSetting!==undefined ? this.props.settingStore.browseSetting : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight-125}}>
        {paging!=undefined && paging.count>0 &&
          <div style={{padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination size='mini' onPageChange={this.doPaging.bind(this)}
              
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
            <Divider />
          </div>
        }
        <div style={{flex: '1'}}>
          <div style={{marginBottom: '.2em'}}>
            <Label size='large' color={this.props.settingId==1 ? 'red' : null} style={{width: '2.5em'}}>!!</Label>
            <Label size='large' _color='red' as='a' onClick={this.props.setMode.bind(this, 2, 1)} style={{fontWeight: (this.props.settingId==1 ? '600' : '500'), color: (this.props.settingId==1 ? '#000' : '#666')}}>System Ref. Config</Label>
          </div>
          <Divider />

          { data!=undefined && data.map((item,key)=>{
              return(
                <div key={key} style={{marginBottom: '.2em'}}>
                  <Label size='large' color={item.settingId==this.props.settingId ? 'red' : 'yellow'} style={{width: '2.5em'}}>{++seq}</Label>
                  <Label size='large' _color='blue' as='a' onClick={this.props.setMode.bind(this, 2, item.settingId)} style={{fontWeight: (item.settingId==this.props.settingId ? '600' : '500'), color: (item.settingId==this.props.settingId ? '#000' : '#666')}}>{item.company && (item.company.length>18 ? (item.company.substr(0,18)+ ' ...') : item.company)}</Label>
                </div>
              )
            })
          }
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingBrowse)
