import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Feed,
} from 'semantic-ui-react'

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse } from "date-fns"
import { messagingStatus } from "../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { inboundMessaging, statusMessaging } from "../../actions/stream/messagingAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    messagingStore: state.messagingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    inboundMessaging:(objParam) => dispatch(inboundMessaging(objParam)),
    statusMessaging:(objParam) => dispatch(statusMessaging(objParam)),
  }
}

class MessagingInboundRecent extends Component {
  constructor(props) {
    super(props)
    this.state= {
      content: false,
      expand: false,
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.messagingStore.inboundMessaging!==undefined ? this.props.messagingStore.inboundMessaging : {})
    this.props.inboundMessaging(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.messagingStore.inboundMessaging!==undefined ? this.props.messagingStore.inboundMessaging : {})
    this.props.inboundMessaging({search: {
      // transactDate: format(new Date(), 'yyyy-MM-dd 00:00:00'), 
    ...search, campaignId: this.props.campaignId}, paging: paging})
  }

  onChangeStatus(id, status, e) {
    // console.log(id, status, e)
    this.props.statusMessaging({messagingId:id, isEnabled: status})
  }
  setExpand() {
    const { expand }= this.state
    this.setState({
      expand: !expand
    })
  }
  setContent() {
    const { content }= this.state
    this.setState({
      content: !content
    })
  }

  expandEmail(id) {
    // console.log(id)
    this.setState({
      messagingId: (this.state.messagingId==id ? 0 : id),
    })
  }

  render() {
    //console.log(this.props.messagingStore.inboundMessaging)
    const { expand, content }= this.state
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.messagingStore.inboundMessaging!==undefined ? this.props.messagingStore.inboundMessaging : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', padding: '0 0 0 0', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1', padding: '0'}}>
          <Feed>
              { data!=undefined && data.length==0 && <div style={{padding: '.5em 0'}}><Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label></div>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Feed.Event key={++seq} style={{background: key%2==0 ? '#fffff' : '#fcfcfc', borderTop:key!=0 ? '1px solid #fff' : 'none', borderBottom:key!=(data.length-1) ? '1px solid rgba(100, 100, 100, .1)' : 'none'}}>
                      <Feed.Label style={{marginTop: '1em'}} image='https://react.semantic-ui.com//images/avatar/small/joe.jpg' />
                      <Feed.Content>
                        <Feed.Summary>
                          <Label as='a' color='olive'  style={{fontSize: '1.1em', padding: '.3em .5em', borderRadius: '1em'}}>Joe Henderson</Label>
                          <Feed.Date><b>3 days ago</b></Feed.Date>
                        </Feed.Summary>
                        <Feed.Extra text>
                          Ours is a life of constant reruns. We're always . . .
                        </Feed.Extra>
                        <Feed.Meta>
                          <Feed.Like>
                            {item.sentStamp!=null && format(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm:ss')}
                          </Feed.Like>
                        </Feed.Meta>
                      </Feed.Content>
                    </Feed.Event>
                  )
                }) : <div><Icon name='circle notch' loading />loading ...</div>
              }
          </Feed>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagingInboundRecent)
