import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Icon } from 'semantic-ui-react'

import { loggerOptions, yesnoOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'

import { SubmissionMiddleware, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { ImageUploadField, LabelInputField, NumberField, InputField, CheckboxField, SelectField, DatePickerField, TextAreaField, LabelNumberField, LabelSelectField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
import { connect } from "react-redux"
import { browseMiddleware, resetMiddleware, sandboxMiddleware } from "../../actions/middleware/middlewareAction"

//CONNECT REDUX STORE
const selector = formValueSelector('APISandboxForm')

const mapStateToProps = (state, props) => {
  return {
    middlewareStore: state.middlewareStore
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseMiddleware:(objParam) => dispatch(browseMiddleware(objParam)),

    resetMiddleware:(objParam) => dispatch(resetMiddleware(objParam)),
    sandboxMiddleware:(objParam, token, urlApi) => dispatch(sandboxMiddleware(objParam, token, urlApi)),
  }
}

class APISandboxForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    this.props.resetMiddleware(this.props.mode)
  }

  componentDidUpdate() {
    const { mode } = this.props
    const result= this.props.middlewareStore.sandboxMiddleware!=undefined ? this.props.middlewareStore.sandboxMiddleware : {}
    // if (result.progress==1 || result.progress==2) {
      console.log(result)
      this.props.dispatch(change('APISandboxForm', 'output', (result ? JSON.stringify(result) : '')))
    // }
  }

  componentWillUnmount() {
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const params= {}
    for (let i=1; i<10; i++) {
      if (values['param'+i]) {
        params[values['param'+i]]= values['value'+i];
      } 
    }
    this.props.sandboxMiddleware({...params}, this.props.token, this.props.endpoint)
  }
  

  render() {
    const { middleware, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= this.props.middlewareStore.sandboxMiddleware!=undefined ? this.props.middlewareStore.sandboxMiddleware : {}

    return (
      <div style={{padding: '1em 1em'}}>
        <Header as='h4' style={{marginTop: '.25em'}}>
          <Icon name='cube' style={{fontSize: '1em'}}/>
          <Header.Content>
            API Sandbox / Submission
          </Header.Content>
        </Header>

        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='equal'>
              <Field name='endpoint' component={InputField}
              label='URL API'
              placeholder='Api endpoint url.' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                  <b>Header Params</b>
                <Header.Subheader></Header.Subheader>
              </Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='token' component={LabelInputField}
              caption='Authorization'
              label='Token'
              placeholder='Token access.' />
            </Form.Group>
          </Segment>  
          <Segment>
            <Header as='h5'>
              <Header.Content>
                <b>Post Value Params</b>
                <Header.Subheader></Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <Form.Group widths={16}>
              <Field name='param1' component={LabelInputField}
              width={4}
              label='Param 1'
              placeholder='Param Name' />
              <Field name='value1' component={LabelInputField}
              width={12}
              label='Value 1'
              placeholder='Param value' />
            </Form.Group>
            <Form.Group widths={16}>
              <Field name='param2' component={LabelInputField}
              width={4}
              label='Param 2'
              placeholder='Param Name' />
              <Field name='value2' component={LabelInputField}
              width={12}
              label='Value 2'
              placeholder='Param value' />
            </Form.Group>
            <Form.Group widths={16}>
              <Field name='param3' component={LabelInputField}
              width={4}
              label='Param 3'
              placeholder='Param Name' />
              <Field name='value3' component={LabelInputField}
              width={12}
              label='Value 3'
              placeholder='Param value' />
            </Form.Group>
            <Form.Group widths={16}>
              <Field name='param4' component={LabelInputField}
              width={4}
              label='Param 4'
              placeholder='Param Name' />
              <Field name='value4' component={LabelInputField}
              width={12}
              label='Value 4'
              placeholder='Param value' />
            </Form.Group>
            <Form.Group widths={16}>
              <Field name='param5' component={LabelInputField}
              width={4}
              label='Param 5'
              placeholder='Param Name' />
              <Field name='value5' component={LabelInputField}
              width={12}
              label='Value 5'
              placeholder='Param value' />
            </Form.Group>
            <Form.Group widths={16}>
              <Field name='param6' component={LabelInputField}
              width={4}
              label='Param 6'
              placeholder='Param Name' />
              <Field name='value6' component={LabelInputField}
              width={12}
              label='Value 6'
              placeholder='Param value' />
            </Form.Group>
            <Form.Group widths={16}>
              <Field name='param7' component={LabelInputField}
              width={4}
              label='Param 7'
              placeholder='Param Name' />
              <Field name='value7' component={LabelInputField}
              width={12}
              label='Value 7'
              placeholder='Param value' />
            </Form.Group>
            <Form.Group widths={16}>
              <Field name='param8' component={LabelInputField}
              width={4}
              label='Param 8'
              placeholder='Param Name' />
              <Field name='value8' component={LabelInputField}
              width={12}
              label='Value 8'
              placeholder='Param value' />
            </Form.Group>
            <Form.Group widths={16}>
              <Field name='param9' component={LabelInputField}
              width={4}
              label='Param 9'
              placeholder='Param Name' />
              <Field name='value9' component={LabelInputField}
              width={12}
              label='Value 9'
              placeholder='Param value' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                <b>Output / Result</b>
                <Header.Subheader></Header.Subheader>
              </Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='output' component={TextAreaField}
              rows={15}
              label='Result / Output'
              placeholder='API result in JSON representation.' />
            </Form.Group>
          </Segment>

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={'API Submission' + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    (state, props) => ({
      // initialValues: (state.middlewareStore.getMiddleware!=undefined && state.middlewareStore.getMiddleware.progress==1 ? state.middlewareStore.getMiddleware.data : {}),
      initialValues: (props.data),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,

      endpoint: selector(state, 'endpoint'),
      token: selector(state, 'token'),
    }),
  )
  (reduxForm({
    form: 'APISandboxForm',	// a unique identifier for this form
  })(APISandboxForm)
  )
)