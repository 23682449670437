
import React from "react"
import {ResponsiveContainer,
  Legend, Tooltip, Cell,
  RadialBarChart, RadialBar, } from 'recharts'

const scheme= ['#F279B1', '#775566', '#7F3F5D', '#992255', '#961D55', '#4C3D44', '#A8004D', '#881144', '#2B2B2B', '#660033']

const dataDummy = [
  {name: '35-39', uv: 82, fill: '#82ca9d'},
  {name: '40-49', uv: 86, fill: '#a4de6c'},
  {name: '50+', uv: 26, fill: '#d0ed57'},
  {name: 'unknown', uv: 66, fill: '#ffc658'}
];
const legendDummy = [
  {id: 'uv', name: 'UV'}, 
];

class RadialRechart extends React.Component {
  constructor(props) {
    super(props);
    this.state={} 
  }

	render () {
    const { data, legend, width, height, mode, fill } = this.props

  	return (
      <div>
        <div style={{ width: width || 'auto', height: height || 250, margin: '1em auto' }}>
          <ResponsiveContainer>
            <RadialBarChart innerRadius="110%" outerRadius="0%" data={data || dataDummy} startAngle={360} endAngle={0}>
              {
                (legend || legendDummy).map((value, i) => <RadialBar key={i} minAngle={95} label={{ fill: '#666', position: 'insideStart' }} background clockWise={true} dataKey={value.id} />)
              }
              <Legend iconSize={10} />
              <Tooltip />
            </RadialBarChart>
          </ResponsiveContainer>
      </div>
    </div>
    );
  }
}

export default RadialRechart;
