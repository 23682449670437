import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Label } from 'semantic-ui-react'


import { format, parse } from "date-fns"

import DismissableMessage from '../common/DismissableMessage'
import { businessOptions, }  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm, formValueSelector } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField, NumberField, DatePickerField, TextAreaField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email, phoneNumberID } from '../validation/validation'

import { connect } from "react-redux"
import { resetForm, checkToken, setOnboarding, echoWhatsapp} from "../../actions/auth/authAction"
import { browseCampaign} from "../../actions/stream/campaignAction"

//CONNECT REDUX STORE
const selector = formValueSelector('OnboardingForm')
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
    campaignStore: state.campaignStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),
    setOnboarding:(objParam) => dispatch(setOnboarding(objParam)),
    echoWhatsapp:(objParam) => dispatch(echoWhatsapp(objParam)),

    resetForm:(objParam) => dispatch(resetForm(objParam)),

    browseCampaign:(objParam) => dispatch(browseCampaign(objParam)),
  }
}

class OnboardingForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      step: 0,
    }
  }

  componentDidMount(){
    this.props.resetForm('setOnboarding')
    // this.props.checkToken();
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= this.props.authStore.setOnboarding!=undefined ? this.props.authStore.setOnboarding : {}

    if (progress==1 && code=='00') {
      setTimeout(()=>{
        this.setState({
          step: 5,
        });
    }, 1000)
    }
  }

  componentWillUnmount() {
    const { search, paging }= (this.props.campaignStore.browseCampaign!==undefined ? this.props.campaignStore.browseCampaign : {})
    this.props.browseCampaign({search: {eventDate: format(new Date(), 'yyyy-MM-dd'), ...search}, paging: paging})
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  submitForm(values) {
    const { mode } = this.props
    const { data }= this.props.authStore.checkToken!=undefined ? this.props.authStore.checkToken : {}
    
    // console.log('!!! SUBMIT: ', this.state.step, values)
    if (data.packageId=='Personal') {
      if (this.state.step<=4) {
        if (this.state.step==4) {
          this.props.setOnboarding({...values,
            mobile: this.props.mobile.substr(0,1)=='0' ? '62' + this.props.mobile.substr(1) : this.props.mobile,
          })
        }
  
        this.setState({
          step: this.state.step+1,
        })
      }
    } else if (data.packageId=='Enterprise') {
      if (this.state.step<=2) {
        if (this.state.step==2) {
          this.props.setOnboarding({...values,
            mobile: this.props.mobile.substr(0,1)=='0' ? '62' + this.props.mobile.substr(1) : this.props.mobile,
          })

          this.setState({
            step: 5,
          })
        } else {
          this.setState({
            step: this.state.step+1,
          })
        }
      }
    }
  }

  clickStep(v) {
    this.setState({
      step: v
    })
  }

  closeModal() {
    this.props.closeModal();
    // window.location.reload();
  }

  stepLogo() {
    return(
      <div>
        <div>
          <div style={{minHeight: '325px'}}>
            <div style={{textAlign: 'center'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  Please, define <b>logo</b> for your company !
                  <Header.Subheader>
                    Share your image logo will make more interactive for your customers, and getting more views.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <Divider style={{margin: '2em 0 2em 0'}}/>
            <Form.Group widths='equal' >
              <Form.Field>
                <Field name='avatar' component={ImageUploadField}
                width={300} 
                height={300} 
                aspectRation={3/3} 
                />
              </Form.Field>
            </Form.Group>
          </div>
        </div>
        <div style={{textAlign: 'right', marginTop: '2em'}}>
          <Button color='blue' size='large'> Next</Button>
        </div>
      </div>
    )
  }
  stepCompany(v) {
    return(
      <div>
        <div>
          <div style={{minHeight: '325px'}}>
            <div style={{textAlign: 'center'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  Describe your <b>business profile</b>  ?
                  <Header.Subheader>
                    Your profile is required for <b>matching up</b> assignment and requirement.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <Divider style={{margin: '2em 0'}}/>
            <Form.Group widths='16'>
              <Field name='company' component={InputField}
              width={16}
              label='Company'
              validate={[required]}
              placeholder='Company / Institution' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='brand' component={InputField}
              fluid
              width={8}
              label='Brand / Code'
              validate={[required]}
              placeholder='Brand / Code' />
              <Field name='business' component={SelectField}
              fluid
              width={8}
              options={businessOptions}
              label='Business Stream'
              // validate={[required]}
              placeholder='Business Stream' />
            </Form.Group>

            <Divider hidden />
            <Form.Group widths='equal'>
              <Field name='address' component={InputField}
              fluid
              label='Address'
              validate={[required]}
              placeholder='Address' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='city' component={InputField}
              width={8}
              validate={[required]}
              placeholder='City' />
              <Field name='province' component={InputField}
              width={8}
              validate={[required]}
              placeholder='Province' />
              {/* <Field name='country' component={InputField}
              width={4}
              placeholder='Country' /> */}
            </Form.Group>
          </div>
        </div>
        <div style={{display: 'flex', marginTop: '2em'}}>
          <div style={{flex: 1}}>
            <Button disabled={!(v.progress!=1 || v.code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this, 0)}> Back</Button>
          </div>
          <div>
          <Button color='blue' size='large'> Next</Button>
          </div>
        </div>
      </div>
    )
  }
  stepPersonal(v) {
    const { data }= this.props.authStore.checkToken!=undefined ? this.props.authStore.checkToken : {}

    return(
      <div>
        <div>
          <div style={{minHeight: '325px'}}>
            <div style={{textAlign: 'center'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  Personal information <b>admin's contact</b>  ?
                  <Header.Subheader>
                    Person in charge as <b>admin</b> for your platform.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <Divider style={{margin: '2em 0'}}/>
            {/* <Form.Group widths='16'>
              <Field name='monthlyRate' component={NumberField}
              width={8}
              label='Expected Salary'
              validate={[required]}
              placeholder='Rp. / Month' />
            </Form.Group> */}
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              width={16}
              label='Name'
              validate={[required]}
              placeholder='Full Name' />
              {/* <Field name='quote' component={InputField}
              label='Tagline'
              placeholder='Quote / Tagline' /> */}
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='mobile' component={InputField}
              readOnly
              label='Mobile'
              validate={[required, phoneNumberID]}
              icon='square outline'
              placeholder='Handphone' />
              <Field name='email' component={InputField}
              readOnly
              validate={[email]}
              label='Email'
              validate={[required]}
              icon='envelope outline'
              placeholder='Email' />
            </Form.Group>
          </div>
        </div>
        <div style={{display: 'flex', marginTop: '2em'}}>
          <div style={{flex: 1}}>
            <Button disabled={!(v.progress!=1 || v.code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this, 1)}> Back</Button>
          </div>
          {data && data.packageId=='Personal' && <div>
            <Button color='blue' size='large'> Next</Button>
          </div>}
          {data && data.packageId=='Enterprise' && <div>
            <Button disabled={!(v.progress!=1 || v.code!="00")} color='red' size='large' id='btnOnboarding' icon={v.progress==0 ? true : false}> {v.progress==0 ? <Icon name='notch circle' loading /> : ''} Save</Button>
          </div>}
        </div>
      </div>
    )
  }
  stepExpectation(v) {
    return(
      <div>
        <div >
          <div style={{minHeight: '325px'}}>
            <div style={{textAlign: 'center'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  How <b>your market</b> and <b>revenue</b> would be? 
                  <Header.Subheader>
                    Set your goals for <b>next year</b> business performance.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <Divider style={{margin: '2em 0'}}/>
            <Header as='h4'>
              Current Performance
            </Header>
            <Form.Group widths='equal' style={{margin: '1em -.5em'}}>
              <Field name='businessRevenue' component={InputField}
              // validate={[required]}
              label='Business Revenue'
              placeholder='Business Revenue' />
              <Field name='businessMarket' component={SelectField}
              // validate={[required]}
              // options={businessOptions}
              label='Market Share'
              placeholder='Market Share' />
            </Form.Group>
            <Header as='h4'>
              Expectation / Target Performance
            </Header>
            <Form.Group widths='equal' style={{margin: '1em -.5em'}}>
              <Field name='businessRevenue' component={InputField}
              // validate={[required]}
              label='Business Revenue'
              placeholder='Business Revenue' />
              <Field name='businessMarket' component={SelectField}
              // validate={[required]}
              // options={businessOptions}
              label='Market Share'
              placeholder='Market Share' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='businessTagline' component={TextAreaField}
              rows={5}
              label='Define your goals tagline' />
            </Form.Group>
          </div>
        </div>
        <div style={{display: 'flex', marginTop: '2em'}}>
          <div style={{flex: 1}}>
            <Button disabled={!(v.progress!=1 || v.code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this,2)}> Back</Button>
          </div>
          <div>
            {/* <Button disabled={!(v.progress!=1 || v.code!="00")} color='red' size='large' id='btnOnboarding' icon={v.progress==0 ? true : false}> {v.progress==0 ? <Icon name='notch circle' loading /> : ''} Save</Button> */}
            <Button color='blue' size='large'> Next</Button>
          </div>
        </div>
      </div>
    )
  }
  stepInstallation(v) {
    return(
      <div>
        <div >
          <div style={{minHeight: '325px'}}>
            <div style={{textAlign: 'center'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  Install whatsapp broadcast <b>environment</b> !
                  <Header.Subheader>
                    Install required components and <b>configurations</b> before going commercial.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <Divider style={{margin: '2em 0'}}/>
            {/* <div style={{display: 'flex', margin: '0 0 1.5em 0'}}>
              <div style={{flex: 1}}>
                <Header as='h4'>
                  <Icon name='chrome' />
                  <Header.Content>
                    Use Chrome Browser Only!
                    <Header.Subheader>It's only support for chrome browser only.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{width: '5em'}}>
              </div>
            </div> */}
            <div style={{display: 'flex', margin: '0 0 1.5em 0'}}>
              <div style={{flex: 1}}>
                <Header as='h4'>
                  <Icon name='chrome' />
                  <Header.Content>
                    Install Chrome Extension
                    <Header.Subheader>Download and install chrome extension from chromestore.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{width: '5em'}}>
                {/* <Form.Group widths='equal'>
                  <Field name='hasExtension' component={SelectField}
                  // validate={[required]}
                  // label='Business Revenue' 
                  options= {yesnoOptions()} />
                </Form.Group> */}
                <Button onClick={this.openUrl.bind(this, 'https://chrome.google.com/webstore/detail/stylish-custom-themes-for/fjnbnpbmkenffdnngjfgmeleoegfcffe?hl=id')} color='orange' type='button' fluid size='large'>Get It</Button>
              </div>
            </div>

            <Divider />
            <div style={{display: 'flex', margin: '0 0 1.5em 0'}}>
              <div style={{flex: 1}}>
                <Header as='h4'>
                  <Icon name='setting' />
                  <Header.Content>
                    Configure Chrome Extension
                    <Header.Subheader>Configure credential for whatsapp sender.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{width: '5em'}}>
                {/* <Form.Group widths='equal'>
                  <Field name='hasExtension' component={SelectField}
                  // validate={[required]}
                  // label='Business Revenue' 
                  options= {yesnoOptions()} />
                </Form.Group> */}
                <Button onClick={this.openUrl.bind(this, 'chrome-extension://koocibpkcbbkjdolabmdmdfcahibpmbi/options.html')} color='teal' type='button' fluid size='large'>Config</Button>
              </div>
            </div>
            
            <Divider />
            <div style={{display: 'flex', margin: '0 0 1.5em 0'}}>
              <div style={{flex: 1}}>
                <Header as='h4'>
                  <Icon name='whatsapp' />
                  <Header.Content>
                    Open Whatsapp Web
                    <Header.Subheader>Use whatsapp web and makesure your number is connected.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{width: '5em'}}>
                {/* <Form.Group widths='equal'>
                  <Field name='hasWhatsapp' component={SelectField}
                  // validate={[required]}
                  // label='Business Revenue' 
                  options= {yesnoOptions()} />
                </Form.Group> */}
                <Button onClick={this.openUrl.bind(this, 'https://web.whatsapp.com/')} color='green' type='button' fluid size='large'>Open</Button>
              </div>
            </div>

            <Divider style={{margin: '2em 0'}}/>
            <Header as='h5'>
              <Header.Content>
                Data environment setting is reconfigurable through setting menu after you saved, don't worry it's manageable after all. 
              </Header.Content>
            </Header>
          </div>
        </div>
        <div style={{display: 'flex', marginTop: '2em'}}>
          <div style={{flex: 1}}>
            <Button disabled={!(v.progress!=1 || v.code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this,2)}> Back</Button>
          </div>
          <div>
            <Button disabled={!(v.progress!=1 || v.code!="00")} color='blue' size='large' id='btnOnboarding' icon={v.progress==0 ? true : false}> {v.progress==0 ? <Icon name='notch circle' loading /> : ''} Next</Button>
          </div>
        </div>
      </div>
    )
  }
  stepEchoing(v) {
    return(
      <div>
        <div >
          <div style={{minHeight: '325px'}}>
            <div style={{textAlign: 'center'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  Check if configuration <b>works</b> !
                  <Header.Subheader>
                    Makesure you will receive <b>echoing</b> message form your own number.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <Divider style={{margin: '2em 0'}}/>
            <div style={{display: 'flex', margin: '0 0 1.5em 0'}}>
              <div style={{flex: 1}}>
                <Header as='h4'>
                  <Icon name='plug' />
                  <Header.Content>
                    Check if Yor Whatsapp is Connected!
                    <Header.Subheader>Makesure whether your whatsapp mobile number is linked to application.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{width: '5em'}}>
                {/* <Form.Group widths='equal'>
                  <Field name='hasConnected' component={SelectField}
                  // validate={[required]}
                  // label='Business Revenue' 
                  options= {yesnoOptions()} />
                </Form.Group> */}
                <Button onClick={this.echoWhatsapp.bind(this)} color='green' type='button' fluid size='large'>Echo</Button>
              </div>
            </div>

            <Divider style={{margin: '2em 0'}}/>
            <div style={{display: 'flex', margin: '0 0 1.5em 0'}}>
              <div>
                <Label color='grey' style={{padding: '5em 2em'}}>ECHO MESSAGE !</Label>
              </div>
              <div style={{flex: 1, textAlign: 'center', paddingTop: '3em'}}>
                <Icon name='arrow right' style={{fontSize: '4em'}}/>
              </div>
              <div>
                <Label color='blue' style={{padding: '5em 2em'}}>ECHO MESSAGE !</Label>
              </div>
            </div>

            <Divider style={{margin: '2em 0'}}/>
            <Header as='h5'>
              <Header.Content>
                Echoing message will send "ECHO MESSAGE !" from and to your Whatsapp sender number. If your whatsapp receive message from your own number, your configuration is succeed. Otherwise click back to reconfigure.
              </Header.Content>
            </Header>
          </div>
        </div>
        <div style={{display: 'flex', marginTop: '2em'}}>
          <div style={{flex: 1}}>
            <Button disabled={!(v.progress!=1 || v.code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this,3)}> Back</Button>
          </div>
          <div>
            <Button disabled={!(v.progress!=1 || v.code!="00")} color='red' size='large' id='btnOnboarding' icon={v.progress==0 ? true : false}> {v.progress==0 ? <Icon name='notch circle' loading /> : ''} Save</Button>
          </div>
        </div>
      </div>
    )
  }
  stepAfirmation(v) {
    return(
      <div>
        <div>
          <div style={{minHeight: '325px'}}>
            <div style={{textAlign: 'center', paddingTop: '100px'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  Business <b>profile</b> and <b>platform</b> is prepared. 
                  <Header.Subheader>
                    It would refresh page, your content distribution channel will be established.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>
          </div>
        </div>
        {v.dialog && <div style={{marginTop: '2em', textAlign: 'center'}}>
          <Button type='button' color='blue' size='large' onClick={this.closeModal.bind(this)}> Close !</Button>
        </div>}
      </div>
    )
  }

  openUrl(v) {
    window.open(
      v, "_blank");
  }
  echoWhatsapp(){
    this.props.echoWhatsapp({
      whatsapp: this.props.mobile.substr(0,1)=='0' ? '62' + this.props.mobile.substr(1) : this.props.mobile,
      content: 'ECHO MESSAGE !',
    })
  }

  render() {
    const { step }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode, dialog } = this.props
    const { progress, code, text }= this.props.authStore.setOnboarding!=undefined ? this.props.authStore.setOnboarding : {}
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
            {step==0 &&
              this.stepLogo()
            }
            {step==1 &&
              this.stepCompany({progress: progress, code: code})
            }
            {step==2 &&
              this.stepPersonal({progress: progress, code: code})
            }
            {/* {step==3 &&
              this.stepExpectation({progress: progress, code: code})
            } */}
            {step==3 &&
              this.stepInstallation({progress: progress, code: code})
            }
            {step==4 &&
              this.stepEchoing({progress: progress, code: code})
            }
            {step==5 &&
              this.stepAfirmation({dialog: dialog})
            }
          </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.authStore.checkToken!=undefined && state.authStore.checkToken.progress==1 ? state.authStore.checkToken.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      mobile: selector(state, 'mobile'),
    }),
    { load: checkToken }
  )
  (reduxForm({
    form: 'OnboardingForm',	// a unique identifier for this form
  })(OnboardingForm)
  )
)
