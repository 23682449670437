import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
} from 'semantic-ui-react'

import numeral from "numeral"
import { parse, format } from "date-fns"

import { connect } from "react-redux"
import { browseTunnel } from "../../actions/talk/tunnelAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    tunnelStore: state.tunnelStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseTunnel:(objParam) => dispatch(browseTunnel(objParam)),
  }
};


class TunnelBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.tunnelStore.browseTunnel!==undefined ? this.props.tunnelStore.browseTunnel : {})
    this.props.browseTunnel(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.tunnelStore.browseTunnel!==undefined ? this.props.tunnelStore.browseTunnel : {})
    this.props.browseTunnel({search: {...search}, paging: paging})
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.tunnelStore.browseTunnel!==undefined ? this.props.tunnelStore.browseTunnel : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight-125}}>
        <div style={{flex: '1'}}>
        <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                {/* <Table.HeaderCell></Table.HeaderCell> */}
                <Table.HeaderCell width='6'>
                  <Header as='h5' color='blue'>
                    TUNNEL
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>
                    PARKING STAMP
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>
                    DISPATCH STAMP
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>
                    RELEASE STAMP
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='4'>
                  <Header as='h5' color='blue'>
                    STATUS
                  </Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      {/* <Table.Cell textAlign='center'>
                        <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.tunnelId)}/>
                      </Table.Cell> */}
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.parking} / {item.conference}
                            <Header.Subheader>{item.client || '-'}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.parkingStamp ? format(parse(item.parkingStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss') : ''}
                            <Header.Subheader>{item.parkingStamp ? format(parse(item.parkingStamp, 'yyyy-MM-dd hh:mm:ss', new Date()), 'dd / MMM yyyy') : ''}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.dispatchStamp ? format(parse(item.dispatchStamp, 'yyyy-MM-dd hh:mm:ss', new Date()), 'HH:mm:ss') : ''}
                            <Header.Subheader>{item.dispatchStamp ? format(parse(item.dispatchStamp, 'yyyy-MM-dd hh:mm:ss', new Date()), 'dd / MMM yyyy') : ''}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.releaseStamp ? format(parse(item.releaseStamp, 'yyyy-MM-dd hh:mm:ss', new Date()), 'HH:mm:ss') : ''}
                            <Header.Subheader>{item.releaseStamp ? format(parse(item.releaseStamp, 'yyyy-MM-dd hh:mm:ss', new Date()), 'dd / MMM yyyy') : ''}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.occupied=='1' ? 'Occupied' : 'Available'}
                            <Header.Subheader>Booking Stat: <b>{item.booked=='1' ? 'Booked' : 'Unbooked'}</b></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        
        {paging!=undefined && paging.count>0 &&
            <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
              <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
               
                defaultActivePage={paging.page}
                totalPages={Math.ceil(paging.count/paging.size)}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TunnelBrowse)
