import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetPrepaid(objParam) {
  return {
      type: 'PREPAID_RESET',
      payload: objParam
  }
}

export function getPrepaid(objParam) {
  return {
      type: 'PREPAID_GET',
      payload:
        axios.post(
          urlAPI + 'prepaid/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusPrepaid(objParam) {
  return {
      type: 'PREPAID_STATUS',
      payload:
        axios.post(
          urlAPI + 'prepaid/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browsePrepaid(objParam) {
  return {
      type: 'PREPAID_BROWSE',
      payload:
        axios.post(
          urlAPI + 'prepaid/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createPrepaid(objParam) {
  return {
      type: 'PREPAID_CREATE',
      payload:
        axios.post(
          urlAPI + 'prepaid/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updatePrepaid(objParam) {
  return {
      type: 'PREPAID_UPDATE',
      payload:
        axios.post(
          urlAPI + 'prepaid/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removePrepaid(objParam) {
  return {
      type: 'PREPAID_REMOVE',
      payload:
        axios.post(
          urlAPI + 'prepaid/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
