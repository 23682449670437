import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import TickethandlingBrowse from '../../../libraries/service/TickethandlingBrowse'
import TickethandlingSearch from '../../../libraries/service/TickethandlingSearch'


class TickethandlingBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        handlingId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, handlingId: id, clientId: clientId})
    }

    render() {
      const {mode, handlingId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              <TickethandlingSearch modal mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
              <div>
                {mode==1 && <TickethandlingBrowse modal setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} ticketId={this.props.ticketId} clientId={this.props.clientId} />}
              </div>
            </div>
          </div>
        </Container>
      )
    }
}

export default TickethandlingBody
