import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'
import ModalContainer from '../../libraries/common/ModalContainer';



//TALK - IN-APP VOICE
import TunnelBody from '../ring/TunnelBody';
import MauBody from '../ring/MauBody';

import WidgetBody from '../ring/WidgetBody';


class AdminBodyOfficephone extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }
  
  render() {
    const { mode, module, screenWidth, screenHeight, mini, modal, params }= this.props

    return (
      <div>
        
        {mode==='tunnel' && <TunnelBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
        {mode==='mau' && <MauBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
        
        {mode==='desk phone' && <WidgetBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}

      </div>
    )
  }
}

export default AdminBodyOfficephone