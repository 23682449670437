import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Label } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'
import { responsedOptions, yesnoOptions }  from '../common/StaticMasterData'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, ToggleField, SelectField, TextAreaField, DatePickerField, MaskedField, AudioUploadField, LabelNumberField, LabelSelectField, LabelInputField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetInteractive, getInteractive,updateInteractive, createInteractive } from "../../actions/flow/interactiveAction"
import { listWorkgroup } from "../../actions/halo/masterAction"
import IconToggle from '../common/IconToggle'

//CONNECT REDUX STORE
const selector = formValueSelector('InteractiveForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    interactiveStore: state.interactiveStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),

    resetInteractive:(objParam) => dispatch(resetInteractive(objParam)),
    getInteractive:(objParam) => dispatch(getInteractive(objParam)),
    createInteractive:(objParam) => dispatch(createInteractive(objParam)),
    updateInteractive:(objParam) => dispatch(updateInteractive(objParam)),
  }
}


class NavigationItem extends Component {
  constructor(props) {
    super(props)
    this.state= {
      isScript: true,
    }
  }

  setScript() {
    this.setState({
      isScript: !this.state.isScript
    })
  }

  render() {
    const { isScript }= this.state
    const { screenWidth, item, listWorkgroup }= this.props

    // console.log(item)

    return (
      <Segment attached={true} style={{
        paddingLeft: (item && item.level!=0 && (((item.level.toString().length * (1.2 + 3)) +'em'))) || '1.5em', 
        background : item && item.level!=0 && item.level.toString().length>=1 ?  item.level.toString().length==1 ? '#f7f6f4' : '#ffffff' : '#ffffff'}}>
        {/* {item && item.level}  */}
        {/* {item && item.level.toString().length} {item && ((item.level.toString().length * (1.2 + 3)) +'em')} */}
        <div style={{display: 'flex'}}>
          <Label style={{margin: '1.8em 1.5em .6em 0'}} color={(item && ((item.level==0 && 'grey') || (item.level==1 && 'yellow') || (item.level==2 && 'olive') || (item.level==3 && 'orange') || (item.level==4 && 'teal') || (item.level==5 && 'blue') || (item.level==6 && 'green'))) || 'red'}>
            <div style={{background: '#f5f5f5',  padding: '.7em .5em', borderRadius: '.2em'}}>
              <Button type='button' onClick={this.props.addChildNav.bind(this, item.level)} style={{margin: '0'}} color={item && item.level.toString().length>=3 ? null : 'blue'} disabled={item && item.level.toString().length>=3}>
                <Icon name='plus' style={{margin: '0', cursor: 'pointer'}} />
              </Button>
              <Divider />
              <Button type='button' onClick={this.props.delChildNav.bind(this, item.level)} style={{margin: '0'}} color={item && item.level==0 ? null : 'orange'} disabled={item && item.level==0}>
                <Icon name='close' style={{margin: '0', cursor: 'pointer'}} />
              </Button>
            </div>
          </Label>
          <div style={{flex: '1'}}>
            {item.level}
            <Form.Group widths='16'>
              {/* <Field name={'level_' + item.level} component={InputField}
              width={4}
              label='Level'
              placeholder='Level' /> */}
              <Field name={'shortcut_' + item.level} component={LabelNumberField}
              width={4}
              validate={required}
              label='Dial'
              caption='Menu'
              placeholder='Menu' />
              <Field name={'name_' + item.level} component={InputField}
              width={4}
              validate={required}
              label='Title / Caption'
              placeholder='Menu Title / Caption' />
              <Field name={'responsed_' + item.level} component={SelectField}
              width={4}
              validate={required}
              options={responsedOptions}
              label='Resp. By'
              placeholder='By' />
              <Field name={'prepared_' + item.level} component={SelectField}
                width={4}
                options={yesnoOptions('ivr')}
                label='Re/Generate Speech'
              />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name={'dispatches_' + item.level} component={LabelSelectField}
              multiple
              options={listWorkgroup||[]}
              width={8}
              label='Group'
              caption='Dispatch / Handler'
              placeholder='Dispatches' />
              <Field name={'params_' + item.level} component={InputField}
              width={8}
              label='Params'
              placeholder='Requested Params (Params_1, Params_2, Params_3, ..' />
            </Form.Group>
            {/* <div style={{display: 'flex', marginBottom: '1em'}}>
              <div style={{flex: '1'}}>
                <Header as='h5' color='blue'>
                  <Header.Content>
                    Voice Talent
                    <Header.Subheader>Greeting voice when customer entering navigation.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div>
                <Header as='h5'>
                  <IconToggle active={false} onClick={this.setScript.bind(this)} />
                  <Header.Content>
                    File Upload
                    <Header.Subheader>Upload file or text to speech conversion</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div> */}
            {isScript &&
              <Form.Group widths='16'>
                <Field name={'scriptTalent_' + item.level} component={InputField}
                width={16}
                // label='Script Talent'
                placeholder='Script talent will be converted to audio by text to speech system' />
              </Form.Group>
            }
          </div>
        </div>
      </Segment>
    )
  }
}

class InteractiveForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remarkResult: null,
      navigations: null,
      // {
      //   0: {
      //     level: 0
      //   },
      //   1: {
      //     level: 1
      //   },
      //   10: {
      //     level: 10
      //   },
      //   100: {
      //     level: 100
      //   },
      //   11: {
      //     level: 11
      //   },
      //   110: {
      //     level: 110
      //   },
      //   2: {
      //     level: 2
      //   },
      //   20: {
      //     level: 20
      //   },
      //   200: {
      //     level: 200
      //   },
      //   3: {
      //     level: 3
      //   },
      //   4: {
      //     level: 4
      //   }
      // }
    }
  }

  componentDidMount(){
    this.props.resetInteractive(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getInteractive({interactiveId: this.props.interactiveId})
    } else {
      this.setState({
        navigations: [
          {
            level: 0,
          }
        ]
      })
    }

    this.props.listWorkgroup({search: {clientId: this.props.clientId!=undefined ? this.props.clientId : null}})
  }

  componentDidUpdate() {
    const { navigations } = this.state
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.interactiveStore.updateInteractive!=undefined ? this.props.interactiveStore.updateInteractive : {}) : (this.props.interactiveStore.createInteractive!=undefined ? this.props.interactiveStore.createInteractive : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }

    const { data }= this.props.interactiveStore.getInteractive!=undefined ? this.props.interactiveStore.getInteractive : {}
    if (!navigations && data) {
      this.setState({
        navigations: data.navigations || [
          {
            level: 0,
          }
        ]
      })
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    const { navigations } = this.state
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateInteractive({...values, navigations: navigations}) : this.props.createInteractive({...values, navigations: navigations})
    
  }

  addChildNav(parentLevel) {
    const { navigations }= this.state

    if (parentLevel>0) {
      let i= (parentLevel * 10) + 1;
      while (i<((parentLevel + 1) * 10)) {
        if (!navigations[i]) {
          navigations[i]= {
            level: i,
          }
          break;
        }
        i++;
      }
    } else {
      let i= 1;
      while (i<((parentLevel + 1) * 10)) {
        if (!navigations[i]) {
          navigations[i]= {
            level: i,
          }
          break;
        }
        i++;
      }
    }

    this.setState({
      navigations: navigations
    })
  }

  delChildNav(level) {
    const { navigations }= this.state
    if (level>0) {
      navigations[level]= undefined
    }
    this.setState({
      navigations: navigations
    })
  }

  render() {
    const { navigations }= this.state
    const { screenWidth, error, handleSubmit, pristine, reset, submitting, mode } = this.props
    
    const { data }= (this.props.interactiveStore.getInteractive!=undefined && this.props.interactiveStore.getInteractive.progress=='1' ? this.props.interactiveStore.getInteractive : {})
    const { progress, code, text }= (mode==2 ? (this.props.interactiveStore.updateInteractive!=undefined ? this.props.interactiveStore.updateInteractive : {}) : (this.props.interactiveStore.createInteractive!=undefined ? this.props.interactiveStore.createInteractive : {}))

    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    
    const navItems= []
    
    let i= 0;
    while (i<=9) {
      if (navigations && navigations[i] && i!=0) {
        navItems.push(navigations[i]);
        let j= (i * 10) + 1;
        while (j<((i+1) * 10)) {
          if (navigations[j]) {
            navItems.push(navigations[j]);
            let k= (j * 10) + 1;
            while (k<((j+1) * 10)) {
              if (navigations[k]) {
                navItems.push(navigations[k]);
              }
              k++;
            }
          }
          j++;
        }
      } else if (navigations && navigations[i]) {
        navItems.push(navigations[i]);
      } else if (i==0) {
        navItems.push({
          level: 0,
        });
      }
      i++;
    }

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          {/* <Segment>
            <Form.Group widths='16'>
              <Form.Field width={8} />
              <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' />
            </Form.Group>
          </Segment> */}
          
          <Segment>
            <Form.Group widths='16'>
              <Field name='isEnabled' component={CheckboxField}
              width={8}
              toggle
              // caption='Enabled / Disabled'
              label='Enable Now !'
              placeholder='Enable/disabled' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              fluid
              width={4}
              label='Name'
              placeholder='Name' />
              <Field name='descript' component={InputField}
              width={12}
              label='Descript'
              placeholder='Descript' />
            </Form.Group>
          </Segment>

          <Segment attached='top'>
            <Header as='h5'>
              <Icon name='phone volume' style={{fontSize: '1em'}}/>
              <Header.Content>Greeting Voice</Header.Content>
            </Header>
            
            <Divider />
            <Form.Group widths='equal'>
              <Field name={'scriptBackPrevious'} component={LabelInputField}
              label='Script'
              caption='Back to Previous Menu (press *)'
              placeholder='Script talent will be converted to audio by text to speech system' />
              <Field name={'scriptRepeatCurrent'} component={LabelInputField}
              label='Script'
              caption='Repeat for Current Menu (press 0)'
              placeholder='Script talent will be converted to audio by text to speech system' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name={'scriptBusy'} component={LabelInputField}
              label='Script'
              caption='Agent is Busy / Not Available'
              placeholder='Script talent will be converted to audio by text to speech system' />
              <Field name={'scriptOffline'} component={LabelInputField}
              label='Script'
              caption='Agent is Offline'
              placeholder='Script talent will be converted to audio by text to speech system' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name={'scriptThankyou'} component={LabelInputField}
              label='Script'
              caption='Thank You / Good Bye'
              placeholder='Script talent will be converted to audio by text to speech system' />
              <Field name={'scriptProblem'} component={LabelInputField}
              label='Script'
              caption='System has Problem'
              placeholder='Script talent will be converted to audio by text to speech system' />
            </Form.Group>
          </Segment>

          <Segment attached='top'>
            <Header as='h5'>
              <Icon name='ellipsis vertical' style={{fontSize: '1em'}}/>
              <Header.Content>IVR Menu / Navigations</Header.Content>
            </Header>
          </Segment>
          {
            navItems.map((item, idx)=>{
                return(<NavigationItem key={idx} item={item} addChildNav={this.addChildNav.bind(this)} delChildNav={this.delChildNav.bind(this)} listWorkgroup={listWorkgroup||[]} screenWidth={screenWidth}/>)
            })
          }
          
          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Interactive' : 'Insert Interactive') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.interactiveStore.getInteractive!=undefined && state.interactiveStore.getInteractive.progress==1 ? state.interactiveStore.getInteractive.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
      navigations: selector(state, 'navigations'),
    }),
    { load: getInteractive }
  )
  (reduxForm({
    form: 'InteractiveForm',	// a unique identifier for this form
  })(InteractiveForm)
  )
)
