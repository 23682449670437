import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, DatePickerField, LabelSelectField } from '../../validation/componentrsui'
import { conversationOptions } from "../../../libraries/common/StaticMasterData"
import { required, number, minValue, email } from '../../validation/validation'

import { connect } from "react-redux"
import { checkToken} from "../../../actions/auth/authAction"
import { recentContact } from "../../../actions/flow/omnichannelAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omnichannelStore: state.omnichannelStore,
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    recentContact:(objParam) => dispatch(recentContact(objParam)),

    checkToken:(objParam) => dispatch(checkToken(objParam)),
  }
}


class InteractionSearch extends Component {
  constructor(props) {
    super(props)
    this.state= {
      channels: [], //Voice, InApp, Chat, SMS, WA, Email, Twitter, Facebook, Instagram
      searchMode: true,
      lastChange: null,
    }
  }

  submitForm(values) {
    const { search }= this.state
    this.props.recentContact({search: {...values, ...search}})
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeDate(key, v) {
    const { search }= (this.props.omnichannelStore.recentContact!==undefined ? this.props.omnichannelStore.recentContact : {})
    var values= []
    values[key]= v
    this.props.recentContact({search: {...search, ...values}});
    this.props.setMode(1, null);
  }

  onChangeInput(e, v) {
    const { search }= (this.props.omnichannelStore.recentContact!==undefined ? this.props.omnichannelStore.recentContact : {})
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-this.state.lastChange>=500) {
        this.props.recentContact({search: {...search, keyword: v}});
        this.props.setMode(1, null);
      }
    }, 500)
  }

  onChangeSelect(k, e, v) {
    const { search }= (this.props.omnichannelStore.recentContact!==undefined ? this.props.omnichannelStore.recentContact : {})
    if (k=='status') {
      this.props.recentContact({search: {...search, status: v}})
    } else {
      this.props.recentContact({search: {...search, channelType: v}})
    }
  }

  onChangeChannels(v) {
    const { search }= (this.props.omnichannelStore.recentContact!==undefined ? this.props.omnichannelStore.recentContact : {})
    const channels= this.state.channels || [];
    const i = channels.indexOf(v);
    if (i!== -1) {
      channels.splice(i, 1);
    } else {
      channels.push(v);
    }
    this.setState({
      lastChange: new Date().getTime(),
      channels: channels
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-this.state.lastChange>=500) {
        this.props.recentContact({search: {...search, channels: channels}})
        this.props.setMode(1, null);
      }
    }, 1000)
  }
  
  render() {
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode }=this.props
    const token= this.props.authStore.checkToken && this.props.authStore.checkToken.data ? this.props.authStore.checkToken.data : {};
    
    return (
      <div style={{position: 'static', width: '100%', zIndex: '1000'}}>
        <div style={{padding: '1.4em', background: 'rgba(230, 230, 230, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
          <div>
            <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
              <div style={{display: (token.svcChannels && token.svcChannels.length>4) ? 'block' : 'flex'}}>
                <div style={{flex: '1', paddingRight: (token.svcChannels && token.svcChannels.length>4) ? '0' : '1em'}}>
                  <Form.Group widths='16'>
                    <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)}
                    width={10}
                    placeholder= 'Search conversation ...' />
                    <Field name='status' component={LabelSelectField} onChange= {this.onChangeSelect.bind(this, 'status')}
                    width={6}
                    options={conversationOptions()}
                    label='Status'
                    placeholder= 'Conversation' />
                  </Form.Group>
                </div>
                <div>
                <Menu compact size='small'>
                  {token.isVoice && <Menu.Item as='a' active={(this.state.channels||[]).includes('Voice')} onClick={this.onChangeChannels.bind(this, 'Voice')} style={{color: ((this.state.channels||[]).includes('Voice') && '#27e') || '#555'}}>
                    <Icon name='call square' /> Telco Call
                    {/* <Label color='red' floating>22</Label> */}
                  </Menu.Item>}
                  {token.isChat && <Menu.Item as='a' active={(this.state.channels||[]).includes('Chat')} onClick={this.onChangeChannels.bind(this, 'Chat')} style={{color: ((this.state.channels||[]).includes('Chat') && '#27e') || '#555'}}>
                    <Icon name='comment alternate outline' /> Live Chat
                    {/* <Label color='red' floating>22</Label> */}
                  </Menu.Item>}
                  {token.isVoice && <Menu.Item as='a' active={(this.state.channels||[]).includes('InApp')} onClick={this.onChangeChannels.bind(this, 'InApp')} style={{color: ((this.state.channels||[]).includes('InApp') && '#27e') || '#555'}}>
                    <Icon name='tablet alternate' /> In-App Call
                    {/* <Label color='red' floating>22</Label> */}
                  </Menu.Item>}
                  {token.isSMS && <Menu.Item as='a' active={(this.state.channels||[]).includes('SMS')} onClick={this.onChangeChannels.bind(this, 'SMS')} style={{color: ((this.state.channels||[]).includes('SMS') && '#27e') || '#555'}}>
                    <Icon name='sticky note outline' /> SMS
                    {/* <Label color='red' floating>22</Label> */}
                  </Menu.Item>}
                  {token.isEmail && <Menu.Item as='a' active={(this.state.channels||[]).includes('Email')} onClick={this.onChangeChannels.bind(this, 'Email')} style={{color: ((this.state.channels||[]).includes('Email') && '#27e') || '#555'}}>
                    <Icon name='envelope open outline' /> Email
                    {/* <Label color='red' floating>22</Label> */}
                  </Menu.Item>}
                  {token.isWhatsapp && <Menu.Item as='a' active={(this.state.channels||[]).includes('WA')} onClick={this.onChangeChannels.bind(this, 'WA')} style={{color: ((this.state.channels||[]).includes('WA') && '#27e') || '#555'}}>
                    <Icon name='whatsapp' /> Whatsapp
                    {/* <Label color='red' floating>22</Label> */}
                  </Menu.Item>}
                  {token.isTwitter && <Menu.Item as='a' active={(this.state.channels||[]).includes('Twitter')} onClick={this.onChangeChannels.bind(this, 'Twitter')} style={{color: ((this.state.channels||[]).includes('Twitter') && '#27e') || '#555'}}>
                    <Icon name='twitter' /> Twitter
                    {/* <Label color='red' floating>22</Label> */}
                  </Menu.Item>}
                  {token.isFacebook && <Menu.Item as='a' active={(this.state.channels||[]).includes('Facebook')} onClick={this.onChangeChannels.bind(this, 'Facebook')} style={{color: ((this.state.channels||[]).includes('Facebook') && '#27e') || '#555'}}>
                    <Icon name='facebook f' /> Facebook
                    {/* <Label color='red' floating>22</Label> */}
                  </Menu.Item>}
                  {token.isInstagram && <Menu.Item as='a' active={(this.state.channels||[]).includes('Instagram')} onClick={this.onChangeChannels.bind(this, 'Instagram')} style={{color: ((this.state.channels||[]).includes('Instagram') && '#27e') || '#555'}}>
                    <Icon name='instagram' /> Instagram
                    {/* <Label color='red' floating>22</Label> */}
                  </Menu.Item>}
                </Menu>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.omnichannelStore.recentContact!=undefined && state.omnichannelStore.recentContact.progress==1 ? state.omnichannelStore.recentContact.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'InteractionSearch',	// a unique identifier for this form
  })(InteractionSearch)
  )
)