import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react'

import DismissableMessage from '../../common/DismissableMessage'
import { resultOptions, timeOptions }  from '../../common/StaticMasterData'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField } from '../../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../../validation/validation'

import { connect } from "react-redux"
import { resetVoice, getVoice,updateVoice, createVoice } from "../../../actions/stream/voiceAction"
import { listRemark, listProduct } from "../../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('VoiceForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    voiceStore: state.voiceStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listRemark:(objParam) => dispatch(listRemark(objParam)),
    listProduct:(objParam) => dispatch(listProduct(objParam)),

    resetVoice:(objParam) => dispatch(resetVoice(objParam)),
    getVoice:(objParam) => dispatch(getVoice(objParam)),
    createVoice:(objParam) => dispatch(createVoice(objParam)),
    updateVoice:(objParam) => dispatch(updateVoice(objParam)),
  }
}

class VoiceForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remarkResult: null,
    }
  }

  componentDidMount(){
    this.props.resetVoice(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getVoice({voiceId: this.props.voiceId})
    }

    this.props.listProduct()
    this.props.listRemark({clientId: this.props.clientId, campaignId: this.props.campaignId})
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.voiceStore.updateVoice!=undefined ? this.props.voiceStore.updateVoice : {}) : (this.props.voiceStore.createVoice!=undefined ? this.props.voiceStore.createVoice : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateVoice({...values}) : this.props.createVoice({...values})
    
  }

  onChangeResult(v) {
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})

    this.props.dispatch(change('VoiceForm', 'progress', listRemark.progress[v]));
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    
    const { data }= (this.props.voiceStore.getVoice!=undefined && this.props.voiceStore.getVoice.progress=='1' ? this.props.voiceStore.getVoice : {})
    const { progress, code, text }= (mode==2 ? (this.props.voiceStore.updateVoice!=undefined ? this.props.voiceStore.updateVoice : {}) : (this.props.voiceStore.createVoice!=undefined ? this.props.voiceStore.createVoice : {}))

    const listProduct= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          {/* <Segment>
            <Form.Group widths='16'>
              <Form.Field width={8} />
              <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' />
            </Form.Group>
          </Segment> */}
          {this.props.campaignType=='Collection' &&
          <Segment>
            <Header as='h5'>
              <Icon name='edit outline' style={{fontSize: '1em'}}/>
              <Header.Content>Customer Remarks</Header.Content>
            </Header>
            {/* <Form.Group widths='16'>
              <Form.Field width={8} />
              <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' />
            </Form.Group> */}
            <Form.Group widths='16'>
              <Field name='remark' component={SelectField} onChange={this.onChangeResult.bind(this)}
              width={4}
              options={listRemark.options||[]}
              label= 'Result'
              placeholder='Collection Result' />
              {this.props.remark=='Rescheduled' && <>
              <Field name='appointmentDate' component={DatePickerField}
              width={4}
              label= 'Appointment Date'
              placeholder='Appointment Date' />
              <Field name='appointmentSession' component={SelectField}
              options={timeOptions||[]}
              width={4}
              label= 'Appointment Time'
              placeholder='Appointment Time' />
              </>}
              {this.props.remark=='Promised' &&
              <Field name='promisedDate' component={DatePickerField}
              width={4}
              label= 'Promised Date'
              placeholder='Promised Date' />}
              {this.props.remark=='Paid' &&
              <Field name='paidDate' component={DatePickerField}
              width={4}
              label= 'Payment Date'
              placeholder='Payment Date' /> }
            </Form.Group>
            <Form.Group widths='16'>
            <Field name='reason' component={InputField}
              width={16}
              label= 'Reason'
              placeholder='Remarks Note / Reason' />  
            </Form.Group>
          </Segment>}
          {this.props.campaignType=='Restructuring' &&
          <Segment>
            <Header as='h5'>
              <Icon name='edit outline' style={{fontSize: '1em'}}/>
              <Header.Content>Customer Remarks</Header.Content>
            </Header>
            {/* <Form.Group widths='16'>
              <Form.Field width={8} />
              <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' />
            </Form.Group> */}
            <Form.Group widths='16'>
              <Field name='remark' component={SelectField} onChange={this.onChangeResult.bind(this)}
              width={4}
              options={listRemark.options||[]}
              label= 'Result'
              placeholder='Restructuring Result' />
              {this.props.remark=='Rescheduled' && <>
              <Field name='appointmentDate' component={DatePickerField}
              width={4}
              label= 'Appointment Date'
              placeholder='Appointment Date' />
              <Field name='appointmentSession' component={SelectField}
              options={timeOptions||[]}
              width={4}
              label= 'Appointment Time'
              placeholder='Appointment Time' />
              </>}
              {this.props.remark=='Restructured' && <>
              <Field name='adjustmentAmount' component={NumberField}
              width={4}
              label= 'Adjustment Amount'
              placeholder='Adjustment Amount' />
              <Field name='adjustmentDuration' component={NumberField}
              width={4}
              label= 'Adjustment Duration'
              placeholder='Adjustment Duration' /></>}
            </Form.Group>
            <Form.Group widths='16'>
            <Field name='reason' component={InputField}
              width={16}
              label= 'Reason'
              placeholder='Remarks Note / Reason' />  
            </Form.Group>
          </Segment>}
          {this.props.campaignType=='Telesales' &&
          <Segment>
            <Header as='h5'>
              <Icon name='edit outline' style={{fontSize: '1em'}}/>
              <Header.Content>Telesales Progress</Header.Content>
            </Header>
            {/* <Form.Group widths='16'>
              <Form.Field width={8} />
              <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' />
            </Form.Group> */}
            <Form.Group widths='16'>
              <Field name='remark' component={SelectField} onChange={this.onChangeResult.bind(this)}
              width={4}
              options={listRemark.options||[]}
              label= 'Result'
              placeholder='Verification Result' />
              {this.props.remark=='Rescheduled' && <>
              <Field name='appointmentDate' component={DatePickerField}
              width={4}
              label= 'Appointment Date'
              placeholder='Appointment Date' />
              <Field name='appointmentSession' component={SelectField}
              options={timeOptions||[]}
              width={4}
              label= 'Appointment Time'
              placeholder='Appointment Time' />
              </>}
              {this.props.remark!='Rescheduled' && <>
              <Field name='productId' component={SelectField}
              width={8}
              options={listProduct||[]}
              label='Offered Product / Service'
              placeholder='Offered Product / Service' />
              <Field name='value' component={NumberField}
              prefix='Rp. '
              thousandSeparator=','
              width={4}
              label='Amount / Valuation'
              placeholder='Amount / Valuation' /></>}
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='reason' component={InputField}
              width={16}
              label= 'Reason'
              placeholder='Remarks Note / Reason' />  
            </Form.Group>
          </Segment>}
          {this.props.campaignType=='Verification' &&
          <Segment>
            <Header as='h5'>
              <Icon name='edit outline' style={{fontSize: '1em'}}/>
              <Header.Content>Verification Remarks</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Form.Field width={8} />
              <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' />
            </Form.Group>
            <Form.Group>
              <Field name='remark' component={SelectField} onChange={this.onChangeResult.bind(this)}
              width={4}
              options={listRemark.options||[]}
              label= 'Result'
              placeholder='Verification Result' />
              {this.props.remark=='Rescheduled' && <>
              <Field name='appointmentDate' component={DatePickerField}
              width={4}
              label= 'Appointment Date'
              placeholder='Appointment Date' />
              <Field name='appointmentSession' component={SelectField}
              options={timeOptions||[]}
              width={4}
              label= 'Appointment Time'
              placeholder='Appointment Time' />
              </>}
              {this.props.remark!='Rescheduled' && <>
              <Field name='productId' component={SelectField}
              width={8}
              options={listProduct}
              label='Offered Product / Service'
              placeholder='Offered Product / Service' />
              <Field name='value' component={NumberField}
              prefix='Rp. '
              thousandSeparator=','
              width={4}
              label='Amount / Valuation'
              placeholder='Amount / Valuation' /></>}
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='reason' component={InputField}
              width={16}
              label= 'Reason'
              placeholder='Remarks Note / Reason' />  
            </Form.Group>
          </Segment>}

          {data!=undefined && data.subscriptions!=null &&
          <Segment>
            <Header as='h5'>
              <Icon name='envelope open outline' style={{fontSize: '1em'}}/>
              <Header.Content>Bill Information</Header.Content>
            </Header>
            <div style={{display: 'flex', padding: '.5em 0'}}>
              <div style={{flex: '2', padding: '1em 0'}}>
                <Header as='h2' color='red'>
                  <Icon name='calendar times outline' style={{color: '#aaa'}}/>
                  <Header.Content>
                    <b>{numeral(data.subscriptions.overdueAmt).format('0,0')}</b>
                    <Header.Subheader>Due date {data.subscriptions.dueDate!=null ? format(new Date(data.subscriptions.dueDate), 'dd / MMM, yyyy') : 'No duedate'} / {data.subscriptions.overdueDay || 'Uncertain'} Days</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '3', borderLeft: '1px dashed rgb(112 200 255)', padding: '.5em 1em'}}>
                <Header as='h2' style={{margin: '0'}}>
                  <Icon name='window restore outline' style={{color: '#aaa'}}/>
                  <Header.Content>
                    {data.subscriptions.bucket}
                    <Header.Subheader>{data.subscriptions.product}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
          </Segment>}
          {data!=undefined && data.appointments!=null && 
          <Segment>
            <Header as='h5'>
              <Icon name='bell outline' style={{fontSize: '1em'}}/>
              <Header.Content>Appointment</Header.Content>
            </Header>
            {data.appointments.map((item, i)=>{
              if (i==0) { 
                return(
                  <div style={{display: 'flex', padding: '.5em 0'}}>
                    <div style={{flex: '1', padding: '1em'}}>
                      <Header as='h3' color='red'>
                        <Icon name='calendar check outline' style={{color: '#aaa'}}/>
                        <Header.Content>
                          {format(parse(item.startEvent, 'yyyy-MM-dd', new Date()), 'dd / MMM, yyyy')}
                          <Header.Subheader>{item.session || 'Pagi'} via {item.channelType || 'Voice'}</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>
                    <div style={{flex: '2', borderLeft: '1px dashed rgb(112 200 255)', padding: '.5em 1em'}}>
                      <Header as='h5' style={{margin: '0'}}>
                      <Header.Subheader>TOPIC</Header.Subheader>
                      <i>{item.topic}</i></Header>
                      <Header as='h5' style={{margin: '.5em 0'}}>
                      <Header.Subheader>VENUE</Header.Subheader>
                        <i>{item.venue}</i></Header>
                      <Divider />
                      <Header as='h5' style={{margin: '0'}}>
                        <Header.Content>
                          <Header.Subheader>CONTENT</Header.Subheader>
                          {item.content}
                        </Header.Content>
                      </Header>
                    </div>
                  </div>
                )
              }
            })}
          </Segment>}

          <Segment>
            <Header as='h5'>
              <Icon name='phone volume' style={{fontSize: '1em'}}/>
              <Header.Content>Call Information</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='dialStamp' component={InputField}
              readOnly
              label='Dialed'
              placeholder='Dialed' />
              <Field name='pickedStamp' component={InputField}
              readOnly
              label='Picked'
              placeholder='Picked' />
              <Field name='queueStamp' component={InputField}
              readOnly
              label='Queued'
              placeholder='Queued' />
              <Field name='dropStamp' component={InputField}
              readOnly
              label='Dropped'
              placeholder='Dropped' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='talkStamp' component={InputField}
              readOnly
              label='Talked'
              placeholder='Talked' />
              <Field name='hangupStamp' component={InputField}
              readOnly
              label='Hungup'
              placeholder='Hungup' />
              <Field name='status' component={InputField}
              readOnly
              label='Call Status'
              placeholder='Call Status' />
              <Field name='reason' component={InputField}
              readOnly
              label='Call Reason'
              placeholder='Call Reason' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Icon name='user circle' style={{fontSize: '1em'}}/>
              <Header.Content>Target / Campaign</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              readOnly
              width={8}
              placeholder='Name' />
              <Field name='mobile' component={InputField}
              readOnly
              width={4}
              placeholder='Mobile' />
              <Field name='email' component={InputField}
              readOnly
              width={4}
              placeholder='Email' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='information' component={InputField}
              readOnly
              width={16}
              placeholder='Information' />
            </Form.Group>
            {/* <Form.Group widths='equal'>
              <Field name='city' component={InputField}
              width={4}
              placeholder='City' />
              <Field name='province' component={InputField}
              width={4}
              placeholder='Province' />
              <Field name='address' component={InputField}
              width={8}
              placeholder='Address' />
            </Form.Group> */}
          
            <Divider hidden />
            <Form.Group widths='16'>
              <Field name='campaign' component={InputField}
              readOnly
              width={16}
              placeholder='Campaign' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='campaignType' component={InputField}
              readOnly
              width={4}
              placeholder='Campaign Type' />
              <Field name='startDate' component={InputField}
              readOnly
              width={4}
              placeholder='StartDate' />
              <Field name='endDate' component={InputField}
              readOnly
              width={4}
              placeholder='End Date' />
              <Field name='startTime' component={InputField}
              readOnly
              width={2}
              placeholder='Start Time' />
              <Field name='endTime' component={InputField}
              readOnly
              width={2}
              placeholder='End Time' />
            </Form.Group>
          </Segment>

          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Call' : 'Insert Call') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.voiceStore.getVoice!=undefined && state.voiceStore.getVoice.progress==1 ? state.voiceStore.getVoice.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
      result: selector(state, 'result'),
      campaignType: selector(state, 'campaignType'),
      remark: selector(state, 'remark'),
    }),
    { load: getVoice }
  )
  (reduxForm({
    form: 'VoiceForm',	// a unique identifier for this form
  })(VoiceForm)
  )
)
