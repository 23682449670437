import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Icon } from 'semantic-ui-react'

import { errorOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { ImageUploadField, LabelInputField, NumberField, InputField, CheckboxField, SelectField, DatePickerField, TextAreaField, LabelNumberField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
import { connect } from "react-redux"
import { browseError, resetError, getError,updateError, createError } from "../../actions/system/errorAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    errorStore: state.errorStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseError:(objParam) => dispatch(browseError(objParam)),

    resetError:(objParam) => dispatch(resetError(objParam)),
    getError:(objParam) => dispatch(getError(objParam)),
    createError:(objParam) => dispatch(createError(objParam)),
    updateError:(objParam) => dispatch(updateError(objParam)),
  }
}

class ErrorForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    this.props.resetError(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getError({errorId: this.props.errorId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.errorStore.updateError!=undefined ? this.props.errorStore.updateError : {}) : (this.props.errorStore.createError!=undefined ? this.props.errorStore.createError : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  componentWillUnmount() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.errorStore.updateError!=undefined ? this.props.errorStore.updateError : {}) : (this.props.errorStore.createError!=undefined ? this.props.errorStore.createError : {}))
    if (progress==1 && code=='00') {
      const { search, paging }= (this.props.errorStore.browseError!==undefined ? this.props.errorStore.browseError : {})
      this.props.browseError({search: {...search}, paging: paging})
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, errorId } = this.props
    mode==2 ? this.props.updateError({...values}) : this.props.createError({...values})
  }
  

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.errorStore.updateError!=undefined ? this.props.errorStore.updateError : {}) : (this.props.errorStore.createError!=undefined ? this.props.errorStore.createError : {}))

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
        <Segment>
            <Form.Group widths={16}>
              <Field name='level' component={SelectField}
              width={4}
              options={errorOptions('level')}
              label='Level'
              placeholder='Level' />
              <Field name='actor' component={InputField}
              width={4}
              label='Actor'
              placeholder='Actor' />
              <Field name='group' component={InputField}
              width={4}
              label='Group'
              placeholder='Group' />
              <Field name='host' component={InputField}
              width={4}
              label='Host'
              placeholder='Host' />
            </Form.Group>
          </Segment>
          <Segment>
            <Form.Group widths='equal'>
              <Field name='period' component={InputField}
              label='Period'
              placeholder='Period' />
              <Field name='execStart' component={InputField}
              label='Exec. Start'
              placeholder='Exec. Start' />
              <Field name='execFinish' component={InputField}
              label='Exec. Finish'
              placeholder='Exec. Finish' />
              <Field name='execDuration' component={LabelNumberField}
              label='mSec'
              caption='Exec. Duration'
              placeholder='Exec. Duration' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='scope' component={SelectField}
              options={errorOptions('scope')}
              label='Scope'
              placeholder='Scope' />
              <Field name='event' component={SelectField}
              options={errorOptions('event')}
              label='Event'
              placeholder='Event' />
              <Field name='action' component={InputField}
              label='Action'
              placeholder='Action' />
              <Field name='method' component={InputField}
              label='Method'
              placeholder='Method' />
            </Form.Group>
          </Segment>
          <Segment>
            <Form.Group widths='16'>
              <Field name='status' component={InputField}
              width={4}
              label='Status'
              placeholder='Status' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='params' component={InputField}
              width={8}
              label='Params'
              placeholder='Params' />
              <Field name='outputs' component={InputField}
              width={8}
              label='Outputs'
              placeholder='Outputs' />
            </Form.Group>
          </Segment>
          <Segment>
            <Form.Group widths='equal'>
              <Field name='message' component={InputField}
              label='Message'
              placeholder='Message' />
            </Form.Group>
          </Segment>

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Error' : 'Insert Error') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.errorStore.getError!=undefined && state.errorStore.getError.progress==1 ? state.errorStore.getError.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'ErrorForm',	// a unique identifier for this form
  })(ErrorForm)
  )
)