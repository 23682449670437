import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
} from 'semantic-ui-react'

import { format, parse } from "date-fns"

import { connect } from "react-redux"
import { browseService } from "../../actions/halo/serviceAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    serviceStore: state.serviceStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseService:(objParam) => dispatch(browseService(objParam)),
  }
}
class ReportingProcess extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.serviceStore.browseService!==undefined ? this.props.serviceStore.browseService : {})
    this.props.browseService(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.serviceStore.browseService!==undefined ? this.props.serviceStore.browseService : {})
    this.props.browseService({search: search, paging: paging})
  }
  
  render() {
    //console.log(this.props.serviceStore.browseService)
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.serviceStore.browseService!==undefined ? this.props.serviceStore.browseService : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1', padding: '0'}}>
          <div style={{display: 'flex', paddingBottom: '.5em'}}>
            <Header as='h4' style={{flex: '1', margin: '0'}}>
              <Icon name='clock outline' />
              <Header.Content>
                Processing
                <Header.Subheader>Reporting log process</Header.Subheader>
              </Header.Content>
            </Header>
            <div>
              <Button onClick={this.props.setMode.bind(this,3)} icon labelPosition='left' size='mini' color='red' style={{padding: '.7em 0', margin: '.5em 0 0 0'}}><Icon name='plus' /> CREATE  </Button>
            </div>
          </div>

          <Table unstackable selectable columns='16' celled striped basic='very' style={{background: 'none', border: 'none'}}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width='12'>
                  <Header as='h5' color='blue'>
                    PERIOD
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='4'>
                  <Header as='h5' color='blue'>
                    STATUS
                  </Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>
                        <Header as='h6'>
                          <Header.Content>
                            {item.period}
                            <Header.Subheader>Created at, {item.endStamp!=null ? format(parse(item.endStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'hh:mm - dd / MMM yy') : ''}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h6' style={{margin: '0'}}>
                          <Icon name={item.result=='OK' ? 'check circle' : 'times circle'} style={{fontSize: '1em', paddingRight: '.6em', borderRight: '1px solid #ddd'}} color='teal'/>
                          <Header.Content style={{paddingLeft: '.7em'}}>
                            {item.result}
                          </Header.Content>
                        </Header>
                        
                        {/* <Header as='h5' style={{margin: '0'}}>
                          <Icon name='check circle' style={{fontSize: '1em', paddingRight: '.6em', borderRight: '1px solid #ddd'}} color='teal'/>
                          <Header.Content style={{paddingLeft: '.7em'}}>
                            Transaction
                          </Header.Content>
                        </Header>
                        <Header as='h5' style={{margin: '.2em 0'}}>
                          <Icon name='times circle' style={{fontSize: '1em', paddingRight: '.6em', borderRight: '1px solid #ddd'}} color='orange'/>
                          <Header.Content style={{paddingLeft: '.7em'}}>
                            Campaign Result
                          </Header.Content>
                        </Header>
                        <Header as='h5' style={{margin: '0'}}>
                          <Icon name='check circle' style={{fontSize: '1em', paddingRight: '.6em', borderRight: '1px solid #ddd'}} color='teal'/>
                          <Header.Content style={{paddingLeft: '.7em'}}>
                            Agent Performance
                          </Header.Content>
                        </Header> */}
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={2}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        {/* {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>} */}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportingProcess)
