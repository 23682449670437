import React,{Component} from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
  ModalDescription,
  Modal,
  Progress,
} from 'semantic-ui-react'

import Draggable, {DraggableCore} from 'react-draggable';

import OmniphoneHeader from './OmniphoneHeader';
import OmniphoneFooter from './OmniphoneFooter';
import OmniphoneAccount from './OmniphoneAccount';
import OmniphoneDial from './OmniphoneDial';
import OmniphoneOncall from './OmniphoneOncall';
import OmniphoneOnline from './OmniphoneOnline';
import OmniphoneOffline from './OmniphoneOffline';
import OmniphoneOutgoing from './OmniphoneOutgoing';
import OmniphoneIncoming from './OmniphoneIncoming';

import OmniphoneOncallMini from './OmniphoneOncallMini';
import OmniphoneOnlineMini from './OmniphoneOnlineMini';
import OmniphoneOfflineMini from './OmniphoneOfflineMini';
import OmniphoneIncomingMini from './OmniphoneIncomingMini';

import OmniphoneOnlineFloating from './OmniphoneOnlineFloating';
import OmniphoneIncomingFloating from './OmniphoneIncomingFloating';
import OmniphoneOncallFloating from './OmniphoneOncallFloating';
import OmniphoneOfflineFloating from './OmniphoneOfflineFloating';

import { connect } from "react-redux"
import { loggingError } from "../../actions/system/errorAction"
import { socketStatus, socketAgentStatus, socketAgentStatistic, socketAgentNotification } from "../../actions/socket/socketAction"

import { WebSocketContext } from '../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
    errorStore: state.errorStor
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketStatus:(objParam) => dispatch(socketStatus(objParam)),
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),
    loggingError:(objParam) => dispatch(loggingError(objParam)),
  }
}

class OmniphoneCall extends Component {
  constructor(props){
    super(props)
    this.pos={
      x: 0,
      y: 0,
    }
  	this.state = {
      
    }
  }

  handleStart(e, data) {
    this.pos= {
      x: data.x,
      y: data.y,
    }
    // console.log('!!! START DRAG', e,  data)
  }

  handleDrag(e, data) {
    // console.log('!!! ON DRAG', e,  data)
  }

  handleStop(e, data) {
    const { layout }= this.state

    if (Math.abs(this.pos.x-data.x)>=200 || Math.abs(this.pos.y-data.y)>=200 || layout=='floating') {
      if (layout!='floating' ) {
        this.setState({
          layout: 'floating',
        })
      }
      this.setState({
        windowPos: {x: data.x, y: data.y}
      })
    }
    // console.log('!!! STOP DRAG', e,  data)
  }

  render() {
    const { screenWidth, screenHeight, responsive, layout, windowPos, uiMode, sipAccount, phoneState, dialMode, statusUA }= this.props
    const agentStatus= (this.props.socketStore.socketAgentStatus && this.props.socketStore.socketAgentStatus.data && this.props.socketStore.socketAgentStatus.data.payload ? this.props.socketStore.socketAgentStatus.data.payload.data : {})
    try {
      // console.log('!!!! OMNIPHONE', agentStatus.status, this.state.targetId)
      if (layout=='normal') {
        return (
          <div style={{display: 'flex', flexDirection: 'column', minHeight: '100%'}}>
            <div style={{flex: '1', background: '#424242'}}>
              {/* <OmniphoneHeader responsive={responsive} mode={agentStatus.mode||'predictive'} status={agentStatus.status} dialMode={dialMode} phoneState={phoneState} uiMode={uiMode} layout={layout} sipAccount={sipAccount} setSkinMode={this.props.setSkinMode.bind(this)} setPhoneUI={this.props.setPhoneUI.bind(this)}/> */}
          
              {/* {uiMode.includes('setting') &&
              <OmniphoneAccount 
                screenWidth={screenWidth} screenHeight={screenHeight} 
                responsive={responsive} mode={agentStatus.mode||'predictive'} onChangeText={this.onChangeText.bind(this)} onClickReconnect={this.onClickReconnect.bind(this)} 
              />} */}

              {/* {phoneState=='online' && dialMode &&
              <OmniphoneDial 
                screenWidth={screenWidth} screenHeight={screenHeight} 
                responsive={responsive} mode={agentStatus.mode||'predictive'} onChangeText={this.onChangeText.bind(this)} callDial={this.callDial.bind(this)} 
              />} */}
              {/* {phoneState=='dialing' &&
              <OmniphoneOutgoing 
                screenWidth={screenWidth} screenHeight={screenHeight} 
                responsive={responsive} mode={agentStatus.mode||'predictive'} callHangup={this.props.callHangup.bind(this)}  layout={layout} 
              />} */}

              {['online'].includes(phoneState) &&
              <OmniphoneOnline 
                screenWidth={screenWidth} screenHeight={screenHeight} 
                responsive={responsive} mode={agentStatus.mode||'predictive'} layout={layout} 
              />}
              
              {['incoming'].includes(phoneState) &&
              <OmniphoneIncoming 
                screenWidth={screenWidth} screenHeight={screenHeight} 
                responsive={responsive} mode={agentStatus.mode||'predictive'} callAnswer={this.props.callAnswer.bind(this)} callHangup={this.props.callHangup.bind(this)}  layout={layout} 
              />}

              {['oncall'].includes(phoneState) &&
              <OmniphoneOncall 
                channel={this.props.channel}
                remoteVideoStream= {this.props.remoteVideoStream} localVideoStream={this.props.localVideoStream}
                screenWidth={screenWidth} screenHeight={screenHeight} 
                responsive={responsive} mode={agentStatus.mode||'predictive'} 
                callHangup={this.props.callHangup.bind(this)}  layout={layout} status={agentStatus.status || phoneState} dialings={agentStatus.dialings}
                endConversation={this.props.endConversation.bind(this)}
              />}

              {['offline'].includes(phoneState) && false &&
              <OmniphoneOffline 
                screenWidth={screenWidth} screenHeight={screenHeight} 
                responsive={responsive} mode={agentStatus.mode||'predictive'} statusUA={statusUA} layout={layout} 
              />}
            </div>
            <div>
              <OmniphoneFooter 
                screenWidth={screenWidth} screenHeight={screenHeight} 
                responsive={responsive} mode={agentStatus.mode||'predictive'} layout={layout} phoneState={phoneState}
              />
            </div>
          </div>
        )
      } else if (layout==='mini') {
        return (
          <div style={{height: responsive ? 'auto' : layout=='floating' ? '0' : '78px', }}>
            <div style={{width: responsive ? 'auto' : layout=='floating' ? '350px' : '258px', position: responsive ? 'relative' : 'fixed', zIndex: 999}}>
              <div style={{maxWidth: responsive ? 'auto' : 'auto  ', overflow: 'hidden', border: 'none', borderRadius: '2.5em 0 2em 0'}}>
                {['incoming'].includes(phoneState) &&
                <OmniphoneIncomingMini mode={agentStatus.mode||'predictive'} callAnswer={this.props.callAnswer.bind(this)} callHangup={this.props.callHangup.bind(this)}  layout={layout} agent={this.props.agent} />}

                {['offline'].includes(phoneState) &&
                <OmniphoneOfflineMini mode={agentStatus.mode||'predictive'} statusUA={statusUA} layout={layout} agent={this.props.agent} />}

                {['online'].includes(phoneState) &&
                <OmniphoneOnlineMini mode={agentStatus.mode||'predictive'} layout={layout} agent={this.props.agent} />}

                {['oncall'].includes(phoneState) &&
                <OmniphoneOncallMini 
                  channel={this.props.channel}
                  mode={agentStatus.mode||'predictive'} callHangup={this.props.callHangup.bind(this)}  layout={layout} status={agentStatus.status} dialings={agentStatus.dialings}
                  endConversation={this.props.endConversation.bind(this)}
                  agent={this.props.agent} 
                />}
              </div>
            </div>
          </div>
        );
      } else if (layout==='floating') {
        return (
          <div style={{height: responsive ? 'auto' : layout=='floating' ? '0' : '200px', }}>
            <div style={{width: responsive ? 'auto' : layout=='floating' ? '350px' : '260px', position: responsive ? 'relative' : 'fixed', zIndex: 999}}>
              {!responsive && 
              <Draggable
                // axis="x"
                handle=".handle"
                // defaultPosition={{x: 0, y: 0}}
                position={layout=='floating' ? windowPos : {x: 0, y: 0}}
                grid={[25, 25]}
                scale={1}
                onStart={this.handleStart.bind(this)}
                onDrag={this.handleDrag.bind(this)}
                onStop={this.handleStop.bind(this)}>
                  <div style={{maxWidth: '350px', borderRadius: layout=='floating' ? '0' : '.3em', overflow: 'hidden', border: layout=='floating' ? '1.5em solid #fff' : 'none'}}>
                    <OmniphoneHeader mode={agentStatus.mode||'predictive'} status={agentStatus.status}  dialMode={dialMode} phoneState={phoneState} uiMode={uiMode} layout={layout} sipAccount={sipAccount} setSkinMode={this.props.setSkinMode.bind(this)} setPhoneUI={this.props.setPhoneUI.bind(this)}/>
                    {['online'].includes(phoneState) &&
                    <OmniphoneOnlineFloating mode={agentStatus.mode||'predictive'} layout={layout} />}

                    {['incoming'].includes(phoneState) &&
                    <OmniphoneIncomingFloating mode={agentStatus.mode||'predictive'} callAnswer={this.props.callAnswer.bind(this)} callHangup={this.props.callHangup.bind(this)}  layout={layout} />}

                    {['oncall'].includes(phoneState) &&
                    <OmniphoneOncallFloating 
                      channel={this.props.channel}
                      mode={agentStatus.mode||'predictive'} callHangup={this.props.callHangup.bind(this)}  layout={layout} status={agentStatus.status}
                      endConversation={this.props.endConversation.bind(this)}
                    />}

                    {['offline'].includes(phoneState) &&
                    <OmniphoneOfflineFloating mode={agentStatus.mode||'predictive'} statusUA={statusUA} layout={layout} />}
                    
                    <OmniphoneFooter mode={agentStatus.mode||'predictive'} layout={layout} phoneState={phoneState}/>
                  </div>
              </Draggable>}
            </div>
          </div>
        );
      }
    } catch(err) {
      this.props.loggingError({level: 'error', actor: 'APP SIP', scope: 'OmniphoneCall', event: 'On render', message: err });
    }
  }
}

OmniphoneCall.contextType= WebSocketContext;
export default connect(mapStateToProps, mapDispatchToProps)(OmniphoneCall)