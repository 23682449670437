import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
} from 'semantic-ui-react'



class EmulatorIncoming extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { mode, layout, responsive }= this.props
    return (
      <>
        <Segment attached={true} style={{padding: '1em .5em', background: '#eee', border: 'none', textAlign: !responsive && layout!='mini' ? 'center' : 'left'}}>
          <Header as='h3' color='blue' icon={!responsive && layout!='mini'} style={{margin: '1em .5em 0 .5em', display: 'inline'}} >
            <Icon name='phone' className='bganimateiconborder' style={{transform: 'rotate(135deg)', fontSize: '1em', cursor: 'pointer'}} inverted circular color='blue' onClick={this.props.callAnswer.bind(this)}/>
            <Header.Content style={{paddingLeft: '.5em'}}>
              {mode=='predialed' ? 'Incoming ...' : 'Incoming !'}
              <Header.Subheader>
                {mode=='predialed' ? 'Inbound call ...' : 'Click to answer'}
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Segment>
      </>
    )
  }
}

export default EmulatorIncoming