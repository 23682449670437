import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Icon } from 'semantic-ui-react'

import { competencyOptions, voipOptions, genderOptions, educationOptions,protoOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { ImageUploadField, LabelInputField, NumberField, InputField, CheckboxField, SelectField, DatePickerField, MaskedField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
import { connect } from "react-redux"
import { resetVoip, getVoip,updateVoip, createVoip } from "../../actions/halo/voipAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    voipStore: state.voipStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetVoip:(objParam) => dispatch(resetVoip(objParam)),
    getVoip:(objParam) => dispatch(getVoip(objParam)),
    createVoip:(objParam) => dispatch(createVoip(objParam)),
    updateVoip:(objParam) => dispatch(updateVoip(objParam)),
  }
}

class VoipForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    this.props.resetVoip(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getVoip({voipId: this.props.voipId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.voipStore.updateVoip!=undefined ? this.props.voipStore.updateVoip : {}) : (this.props.voipStore.createVoip!=undefined ? this.props.voipStore.createVoip : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, voipId } = this.props
    mode==2 ? this.props.updateVoip({...values}) : this.props.createVoip({...values})
  }
  

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.voipStore.updateVoip!=undefined ? this.props.voipStore.updateVoip : {}) : (this.props.voipStore.createVoip!=undefined ? this.props.voipStore.createVoip : {}))

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          {mode==3 && <>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                Generate VOIP Account
                <Header.Subheader>Account required to copied to voip proxy platform.</Header.Subheader>
              </Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='voipGroup' component={SelectField}
              width={8}
              options= {voipOptions('voip')}
              validate={[required]}
              label='Group Voip Account'
              placeholder='Agent or Public' />
              <Field name='prefixPattern' component={MaskedField}
              mask='7099999XXX'
              width={8}
              validate={[required]}
              label='Pattern of Prefix Account'
              placeholder='Format of account prefix number' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                Voip Realm
                <Header.Subheader>Realm or Domain Coverage Authentication</Header.Subheader>
              </Header.Content>
            </Header>

            <Form.Group widths='16'>
              <Field name='voipRealm' component={InputField}
              width={8}
              label='Realm'
              placeholder='Voip realm' />
              <Field name='voipProto' component={SelectField}
              width={8}
              options={protoOptions}
              label='Protocol'
              placeholder='Kind of connection protocol' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='voipHost' component={InputField}
              width={8}
              label='Host / Server'
              placeholder='Host domain or ip address' />
              <Field name='voipPort' component={InputField}
              width={8}
              label='Port Number'
              placeholder='Port number to connect' />
            </Form.Group>
          </Segment></>}

          {mode==2 && <>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                Account Credential
                <Header.Subheader>Voip Account Assigned For Agent / Public Visitor</Header.Subheader>
              </Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='voipGroup' component={SelectField}
              width={4}
              readOnly
              options= {voipOptions('voip')}
              validate={[required]}
              label='Group Voip Account'
              placeholder='Agent or Public' />
              <Field name='voipAccount' component={InputField}
              width={4}
              label='Account'
              placeholder='User account for voip access' />
              <Field name='voipPasswd' component={InputField}
              width={8}
              label='Password'
              placeholder='Password for voip access' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                Voip Realm
                <Header.Subheader>Realm or Domain Coverage Authentication</Header.Subheader>
              </Header.Content>
            </Header>
            
            <Form.Group widths='16'>
              <Field name='voipRealm' component={InputField}
              width={8}
              label='Realm'
              placeholder='Voip realm' />
              <Field name='voipProto' component={SelectField}
              width={8}
              options={protoOptions}
              label='Protocol'
              placeholder='Kind of connection protocol' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='voipHost' component={InputField}
              width={8}
              label='Host / Server'
              placeholder='Host domain or ip address' />
              <Field name='voipPort' component={InputField}
              width={8}
              label='Port Number'
              placeholder='Port number to connect' />
            </Form.Group>
          </Segment></>}

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Voip' : 'Insert Voip') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.voipStore.getVoip!=undefined && state.voipStore.getVoip.progress==1 ? state.voipStore.getVoip.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'VoipForm',	// a unique identifier for this form
  })(VoipForm)
  )
)