import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Table } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import DismissableMessage from '../common/DismissableMessage'
import {genderOptions, maritalOptions}  from '../common/StaticMasterData'
import MenuTabular from '../common/MenuTabular'

import { SubmissionError, Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, NumberField, DatePickerField, SelectField, TextAreaField, LabelNumberField, LabelDatePickerField } from '../validation/componentrsui'
import { required, confirmedPassword, phoneNumber, number, minValue, email, phoneNumberID } from '../validation/validation'

import { connect } from "react-redux"
import { resetVisitor, getVisitor,updateVisitor } from "../../actions/halo/visitorAction"
import { contactOmniphone,remarkOmniphone, resetOmniphone } from "../../actions/stream/omniphoneAction"
import { listRemark } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('VisitorForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    visitorStore: state.visitorStore,
    omniphoneStore: state.omniphoneStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listRemark:(objParam) => dispatch(listRemark(objParam)),

    resetVisitor:(objParam) => dispatch(resetVisitor(objParam)),
    getVisitor:(objParam) => dispatch(getVisitor(objParam)),
    updateVisitor:(objParam) => dispatch(updateVisitor(objParam)),

    resetOmniphone:(objParam) => dispatch(resetOmniphone(objParam)),
    remarkOmniphone:(objParam) => dispatch(remarkOmniphone(objParam)),
  }
}

const panes = (param)=>{
  return([
    {
      menuItem: { key: '0', icon: 'clone outline', content: 'Remarks' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '1', icon: 'user circle', content: 'Personal' },
      render: () => 
      <></>
    },
  ])
};

class VisitorForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      tab: 0,
      lastVisitor: null,
    }
  }

  componentDidMount(){
    this.props.listRemark({remarkType: 'visitor'});

    this.props.resetVisitor(this.props.mode)
    this.props.getVisitor({visitorId: this.props.visitorId})
  }

  componentDidUpdate(prevProps) {
    const { mode, visitor, media } = this.props
    const { lastVisitor } = this.state
    
    const { progress, code, text }= this.props.visitorStore.updateVisitor!=undefined ? this.props.visitorStore.updateVisitor : {};
    if (progress==1 && code=='00') {
      setTimeout(()=>{
        this.props.resetOmniphone();
        this.props.hasSaved();
      }, 3000 )
    } else {
      if (prevProps.visitorId!=this.props.visitorId) {
        this.props.getVisitor({visitorId: this.props.visitorId})
      }
    }

    if (visitor && visitor.name) {
      if (!lastVisitor || visitor.name!=lastVisitor.name)  {
        this.props.dispatch(change('VisitorForm', 'name', visitor.name ));
        this.props.dispatch(change('VisitorForm', 'mobile', visitor.mobile ));
        this.props.dispatch(change('VisitorForm', 'email', visitor.email ));

        this.setState({
          lastVisitor: visitor
        })
      }
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { media } = this.props
    this.props.updateVisitor({...values})
    this.props.remarkOmniphone({...values, voices: null, appointments: null })

    this.props.endConversation(media, 
      (media=='Chat' && {
        command: 'end_by_agent',
        visitorId: this.props.visitorId,
        agentId: this.props.agentId,
        clientId: this.props.clientId,
      })
    )
  }

  setMode(e, d) {
    // console.log(d.activeIndex)
    this.setState({tab: d.activeIndex})
  }

  render() {
    const { tab }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.visitorStore.updateVisitor!=undefined ? this.props.visitorStore.updateVisitor : {}) : (this.props.visitorStore.createVisitor!=undefined ? this.props.visitorStore.createVisitor : {}))
    const { data }= this.props.visitorStore.getVisitor!=undefined ? this.props.visitorStore.getVisitor : {}

    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})
    
    return (
      <div style={{padding: '1em 1em', ...style}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              width={8}
              label='Name'
              placeholder='Full Name' />
              <Field name='mobile' component={InputField}
              width={4}
              validate={[phoneNumberID]}
              label='Mobile'
              placeholder='Mobile Phone' />
              <Field name='email' component={InputField}
              width={4}
              validate={[email]}
              label='Email'
              placeholder='Email' />
            </Form.Group>
          </Segment>

          <Divider hidden />
          <MenuTabular panes={panes({})} style={{width: '100%', marginBottom: '-1.2em', marginTop: '1em'  }} renderActiveOnly={true} onTabChange={this.setMode.bind(this)}/>

          {tab==0 && <>
          <Segment>
            <Header as='h5'>
              <Icon name='clone outline' style={{fontSize: '1em'}}/>
              <Header.Content>Remarks</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='remark' component={SelectField}
              width={8}
              options={listRemark.options||[]}
              label='Remark'
              placeholder='Remarks' />
              <Field name='result' component={LabelNumberField}
              width={4}
              label='Rp.'
              caption= 'Opportunity Value'
              placeholder='Result / Value' />
              <Field name='expired' component={LabelDatePickerField}
              width={4}
              label='Expired'
              caption='Valid Until'
              placeholder='Valid Date' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='reason' component={TextAreaField}
              label='Description'
              placeholder='Description / reason' />
            </Form.Group>
          </Segment>
          </>}

          {tab==1 && <>
          <Segment>
            <Header as='h5'>
              <Icon name='user circle' style={{fontSize: '1em'}}/>
              <Header.Content>Personal</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='gender' component={SelectField}
              fluid
              options={genderOptions}
              label='Gender'
              placeholder='L/P' />
              <Field name='birthDate' component={DatePickerField}
              label='Birth Date'
              placeholder='Birth Date' />
              <Field name='children' component={InputField}
              label='Children'
              placeholder='Children' />
              <Field name='marital' component={SelectField}
              fluid
              options={maritalOptions}
              label='Marital'
              placeholder='Marital' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Icon name='map marker alternate' style={{fontSize: '1em'}}/>
              <Header.Content>Address</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='address' component={InputField}
              fluid
              placeholder='Address' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='city' component={InputField}
              fluid
              placeholder='City' />
              <Field name='province' component={InputField}
              fluid
              placeholder='Province' />
              <Field name='country' component={InputField}
              fluid
              placeholder='Country' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Icon name='fax' style={{fontSize: '1em'}}/>
              <Header.Content>Contact</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='mobile' component={InputField}
              label='Mobile'
              placeholder='Handphone' />
              <Field name='email' component={InputField}
              label='Email'
              placeholder='Email' />
              <Field name='whatsapp' component={InputField}
              label='Whatsapp'
              placeholder='Whatsapp' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='twitter' component={InputField}
              fluid
              label='Twitter'
              placeholder='Twitter' />
              <Field name='instagram' component={InputField}
              fluid
              label='Instagram'
              placeholder='Instagram' />
              <Field name='facebook' component={InputField}
              fluid
              label='Facebook'
              placeholder='Facebook' />
            </Form.Group>
          </Segment>
          </>}

          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Visitor' : 'Insert Visitor') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.visitorStore.getVisitor!=undefined && state.visitorStore.getVisitor.progress==1 ? state.visitorStore.getVisitor.data : {}),
      formValues: getFormValues('VisitorForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
      name: selector(state, 'name'),
      mobile: selector(state, 'mobile'),
      email: selector(state, 'email'),
    }),
    { load: getVisitor }
  )
  (reduxForm({
    form: 'VisitorForm',	// a unique identifier for this form
  })(VisitorForm)
  )
)
