import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetTrespassing(objParam) {
  return {
      type: 'TRESPASSING_RESET',
      payload: objParam
  }
}

export function getTrespassing(objParam) {
  return {
      type: 'TRESPASSING_GET',
      payload:
        axios.post(
          urlAPI + 'trespassing/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusTrespassing(objParam) {
  return {
      type: 'TRESPASSING_STATUS',
      payload:
        axios.post(
          urlAPI + 'trespassing/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseTrespassing(objParam) {
  return {
      type: 'TRESPASSING_BROWSE',
      payload:
        axios.post(
          urlAPI + 'trespassing/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createTrespassing(objParam) {
  return {
      type: 'TRESPASSING_CREATE',
      payload:
        axios.post(
          urlAPI + 'trespassing/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateTrespassing(objParam) {
  return {
      type: 'TRESPASSING_UPDATE',
      payload:
        axios.post(
          urlAPI + 'trespassing/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeTrespassing(objParam) {
  return {
      type: 'TRESPASSING_REMOVE',
      payload:
        axios.post(
          urlAPI + 'trespassing/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
