import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetVoip(objParam) {
  return {
      type: 'VOIP_RESET',
      payload: objParam
  }
}

export function getVoip(objParam) {
  return {
      type: 'VOIP_GET',
      payload:
        axios.post(
          urlAPI + 'voip/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusVoip(objParam) {
  return {
      type: 'VOIP_STATUS',
      payload:
        axios.post(
          urlAPI + 'voip/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseVoip(objParam) {
  return {
      type: 'VOIP_BROWSE',
      payload:
        axios.post(
          urlAPI + 'voip/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createVoip(objParam) {
  return {
      type: 'VOIP_CREATE',
      payload:
        axios.post(
          urlAPI + 'voip/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateVoip(objParam) {
  return {
      type: 'VOIP_UPDATE',
      payload:
        axios.post(
          urlAPI + 'voip/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeVoip(objParam) {
  return {
      type: 'VOIP_REMOVE',
      payload:
        axios.post(
          urlAPI + 'voip/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
