import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'

import FlowInteractiveBody from '../flow/InteractiveBody'
import FlowWidgetBody from '../flow/WidgetBody'

class AdminBodyInbound extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }
  
  render() {
    const { mode, module, screenWidth, screenHeight, mini, modal, params }= this.props

    return (
      <div>
        {/* FLOW OMNI CHANNEL */}
        {mode==='interactive' && <FlowInteractiveBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
        {mode==='widget' && <FlowWidgetBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
      </div>
    )
  }
}

export default AdminBodyInbound