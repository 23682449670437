import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'
import ModalContainer from '../../libraries/common/ModalContainer';

import TunnelBody from '../talk/TunnelBody';
import MauBody from '../talk/MauBody';

import ConferenceBody from '../talk/ConferenceBody';
import LinkcallBody from '../talk/LinkcallBody';
import WidgetBody from '../talk/WidgetBody';

// import WebwidgetBody from '../talk/WebwidgetBody';
// import AndroidwidgetBody from '../talk/AndroidwidgetBody';
// import IOSwidgetBody from '../talk/IOSwidgetBody';


class AdminBodyInappvoice extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }
  
  render() {
    const { mode, module, screenWidth, screenHeight, mini, modal, params }= this.props

    return (
      <div>
        {mode==='tunnel' && <TunnelBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
        {mode==='mau' && <MauBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}

        {mode==='call link' && <LinkcallBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
        {mode==='conference' && <ConferenceBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
        {mode==='in-app widget' && <WidgetBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}

        {/* {mode==='web widget' && <WebwidgetBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />} */}
        {/* {mode==='android widget' && <AndroidwidgetBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />} */}
        {/* {mode==='IOS widget' && <IOSwidgetBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />} */}
      </div>
    )
  }
}

export default AdminBodyInappvoice