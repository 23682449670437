import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
} from 'semantic-ui-react'

import numeral from "numeral"
import { parse, format, addSeconds } from "date-fns"

import { connect } from "react-redux"
import { browseTopup } from "../../actions/halo/topupAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    topupStore: state.topupStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseTopup:(objParam) => dispatch(browseTopup(objParam)),
  }
};


class TopupBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.topupStore.browseTopup!==undefined ? this.props.topupStore.browseTopup : {})
    this.props.browseTopup(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.topupStore.browseTopup!==undefined ? this.props.topupStore.browseTopup : {})
    this.props.browseTopup({search: {topupStamp: format(new Date(), 'yyyy-MM-01'), ...search, userId: (this.props.params && this.props.params.userId)}, paging: paging})
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.topupStore.browseTopup!==undefined ? this.props.topupStore.browseTopup : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight-125}}>
        <div style={{flex: '1'}}>
        <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                {/* <Table.HeaderCell></Table.HeaderCell> */}
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell width='3'>
                  <Header as='h5' color='blue'>
                    AMOUNT
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='8' style={{background: '#fff2f2'}}>
                  <Header as='h5' color='blue'>
                    TRANSACTION
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='5'>
                  <Header as='h5' color='blue'>
                    VERIFICATION
                  </Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={4} style={{padding: '1em 2em'}}><Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      {/* <Table.Cell textAlign='center'>
                        <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.topupId)}/>
                      </Table.Cell> */}
                      <Table.Cell textAlign='center'>
                        <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.topupId)}/>
                      </Table.Cell>
                      <Table.Cell textAlign='right'>
                        <Header as='h5' style={{fontWeight: 'bold'}}>
                          <Header.Content>
                            {numeral(item.depositCredit).format('0,0')}
                            <Header.Subheader>Topup Credit</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell style={{background: '#fff2f2'}}>
                        <Header as='h5'>
                          <Header.Content>
                            {item.topupType}
                            <Header.Subheader>{item.topupStamp} / {item.user && item.user.company}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5' style={{margin: '0 0 .5em 0', flex: '1'}}>
                          <Header.Content>
                            {(item.isVerified==0 && 'Unverified') || (item.isVerified==1 && 'Verified') || (item.isVerified==-1 && 'Suspect')}
                            <Header.Subheader>{item.verifiedStamp} / {item.verifiedBy}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        
        {paging!=undefined && paging.count>0 &&
            <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
              <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
               
                defaultActivePage={paging.page}
                totalPages={Math.ceil(paging.count/paging.size)}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopupBrowse)
