import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import AgentBrowse from '../../libraries/halo/AgentBrowse'
import AgentForm from '../../libraries/halo/AgentForm'
import AgentSearch from '../../libraries/halo/AgentSearch'


class AgentBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        agentId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, agentId: id})
    }

    render() {
      const {mode, agentId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div>
            <AgentSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <AgentBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}/>}
              {mode==2 && <AgentForm mode={mode} agentId={agentId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <AgentForm mode={mode} agentId={0} setMode={this.setMode.bind(this)} />}
            </div>
          </div>
        </Container>
      )
    }
}

export default AgentBody
