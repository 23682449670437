import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Dropdown } from 'semantic-ui-react'
import { parse, format, addSeconds } from "date-fns"

import BarRechart from '../../chart/BarRechart';

import { connect } from "react-redux"
import { statApi } from "../../../actions/halo/statisticAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    statisticStore: state.statisticStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    statApi:(objParam) => dispatch(statApi(objParam)),
  }
}

class ApiResponseWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      stamp: '',
      barData: [
        {name: 'Campaign', OK: 0, RTO: 0, NOK: 0}, 
        {name: 'Agent', OK: 0, RTO: 0, NOK: 0}, 
        {name: 'Target', OK: 0, RTO: 0, NOK: 0}, 
        {name: 'Widget', OK: 0, RTO: 0, NOK: 0}, 
        {name: 'Webhook', OK: 0, RTO: 0, NOK: 0}, 
        {name: 'Reporting', OK: 0, RTO: 0, NOK: 0}, 
        {name: 'Interaction', OK: 0, RTO: 0, NOK: 0}, 
        {name: 'Communication', OK: 0, RTO: 0, NOK: 0}, 
      ],
      barLegend: [
        {id: 'OK', name: 'OK'},
        {id: 'NOK', name: 'NOK'},
      ]
    };
  }
  
  componentDidUpdate() {
    const barData= [];
    const inventoryKeys= new Map([
      ['Campaign', 0],
      ['Agent', 0],
      ['Target', 0],
      ['Widget', 0],
      ['Webhook', 0],
      ['Reporting', 0],
      ['Interaction', 0],
      ['Communication', 0],
    ])
    const { data, search }= this.props.statisticStore.statApi!=undefined ? this.props.statisticStore.statApi: {}

    if (this.state.stamp=='') {
      if (data && data.statApis && data.statApis[format(new Date(), 'dd')]) {
        Object.entries(data.statApis[format(new Date(), 'dd')]).forEach(([inventory, values]) => {
          barData.push({
            name: inventory, 
            OK: values.totSucceed, 
            NOK: values.totFailed,
            // ...values
          });
          inventoryKeys.delete(inventory)
        })

        // console.log(barData)
        inventoryKeys.forEach((value, key)=>{
          barData.push({
            name: key, 
            OK: 0, 
            NOK: 0,
            // ...values
          });
        })
        this.setState({
          stamp: '-',
          barData: barData
        })
      }
    }
  }
  
  render() {
    const { screenWidth,  screenHeight}= this.props
    const { barData, barLegend}= this.state
    const { data, search, paging }= (this.props.statisticStore.statApi!==undefined ? this.props.statisticStore.statApi : {})
    
    return (<>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <Header as='h5'>
          <Icon name='chart bar' style={{fontSize: '1em'}} />
          <Header.Content>
            API Response
            <Header.Subheader>Middleware response success rate.</Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em'}}>
        {/* {data!=undefined && */}
          <BarRechart mode='shape' height={this.props.height || 250} yAxis={false} dashed={true} label={true} data={barData} legend={barLegend}/>
        {/* } */}
      </Segment>
    </>);
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ApiResponseWidget)