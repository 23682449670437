import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'
import { ticketOptions, progressOptions, durationOptions }  from '../common/StaticMasterData'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetHandling, getHandling,updateHandling, createHandling } from "../../actions/service/handlingAction"
import { listSegment } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('TickethandlingForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    handlingStore: state.handlingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listSegment:(objParam) => dispatch(listSegment(objParam)),

    resetHandling:(objParam) => dispatch(resetHandling(objParam)),
    getHandling:(objParam) => dispatch(getHandling(objParam)),
    createHandling:(objParam) => dispatch(createHandling(objParam)),
    updateHandling:(objParam) => dispatch(updateHandling(objParam)),
  }
}

class TickethandlingForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remarkResult: null,
    }
  }

  componentDidMount(){
    this.props.resetHandling(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getHandling({handlingId: this.props.handlingId})
    }

    this.props.listSegment({search: {clientId: this.props.clientId}})
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.handlingStore.updateHandling!=undefined ? this.props.handlingStore.updateHandling : {}) : (this.props.handlingStore.createHandling!=undefined ? this.props.handlingStore.createHandling : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, handlingId, ticketId } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateHandling({...values, ticketId: ticketId, handlingId: handlingId}) : this.props.createHandling({...values, ticketId: ticketId})
    
  }

  onChangeResult(v) {
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})

    this.props.dispatch(change('TickethandlingForm', 'progress', listRemark.progress[v]));
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.handlingStore.getHandling!=undefined && this.props.handlingStore.getHandling.progress=='1' ? this.props.handlingStore.getHandling : {})
    const { progress, code, text }= (mode==2 ? (this.props.handlingStore.updateHandling!=undefined ? this.props.handlingStore.updateHandling : {}) : (this.props.handlingStore.createHandling!=undefined ? this.props.handlingStore.createHandling : {}))

    const listSegment= (this.props.masterStore.listSegment!=undefined && this.props.masterStore.listSegment.progress=='1' ? this.props.masterStore.listSegment.data : [])
    
    return (
      <div style={{padding: '1em 1em', ...style}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment attached='top'>
            <Header as='h5'>
              <Icon name='edit' style={{fontSize: '1em'}}/>
              <Header.Content>Handling Action</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='actionType' component={SelectField}
              width={4}
              // options={actionOptions}
              label='Action Type'
              placeholder='Action Type' />
              {/* <Field name='actionStamp' component={DatePickerField}
              width={4}
              showTimeSelect={true}
              dateFormat={'dd / MMMM, yyyy h:mm aa'}
              label='Action Stamp'
              placeholder='Action Stamp' /> */}
              <Field name='estDuration' component={SelectField}
              width={4}
              options={durationOptions('ticket')}
              label='Action Duration'
              placeholder='Est Action Duration' />
              <Field name='actionHandler' component={InputField}
              width={8}
              label='Handled By'
              placeholder='Action Handler' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='actionActivity' component={TextAreaField}
              label='Activity'
              placeholder='Activity' />
            </Form.Group>
          </Segment>
          <Segment attached='bottom'>
            <Header as='h5'>
              <Icon name='ticket alternate' style={{fontSize: '1em'}}/>
              <Header.Content>Ticket Status</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='status' component={SelectField}
              width={4}
              options={ticketOptions}
              label='Ticket Status'
              placeholder='Ticket Status' />
              <Field name='progress' component={SelectField}
              options={progressOptions}
              width={4}
              label='Ticket Progress'
              placeholder='Ticket Progress' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='reason' component={TextAreaField}
              width={16}
              label='Reason'
              placeholder='Reason' />
            </Form.Group>
          </Segment>
          
          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Handling' : 'Insert Handling') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.handlingStore.getHandling!=undefined && state.handlingStore.getHandling.progress==1 ? state.handlingStore.getHandling.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
      result: selector(state, 'result'),
      campaignType: selector(state, 'campaignType'),
      remark: selector(state, 'remark'),
    }),
    { load: getHandling }
  )
  (reduxForm({
    form: 'TickethandlingForm',	// a unique identifier for this form
  })(TickethandlingForm)
  )
)
