import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
} from 'semantic-ui-react'

import {withRouter} from "react-router-dom"

import { connect } from "react-redux"
import { browseAgent, realmAgent } from "../../../actions/halo/agentAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    agentStore: state.agentStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseAgent:(objParam) => dispatch(browseAgent(objParam)),
    realmAgent:(objParam) => dispatch(realmAgent(objParam)),
  }
};


class AgentModeBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.agentStore.browseAgent!==undefined ? this.props.agentStore.browseAgent : {})
    this.props.browseAgent(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.agentStore.browseAgent!==undefined ? this.props.agentStore.browseAgent : {})
    this.props.browseAgent({search: {...search}, paging: paging})
  }

  componentDidUpdate() {
    const tokenAuth= localStorage.getItem('tokenAuth');
    const { code, text, progress, data }= this.props.agentStore.realmAgent ? this.props.agentStore.realmAgent : {}

    if (progress!=undefined && progress==1 && data.groupId=='Admin' && tokenAuth!='') {
        setTimeout(()=>{this.props.history.push('/admin')}, 1000)
    } else if (progress!=undefined && progress==1 && data.groupId=='Agent' && tokenAuth!='') {
      setTimeout(()=>{this.props.history.push('/agent')}, 1000)
    } else if (progress!=undefined && progress==1 && data.groupId=='Client' && tokenAuth!='') {
      setTimeout(()=>{this.props.history.push('/realm')}, 1000)
    } else if (progress!=undefined && progress==1 && (data.groupId=='Supervisor' || data.groupId=='Team Leader') && tokenAuth!='') {
      setTimeout(()=>{this.props.history.push('/spv')}, 1000)
    } else if (progress!=undefined && progress==1 && data.groupId=='Emulator' && tokenAuth!='') {
      setTimeout(()=>{this.props.history.push('/emulator')}, 1000)
    } else if (progress!=undefined && progress==1 && data.groupId=='Whatsapp' && tokenAuth!='') {
      setTimeout(()=>{this.props.history.push('/wasap')}, 1000)
    }
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.agentStore.browseAgent!==undefined ? this.props.agentStore.browseAgent : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight-125}}>
        <div style={{flex: '1'}}>
        <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell width='8'>
                  <Header as='h5' color='blue'>
                    AGENT
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='8'>
                  <Header as='h5' color='blue'>
                    PRIVILEGE
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell>
                        <Header as='h5' color={((item.isEnabled=='0' || item.isDeleted=='1') && 'brown') || 'black'}>
                          {item.avatar && <Image src={item.avatar} rounded size='mini' />}
                          {(item.isDeleted=='1' || !item.avatar) && <Icon name='user circle' color={(item.isEnabled=='1' && 'teal') || (item.isEnabled=='0' && 'grey') || (item.isDeleted=='1' || 'red')}/>}
                          <Header.Content>
                            <span style={item.isEnabled!='1'? {fontSize:'1.15em', textDecoration: 'line-through', color: '#A5673F'} : {}}>{item.account} / {item.name}</span>
                            <Header.Subheader>{item.client || 'Nobody'}</Header.Subheader>
                            <Header.Subheader>{(item.isEnabled=='0' ? 'Disabled' : '') +' '+ (item.isDeleted=='1' ? 'Deleted' : '')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5' style={{margin: '0 0 .5em 0'}}>
                          <Header.Content>
                            <Header.Subheader>Tagger</Header.Subheader>
                            {item.tagger || 'No tagger'}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Button disabled={item.isEnabled!='1'} icon labelPosition='left' onClick={this.props.realmAgent.bind(this, {agentId: item.agentId})}><Icon color='blue' name='rocket' /> Simulate</Button>
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        
        {paging!=undefined && paging.count>0 &&
            <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
              <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
               
                defaultActivePage={paging.page}
                totalPages={Math.ceil(paging.count/paging.size)}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          }
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AgentModeBrowse))