import React, { Component } from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Label, Dropdown } from 'semantic-ui-react'

import ModalContainer from '../../../libraries/common/ModalContainer';
import SignoutConfirmation from '../../../libraries/realm/halo/SignoutConfirmation';

import { Link } from 'react-router-dom'
import {withRouter} from "react-router-dom"

import { connect } from "react-redux"
import { checkToken, signOut } from "../../../actions/auth/authAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),
    signOut:(objParam) => dispatch(signOut(objParam))
  }
}

const stylePopup = {
  borderRadius: '0',
  opacity: 1,
  padding: '.5em .8em',
  fontSize: '1em',
  background: '#000',
  color: '#fff'
}
const styleButton={
  margin: '.3em 0em',
  border: 'none',
}


class RealmHeader extends Component {
  constructor(props) {
    super(props);
    this.state={
      signoutModal: false,
      signoutReasong: null,
    }
  }

  componentDidMount() {
    const tokenAuth= localStorage.getItem('tokenAuth');
    if (tokenAuth==undefined || tokenAuth=='') {
      this.props.history.push('/signin')
    }
  }

  componentDidUpdate() {
    if (localStorage.getItem('tokenAuth')=='') {
      this.props.history.push('/signin');
    }
  }
  
  openDrawer() {
    this.props.openDrawer()
  }

  openWindow(value) {
    this.props.openWindow(value)
  }

  closeModal() {
    this.setState({
      signoutModal: false,
      signoutReason: null,
    })
    this.props.closeModal();
  }

  onChangeSelect(e, v) {
    console.log(v.value)
    this.setState({
      signoutReason: v.value,
    })
  }

  doSignOut(values) {
    this.props.signOut(values)
    this.props.history.push('/signin')
  }
  
  render() {
    const { screenWidth,  screenHeight, mini, module }= this.props
    const { data }= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken : {}

    return (
      <div style={{marginLeft: '5em'}}>
        <Menu fixed='top'  style={{padding: '0', border: 'none', boxShadow: 'none', background : 'none'}}>
          {/* <div style={{display: 'flex', width: '100%', background : '#646464'}}> */}
          <div style={{display: 'flex', width: '100%', background : '#5c5341'}}>
            <div style={{padding: '.3em 0', textAlign: 'center'}}>
              <Label as='a' style={{border: 'none', background: 'none', padding: '.2 .75em', border: '0px solid rgba(100, 100, 100, .3)'}} onClick={this.openDrawer.bind(this)}>
                <Icon name='bars' style={{fontSize: '1.75em', margin: '0'}} color={mini==true ? 'black' : 'yellow'} />
              </Label>
            </div>
            <div style={{padding: '1em 0 0 1em'}}>
              <Header as='h5' color='black' inverted>
                <Header.Content>
                  Admin / ver. 2.3.01
                </Header.Content>
              </Header>
            </div>
            <div style={{margin: '0 0 0 1.5em'}}>
              <Popup style={stylePopup} position= 'bottom center' 
                trigger={<Label style={{margin: '.5em .5em', padding: '.7em .6em .5em .6em', background: 'rgba(200, 200, 200, .04)'}} as='a' onClick={this.props.openModule.bind(this, 'home')} ><Icon name='home' color={module=='home' && 'yellow' || 'black'} style={{margin: '0'}} /></Label>}
                content='Home'
                inverted
                on={['hover']}
              />
            </div>
            <div style={{margin: '0 0', borderLeft: '1px solid #444'}}>
              <Popup style={stylePopup} position= 'bottom center' 
                trigger={
                  <Label disabled={data && data.halopagiModules && !data.halopagiModules.includes('Stream')} style={{margin: '.5em .3em .5em .5em', padding: '.7em .6em .5em .6em', background: 'rgba(200, 200, 200, .04)'}} as='a' onClick={this.props.openModule.bind(this, 'outbound')}><Icon name='arrow left' color={module=='outbound' && 'yellow' || 'black'} style={{margin: '0'}} /></Label>
                }
                content='Outbound / Stream'
                inverted
                on={['hover']}
              />
              <Popup style={stylePopup} position= 'bottom center' 
                trigger={
                  <Label style={{margin: '.5em .5em .5em .3em', padding: '.7em .6em .5em .6em', background: 'rgba(200, 200, 200, .04)'}} as='a' 
                    onClick={data && data.halopagiModules && data.halopagiModules.includes('Flow') ? this.props.openModule.bind(this, 'inbound') : null}>
                    <Icon disabled={data && data.halopagiModules && !data.halopagiModules.includes('Flow')} name='arrow right' color={module=='inbound' && 'yellow' || 'black'} style={{margin: '0'}} />
                  </Label>
                }
                content='Inbound / FLow'
                inverted
                on={['hover']}
              />
            </div>
            <div style={{margin: '0 0', borderLeft: '1px solid #444'}}>
              <Popup style={stylePopup} position= 'bottom center' 
                trigger={
                  <Label style={{margin: '.5em .5em', padding: '.7em .6em .5em .6em', background: 'rgba(200, 200, 200, .04)'}} as='a' 
                    onClick={data && data.halopagiModules && data.halopagiModules.includes('Talk') ? this.props.openModule.bind(this, 'inapp') : null}>
                    <Icon disabled={data && data.halopagiModules && !data.halopagiModules.includes('Talk')} name='tablet alternate' color={module=='inapp' && 'yellow' || 'black'} style={{margin: '0'}} />
                  </Label>
                }
                content='In-App Call / Talk'
                inverted
                on={['hover']}
              />
            </div>
            <div style={{margin: '0 0', borderLeft: '1px solid #444'}}>
              <Popup style={stylePopup} position= 'bottom center' 
                trigger={
                  <Label style={{margin: '.5em .5em', padding: '.6em .6em .4em .6em', background: 'rgba(200, 200, 200, .04)'}} as='a' 
                    onClick={data && data.halopagiModules && data.halopagiModules.includes('Ring') ? this.props.openModule.bind(this, 'office') : null}>
                    <Icon disabled={data && data.halopagiModules && !data.halopagiModules.includes('Ring')} name='fax' color={module=='office' && 'yellow' || 'black'} style={{margin: '0', fontSize: '1.13em'}} />
                  </Label>
                }
                content='Office Phone / Ring'
                inverted
                on={['hover']}
              />
            </div>
            <div style={{margin: '0 0', borderLeft: '1px solid #444'}}>
              <Popup style={stylePopup} position= 'bottom center' 
                trigger={
                  <Label style={{margin: '.5em .5em', padding: '.7em .6em .5em .6em', background: 'rgba(200, 200, 200, .04)'}} as='a' 
                    onClick={data && data.halopagiModules && data.halopagiModules.includes('Middleware') ? this.props.openModule.bind(this, 'middleware') : null}>
                    <Icon disabled={data && data.halopagiModules && !data.halopagiModules.includes('Middleware')} name='code' color={module=='middleware' && 'yellow' || 'black'} style={{margin: '0'}} />
                  </Label>
                }
                content='API / Middleware'
                inverted
                on={['hover']}
              />
            </div>

            <div style={{margin: '0 0', borderLeft: '1px solid #444'}}>
              <Popup style={stylePopup} position= 'bottom center' 
                trigger={
                  <Label style={{margin: '.5em .5em', padding: '.7em .6em .5em .6em', background: 'rgba(200, 200, 200, .04)'}} as='a' 
                    onClick={data && data.halopagiModules && data.halopagiModules.includes('Agent Mode') ? this.props.openModule.bind(this, 'agent') : null}>
                    <Icon disabled={data && data.halopagiModules && !data.halopagiModules.includes('Agent Mode')} name='desktop' color={module=='agent' && 'yellow' || 'black'} style={{margin: '0'}} />
                  </Label>
                }
                content='Agent Mode'
                inverted
                on={['hover']}
              />
            </div>
            <div style={{flex: '1'}}>
            </div>
            {this.props.mini && 
            <div style={{paddingTop: '.6em', marginRight: '1em'}}>
              {module=='outbound' &&
              <Button.Group size='small' color='grey' style={{padding: '.5em .5em .4em .5em'}}>
                <Button icon style={{padding: '.5em .5em .5em .5em', borderRadius: '.25em 0 0 .25em', background: '#fbbd08', color: '#000'}}>
                {this.props.mode=='dashboard' && <Icon name='chart bar' />}
                {this.props.mode=='conversation' && <Icon name='comments outline' />}
                {this.props.mode=='activity' && <Icon name='envelope open outline' />}
                {this.props.mode=='event' && <Icon name='calendar alternate outline' />}
                {this.props.mode=='resource' && <Icon name='hdd outline' />}
                {this.props.mode=='dashboard' && ' Dashboard'}
                {this.props.mode=='conversation' && ' Interaction'}
                {this.props.mode=='activity' && ' Completed'}
                {this.props.mode=='event' && ' Campaigns'}
                {this.props.mode=='resource' && ' Resources'}
                </Button>
                <Dropdown className='button icon' style={{padding: '.5em .5em .5em .5em', marginRight: '.5em', borderRadius: '0 .25em .25em 0', background: '#444'}}>
                  <Dropdown.Menu style={{background: '#555'}}>
                    <Dropdown.Item>
                      <Popup style={stylePopup} header='Dashboard' content='Real time monitoring channel, traffic and system heartbeat.' position= 'left center' inverted
                      trigger={
                        <Header as='h5' inverted
                          onClick={this.openWindow.bind(this,'dashboard')}
                          color={this.props.mode=='dashboard' ? null : 'grey'}
                          style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='dashboard' ? '600' : '100'}}
                          >
                            <Header.Content>
                              <Icon name='chart bar' color={this.props.mode=='dashboard' ? 'yellow' : 'black'}/> Dashboard
                            </Header.Content>
                        </Header>
                      } />
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Popup style={stylePopup} header='Interaction' content='Engagement over multi channel as conversation activity.' position= 'left center' inverted
                      trigger={
                        <Header as='h5' inverted
                          onClick={this.openWindow.bind(this,'conversation')}
                          color={this.props.mode=='conversation' ? null : 'grey'}
                          style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='conversation' ? '600' : '100'}}>
                            <Header.Content>
                              <Icon name='comments outline' style={{fontSize: '1.6em'}} color={this.props.mode=='conversation' ? 'yellow' : 'black'}/> Interaction
                            </Header.Content>
                        </Header>
                      } />
                    </Dropdown.Item>

                    <Dropdown.Divider />
                    <Dropdown.Item>
                      <Popup style={stylePopup} header='Completed' content='Achieved conversation as 100% score of remark or feedback is covered.' position= 'left center' inverted
                      trigger={
                        <Header as='h5' inverted
                          onClick={this.openWindow.bind(this,'activity')}
                          color={this.props.mode=='activity' ? null : 'grey'}
                          style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='activity' ? '600' : '100'}}>
                            <Header.Content>
                              <Icon name='envelope open outline' style={{fontSize: '1.6em'}} color={this.props.mode=='activity' ? 'yellow' : 'black'}/> Completed
                            </Header.Content>
                        </Header>
                      } />
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Popup style={stylePopup} header='Campaign Setup' content='Campaign management and target allocation.' position= 'left center' inverted
                      trigger={
                        <Header as='h5' inverted
                          onClick={this.openWindow.bind(this,'event')}
                          color={this.props.mode=='event' ? null : 'grey'}
                          style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='event' ? '600' : '100'}}>
                            <Header.Content>
                              <Icon name='calendar alternate outline' style={{fontSize: '1.6em'}} color={this.props.mode=='event' ? 'yellow' : 'black'}/> Campaigns
                            </Header.Content>
                        </Header>
                      } />
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Popup style={stylePopup} header='Resources' content='Manage private and customer resources for campaigns usage.' position= 'left center' inverted
                      trigger={
                        <Header as='h5' inverted
                          onClick={this.openWindow.bind(this,'resource')}
                          color={this.props.mode=='resource' ? null : 'grey'}
                          style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='resource' ? '600' : '100'}}>
                            <Header.Content>
                              <Icon name='hdd outline' style={{fontSize: '1.6em'}} color={this.props.mode=='resource' ? 'yellow' : 'black'}/> Resources
                            </Header.Content>
                        </Header>
                      } />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Button.Group>
              }
              {module=='inbound' &&
              <Button.Group size='small' color='grey' style={{padding: '.5em .5em .4em .5em'}}>
                <Button icon style={{padding: '.5em .5em .5em .5em', borderRadius: '.25em 0 0 .25em', background: '#fbbd08', color: '#000'}}>
                {this.props.mode=='dashboard' && <Icon name='chart bar' />}
                {this.props.mode=='conversation' && <Icon name='comments outline' />}
                {this.props.mode=='interactive' && <Icon name='window restore outline' />}
                {this.props.mode=='phonewidget' && <Icon name='phone square' />}
                {this.props.mode=='event' && <Icon name='calendar alternate outline' />}
                {this.props.mode=='resource' && <Icon name='hdd outline' />}
                {this.props.mode=='dashboard' && ' Dashboard'}
                {this.props.mode=='conversation' && ' Interaction'}
                {this.props.mode=='interactive' && ' IVR Menu'}
                {this.props.mode=='phonewidget' && ' Phone Widget'}
                {this.props.mode=='event' && ' Campaigns'}
                {this.props.mode=='resource' && ' Resources'}
                </Button>
                <Dropdown className='button icon' style={{padding: '.5em .5em .5em .5em', marginRight: '.5em', borderRadius: '0 .25em .25em 0', background: '#444'}}>
                  <Dropdown.Menu style={{background: '#555'}}>
                    <Dropdown.Item>
                      <Popup style={stylePopup} header='Dashboard' content='Real time monitoring channel, traffic and system heartbeat.' position= 'left center' inverted
                      trigger={
                        <Header as='h5' inverted
                          onClick={this.openWindow.bind(this,'dashboard')}
                          color={this.props.mode=='dashboard' ? null : 'grey'}
                          style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='dashboard' ? '600' : '100'}}
                          >
                            <Header.Content>
                              <Icon name='chart bar' color={this.props.mode=='dashboard' ? 'yellow' : 'black'}/> Dashboard
                            </Header.Content>
                        </Header>
                      } />
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Popup style={stylePopup} header='Interaction' content='Engagement over multi channel as conversation activity.' position= 'left center' inverted
                      trigger={
                        <Header as='h5' inverted
                          onClick={this.openWindow.bind(this,'conversation')}
                          color={this.props.mode=='conversation' ? null : 'grey'}
                          style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='conversation' ? '600' : '100'}}>
                            <Header.Content>
                              <Icon name='comments outline' style={{fontSize: '1.6em'}} color={this.props.mode=='conversation' ? 'yellow' : 'black'}/> Interaction
                            </Header.Content>
                        </Header>
                      } />
                    </Dropdown.Item>

                    <Dropdown.Divider />
                    <Dropdown.Item>
                      <Popup style={stylePopup} header='Interactive Menu' content='Interactive menu based on IVR automation or dispatch to specific agent / workgroup.' position= 'left center' inverted
                      trigger={
                        <Header as='h5' inverted
                          onClick={this.openWindow.bind(this,'interactive')}
                          color={this.props.mode=='interactive' ? null : 'grey'}
                          style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='interactive' ? '600' : '100'}}>
                            <Header.Content>
                              <Icon name='window restore outline' style={{fontSize: '1.6em'}} color={this.props.mode=='interactive' ? 'yellow' : 'black'}/> IVR Menu
                            </Header.Content>
                        </Header>
                      } />
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Popup style={stylePopup} header='Phone Widget' content='Phone widget to simulate inbound call for your interactive menu.' position= 'left center' inverted
                      trigger={
                        <Header as='h5' inverted
                          onClick={this.openWindow.bind(this,'phonewidget')}
                          color={this.props.mode=='phonewidget' ? null : 'grey'}
                          style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='phonewidget' ? '600' : '100'}}>
                            <Header.Content>
                              <Icon name='phone square' style={{fontSize: '1.6em'}} color={this.props.mode=='phonewidget' ? 'yellow' : 'black'}/> Phone Widget
                            </Header.Content>
                        </Header>
                      } />
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Popup style={stylePopup} header='Campaign Setup' content='Campaign management and target allocation.' position= 'left center' inverted
                      trigger={
                        <Header as='h5' inverted
                          onClick={this.openWindow.bind(this,'event')}
                          color={this.props.mode=='event' ? null : 'grey'}
                          style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='event' ? '600' : '100'}}>
                            <Header.Content>
                              <Icon name='calendar alternate outline' style={{fontSize: '1.6em'}} color={this.props.mode=='event' ? 'yellow' : 'black'}/> Campaigns
                            </Header.Content>
                        </Header>
                      } />
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Popup style={stylePopup} header='Resources' content='Manage private and customer resources for campaigns usage.' position= 'left center' inverted
                      trigger={
                        <Header as='h5' inverted
                          onClick={this.openWindow.bind(this,'resource')}
                          color={this.props.mode=='resource' ? null : 'grey'}
                          style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='resource' ? '600' : '100'}}>
                            <Header.Content>
                              <Icon name='hdd outline' style={{fontSize: '1.6em'}} color={this.props.mode=='resource' ? 'yellow' : 'black'}/> Resources
                            </Header.Content>
                        </Header>
                      } />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Button.Group>
              }
            </div>}
            <div style={{textAlign: 'right', paddingTop: '.25em', marginRight: '1em'}}>
              <Popup style={stylePopup} position= 'bottom right' 
                trigger={<Button onClick={this.props.openModal.bind(this,'profile')} basic={this.props.modal!='profile'} color={this.props.modal=='profile'? 'yellow' : null} style={styleButton} icon><Icon name='user outline' style={{fontSize: '.9em', color: this.props.modal=='profile' ? '#000' : '#fff'}}/></Button>}
                content='My Account'
                inverted
                on={['hover']}
              />
              {/* <Popup style={stylePopup}
                trigger={<Button onClick={this.openWindow.bind(this,'account')} basic={this.props.mode!='account'} color={this.props.mode=='account'? 'yellow' : null} style={styleButton} icon><Icon name='user' style={{color: this.props.mode=='account' ? '#000' : '#fff'}}/></Button>}
                content='Account'
                inverted
                on={['hover']}
              /> */}
              <Popup style={stylePopup} position= 'bottom right' 
                trigger={<Button onClick={this.props.openModal.bind(this,'message')} basic={this.props.modal!='message'} color={this.props.modal=='message'? 'yellow' : null} style={styleButton} icon><Icon name='bell' style={{color: this.props.modal=='message' ? '#000' : '#fff'}} /> <span style={{color: this.props.modal=='message' ? '#000' : '#fff'}}></span></Button>}
                content='Notification'
                inverted
                on={['hover']}
              />
            </div>
            <div style={{textAlign: 'right', paddingTop: '.25em', marginRight: '1em'}}>
              <Popup style={stylePopup} position= 'bottom right' 
                trigger={<Button onClick={this.props.openModal.bind(this,'audit')} basic={this.props.modal!='audit'} color={this.props.modal=='audit' ? 'yellow' : null} style={styleButton} icon><Icon name='paw' style={{color: this.props.modal=='audit' ? '#000' : '#fff'}} /></Button>}
                header='Audit Trail'
                content='Monitor and trace all user activity based on logging level.'
                inverted
                on={['hover']}
              />
              <Popup style={stylePopup} position= 'bottom right' 
                trigger={<Button onClick={this.props.openModal.bind(this,'account')} basic={this.props.modal!='account'} color={this.props.modal=='account' ? 'yellow' : null} style={styleButton} icon><Icon name='user circle' style={{color: this.props.modal=='account' ? '#000' : '#fff'}} /></Button>}
                header='Agents'
                content='Manage Agents Account, create, enabled or disabled agent, your seat occupancy bills will be based on your agent status.'
                inverted
                on={['hover']}
              />
              <Popup style={stylePopup} position= 'bottom right' 
                trigger={<Button onClick={this.props.openModal.bind(this,'setting')} basic={this.props.modal!='setting'} color={this.props.modal=='setting' ? 'yellow' : null} style={styleButton} icon><Icon name='setting' style={{color: this.props.modal=='setting' ? '#000' : '#fff'}} /></Button>}
                header='Setting'
                content='Set custom preference for your outbound campaign behaviour, make sure you khow how.'
                inverted
                on={['hover']}
              />
              {/* <Popup style={stylePopup}
                trigger={<Button onClick={this.openWindow.bind(this,'provider')} basic={this.props.mode!='provider'} color={this.props.mode=='provider'? 'yellow' : null} style={styleButton} icon><Icon name='heart outline'/></Button>}
                content='Provider'
                inverted
                on={['hover']}
              />
              <Popup style={stylePopup}
                trigger={<Button onClick={this.openWindow.bind(this,'api')} basic={this.props.mode!='api'} color={this.props.mode=='api'? 'yellow' : null} style={styleButton} icon><Icon name='rocket' /></Button>}
                content='API'
                inverted
                on={['hover']}
              /> */}
              <Popup style={stylePopup} position= 'bottom right' 
                trigger={
                  <Button 
                  //onClick={()=>this.doSignOut()} 
                  onClick={()=>this.setState({signoutModal: true})} 
                  color={this.props.mode=='signout'? 'yellow' : 'red'} style={{...styleButton, marginLeft: '3em'}} icon><Icon name='sign-out' style={{color: this.props.mode=='signout' ? '#000' : '#fff'}} /></Button>
                }
                content='Signout'
                inverted
                on={['hover']}
              />
              {/* <Button onClick={()=>this.doSignOut()} style={{marginRight: '1.15em'}} size='tiny' color='red'>Sign Out</Button> */}
            </div>
          </div>
        </Menu>

        {this.state.signoutModal && <ModalContainer size='mini' content={
          <SignoutConfirmation onClick={()=>this.doSignOut({actionReason: this.state.signoutReason})} onChangeSelect={this.onChangeSelect.bind(this)} state={this.state}/>
        } closeModal={this.closeModal.bind(this)} /> }
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RealmHeader))