import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
} from 'semantic-ui-react'

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse } from "date-fns"
import {  } from "../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { browseHandling, statusHandling } from "../../actions/service/handlingAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    handlingStore: state.handlingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseHandling:(objParam) => dispatch(browseHandling(objParam)),
    statusHandling:(objParam) => dispatch(statusHandling(objParam)),
  }
}

class TickethandlingBrowse extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remark: false,
      expand: false,
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.handlingStore.browseHandling!==undefined ? this.props.handlingStore.browseHandling : {})
    this.props.browseHandling(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { ticketId, clientId }= this.props
    const { search, paging }= (this.props.handlingStore.browseHandling!==undefined ? this.props.handlingStore.browseHandling : {})
    this.props.browseHandling({search: {ticketId: ticketId, clientId: clientId}, paging: paging})
  }

  onChangeStatus(id, status, e) {
    // console.log(id, status, e)
    this.props.statusHandling({handlingId:id, isEnabled: status})
  }
  setExpand() {
    const { expand }= this.state
    this.setState({
      expand: !expand
    })
  }
  setRemark() {
    const { remark }= this.state
    this.setState({
      remark: !remark
    })
  }

  render() {
    //console.log(this.props.handlingStore.browseHandling)
    const { expand, remark }= this.state
    const { screenWidth, screenHeight, modal }= this.props
    const { data, search, paging }= (this.props.handlingStore.browseHandling!==undefined ? this.props.handlingStore.browseHandling : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1', padding: '0'}}>
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                {!modal &&
                <Table.HeaderCell></Table.HeaderCell>}
                <Table.HeaderCell width={5}>
                  <Header as='h5' color='blue'>ACTION</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={5}>
                  <Header as='h5' color='blue'>TICKET</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  <Header as='h5' color='blue'>
                    <Icon name={expand ? 'angle double right' : 'angle double left'} color={expand ? 'red' : 'red'} style={{fontSize: '1.5em', cursor: 'pointer'}} onClick={this.setExpand.bind(this)}/>
                    <Header.Content>DURATION</Header.Content>
                  </Header>
                </Table.HeaderCell>
                {expand && <>
                <Table.HeaderCell width='1'>
                  <Header as='h5' color='blue'>DISPATCH</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='1'>
                  <Header as='h5' color='blue'>HANDLED</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>FINISH</Header>
                </Table.HeaderCell>
                </>}
                {!expand && 
                <Table.HeaderCell width={4} style={{background: remark ? '#e7feff' : '#fff2f2'}}>
                <Header as='h5' color='blue'>RESULT</Header>
                </Table.HeaderCell>}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      {!modal &&
                      <Table.Cell textAlign='center'>
                        <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.handlingId, item.ticketId, item.clientId)}/>
                      </Table.Cell>}
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            <Header.Subheader>{item.actionType} / {item.actionHandler}</Header.Subheader>
                            {item.actionActivity}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            <Header.Subheader>{item.ticketNo} / {item.segment} cust:{item.custName}</Header.Subheader>
                            {item.information}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.estDuration} hour
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      {expand && <>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.dispatchStamp!=null && format(parse(item.dispatchStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.dispatchStamp!=null && format(parse(item.dispatchStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.actionStamp!=null && format(parse(item.actionStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.actionStamp!=null && format(parse(item.actionStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.finishStamp!=null && format(parse(item.finishStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.finishStamp!=null && format(parse(item.finishStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      </>}
                      {!expand && 
                      <Table.Cell>
                        <Header as='h5'>
                          <div style={{background: (item.status=='1' && '#84dbff') || (item.status=='0' && '#ffb3b3') || '#ddd', textAlign: 'right', display: 'inline-block', width: '4em', padding: '.5em', marginRight: '.5em'}}>{item.progress} %</div>
                          <Header.Content>
                            {item.status}
                            <Header.Subheader>{item.reason || '-'}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>}
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TickethandlingBrowse)
