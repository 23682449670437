import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'

import MenuLeftOffice from '../../../libraries/realm/halo/MenuLeftOffice'
import ModalContainer from '../../../libraries/common/ModalContainer';

import DashboardBody from '../ring/DashboardBody';
import TunnelBody from '../ring/TunnelBody';
import MauBody from '../ring/MauBody';
import WidgetBody from '../ring/WidgetBody';

import { connect } from "react-redux"
import { checkToken } from "../../../actions/auth/authAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),
  }
}

class RealmOfficeBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
      mode: 'dashboard',
    }
  }

  componentDidMount() {
    this.props.checkToken();
  }

  componentDidUpdate() {
    
  }

  render() {
    const { mode, module, screenWidth, screenHeight, mini, modal }= this.props
    const { data }= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken : {}
    
    return (
      <div>
        <div>
            <div style={{display: 'flex'}}>
              <div style={{background: '#ccc', minHeight: screenHeight-300, borderRight: '1px solid #fff'}}>
                <MenuLeftOffice openWindow={this.props.openWindow.bind(this)} mode={this.props.mode} screenHeight={screenHeight} screenWidth={screenWidth} module={module} mini={mini} setModule={this.props.setModule.bind(this)}/>
              </div>
              <div style={{flex: '1', marginLeft: mini ? '2px' : '80px', marginRight: '2px', padding: '.85em .5em'}}>
              
                {mode==='dashboard' && <DashboardBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
                
                {mode==='call tunnel' && <TunnelBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
                {mode==='active user' && <MauBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
                
                {mode==='widget' && <WidgetBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
                {/* {mode==='web widget' && <WebwidgetBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
                {mode==='android widget' && <AndroidwidgetBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
                {mode==='IOS widget' && <IOSwidgetBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />} */}
              </div>
            </div>
        </div>
      </div>
    )
  }
}

// export default RealmOfficeBody
export default connect(mapStateToProps, mapDispatchToProps)(RealmOfficeBody)