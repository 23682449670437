import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import DocumentBrowse from '../../libraries/halo/DocumentBrowse'
import DocumentForm from '../../libraries/halo/DocumentForm'
import DocumentSearch from '../../libraries/halo/DocumentSearch'


class DocumentBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        documentId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, documentId: id})
    }

    render() {
      const {mode, documentId}= this.state
      const {screenWidth}= this.props
      
      return(
        <Container fluid>
          <div>
            <DocumentSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <DocumentBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} />}
              {mode==2 && <DocumentForm mode={mode} documentId={documentId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <DocumentForm mode={mode} documentId={0} setMode={this.setMode.bind(this)} />}
            </div>
          </div>
        </Container>
      )
    }
}

export default DocumentBody
