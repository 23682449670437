import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
} from 'semantic-ui-react'

import numeral from "numeral"
import { parse, format } from "date-fns"

import { connect } from "react-redux"
import { browseMiddleware } from "../../actions/middleware/middlewareAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    middlewareStore: state.middlewareStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseMiddleware:(objParam) => dispatch(browseMiddleware(objParam)),
  }
};


class APIInventoryBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.middlewareStore.browseMiddleware!==undefined ? this.props.middlewareStore.browseMiddleware : {})
    this.props.browseMiddleware(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.middlewareStore.browseMiddleware!==undefined ? this.props.middlewareStore.browseMiddleware : {})
    this.props.browseMiddleware({search: {...search}, paging: paging})
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.middlewareStore.browseMiddleware!==undefined ? this.props.middlewareStore.browseMiddleware : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight-125}}>
        <div style={{flex: '1'}}>
        <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell width='4'>
                  <Header as='h5' color='blue'>
                    CLIENT
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='12'>
                  <Header as='h5' color='blue'>
                    API INVENTORY
                  </Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell textAlign='center'>
                        <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.settingId, item)}/>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.company}
                            <Header.Subheader>{item.city}, {item.province}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Label.Group>
                          <Label size='large' style={{minWidth: '7.5em', padding: '.5em .7em', margin: '.5em', borderRadius: '1em'}}><Icon color={item.isCampaign=='1' ? 'red' :  null} name={(item.isCampaign=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0'}} />Campaign</Label> 
                          <Label size='large' style={{minWidth: '7.5em', padding: '.5em .7em', margin: '.5em', borderRadius: '1em'}}><Icon color={item.isTarget=='1' ? 'red' :  null} name={(item.isTarget=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0'}} />Target</Label>
                          <Label size='large' style={{minWidth: '7.5em', padding: '.5em .7em', margin: '.5em', borderRadius: '1em'}}><Icon color={item.isAgent=='1' ? 'red' :  null} name={(item.isAgent=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0'}} />Agent</Label>
                          <Label size='large' style={{minWidth: '7.5em', padding: '.5em .7em', margin: '.5em', borderRadius: '1em'}}><Icon color={item.isWidget=='1' ? 'red' :  null} name={(item.isWidget=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0'}} />Widget</Label>
                          <Label size='large' style={{minWidth: '7.5em', padding: '.5em .7em', margin: '.5em', borderRadius: '1em'}}><Icon color={item.isWebhook=='1' ? 'red' :  null} name={(item.isWebhook=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0'}} />Webhook</Label>
                          <Label size='large' style={{minWidth: '7.5em', padding: '.5em .7em', margin: '.5em', borderRadius: '1em'}}><Icon color={item.isReport=='1' ? 'red' :  null} name={(item.isReport=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0'}} />Reporting</Label>
                          <Label size='large' style={{minWidth: '7.5em', padding: '.5em .7em', margin: '.5em', borderRadius: '1em'}}><Icon color={item.isInteraction=='1' ? 'red' :  null} name={(item.isInteraction=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0'}} />Interaction</Label>
                          <Label size='large' style={{minWidth: '7.5em', padding: '.5em .7em', margin: '.5em', borderRadius: '1em'}}><Icon color={item.isCommunication=='1' ? 'red' :  null} name={(item.isCommunication=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0'}} />Comm</Label>
                        </Label.Group>
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        
        {paging!=undefined && paging.count>0 &&
            <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
              <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
                defaultActivePage={paging.page}
                totalPages={Math.ceil(paging.count/paging.size)}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(APIInventoryBrowse)
