import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Table } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import DismissableMessage from '../common/DismissableMessage'
import ModalContainer from '../common/ModalContainer';
import {genderOptions, maritalOptions, occupationOptions, revenueOptions, expenseOptions, homestayOptions, vehicleOptions}  from '../common/StaticMasterData'
import MenuTabular from '../common/MenuTabular'

import TicketSearch from './TicketSearch'
import TicketBrowse from './TicketBrowse'
import TicketForm from './TicketForm'
import TickethandlingSearch from './TickethandlingSearch'
import TickethandlingBrowse from './TickethandlingBrowse'
import TickethandlingForm from './TickethandlingForm'

import { SubmissionError, Field, reduxForm, getFormValues } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, NumberField, DatePickerField, SelectField } from '../validation/componentrsui'
import { required, confirmedPassword, phoneNumber, number, minValue, email, phoneNumberID } from '../validation/validation'

import { connect } from "react-redux"
import { resetCustomer, getCustomer,updateCustomer } from "../../actions/halo/customerAction"
import { resetOmniphone } from "../../actions/stream/omniphoneAction"
import { listRemark } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    customerStore: state.customerStore,
    omniphoneStore: state.omniphoneStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listRemark:(objParam) => dispatch(listRemark(objParam)),

    resetCustomer:(objParam) => dispatch(resetCustomer(objParam)),
    getCustomer:(objParam) => dispatch(getCustomer(objParam)),
    updateCustomer:(objParam) => dispatch(updateCustomer(objParam)),

    resetOmniphone:(objParam) => dispatch(resetOmniphone(objParam)),
  }
}

const panes = (param)=>{
  return([
    {
      menuItem: { key: '0', icon: 'ticket alternate', content: 'Cust Ticket' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '1', icon: 'user circle', content: 'Personal' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '2', icon: 'clone outline', content: 'Subscription' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '3', icon: 'file alternate outline', content: 'Transaction' },
      render: () => 
      <></>
    },
    // {
    //   menuItem: { key: '4', icon: 'snowflake outline', content: 'Behaviour' },
    //   render: () => 
    //   <></>
    // },
  ])
};

class CustomerForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      tab: 0,

      isPersonal: false,
      isProfile: true,
      modeTicket: 1,
      modeTicketHandling: 1,
    }
  }

  componentDidMount(){
    // this.props.listRemark({remarkType: 'customer'}) BIKIN ERROR

    this.props.resetCustomer(this.props.mode)
    this.props.getCustomer({customerId: this.props.customerId})
  }

  componentDidUpdate(prevProps) {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.customerStore.updateCustomer!=undefined ? this.props.customerStore.updateCustomer : {}) : (this.props.customerStore.createCustomer!=undefined ? this.props.customerStore.createCustomer : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{
        this.props.resetOmniphone();
        this.props.hasSaved();
      }, 3000 )
    } else {
      if (prevProps.customerId!=this.props.customerId) {
        this.props.getCustomer({customerId: this.props.customerId})
      }
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { media } = this.props
    this.props.updateCustomer({...values});
    this.props.remarkOmniphone({...values, voices: null, appointments: null });

    this.props.endConversation(media, 
      (media=='Chat' && {
        command: 'end_by_agent',
        visitorId: this.props.visitorId,
        agentId: this.props.agentId,
        clientId: this.props.clientId,
      })
    )
  }

  setMode(e, d) {
    // console.log(d.activeIndex)
    this.setState({tab: d.activeIndex})
  }

  setModeTicket(mode, id, clientId) {
    this.setState({modeTicket: mode, ticketId: id, clientId: clientId})
  }

  setModeTicketHandling(mode, id, ticketId, clientId) {
    // console.log(mode, id, ticketId, clientId)
    this.setState({modeTicketHandling: mode, handlingId: id, ticketId: ticketId, clientId: clientId})
  }

  closeModalTicket() {
    this.setState({
      modeTicket: 1, //1: browse, 2: edit, 3: insert, 4: delete
      modeTicketHandling: 1,
      // ticketId: 0,
    });
  }

  closeModalTicketHandling() {
    this.setState({
      modeTicket: 4,
      modeTicketHandling: 1,
    });
  }

  setExpand(menu) {
    const state= {}
    state[menu]= !this.state[menu]
    this.setState(state)
  }

  render() {
    const { tab, modeTicket, modeTicketHandling, handlingId, ticketId, clientId }= this.state
    const { screenWidth, screenHeight, error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.customerStore.updateCustomer!=undefined ? this.props.customerStore.updateCustomer : {}) : (this.props.customerStore.createCustomer!=undefined ? this.props.customerStore.createCustomer : {}))
    const { data }= this.props.customerStore.getCustomer!=undefined ? this.props.customerStore.getCustomer : {}

    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})
    
    return (
      <div style={{padding: '1em 1em', ...style}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              width={8}
              label='Name'
              placeholder='Full Name' />
              <Field name='custRef' component={InputField}
              width={4}
              label='CIF / Cust. Ref'
              placeholder='Customer Id / Ref.' />
              <Field name='contractRef' component={InputField}
              width={4}
              label='Contract Ref.'
              placeholder='Subscriber Id / Contract Ref.' />
            </Form.Group>
          </Segment>
          
          <Divider hidden />
          <MenuTabular panes={panes({})} style={{width: '100%', marginBottom: '-1.2em', marginTop: '1em'  }} renderActiveOnly={true} onTabChange={this.setMode.bind(this)}/>
          
          {tab==0 && <div style={{margin: '.3em -1em .5em -1em'}}>
            {(modeTicket==1 || modeTicket==4) && <div>
              <TicketSearch screenWidth={screenWidth} screenHeight={screenHeight-125} 
              media={this.props.media} customerId={this.props.customerId} visitorId={this.props.visitorId} agentId={this.props.userId} clientId={this.props.clientId} 
              setMode={this.setModeTicket.bind(this)} />
              <TicketBrowse 
              screenWidth={screenWidth} screenHeight={screenHeight-125} 
              media={this.props.media} customerId={this.props.customerId} visitorId={this.props.visitorId} agentId={this.props.userId} clientId={this.props.clientId} 
              setMode={this.setModeTicket.bind(this)} 
              />
            </div>}
            {modeTicket==2 && <ModalContainer size='large' 
              content={
                <TicketForm mode={modeTicket} ticketId={ticketId} clientId={clientId} setMode={this.setModeTicket.bind(this)} />
              } 
              closeModal={this.closeModalTicket.bind(this)} 
            />}
            {modeTicket==3 && <ModalContainer size='large' 
              content={
                <TicketForm mode={modeTicket} ticketId={0} clientId={0} setMode={this.setModeTicket.bind(this)} />
              } 
              closeModal={this.closeModalTicket.bind(this)} 
            />}
            {modeTicket==4 && modeTicketHandling<=1 && <ModalContainer size='large' 
              content={
                <div>
                  <TickethandlingSearch mode={modeTicketHandling} ticketId={ticketId} clientId={clientId}
                  setMode={this.setModeTicketHandling.bind(this)} />
                  <TickethandlingBrowse ticketId={ticketId} clientId={clientId}
                  setMode={this.setModeTicketHandling.bind(this)} 
                  closeModal={this.closeModalTicket.bind(this)} />
                </div>
              } 
              closeModal={this.closeModalTicket.bind(this)} 
            />}
            {modeTicket==4 && modeTicketHandling>1 && <ModalContainer size='large' 
              content={
                <div>
                  <TickethandlingSearch mode={modeTicketHandling} ticketId={ticketId} clientId={clientId}
                  setMode={this.setModeTicketHandling.bind(this)} />
                  <TickethandlingForm mode={modeTicketHandling} handlingId={handlingId} ticketId={ticketId} clientId={clientId}
                  setMode={this.setModeTicketHandling.bind(this)} 
                  closeModal={this.closeModalTicketHandling.bind(this)} />
                </div>
              } 
              closeModal={this.closeModalTicketHandling.bind(this)} 
            />}
          </div>}
          {tab==1 && <>
            <Segment>
            <div style={{display: 'flex', marginBottom: '.5em'}}>
              <div style={{flex: '1'}}>
                <Header as='h5'>
                  <Icon name='fax' style={{fontSize: '1em'}}/>
                  <Header.Content>Contact</Header.Content>
                </Header>
              </div>
              <div>
                <Button type='button' icon onClick= {this.setExpand.bind(this, 'isProfile')} style={{padding: '0', background: 'none', border: 'none'}}>
                <Icon color='red' name={this.state.isProfile ? 'toggle on' : 'toggle off'} style={{transform: 'rotate(90deg)', fontSize: '1.3em', cursor: 'pointer', padding: '0'}} />
                </Button>
              </div>
            </div>
            {this.state.isProfile && <>
            <Form.Group widths='equal'>
              <Field name='mobile' component={InputField}
              label='Mobile'
              validate={[phoneNumberID]}
              placeholder='Handphone' />
              <Field name='homePhone' component={InputField}
              label='Home Phone'
              validate={[phoneNumberID]}
              placeholder='Home Phone' />
              <Field name='officePhone' component={InputField}
              label='Office Phone'
              validate={[phoneNumberID]}
              placeholder='Office Phone' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='email' component={InputField}
              label='Email'
              validate={[email]}
              placeholder='Email' />
              <Field name='whatsapp' component={InputField}
              label='Whatsapp'
              validate={[phoneNumberID]}
              placeholder='Whatsapp' />
              <Field name='telegram' component={InputField}
              label='Telegram'
              placeholder='Telegram' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='twitter' component={InputField}
              fluid
              label='Twitter'
              placeholder='Twitter' />
              <Field name='instagram' component={InputField}
              fluid
              label='Instagram'
              placeholder='Instagram' />
              <Field name='facebook' component={InputField}
              fluid
              label='Facebook'
              placeholder='Facebook' />
            </Form.Group>
            </>}
          </Segment>
          <Segment>
            <div style={{display: 'flex', marginBottom: '.5em'}}>
              <div style={{flex: '1'}}>
                <Header as='h5'>
                  <Icon name='user circle' style={{fontSize: '1em'}}/>
                  <Header.Content>Personal</Header.Content>
                </Header>
              </div>
              <div>
                <Button type='button' icon onClick= {this.setExpand.bind(this, 'isPersonal')} style={{padding: '0', background: 'none', border: 'none'}}>
                <Icon color='red' name={this.state.isPersonal ? 'toggle on' : 'toggle off'} style={{transform: 'rotate(90deg)', fontSize: '1.3em', cursor: 'pointer', padding: '0'}} />
                </Button>
              </div>
            </div>
            {this.state.isPersonal && <>
            <Form.Group widths='equal'>
              <Field name='gender' component={SelectField}
              fluid
              options={genderOptions}
              label='Gender'
              placeholder='L/P' />
              <Field name='birthDate' component={DatePickerField}
              label='Birth Date'
              placeholder='Birth Date' />
              <Field name='children' component={InputField}
              label='Children'
              placeholder='Children' />
              <Field name='marital' component={SelectField}
              fluid
              options={maritalOptions}
              label='Marital'
              placeholder='Marital' />
            </Form.Group>
            </>}
          </Segment>
          {this.state.isPersonal &&
          <Segment>
            <Header as='h5'>
              <Icon name='map marker alternate' style={{fontSize: '1em'}}/>
              <Header.Content>Address</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='address' component={InputField}
              fluid
              placeholder='Address' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='city' component={InputField}
              fluid
              placeholder='City' />
              <Field name='province' component={InputField}
              fluid
              placeholder='Province' />
              <Field name='country' component={InputField}
              fluid
              placeholder='Country' />
            </Form.Group>
          </Segment>}
          {this.state.isPersonal &&
          <Segment>
            <Header as='h5'>
              <Icon name='closed captioning outline' style={{fontSize: '1em'}}/>
              <Header.Content>Financial</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='occupation' component={SelectField}
              options={occupationOptions}
              label='Occupation'
              placeholder='Occupation' />
              <Field name='revenue' component={SelectField}
              options={revenueOptions}
              label='Revenue'
              placeholder='Revenue' />
              <Field name='expense' component={SelectField}
              options={expenseOptions}
              label='Expense'
              placeholder='Expense' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='homestay' component={SelectField}
              options={homestayOptions}
              label='Homestay'
              placeholder='Homestay' />
              <Field name='vehicle' component={SelectField}
              options={vehicleOptions}
              label='Vehicle'
              placeholder='Vehicle' />
              <Field name='creditcard' component={InputField}
              label='Credit Card'
              placeholder='Credit Card' />
            </Form.Group>
          </Segment>}
          </>}

          {tab==2 && <>
          <Segment>
            <Header as='h5'>
              <Icon name='clone outline' style={{fontSize: '1em'}}/>
              <Header.Content>Subscription</Header.Content>
            </Header>
            <Table unstackable selectable columns='16' celled striped basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell width='8'>
                    <Header as='h5' color='blue'>
                      PRODUCT / SERVICE
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='4'>
                    <Header as='h5' color='blue'>
                      SUBSCRIPTION
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='4'>
                    <Header as='h5' color='blue'>
                      TOTAL VALUE
                    </Header>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { data && data.subscriptions ? data.subscriptions.map((item,i)=>{
                    return(
                      <Table.Row key={++i}>
                        <Table.Cell>{i}</Table.Cell>
                        <Table.Cell>
                          <Header as='h5'>
                            <Header.Content>
                              {item.product && item.product.name}
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5'>
                            <Header.Content>
                              {item.subsDate!=null ? format(parse(item.subsDate, 'yyyy-MM-dd', new Date()), 'dd / MMM, yyyy') : ''}
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell style={{textAlign: 'right'}}>
                          <Header as='h5'>
                            <Header.Content>
                              {}
                              Rp. {numeral(item.amount || 0).format('0,0')}
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                      </Table.Row>
                    )
                  }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
                }
              </Table.Body>
            </Table>
          </Segment>
          </>}

          {tab==3 && <>
          <Segment>
            <Header as='h5'>
              <Icon name='file alternate outline' style={{fontSize: '1em'}}/>
              <Header.Content>Transaction</Header.Content>
            </Header>
            <Table unstackable selectable columns='16' celled striped basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell width='5'>
                    <Header as='h5' color='blue'>
                      CAMPAIGN
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='4'>
                    <Header as='h5' color='blue'>
                      DELIVERY
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='4'>
                    <Header as='h5' color='blue'>
                      TOT. ATTEMPT
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='3'>
                    <Header as='h5' color='blue'>
                      RESULT
                    </Header>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { data && data.transacts ? data.transacts.map((item,i)=>{
                    return(
                      <Table.Row key={++i}>
                        <Table.Cell>{i}</Table.Cell>
                        <Table.Cell>
                          <Header as='h5'>
                            <Header.Content>
                              <Header.Subheader>{item.campaign.campaignType}</Header.Subheader>
                              {item.campaign.name}
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            <Header.Subheader>{item.campaign.startTime}-{item.campaign.endTime}</Header.Subheader>
                            {format(parse(item.campaign.startDate, 'yyyy-MM-dd', new Date()), 'dd / MMM')} - {format(parse(item.campaign.endDate, 'yyyy-MM-dd', new Date()), 'dd / MMM, yyyy')}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                        <Table.Cell>
                          <Header as='h5'>
                            <Header.Content>
                              {item.totAttempt==0 ? '0%' : (item.totEngaged / item.totAttempt)} CAR
                              <Header.Subheader>{item.totAttempt} Attempts, {item.totDelivered} Delivered, {item.totEngaged} Contacted</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5'>
                            <Header.Content>
                              {item.result}
                              <Header.Subheader>{item.transactStamp!=null ? format(parse(item.subsDate, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM, yyyy') : 'No recent date'}</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                      </Table.Row>
                    )
                  }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
                }
              </Table.Body>
            </Table>
          </Segment>
          </>}

          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Customer' : 'Insert Customer') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.customerStore.getCustomer!=undefined && state.customerStore.getCustomer.progress==1 ? state.customerStore.getCustomer.data : {}),
      formValues: getFormValues('CustomerForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    }),
    { load: getCustomer }
  )
  (reduxForm({
    form: 'CustomerForm',	// a unique identifier for this form
  })(CustomerForm)
  )
)
