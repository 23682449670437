import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

import AdminHeader from "./AdminHeader"
import AdminFooter from "./AdminFooter"
import AdminBody from "./AdminBody"

class Admin extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      screenHeight: 800,
      screenWidth: 1024,
      
      module: 'dashboard',
      mode: 'statistic',

      openDrawer: true,
    }
    this.updateDimension = this.updateDimension.bind(this)
  }

  componentDidMount() {
    this.updateDimension()
    window.addEventListener('resize', this.updateDimension)
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimension)
    this.context.socket && this.context.socket.close();
  }
  
  updateDimension() {
    this.setState({ ...this.state, screenWidth: window.innerWidth, screenHeight: window.innerHeight })
  }
  
  openModal(value, params, module) {
    // console.log(value, params)
    this.setState({
      modal: value,
      params: params,
    })
  }

  closeModal(params) {
    // console.log(params)
    this.setState({
      modal: null,
      params: params,
    })
  }

  openWindow(mode, module) {
    this.setState({
      module: module || '',
      mode: mode,
      // openDrawer: true
    })
  }
  
  openDrawer() {
    const { openDrawer }= this.state
    this.setState({
      openDrawer: !openDrawer
    })
  }

  render() {
    const {screenHeight, screenWidth, mode, module, openDrawer, modal, params,}= this.state
    
    return(
        <Container fluid>
          <AdminHeader screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.openWindow.bind(this)} module={module} mode={mode} modal={modal}  openModal={this.openModal.bind(this)} closeModal={this.closeModal.bind(this)} openDrawer={this.openDrawer.bind(this)} mini={openDrawer}/>
          <AdminBody screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.openWindow.bind(this)} module={module} mode={mode} mini={openDrawer} openModal={this.openModal.bind(this)} closeModal={this.closeModal.bind(this)} modal={modal}  params={params} />
          <AdminFooter screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.openWindow.bind(this)}/>
        </Container>
    )
  }
} 

Admin.contextType= WebSocketContext;
export default Admin
