import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { parse, format, addSeconds } from "date-fns"


import { voiceStatus, messagingStatus, socialStatus } from "../../libraries/common/StaticMasterData"

import { connect } from "react-redux"
import { engagementTarget } from "../../actions/stream/targetAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    targetStore: state.targetStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    engagementTarget:(objParam) => dispatch(engagementTarget(objParam)),
  }
};

class EngagementBrowse extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remark: false,
      expand: false,
    }
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.targetStore.engagementTarget!==undefined ? this.props.targetStore.engagementTarget : {})
    this.props.engagementTarget({search: {targetId: this.props.params.targetId}});
  }

  setExpand() {
    const { expand }= this.state
    this.setState({
      expand: !expand
    })
  }
  setRemark() {
    const { remark }= this.state
    this.setState({
      remark: !remark
    })
  }

  formatSeconds(seconds) {
    const date= addSeconds(new Date(0, 0, 0, 0, 0, 0), seconds);
    // console.log(date)
    return format(date, 'HH:mm:ss');
  }

  expandEmail(id) {
    // console.log(id)
    this.setState({
      messagingId: (this.state.messagingId==id ? 0 : id),
    })
  }

  expandConversation(id) {
    // console.log(id)
    this.setState({
      socialId: (this.state.socialId==id ? 0 : id),
    })
  }

  render() {
    //console.log(this.props.voiceStore.browseVoice)
    const { expand, remark }= this.state
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.targetStore.engagementTarget!==undefined ? this.props.targetStore.engagementTarget : {})

    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1', padding: '0'}}>
          <Header as='h3'>
            <Icon name='user outline' />
            <Header.Content>
              {this.props.params && this.props.params.name}
              <Header.Subheader>
              <b>{this.props.params && this.props.params.jobTitle}</b> {this.props.params && this.props.params.jobCompany} 
              <br /><b>{this.props.params && this.props.params.campaign && this.props.params.campaign.name}</b>
              </Header.Subheader>
            </Header.Content>
          </Header>

          <Divider />
          <Header as='h4'>
            {/* <Icon name='phone' /> */}
            <Header.Content>
              Voice
              <Header.Subheader>
                Voice call / robo IVR interaction.
              </Header.Subheader>
            </Header.Content>
          </Header>
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell width={expand ? 8: 7}>
                  <Header as='h5' color='blue'>AGENT</Header>
                </Table.HeaderCell>
                {!expand &&
                <Table.HeaderCell width={2}>
                  <Header as='h5' color='blue' style={{minWidth:'5.5em'}}>DUR.</Header>
                </Table.HeaderCell>}
                <Table.HeaderCell width={expand ? 2 : 2}>
                  <Header as='h5' color='blue' style={{minWidth:'5.5em'}}>
                    <Icon name={expand ? 'angle double right' : 'angle double left'} color={expand ? 'red' : 'red'} style={{fontSize: '1.5em', cursor: 'pointer'}} onClick={this.setExpand.bind(this)}/>
                    <Header.Content>DIAL</Header.Content>
                  </Header>
                </Table.HeaderCell>
                {expand && <>
                {/* <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue' style={{minWidth:'5.5em'}}>PICKED</Header>
                </Table.HeaderCell> */}
                {/* <Table.HeaderCell width='1'>
                  <Header as='h5' color='blue' style={{minWidth:'5.5em'}}>QUEUED</Header>
                </Table.HeaderCell> */}
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue' style={{minWidth:'5.5em'}}>ANSWER</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue' style={{minWidth:'5.5em'}}>DROP</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue' style={{minWidth:'5.5em'}}>HANGUP</Header>
                </Table.HeaderCell>
                </>}
                {!expand && 
                <Table.HeaderCell width={5} style={{background: remark ? '#e7feff' : '#fff2f2'}}>
                  <Header as='h5' color='blue'><Icon name={remark ? 'toggle on' : 'toggle off'} color={remark ? 'blue' : 'red'} style={{fontSize: '1.5em', cursor: 'pointer'}} onClick={this.setRemark.bind(this)}/><Header.Content>{remark ? 'REMARK' : 'RESULT'}</Header.Content></Header>
                </Table.HeaderCell>}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data && data.voices && data.voices.length==0 &&<Table.Row><Table.Cell colSpan={4} style={{padding: '1em 2em'}}><Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data && data.voices ? data.voices.map((item,key)=>{
                  return(
                    <Table.Row key={++key}>
                      <Table.Cell textAlign='center'>
                        {key} <br />
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            <Icon name={item.flow==0 ? 'chevron circle right' : 'chevron circle left'} color={(item.dropStamp && 'orange') || (item.talkStamp && 'blue') || (item.callStamp && 'teal') || (item.dialStamp && 'red')} style={{fontSize: '1.1em'}} />
                            <b>{item.terminating}</b> 
                            <i>( {
                              (item.terminating==this.props.params.mobile && 'Mobile') || 
                              (item.terminating==this.props.params.officePhone && 'Office') || 
                              (item.terminating==this.props.params.homePhone && 'Home') || 
                              (item.terminating==this.props.params.emergencyPhone && (this.props.params.emergencyRelationship || 'Emergency')) || 
                              (item.terminating==this.props.params.otherPhone && (this.props.params.otherRelationship || 'Other'))
                              || 'Self'
                            } <b style={{textTransform: 'capitalize'}}>{
                              (item.terminating==this.props.params.officePhone && (this.props.params.jobCompany || '')) || 
                              (item.terminating==this.props.params.emergencyPhone && (this.props.params.emergencyName || '')) || 
                              (item.terminating==this.props.params.otherPhone && (this.props.params.otherName || ''))
                              || ''
                            }</b> )</i>
                            {/* / {item.agentId} - {this.props.params.agentId} */}
                            <Header.Subheader> 
                            <b><i>{(item.agentId && this.props.params.agentId==item.agentId && 'Locked') || (this.props.params.tagger ?  'Tags:'+ this.props.params.tagger : 'Floating')}</i></b> / <b>{this.props.params.name}</b>
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                        <Divider style={{margin: '.5em 0 -1.5em 0'}}/>
                        <Header as='h5'>
                          <Header.Content>
                            {!item.user && 'No Agent'} {item.user && item.user.name} 
                            <Header.Subheader><b>{(this.props.params.agentId && this.props.params.agentId==item.agentId && 'Locked') || 'Floating'}</b> / 
                            {/* {item.channelType} /  */}
                            <mark>{item.dialBy=='predialed' ? 'Preview Dial' : 'Predictive Dial' }</mark> {item.user  && item.user.tagger ? ('('+ item.user.tagger +')') : ''}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      {!expand &&
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.callDuration!=null && (this.formatSeconds(item.callDuration || 0))}
                            <Header.Subheader></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>}
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.dialStamp!=null && format(parse(item.dialStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm:ss')}
                            <Header.Subheader>{item.dialStamp!=null && format(parse(item.dialStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      {expand && <>
                      {/* <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.callStamp!=null && format(parse(item.callStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.callStamp!=null && format(parse(item.callStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell> */}
                      {/* <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.queueStamp!=null && format(parse(item.queueStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.queueStamp!=null && format(parse(item.queueStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell> */}
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.talkStamp!=null && format(parse(item.talkStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.talkStamp!=null && format(parse(item.talkStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.dropStamp!=null && format(parse(item.dropStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.dropStamp!=null && format(parse(item.dropStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.hangupStamp!=null && format(parse(item.hangupStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.hangupStamp!=null && format(parse(item.hangupStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      </>}
                      {!expand && 
                      <Table.Cell style={{background: remark ? '#e7feff' : '#fff2f2'}}>
                        {!remark && <Header as='h5'>
                          <Popup size='small' content={item.reason || '-'} inverted position='left center'
                          trigger={
                            <Icon name={(item.dropStamp && 'exclamation circle') || (item.status<0 && 'times circle outline') || (item.status>0 && 'check circle') || 'dot circle outline'} color={(item.dropStamp && 'orange') || (item.status<0 && 'red') || (item.status>0 && 'blue') || 'teal'} style={{cursor: 'pointer', fontSize: '1.25em'}}/> 
                          } />
                          <Header.Content>
                            {(item.dropStamp && ' Dropped') || voiceStatus[item.status] || 'No reason'}
                            <Header.Subheader>{item.hangupBy && ('Hangup By: '+ item.hangupBy)} {(item.reason && (item.reason.length>15 ? ' / '+ item.reason.substr(0,15) +' ...' : ' / '+ item.reason)) || '-'}</Header.Subheader>
                          </Header.Content>
                        </Header>}
                        {remark && 
                        <Header as='h5'>
                          {/* <Icon name={(item.remark=='Rescheduled' && 'calendar alternate outline') || (item.remark=='Promised' && 'calendar check') || (item.remark=='Paid' && 'calendar check') || 'exclamation circle'} color={(item.remark=='Rescheduled' && 'red') || (item.remark=='Promised' && 'blue') || (item.remark=='Paid' && 'red') || 'grey'} style={{fontSize: '1.25em'}}/>  */}
                          <div style={{background: (item.status=='1' && '#84dbff') || (item.status=='0' && '#ffb3b3') || '#ddd', textAlign: 'right', display: 'inline-block', width: '4em', padding: '.5em', marginRight: '.5em'}}>{item.progress} %</div>
                          <Header.Content>
                            {item.remark  || 'No remark'}
                            {item.remark=='Rescheduled' && <Header.Subheader>{item.remarks.appointmentSession}, {item.remarks.appointmentDate!=null && format(parse(item.remarks.appointmentDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy')}</Header.Subheader>}
                            {item.remark=='Promised' && <Header.Subheader>{item.remarks.promisedDate!=null && format(parse(item.remarks.promisedDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy')}</Header.Subheader>}
                            {item.remark=='Paid' && <Header.Subheader>{item.remarks.paidDate!=null && format(parse(item.remarks.paidDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy')}</Header.Subheader>}
                            {item.remark!='Rescheduled' && item.remark!='Promised' && item.remark!='Paid' && <Header.Subheader>Exp. on <i>{item.expired!=null ? format(parse(item.expired, 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy') : 'none'}</i></Header.Subheader>}
                          </Header.Content>
                        </Header>}
                      </Table.Cell>}
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        <div>
          <Divider hidden/>
          <Header as='h4'>
            {/* <Icon name='comment alternate outline' /> */}
            <Header.Content>
              Messaging
              <Header.Subheader>
                Messaging interaction.
              </Header.Subheader>
            </Header.Content>
          </Header>

          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell width={!expand ? 2 : 2}>
                  <Header as='h5' color='blue'>METHOD</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={6}>
                  <Header as='h5' color='blue'>CONTENT</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={expand ? 2 : 3}>
                  <Header as='h5' color='blue'>
                    <Icon name={expand ? 'angle double right' : 'angle double left'} color={expand ? 'red' : 'red'} style={{fontSize: '1.5em', cursor: 'pointer'}} onClick={this.setExpand.bind(this)}/>
                    <Header.Content>SENT</Header.Content>
                  </Header>
                </Table.HeaderCell>
                {expand && <>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>DLVRD</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2' style={{background: '#fffee0'}}>
                  <Header as='h5' color='blue'>READ</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>CLICK</Header>
                </Table.HeaderCell>
                </>}
                {!expand && 
                <Table.HeaderCell width={!expand ? '5' : '5'} style={{background: remark ? '#e7feff' : '#fff2f2'}}>
                  <Header as='h5' color='blue'><Icon name={remark ? 'toggle on' : 'toggle off'} color={remark ? 'blue' : 'red'} style={{fontSize: '1.5em', cursor: 'pointer'}} onClick={this.setRemark.bind(this)}/><Header.Content>RESULT</Header.Content></Header>
                </Table.HeaderCell>}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data && data.messagings && data.messagings.length==0 &&<Table.Row><Table.Cell colSpan={4} style={{padding: '1em 2em'}}><Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data && data.messagings ? data.messagings.map((item,key)=>{
                  return(
                    <Table.Row key={++key}>
                      <Table.Cell>{key}</Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Icon name={item.flow==0 ? 'chevron circle right' : 'chevron circle left'} color={item.flow==0 ? 'green' : 'orange'} style={{fontSize: '1.2em'}}/>
                          <Header.Content>
                            {/* {item.channelType} / {item.flow==0 ? 'Inbound' : 'Outbound'} */}
                            {/* <Header.Subheader>{item.client || 'By unknown'}</Header.Subheader> */}
                            {item.channelType}
                            <Header.Subheader>{item.flow==0 ? 'Inbound' : 'Outbound'}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    
                      <Table.Cell>
                        {item.conversations && item.channelType=='Chat' && 
                          <div style={{display: 'flex'}}>
                            <div style={{textAlign: 'center', paddingRight: '.5em'}}>
                              <Popup size='small' style={{background: '#fafafa', padding: '0 .5em', borderRadius: '.5em', minHeight: '3em', minWidth: '10em'}} content={<>
                                {item.conversations && 
                                  <div id='wd_chat' style={{padding: '0em'}}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                      <div className='chat' style={{maxHeight: screenHeight, overflowY: 'scroll'}}>
                                        <div>
                                          {item.conversations.map((item, idx)=>{
                                            return(
                                              <div key={idx} style={{margin: '.5em 0', textAlign: item.sender=='agent' ? 'right' : 'left'}}>
                                                <div style={{display: 'inline-block', background: item.sender=='agent' ? '#d7f5fe' : '#ffebe9', padding: '.5em 1em .2em 1em', margin: item.sender=='agent' ? '0 0 0 2em' : '0 2em 0 0', borderRadius: item.sender=='agent' ? '2.5em 1em 0em 2.5em' : '1em 2.5em 2.5em 0em'}}>
                                                  <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                                                    <Header.Content>
                                                      <Header.Subheader style={{fontSize: '.8em'}}><b>{idx}. {item.sender}</b> / {item.stamp}</Header.Subheader>
                                                      {item.message}
                                                      <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}><Icon name={(item.status=='1' && 'check') || (item.status=='-1' && 'times') || 'ellipsis horizontal'} color={(item.status=='1' && 'blue') || (item.status=='-1' && 'red') || 'grey'} style={{margin: '0', padding: '0'}}/></Header.Subheader>
                                                    </Header.Content>
                                                  </Header>
                                                </div>
                                              </div>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </>} position='left center'
                              trigger={
                                <Icon 
                                name= 'comments outline'
                                color={(item.status=='-1' && 'red') || (item.invalidStamp && 'red') || (item.clickStamp && 'blue') || (item.readStamp && 'teal') || (item.deliveredStamp && 'yellow') || (item.sentStamp && 'yellow')} 
                                inverted circular
                                style={{fontSize: '1em', cursor: 'pointer'}} onClick={this.expandEmail.bind(this, item.messagingId)} />
                              } />
                            </div>
                            <div style={{flex: '1'}}>
                              <Header as='h5'>
                                <Header.Content>
                                  {item.conversations.length} lines.
                                  <Header.Subheader>Chat Repository</Header.Subheader>
                                </Header.Content>
                              </Header>
                            </div>
                          </div>
                        }
                        {item.channelType=='Email' &&
                          <div style={{display: 'flex'}}>
                            <div style={{textAlign: 'center', paddingRight: '.5em'}}>
                              <Popup size='small' content={<>
                                {item.content && 
                                  <div dangerouslySetInnerHTML={ {__html: item.content.replace(/img/g, 'div').replace(/[0-9][0-9]px/g, '1.2em').replace(/style/g, '_style')}} />
                                }
                              </>} inverted position='left center'
                              trigger={
                                <Icon 
                                name= 'envelope open outline'
                                color={(item.status=='-1' && 'red') || (item.invalidStamp && 'red') || (item.clickStamp && 'blue') || (item.readStamp && 'teal') || (item.deliveredStamp && 'yellow') || (item.sentStamp && 'yellow')} 
                                inverted circular
                                style={{fontSize: '1em', cursor: 'pointer'}} onClick={this.expandEmail.bind(this, item.messagingId)} />
                              } />
                            </div>
                            <div style={{flex: '1'}}>
                              <div style={{margin: '0 auto'}}>
                                <Header as='h5'>
                                  <Header.Content>
                                    <b>t:</b> {item.terminating}<br />
                                    <b>f:</b> {item.originating && item.originating.length>25 ? item.originating.substr(0, 25) +' ...' : item.originating}<br />
                                  </Header.Content>
                                </Header>
                              </div>
                            </div>
                          </div>
                        }
                        {(item.channelType=='WA' || item.channelType=='SMS') && 
                          <div style={{display: 'flex'}}>
                            <div style={{textAlign: 'center', paddingRight: '.5em'}}>
                              <Popup size='small' content={<>
                                {item.content}
                              </>} inverted position='left center'
                              trigger={
                                <Icon 
                                name= 'comment alternate outline'
                                color={(item.status=='-1' && 'red') || (item.invalidStamp && 'red') || (item.clickStamp && 'blue') || (item.readStamp && 'teal') || (item.deliveredStamp && 'yellow') || (item.sentStamp && 'yellow')} 
                                inverted circular
                                style={{fontSize: '1em', cursor: 'pointer'}} onClick={this.expandEmail.bind(this, item.messagingId)} />
                              } />
                            </div>
                            <div style={{flex: '1'}}>
                              <div style={{margin: '0 auto'}}>
                                <Header as='h5'>
                                  <Header.Content>
                                    <b>t:</b> {item.terminating}<br />
                                    <b>f:</b> {item.originating}
                                  </Header.Content>
                                </Header>
                              </div>
                            </div>
                          </div>
                        }
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.sentStamp!=null && format(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm:ss')}
                            <Header.Subheader>{item.sentStamp!=null && format(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      {expand && <>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.deliveredStamp!=null && format(parse(item.deliveredStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.deliveredStamp!=null && format(parse(item.deliveredStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell style={{background: '#fffee0'}}>
                        <Header as='h5'>
                          <Header.Content>
                            {item.readStamp!=null && format(parse(item.readStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.readStamp!=null && format(parse(item.readStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.clickStamp!=null && format(parse(item.clickStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.clickStamp!=null && format(parse(item.clickStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      </>}
                      {!expand && 
                      <Table.Cell style={{background: remark ? '#e7feff' : '#fff2f2'}}>
                        {!remark && <Header as='h5'>
                          <Popup size='small' content={item.reason || '-'} inverted position='left center'
                          trigger={
                            <Icon name={(item.dropStamp && 'exclamation circle') || (item.status<0 && 'times circle outline') || (item.status>0 && 'check circle') || 'dot circle outline'} color={(item.dropStamp && 'orange') || (item.status<0 && 'red') || (item.status>0 && 'blue') || 'teal'} style={{cursor: 'pointer', fontSize: '1.25em'}}/> 
                          } />
                          <Header.Content>
                            {(item.dropStamp && ' Dropped') || messagingStatus[item.status] || 'No reason'}
                            <Header.Subheader>{(item.reason && (item.reason.length>15 ? item.reason.substr(0,15) +' ...' : item.reason)) || '-'}</Header.Subheader>
                          </Header.Content>
                        </Header>}
                        {remark && 
                        <Header as='h5'>
                          {/* <Icon name={(item.remark=='Rescheduled' && 'calendar alternate outline') || (item.remark=='Promised' && 'calendar check') || (item.remark=='Paid' && 'calendar check') || 'exclamation circle'} color={(item.remark=='Rescheduled' && 'red') || (item.remark=='Promised' && 'blue') || (item.remark=='Paid' && 'red') || 'grey'} style={{fontSize: '1.25em'}}/>  */}
                          <div style={{background: (item.status=='1' && '#84dbff') || (item.status=='0' && '#ffb3b3') || '#ddd', textAlign: 'right', display: 'inline-block', width: '4em', padding: '.5em', marginRight: '.5em'}}>{item.progress} %</div>
                          <Header.Content>
                            {item.remark  || 'No remark'}
                            {item.remark=='Rescheduled' && <Header.Subheader>{item.remarks.appointmentSession}, {item.remarks.appointmentDate!=null && format(parse(item.remarks.appointmentDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy')}</Header.Subheader>}
                            {item.remark=='Promised' && <Header.Subheader>{item.remarks.promisedDate!=null && format(parse(item.remarks.promisedDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy')}</Header.Subheader>}
                            {item.remark=='Paid' && <Header.Subheader>{item.remarks.paidDate!=null && format(parse(item.remarks.paidDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy')}</Header.Subheader>}
                            {item.remark!='Rescheduled' && item.remark!='Promised' && item.remark!='Paid' && <Header.Subheader><i>{item.expired!=null ? 'Exp. on '+ format(parse(item.expired, 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy') : 'no info'}</i></Header.Subheader>}
                          </Header.Content>
                        </Header>}
                      </Table.Cell>
                      }
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        <div>
          <Divider hidden/>
          <Header as='h4'>
            {/* <Icon name='share alternate' /> */}
            <Header.Content>
              Social Media
              <Header.Subheader>
                Social media interaction.
              </Header.Subheader>
            </Header.Content>
          </Header>
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell width={expand ? 2 : 2}>
                  <Header as='h5' color='blue'>METHOD</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={expand ? 6 : 6}>
                  <Header as='h5' color='blue'>CONTENT</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={expand ? 3 : 3}>
                  <Header as='h5' color='blue'>
                    <Icon name={expand ? 'angle double right' : 'angle double left'} color={expand ? 'red' : 'red'} style={{fontSize: '1.5em', cursor: 'pointer'}} onClick={this.setExpand.bind(this)}/>
                    <Header.Content>POST</Header.Content>
                  </Header>
                </Table.HeaderCell>
                {expand && <>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>VIEW</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='3'>
                  <Header as='h5' color='blue'>REPLY</Header>
                </Table.HeaderCell>
                </>}
                {!expand &&
                <Table.HeaderCell width='5' style={{background: remark ? '#e7feff' : '#fff2f2'}}>
                  <Header as='h5' color='blue'><Icon name={remark ? 'toggle on' : 'toggle off'} color={remark ? 'blue' : 'red'} style={{fontSize: '1.5em', cursor: 'pointer'}} onClick={this.setRemark.bind(this)}/><Header.Content>RESULT</Header.Content></Header>
                </Table.HeaderCell>}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data && data.socials && data.socials.length==0 &&<Table.Row><Table.Cell colSpan={7} style={{padding: '1em 2em'}}><Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data && data.socials ? data.socials.map((item,key)=>{
                return(
                  <Table.Row key={++key}>
                    <Table.Cell>{key}</Table.Cell>
                    <Table.Cell>
                      <Header as='h5'>
                        <Icon name={item.flow==0 ? 'chevron circle right' : 'chevron circle left'} color={item.flow==0 ? 'green' : 'orange'} style={{fontSize: '1.2em'}}/>
                        <Header.Content>
                          {/* {item.channelType} / {item.flow==0 ? 'Inbound' : 'Outbound'} */}
                          {/* <Header.Subheader>{item.client || 'By unknown'}</Header.Subheader> */}
                          {item.channelType}
                          <Header.Subheader>{item.flow==0 ? 'Inbound' : 'Outbound'}</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <div style={{display: 'flex'}}>
                        <div style={{flex: '1'}}>
                          {item.channelType=='Twitter' && 
                            <Header as='h5'>
                              <Popup size='small' style={{background: item.content ? null : '#fafafa', padding: item.content ? '1em' : '0 .5em', borderRadius: '.5em', minHeight: '3em', minWidth: '10em'}} content={<>
                                {item.content || (item.conversations && 
                                  <div id='wd_chat' style={{padding: '0em'}}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                      <div className='chat' style={{maxHeight: screenHeight, overflowY: 'scroll'}}>
                                        <div>
                                          {item.conversations.map((item, idx)=>{
                                            return(
                                              <div key={idx} style={{margin: '.5em 0', textAlign: item.sender=='agent' ? 'right' : 'left'}}>
                                                <div style={{display: 'inline-block', background: item.sender=='agent' ? '#d7f5fe' : '#ffebe9', padding: '.5em 1em .2em 1em', margin: item.sender=='agent' ? '0 0 0 2em' : '0 2em 0 0', borderRadius: item.sender=='agent' ? '2.5em 1em 0em 2.5em' : '1em 2.5em 2.5em 0em'}}>
                                                  <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                                                    <Header.Content>
                                                      <Header.Subheader style={{fontSize: '.8em'}}><b>{idx}. {item.sender}</b> / {item.stamp}</Header.Subheader>
                                                      {item.message}
                                                      <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}><Icon name={(item.status=='1' && 'check') || (item.status=='-1' && 'times') || 'ellipsis horizontal'} color={(item.status=='1' && 'blue') || (item.status=='-1' && 'red') || 'grey'} style={{margin: '0', padding: '0'}}/></Header.Subheader>
                                                    </Header.Content>
                                                  </Header>
                                                </div>
                                              </div>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>} 
                              position='left center' inverted={item.content ? true : false}
                              trigger={
                                <Icon 
                                name={item.channelType.toLowerCase()}
                                color='blue'
                                style={{fontSize: '1.8em', paddingRight: '.5em', cursor: 'pointer'}}/>
                              } />
                              <Header.Content>
                                {item.terminating}
                                <Header.Subheader>{(item.content && item.content.length>25 ? item.content.substr(0,25) +' ...' : item.content)}</Header.Subheader>
                              </Header.Content>
                            </Header>
                          }
                          {item.channelType=='Facebook' && 
                            <Header as='h5'>
                              <Popup size='small' style={{background: item.content ? null : '#fafafa', padding: item.content ? '1em' : '0 .5em', borderRadius: '.5em', minHeight: '3em', minWidth: '10em'}} content={<>
                                {item.conversations && 
                                  <div id='wd_chat' style={{padding: '0em'}}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                      <div className='chat' style={{maxHeight: screenHeight, overflowY: 'scroll'}}>
                                        <div>
                                          {item.conversations.map((item, idx)=>{
                                            return(
                                              <div key={idx} style={{margin: '.5em 0', textAlign: item.sender=='agent' ? 'right' : 'left'}}>
                                                <div style={{display: 'inline-block', background: item.sender=='agent' ? '#d7f5fe' : '#ffebe9', padding: '.5em 1em .2em 1em', margin: item.sender=='agent' ? '0 0 0 2em' : '0 2em 0 0', borderRadius: item.sender=='agent' ? '2.5em 1em 0em 2.5em' : '1em 2.5em 2.5em 0em'}}>
                                                  <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                                                    <Header.Content>
                                                      <Header.Subheader style={{fontSize: '.8em'}}><b>{idx}. {item.sender}</b> / {item.stamp}</Header.Subheader>
                                                      {item.message}
                                                      <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}><Icon name={(item.status=='1' && 'check') || (item.status=='-1' && 'times') || 'ellipsis horizontal'} color={(item.status=='1' && 'blue') || (item.status=='-1' && 'red') || 'grey'} style={{margin: '0', padding: '0'}}/></Header.Subheader>
                                                    </Header.Content>
                                                  </Header>
                                                </div>
                                              </div>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </>} 
                              position='left center' inverted={item.content ? true : false}
                              trigger={
                                <Icon 
                                name={item.channelType.toLowerCase()}
                                color='blue'
                                style={{fontSize: '1.8em', paddingRight: '.5em', cursor: 'pointer'}}/>
                              } />
                              <Header.Content>
                                {item.terminating}
                                <Header.Subheader>{item.content || '-'}</Header.Subheader>
                              </Header.Content>
                            </Header>
                          }
                          {item.channelType=='Instagram' && 
                            <Header as='h5'>
                              <Popup size='small' style={{background: item.content ? null : '#fafafa', padding: item.content ? '1em' : '0 .5em', borderRadius: '.5em', minHeight: '3em', minWidth: '10em'}} content={<>
                                {item.content || (item.conversations && 
                                  <div id='wd_chat' style={{padding: '0em'}}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                      <div className='chat' style={{maxHeight: screenHeight, overflowY: 'scroll'}}>
                                        <div>
                                          {item.conversations.map((item, idx)=>{
                                            return(
                                              <div key={idx} style={{margin: '.5em 0', textAlign: item.sender=='agent' ? 'right' : 'left'}}>
                                                <div style={{display: 'inline-block', background: item.sender=='agent' ? '#d7f5fe' : '#ffebe9', padding: '.5em 1em .2em 1em', margin: item.sender=='agent' ? '0 0 0 2em' : '0 2em 0 0', borderRadius: item.sender=='agent' ? '2.5em 1em 0em 2.5em' : '1em 2.5em 2.5em 0em'}}>
                                                  <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                                                    <Header.Content>
                                                      <Header.Subheader style={{fontSize: '.8em'}}><b>{idx}. {item.sender}</b> / {item.stamp}</Header.Subheader>
                                                      {item.message}
                                                      <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}><Icon name={(item.status=='1' && 'check') || (item.status=='-1' && 'times') || 'ellipsis horizontal'} color={(item.status=='1' && 'blue') || (item.status=='-1' && 'red') || 'grey'} style={{margin: '0', padding: '0'}}/></Header.Subheader>
                                                    </Header.Content>
                                                  </Header>
                                                </div>
                                              </div>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>} 
                              position='left center' inverted={item.content ? true : false}
                              trigger={
                                <Icon 
                                name={item.channelType.toLowerCase()}
                                color='blue'
                                style={{fontSize: '1.8em', paddingRight: '.5em', cursor: 'pointer'}}/>
                              } />
                              <Header.Content>
                                {item.terminating}
                                <Header.Subheader>{item.content || '-'}</Header.Subheader>
                              </Header.Content>
                            </Header>
                          }
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <Header as='h5'>
                        <Header.Content>
                          {item.sentStamp!=null && format(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm:ss')}
                          <Header.Subheader>{item.sentStamp!=null && format(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                    {expand && <>
                    <Table.Cell style={{background: '#fffee0'}}>
                      <Header as='h5'>
                        <Header.Content>
                          {item.readStamp!=null && format(parse(item.readStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                          <Header.Subheader>{item.readStamp!=null && format(parse(item.readStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Header as='h5'>
                        <Header.Content>
                          {item.clickStamp!=null && format(parse(item.clickStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                          <Header.Subheader>{item.clickStamp!=null && format(parse(item.clickStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM')}</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                    </>}
                    {!expand &&
                    <Table.Cell style={{background: remark ? '#e7feff' : '#fff2f2'}}>
                      {!remark && <Header as='h5'>
                        <Popup size='small' content={item.reason || '-'} inverted position='left center'
                        trigger={
                          <Icon name={(item.dropStamp && 'exclamation circle') || (item.status<0 && 'times circle outline') || (item.status>0 && 'check circle') || 'dot circle outline'} color={(item.dropStamp && 'orange') || (item.status<0 && 'red') || (item.status>0 && 'blue') || 'teal'} style={{cursor: 'pointer', fontSize: '1.25em'}} /> 
                        } />
                        <Header.Content>
                          {(item.dropStamp && ' Dropped') || socialStatus[item.status] || 'No reason'}
                          <Header.Subheader>{(item.reason && (item.reason.length>15 ? item.reason.substr(0,15) +' ...' : item.reason)) || '-'}</Header.Subheader>
                        </Header.Content>
                      </Header>}
                      {remark && <Header as='h5'>
                      <Icon name={(item.remark=='Rescheduled' && 'calendar alternate outline') || (item.remark=='Promised' && 'calendar check') || (item.remark=='Paid' && 'calendar check') || 'exclamation circle'} color={(item.remark=='Rescheduled' && 'red') || (item.remark=='Promised' && 'blue') || (item.remark=='Paid' && 'red') || 'grey'} style={{fontSize: '1.25em'}}/> 
                        <Header.Content>
                          {item.remark  || 'No remark'}
                          {item.remark=='Rescheduled' && <Header.Subheader>{item.remarks.appointmentSession}, {item.remarks.appointmentDate!=null && format(parse(item.remarks.appointmentDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy')}</Header.Subheader>}
                          {item.remark=='Promised' && <Header.Subheader>{item.remarks.promisedDate!=null && format(parse(item.remarks.promisedDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy')}</Header.Subheader>}
                          {item.remark=='Paid' && <Header.Subheader>{item.remarks.paidDate!=null && format(parse(item.remarks.paidDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy')}</Header.Subheader>}
                        </Header.Content>
                      </Header>}
                    </Table.Cell>}
                  </Table.Row>
                )
              }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
            }
            </Table.Body>
          </Table>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EngagementBrowse)