import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';
import TickethandlingBody from './TickethandlingBody'

import TicketBrowse from '../../libraries/agent/TicketBrowse'
import TicketForm from '../../libraries/agent/TicketForm'
import TicketSearch from '../../libraries/agent/TicketSearch'



class TicketBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        ticketId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, ticketId: id, clientId: clientId})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        ticketId: 0,
      });
    }

    render() {
      const {mode, ticketId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid style={{padding: '1em'}}>
          <Segment style={{padding: '.5em .7em'}}>
            <TicketSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {(mode==1 || mode==4) && <TicketBrowse setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
              {mode==2 && <TicketForm mode={mode} ticketId={ticketId} clientId={clientId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <TicketForm mode={mode} ticketId={0} clientId={0} setMode={this.setMode.bind(this)} />}
              {mode==4 && <ModalContainer size='large' content={<TickethandlingBody screenWidth={screenWidth}  closeModal={this.closeModal.bind(this)} ticketId={ticketId} clientId={clientId} />} closeModal={this.closeModal.bind(this)} /> }
            </div>
          </Segment>
        </Container>
      )
    }
}

export default TicketBody
