import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, FormField } from 'semantic-ui-react'

import ModalContainer from '../../../libraries/common/ModalContainer';
import DismissableMessage from '../../common/DismissableMessage';
import IconToggle from '../../common/IconToggle';
import ScriptForm from '../halo/ScriptForm';
import WorkgroupForm from '../halo/WorkgroupForm';
// import ProductForm from '../halo/ProductForm';
import ProductForm from '../../../libraries/realm/halo/ProductForm'

import {hourOptions, dialingOptions, yesnoOptions, redistOptions, delimiterOptions, fieldTargetOptions, dispatchOptions}  from '../../common/StaticMasterData'

import { SubmissionError, Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, NumberField, DatePickerField, SelectField, TextAreaField, DataUploadField } from '../../validation/componentrsui'
import { required, confirmedPassword, phoneNumber, number, minValue, email } from '../../validation/validation'

import { connect } from "react-redux"
import { resetCampaign, getCampaign,updateCampaign, createCampaign } from "../../../actions/stream/campaignAction"
import { listTeamleader, listSupervisor, listWorkgroup, listTemplate, listProduct, listCampaign, collectionStage, restructuringStage, telesaleStage, verificationStage, listParams } from "../../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('CampaignForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    campaignStore: state.campaignStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listTeamleader:(objParam) => dispatch(listTeamleader(objParam)),
    listSupervisor:(objParam) => dispatch(listSupervisor(objParam)),

    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    listTemplate:(objParam) => dispatch(listTemplate(objParam)),
    listProduct:(objParam) => dispatch(listProduct(objParam)),
    listCampaign:(objParam) => dispatch(listCampaign(objParam)),
    listParams:(objParam) => dispatch(listParams(objParam)),

    collectionStage:(objParam) => dispatch(collectionStage(objParam)),
    restructuringStage:(objParam) => dispatch(restructuringStage(objParam)),
    telesaleStage:(objParam) => dispatch(telesaleStage(objParam)),
    verificationStage:(objParam) => dispatch(verificationStage(objParam)),

    resetCampaign:(objParam) => dispatch(resetCampaign(objParam)),
    getCampaign:(objParam) => dispatch(getCampaign(objParam)),
    createCampaign:(objParam) => dispatch(createCampaign(objParam)),
    updateCampaign:(objParam) => dispatch(updateCampaign(objParam)),
  }
}

class CampaignForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      modalScript: false,
      modalWorkgroup: false,
      modalProduct: false,
      fieldOptions: [],

      isAlternative: false,
      isInit: false,

      showOptional:false,
    }
  }

  componentDidMount(){
    this.props.resetCampaign(this.props.mode)

    if (this.props.mode==2) { //update
      this.props.getCampaign({campaignId: this.props.campaignId})
    }
    
    this.props.listTeamleader({search:{groupId: 'Team Leader'}})
    this.props.listSupervisor({search:{groupId: 'Supervisor'}})

    this.props.listWorkgroup({search: {clientId: this.props.clientId!=undefined ? this.props.clientId : null}})
    this.props.listTemplate({search: {clientId: this.props.clientId!=undefined ? this.props.clientId : null}})
    this.props.listProduct({search: {clientId: this.props.clientId!=undefined ? this.props.clientId : null}})
    
    this.props.listParams({clientId: this.props.clientId!=undefined ? this.props.clientId : null})

    this.props.collectionStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
    this.props.restructuringStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
    this.props.telesaleStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
    this.props.verificationStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
  }

  componentDidUpdate() {
    const { fieldOptions }= this.state
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.campaignStore.updateCampaign!=undefined ? this.props.campaignStore.updateCampaign : {}) : (this.props.campaignStore.createCampaign!=undefined ? this.props.campaignStore.createCampaign : {}))
    
//    console.log('!!! DIDUPDATE fieldOptions firstRow firstRows', fieldOptions.length, this.props.firstRow, this.props.firstRows)

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }

    if (fieldOptions.length==0 && this.props.firstRow) {
      this.parseFields(this.props.firstRow.replace(/(\r\n|\n|\r)/gm, ''))
    } 
    // else if (fieldOptions.length>0 && !this.props.firstRow && !this.props.firstRows) {
    //   this.setState({
    //     fieldOptions: [],
    //   })
    // }

    if (!this.state.isInit && this.props.channel_2 && this.props.campaignStore.getCampaign.progress==1) {
      this.setState({
        isAlternative: true,
        isInit: true,
      })
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateCampaign({...values, ...((this.props.isRedistribution!='0' && {imported: null}) || {}), qtyChannel: this.state.isAlternative ? 2 : 1}) : this.props.createCampaign({...values, ...((this.props.isRedistribution!='0' && {imported: null}) || {}), qtyChannel: this.state.isAlternative ? 2 : 1})
  }

  setMode(key) {
    if (key=='isAlternative') {
      this.setState({
        isAlternative: !this.state.isAlternative
      })
      this.props.dispatch(change('CampaignForm', 'channel_2', null ));
    } else if (key=='fixed') {
      const { fileParams }= this.props.masterStore.listParams && this.props.masterStore.listParams.data ? this.props.masterStore.listParams.data : {}
      if (this.props.fixed==undefined || this.props.fixed=='0') {
        this.props.dispatch(change('CampaignForm', 'fixed','1' ));
        if (!this.props.fileFormat && fileParams) {
          this.props.dispatch(change('CampaignForm', 'fileFormat', fileParams.fileFormat ));
        }
      } else {
        this.props.dispatch(change('CampaignForm', 'fixed','0' ));
      } 
    } else {
      if (this.props.isRedistribution==undefined || this.props.isRedistribution=='0') {
        this.props.dispatch(change('CampaignForm', 'isRedistribution','1' ));
      } else {
        this.props.dispatch(change('CampaignForm', 'isRedistribution','0' ));
      } 
    }
  }

  samplingData(v) {
//    console.log('!!! SAMPLING DATA', v)

    if (v!=null) {
      const data= JSON.parse(v);

      if ( data.firstRow && data.firstRow!='') {
        this.props.dispatch(change('CampaignForm', 'firstRow', data.firstRow && data.firstRow.replace(/(\r\n|\n|\r)/gm, '')));
        this.props.dispatch(change('CampaignForm', 'firstRows', []));
      } else {
        this.props.dispatch(change('CampaignForm', 'firstRow', null));
        this.props.dispatch(change('CampaignForm', 'firstRows', data.firstRows));
      }
      this.props.dispatch(change('CampaignForm', 'delimiter', null ));
      this.props.dispatch(change('CampaignForm', 'imported', null ));

      if (data.firstRows && data.firstRows.length>0) {
        this.parseFields(data.firstRows)
      } else if (data.firstRow!='') {
        this.parseFields(data.firstRow.replace(/(\r\n|\n|\r)/gm, ''))
      }
    } else {
      this.setState({fieldOptions: []});
    }
  }

  parseFields(firstrow) {
//    console.log('!!! FIRSTROW', firstrow)

    var fieldOptions= [];
    fieldOptions.push({ key: 0, value: '', text: '! None / Null' })

    if (Array.isArray(firstrow)) {
      firstrow.map((item, i)=>{
        fieldOptions.push({ key: ++i, value: item, text: item })
      })
    } else {
      if (this.props.delimiter==undefined || this.props.delimiter==null) {
        if (firstrow.split('", "').length>=3) {
          firstrow.split('", "').map((field, i)=>{
            fieldOptions.push({ key: ++i, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
          this.props.dispatch(change('CampaignForm', 'delimiter','", "' ));
        } else if (firstrow.split('","').length>=3) {
          firstrow.split('","').map((field, i)=>{
            fieldOptions.push({ key: ++i, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
          this.props.dispatch(change('CampaignForm', 'delimiter','","' ));
        } else if (firstrow.split(',').length>=3) {
          firstrow.split(',').map((field, i)=>{
            fieldOptions.push({ key: ++i, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
          this.props.dispatch(change('CampaignForm', 'delimiter',',' ));
        } else if (firstrow.split('"; "').length>=3) {
          firstrow.split('"; "').map((field, i)=>{
            fieldOptions.push({ key: ++i, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
          this.props.dispatch(change('CampaignForm', 'delimiter','"; "' ));
        } else if (firstrow.split('";"').length>=3) {
          firstrow.split('";"').map((field, i)=>{
            fieldOptions.push({ key: ++i, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
          this.props.dispatch(change('CampaignForm', 'delimiter','";"' ));
        } else if (firstrow.split(';').length>=3) {
          firstrow.split(';').map((field, i)=>{
            fieldOptions.push({ key: ++i, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
          this.props.dispatch(change('CampaignForm', 'delimiter',';' ));
        }
      } else {
        if (firstrow.split(this.props.delimiter).length>=3) {
          firstrow.split(this.props.delimiter).map((field, i)=>{
            fieldOptions.push({ key: ++i, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
        }
      }
      // console.log(this.props.delimiter, firstrow)
    }
    this.setState({fieldOptions: fieldOptions});
  }

  toggleModal(v) {
    switch(v) {
      case 'script':
        this.setState({
          modalScript: !this.state.modalScript,
        })
      break;
      case 'workgroup':
        this.setState({
          modalWorkgroup: !this.state.modalWorkgroup,
        })
      break;
      case 'product':
        this.setState({
          modalProduct: !this.state.modalProduct,
        })
      break;
      default:
        this.setState({
          modalScript: false,
          modalWorkgroup: false,
          modalProduct: false,
        })
    }
  }

  showOptional(v) {
    this.setState({
      showOptional: (v ==this.state.showOptional) ? 0 : v,
    })
  }

  render() {
    const { fieldOptions, modalScript, modalWorkgroup, modalProduct, isAlternative }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode, screenWidth } = this.props
    
    const { progress, code, text }= (mode==2 ? (this.props.campaignStore.updateCampaign!=undefined ? this.props.campaignStore.updateCampaign : {}) : (this.props.campaignStore.createCampaign!=undefined ? this.props.campaignStore.createCampaign : {}))
    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    const listTemplate= (this.props.masterStore.listTemplate!=undefined && this.props.masterStore.listTemplate.progress=='1' ? this.props.masterStore.listTemplate.data : [])
    const listProduct= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    const listCampaign= (this.props.masterStore.listCampaign!=undefined && this.props.masterStore.listCampaign.progress=='1' ? this.props.masterStore.listCampaign.data : [])

    const listTeamleader= (this.props.masterStore.listTeamleader!=undefined && this.props.masterStore.listTeamleader.progress=='1' ? this.props.masterStore.listTeamleader.data : [])
    const listSupervisor= (this.props.masterStore.listSupervisor!=undefined && this.props.masterStore.listSupervisor.progress=='1' ? this.props.masterStore.listSupervisor.data : [])

    // const collectionStage= (this.props.masterStore.collectionStage!=undefined && this.props.masterStore.collectionStage.progress=='1' ? this.props.masterStore.collectionStage.data : [])
    // const restructuringStage= (this.props.masterStore.restructuringStage!=undefined && this.props.masterStore.restructuringStage.progress=='1' ? this.props.masterStore.restructuringStage.data : [])
    // const telesaleStage= (this.props.masterStore.telesaleStage!=undefined && this.props.masterStore.telesaleStage.progress=='1' ? this.props.masterStore.telesaleStage.data : [])
    // const verificationStage= (this.props.masterStore.verificationStage!=undefined && this.props.masterStore.verificationStage.progress=='1' ? this.props.masterStore.verificationStage.data : [])

    const { svcCampaigns, svcRatios, svcChannels }= this.props.masterStore.listParams && this.props.masterStore.listParams.data ? this.props.masterStore.listParams.data : {}

    const campaignOptions= svcCampaigns
    const channelOptions= svcChannels
    const ratioOptions= svcRatios
    
    const filteredListTemplate_1= [{ key: 0, value: null, text: 'No Template', icon: 'code'}];
    if (this.props.channel_1 && listTemplate.length>0) {
      listTemplate.map(item=>{
        item.media==this.props.channel_1 && filteredListTemplate_1.push(item);
      })
    }
    const filteredListTemplate_2= [{ key: 0, value: null, text: 'No Template', icon: 'code'}];
    if (this.props.channel_2 && listTemplate.length>0) {
      listTemplate.map(item=>{
        item.media==this.props.channel_2 && filteredListTemplate_2.push(item);
      })
    }

    const channels= [];
    this.props.channel_1 && channels.push(this.props.channel_1)
    this.props.channel_2 && channels.push(this.props.channel_2)
//    console.log('!!! RENDER isRedistribution mode fixed fieldOptions', this.props.isRedistribution, mode, this.props.fixed, fieldOptions.length)

    return (
      <div style={{padding: '1em 1em'}}>
        <ModalContainer title={
          (modalScript && 'Create Template') ||
          (modalWorkgroup && 'Create Workgroup') ||
          (modalProduct && 'Create Product / Service')
        } size='small' open={modalScript||modalWorkgroup||modalProduct} content={
          (modalScript && <ScriptForm mode={3} workgroupId={0} modal clientId={this.props.clientId} closeModal={this.toggleModal.bind(this)}/>) ||
          (modalWorkgroup && <WorkgroupForm mode={3} workgroupId={0} modal clientId={this.props.clientId} closeModal={this.toggleModal.bind(this)}/>) ||
          (modalProduct && <ProductForm mode={3} workgroupId={0} modal clientId={this.props.clientId} closeModal={this.toggleModal.bind(this)}/>)
        } closeModal={this.toggleModal.bind(this, 'hide')} />

        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='equal' >
              <Form.Field>
                <Field name='avatar' component={ImageUploadField}
                width={300} 
                height={300} 
                aspectRatio= '3:3' />
              </Form.Field>
            </Form.Group>

            <Form.Group widths='16' style={{marginTop: '2em'}}>
              <Field name='isEnabled' component={CheckboxField}
              toggle
              width={8}
              caption='Enable Now !'
              placeholder='Status enable/disabled' />
              <Field name='owner' component={SelectField}
              // readOnly
              width={8}
              options= {listTeamleader}
              label='Owner'
              placeholder='Owner' />
              {/* <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' /> */}
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              width={8}
              validate={[required]}
              label='Campaign Name'
              placeholder='Campaign Name' />
              <Field name='code' component={InputField}
              width={2}
              // validate={[required]}
              label='Code'
              placeholder='Opt. Code' />
              <Field name='attemptRatio' component={SelectField}
              options= {ratioOptions || []}
              width={2}
              validate={[required]}
              label='Ratio'
              placeholder='Attempt' />
              <Field name='campaignType' component={SelectField}
              width={4}
              options={campaignOptions || []}
              validate={[required]}
              label='Campaign Type'
              placeholder='Campaign Type' />
            </Form.Group>
            {/* <Form.Group widths='equal'>
              <Field name='descript' component={InputField}
              label='Description'
              placeholder='Description' />
            </Form.Group> */}
            {(this.props.campaignType=='Verification' || this.props.campaignType=='Telesales') &&
            <div>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Form.Group widths='equal'>
                    <Field name='products' component={SelectField}
                    options={listProduct || []}
                    multiple
                    validate={[required]}
                    label='Offered Products'
                    placeholder='Offered Products' />
                  </Form.Group>
                </div>
                <div style={{marginTop: '1.6em', marginLeft: '1em'}}>
                  <Button type='button' icon size='big' color='grey' onClick={this.toggleModal.bind(this, 'product')}>
                    <Icon name='plus' />
                  </Button>
                </div>
              </div>
            </div>}
          </Segment>

          {/* {this.props.campaignType=='Collection' &&
          <Segment>
            <Header as='h5'>
              <Icon name='copy outline' style={{fontSize: '1em'}}/>
              <Header.Content>Collection Policy</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='promisedDays' component={NumberField}
              width={4}
              validate={[required]}
              label='Promised Days'
              placeholder='Promised Days' />
            </Form.Group>
          </Segment>} */}
          
          <Segment>
            <Header as='h5'>
              <Icon name='calendar alternate outline' style={{fontSize: '1em'}}/>
              <Header.Content>
                Schedule and Workgroup
                {/* <Header.Subheader>Schedule when campaign will be active and deliver contents</Header.Subheader> */}
              </Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='startDate' component={DatePickerField}
              validate={[required]}
              label='Start Date'
              placeholder='Start Date' />
              <Field name='endDate' component={DatePickerField}
              validate={[required]}
              label='End Date'
              placeholder='End Date' />
              <Field name='startTime' component={SelectField}
              options= {hourOptions()}
              validate={[required]}
              label='Start Time'
              placeholder='Start Time' />
              <Field name='endTime' component={SelectField}
              options= {hourOptions()}
              validate={[required]}
              label='End Time'
              placeholder='End Time' />
            </Form.Group>
          {/* </Segment>
          <Segment attached='bottom'> */}
            {/* <Header as='h5'>
              <Icon name='bookmark outline' style={{fontSize: '1em'}}/>
              <Header.Content>
                Assignment
                <Header.Subheader>Task force assignment for campaign, based on workgroup of agents</Header.Subheader>
              </Header.Content>
            </Header> */}
                <Form.Group widths='16'>
                  <Field name='dispatchType' component={SelectField}
                  width={4}
                  options={dispatchOptions('campaign')}
                  label='Dispatch Type'
                  placeholder='Dispatch Type' />
                  <Form.Field width={12}>
                    <div style={{display: 'flex'}}>
                      <div style={{flex: '1'}}>
                        <Form.Group widths='equal'>
                          <Field name='workgroups' component={SelectField}
                          options={listWorkgroup || []}
                          multiple
                          label='Assigned Workgroup'
                          placeholder='Assigned Workgroup' />
                        </Form.Group>
                      </div>
                      <div style={{marginTop: '1.6em', marginLeft: '1em'}}>
                        <Button type='button' icon size='big' color='orange' onClick={this.toggleModal.bind(this, 'workgroup')}>
                          <Icon name='plus' />
                        </Button>
                      </div>
                    </div>
                  </Form.Field>
                </Form.Group>
          </Segment>
          
          <Segment attached={isAlternative && 'top'}>
            <div style={{display: 'flex', marginBottom: '.5em'}}>
              <div style={{flex: '1'}}>
                <Header as='h5'>
                  <Icon name='share alternate' style={{fontSize: '1em'}}/>
                  <Header.Content>
                    Channel / Media Stream
                    {/* <Header.Subheader>Primary campaign's channel_1 for content delivery</Header.Subheader> */}
                  </Header.Content>
                </Header>
              </div>
              <div style={{width: '15em'}}>
                <Header as='h5' style={{fontWeight: 'bold'}}>
                  <IconToggle active={isAlternative} onClick={this.setMode.bind(this, 'isAlternative')} />
                  <Header.Content>
                    Secondary Channel
                    <Header.Subheader>Alternative Channel / Funnel</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
            <Form.Group widths='16'>
              <Field name='channel_1' component={SelectField} style={{fontWeight: 'bold'}}
              width={4}
              options={channelOptions}
              validate={[required]}
              label='Campaign Media'
              placeholder='Media Channel Delivery' />
              {this.props.channel_1=='Voice' && 
                <Field name='dialingMode' component={SelectField}
                width= {4}
                options={dialingOptions} //{listTemplate}
                validate={[required]}
                label='Dialing Mode'
                placeholder='Dialing Mode' />
              }
              <Form.Field width={this.props.channel_1=='Voice' ? 8 : 12}>
                <div style={{display: 'flex'}}>
                  <div style={{flex: '1'}}>
                    <Form.Group widths='equal'>
                      <Field name='script_1' component={SelectField}
                      options={filteredListTemplate_1} //{listTemplate}
                      label='Content Template'
                      placeholder='Content Template' />
                    </Form.Group>
                  </div>
                  <div style={{marginTop: '1.6em', marginLeft: '1em'}}>
                    <Button type='button' icon size='big' color='teal' onClick={this.toggleModal.bind(this, 'script')}>
                      <Icon name='plus' />
                    </Button>
                  </div>
                </div>
              </Form.Field>
            </Form.Group>
          </Segment>
          {(isAlternative) &&
          <Segment attached='bottom'>
            <Header as='h5'>
              <Icon name='share alternate' style={{fontSize: '1em'}}/>
              <Header.Content>
                Alternative Channel / Media Stream
                {/* <Header.Subheader>Failured action plan as a alternative campaign's channel_1 when primary is failed to be delivered</Header.Subheader> */}
              </Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='channel_2' component={SelectField} style={{fontWeight: 'bold'}}
              width={4}
              options={channelOptions}
              validate={[required]}
              label='Campaign Media'
              placeholder='Media Channel Delivery' />
              {this.props.channel_2=='Voice' && 
                <Field name='dialingMode' component={SelectField}
                width= {4}
                options={dialingOptions} //{listTemplate}
                validate={[required]}
                label='Dialing Mode'
                placeholder='Dialing Mode' />
              }
              <Form.Field width={this.props.channel_2=='Voice' ? 8 : 12}>
                <div style={{display: 'flex'}}>
                  <div style={{flex: '1'}}>
                    <Form.Group widths='equal'>
                      <Field name='script_2' component={SelectField}
                      options={filteredListTemplate_2} //{listTemplate}
                      label='Content Template'
                      placeholder='Content Template' />
                    </Form.Group>
                  </div>
                  <div style={{marginTop: '1.6em', marginLeft: '1em'}}>
                    <Button type='button' icon size='big' color='green' onClick={this.toggleModal.bind(this, 'script')}>
                      <Icon name='plus' />
                    </Button>
                  </div>
                </div>
              </Form.Field>
            </Form.Group>
          </Segment>}
          
          {(this.props.isRedistribution=='0' || (this.props.isRedistribution==undefined && mode!=2)) && <>
            <div style={{display: 'flex', margin: '3em 0 1.5em 0'}}>
              <div style={{flex: '1'}}>
                <Header as='h4'>
                  <Icon name='file alternate outline' style={{fontSize: '1em'}}/>
                  <Header.Content>
                    {fieldOptions.length>0 ? 'Data Field Mapping' : 'Upload files here!'}
                    <Header.Subheader>
                      {fieldOptions.length>0 ? 'Please map file\'s column  to database attributes' : 'Download sample csv files, simple.csv or complete.csv'}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{width: '16.5em'}}>
                <Header as='h5' style={{margin: '0', fontWeight: 'bold'}}>
                  <IconToggle active={this.props.fixed=='1'} onClick={this.setMode.bind(this, 'fixed')} />
                  <Header.Content>
                    Fixed Mapping
                    <Header.Subheader>Based on current configuration</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>

            {this.props.fixed=='1' && 
            <Segment>
              {/* <Form.Group widths='16'>
                <Field name='fileDelimiter' component={SelectField}
                width={4}
                options={delimiterOptions}
                label='File Delimiter'
                placeholder='Upload File Delimiter' />
              </Form.Group> */}
              <Form.Group widths='16'>
                <Field name='fileFormat' component={SelectField}
                width={16}
                options={fieldTargetOptions}
                multiple
                label='Upload File Format'
                placeholder='Upload File Format' />
              </Form.Group>
            </Segment>
            }
            
            {(!this.props.fixed || this.props.fixed=='0') && fieldOptions.length>0 && <>
              <Segment attached='top'>
                <div style={{display: 'flex', marginBottom: '1.5em'}}>
                  <div style={{flex: '1'}}>
                    <Header as='h5' style={{fontWeight: 'bold'}}>
                      <Header.Content>
                        Customer
                        <Header.Subheader>Customer primary and additional information.</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{width: '15em'}}>
                  </div>
                </div>
                <Form.Group widths='16'>
                  <Field name='f_name' component={SelectField}
                  width={4}
                  options={fieldOptions}
                  label='Customer Name'
                  placeholder='Customer Name' />
                  <Field name='f_descript' component={SelectField}
                  width={4}
                  options={fieldOptions}
                  label='Contact Description'
                  placeholder='Contact Description / Information' />
                </Form.Group>
                <Form.Group widths='16'>
                  <Field name='f_contractRef' component={SelectField}
                  width={4}
                  options={fieldOptions}
                  label='Contract. Ref. Id'
                  placeholder='Contract. Ref. Id' />
                  <Field name='f_custRef' component={SelectField}
                  width={4}
                  options={fieldOptions}
                  label='Cust. Ref. Id'
                  placeholder='Cust. Ref. Id' />
                  <Field name='f_dataOrigin' component={SelectField}
                  width={4}
                  options={fieldOptions}
                  fluid
                  label='Data Source'
                  placeholder='Data Source' />
                  <Field name='f_dataMaturity' component={SelectField}
                  width={4}
                  options={fieldOptions}
                  fluid
                  label='Data Maturity'
                  placeholder='Data Maturity' />
                </Form.Group>
              </Segment>

              <Segment>
                <Header as='h5' style={{fontWeight: 'bold'}}>
                  <Header.Content>Contact</Header.Content>
                </Header>
                {(channels.indexOf('SMS')>=0 || channels.indexOf('Voice')>=0 || channels.indexOf('IVR')>=0 || channels.indexOf('Whatsapp')>=0) && <>
                <Form.Group widths='equal'>
                  <Field name='f_mobile' component={SelectField}
                  disabled= {!(channels.indexOf('SMS')>=0 || channels.indexOf('Voice')>=0 || channels.indexOf('IVR')>=0)}
                  options={fieldOptions}
                  label='Mobile Phone'
                  placeholder='Mobile Phone' />
                  <Field name='f_whatsapp' component={SelectField}
                  readOnly= {!(channels.indexOf('Whatsapp')>=0)}
                  options={fieldOptions}
                  label='Whatsapp'
                  placeholder='Whatsapp' />
                  <Field name='f_homePhone' component={SelectField}
                  disabled= {!(channels.indexOf('Voice')>=0)}
                  options={fieldOptions}
                  label='Home Phone'
                  placeholder='Home Phone' />
                  <Field name='f_officePhone' component={SelectField}
                  disabled= {!(channels.indexOf('Voice')>=0)}
                  options={fieldOptions}
                  label='Office Phone'
                  placeholder='Office Phone' />
                </Form.Group></>}

                {(channels.indexOf('Email')>=0 || channels.indexOf('Twitter')>=0 || channels.indexOf('Facebook')>=0 || channels.indexOf('Instagram')>=0) && <>
                <Form.Group widths='equal'>
                  <Field name='f_email' component={SelectField}
                  disabled={!(channels.indexOf('Email')>=0)}
                  options={fieldOptions}
                  label='Email'
                  placeholder='Email' />
                  <Field name='f_twitter' component={SelectField}
                  disabled={!(channels.indexOf('Twitter')>=0)}
                  options={fieldOptions}
                  label='Twitter'
                  placeholder='Twitter' />
                  <Field name='f_facebook' component={SelectField}
                  disabled={!(channels.indexOf('Facebook')>=0)}
                  options={fieldOptions}
                  label='Facebook'
                  placeholder='Facebook' />
                  <Field name='f_instagram' component={SelectField}
                  disabled={!(channels.indexOf('Instagram')>=0)}
                  options={fieldOptions}
                  label='Instagram'
                  placeholder='Instagram' />
                </Form.Group></>}

                {(channels.indexOf('Telegram')>=0) && <>
                <Form.Group widths='16'>
                  <Field name='f_telegram' component={SelectField}
                  width={4}
                  disabled= {!(channels.indexOf('Telegram')>=0)}
                  options={fieldOptions}
                  label='Telegram'
                  placeholder='Telegram' />
                </Form.Group></>}

                <Divider hidden />
                {(channels.indexOf('SMS')>=0 || channels.indexOf('Voice')>=0 || channels.indexOf('IVR')>=0 || channels.indexOf('Whatsapp')>=0) && <>
                <Header as='h5' style={{fontWeight: 'bold'}}>
                  <Header.Content>Emergency / Relatives</Header.Content>
                </Header>
                <Form.Group widths='equal'>
                  <Field name='emergencyName' component={SelectField}
                  options={fieldOptions}
                  disabled= {!(channels.indexOf('SMS')>=0 || channels.indexOf('Voice')>=0 || channels.indexOf('IVR')>=0)}
                  label='Emergency Contact / Name'
                  placeholder='Emergency Contact / Name' />
                  <Field name='otherName' component={SelectField}
                  options={fieldOptions}
                  disabled= {!(channels.indexOf('Voice')>=0)}
                  label='Other Contact / Name'
                  placeholder='Other Contact / Name' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='emergencyPhone' component={SelectField}
                  options={fieldOptions}
                  disabled= {!(channels.indexOf('SMS')>=0 || channels.indexOf('Voice')>=0 || channels.indexOf('IVR')>=0)}
                  label='Emergency Phone'
                  placeholder='Emergency Phone' />
                  <Field name='emergencyRelationship' component={SelectField}
                  options={fieldOptions}
                  disabled= {!(channels.indexOf('Voice')>=0)}
                  label='Emergency Relationship'
                  placeholder='Emergency contact relationship' />
                  <Field name='otherPhone' component={SelectField}
                  options={fieldOptions}
                  disabled= {!(channels.indexOf('Voice')>=0)}
                  label='Other Phone'
                  placeholder='Other Phone' />
                  <Field name='otherRelationship' component={SelectField}
                  options={fieldOptions}
                  disabled= {!(channels.indexOf('Voice')>=0)}
                  label='Other Relationship'
                  placeholder='Other contact relationship' />
                </Form.Group>
                </>}
              </Segment>
            </>}

            {(!this.props.fixed || this.props.fixed=='0') && fieldOptions.length>0 && (this.props.campaignType=='Collection' || this.props.campaignType=='Restructuring' || this.props.campaignType=='Telesales' || this.props.campaignType=='Verification') &&
            <Segment>
              <Header as='h5' style={{fontWeight: 'bold'}}>
                <Header.Content>{this.props.campaignType}</Header.Content>
              </Header>

              {(this.props.campaignType=='Collection' || this.props.campaignType=='Restructuring') &&  <>
              <Form.Group widths='equal'>
                <Field name='f_billAmount' component={SelectField}
                options={fieldOptions}
                label='Bill Amount'
                placeholder='Bill Amount' />
                <Field name='f_paymentAmount' component={SelectField}
                options={fieldOptions}
                label='Payment Amount'
                placeholder='Payment Amount' />
                <Field name='f_billDate' component={SelectField}
                options={fieldOptions}
                label='Bill Date'
                placeholder='Bill Date' />
                <Field name='f_dueDate' component={SelectField}
                options={fieldOptions}
                label='Payment Due Date'
                placeholder='Payment Due Date' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='f_overdueAmount' component={SelectField}
                options={fieldOptions}
                label='Overdue Amount'
                placeholder='Overdue Amount' />
                <Field name='f_penaltyfeeAmount' component={SelectField}
                options={fieldOptions}
                label='Penalty Fee'
                placeholder='Penalty Fee Amount' />
                <Field name='f_overdueDay' component={SelectField}
                options={fieldOptions}
                label='Overdue Day'
                placeholder='Overdue Day' />
                <Field name='f_maxDPD' component={SelectField}
                options={fieldOptions}
                label='Max Days Past Due'
                placeholder='Max Days Past Due' />
              </Form.Group>
              
              <Divider hidden />
              <Form.Group widths='equal'>
                <Field name='f_principalAmount' component={SelectField}
                options={fieldOptions}
                label='Principal Amount'
                placeholder='Principal Amount' />
                <Field name='f_discountAmount' component={SelectField}
                options={fieldOptions}
                label='Discount Amount'
                placeholder='Discount Amount' />
                <Field name='f_interestAmount' component={SelectField}
                options={fieldOptions}
                label='Interest Amount'
                placeholder='Interest Amount' />
                <Field name='f_servicefeeAmount' component={SelectField}
                options={fieldOptions}
                label='Service Fee'
                placeholder='Service Fee Amount' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='f_service' component={SelectField}
                options={fieldOptions}
                label='Loan / Service Info'
                placeholder='Loan / Service Information' />
                <Field name='f_bucket' component={SelectField}
                options={fieldOptions}
                label='Bucket Info'
                placeholder='Bucket Info' />
              </Form.Group></>}

              {(this.props.campaignType=='Telesales' || this.props.campaignType=='Verification') && 
              <Form.Group widths='equal'>
                <Field name='f_product' component={SelectField}
                options={fieldOptions}
                label='Product / Service'
                placeholder='Product / Service' />
                <Field name='f_valuation' component={SelectField}
                options={fieldOptions}
                label='Amount / Valuation'
                placeholder='Amount / Valuation' />
                <Field name='f_paymentInfo' component={SelectField}
                options={fieldOptions}
                label='Payment Info'
                placeholder='Payment Info' />
              </Form.Group>}
            </Segment>}
          </>}

          {(this.props.isRedistribution=='0' || (this.props.isRedistribution==undefined && mode!=2)) && (!this.props.fixed || this.props.fixed=='0') &&  <>
            {fieldOptions.length>0 && <>
              <Segment attached='top'>
                <div style={{display: 'flex', marginBottom: '1.5em'}}>
                  <div style={{flex: '1'}}>
                    <Header as='h5' style={{fontWeight: 'bold'}}>
                      <Header.Content>
                        Extended Information
                        <Header.Subheader>Extended information.</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{width: '15em'}}>
                    <Header as='h5' style={{margin: '0', fontWeight: 'bold'}}>
                      <IconToggle active={this.state.showOptional=='1'} onClick={this.showOptional.bind(this, 1)} />
                      <Header.Content>
                        Optional
                        <Header.Subheader>Show Extended Data</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                </div>
                {this.state.showOptional=='1' && <>
                  <Form.Group widths='16'>
                    <Field name='f_priority' component={SelectField}
                    options={fieldOptions}
                    width={4}
                    label='Priority'
                    placeholder='Priority' />
                    <Field name='f_tagger' component={SelectField}
                    options={fieldOptions}
                    width={4}
                    label='Tagger'
                    placeholder='Tagger' />
                    <Field name='f_keyword' component={SelectField}
                    options={fieldOptions}
                    width={8}
                    label='Keyword / Labeling'
                    placeholder='Data labeling separated by comma' />
                  </Form.Group>
                </>}
              </Segment>
            </>}
          </>}

          {(this.props.isRedistribution=='0' || (this.props.isRedistribution==undefined && mode!=2)) && (!this.props.fixed || this.props.fixed=='0') &&  <>
            {fieldOptions.length>0 && <>
              <Segment attached='top'>
                <div style={{display: 'flex', marginBottom: '1.5em'}}>
                  <div style={{flex: '1'}}>
                    <Header as='h5' style={{fontWeight: 'bold'}}>
                      <Header.Content>
                        Personal Information
                        <Header.Subheader>Personal information.</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{width: '15em'}}>
                    <Header as='h5' style={{margin: '0', fontWeight: 'bold'}}>
                      <IconToggle active={this.state.showOptional=='2'} onClick={this.showOptional.bind(this, 2)} />
                      <Header.Content>
                        Optional
                        <Header.Subheader>Show Personal Data</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                </div>
                {this.state.showOptional=='2' && <>
                  <Form.Group widths='equal'>
                    <Field name='f_occupation' component={SelectField}
                    options={fieldOptions}
                    label='Occupation'
                    placeholder='Occupation' />
                    <Field name='f_jobTitle' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Job Title'
                    placeholder='Job Title' />
                    <Field name='f_jobDepartment' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Department'
                    placeholder='Department' />
                    <Field name='f_jobCompany' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Company'
                    placeholder='Company' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='f_revenue' component={SelectField}
                    options={fieldOptions}
                    label='Revenue'
                    placeholder='Revenue' />
                    <Field name='f_expense' component={SelectField}
                    options={fieldOptions}
                    label='Expense'
                    placeholder='Expense' />
                    <Field name='f_homestay' component={SelectField}
                    options={fieldOptions}
                    label='Home Ownership'
                    placeholder='Home Ownership' />
                    <Field name='f_vehicle' component={SelectField}
                    options={fieldOptions}
                    label='Vehicle Ownerhsip'
                    placeholder='Vehicle Ownerhsip' />
                    {/* <Field name='creditcard' component={SelectField}
                    options={fieldOptions}
                    label='Credit Card'
                    placeholder='Credit Card' /> */}
                  </Form.Group>
                </>}
              </Segment>
              {this.state.showOptional=='2' && <>
                <Segment attached='bottom'>
                  <Form.Group widths='equal'>
                    <Field name='f_gender' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Gender'
                    placeholder='L/P' />
                    <Field name='f_birthDate' component={DatePickerField}
                    label='Birth Date'
                    placeholder='Birth Date' />
                    <Field name='f_children' component={SelectField}
                    options={fieldOptions}
                    label='Children'
                    placeholder='Children' />
                    <Field name='f_marital' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Marital'
                    placeholder='Marital' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='f_address' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Address'
                    placeholder='Address' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='f_city' component={SelectField}
                    options={fieldOptions}
                    fluid
                    placeholder='City' />
                    <Field name='f_province' component={SelectField}
                    options={fieldOptions}
                    fluid
                    placeholder='Province' />
                    <Field name='f_country' component={SelectField}
                    options={fieldOptions}
                    fluid
                    placeholder='Country' />
                    <Field name='f_zipcode' component={SelectField}
                    options={fieldOptions}
                    fluid
                    placeholder='Zipcode' />
                  </Form.Group>

                  {/* <Divider hidden />
                  <Form.Group widths='equal'>
                    <Field name='f_appointmentDate' component={SelectField}
                    options={fieldOptions}
                    label='Appointment Date'
                    placeholder='Appointment Date' />
                    <Field name='f_appointmentTime' component={SelectField}
                    options={fieldOptions}
                    label='Appointment Time'
                    placeholder='Appointment Time' />
                    <Field name='f_appointmentTopic' component={SelectField}
                    options={fieldOptions}
                    label='Appointment Topic'
                    placeholder='Appointment Topic' />
                  </Form.Group> */}
                </Segment>
              </>}
            </>}
          </>}

          {(this.props.isRedistribution=='0' || (this.props.isRedistribution==undefined && mode!=2)) && (!this.props.fixed || this.props.fixed=='0') &&  <>
            {fieldOptions.length>0 && <>
              <Segment attached='top'>
                <div style={{display: 'flex', marginBottom: '1.5em'}}>
                  <div style={{flex: '1'}}>
                    <Header as='h5' style={{fontWeight: 'bold'}}>
                      <Header.Content>
                        Merchant Information
                        <Header.Subheader>Merchant information.</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{width: '15em'}}>
                    <Header as='h5' style={{margin: '0', fontWeight: 'bold'}}>
                      <IconToggle active={this.state.showOptional=='3'} onClick={this.showOptional.bind(this, 3)} />
                      <Header.Content>
                        Optional
                        <Header.Subheader>Show Merchant Data</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                </div>
                {this.state.showOptional=='3' && <>
                  <Form.Group widths='equal'>
                    <Field name='f_merchantCode' component={SelectField}
                    options={fieldOptions}
                    label='Merchant Code'
                    placeholder='Merchant Code' />
                    <Field name='f_merchantName' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Merchant Name'
                    placeholder='Merchant Name' />
                    <Field name='f_merchantWarehouse' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Merchant Warehouse'
                    placeholder='Merchant Warehouse' />
                    <Field name='f_merchantAcquisition' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Merchant Acquisition'
                    placeholder='Merchant Acquisition' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='f_previousAov' component={SelectField}
                    options={fieldOptions}
                    label='Previous AOV'
                    placeholder='Previous Average Order Value' />
                    <Field name='f_previousFreqOrder' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Previous Freq. Order'
                    placeholder='Previous Freq. Order' />
                    <Field name='f_currentAov' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Current AOV'
                    placeholder='Previous Freq. Order' />
                    <Field name='f_currentFreqOrder' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Current Freq. Order'
                    placeholder='Current Freq. Order' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='f_limitMaximum' component={SelectField}
                    options={fieldOptions}
                    label='Limit Maximum'
                    placeholder='Limit Maximum' />
                    <Field name='f_limitRemaining' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Limit Remaining'
                    placeholder='Limit Remaining' />
                    <Field name='f_previousMonthTrx' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Previous Month Trx.'
                    placeholder='Previous Month Trx.' />
                    <Field name='f_currentMonthTrx' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Current Month Trx.'
                    placeholder='Current Month Trx.' />
                  </Form.Group>
                </>}
              </Segment>
              {this.state.showOptional=='3' && <>
                <Segment attached='bottom'>
                  <Form.Group widths='equal'>
                    <Field name='f_project' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Project'
                    placeholder='Project' />
                    <Field name='f_retentionGmvStatus' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Retention GMV'
                    placeholder='Retention Gross Merchant Value' />
                    <Field name='f_retentionOrderStatus' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Retention Order Status'
                    placeholder='Retention Order Status' />
                    <Field name='f_retentionAppStatus' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Retention App Status'
                    placeholder='Retention App Status' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='f_retentionPhysicalSegment' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Retention Physical Segment'
                    placeholder='Retention Physical Segment' />
                    <Field name='f_retentionJourney' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Retention Journey'
                    placeholder='Retention Journey' />
                    <Field name='f_retentionAccess' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Retention Access'
                    placeholder='Retention Access' />
                    <Field name='f_retentionSegment' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Retention Segment'
                    placeholder='Retention Segment' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='f_retentionCountTrx' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Retention Count Trx'
                    placeholder='Retention Count Trx' />
                    <Field name='f_retentionGmvAccumulated' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Retention Accumulated GMV'
                    placeholder='Retention Accumulated Gross Merchant Value' />
                    <Field name='f_retentionCountTrxUngroup' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Retention Count Trx Ungroup'
                    placeholder='Retention Count Trx Ungroup' />
                    <Field name='f_retentionUninstallStatus' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Retention Uninstall Status'
                    placeholder='Retention Uninstall Status' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='f_retentionAppEngine' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Retention App Engine'
                    placeholder='Retention App Engine' />
                    <Field name='f_retentionOutboundCall' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Retention Outbound Called'
                    placeholder='Retention Outbound Called' />
                    <Field name='f_retentionRfm' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Retention RFM'
                    placeholder='Retention Recency, Frequency, and Monetary' />
                    <Field name='f_retentionChurnProgress' component={SelectField}
                    options={fieldOptions}
                    fluid
                    label='Retention Churn Progress'
                    placeholder='Retention Churn Progress' />
                  </Form.Group>
                </Segment>
              </>}
            </>}
          </>}

          <Segment style={{background: '#fafafa'}}>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Header as='h5' style={{margin: '0', fontWeight: 'bold'}}>
                  {/* <Icon name='user circle' style={{fontSize: '1.5em'}}/> */}
                  <Header.Content>
                    Target Audience / Upload Here
                    <Header.Subheader>Upload your target file here or grab your database before database field mapping.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{width: '15em'}}>
                {/* <Header as='h5' style={{margin: '0', fontWeight: 'bold'}}>
                  {(this.props.isRedistribution!=undefined || mode!=2) &&
                  <IconToggle active={this.props.isRedistribution=='1'} onClick={this.setMode.bind(this)} />}
                  <Header.Content>
                    {(this.props.isRedistribution=='1' && 'Redistribution') || 'Upload File'}
                    <Header.Subheader>
                      {(this.props.isRedistribution=='1' && 'From recent campaigns') || 'From uploaded file'}
                    </Header.Subheader>
                  </Header.Content>
                </Header> */}
              </div>
            </div>
            <Divider hidden />
            {this.props.isRedistribution=='1' &&<>
            <Form.Group widths='equal'>
              <Field name='redistCampaigns' component={SelectField}
              options={listCampaign || []}
              multiple
              label='Recent Reference Campaigns'
              placeholder='Recent Reference Campaigns' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='redistConditions' component={SelectField}
              options={redistOptions || []}
              multiple
              label='Redistribution Conditions'
              placeholder='Redistribution Conditions' />
            </Form.Group></>}
            {(this.props.isRedistribution=='0' || (this.props.isRedistribution==undefined && mode!=2)) &&
              <Form.Group widths='equal'>
                <Field name='contactFile' component={DataUploadField}
                samplingData={this.samplingData.bind(this)}
                label='Contact File'
                placeholder='Contact File' />
              </Form.Group>
            }
          </Segment>

          <Segment>
            {/* {this.state.fieldOptions.length} / {this.props.firstRow} */}
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2) && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Campaign' : 'Insert Campaign') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.campaignStore.getCampaign!=undefined && state.campaignStore.getCampaign.progress==1 ? state.campaignStore.getCampaign.data : {products: [], closingTerms: [], workgroups:[]}),
      formValues: getFormValues('CampaignForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      campaignType: selector(state, 'campaignType'),
      channels: selector(state, 'channels'),
      channel_1: selector(state, 'channel_1'),
      channel_2: selector(state, 'channel_2'),

      fixed: selector(state, 'fixed'),
      fileFormat: selector(state, 'fileFormat'),
      isRedistribution: selector(state, 'isRedistribution'),
      delimiter: selector(state, 'delimiter'),
      firstRow: selector(state, 'firstRow'),
    }),
    { load: getCampaign }
  )
  (reduxForm({
    form: 'CampaignForm',	// a unique identifier for this form
  })(CampaignForm)
  )
)
