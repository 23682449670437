import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { connect } from "react-redux"
import { callOmniphone, predictiveOmniphone } from "../../actions/stream/omniphoneAction"
import { browseDialer, statusDialer } from "../../actions/stream/dialerAction"
import { socketAgentStatus } from "../../actions/socket/socketAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,

    omniphoneStore: state.omniphoneStore,
    dialerStore: state.dialerStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),

    callOmniphone:(objParam) => dispatch(callOmniphone(objParam)),
    predictiveOmniphone:(objParam) => dispatch(predictiveOmniphone(objParam)),

    browseDialer:(objParam) => dispatch(browseDialer(objParam)),
    statusDialer:(objParam) => dispatch(statusDialer(objParam)),
  }
}

class DialerBrowse extends Component {
  constructor(props) {
    super(props)
    
    this.timers= []
    this.state= {
      calling: 0,
      color: 'teal',
      targetId: null,
      remark: true,
    }
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.dialerStore.browseDialer!==undefined ? this.props.dialerStore.browseDialer : {})
    this.props.browseDialer({search: {eventDate: format(new Date(), 'yyyy-MM-dd'), ...search}, paging: paging})
  }


  componentWillUnmount() {
    this.timers.map(timer=>{
      clearTimeout(timer);
    })
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.dialerStore.browseDialer!==undefined ? this.props.dialerStore.browseDialer : {})
    this.props.browseDialer(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  onChangeStatus(id, status, e) {
    // console.log(id, status, e)
    this.props.statusDialer({dialerId:id, isEnabled: status})
  }

  callOmniphone(predialType, targetId, campaignId, target) {
    this.setState({
      calling: 5,
      color: 'teal',
      targetId: 0,
      target: target,
    })
    this.props.callOmniphone({predialType: predialType, targetId: targetId, campaignId: campaignId})
    this.callingCounter()

    //set next 60 minute to predictive mode
    const parent= this;
    const timer= setTimeout(()=>{
      parent.props.predictiveOmniphone()
    }, 60000)

    this.timers.push(timer);
  }

  callingCounter() {
    const parent= this;
    const timer= setInterval(()=>{
      parent.setState({
        calling: parent.state.calling - 1,
        color: parent.state.calling%2==0 ? 'orange' : 'teal',
      })
      if (parent.state.calling<1) {
        clearInterval(timer);
      }
    }, 1000)
  }

  render() {
    //console.log(this.props.dialerStore.browseDialer)
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.dialerStore.browseDialer!==undefined ? this.props.dialerStore.browseDialer : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    const callResult= (this.props.omniphoneStore.callOmniphone!==undefined ? this.props.omniphoneStore.callOmniphone : null)
    const agentStatus= (this.props.socketStore.socketAgentStatus!==undefined ? this.props.socketStore.socketAgentStatus.data : undefined)
    const { dialings, status }= (agentStatus && agentStatus.payload.data) || {}

    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1', padding: '0'}}>
        <Table unstackable selectable columns='16' celled striped basic='very' style={{border: '1px dashed #ddd'}}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                {/* <Table.HeaderCell></Table.HeaderCell> */}
                <Table.HeaderCell width='6'>
                  <Header as='h5' color='blue'>
                    DIALED CONTACT
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='10'>
                  <Header as='h5' color='blue'>
                    REFF. CAMPAIGN
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={5} style={{padding: '1em 2em'}}><Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          {item.mobile || item.officePhone || item.homePhone || item.otherPhone || item.emergencyPhone}
                          <Header.Subheader><b>{item.name || 'No name'}</b></Header.Subheader>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        {item.campaign && 
                          <Header as='h5'>
                            <Header.Content>
                              {format(parse(item.campaign.startDate, 'yyyy-MM-dd', new Date()), 'dd / MMM, yyyy')}, {item.campaign.startTime}-{item.campaign.endTime}
                              <Header.Subheader>{item.campaign.name}</Header.Subheader>
                            </Header.Content>
                          </Header>
                        }
                      </Table.Cell>
                      <Table.Cell>
                        {(dialings && dialings.indexOf(item.targetId)>=0) &&
                          <Popup header='Ringing' content='Dialing on progress' trigger={<Label color='green' className='blink' style={{margin: '0', padding: '.5em .5em', whiteSpace: 'nowrap'}}><Icon color='red' name='phone' inverted circular style={{margin: '0', cursor: 'pointer'}}/> RINGING</Label>} />
                        }
                        {(!dialings || dialings.indexOf(item.targetId)<0) && (item.campaign && item.campaign.channels.indexOf('Voice')>=0) &&
                          <Dropdown pointing='top right' direction='left' icon='ellipsis vertical' inline>
                            <Dropdown.Menu>
                              {!item.predialCalls && <Dropdown.Item disabled= {(item.campaign && item.campaign.channels && item.campaign.channels.indexOf('Voice')<0) || status!='online'} onClick= {this.callOmniphone.bind(this, 'mobile', item.targetId, item.campaignId, item)}><Icon name='phone volume' color='blue'/>Call</Dropdown.Item>}
                              {item.predialCalls && item.predialCalls.map((predialType, i)=>{
                                return(
                                  <Popup content="Cost will be charged based on providers rate." inverted
                                    trigger={
                                      <Dropdown.Item key={i} disabled= {(item.campaign && item.campaign.channels && item.campaign.channels.indexOf('Voice')<0) || status!='online'} style={{color: ((item.campaign && item.campaign.channels && item.campaign.channels.indexOf('Voice')<0) || status!='online' ? '#333' : '#1b5cab')}} onClick= {this.callOmniphone.bind(this, predialType, item.targetId, item.campaignId, item)}><Icon name={(predialType=='Mobile' && 'mobile alternate') || (predialType=='Office Phone' && 'fax') || (predialType=='Home Phone' && 'phone') || (predialType=='Emergency Phone' && 'warning sign') || (predialType=='Other/Relative Phone' && 'home') || 'phone volume'} />Call {predialType || 'Phone'}</Dropdown.Item>
                                    } position='left center'
                                  />
                                )
                              })}
                              {/* <Divider style={{margin: '0'}}/>
                              <Dropdown.Item disabled ><Icon name='comment alternate outline' color='blue'/>Messaging</Dropdown.Item>
                              <Divider style={{margin: '0'}}/>
                              <Dropdown.Item disabled ><Icon name='share alternate' color='blue'/>Social Media</Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        }
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DialerBrowse)
