import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetOmniphone(objParam) {
  return {
      type: 'OMNIPHONE_RESET',
      payload: objParam
  }
}

export function accountOmniphone(objParam) {
  return {
      type: 'OMNIPHONE_ACCOUNT',
      payload:
        axios.post(
          urlAPI + 'omniphone/account',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function contactOmniphone(objParam) {
  return {
      type: 'OMNIPHONE_CONTACT',
      payload:
        axios.post(
          urlAPI + 'omniphone/contact',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function remarkOmniphone(objParam) {
  return {
      type: 'OMNIPHONE_REMARK',
      payload:
        axios.post(
          urlAPI + 'omniphone/remark',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function muteOmniphone(objParam) {
  return {
      type: 'OMNIPHONE_MUTE',
      payload:
        axios.post(
          urlAPI + 'omniphone/mute',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function callOmniphone(objParam) {
  return {
      type: 'OMNIPHONE_CALL',
      payload:
        axios.post(
          urlAPI + 'omniphone/call',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function hangupOmniphone(objParam) {
  return {
      type: 'OMNIPHONE_HANGUP',
      payload:
        axios.post(
          urlAPI + 'omniphone/hangup',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function knowledgeOmniphone(objParam) {
  return {
      type: 'OMNIPHONE_KNOWLEDGE',
      payload:
        axios.post(
          urlAPI + 'omniphone/knowledge',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function predictiveOmniphone(objParam) {
  return {
      type: 'OMNIPHONE_PREDICTIVE',
      payload:
        axios.post(
          urlAPI + 'omniphone/predictive',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function chatOmniphone(objParam) {
  return {
    type: 'OMNIPHONE_CHAT',
    payload:
      axios.post(
        urlAPI + 'omniphone/chat',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function mesgOmniphone(objParam) {
  return {
    type: 'OMNIPHONE_MESG',
    payload:
      axios.post(
        urlAPI + 'omniphone/mesg',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function spyAgent(objParam) {
  return {
    type: 'OMNIPHONE_SPY',
    payload:
      axios.post(
        urlAPI + 'omniphone/spy',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function whisperAgent(objParam) {
  return {
    type: 'OMNIPHONE_WHISPER',
    payload:
      axios.post(
        urlAPI + 'omniphone/whisper',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function dtmfOmniphone(objParam) {
  return {
    type: 'OMNIPHONE_DTMF',
    payload:
      axios.post(
        urlAPI + 'omniphone/dtmf',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}
