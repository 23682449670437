import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Button } from 'semantic-ui-react'

import MessagingOutboundBrowse from '../../libraries/whatsapp/MessagingOutboundBrowse'
import MessagingOutboundSearch from '../../libraries/whatsapp/MessagingOutboundSearch'

class MessagingOutboundBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, messagingId: id})
    }

    render() {
      const {mode, messagingId}= this.state
      const {screenWidth, screenHeight, module}= this.props

      return(
        <Container fluid>
          <MessagingOutboundSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
          <div>
            {mode==1 && <MessagingOutboundBrowse campaignId={this.props.params && this.props.params.campaignId} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
          </div>
        </Container>
      )
    }
}

export default MessagingOutboundBody