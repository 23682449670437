import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'
import ModalContainer from '../../libraries/common/ModalContainer';

//STREAM - OUTBOUND CAMPAIGN
import CollectionBody from '../stream/CollectionBody'
import RestructuringBody from '../stream/RestructuringBody'
import TelesaleBody from '../stream/TelesaleBody'
import VerificationBody from '../stream/VerificationBody'

import CampaignBody from '../stream/CampaignBody'
import TargetBody from '../stream/TargetBody'
import AssignBody from '../stream/AssignBody'

import EngagementBody from '../stream/EngagementBody'

class AdminBodyOutbound extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }
  
  render() {
    const { mode, module, screenWidth, screenHeight, mini, modal, params }= this.props

    return (
      <div>
        {mode==='collection' && <CollectionBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
        {mode==='restructuring' && <RestructuringBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
        {mode==='telesale' && <TelesaleBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
        {mode==='verification' && <VerificationBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}

        {mode==='campaign' && <CampaignBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
        {mode==='target' && <TargetBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
        {mode==='assignment' && <AssignBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
        
        {modal==='engagement' && <ModalContainer size='small' content={<EngagementBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal.bind(this)}  params={params} />} closeModal={this.props.closeModal.bind(this)} /> }
      </div>
    )
  }
}

export default AdminBodyOutbound