import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import WhitelistBrowse from '../../libraries/halo/WhitelistBrowse'
import WhitelistForm from '../../libraries/halo/WhitelistForm'
import WhitelistSearch from '../../libraries/halo/WhitelistSearch'


class WhitelistBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        whitelistId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, whitelistId: id})
    }

    closeModal() {
      this.setState({
        mode: 1,
        whitelistId: 0,
      });
      this.props.closeModal() 
    }

    render() {
      const {mode, whitelistId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div>
            <WhitelistSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {<WhitelistBrowse setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}/>}
              {/* {mode==2 && <WhitelistForm mode={mode} whitelistId={whitelistId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <WhitelistForm mode={mode} whitelistId={0} setMode={this.setMode.bind(this)} />} */}
              {mode==2 && <ModalContainer size='large' content={<><WhitelistSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} /><WhitelistForm mode={mode} whitelistId={whitelistId} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} /></>} closeModal={this.closeModal.bind(this)} /> }
              {mode==3 && <ModalContainer size='large' content={<><WhitelistSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} /><WhitelistForm mode={mode} whitelistId={0} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} /></>} closeModal={this.closeModal.bind(this)} /> }
            </div>
          </div>
        </Container>
      )
    }
}

export default WhitelistBody
