import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Label, Statistic } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';
import DismissableMessage from '../common/DismissableMessage';
import IconToggle from '../common/IconToggle';


import { hourOptions, importOptions, yesnoOptions, redistOptions, delimiterOptions, fieldTargetOptions }  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form'
import { ImageUploadField, LabelInputField, InputField, CheckboxField, NumberField, DatePickerField, SelectField, TextAreaField, DataUploadField } from '../validation/componentrsui'
import { required, confirmedPassword, phoneNumberID, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { checkToken } from "../../actions/auth/authAction"
import { browseCampaign, resetCampaign, getCampaign,updateCampaign, createCampaign } from "../../actions/stream/campaignAction"
import { listWorkgroup, listTemplate, listProduct, listCampaign, collectionStage, restructuringStage, telesaleStage, verificationStage, listParams } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('CampaignForm')

const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
    masterStore: state.masterStore,
    campaignStore: state.campaignStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),

    // listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    // listTemplate:(objParam) => dispatch(listTemplate(objParam)),
    // listProduct:(objParam) => dispatch(listProduct(objParam)),
    listCampaign:(objParam) => dispatch(listCampaign(objParam)),
    listParams:(objParam) => dispatch(listParams(objParam)),

    // collectionStage:(objParam) => dispatch(collectionStage(objParam)),
    // restructuringStage:(objParam) => dispatch(restructuringStage(objParam)),
    // telesaleStage:(objParam) => dispatch(telesaleStage(objParam)),
    // verificationStage:(objParam) => dispatch(verificationStage(objParam)),

    browseCampaign:(objParam) => dispatch(browseCampaign(objParam)),
    resetCampaign:(objParam) => dispatch(resetCampaign(objParam)),
    getCampaign:(objParam) => dispatch(getCampaign(objParam)),
    createCampaign:(objParam) => dispatch(createCampaign(objParam)),
    updateCampaign:(objParam) => dispatch(updateCampaign(objParam)),
  }
}

class CampaignForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      modalScript: false,
      modalWorkgroup: false,
      modalProduct: false,
      fields: [],
      variables: null,

      isAlternative: false,
      isInit: false,

      showOptional:false,
      step: 0,

      isUpdated: false,
    }
  }

  componentDidMount(){
    this.props.resetCampaign(this.props.mode)

    if (this.props.mode==2) { //update
      this.props.getCampaign({campaignId: this.props.campaignId})
    }
    
    this.props.listCampaign();

    // this.props.listWorkgroup({search: {clientId: this.props.clientId!=undefined ? this.props.clientId : null}})
    // this.props.listTemplate({search: {clientId: this.props.clientId!=undefined ? this.props.clientId : null}})
    // this.props.listProduct({search: {clientId: this.props.clientId!=undefined ? this.props.clientId : null}})
    
    this.props.listParams({clientId: this.props.clientId!=undefined ? this.props.clientId : null})

    // this.props.collectionStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
    // this.props.restructuringStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
    // this.props.telesaleStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
    // this.props.verificationStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
  }

  componentWillUnmount() {
    const { search, paging }= (this.props.campaignStore.browseCampaign!==undefined ? this.props.campaignStore.browseCampaign : {})
    this.state.isUpdated && this.props.browseCampaign(
      {
        paging: paging,
        search: search
      }
    )
  }

  componentDidUpdate(prevProps) {
    const { fields }= this.state
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.campaignStore.updateCampaign!=undefined ? this.props.campaignStore.updateCampaign : {}) : (this.props.campaignStore.createCampaign!=undefined ? this.props.campaignStore.createCampaign : {}))
    
    if (progress==1 && code=='00') {
      setTimeout(()=>{
        this.props.closeModal();
      }, 2000)
    }
    
    if (fields.length==0 && (this.props.firstRows || this.props.firstRow)) {
      // this.parseFields(this.props.firstRows, this.props.firstRow.replace(/(\r\n|\n|\r)/gm, ''));
      this.parseFields(this.props.firstRows, this.props.firstRow && this.props.firstRow.replace(/(\r\n|\n|\r)/gm, ''));
    } else if (fields.length>0 && !(this.props.firstRows || this.props.firstRow)) {
      this.setState({
        fields: [],
      })
    }

    if (!this.state.isInit && this.props.channel_2 && this.props.campaignStore.getCampaign.progress==1) {
      this.setState({
        isAlternative: true,
        isInit: true,
      })
    }

    if (prevProps.scriptExt!=this.props.scriptExt) {
      // console.log('!!! PROPS SCRIPT', prevProps.scriptExt, this.props.scriptExt)

      const vars= new Map()
      const params = this.props.scriptExt && this.props.scriptExt.match(/{[a-zA-Z_]*}/g);
      
      // console.log('!!! PARAMS', params)
      params && params.map(item=>{
        !vars.get(item) && vars.set(item)
      })
      this.setState({
        variables: params && params.length>0 ? vars : null,
      })
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    if (this.state.step==3 || this.state.step==4 || this.state.step==5 || this.state.step==6) {
      
      // this.state.step==3 && this.props.dispatch(change('WizardForm', 'isDevice', 1)); //import device contact
      // this.state.step==5 && this.props.dispatch(change('WizardForm', 'isRedistribution', 1)); //redistribute from previous campaigns
      this.setState({
        step: 0,
      })  
    } else {
      // console.log('!!! SUBMIT: ', values)
      const conditions= []
      this.props.hasDelivered=='1' && conditions.push('Delivered')
      this.props.hasRead=='1' && conditions.push('Read')
      this.props.hasClicked=='1' && conditions.push('Clicked')

      mode==2 ? this.props.updateCampaign({...values, ...((this.props.isRedistribution!='0' && {imported: null}) || {}), redistConditions: conditions, qtyChannel: 1, channel_1: 'Whatsapp'}) : this.props.createCampaign({...values, ...((this.props.isRedistribution!='0' && {imported: null}) || {}), redistConditions: conditions, qtyChannel: 1, channel_1: 'Whatsapp'})

      this.setState({
        isUpdated: true,
      })
    }
  }

  setMode(key) {
    if (key=='isAlternative') {
      this.setState({
        isAlternative: !this.state.isAlternative
      })
      this.props.dispatch(change('CampaignForm', 'channel_2', null ));
    } else if (key=='fixed') {
      const { fileParams }= this.props.masterStore.listParams && this.props.masterStore.listParams.data ? this.props.masterStore.listParams.data : {}
      if (this.props.fixed==undefined || this.props.fixed=='0') {
        this.props.dispatch(change('CampaignForm', 'fixed','1' ));
        if (!this.props.fileFormat && fileParams) {
          this.props.dispatch(change('CampaignForm', 'fileFormat', fileParams.fileFormat ));
        }
      } else {
        this.props.dispatch(change('CampaignForm', 'fixed','0' ));
      } 
    } else {
      if (this.props.isRedistribution==undefined || this.props.isRedistribution=='0') {
        this.props.dispatch(change('CampaignForm', 'isRedistribution','1' ));
      } else {
        this.props.dispatch(change('CampaignForm', 'isRedistribution','0' ));
      } 
    }
  }

  samplingData(v) {
    if (v!=null) {
      // console.log('!!! SAMPLING DATA', v)
      const data= JSON.parse(v);
      this.props.dispatch(change('CampaignForm', 'firstRows', data.firstRows));
      this.props.dispatch(change('CampaignForm', 'firstRow', data.firstRow && data.firstRow.replace(/(\r\n|\n|\r)/gm, '')));
      this.props.dispatch(change('CampaignForm', 'delimiter', null ));
      this.props.dispatch(change('CampaignForm', 'imported', null ));
      // this.parseFields(data.firstRow && data.firstRow.replace(/(\r\n|\n|\r)/gm, ''));
      this.parseFields(data.firstRows, data.firstRow && data.firstRow.replace(/(\r\n|\n|\r)/gm, ''));
    }
  }

  parseFields(firstRows, firstRow) {
    var fields= [];
    fields.push({ key: 0, value: '', text: '! None / Null' })
    
    if (firstRows && firstRows.length>0) {
      firstRows.map((item,index)=>{
        fields.push({ key: ++index, value: item, text: item })
      })

      // console.log('!!! FIRSTROWS', fields, firstRows)
    } else {
      if (firstRow && (this.props.delimiter==undefined || this.props.delimiter==null)) {
        if (firstRow.split('", "').length>=3) {
          firstRow.split('", "').map((field, index)=>{
            fields.push({ key: ++index, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
          this.props.dispatch(change('CampaignForm', 'delimiter','", "' ));
        } else if (firstRow.split('","').length>=3) {
          firstRow.split('","').map((field, index)=>{
            fields.push({ key: ++index, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
          this.props.dispatch(change('CampaignForm', 'delimiter','","' ));
        } else if (firstRow.split(',').length>=3) {
          firstRow.split(',').map((field, index)=>{
            fields.push({ key: ++index, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
          this.props.dispatch(change('CampaignForm', 'delimiter',',' ));
        } else if (firstRow.split('"; "').length>=3) {
          firstRow.split('"; "').map((field, index)=>{
            fields.push({ key: ++index, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
          this.props.dispatch(change('CampaignForm', 'delimiter','"; "' ));
        } else if (firstRow.split('";"').length>=3) {
          firstRow.split('";"').map((field, index)=>{
            fields.push({ key: ++index, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
          this.props.dispatch(change('CampaignForm', 'delimiter','";"' ));
        }
      } else {
        if (firstRow && firstRow.split(this.props.delimiter).length>=3) {
          firstRow.split(this.props.delimiter).map((field, index)=>{
            fields.push({ key: ++index, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
        }
      }
      
      // console.log('!!! FIRSTROW', fields, this.props.delimiter, firstRow)
    }

    this.setState({fields: fields});
  }

  toggleModal(v) {
    switch(v) {
      case 'script':
        this.setState({
          modalScript: !this.state.modalScript,
        })
      break;
      case 'workgroup':
        this.setState({
          modalWorkgroup: !this.state.modalWorkgroup,
        })
      break;
      case 'product':
        this.setState({
          modalProduct: !this.state.modalProduct,
        })
      break;
      default:
        this.setState({
          modalScript: false,
          modalWorkgroup: false,
          modalProduct: false,
        })
    }
  }

  showOptional(v) {
    this.setState({
      showOptional: (v ==this.state.showOptional) ? 0 : v,
    })
  }

  setDynamic() {
    this.setState({isDynamic: !this.state.isDynamic})
  }
  insertVariable(v) {
    // console.log(v)
    if (this.props.media!='Email') {
      this.props.dispatch(change('CampaignForm', 'scriptExt', (this.props.scriptExt||'') +' '+ v +' '));
    } else {
      navigator.clipboard.writeText(v)
    }
    // e.target.focus()
  }

  clickStep(v) {
    this.setState({
      step: v
    })
  }
  stepImport(v) {
    return(
      <div>
        <div >
          <div>
            <div style={{textAlign: 'center'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  Import <b>contacts</b> from Whatsapp ? 
                  <Header.Subheader>
                    Accessing contacts from your Whatsapp, contact is <b>manageable</b> by user.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <Divider style={{margin: '2em 0'}}/>
            {/* <div style={{textAlign: 'center', paddingTop: '2em'}}>
              <Label style={{width: '20em'}}>
                <Statistic horizontal style={{margin: '0'}}>
                  <Statistic.Value>2,204</Statistic.Value>
                  <Statistic.Label>CONTACTS</Statistic.Label>
                </Statistic>
                <Divider />
                Click import to edit and save contacts for current campaign.
              </Label>
            </div> */}
            <Label style={{display: 'block', padding: '1.5em 1em'}}>
              <div style={{display: 'flex'}}>
                <div style={{borderRight: '1px solid #fff', paddingRight: '1em'}}>
                  <Statistic horizontal style={{margin: '0'}}>
                    <Statistic.Value>WA</Statistic.Value>
                    <Statistic.Label>CONTACTS</Statistic.Label>
                  </Statistic>
                </div>
                <div style={{flex: '1', textAlign: 'left', borderLeft: '1px solid #999', paddingLeft: '1em', lineHeight: '1.3em'}}>
                  Import contacts from your connected Whatsapp / Handphone to current campaign.
                </div>    
              </div>
            </Label>
          </div>
        </div>
        {/* <div style={{display: 'flex', marginTop: '2em'}}>
          <div style={{flex: 1}}>
            <Button disabled={!(v.progress!=1 || v.code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this,0)}> Back</Button>
          </div>
          <div>
            <Button color='blue' size='large'> Import</Button>
          </div>
        </div> */}
      </div>
    )
  }
  stepUpload(v) {
    let fields= [];

    return(
      <div>
        <div >
          <div>
            <div style={{textAlign: 'center'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  Upload <b>contacts</b> from file ? 
                  <Header.Subheader>
                    Get contact from uploaded file, contact is <b>manageable</b> by user.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <Divider style={{margin: '2em 0'}}/>
            {this.state.fields.length>0 && <div>
              <Form.Group widths='16'>
                <Field name='f_whatsapp' component={SelectField}
                width={8}
                validate={[required, phoneNumberID]}
                options={this.props.contactFile && this.state.fields}
                label='Whatsapp'
                placeholder='Whatsapp Number' />
              </Form.Group>
              {
                this.state.variables && [...this.state.variables.keys()].map((item, idx)=>{
                  fields[idx]= <Field key={idx} name={'f_'+ item.replace(/{|}/gm,'')} component={SelectField}
                  validate={[required]}
                  options={this.props.contactFile && this.state.fields}
                  label={item.replace(/{|}/gm,'')}
                  placeholder={item.replace(/{|}/gm,'')} />;

                  if ([...this.state.variables.keys()].length==(idx+1) || idx%2==1) {
                    if (idx%2==1) {
                      return(
                        <Form.Group key={idx} widths='equal'>
                          {fields[idx-1]} {fields[idx]}
                        </Form.Group>      
                      )
                    } else {
                      return(
                        <Form.Group key={idx} widths='equal'>
                          {fields[idx]}
                        </Form.Group>      
                      )
                    }
                  }
                })
              }
              <Divider style={{margin: '2em 0'}}/>
            </div>}
            <Form.Group widths='equal'>
              <Field name='contactFile' component={DataUploadField}
              validate={[required]}
              samplingData={this.samplingData.bind(this)}
              label='Contact File'
              placeholder='Contact File' />
            </Form.Group>
          </div>
        </div>
        {/* <div style={{display: 'flex', marginTop: '2em'}}>
          <div style={{flex: 1}}>
            <Button disabled={!(v.progress!=1 || v.code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this,0)}> Back</Button>
          </div>
          <div>
            <Button color='blue' size='large'> Upload</Button>
          </div>
        </div> */}
      </div>
    )
  }
  stepRedistribute(v) {
    return(
      <div>
        <div >
          <div>
            <div style={{textAlign: 'center'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  Get <b>contacts</b> from prev. campaigns ? 
                  <Header.Subheader>
                    Get contact from previous campaign, contact is <b>manageable</b> by user.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <Divider style={{margin: '2em 0'}}/>
            <Form.Group widths='equal'>
              <Field name='redistCampaigns' component={SelectField}
              multiple
              validate={[required]}
              options={v.listCampaign}
              label='Prev. Campaigns'
              placeholder='Reference to prev. campaigns' />
            </Form.Group>
            
            <Divider hidden />
            <Header as='h5' color='blue'style={{fontSize: '1em'}}>
              <Header.Content>
                <label color='blue'>Filtered Contact</label>
              </Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='hasDelivered' component={CheckboxField}
              label={<Label color='green'><b>Sent / Delivered</b></Label>}
              placeholder='Delivered' />
              <Field name='hasRead' component={CheckboxField}
              label={<Label color='orange'><b>Read / Viewed</b></Label>}
              placeholder='Read' />
              <Field name='hasClicked' component={CheckboxField}
              disabled
              readOnly
              label={<Label color='red'><b>Clicked Page/Link</b></Label>}
              placeholder='Clicked' />
            </Form.Group>
          </div>
        </div>
        {/* <div style={{display: 'flex', marginTop: '2em'}}>
          <div style={{flex: 1}}>
            <Button disabled={!(v.progress!=1 || v.code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this,0)}> Back</Button>
          </div>
          <div>
            <Button color='blue' size='large'>Import</Button>
          </div>
        </div> */}
      </div>
    )
  }

  render() {
    const { step, fields, modalScript, modalWorkgroup, modalProduct, isAlternative }= this.state
    const { error, handleSubmit, pristine, reset, submitting, valid, mode, screenWidth } = this.props
    
    const { data }= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken : {}
    const { progress, code, text }= (mode==2 ? (this.props.campaignStore.updateCampaign!=undefined ? this.props.campaignStore.updateCampaign : {}) : (this.props.campaignStore.createCampaign!=undefined ? this.props.campaignStore.createCampaign : {}))
    // const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    // const listTemplate= (this.props.masterStore.listTemplate!=undefined && this.props.masterStore.listTemplate.progress=='1' ? this.props.masterStore.listTemplate.data : [])
    // const listProduct= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    const listCampaign= (this.props.masterStore.listCampaign!=undefined && this.props.masterStore.listCampaign.progress=='1' ? this.props.masterStore.listCampaign.data : [])

    const isApiCampaign= this.props.name && this.props.name.substr(0,8)=='API-AUTO'
    // const collectionStage= (this.props.masterStore.collectionStage!=undefined && this.props.masterStore.collectionStage.progress=='1' ? this.props.masterStore.collectionStage.data : [])
    // const restructuringStage= (this.props.masterStore.restructuringStage!=undefined && this.props.masterStore.restructuringStage.progress=='1' ? this.props.masterStore.restructuringStage.data : [])
    // const telesaleStage= (this.props.masterStore.telesaleStage!=undefined && this.props.masterStore.telesaleStage.progress=='1' ? this.props.masterStore.telesaleStage.data : [])
    // const verificationStage= (this.props.masterStore.verificationStage!=undefined && this.props.masterStore.verificationStage.progress=='1' ? this.props.masterStore.verificationStage.data : [])

    const { svcCampaigns, svcRatios, svcChannels }= this.props.masterStore.listParams && this.props.masterStore.listParams.data ? this.props.masterStore.listParams.data : {}

    const campaignOptions= svcCampaigns
    const channelOptions= svcChannels
    const ratioOptions= svcRatios
    
    // const filteredListTemplate= [{ key: 0, value: null, text: 'No Template', icon: 'code'}];
    // if (this.props.channel_1 && listTemplate.length>0) {
    //   listTemplate.map(item=>{
    //     item.media==this.props.channel_1 && filteredListTemplate.push(item);
    //   })
    // }
    // const filteredListTemplate_1= [{ key: 0, value: null, text: 'No Template', icon: 'code'}];
    // if (this.props.channel_2 && listTemplate.length>0) {
    //   listTemplate.map(item=>{
    //     item.media==this.props.channel_2 && filteredListTemplate_1.push(item);
    //   })
    // }

    // const channels= [];
    // this.props.channel_1 && channels.push(this.props.channel_1)
    // this.props.channel_2 && channels.push(this.props.channel_2)
    
    // console.log(pristine, progress);
    
    return (<>
      <div style={{marginTop: '-3em', height: '3em'}}>
        <Button color='blue' onClick={this.props.openModal.bind(this, 'campaign', this.props.params)}><Icon name='edit' />Campaign</Button>
        <Button disabled={!(pristine || progress==1)} color='grey' onClick={this.props.openModal.bind(this, 'target', this.props.params)}><Icon name='user circle' />Target</Button>
        <Button disabled={!(pristine || progress==1)} color='grey' onClick={this.props.openModal.bind(this, 'messaging', this.props.params)}><Icon name='check square outline' />Delivery</Button>
        <Button disabled={!(pristine || progress==1)} color='grey' onClick={this.props.openModal.bind(this, 'performance', this.props.params)}><Icon name='chart line' />Performance</Button>
      </div>
      <div style={{padding: '1em 1em'}}>
        <div style={{textAlign: 'center'}}>
          <Header as='h2' color='blue'>
            <Header.Content>
              <b>Campaign's Setup !</b>
              <Header.Subheader>
                Manage campaign's schedule, content and contacts.
              </Header.Subheader>
            </Header.Content>
          </Header>
        </div>

        <Divider style={{margin: '2em 0 2em 0'}}/>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='16' style={{marginTop: '2em'}}>
              <Field name='isEnabled' component={CheckboxField}
              toggle
              readOnly={isApiCampaign}
              width={8}
              caption='Enable Now !'
              placeholder='Status enable/disabled' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              width={8}
              readOnly={isApiCampaign}
              validate={[required]}
              label='Campaign Name'
              placeholder='Campaign Name' />
              <Field name='campaignType' component={isApiCampaign ? InputField : SelectField}
              width={8}
              readOnly={isApiCampaign}
              options={campaignOptions}
              validate={[required]}
              label='Campaign Type'
              placeholder='Campaign Type' />
            </Form.Group>
          </Segment>
          
          <Segment>
            <Header as='h5'>
              <Icon name='calendar alternate outline' style={{fontSize: '1em'}}/>
              <Header.Content>
                Campaign Schedule
                {/* <Header.Subheader>Schedule when campaign will be active and deliver contents</Header.Subheader> */}
              </Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='startDate' component={DatePickerField}
              readOnly={isApiCampaign}
              validate={[required]}
              label='Start Date'
              placeholder='Start Date' />
              <Field name='endDate' component={DatePickerField}
              readOnly={isApiCampaign}
              validate={[required]}
              label='End Date'
              placeholder='End Date' />
              <Field name='startTime' component={isApiCampaign ? InputField : SelectField}
              fluid
              options= {hourOptions()}
              readOnly={isApiCampaign}
              validate={[required]}
              label='Start Time'
              placeholder='Start Time' />
              <Field name='endTime' component={isApiCampaign ? InputField : SelectField}
              fluid
              options= {hourOptions()}
              readOnly={isApiCampaign}
              validate={[required]}
              label='End Time'
              placeholder='End Time' />
            </Form.Group>
          </Segment>

          {!isApiCampaign &&
            <Segment>
              <Header as='h5'>
                <Icon name='image outline' style={{fontSize: '1em'}}/>
                <Header.Content>
                  Broadcast Content
                  {/* <Header.Subheader>Schedule when campaign will be active and deliver contents</Header.Subheader> */}
                </Header.Content>
              </Header>
              <div>
                <div className='variable' style={{margin: '0 0 1em 0'}}>
                  <div style={{display: 'flex', marginBottom: '1.5em'}}>
                    <div style={{flex: '1'}}>
                      <Header as='h5' color='blue'>
                        <Header.Content>
                          <b>Content Script</b>
                          <Header.Subheader>Set content script type, static or dynamic on variable basis.</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>
                    <div>
                      <Header as='h5' style={{margin: '0', fontWeight: 'bold'}}>
                        <IconToggle active={this.state.isDynamic==true} onClick={this.setDynamic.bind(this)} />
                        <Header.Content>
                          Variables
                          <Header.Subheader>Click Variable Options</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>
                  </div>
                  {this.state.isDynamic==true && 
                    <Label.Group>
                      <Popup header='{custName}' content='Customer or target name' trigger={
                        <Label as='a' onClick={this.insertVariable.bind(this, '{custName}')}>Customer Name</Label> 
                      } />
                      <Popup header='{billAmount}' content='Bill amount which must paid' trigger={
                        <Label as='a' onClick={this.insertVariable.bind(this, '{billAmount}')}>Bill Amount</Label>
                      } />
                      <Popup header='{overdueAmount}' content='Penalty or fine amount' trigger={
                        <Label as='a' onClick={this.insertVariable.bind(this, '{overdueAmount}')}>Penalty Amount</Label>
                      } />
                      <Popup header='{overdueDay}' content='Payment was late in days' trigger={
                        <Label as='a' onClick={this.insertVariable.bind(this, '{overdueDay}')}>Late In Days</Label> 
                      } />
                      <Popup header='{service}' content='Subscription product or service information' trigger={
                        <Label as='a' onClick={this.insertVariable.bind(this, '{service}')}>Subscription Info</Label>
                      } />
                      <Popup header='{billDate}' content='Date when billing generated' trigger={
                        <Label as='a' onClick={this.insertVariable.bind(this, '{billDate}')}>Billing Date</Label>
                      } />
                      <Popup header='{dueDate}' content='Due date when bill should be paid ' trigger={
                        <Label as='a' onClick={this.insertVariable.bind(this, '{dueDate}')}>Payment Due Date</Label>
                      } />
                  
                      <Popup header='{product}' content='Product information / name' trigger={
                        <Label as='a' onClick={this.insertVariable.bind(this, '{product}')}>Product</Label>
                      } />
                      <Popup header='{information}' content='General information which important for customers' trigger={
                        <Label as='a' onClick={this.insertVariable.bind(this, '{information}')}>Information</Label>
                      } />
                      <Popup header='{merchantName}' content='Merchant information / name' trigger={
                        <Label as='a' onClick={this.insertVariable.bind(this, '{merchantName}')}>Merchant Name</Label>
                      } />
                      <Popup header='{custRef}' content='Customer ref. identity' trigger={
                        <Label as='a' onClick={this.insertVariable.bind(this, '{custRef}')}>Cust. Ref. Id</Label>
                      } />
                      <Popup header='{contractRef}' content='Contract ref. for customer' trigger={
                        <Label as='a' onClick={this.insertVariable.bind(this, '{contractRef}')}>Cust. Contract</Label>
                      } />
                      <Popup header='{city}' content='City where customer stays' trigger={
                        <Label as='a' onClick={this.insertVariable.bind(this, '{city}')}>City</Label>
                      } />
                    </Label.Group>
                  }
                </div>
                <div style={{flex: '1'}}>
                  <Form.Group widths='16'>
                    <Field name='scriptExt' component={TextAreaField}
                    width='16'
                    rows='8'
                    // validate={[required]}
                    // label='Broadcast Content Script' 
                    />
                  </Form.Group>
                  {/* {(this.props.media=='Email' || this.props.media=='Whatsapp' || this.props.media=='Telegram' || this.props.media=='Facebook' || this.props.media=='Twitter' || this.props.media=='Instagram') && <>
                    <Divider hidden/>
                    <Form.Group widths='equal' style={{marginTop: '2em'}}>
                      <Form.Field>
                        <Field name='image' component={ImageUploadField}
                        // width={420} 
                        height={420} 
                        aspectRatio= '1:1' />
                      </Form.Field>
                    </Form.Group>
                  </>} */}
                  <Label.Group>
                    <Label style={{fontWeight: '300'}}>_italic_ : <i>italic</i></Label>
                    <Label style={{fontWeight: '300'}}>*bold*: <b>bold</b></Label>
                    <Label style={{fontWeight: '300'}}>~strikethrough~: <span style={{textDecoration: 'line-through'}}>strikethrough</span></Label>
                  </Label.Group>
                </div>
              </div>
            </Segment>
          }

          {!isApiCampaign &&
            <Segment>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Header as='h5' style={{margin: '0'}}>
                    <Icon name='user circle' style={{fontSize: '1em'}}/>
                    <Header.Content>
                      Target's Contact
                      <Header.Subheader>Set target by import form handphone, file or prev. campaign.</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div style={{width: '13em', marginLeft: '1em'}}>
                  {/* <Button type='button' icon size='big' onClick={this.props.openModal.bind(this, 'target', {...this.props.params, variables: this.state.variables})} style={{padding: '.15em .1em', margin: '0'}}>
                    <Icon name='window maximize outline' color='blue' />
                  </Button> */}
                  {data && 
                  <Form.Group widths='equal'>
                    <Field name='isRedistribution' component={SelectField} style={{fontWeight: 'bold'}}
                    options={importOptions(data.packageId)}
                    validate={[required]}
                    // label='Campaign Type'
                    placeholder='Contact Source' />
                  </Form.Group>
                  }
                </div>
              </div>
              {/* <div>
                <Label as='a' onClick={this.clickStep.bind(this, 3)}><Icon name='address card outline' /> Import Contact</Label>
                <Label as='a' onClick={this.clickStep.bind(this, 4)}><Icon name='file alternate outline' /> Upload Contact</Label>
                <Label as='a' onClick={this.clickStep.bind(this, 5)}><Icon name='recycle' /> Redistribute</Label>
              </div> */}
              
              <Divider />
              {(step==0 && !this.props.isRedistribution) &&
                <div style={{textAlign: 'center', padding: '1em 0 2em 0'}}>
                  <Label style={{padding: '1.5em 1em'}}>
                    <div style={{display: 'flex'}}>
                      <div style={{borderRight: '1px solid #fff', paddingRight: '1em'}}>
                        <Statistic horizontal style={{margin: '0'}}>
                          <Statistic.Value>{this.props.totTarget}</Statistic.Value>
                          <Statistic.Label>CONTACTS</Statistic.Label>
                        </Statistic>
                      </div>
                      <div style={{flex: '1', textAlign: 'left', borderLeft: '1px solid #999', paddingLeft: '1em', lineHeight: '1.3em'}}>
                        Targets are available from your Whatsapp / Handphone Contacts, Import from File or Redistribute from Campaigns.
                      </div>    
                    </div>
                  </Label>
                </div>
              }

              {this.props.isRedistribution &&
                <Divider />
              }
              {(step==3 || this.props.isRedistribution=='2') && 
                this.stepImport({progress: progress, code: code})
              }
              {(step==4 || this.props.isRedistribution=='0') &&
                this.stepUpload({progress: progress, code: code})
              }
              {(step==5 || this.props.isRedistribution=='1') &&
                this.stepRedistribute({progress: progress, code: code, listCampaign: listCampaign})
              }
              
            </Segment>
          }
          
          {step==0 &&
            <Segment>
              {/* {this.state.fields.length} / {this.props.firstRow} */}
              {/* <Divider hidden/> */}
              {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Campaign' : 'Insert Campaign') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
              
              <div style={{textAlign: 'right'}}>
                <Button type='reset' size='small' content=' Reset ' color='grey' disabled={pristine || submitting || progress==0} onClick={this.resetForm.bind(this)} />
                <Button type='submit' size='small' color='red' disabled={pristine || submitting || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
              </div>
            </Segment>
          }
        </Form>
      </div>
    </>)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.campaignStore.getCampaign!=undefined && state.campaignStore.getCampaign.progress==1 ? state.campaignStore.getCampaign.data : {products: [], closingTerms: [], workgroups:[]}),
      formValues: getFormValues('CampaignForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      name: selector(state, 'name'),

      scriptExt: selector(state, 'scriptExt'),

      campaignType: selector(state, 'campaignType'),
      isRedistribution: selector(state, 'isRedistribution'),
      totTarget: selector(state, 'totTarget'),
      
      channels: selector(state, 'channels'),
      channel_1: selector(state, 'channel_1'),
      channel_2: selector(state, 'channel_2'),

      contactFile: selector(state, 'contactFile'),
      fixed: selector(state, 'fixed'),
      fileFormat: selector(state, 'fileFormat'),
      delimiter: selector(state, 'delimiter'),
      firstRow: selector(state, 'firstRow'),
      firstRows: selector(state, 'firstRows'),

      hasDelivered: selector(state, 'hasDelivered'),
      hasRead: selector(state, 'hasRead'),
      hasClicked: selector(state, 'hasClicked'),
    }),
    { load: getCampaign }
  )
  (reduxForm({
    form: 'CampaignForm',	// a unique identifier for this form
  })(CampaignForm)
  )
)
