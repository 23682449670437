import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';


import DashboardBody from './DashboardBody';
import WizardBody from './WizardBody';
import MessagingInboundBody from './MessagingInboundBody';
import MessagingOutboundBody from './MessagingOutboundBody';
import CampaignBody from './CampaignBody';

import OnboardingBody from './OnboardingBody';

import PerformanceBody from './PerformanceBody';
import MessagingBody from './MessagingBody';
import TargetBody from './TargetBody';

import ProfileBody from './ProfileBody';
import MessageBody from './MessageBody';
import BillingBody from './BillingBody';
import SettingBody from './SettingBody';
import ApiBody from './ApiBody';
import SenderBody from './SenderBody';

import DeductBody from './DeductBody';
import TopupBody from './TopupBody';
import RecordBody from './RecordBody';

import InformationBody from './InformationBody';



import { connect } from "react-redux"
import { checkToken } from "../../actions/auth/authAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),
  }
}

class WhatsappBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
      mode: 'dashboard',
      modal: 'authentication',
      
      onboarding: false,
      topupalert: false,
    }
  }

  componentDidMount() {
    this.props.checkToken();
  }

  componentDidUpdate() {
    const { code, data }= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken : {}
    data && code=='00' && data.isConfigured=='0' && this.state.onboarding==false && this.setState({
      onboarding: true,
    })

    data && code=='00' && data.billingType=='prepaid' && parseFloat(data.depositCredit)<=0 && this.state.topupalert==false && this.setState({
      topupalert: true,
    })
  }

  closeModal(params) {
    this.props.closeModal(params)
  }
  
  openModal(value, params) {
    this.props.openModal(value, params)
  }

  openWindow(mode) {
    this.props.openWindow(mode)
  }

  render() {
    const { data }= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken : {}

    const { onboarding, topupalert }= this.state
    const { mode, module, screenWidth, screenHeight, modal }= this.props
    
    // console.log('CHECKTOKEN', code, data)
    return (
      <div>
        <div style={{margin: '3.3em 0 0 0'}}>
          {modal==='wizard' && <ModalContainer size='small' content={<WizardBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
          
          {modal==='campaign' && <ModalContainer size='small' content={<CampaignBody mode={2} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.closeModal.bind(this)} params={this.props.params} />} closeModal={this.closeModal.bind(this)} /> }
          {modal==='inbound' && <ModalContainer size='small' content={<MessagingInboundBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.closeModal.bind(this)} params={this.props.params} />} closeModal={this.closeModal.bind(this)} /> }
          {modal==='outbound' && <ModalContainer size='small' content={<MessagingOutboundBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.closeModal.bind(this)} params={this.props.params} />} closeModal={this.closeModal.bind(this)} /> }

          {modal==='performance' && <ModalContainer size='small' content={<PerformanceBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.closeModal.bind(this)} params={this.props.params} />} closeModal={this.closeModal.bind(this)} /> }
          {modal==='messaging' && <ModalContainer size='small' content={<MessagingBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.closeModal.bind(this)} params={this.props.params} />} closeModal={this.closeModal.bind(this)} /> }
          {modal==='target' && <ModalContainer size='small' content={<TargetBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.closeModal.bind(this)} params={this.props.params} />} closeModal={this.closeModal.bind(this)} /> }

          {modal==='profile' && <ModalContainer size='small' content={<ProfileBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
          {modal==='message' && <ModalContainer size='tiny' content={<MessageBody screenWidth={screenWidth} screenHeight={screenHeight} />} closeModal={this.closeModal.bind(this)} /> }
          {modal==='billing' && <ModalContainer size='small' content={<BillingBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.openWindow.bind(this)} openModal={this.props.openModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }

          {modal==='setting' && <ModalContainer size='small' content={<SettingBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
          {modal==='api' && <ModalContainer size='small' content={<ApiBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
          {modal==='sender' && <ModalContainer size='small' content={<SenderBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
                
          {modal==='deduct' && <ModalContainer size='small' content={<DeductBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
          {modal==='topup' && <ModalContainer size='small' content={<TopupBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
          {modal==='record' && <ModalContainer size='small' content={<RecordBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }

          {modal==='information' && <ModalContainer dialog size='tiny' content={<InformationBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
          
          {onboarding && <OnboardingBody dialog />}
          {mode==='dashboard' && <DashboardBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} checkToken={data} modal={modal}/>}
          
          {/* {mode==='authentication' && <AuthenticateBody />} */}
          {/* {!onboarding && topupalert && <ModalContainer size='small' content={<TopupBody screenWidth={screenWidth} />} closeModal={this.props.closeModal.bind(this)} /> } */}
        </div>
      </div>
    )
  }
}

// export default WhatsappBody
export default connect(mapStateToProps, mapDispatchToProps)(WhatsappBody)