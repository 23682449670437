import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  Blogging,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
} from 'semantic-ui-react'


import { format, parse } from "date-fns"

import { connect } from "react-redux"
import { browseBlogging } from "../../actions/halo/bloggingAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    bloggingStore: state.bloggingStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseBlogging:(objParam) => dispatch(browseBlogging(objParam)),
  }
};


class BloggingBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.bloggingStore.browseBlogging!==undefined ? this.props.bloggingStore.browseBlogging : {})
    this.props.browseBlogging({search: search, paging: paging})
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.bloggingStore.browseBlogging!==undefined ? this.props.bloggingStore.browseBlogging : {})
    this.props.browseBlogging(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const {data, search, paging}= (this.props.bloggingStore.browseBlogging!==undefined ? this.props.bloggingStore.browseBlogging : {})
    let seq= 0
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1'}}>
          <Segment>
            <Card.Group itemsPerRow={2} stackable doubling>

              { data!=undefined && data.length==0 && <Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label>}
              {data!=undefined ? data.map(item=>{
              return(
              <Card key={seq++} as='a' onClick={this.props.setMode.bind(this,2, item.bloggingId)}>
                <Card.Content extra style={{textAlign: 'left', padding: '1em 1.5em'}}>
                  <Header color='blue' as='h3' style={{fontWeight: 'bold'}}>
                    {item.title}
                  </Header>
                </Card.Content>
                <Card.Content style={{textAlign: 'left', padding: '1em 1.5em'}}>
                  <div style={{fontSize: '1.2em', color: '#333'}} dangerouslySetInnerHTML={{__html: item.content}} />
                  {/* <Header as='h5'>
                    {item.content.split('\n').map((item, j)=>{
                      return(<p key={j}>{item}</p>)
                    })}
                  </Header> */}
                  <Image src={item.image} />
                </Card.Content>
                {/* <Card.Content style={{textAlign: 'center'}}>
                  <Header as='h3' style={item.isEnabled!='1'? {textDecoration: 'line-through'} : {}} color={item.isEnabled=='1' ? 'red' : 'brown'}>
                    {item.name}
                  </Header>
                </Card.Content> */}
                <Card.Content extra>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1'}}>
                      <Header as='h5' style={{margin: '0'}}>
                        <Icon name='lightbulb outline' color='grey'style={{fontSize: '1em'}}/>
                        <Header.Content>
                        {item.author},  {item.publishStamp!=null && format(parse(item.publishStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM yyyy')}
                        </Header.Content>
                        
                      </Header>
                    </div>
                    <div>
                      <Label style={{padding: '.5em .8em .3em .5em'}}><Icon name='arrow up' />{item.totLike}</Label>
                      <Label style={{padding: '.5em .8em .3em .5em'}}><Icon name='arrow down' />{item.totDislike}</Label>
                    </div>
                  </div>
                  
                </Card.Content>
              </Card>
              )}) : <div style={{padding: '1em'}}><Icon name='circle notch' loading /></div>
              }
            </Card.Group>
          </Segment>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BloggingBrowse)
