import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetWaserver(objParam) {
  return {
      type: 'WASERVER_RESET',
      payload: objParam
  }
}

export function getWaserver(objParam) {
  return {
      type: 'WASERVER_GET',
      payload:
        axios.post(
          urlAPI + 'waserver/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusWaserver(objParam) {
  return {
      type: 'WASERVER_STATUS',
      payload:
        axios.post(
          urlAPI + 'waserver/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseWaserver(objParam) {
  return {
      type: 'WASERVER_BROWSE',
      payload:
        axios.post(
          urlAPI + 'waserver/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createWaserver(objParam) {
  return {
      type: 'WASERVER_CREATE',
      payload:
        axios.post(
          urlAPI + 'waserver/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateWaserver(objParam) {
  return {
      type: 'WASERVER_UPDATE',
      payload:
        axios.post(
          urlAPI + 'waserver/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeWaserver(objParam) {
  return {
      type: 'WASERVER_REMOVE',
      payload:
        axios.post(
          urlAPI + 'waserver/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function qrcodeWaserver(objParam, urlCustom) {
  // console.log('PARAM: ', objParam, urlCustom)
  return {
      type: 'WASERVER_QRCODE',
      payload:
        axios.post(
          urlCustom || (urlAPI +'waserver/qrcode'),
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function commandWaserver(objParam, urlCustom) {
  // console.log('PARAM: ', objParam, urlCustom)
  return {
      type: 'WASERVER_COMMAND',
      payload:
        axios.post(
          urlCustom || (urlAPI +'waserver/command'),
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
