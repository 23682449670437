import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'
import ModalContainer from '../../libraries/common/ModalContainer';

import MonitoringBody from './MonitoringBody';

import StatisticBody from '../stream/StatisticBody'
import OccupancyBody from '../talk/DashboardBody';
import DatafeedingBody from '../middleware/DashboardBody';

import PerformanceBody from '../stream/PerformanceBody'
import ReportingBody from '../stream/ReportingBody'

import TelephonyBody from '../stream/TelephonyBody'
import MessagingBody from '../stream/MessagingBody'
import SocialBody from '../stream/SocialBody'

import CollectionBody from '../stream/CollectionBody'
import RestructuringBody from '../stream/RestructuringBody'
import TelesaleBody from '../stream/TelesaleBody'
import VerificationBody from '../stream/VerificationBody'

import CampaignBody from '../stream/CampaignBody'
import TargetBody from '../stream/TargetBody'
import AssignBody from '../stream/AssignBody'

import EngagementBody from '../stream/EngagementBody'
import CampaignStatisticBody from '../stream/CampaignStatisticBody';

class AdminBodyDashboard extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }
  
  render() {
    const { mode, module, screenWidth, screenHeight, mini, modal, params }= this.props

    return (
      <div>
        {mode==='monitoring' && <MonitoringBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
        
        {mode==='statistic' && <StatisticBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
        {mode==='occupancy' && <OccupancyBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
        {mode==='datafeeding' && <DatafeedingBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}

        {mode==='performance' && <PerformanceBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
        {mode==='reporting' && <ReportingBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}

        {mode==='telephony' && <TelephonyBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
        {mode==='messaging' && <MessagingBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
        {mode==='social' && <SocialBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
        
        {mode==='collection' && <CollectionBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
        {mode==='restructuring' && <RestructuringBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
        {mode==='telesale' && <TelesaleBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
        {mode==='verification' && <VerificationBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}

        {mode==='campaign' && <CampaignBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
        {mode==='target' && <TargetBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
        {mode==='assignment' && <AssignBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
        
        {modal==='engagement' && <ModalContainer size='small' content={<EngagementBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal.bind(this)}  params={params} />} closeModal={this.props.closeModal.bind(this)} /> }
        {modal==='campaign_statistic' && <ModalContainer size='large' content={<CampaignStatisticBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal.bind(this)}  params={params} />} closeModal={this.props.closeModal.bind(this)} /> }

        {/* {mode==='talk-widget' && <InappwidgetBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />} */}

      </div>
    )
  }
}

export default AdminBodyDashboard