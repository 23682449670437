import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Icon } from 'semantic-ui-react'


import numeral from "numeral"
import { parse, format, addMinutes } from "date-fns"

import DismissableMessage from '../common/DismissableMessage'
import IconToggle from '../common/IconToggle';

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { ImageUploadField, LabelInputField, NumberField, InputField, CheckboxField, SelectField, DatePickerField, MaskedField, LabelNumberField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { competencyOptions, linkOptions, genderOptions, educationOptions,protoOptions }  from '../common/StaticMasterData'

import { connect } from "react-redux"
import { resetLink, getLink,updateLink, createLink } from "../../actions/talk/linkAction"

//CONNECT REDUX STORE
const selector = formValueSelector('LinkForm')
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    linkStore: state.linkStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetLink:(objParam) => dispatch(resetLink(objParam)),
    getLink:(objParam) => dispatch(getLink(objParam)),
    createLink:(objParam) => dispatch(createLink(objParam)),
    updateLink:(objParam) => dispatch(updateLink(objParam)),
  }
}

class LinkForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount(){
    this.props.resetLink(this.props.mode)

    if (this.props.mode==2) { //update
      this.props.getLink({linkId: this.props.linkId})
    }
  }

  componentDidUpdate(prevProps) {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.linkStore.updateLink!=undefined ? this.props.linkStore.updateLink : {}) : (this.props.linkStore.createLink!=undefined ? this.props.linkStore.createLink : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }

    if (this.props.createTime && this.props.expiredMinute && this.props.expiredMinute!=prevProps.expiredMinute) {
      this.props.dispatch(change('LinkForm', 'expireTime', format(addMinutes(parse(this.props.createTime, 'yyyy-MM-dd HH:mm:ss', new Date()), this.props.expiredMinute), 'yyyy-MM-dd HH:mm:ss')));
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, linkId } = this.props
    mode==2 ? this.props.updateLink({...values}) : this.props.createLink({...values, ...this.props.params})
  }

  setMode(key) {
    if (this.props[key]==undefined || this.props[key]=='0') {
      this.props.dispatch(change('LinkForm', key, '1' ));
    } else {
      this.props.dispatch(change('LinkForm', key, '0' ));
    }
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.linkStore.updateLink!=undefined ? this.props.linkStore.updateLink : {}) : (this.props.linkStore.createLink!=undefined ? this.props.linkStore.createLink : {}))

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              width={8}
              readOnly
              label='Name'
              placeholder='Name' />
              <Field name='baseUrl' component={InputField}
              width={8}
              readOnly
              label='Base URL'
              placeholder='Base URL Call Link' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='startDate' component={DatePickerField}
              readOnly
              label='Start Date'
              placeholder='Start Date' />
              <Field name='endDate' component={DatePickerField}
              readOnly
              label='End Date'
              placeholder='End Date' />
              <Field name='startTime' component={LabelInputField}
              readOnly
              label='HH:MM'
              caption='Start Time'
              placeholder='Start Time' />
              <Field name='endTime' component={LabelInputField}
              readOnly
              label='HH:MM'
              caption='End Time'
              placeholder='End Time' />
            </Form.Group>
          </Segment>

          <Segment>
            <Form.Group widths='16'>
              <Field name='isEnabled' component={CheckboxField}
              toggle
              width={12}
              caption='Enable Now !'
              placeholder='Status enable/disabled' />
              <Field name='code' component={InputField}
              width={4}
              validate={[required]}
              label='Code'
              placeholder='Conference Code' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='maxConversation' component={LabelInputField}
              width={4}
              validate={[required]}
              label='Calls'
              caption='Max. Conversation'
              placeholder='Max. Conversation' />
              <Field name='expiredMinute' component={LabelNumberField}
              width={4}
              validate={[required]}
              label='Minute'
              caption='Expired In'
              placeholder='Expired In' />
              <Field name='createTime' component={InputField}
              width={4}
              readOnly
              validate={[required]}
              label='Create Stamp'
              placeholder='Create Stamp' />
              <Field name='expireTime' component={InputField}
              width={4}
              readOnly
              validate={[required]}
              label='Expire Stamp'
              placeholder='Expire Stamp' />
            </Form.Group>
          </Segment>
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Link' : 'Insert Link') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
   (state, props) => ({
      initialValues: {createTime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),  ...props.params, ...(state.linkStore.getLink!=undefined && state.linkStore.getLink.progress==1 ? state.linkStore.getLink.data : {})},
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,

      expiredMinute: selector(state, 'expiredMinute'),
      createTime: selector(state, 'createTime'),
      expireTime: selector(state, 'expireTime'),
    }),
  )
  (
    reduxForm({
      form: 'LinkForm',	// a unique identifier for this form
    }) (LinkForm)
  )
)