import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import wasap_logo  from '../../assets/wasap-logo.svg'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react';

import DismissableMessage from '../common/DismissableMessage';
import IconToggle from '../common/IconToggle';
import {genderOptions, maritalOptions, occupationOptions, revenueOptions, expenseOptions, homestayOptions, vehicleOptions}  from '../common/StaticMasterData';

import { SubmissionError, Field, reduxForm, getFormValues, formValueSelector } from 'redux-form';
import {ImageUploadField, LabelInputField, InputField, CheckboxField, NumberField, DatePickerField, SelectField } from '../validation/componentrsui';
import { required, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation';

import { connect } from "react-redux";
import { getTarget } from "../../actions/stream/targetAction";

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    targetStore: state.targetStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTarget:(objParam) => dispatch(getTarget(objParam)),
  }
}

class InformationBoard extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    
  }

  componentDidUpdate() {
    
  }

  viewTips() {
    return (
      <div>
        <Header as='h2' icon inverted style={{display: 'block', textAlign: 'center'}}>
          <Icon name='lightbulb outline' style={{fontSize: '1.5em'}}/>
          <Header.Content>
            Campaign Hacks !  
          </Header.Content>
        </Header>

        <Divider />
        <div style={{padding: '1em 5%'}}>
          <Header as='h2' color='yellow' inverted style={{display: 'block', textAlign: 'center'}}>
            <Header.Content>
              <b>No Flooding Content</b>
              <Header.Subheader>Manage your content delivery to certain targets based on your customer preference, pick a great time and set comfortable interval, use your emphaty and feelings as human being.</Header.Subheader>
            </Header.Content>
          </Header>

          <Header as='h2' color='yellow' inverted style={{display: 'block', textAlign: 'center'}}>
            <Header.Content>
              <b>Has Value for Customers</b>
              <Header.Subheader>Despite of your clear words and meanings, makesure your content has benefits for customer, give them <b>discount</b>, <b>voucher</b> or <b>suprised gift</b> to increase your sales conversion.</Header.Subheader>
            </Header.Content>
          </Header>
          
          <Header as='h2' color='yellow' inverted style={{display: 'block', textAlign: 'center'}}>
            <Header.Content>
              <b>Import Targets From Any</b>
              <Header.Subheader>Import campaign's targets from any source, your <b>whatsapp contacts</b>, <b>excel/spreadsheet</b> files or use your previous <b>campaign's targets</b>, then do follow up and convert your leads.</Header.Subheader>
            </Header.Content>
            
          </Header>
        </div>
        <Divider horizontal style={{marginTop: '4em'}}>
          <div style={{width: '6em', margin: '0em auto 0 auto'}}>
            <div>
              <ReactSVG src={wasap_logo}/>
            </div>
          </div>
        </Divider>
      </div>
    )
  }

  viewPackage(val) {
    if (val=='Enterprise') {
      return (
        <div style={{display: 'flex'}}>
          <div style={{minWidth: '200px', marginRight: '2em'}}>
            <Header as='h1' icon inverted style={{display: 'block', textAlign: 'center'}} color='yellow'>
              <Icon name='chess queen' style={{fontSize: '1.5em'}}/>
              <Header.Content>
                Enterprise
                <Header.Subheader>Corporate Service</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div style={{flex: '1'}}>
            <div style={{padding: '1em 5%'}}>
              <Header as='h4' inverted style={{display: 'block'}}>
                <Header.Content>
                  <b>Charge on Delivered Only</b>
                  <Header.Subheader>Pay only on delivered message or active whatsapp account only.</Header.Subheader>
                </Header.Content>
              </Header>
              
              <Header as='h4' inverted style={{display: 'block'}}>
                <Header.Content>
                  <b>Official Account Likeness</b>
                  <Header.Subheader>Send content from long number and let your customer has experience as close as to official business account.</Header.Subheader>
                </Header.Content>
              </Header>

              <Header as='h4' inverted style={{display: 'block'}}>
                <Header.Content>
                  <b>Support API Integration</b>
                  <Header.Subheader>Manage your content distribution directly from your environment through API integration.</Header.Subheader>
                </Header.Content>
              </Header>

              <Header as='h4' inverted style={{display: 'block'}}>
                <Header.Content>
                  <b>Real Time Impression Rates</b>
                  <Header.Subheader>Monitor your content impression rates directly on dashboard.</Header.Subheader>
                </Header.Content>
              </Header>
              
              <Header as='h4' inverted style={{display: 'block'}}>
                <Header.Content>
                  <b>Import Target through Multiple Methods</b>
                  <Header.Subheader>Choose your target from different sources with variant import methods, Import Files, Previous Campaign, API.</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            {/* <div style={{margin: '0 15%'}}>
              <ReactSVG src={wasap_logo}/>
            </div> */}
          </div>
        </div>
      )
    } else {
      return (
        <div style={{display: 'flex'}}>
          <div style={{minWidth: '200px', marginRight: '2em'}}>
            <Header as='h1' icon inverted style={{textAlign: 'center'}} color='teal'>
              <Icon name='address card' style={{fontSize: '1.5em'}}/>
              <Header.Content>
                Personal
                <Header.Subheader>Marketer, UMKM</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div style={{flex: '1'}}>
            <div style={{padding: '1em 5%'}}>
              <Header as='h4' inverted style={{display: 'block'}}>
                <Header.Content>
                  <b>Charge on Delivered Only</b>
                  <Header.Subheader>Pay only on delivered message or active whatsapp account only.</Header.Subheader>
                </Header.Content>
              </Header>

              <Header as='h4' inverted style={{display: 'block'}}>
                <Header.Content>
                  <b>Billing Amount Limitation</b>
                  <Header.Subheader>No payment above certain value, message which is delivered beyond certain limit is free.</Header.Subheader>
                </Header.Content>
              </Header>

              <Header as='h4' inverted style={{display: 'block'}}>
                <Header.Content>
                  <b>No Flooding Policy</b>
                  <Header.Subheader>In a week, only allow single content to be delivered to certain number only.</Header.Subheader>
                </Header.Content>
              </Header>

              <Header as='h4' inverted style={{display: 'block'}}>
                <Header.Content>
                  <b>Real Time Impression Rates</b>
                  <Header.Subheader>Monitor your content impression rates directly on dashboard.</Header.Subheader>
                </Header.Content>
              </Header>
              
              <Header as='h4' inverted style={{display: 'block'}}>
                <Header.Content>
                  <b>Import Target through Multiple Methods</b>
                  <Header.Subheader>Choose your target from different sources with variant import methods, Import Contact, Insert Data.</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            {/* <div style={{margin: '0 15%'}}>
              <ReactSVG src={wasap_logo}/>
            </div> */}
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div style={{padding: '1em 1em'}}>
        {/* {this.viewTips()} */}
        {(this.props.mode=='enterprise' && this.viewPackage('Enterprise')) || (this.props.mode=='personal' && this.viewPackage('Personal')) || this.viewTips()}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InformationBoard)