import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
  Popup,
} from 'semantic-ui-react'

import ModalContainer from '../common/ModalContainer';

import { connect } from "react-redux"
import { browseWaserver, qrcodeWaserver, commandWaserver, resetWaserver } from "../../actions/halo/waserverAction"

var qrcodeInterval= null;

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    waserverStore: state.waserverStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetWaserver:(objParam) => dispatch(resetWaserver(objParam)),
    browseWaserver:(objParam) => dispatch(browseWaserver(objParam)),

    qrcodeWaserver:(objParam, urlCustom) => dispatch(qrcodeWaserver(objParam, urlCustom)),
    commandWaserver:(objParam, urlCustom) => dispatch(commandWaserver(objParam, urlCustom)),
  }
};


class WaserverBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.waserverStore.browseWaserver!==undefined ? this.props.waserverStore.browseWaserver : {})
    this.props.browseWaserver(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.waserverStore.browseWaserver!==undefined ? this.props.waserverStore.browseWaserver : {})
    this.props.browseWaserver({search: {...search}, paging: paging})
  }

  componentWillUnmount() {
    if (qrcodeInterval) {
      clearInterval(qrcodeInterval);
    } 
  }

  componentDidUpdate(prevProps, prevState) {
    const { data }= (this.props.waserverStore.qrcodeWaserver!==undefined ? this.props.waserverStore.qrcodeWaserver : {})

    if (data && (this.state.qrcodeStamp!=data.qrcodeStamp || this.state.qrcodeData!=data.qrcodeData || this.state.authenticateStamp!=data.authenticateStamp)) {
      this.setState({
        authenticateStamp: data.authenticateStamp,

        qrcodeStamp: data.qrcodeStamp,
        qrcodeData: data.qrcodeData,
        qrcodeImage: data.qrcodeImage,
      })
    }
    
    if (this.state.modal) {
      if (data && data.authenticateStamp) {
        setTimeout(()=>{
          this.setState({
            modal: null
          })
        }, 10000)
        
        this.props.resetWaserver();
      } else {
        if (!prevState.modal || prevState.modal!=this.state.modal) {
          if (qrcodeInterval) {
            clearInterval(qrcodeInterval);
          }
          this.props.qrcodeWaserver({
            ...this.state.params
          })
          qrcodeInterval= setInterval(()=>{
            this.props.qrcodeWaserver({
              ...this.state.params
            })
          }, 1000)
        }
      }
    } else {
      if (qrcodeInterval) {
        clearInterval(qrcodeInterval);
      }
    }
  }
  
  openModal(value, params) {
    // console.log(params)
    this.setState({
      modal: value,
      params: params,
    })
  }

  closeModal(params) {
    // console.log(params)
    this.setState({
      modal: null,
      params: params,
    })

    setTimeout(()=>{
      const { search, paging }= (this.props.waserverStore.browseWaserver!==undefined ? this.props.waserverStore.browseWaserver : {})
      this.props.browseWaserver({search: {...search}, paging: paging})
    }, 3000)
  }
  
  commandWaserver(cmd, params) {
    this.setState({
      disabled: params.waserverId,
    })
    setTimeout(()=>{
      this.setState({
        disabled: null
      })
    }, 5000)

    this.props.commandWaserver({
      ...params,
      commandType: cmd,
      url:  params.container +'/admin/api/v1/whatsapp/command',
    })

    setTimeout(()=>{
      const { search, paging }= (this.props.waserverStore.browseWaserver!==undefined ? this.props.waserverStore.browseWaserver : {})
      this.props.browseWaserver({search: {...search}, paging: paging})
    }, 3000)
  }

  viewQRCode() {
    return(
      <div style={{background: '#fff', padding: '4em 0', textAlign: 'center'}}>
        {/* {'QRCODE: '+ this.state.qrcodeImage} */}
        <div style={{
        background: (!this.state.qrcodeImage && !this.state.authenticateStamp) ? '#000' : '#eee', 
        // background: '#eee url("'+ (this.state.params.container +'/static/'+ (data && data.qrcodeImage)) +'") no-repeat fixed center',
        height: '264px', width: '264px', margin: '2em auto 4em auto'}}>
          {this.state.qrcodeImage && <Image src={`data:image/png;base64,${this.state.qrcodeImage}`} />}
          {this.state.qrcodeImage==null && !this.state.authenticateStamp && <Header as='h2' inverted style={{paddingTop: '30%'}}>
            <Header.Content>
              NO<br />QR CODE!
            </Header.Content>
          </Header>}
          {this.state.qrcodeImage==null && this.state.authenticateStamp && <Header as='h2' color='blue' style={{paddingTop: '30%'}}>
            <Header.Content>
              YOU ARE <br /><b>CONNECTED</b>
            </Header.Content>
          </Header>}
        </div>
        {/* {this.state.qrcodeData} */}
        <Header as='h3' color='blue'>
          <Header.Content>
            SCAN QR CODE HERE!
            <Header.Subheader>Whatsapp verification and authentication process.</Header.Subheader>
          </Header.Content>
        </Header>
      </div>
    )
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.waserverStore.browseWaserver!==undefined ? this.props.waserverStore.browseWaserver : {})
    const { progress }= (this.props.waserverStore.commandWaserver!==undefined ? this.props.waserverStore.commandWaserver : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight-125}}>
        <div style={{flex: '1'}}>
        <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell width='3'>
                  <Header as='h5' color='blue'>
                    WA ACCOUNT
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='3'>
                  <Header as='h5' color='blue'>
                  CONTAINER / SERVER
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='3'>
                  <Header as='h5' color='blue'>
                    SERVICE CREATED
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='3'>
                  <Header as='h5' color='blue'>
                    AUTHENTICATED
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='4'>
                  <Header as='h5' color='blue'>
                    SERVER STATUS
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell>
                </Table.HeaderCell>
                <Table.HeaderCell>
                </Table.HeaderCell>
                <Table.HeaderCell>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={5} style={{padding: '1em 2em'}}><Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell textAlign='center'>
                        <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.waserverId)}/>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content style={{textDecoration: item.isEnabled=='0' || item.isSuspended=='1' ? 'line-through' : 'none'}}>
                            <b>{item.account}</b>
                            <Header.Subheader>{item.client}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Label color='black' style={{margin: '.2em .5em 0 0'}}>{item.waserverId}</Label>
                          <Header.Content>
                            {item.container}
                            <Header.Subheader>Status: <b>{item.statuscheckResult}</b></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.createStamp && 'CREATED'}
                            <Header.Subheader>Result: {item.createResult || '-'} / {item.createStamp}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {(item.authenticateStamp && <b>LINKED!</b>) || <b><i>NOT-LINKED</i></b>}
                            <Header.Subheader>Result: {item.authenticateResult || '-'} / {item.authenticateStamp}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {(item.terminateStamp && (item.status=='0' ? 'TERMINATING' : 'TERMINATED')) || (item.isSuspended=='1' && 'SUSPENDED') || (item.isDisabled=='1' && 'DISABLED') || (item.startStamp && (item.status=='0' ? 'STARTING' : 'STARTED')) || '-'}
                            <Header.Subheader>Result: <b>{item.status<0 ? 'NOK' : (item.status=='0' ? 'Progress' : 'OK')}</b> {(item.terminateStamp && item.terminateResult) || (item.startStamp && item.startResult) || ''} / {item.terminateStamp || item.startStamp}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Popup content='Create and start Whatsapp Server as service.' position= 'bottom right' inverted
                        trigger={
                          <Button disabled={this.state.disabled==item.waserverId} size='big' color='teal' icon style={{padding: '.3em'}} onClick={this.commandWaserver.bind(this, 'start', {waserverId: item.waserverId, account: item.account, container: item.container})}><Icon name='play circle outline' /></Button>
                        } />
                      </Table.Cell>
                      <Table.Cell>
                        <Popup content='Scan QR Code for Authenticated Whatsapp Server.' position= 'bottom right' inverted
                        trigger={
                          <Button disabled={this.state.disabled==item.waserverId} size='big' color='grey' icon style={{padding: '.3em'}} onClick={this.openModal.bind(this, 'qrcode', {serverId: item.waserverId, account: item.account, container: item.container})}><Icon name='qrcode' /></Button>
                        } />
                      </Table.Cell>
                      <Table.Cell>
                        <Popup content='Stop and terminate Whatsapp Server.' position= 'bottom right' inverted
                        trigger={
                          <Button disabled={this.state.disabled==item.waserverId} size='big' color='orange' icon style={{padding: '.3em'}} onClick={this.commandWaserver.bind(this, 'stop', {waserverId: item.waserverId, account: item.account, container: item.container})}><Icon name='stop circle outline' /></Button>
                        } />
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }

        {this.state.modal && <ModalContainer size='small' content={this.viewQRCode()} closeModal={this.closeModal.bind(this)} /> }
      
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaserverBrowse)
