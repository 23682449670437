import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Statistic, Icon, Label, Table } from 'semantic-ui-react'

import IconToggle from '../common/IconToggle';
import DismissableMessage from '../common/DismissableMessage'
import {hourOptions, importOptions, yesnoOptions, redistOptions, delimiterOptions, fieldTargetOptions}  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'

import {ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField, NumberField, TextAreaField, DatePickerField, DataUploadField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumberID, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { checkToken } from "../../actions/auth/authAction"
import { browseCampaign, resetCampaign, getCampaign,updateCampaign, createCampaign } from "../../actions/stream/campaignAction"
import { listWorkgroup, listTemplate, listProduct, listCampaign, collectionStage, restructuringStage, telesaleStage, verificationStage, listParams } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('WizardForm')

const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
    masterStore: state.masterStore,
    campaignStore: state.campaignStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),

    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    // listTemplate:(objParam) => dispatch(listTemplate(objParam)),
    // listProduct:(objParam) => dispatch(listProduct(objParam)),
    listCampaign:(objParam) => dispatch(listCampaign(objParam)),
    listParams:(objParam) => dispatch(listParams(objParam)),

    // collectionStage:(objParam) => dispatch(collectionStage(objParam)),
    // restructuringStage:(objParam) => dispatch(restructuringStage(objParam)),
    // telesaleStage:(objParam) => dispatch(telesaleStage(objParam)),
    // verificationStage:(objParam) => dispatch(verificationStage(objParam)),

    browseCampaign:(objParam) => dispatch(browseCampaign(objParam)),
    
    resetCampaign:(objParam) => dispatch(resetCampaign(objParam)),
    getCampaign:(objParam) => dispatch(getCampaign(objParam)),
    createCampaign:(objParam) => dispatch(createCampaign(objParam)),
    updateCampaign:(objParam) => dispatch(updateCampaign(objParam)),
  }
}

class WizardForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      step: 0,
      isDynamic: false,
      variables: null,
      fields: [],
    }
  }

  componentDidMount(){
    this.props.resetCampaign(this.props.mode)

    if (this.props.mode==2) { //update
      this.props.getCampaign({campaignId: this.props.campaignId})
    }
    
    this.props.listCampaign();
    // this.props.listCampaign({search: {clientId: this.props.clientId!=undefined ? this.props.clientId : null}})

    // this.props.listWorkgroup({search: {clientId: this.props.clientId!=undefined ? this.props.clientId : null}})
    // this.props.listTemplate({search: {clientId: this.props.clientId!=undefined ? this.props.clientId : null}})
    // this.props.listProduct({search: {clientId: this.props.clientId!=undefined ? this.props.clientId : null}})
    
    // this.props.listParams({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
    this.props.listParams();

    // this.props.collectionStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
    // this.props.restructuringStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
    // this.props.telesaleStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
    // this.props.verificationStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
  }
  componentDidUpdate(prevProps) {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.campaignStore.updateCampaign!=undefined ? this.props.campaignStore.updateCampaign : {}) : (this.props.campaignStore.createCampaign!=undefined ? this.props.campaignStore.createCampaign : {}))
    
    if (progress==1 && code=='00') {
      setTimeout(()=>{
        this.setState({
          step: 8,
        });
      }, 1000);

      setTimeout(()=>{
        this.closeModal();
      }, 2000)
    } else {
      if (prevProps.scriptExt!=this.props.scriptExt) {
        // console.log('!!! PROPS SCRIPT', prevProps.scriptExt, this.props.scriptExt)

        const vars= new Map()
        const params = this.props.scriptExt && this.props.scriptExt.match(/{[a-zA-Z_]*}/g);
        
        // console.log('!!! PARAMS', params)
        params && params.map(item=>{
          !vars.get(item) && vars.set(item)
        })
        this.setState({
          variables: params && params.length>0 ? vars : null,
        })
      }

      if (this.state.step==2) {
        if (prevProps.isRedistribution!=this.props.isRedistribution) {
          this.props.isRedistribution=='2' && this.setState({
            step: 3,
          });
          this.props.isRedistribution=='0' && this.setState({
            step: 4,
          });
          this.props.isRedistribution=='1' && this.setState({
            step: 5,
          });
          this.props.isRedistribution=='-1' && this.setState({
            step: 7,
          });
        }
      }

      // if (fields.length==0 && (this.props.firstRows || this.props.firstRow)) {
      //   // this.parseFields(this.props.firstRows, this.props.firstRow.replace(/(\r\n|\n|\r)/gm, ''));
      //   this.parseFields(this.props.firstRows, this.props.firstRow && this.props.firstRow.replace(/(\r\n|\n|\r)/gm, ''));
      // } else if (fields.length>0 && !(this.props.firstRows || this.props.firstRow)) {
      //   this.setState({
      //     fields: [],
      //   })
      // }
    }
  }

  componentWillUnmount() {
    const { search, paging }= (this.props.campaignStore.browseCampaign!==undefined ? this.props.campaignStore.browseCampaign : {})
    this.props.browseCampaign(
      {
        paging: paging,
        search: search
      }
    )
  }
  
  resetForm() {
    const { reset } = this.props
    reset()
  }

  submitForm(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    if (this.state.step==3 || this.state.step==4 || this.state.step==5 || this.state.step==6) {
      
      // this.state.step==3 && this.props.dispatch(change('WizardForm', 'isDevice', 1)); //import device contact
      // this.state.step==5 && this.props.dispatch(change('WizardForm', 'isRedistribution', 1)); //redistribute from previous campaigns
      this.setState({
        step: 7,
      })  
    } else if (this.state.step==2) {
      this.props.isRedistribution=='2' && this.setState({
        step: 3,
      });
      this.props.isRedistribution=='0' && this.setState({
        step: 4,
      });
      this.props.isRedistribution=='1' && this.setState({
        step: 5,
      });

      // this.setState({
      //   step: 7,
      // })  
    } else if (this.state.step<7) {
      this.setState({
        step: this.state.step+1,
      })  
    } else {
      console.log('!!! SUBMIT FORM', { ...values, 
        isEnabled: 0,
        attemptRatio: 1,

        qtyChannel: 1,
        channel_1: 'Whatsapp',
      })
      
      const conditions= []
      this.props.hasDelivered=='1' && conditions.push('Delivered')
      this.props.hasRead=='1' && conditions.push('Read')
      this.props.hasClicked=='1' && conditions.push('Clicked')

      this.props.createCampaign({...values, redistConditions: conditions, qtyChannel: 1, channel_1: 'Whatsapp'})
      this.setState({
        step: this.state.step+1,
      })
    }
  }

  samplingData(v) {
    if (v!=null) {
      // console.log('!!! SAMPLING DATA', v)
      const data= JSON.parse(v);
      this.props.dispatch(change('WizardForm', 'firstRows', data.firstRows));
      this.props.dispatch(change('WizardForm', 'firstRow', data.firstRow && data.firstRow.replace(/(\r\n|\n|\r)/gm, '')));
      this.props.dispatch(change('WizardForm', 'delimiter', null ));
      this.props.dispatch(change('WizardForm', 'imported', null ));
      this.parseFields(data.firstRows, data.firstRow && data.firstRow.replace(/(\r\n|\n|\r)/gm, ''))
    } else {
      this.setState({fields: []});
    }
  }

  parseFields(firstRows, firstRow) {
    var fields= [];
    fields.push({ key: 0, value: '', text: '! None / Null' })
    
    if (firstRows && firstRows.length>0) {
      firstRows.map((item,index)=>{
        fields.push({ key: ++index, value: item, text: item })
      })

      // console.log('!!! FIRSTROWS', fields, firstRows)
    } else {
      if (firstRow && (this.props.delimiter==undefined || this.props.delimiter==null)) {
        if (firstRow.split('", "').length>=3) {
          firstRow.split('", "').map((field, index)=>{
            fields.push({ key: ++index, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
          this.props.dispatch(change('CampaignForm', 'delimiter','", "' ));
        } else if (firstRow.split('","').length>=3) {
          firstRow.split('","').map((field, index)=>{
            fields.push({ key: ++index, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
          this.props.dispatch(change('CampaignForm', 'delimiter','","' ));
        } else if (firstRow.split(',').length>=3) {
          firstRow.split(',').map((field, index)=>{
            fields.push({ key: ++index, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
          this.props.dispatch(change('CampaignForm', 'delimiter',',' ));
        } else if (firstRow.split('"; "').length>=3) {
          firstRow.split('"; "').map((field, index)=>{
            fields.push({ key: ++index, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
          this.props.dispatch(change('CampaignForm', 'delimiter','"; "' ));
        } else if (firstRow.split('";"').length>=3) {
          firstRow.split('";"').map((field, index)=>{
            fields.push({ key: ++index, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
          this.props.dispatch(change('CampaignForm', 'delimiter','";"' ));
        }
      } else {
        if (firstRow && firstRow.split(this.props.delimiter).length>=3) {
          firstRow.split(this.props.delimiter).map((field, index)=>{
            fields.push({ key: ++index, value: field.replace(/^"+|"+$/gm,''), text: field.replace(/^"+|"+$/gm,'') })
          })
        }
      }
      
      // console.log('!!! FIRSTROW', fields, this.props.delimiter, firstRow)
    }

    this.setState({fields: fields});
  }

  closeModal(params) {
    this.props.closeModal(params);
  }

  setDynamic() {
    this.setState({isDynamic: !this.state.isDynamic})
  }
  insertVariable(v) {
    // console.log(v)
    if (this.props.media!='Email') {
      this.props.dispatch(change('WizardForm', 'scriptExt', (this.props.scriptExt||'') +' '+ v +' '));
    } else {
      navigator.clipboard.writeText(v)
    }
    // e.target.focus()
  }

  clickStep(v) {
    this.setState({
      step: v
    })
  }
  stepCampaign(v) {
    return(
      <div>
        <div>
          <div style={{minHeight: '325px'}}>
            <div style={{textAlign: 'center'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  Campaign's <b>schedule</b> delivery !
                  <Header.Subheader>
                    Setup campaign schedule, duration and delivery plan.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <Divider style={{margin: '2em 0 2em 0'}}/>
            <Form.Group widths='16'>
              <Field name='campaignType' component={SelectField}
              width={8}
              options={v.campaignOptions || []}
              validate={[required]}
              label='Campaign Type'
              placeholder='Campaign Type' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              width={16}
              validate={[required]}
              label='Campaign Name'
              placeholder='Campaign Name' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='startDate' component={DatePickerField}
              validate={[required]}
              label='Start Date'
              placeholder='Start Date' />
              <Field name='endDate' component={DatePickerField}
              validate={[required]}
              label='End Date'
              placeholder='End Date' />
              <Field name='startTime' component={SelectField}
              options= {hourOptions()}
              validate={[required]}
              label='Start Time'
              placeholder='Start Time' />
              <Field name='endTime' component={SelectField}
              options= {hourOptions()}
              validate={[required]}
              label='End Time'
              placeholder='End Time' />
            </Form.Group>
          </div>
        </div>
        <div style={{textAlign: 'right', marginTop: '2em'}}>
          <Button color='blue' size='large'> Next</Button>
        </div>
      </div>
    )
  }
  stepContent(v) {
    return(
      <div>
        <div>
          <div style={{minHeight: '325px'}}>
            <div style={{textAlign: 'center'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  Define broadcast <b>content</b> ?
                  <Header.Subheader>
                    Compose <b>content</b> / <b>information</b> for promotion or notification.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <Divider style={{margin: '2em 0'}}/>
            <div>
              <div className='variable' style={{margin: '0 0 1em 0'}}>
                <div style={{display: 'flex', marginBottom: '1.5em'}}>
                  <div style={{flex: '1'}}>
                    <Header as='h5' color='blue'>
                      <Header.Content>
                        <b>Content Script</b>
                        <Header.Subheader>Set content script type, static or dynamic on variable basis.</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div>
                    <Header as='h5' style={{margin: '0', fontWeight: 'bold'}}>
                      <IconToggle active={this.state.isDynamic==true} onClick={this.setDynamic.bind(this)} />
                      <Header.Content>
                        Variables
                        <Header.Subheader>Click Variable Options</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                </div>
                {this.state.isDynamic==true && 
                  <Label.Group>
                    <Popup header='{custName}' content='Customer or target name' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{custName}')}>Customer Name</Label> 
                    } />
                    <Popup header='{billAmount}' content='Bill amount which must paid' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{billAmount}')}>Bill Amount</Label>
                    } />
                    <Popup header='{overdueAmount}' content='Penalty or fine amount' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{overdueAmount}')}>Penalty Amount</Label>
                    } />
                    <Popup header='{overdueDay}' content='Payment was late in days' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{overdueDay}')}>Late In Days</Label> 
                    } />
                    <Popup header='{service}' content='Subscription product or service information' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{service}')}>Subscription Info</Label>
                    } />
                    <Popup header='{billDate}' content='Date when billing generated' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{billDate}')}>Billing Date</Label>
                    } />
                    <Popup header='{dueDate}' content='Due date when bill should be paid ' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{dueDate}')}>Payment Due Date</Label>
                    } />
                  
                    <Popup header='{product}' content='Product information / name' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{product}')}>Product</Label>
                    } />
                    <Popup header='{information}' content='General information which important for customers' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{information}')}>Information</Label>
                    } />
                    <Popup header='{merchantName}' content='Merchant information / name' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{merchantName}')}>Merchant Name</Label>
                    } />
                    <Popup header='{custRef}' content='Customer ref. identity' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{custRef}')}>Cust. Ref. Id</Label>
                    } />
                    <Popup header='{contractRef}' content='Contract ref. for customer' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{contractRef}')}>Cust. Contract</Label>
                    } />
                    <Popup header='{city}' content='City where customer stays' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{city}')}>City</Label>
                    } />
                  </Label.Group>
                }
              </div>
              <div style={{flex: '1'}}>
                <Form.Group widths='16'>
                  <Field name='scriptExt' component={TextAreaField}
                  width='16'
                  rows='8'
                  // validate={[required]}
                  // label='Broadcast Content Script' 
                  />
                </Form.Group>
                
                <Label.Group>
                  <Label style={{fontWeight: '300'}}>_italic_ : <i>italic</i></Label>
                  <Label style={{fontWeight: '300'}}>*bold*: <b>bold</b></Label>
                  <Label style={{fontWeight: '300'}}>~strikethrough~: <span style={{textDecoration: 'line-through'}}>strikethrough</span></Label>
                </Label.Group>
                {/* {(this.props.media=='Email' || this.props.media=='Whatsapp' || this.props.media=='Telegram' || this.props.media=='Facebook' || this.props.media=='Twitter' || this.props.media=='Instagram') && <>
                  <Divider hidden/>
                  <Form.Group widths='equal' style={{marginTop: '2em'}}>
                    <Form.Field>
                      <Field name='image' component={ImageUploadField}
                      // width={420} 
                      height={420} 
                      aspectRatio= '1:1' />
                    </Form.Field>
                  </Form.Group>
                </>} */}
              </div>
            </div>
          </div>
        </div>
        <div style={{display: 'flex', marginTop: '2em'}}>
          <div style={{flex: 1}}>
            <Button disabled={!(v.progress!=1 || v.code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this, 0)}> Back</Button>
          </div>
          <div>
            <Button color='blue' size='large'> Next</Button>
          </div>
        </div>
      </div>
    )
  }
  stepTarget(v) {
    const { data }= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken : {}

    return(
      <div>
        <div>
          <div style={{minHeight: '325px'}}>
            <div style={{textAlign: 'center'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  Manage <b>target's</b> contact ?
                  <Header.Subheader>
                    Insert, import new <b>contacts</b> as your campaign's target.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <Divider style={{margin: '2em 0'}}/>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Header as='h5' style={{margin: '0'}}>
                  <Icon name='user circle' style={{fontSize: '1em'}}/>
                  <Header.Content>
                    Target's Contact
                    <Header.Subheader>Set target by import form handphone, file or prev. campaign.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{width: '13em', marginLeft: '1em'}}>
                {/* <Button type='button' icon size='big' onClick={this.props.openModal.bind(this, 'target', {...this.props.params, variables: this.state.variables})} style={{padding: '.15em .1em', margin: '0'}}>
                  <Icon name='window maximize outline' color='blue' />
                </Button> */}
                {data && 
                <Form.Group widths='equal'>
                  <Field name='isRedistribution' component={SelectField} style={{fontWeight: 'bold'}}
                  fluid
                  options={importOptions(data.packageId)}
                  validate={[required]}
                  // label='Campaign Type'
                  placeholder='Contact Source' />
                </Form.Group>}
              </div>
            </div>
            {/* <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Label as='a' onClick={this.clickStep.bind(this, 3)}><Icon name='address card outline' /> Import Contact</Label>
                <Label as='a' onClick={this.clickStep.bind(this, 4)}><Icon name='file alternate outline' /> Upload Contact</Label>
                <Label as='a' onClick={this.clickStep.bind(this, 5)}><Icon name='recycle' /> Redistribute</Label>
              </div>
              <div style={{marginLeft: '1em'}}>
                <Button disabled type='button' icon size='medium' color='red' onClick={this.clickStep.bind(this, 6)}>
                  <Icon name='plus' />
                </Button>
              </div>
            </div> */}
            
            <Divider hidden />
            <div>
              <div>
                <Label style={{padding: '1.5em 1em'}}>
                  <div style={{display: 'flex'}}>
                    <div style={{borderRight: '1px solid #fff', paddingRight: '1em'}}>
                      <Statistic horizontal style={{margin: '0'}}>
                        <Statistic.Value>{this.props.totTarget || '0'}</Statistic.Value>
                        <Statistic.Label>CONTACTS</Statistic.Label>
                      </Statistic>
                    </div>
                    <div style={{flex: '1', textAlign: 'left', borderLeft: '1px solid #999', paddingLeft: '1em', lineHeight: '1.3em'}}>
                      Targets are available from your Whatsapp / Handphone Contacts, Import from File or Redistribute from Campaigns.
                    </div>    
                  </div>
                </Label>
              </div>
            </div>
          </div>
        </div>
        <div style={{display: 'flex', marginTop: '2em'}}>
          <div style={{flex: 1}}>
            <Button disabled={!(v.progress!=1 || v.code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this, 1)}> Back</Button>
          </div>
          <div>
            <Button color='blue' size='large'> Next</Button>
          </div>
        </div>
      </div>
    )
  }
  stepContact(v) {
    let fields= [];
    return(
      <div>
        <div>
          <div style={{minHeight: '325px'}}>
            <div style={{textAlign: 'center'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  Create / edit target <b>contact's</b> information !
                  <Header.Subheader>
                    Manage contact item for data insertion or update.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <Divider style={{margin: '2em 0 2em 0'}}/>
            <Form.Group widths='16'>
              <Field name='whatsapp' component={InputField}
              width={8}
              validate={[required, phoneNumberID]}
              label='Whatsapp'
              placeholder='Whatsapp Number' />
            </Form.Group>
            {
              this.state.variables && [...this.state.variables.keys()].map((item, idx)=>{
                fields[idx]= <Field name={item} component={InputField}
                // validate={[required]}
                label={item}
                placeholder={item} />;

                if ([...this.state.variables.keys()].length==(idx+1) || idx%2==1) {
                  if (idx%2==1) {
                    return(
                      <Form.Group widths='equal'>
                        {fields[idx-1]} {fields[idx]}
                      </Form.Group>      
                    )
                  } else {
                    return(
                      <Form.Group widths='equal'>
                        {fields[idx]}
                      </Form.Group>      
                    )
                  }
                }
              })
            }
          </div>
        </div>
        <div style={{display: 'flex', marginTop: '2em'}}>
          <div style={{flex: 1}}>
            <Button disabled={!(v.progress!=1 || v.code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this, 2)}> Back</Button>
          </div>
          <div>
            <Button color='blue' size='large'> Insert</Button>
          </div>
        </div>
      </div>
    )
    
  }
  stepImport(v) {
    return(
      <div>
        <div >
          <div style={{minHeight: '325px'}}>
            <div style={{textAlign: 'center'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  Import <b>contacts</b> from Whatsapp ? 
                  <Header.Subheader>
                    Accessing contacts from your device, contact is <b>manageable</b> by user.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <Divider style={{margin: '2em 0'}}/>
            {/* <div style={{textAlign: 'center', paddingTop: '2em'}}>
              <Label style={{width: '20em'}}>
                <Statistic horizontal style={{margin: '0'}}>
                  <Statistic.Value>2,204</Statistic.Value>
                  <Statistic.Label>CONTACTS</Statistic.Label>
                </Statistic>
                <Divider />
                Click import to edit and save contacts for current campaign.
              </Label>
            </div> */}
            <Label style={{display: 'block', padding: '1.5em 1em'}}>
              <div style={{display: 'flex'}}>
                <div style={{borderRight: '1px solid #fff', paddingRight: '1em'}}>
                  <Statistic horizontal style={{margin: '0'}}>
                    <Statistic.Value>WA</Statistic.Value>
                    <Statistic.Label>CONTACTS</Statistic.Label>
                  </Statistic>
                </div>
                <div style={{flex: '1', textAlign: 'left', borderLeft: '1px solid #999', paddingLeft: '1em', lineHeight: '1.3em'}}>
                  Import contacts from your connected Whatsapp / Handphone to current campaign.
                </div>    
              </div>
            </Label>
          </div>
        </div>
        <div style={{display: 'flex', marginTop: '2em'}}>
          <div style={{flex: 1}}>
            <Button disabled={!(v.progress!=1 || v.code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this,2)}> Back</Button>
          </div>
          <div>
            <Button color='blue' size='large'> Import</Button>
          </div>
        </div>
      </div>
    )
  }
  stepUpload(v) {
    let fields= [];

    return(
      <div>
        <div >
          <div style={{minHeight: '325px'}}>
            <div style={{textAlign: 'center'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  Upload <b>contacts</b> from file ? 
                  <Header.Subheader>
                    Get contact from uploaded file, contact is <b>manageable</b> by user.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <Divider style={{margin: '2em 0'}}/>
            {this.state.fields.length>0 && <div>
              <Form.Group widths='16'>
                <Field name='f_whatsapp' component={SelectField}
                width={8}
                validate={[required, phoneNumberID]}
                options={this.state.fields}
                label='Whatsapp'
                placeholder='Whatsapp Number' />
              </Form.Group>
              {
                this.state.variables && [...this.state.variables.keys()].map((item, idx)=>{
                  fields[idx]= <Field key={idx} name={'f_'+ item.replace(/{|}/gm,'')} component={SelectField}
                  validate={[required]}
                  options={this.state.fields}
                  label={item.replace(/{|}/gm,'')}
                  placeholder={item.replace(/{|}/gm,'')} />;

                  if ([...this.state.variables.keys()].length==(idx+1) || idx%2==1) {
                    if (idx%2==1) {
                      return(
                        <Form.Group key={idx} widths='equal'>
                          {fields[idx-1]} {fields[idx]}
                        </Form.Group>      
                      )
                    } else {
                      return(
                        <Form.Group key={idx} widths='equal'>
                          {fields[idx]}
                        </Form.Group>      
                      )
                    }
                  }
                })
              }
              <Divider style={{margin: '2em 0'}}/>
            </div>}
            <Form.Group widths='equal'>
              <Field name='contactFile' component={DataUploadField}
              validate={[required]}
              samplingData={this.samplingData.bind(this)}
              label='Contact File'
              placeholder='Contact File' />
            </Form.Group>
          </div>
        </div>
        <div style={{display: 'flex', marginTop: '2em'}}>
          <div style={{flex: 1}}>
            <Button disabled={!(v.progress!=1 || v.code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this,2)}> Back</Button>
          </div>
          <div>
            {/* <Button disabled={!(v.progress!=1 || v.code!="00")} color='red' size='large' id='btnOnboarding' icon={v.progress==0 ? true : false}> {v.progress==0 ? <Icon name='notch circle' loading /> : ''} Save</Button> */}
            <Button color='blue' size='large'> Upload</Button>
          </div>
        </div>
      </div>
    )
  }
  stepRedistribute(v) {
    return(
      <div>
        <div >
          <div style={{minHeight: '325px'}}>
            <div style={{textAlign: 'center'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  Get <b>contacts</b> from prev. campaigns ? 
                  <Header.Subheader>
                    Get contact from previous campaign, contact is <b>manageable</b> by user.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <Divider style={{margin: '2em 0'}}/>
            <Form.Group widths='equal'>
              <Field name='redistCampaigns' component={SelectField}
              multiple
              validate={[required]}
              options={v.listCampaign || []}
              label='Prev. Campaigns'
              placeholder='Reference to prev. campaigns' />
            </Form.Group>
            
            <Divider hidden />
            <Header as='h5' color='blue'style={{fontSize: '1em'}}>
              <Header.Content>
                <label color='blue'>Filtered Contact</label>
              </Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='hasDelivered' component={CheckboxField}
              label={<Label color='green'><b>Sent / Delivered</b></Label>}
              placeholder='Delivered' />
              <Field name='hasRead' component={CheckboxField}
              label={<Label color='orange'><b>Read / Viewed</b></Label>}
              placeholder='Read' />
              <Field name='hasClicked' component={CheckboxField}
              disabled
              label={<Label color='red'><b>Clicked Page/Link</b></Label>}
              placeholder='Clicked' />
            </Form.Group>
          </div>
        </div>
        <div style={{display: 'flex', marginTop: '2em'}}>
          <div style={{flex: 1}}>
            <Button disabled={!(v.progress!=1 || v.code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this,2)}> Back</Button>
          </div>
          <div>
            {/* <Button disabled={!(v.progress!=1 || v.code!="00")} color='red' size='large' id='btnOnboarding' icon={v.progress==0 ? true : false}> {v.progress==0 ? <Icon name='notch circle' loading /> : ''} Save</Button> */}
            <Button color='blue' size='large'>Import</Button>
          </div>
        </div>
      </div>
    )
  }
  stepConfirmation(v) {
    return(
      <div>
        <div >
          <div style={{minHeight: '325px'}}>
            <div style={{textAlign: 'center'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  Here is your <b>campaign</b> summary ? 
                  <Header.Subheader>
                    Click save when campaign is correctly setup, campaign is <b>manageable</b> later by user.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <Divider style={{margin: '2em 0'}}/>
            <Header as='h3' style={{margin: '0'}}>
              <Header.Content>
                <Header.Subheader>{this.props.campaignType}</Header.Subheader>
                {this.props.name}
                <Header.Subheader>{this.props.startDate} - {this.props.endDate}</Header.Subheader>
              </Header.Content>
            </Header>

            <Divider style={{margin: '.5em 0'}}/>
            <Header as='h5' color='blue' style={{margin: '2em 0 0 0'}}>
              <Icon name='whatsapp' style={{fontSize: '1em'}}/>
              <Header.Content>
                <b>Content</b>
              </Header.Content>
            </Header>
            <Header as='h5' style={{margin: '0'}}>
              <Header.Content style={{padding: '.5em 1.4em'}}>
                {/* {this.props.scriptExt} */}
                {<div dangerouslySetInnerHTML={ {__html: this.props.scriptExt && this.props.scriptExt.replace(/\n/gm, '<br />')}} />}
                {/* <Header.Subheader>{this.props.channel_1}</Header.Subheader> */}
              </Header.Content>
            </Header>
            <Header as='h5' color='blue' style={{margin: '2em 0 0 0'}}>
              <Icon name='user circle outline' style={{fontSize: '1em'}}/>
              <Header.Content>
                <b>Contacts</b>
              </Header.Content>
            </Header>
            <Header as='h5' style={{margin: '0'}}>
              <Header.Content style={{padding: '.5em 1.1em'}}>
                <Label.Group>
                  {this.props.isRedistribution=='-1' && <Label>
                    <Icon name='keyboard outline' /> Input contact later manually through form.
                  </Label>}
                  {this.props.isRedistribution=='2' && <Label>
                    <Icon name='address card outline' /> Contacts imported from device
                  </Label>}
                  {this.props.isRedistribution=='0' && <Label>
                    <Icon name='file alternate outline' /> Contacts uploaded from file
                  </Label>}
                  {this.props.isRedistribution=='1' && <Label>
                    <Icon name='recycle' /> Contacts redistributed from existing campaigns
                  </Label>}
                </Label.Group>
                {/* {(this.props.isDevice && this.props.contactFile && 'Imported from devices | Uploaded from file') || (this.props.isDevice && 'Imported from devices') || (this.props.contactFile && 'Uploaded from file') || 'No contact data been set'} */}
                {/* <Header.Subheader>{this.props.channel_1}</Header.Subheader> */}
              </Header.Content>
            </Header>
          </div>
        </div>
        <div style={{display: 'flex', marginTop: '2em'}}>
          <div style={{flex: 1}}>
            <Button disabled={!(v.progress!=1 || v.code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this,2)}> Back</Button>
          </div>
          <div>
            {/* <Button disabled={!(v.progress!=1 || v.code!="00")} color='red' size='large' id='btnOnboarding' icon={v.progress==0 ? true : false}> {v.progress==0 ? <Icon name='notch circle' loading /> : ''} Save</Button> */}
            <Button color='red' size='large'> Save</Button>
          </div>
        </div>
      </div>
    )
  }
  stepResult(v) {
    return(
      <div>
        <div>
          <div style={{minHeight: '325px'}}>
            <div style={{textAlign: 'center', paddingTop: '100px'}}>
              <Header as='h2' color='blue'>
                <Header.Content>
                  Thank you, <b>campaign</b> is prepared. 
                  <Header.Subheader>
                    Campaign is editable after submission, manage campaign directly from main window.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>
          </div>
        </div>
        {v.dialog && <div style={{marginTop: '2em', textAlign: 'center'}}>
          <Button type='button' color='red' size='large' onClick={this.closeModal.bind(this)}> Close, I 'm Done</Button>
        </div>}
      </div>
    )
  }

  render() {
    const { step }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode, dialog } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.campaignStore.updateCampaign!=undefined ? this.props.campaignStore.updateCampaign : {}) : (this.props.campaignStore.createCampaign!=undefined ? this.props.campaignStore.createCampaign : {}))

    // const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    // const listTemplate= (this.props.masterStore.listTemplate!=undefined && this.props.masterStore.listTemplate.progress=='1' ? this.props.masterStore.listTemplate.data : [])
    // const listProduct= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    const listCampaign= (this.props.masterStore.listCampaign!=undefined && this.props.masterStore.listCampaign.progress=='1' ? this.props.masterStore.listCampaign.data : [])

    // const collectionStage= (this.props.masterStore.collectionStage!=undefined && this.props.masterStore.collectionStage.progress=='1' ? this.props.masterStore.collectionStage.data : [])
    // const restructuringStage= (this.props.masterStore.restructuringStage!=undefined && this.props.masterStore.restructuringStage.progress=='1' ? this.props.masterStore.restructuringStage.data : [])
    // const telesaleStage= (this.props.masterStore.telesaleStage!=undefined && this.props.masterStore.telesaleStage.progress=='1' ? this.props.masterStore.telesaleStage.data : [])
    // const verificationStage= (this.props.masterStore.verificationStage!=undefined && this.props.masterStore.verificationStage.progress=='1' ? this.props.masterStore.verificationStage.data : [])

    const { svcCampaigns, svcRatios, svcChannels }= this.props.masterStore.listParams && this.props.masterStore.listParams.data ? this.props.masterStore.listParams.data : {}

    const campaignOptions= svcCampaigns
    const channelOptions= svcChannels
    const ratioOptions= svcRatios
    
    // const filteredListTemplate= [{ key: 0, value: null, text: 'No Template', icon: 'code'}];
    // if (this.props.channel_1 && listTemplate.length>0) {
    //   listTemplate.map(item=>{
    //     item.media==this.props.channel_1 && filteredListTemplate.push(item);
    //   })
    // }
    // const filteredListTemplate_1= [{ key: 0, value: null, text: 'No Template', icon: 'code'}];
    // if (this.props.channel_2 && listTemplate.length>0) {
    //   listTemplate.map(item=>{
    //     item.media==this.props.channel_2 && filteredListTemplate_1.push(item);
    //   })
    // }

    // const channels= [];
    // this.props.channel_1 && channels.push(this.props.channel_1)
    // this.props.channel_2 && channels.push(this.props.channel_2)
    
    
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
            {step==0 &&
              this.stepCampaign({campaignOptions: campaignOptions, channelOptions: channelOptions, ratioOptions: ratioOptions})
            }
            {step==1 &&
              this.stepContent({progress: progress, code: code})
            }
            {step==2 &&
              this.stepTarget({progress: progress, code: code})
            }
            {step==3 &&
              this.stepImport({progress: progress, code: code})
            }
            {step==4 &&
              this.stepUpload({progress: progress, code: code})
            }
            {step==5 &&
              this.stepRedistribute({progress: progress, code: code, listCampaign: listCampaign})
            }
            {step==6 &&
              this.stepContact({progress: progress, code: code})
            }
            {step==7 &&
              this.stepConfirmation({dialog: dialog})
            }
            {step==8 &&
              this.stepResult({dialog: dialog})
            }
          </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: ({...(state.campaignStore.getCampaign!=undefined && state.campaignStore.getCampaign.progress==1 ? state.campaignStore.getCampaign.data : {}), startTime: '07:00', endTime: '17:00'}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      campaignType: selector(state, 'campaignType'),
      name: selector(state, 'name'),
      startDate: selector(state, 'startDate'),
      endDate: selector(state, 'endDate'),
      
      media: selector(state, 'media'),
      scriptExt: selector(state, 'scriptExt'),

      delimiter: selector(state, 'delimiter'),
      firstRow: selector(state, 'firstRow'),
      firstRows: selector(state, 'firstRows'),

      contactFile: selector(state, 'contactFile'),
      // isDevice: selector(state, 'isDevice'), //NOT USED
      isRedistribution: selector(state, 'isRedistribution'),

      hasDelivered: selector(state, 'hasDelivered'),
      hasRead: selector(state, 'hasRead'),
      hasClicked: selector(state, 'hasClicked'),
    }),
    { load: getCampaign }
  )
  (reduxForm({
    form: 'WizardForm',	// a unique identifier for this form
  })(WizardForm)
  )
)
