import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'

import ButtonGroup from '../common/ButtonGroup'
import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { browseMau } from "../../actions/talk/mauAction"
import { listClient } from "../../actions/halo/masterAction"
import { voipOptions, yesnoOptions } from '../common/StaticMasterData';


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    mauStore: state.mauStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    listClient:(objParam) => dispatch(listClient(objParam)),
    browseMau:(objParam) => dispatch(browseMau(objParam)),
  }
};


class MauSearch extends Component {
  constructor(props) {
    super(props);
    this.state= {
      searchMode: true,
      lastChange: null,
    }
  }

  componentDidMount() {
    this.props.listClient();
  }

  submitForm(values) {
    const { search }= this.state
    this.props.browseMau({search: {...values, ...search}})
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeSelect(k, e, v) {
    const { search }= (this.props.mauStore.browseMau!==undefined ? this.props.mauStore.browseMau : {})
    if (k=='isHold') {
      this.props.browseMau({search: {...search, isHold: (v=='' ? null : v)}})
    } else if (k=='clientId') {
      this.props.browseMau({search: {...search, clientId: (v=='' ? null : v)}})
    } else if (k=='voipGroup') {
      this.props.browseMau({search: {...search, voipGroup: (v=='' ? null : v)}})
    }
  }

  onChangeInput(e, v) {
    const { search }= (this.props.mauStore.browseMau!==undefined ? this.props.mauStore.browseMau : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.props.browseMau({search: {...search, keyword: v}})
      }
    }, 500)
  }
  
  render() {
    // console.log(this.props)
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode }=this.props
    
    const listClient= (this.props.masterStore.listClient && this.props.masterStore.listClient.progress=='1' ? this.props.masterStore.listClient.data : [])

    return (
      <div>
        <div style={{padding: '1em 1em', background: 'rgba(230, 230, 230, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' style={{marginTop: '.25em'}}>
                <Icon name='users' style={{fontSize: '1em'}}/>
                <Header.Content>
                  Customer / Extension - Monthly Active User / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
                </Header.Content>
              </Header>
            </div>
            <div>
              {mode !=1 &&
              <Button size='mini' color='blue' onClick={this.props.setMode.bind(this,1)} icon style={{padding: '.5em'}}><Icon name='arrow left' /></Button>}
              {mode !=2 && mode !=3 &&
              <Button size='mini' color='red' onClick={this.props.setMode.bind(this,3)} icon style={{padding: '.5em'}}><Icon name='plus' /></Button>}
              {mode ==1 &&
              <Button size='mini' color='grey' onClick={this.showSearch.bind(this)} icon style={{padding: '.5em'}}><Icon name={searchMode==true? 'angle up' : 'angle down'} /></Button>}
            </div>
          </div>

          {mode==1 && searchMode && 
          <div style={{margin: '1em 0 0 0', border: '0px solid rgba(100,100,100,.2', borderRadius: '.25em'}}>
            <Form onSubmit={handleSubmit(this.submitForm.bind(this))} >
              <Form.Group>
                <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)}
                width={8} 
                placeholder= 'Search account ... <ENTER>' />
                <Field name='isHold' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'isHold')}
                width={4}
                options= {yesnoOptions()}
                placeholder= 'Used or Not' />
                <Field name='voipGroup' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'voipGroup')}
                width={4}
                readOnly
                options= {voipOptions('mau-talk')}
                validate={[required]}
                placeholder= 'Account Group' />
                {/* <Field name='clientId' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'clientId')}
                width={4}
                options= {listClient}
                placeholder= 'Client' /> */}
              </Form.Group>
            </Form>
          </div>}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.mauStore.browseMau!=undefined && state.mauStore.browseMau.progress==1 ? state.mauStore.browseMau.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'MauSearch',	// a unique identifier for this form
  })(MauSearch)
  )
)