import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Image, Label } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import InteractionIntro from '../../libraries/agent/inbound/InteractionIntro';
import InteractionSearch from '../../libraries/agent/inbound/InteractionSearch';
import InteractionRecent from '../../libraries/agent/inbound/InteractionRecent';
import InteractionSubject from '../../libraries/agent/inbound/InteractionSubject';
import InteractionVoice from '../../libraries/agent/inbound/InteractionVoice';
import InteractionMessaging from '../../libraries/agent/inbound/InteractionMessaging';
import InteractionSocial from '../../libraries/agent/inbound/InteractionSocial';

class InteractionBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        params: null,
      };
    }

    setMode(mode, params) {
      this.setState({mode: mode, params: params})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        // ticketId: 0,
      });
    }

    render() {
      const {mode, params}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid style={{padding: '1em'}}>
          <Segment style={{padding: '0'}}>
            <div style={{background: '#eeeeee'}}>
              <InteractionSearch setMode={this.setMode.bind(this)} />
            </div>
                
            <div style={{display: 'flex', flexDirection: 'column', minHeight: screenHeight-250}}>
              <div style={{flex: 1, padding: '1.5em 1.5em 3em 1.5em', minHeight: '100%'}}>
                <Label color='red' style={{borderRadius: '2em', padding: '.5em 1em', marginBottom: '2em'}}>
                  <Header as='h4' inverted>
                    <Icon name='comments' style={{fontSize: '1.2em'}}/>
                    <Header.Content>
                      Recent Contacts
                    </Header.Content>
                  </Header>
                </Label>

                <InteractionRecent params={params} setMode={this.setMode.bind(this)} screenHeight={screenHeight}/>
              </div>
              <div style={{background: params ? '#f5f1ed' : '#fcfcfc'}}>
                <Divider horizontal><Icon name='hand point up' style={{fontSize: '2em'}} inverted circular /></Divider>
                <div style={{padding: '1.5em 1.5em', }}>
                  {params && mode==1 && 
                  <ModalContainer size='large' 
                    content={
                      <Segment style={{margin: '1em'}}>
                        <InteractionSubject params={params} setMode={this.setMode.bind(this)} />
                        
                        <Divider />
                        <InteractionVoice params={params} openModal={this.props.openModal} />
                        <InteractionMessaging params={params} openModal={this.props.openModal} />
                        <InteractionSocial params={params} openModal={this.props.openModal} />
                      </Segment>
                    } 
                    closeModal={this.setMode.bind(this, 1)} 
                  />}

                  {/* {params && mode==1 && <InteractionSubject params={params} setMode={this.setMode.bind(this)} />}
                  {params && mode==1 && <InteractionVoice params={params} openModal={this.props.openModal} />}
                  {params && mode==1 && <InteractionMessaging params={params} openModal={this.props.openModal} />} */}
                  {/* {params && mode==1 && <InteractionSocial params={params} openModal={this.props.openModal} />} */}

                  {!params && <InteractionIntro />}
                </div>
              </div>
            </div>
          </Segment>
        </Container>
      )
    }
}

export default InteractionBody
