import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider  } from 'semantic-ui-react'

import CampaignStatistic from '../../libraries/stream/CampaignStatistic';

class CampaignStatisticBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      };
    }

    setMode(mode) {
      this.setState({mode: mode})
    }

    render() {
      const {mode}= this.props
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <CampaignStatistic campaignId={this.props.params.campaignId}/>
        </Container>
      )
    }
}

export default CampaignStatisticBody
