import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import MauBrowse from '../../libraries/ring/MauBrowse'
import MauForm from '../../libraries/ring/MauForm'
import MauSearch from '../../libraries/ring/MauSearch'


class MauBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        voipId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, voipId: id})
    }

    render() {
      const {mode, voipId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div>
            <MauSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <MauBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}/>}
              {mode==2 && <MauForm mode={mode} voipId={voipId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <MauForm mode={mode} voipId={0} setMode={this.setMode.bind(this)} />}
            </div>
          </div>
        </Container>
      )
    }
}

export default MauBody
