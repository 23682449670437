import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react'

import { taggerOptions }  from '../../common/StaticMasterData'
import DismissableMessage from '../../common/DismissableMessage'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField, DatePickerField } from '../../validation/componentrsui'
import { required, number, minValue, email } from '../../validation/validation'
import { connect } from "react-redux"
import { resetWorkgroup, getWorkgroup,updateWorkgroup, createWorkgroup } from "../../../actions/halo/workgroupAction"
import { listWorkgroup, listAgent, listTeamleader, listSupervisor, listController, listManager } from "../../../actions/halo/masterAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    workgroupStore: state.workgroupStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listAgent:(objParam) => dispatch(listAgent(objParam)),
    listTeamleader:(objParam) => dispatch(listTeamleader(objParam)),
    listSupervisor:(objParam) => dispatch(listSupervisor(objParam)),
    listController:(objParam) => dispatch(listController(objParam)),
    listManager:(objParam) => dispatch(listManager(objParam)),

    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),

    resetWorkgroup:(objParam) => dispatch(resetWorkgroup(objParam)),
    getWorkgroup:(objParam) => dispatch(getWorkgroup(objParam)),
    createWorkgroup:(objParam) => dispatch(createWorkgroup(objParam)),
    updateWorkgroup:(objParam) => dispatch(updateWorkgroup(objParam)),
  }
}

class WorkgroupForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    this.props.resetWorkgroup(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getWorkgroup({workgroupId: this.props.workgroupId})
    }
    this.props.listAgent({search:{groupId: 'Agent'}})
    this.props.listTeamleader({search:{groupId: 'Team Leader'}})
    this.props.listSupervisor({search:{groupId: 'Supervisor'}})
    this.props.listController({search:{groupId: 'Controller'}})
    this.props.listManager({search:{groupId: 'Manager'}})
  }

  componentDidUpdate() {
    const { mode, modal } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.workgroupStore.updateWorkgroup!=undefined ? this.props.workgroupStore.updateWorkgroup : {}) : (this.props.workgroupStore.createWorkgroup!=undefined ? this.props.workgroupStore.createWorkgroup : {}))
    if (!modal && progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
    if (modal && progress==1 && code=='00') {
      this.props.closeModal('hide');
      this.props.listWorkgroup({search: {clientId: this.props.clientId || null}})
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, workgroupId } = this.props
    mode==2 ? this.props.updateWorkgroup({...values}) : this.props.createWorkgroup({...values, ...{clientId: this.props.clientId}})
  }
  

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, modal } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.workgroupStore.updateWorkgroup!=undefined ? this.props.workgroupStore.updateWorkgroup : {}) : (this.props.workgroupStore.createWorkgroup!=undefined ? this.props.workgroupStore.createWorkgroup : {}))
    const listAgent= (this.props.masterStore.listAgent!=undefined && this.props.masterStore.listAgent.progress=='1' ? this.props.masterStore.listAgent.data : [])
    const listTeamleader= (this.props.masterStore.listTeamleader!=undefined && this.props.masterStore.listTeamleader.progress=='1' ? this.props.masterStore.listTeamleader.data : [])
    const listSupervisor= (this.props.masterStore.listSupervisor!=undefined && this.props.masterStore.listSupervisor.progress=='1' ? this.props.masterStore.listSupervisor.data : [])
    const listController= (this.props.masterStore.listController!=undefined && this.props.masterStore.listController.progress=='1' ? this.props.masterStore.listController.data : [])
    const listManager= (this.props.masterStore.listManager!=undefined && this.props.masterStore.listManager.progress=='1' ? this.props.masterStore.listManager.data : [])

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              width={6}
              validate={[required]}
              label='Name'
              placeholder='Name' />
              <Field name='tagger' component={SelectField}
              width={2}
              fluid
              options= {taggerOptions}
              validate={[required]}
              label='Tagger'
              placeholder='Tagger' />
              {/* <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' /> */}
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='descript' component={InputField}
              width={16}
              fluid
              placeholder='Description' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Icon name='spy' />
              <Header.Content>
                Leader Allocation
                <Header.Subheader>Set single leader only when necessary.</Header.Subheader>
              </Header.Content>
            </Header>
            {/* <Form.Group widths='equal'>
              <Field name='managers' component={SelectField}
                search
                multiple
                options={listManager}
                label='Manager' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='controllers' component={SelectField}
                search
                multiple
                options={listController}
                label='Quality Control' />
            </Form.Group> */}
            {/* <Form.Group widths='equal'>
              <Field name='supervisors' component={SelectField}
                search
                multiple
                options={listSupervisor}
                label='Supervisor' />
            </Form.Group> */}
            <Form.Group widths='equal'>
              <Field name='teamleaders' component={SelectField}
                search
                multiple
                options={listTeamleader}
                label='Team Leader' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Icon name='user circle'/>
              <Header.Content>
                Agents
                <Header.Subheader>Allocate agent for this group, common total agent between 10 to 20 agents for each group.</Header.Subheader>
              </Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='agents' component={SelectField}
              validate={[required]}
              multiple
              search
              options={listAgent}
              label='Ingroups Agents' />
            </Form.Group>
          </Segment>
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Workgroup' : 'Insert Workgroup') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.workgroupStore.getWorkgroup!=undefined && state.workgroupStore.getWorkgroup.progress==1 ? state.workgroupStore.getWorkgroup.data : {managers: [], controllers: [], supervisors: [], teamleaders: [], agents: []}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'WorkgroupForm',	// a unique identifier for this form
  })(WorkgroupForm)
  )
)