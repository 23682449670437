import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Table,
  Label,
  Progress,
  Pagination,
  Rating,
  Modal,
} from 'semantic-ui-react'

import ModalContainer from '../common/ModalContainer'
import TargetBody from '../../pages/stream/TargetBody'

import numeral from "numeral"
import { parse, format } from "date-fns"

import { connect } from "react-redux"
import { browseCampaign, statusCampaign } from "../../actions/stream/campaignAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    campaignStore: state.campaignStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseCampaign:(objParam) => dispatch(browseCampaign(objParam)),
    statusCampaign:(objParam) => dispatch(statusCampaign(objParam)),
  }
};

class CampaignBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {
      showTarget: false,
    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.campaignStore.browseCampaign!==undefined ? this.props.campaignStore.browseCampaign : {})
    this.props.browseCampaign(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.campaignStore.browseCampaign!==undefined ? this.props.campaignStore.browseCampaign : {})
    this.props.browseCampaign({search: {eventDate: format(new Date(), 'yyyy-MM-dd'), ...search}, paging: paging})
  }

  onChangeStatus(id, status) {
    this.props.statusCampaign({campaignId:id, isEnabled: status})
  }

  showTarget(campaignId, clientId, campaign) {
    this.setState({
      showTarget: true,
      clientId: clientId,
      campaignId: campaignId,
      campaign: campaign,
    })
  }

  onCloseModal() {
    this.setState({
      showTarget: false,
    })
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.campaignStore.browseCampaign!==undefined ? this.props.campaignStore.browseCampaign : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1'}}>
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                {this.props.setMode!=undefined &&
                <Table.HeaderCell></Table.HeaderCell>
                }
                <Table.HeaderCell width='5'>
                  <Header as='h5' color='blue'>CAMPAIGN</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='3'>
                  <Header as='h5' color='blue'>DELIVERY</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='4'>
                  <Header as='h5' color='blue'>TARGETS</Header>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Header as='h5' color='blue'></Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='4'>
                  <Header as='h5' color='blue'>COMPLETENESS (%)</Header>
                </Table.HeaderCell>
                {/* <Table.HeaderCell width='1'>
                  
                </Table.HeaderCell> */}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!=undefined ? data.map((item,key)=>{
                return( 
                  <Table.Row key={++seq}>
                    <Table.Cell rowSpan={1}>{seq}</Table.Cell>
                    {this.props.setMode!=undefined &&
                    <Table.Cell rowSpan={1} textAlign='center'>
                      <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.campaignId, item.clientId)}/>
                    </Table.Cell>
                    }
                    <Table.Cell>
                      <Header as='h5'>
                        {/* <Icon name={item.isEnabled=='1'? 'calendar check outline' : 'calendar times outline'} color={item.isEnabled=='1'? 'blue' : 'red'} /> */}
                        <Icon 
                          color={(item.isEnabled=='0' && 'grey') || (item.isExpired=='-1' && 'yellow') || (item.isExpired=='1' && 'red') || 'teal'} 
                          name='circle thin' 
                          style={{fontSize: '1.1em'}} />
                        <Header.Content>
                          <Header.Subheader><b>{item.campaignType}</b> {item.dialingMode && <b>{' - ' + item.dialingMode +' Mode'}</b>} <b>{(item.isEnabled=='0' && <i> (Disabled)</i>) || (item.isExpired=='-1' && <i> (Upcoming)</i>) || (item.isExpired=='1' && <i> (Expired)</i>) || ''}</b></Header.Subheader>
                          <b>{item.name}</b>
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Header as='h5'>
                        <Header.Content>
                          <Header.Subheader>{item.startTime} - {item.endTime} / {item.channels && <b>{item.channels.join('+')}</b>}</Header.Subheader>
                            {item.startDate!=null ? format(parse(item.startDate, 'yyyy-MM-dd', new Date()), 'dd / MMM') : ''} - 
                            {item.endDate!=null ? format(parse(item.endDate, 'yyyy-MM-dd', new Date()), 'dd / MMM, yyyy') : ''}
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <div style={{display: 'flex'}}>
                        <div style={{flex: '1', padding: '0 1em 0 0'}}>
                          <Header as='h5' color='red' style={{cursor: 'pointer'}} onClick={this.showTarget.bind(this, item.campaignId, item.clientId, {name: item.name, campaignType: item.campaignType})}>
                            <Icon name='window restore outline' style={{fontSize: '1em'}}/>
                            <Header.Content style={{fontWeight: 'bold'}}>
                              {numeral(item.totTarget).format('0,0')}
                              <Header.Subheader>Audience</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </div>
                        <div style={{flex: '1', padding: '0 .5em'}}>
                          <Header as='h5' color='blue'>
                            <Header.Content style={{fontWeight: 'bold'}}>
                              {numeral(item.totAttempt).format('0,0')}
                              <Header.Subheader>Attempt</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </div>
                        <div style={{flex: '1', padding: '0 1em'}}>
                          <Header as='h5' color='blue'>
                            <Header.Content style={{fontWeight: 'bold'}}>
                              {numeral(item.totDelivered).format('0,0')}
                              <Header.Subheader>Delivered</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      {/* <Form.Checkbox name={'isEnabled_'+ item.campaignId} 
                      toggle
                      defaultChecked={item.isEnabled=='1' ? true : false}
                      onChange={this.onChangeStatus.bind(this, item.campaignId,(item.isEnabled=='1' ? '0' : '1'))} /> */}
                      <Icon name={item.isEnabled ? 'toggle on' : 'toggle off'} color={item.isEnabled ? 'teal' : 'grey'} style={{fontSize: '1.5em'}}/>
                    </Table.Cell>
                    <Table.Cell>
                      <Progress indicating size='medium' percent={item.totTarget>0 ? Math.round((item.totComplete / item.totTarget) * 100) : 0} progress><b style={{fontSize: '.9em'}}>{item.client || 'posted by Unknown'}</b></Progress>
                    </Table.Cell>
                    {/* <Table.Cell style={{whiteSpace: 'nowrap'}}>
                      <Header as='h5' color='blue' style={{cursor: 'pointer'}}>
                        <Label style={{margin: '.2em .5em 0 0'}} color='red'>1</Label>
                        <Header.Content>
                          <Rating defaultRating={3} maxRating={5} />
                          <Header.Subheader>Bad, Fine, Good, Great, Excellent</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Table.Cell> */}
                  </Table.Row>
                )
              }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
            }
            </Table.Body>
          </Table>
          
          {this.state.campaignId && this.state.showTarget && 
          <ModalContainer size='fullscreen' open={this.state.showTarget}  content={<TargetBody screenHeight={screenHeight} campaignId={this.state.campaignId}  campaign={this.state.campaign}  clientId={this.state.clientId} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />} closeModal={this.onCloseModal.bind(this)} />
          }
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignBrowse)
