import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'


// import ModalContainer from '../../../libraries/common/ModalContainer';
import MenuHorizontal from '../../../libraries/common/MenuHorizontal';

import StatisticBody from './StatisticBody'
// import StatisticBody from '../halo/StatisticBody'
// import OccupancyBody from '../talk/DashboardBody'
import PerformanceBody from '../stream/PerformanceBody'

const options= (module)=>{
  return([
    {
      key: '1',
      icon: 'chart bar',
      caption: 'Statistic',
      hint:{
        header: 'Campaign Statistic',
        content: 'Monitor your current active and recent campaign, click to make sure you know the details.'
      }
    },
    // {
    //   key: '2',
    //   icon: 'chart bar',
    //   caption: 'Occupancy',
    //   hint:{
    //     header: 'Tunnel / MAU Occupancy',
    //     content: 'Capacity tunnel and accounts which available and already occupied.'
    //   }
    // },
    // {
    //   key: '2',
    //   icon: 'desktop',
    //   caption: 'Monitoring',
    //   hint:{
    //     header: 'Agent Monitoring',
    //     content: 'Real time agent performance and activities, show them you are watching all the times.'
    //   }
    // },
    // {
    //   key: '3',
    //   icon: 'calendar alternate outline',
    //   caption: 'Perf. Campaign',
    //   hint:{
    //     header: 'Campaign Result',
    //     content: 'Your deep analysis of any campaigns which delivered or on progress.'
    //   }
    // },
    {
      key: '4',
      icon: 'user circle outline',
      caption: 'Perf. Agent',
      hint:{
        header: 'Agent Statistic',
        content: 'Agent activity statistic performance, available for one month performance.'
      }
    },
    // {
    //   key: '5',
    //   icon: 'file alternate outline',
    //   caption: 'Reporting',
    //   hint:{
    //     header: 'Transaction Reporting',
    //     content: 'Download your reporting or manualy generate if necessary.'
    //   }
    // },
    // {
    //   key: '6',
    //   icon: 'tv',
    //   caption: 'Wallboard',
    //   hint:{
    //     header: 'Wallboard Display',
    //     content: 'Show your platform real time status on big screen.'
    //   }
    // },
  ])
}

class DashboardBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        // dashboardId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, dashboardId: id})
    }

    render() {
      const {mode, dashboardId}= this.state
      const {screenWidth, screenHeight, module}= this.props

      return(
        <Container fluid>
          <div style={{margin: '0em 1em 0 1em'}}>
            <MenuHorizontal screenWidth={screenWidth} onClick={this.setMode.bind(this)} options= {options(module)} color='red'/>
          </div>
          <div>
            {mode==1 && <StatisticBody screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow.bind(this)} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
            {/* {mode==2 && <OccupancyBody screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow.bind(this)} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />} */}
            
            {/* {mode==3 && <PerformanceBody mode={2} screenHeight={screenHeight} screenWidth={screenWidth} />} */}
            {mode==4 && <PerformanceBody mode={3} screenHeight={screenHeight} screenWidth={screenWidth} />}
          </div>
        </Container>
      )
    }
}

export default DashboardBody
