import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider } from 'semantic-ui-react'
import OccupancyBrowse from '../../../libraries/realm/ring/OccupancyBrowse';

class DashboardBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      userId: 0,
    };
  }

  setMode(mode, id) {
    this.setState({mode: mode, userId: id})
  }

  render() {
    const {screenWidth, screenHeight}= this.props
    
    return(
      <Container fluid>
        <OccupancyBrowse screenWidth={screenWidth} screenHeight={screenHeight}/>
      </Container>
    )
  }
}

export default DashboardBody
