import React, { Component } from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Label } from 'semantic-ui-react'

import { Link } from 'react-router-dom'
import {withRouter} from "react-router-dom"

import { connect } from "react-redux"
import { signOut } from "../../actions/auth/authAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut:(objParam) => dispatch(signOut(objParam)),
  }
}

const stylePopup = {
  borderRadius: '0',
  opacity: 1,
  padding: '.5em .8em',
  fontSize: '1em',
  background: 'rgb(122 223 255)',
  color: '#000'
}
const styleButton={
  margin: '.3em 0em',
  border: 'none',
}

class EmulatorHeader extends Component {
  constructor(props) {
    super(props);
    this.state={
      openDrawer: false
    }
  }

  openDrawer() {
    this.props.openDrawer()
  }

  doSignOut(values) {
    this.props.signOut(values)
    this.props.history.push('/signin')
  }

  openWindow(value) {
    this.props.openWindow(value)
    this.setState({
      
    })
  }

  componentDidMount() {
    const tokenAuth= localStorage.getItem('tokenAuth');
    if (tokenAuth==undefined || tokenAuth=='') {
      this.props.history.push('/signin')
    }
  }

  componentDidUpdate() {
    if (localStorage.getItem('tokenAuth')=='') {
      this.props.history.push('/signin');
    }
  }
  
  render() {
    const { screenWidth,  screenHeight, mini }= this.props
    
    return (
      <div style={{marginLeft: '5em'}}>
        <Menu fixed='top'  style={{padding: '0', border: 'none', boxShadow: 'none', background : 'none'}}>
          <div style={{display: 'flex', width: '100%', background : 'rgb(0, 0, 0)'}}>
            <div style={{padding: '.3em 0', textAlign: 'center'}}>
              <Icon name='rocket' style={{fontSize: '1.3em', margin: '.5em'}} color={mini==true ? 'grey' : 'yellow'} />
            </div>
            <div style={{padding: '1em 0 0 1em'}}>
              <Header as='h5' color='grey' inverted>
                <Header.Content>
                  Emulator / ver. 2.3.01 - 2023
                </Header.Content>
              </Header>
            </div>
            <div style={{padding: '.5em 0 0 1em'}}>
              
            </div>
            <div style={{flex: '1', textAlign: 'right', paddingTop: '.25em', marginRight: '1em'}}>
              <Popup style={stylePopup}
                trigger={<Button onClick={()=>this.doSignOut()} color={this.props.mode=='signout'? 'yellow' : null} style={{...styleButton, marginLeft: '3em', background: '#444'}} icon><Icon name='sign-out' style={{color: this.props.mode=='signout' ? '#000' : '#f61'}} /></Button>}
                content='Signout'
                inverted
                on={['hover']}
              />
            </div>
          </div>
        </Menu>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmulatorHeader))