// import socketIOClient from "socket.io-client";
// const socket = socketIOClient('http://127.0.0.1:4001');

// socket.on('FromAPI', payload => {
//   // update messages
//   // console.log('!!! WEBSOCKET EMIT EVENT', payload)
//   return payload
// })

export default function socketReducer (state = {}, action) {
  // console.log("!!! REDUCER SOCKET", action, state)

  switch (action.type) {
    case 'SOCKET_STATUS': {
      // console.log("!!! REDUCER SOCKET", action, state)
      return({...state, socketStatus: {progress: 1, data: action.payload}})
    }

    case 'SOCKET_REQUEST_PENDING': {
      // console.log("!!! REDUCER SOCKET", action, state)
      return({...state, socketRequest: {progress: 0, data: action.payload}})
    }
    case 'SOCKET_REQUEST_FULFILLED': {
      // console.log("!!! REDUCER SOCKET", action, state)
      return({...state, socketRequest: {progress: 1, data: action.payload}})
    }
    case 'SOCKET_REQUEST_REJECTED': {
      // console.log("!!! REDUCER SOCKET", action, state)
      return({...state, socketRequest: {progress: 2, data: action.payload}})
    }

    case 'SOCKET_TRANSMIT': {
      // console.log("!!! REDUCER SOCKET", action, state)
      return({...state, socketTransmit: {progress: 1, data: action.payload}})
    }

    case 'SOCKET_BROADCAST': {
      // console.log("!!! REDUCER SOCKET", action, state)
      return({...state, socketBroadcast: {progress: 1, data: action.payload}})
    }

    case 'SOCKET_AGENT_STATUS': {
      // console.log("!!! REDUCER SOCKET", action, state)
      return({...state, socketAgentStatus: {progress: 1, data: action.payload}})
    }
    case 'SOCKET_AGENT_STATISTIC': {
      // console.log("!!! REDUCER SOCKET", action, state)
      return({...state, socketAgentStatistic: {progress: 1, data: action.payload}})
    }
    case 'SOCKET_AGENT_NOTIFICATION': {
      // console.log("!!! REDUCER SOCKET", action, state)
      return({...state, socketAgentNotification: {progress: 1, data: action.payload}})
    }

    case 'SOCKET_SYSTEM_HEARTBEAT': {
      // console.log("!!! REDUCER SOCKET", action, state)
      return({...state, socketSystemHeartbeat: {progress: 1, data: action.payload}})
    }
    case 'SOCKET_SYSTEM_MONITORING': {
      // console.log("!!! REDUCER SOCKET", action, state)
      return({...state, socketSystemMonitoring: {progress: 1, data: action.payload}})
    }
    case 'SOCKET_CAMPAIGN_MONITORING': {
      // console.log("!!! REDUCER SOCKET", action, state)
      return({...state, socketCampaignMonitoring: {progress: 1, data: action.payload}})
    }
    case 'SOCKET_SYSTEM_OCCUPANCY': {
      // console.log("!!! REDUCER SOCKET", action, state)
      return({...state, socketSystemOccupancy: {progress: 1, data: action.payload}})
    }
    case 'SOCKET_WHATSAPP_CONNECTION': {
      // console.log("!!! REDUCER SOCKET", action, state)
      return({...state, socketWhatsappConnection: {progress: 1, data: action.payload}})
    }
    case 'SOCKET_INAPP_CONNECTION': {
      // console.log("!!! REDUCER SOCKET", action, state)
      return({...state, socketInappConnection: {progress: 1, data: action.payload}})
    }

    default: {
      return state
    }
  }
}