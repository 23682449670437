import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetBilling(objParam) {
  return {
      type: 'BILLING_RESET',
      payload: objParam
  }
}

export function getBilling(objParam) {
  return {
      type: 'BILLING_GET',
      payload:
        axios.post(
          urlAPI + 'billing/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusBilling(objParam) {
  return {
      type: 'BILLING_STATUS',
      payload:
        axios.post(
          urlAPI + 'billing/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseBilling(objParam) {
  return {
      type: 'BILLING_BROWSE',
      payload:
        axios.post(
          urlAPI + 'billing/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createBilling(objParam) {
  return {
      type: 'BILLING_CREATE',
      payload:
        axios.post(
          urlAPI + 'billing/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateBilling(objParam) {
  return {
      type: 'BILLING_UPDATE',
      payload:
        axios.post(
          urlAPI + 'billing/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeBilling(objParam) {
  return {
      type: 'BILLING_REMOVE',
      payload:
        axios.post(
          urlAPI + 'billing/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
