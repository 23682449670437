import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Table,
  Label,
  Progress,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import PieRechart from '../chart/PieRechart';
import RadialRechart from '../chart/RadialRechart';

import ModalContainer from '../common/ModalContainer'

import numeral from "numeral"
import { format, parse } from "date-fns"
import { connect } from "react-redux"
import { browseCampaign, statusCampaign } from "../../actions/stream/campaignAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    campaignStore: state.campaignStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseCampaign:(objParam) => dispatch(browseCampaign(objParam)),
    statusCampaign:(objParam) => dispatch(statusCampaign(objParam)),
  }
};


class CampaignBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.campaignStore.browseCampaign!==undefined ? this.props.campaignStore.browseCampaign : {})
    this.props.browseCampaign(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.campaignStore.browseCampaign!==undefined ? this.props.campaignStore.browseCampaign : {})
    this.props.browseCampaign({search: {eventDate: format(new Date(), 'yyyy-MM-dd'),showAuto: '0',  ...search}, paging: paging})
  }

  onChangeStatus(id, status) {
    this.props.statusCampaign({campaignId:id, isEnabled: status})
  }

  showTarget(campaignId, clientId, campaign) {
    this.setState({
      showTarget: true,
      clientId: clientId,
      campaignId: campaignId,
      campaign: campaign,
    })
  }

  onCloseModal() {
    this.setState({
      showTarget: false,
    })
  }

  viewStartCampaign() {
    return(<div style={{padding: '.5em 0 3em 0'}}>
      <Header as='h1' color='blue' icon style={{display: 'block', textAlign: 'center'}}>
        {/* <Icon name='hand point up outline' style={{fontSize: '1.5em', margin: '-1.2em 0 .5em 0'}}/> */}
        <Header.Content>
          <b>Start Campaign Now !</b>
          <Header.Subheader style={{marginTop: '1.5em', fontWeight: 200, color: '#333'}}>
            Your campaign is not available yet, please add several campaigns to broadcast your whatsapp message for <b>discount</b> and <b>voucher</b> promotion.
            <Divider style={{margin: '2em 0'}}/>
            Click wizard <Icon name='plus square' color='red' style={{margin: '0', display:'inline', fontSize: '1em'}}/> button to start your new campaign.
          </Header.Subheader>
        </Header.Content>
      </Header>
    </div>)
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.campaignStore.browseCampaign!==undefined ? this.props.campaignStore.browseCampaign : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    const legendDelivery = [
      {id: 'value', name: 'Progress'}, 
    ];
    
    return (
      <div style={{display: 'flex', padding: '1em 0 0 0', flexDirection: 'column'}}>
        <div style={{flex: '1'}}>
          { data!=undefined && data.length==0 && this.viewStartCampaign()}
          
          <Card.Group itemsPerRow={screenWidth>=1900 ? 6 : 4} stackable doubling>
            { data!=undefined && data.map((item,idx)=>{
              const pieDelivery = [
                { name: 'Target', value: item.totTarget },
                { name: 'Sent', value: item.totAttempt },
                { name: 'Delivered', value: item.totDelivered }, 
                { name: 'Read', value: item.totEngaged },
                // { name: 'Clicked', value: 3 },
              ];
              const radialDelivery = [
                {name: 'Target', value: item.totTarget, fill: '#bbbbbb'},
                {name: 'Sent', value: item.totAttempt, fill: '#b5cc18'},
                {name: 'Delivered', value: item.totDelivered, fill: '#21ba45'},
                {name: 'Read', value: item.totEngaged, fill: '#fbbd08'},
                // {name: 'Clicked', value: 5, fill: '#f2711c'},
              ];

              return(
                <Card key={idx} as='a' onClick={this.props.openModal.bind(this, 'campaign', item)} style={{cursor: 'pointer', opacity: (item.isEnabled!='1' && .8) || (item.isExpired=='1' &&.8) || 1}}>
                  <Card.Content style={{padding: '1em'}}>
                    <Card.Meta>{item.campaignType} <b>{(item.isEnabled!='1' && <i> (Disabled)</i>) || (item.isExpired=='-1' && <i> (Upcoming)</i>) || (item.isExpired=='1' && <i> (Expired)</i>)}</b></Card.Meta>
                    <Card.Header style={{margin: '.2em 0 .2em 0', color: item.isEnabled=='1' ? '#0065c1' : '#444',  fontWeight: 'bold', fontSize: '1.6em'}}>{item.name}</Card.Header>
                    <Card.Meta>{item.startTime} - {item.endTime}, {item.startDate!=null ? format(parse(item.startDate, 'yyyy-MM-dd', new Date()), 'dd / MMM') : ''} - {item.endDate!=null ? format(parse(item.endDate, 'yyyy-MM-dd', new Date()), 'dd / MMM, yyyy') : ''}</Card.Meta>
                  </Card.Content>
                  {/* <Card.Content extra>
                    <Card.Description className='noscroll' style={{margin: '.5em', height: '10em', overflow: 'auto'}}>
                      {item.policies.scriptExt ? <pre style={{marginTop: '-.1em', fontFamily: 'Arial', fontSize: '11pt', fontWeight: 'bold', color: '#333'}}>{item.policies.scriptExt}</pre> : <i style={{fontSize: '12pt'}}>Content is manageable through API integration.</i>}
                    </Card.Description>
                  </Card.Content> */}
                  <Card.Content extra style={{background: '#dfdfd7'}}>
                    PROGRESS
                    {/* <Progress color='red' size='small' percent={item.totTarget>0 ? Math.round((item.totComplete / item.totTarget) * 100) : 0} progress><b style={{fontSize: '.9em'}}>{item.client || 'posted by Unknown'}</b></Progress> */}
                    <Progress indicating size='small' percent={item.totTarget>0 ? Math.round((item.totComplete / item.totTarget) * 100) : 0} progress />
                  </Card.Content>
                  <Card.Content extra style={{background: '#f1f1f0'}}>
                    <div style={{height: '200'}}>
                      IMPRESSION
                      <PieRechart data={pieDelivery} height={200} fill='#555'/>
                      {/* <RadialRechart data={radialDelivery} legend={legendDelivery} height={250}/> */}
                    </div>
                  </Card.Content>
                </Card>)
            })}
          </Card.Group>
          
          {/* {this.state.campaignId && this.state.showTarget && 
          <ModalContainer size='fullscreen' open={this.state.showTarget}  content={<TargetBody screenHeight={screenHeight} campaignId={this.state.campaignId} campaign={this.state.campaign} />} closeModal={this.onCloseModal.bind(this)} />
          } */}
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'center'}}>
            <div style={{display:'inline-block', borderRadius: '3em', padding: '.5em', background: '#cbfb00', border: '1px solid rgba(100, 100, 100, .3)'}}>
              <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
                defaultActivePage={paging.page}
                totalPages={Math.ceil(paging.count/paging.size)}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignBrowse)
