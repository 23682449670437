import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import InteractiveBrowse from '../../libraries/flow/InteractiveBrowse'
import InteractiveContent from '../../libraries/flow/InteractiveContent'
import InteractiveForm from '../../libraries/flow/InteractiveForm'
import InteractiveSearch from '../../libraries/flow/InteractiveSearch'

import ModalContainer from '../../libraries/common/ModalContainer';


class InteractiveBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        interactiveId: 0,
      };
    }

    setMode(mode, interactiveId, clientId, params) {
      this.setState({mode: mode, interactiveId: interactiveId, clientId: clientId, params: params})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        interactiveId: 0,
      });
    }

    render() {
      const {mode, interactiveId, clientId, params}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              <InteractiveSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
              <div>
                {(mode==1 || mode==4)&& <InteractiveBrowse setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
                {mode==2 && <InteractiveForm mode={mode} interactiveId={interactiveId} clientId={clientId} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />}
                {mode==3 && <InteractiveForm mode={mode} interactiveId={0} clientId={0} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />}
                {mode==4 && <ModalContainer size='large' content={<InteractiveContent mode={mode} interactiveId={interactiveId} clientId={clientId} params={params} setMode={this.setMode.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
              </div>
            </div>
          </div>
        </Container>
      )
    }
}

export default InteractiveBody
