import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider } from 'semantic-ui-react'

import ModalContainer from '../../../libraries/common/ModalContainer';

import ReportingSearch from '../../../libraries/stream/ReportingSearch';
import ReportingProcess from '../../../libraries/stream/ReportingProcess';
import ReportingDownload from '../../../libraries/stream/ReportingDownload';
import ReportingForm from '../../../libraries/stream/ReportingForm';


class ReportingBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        reportId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, reportId: id})
    }

    closeModal() {
      this.setState({mode: 1})
      this.props.closeModal();
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return( 
        <Container fluid  style={{minHeight: '100%'}}>
          <div style={{display: 'flex', minHeight: '100%'}}>
            <div style={{flex: '1', minHeight: '100%', background: '#fcfcfc'}}>
              <ReportingSearch setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
              <ReportingDownload setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
            </div>
            {/* <div style={{width: '350px', padding: '1em', margin: '0 -.5em 0 0', minHeight: '100%', background: '#fff', borderLeft: '1px solid #ddd'}}>
              <ReportingProcess setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
            </div> */}
          </div>
          {mode==3 && <div>
              <ModalContainer size='small' content={<ReportingForm screenWidth={screenWidth} screenHeight={screenHeight} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />
          </div>}
        </Container>
      )
    }
}

export default ReportingBody
