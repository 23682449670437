import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
} from 'semantic-ui-react'

import { connect } from "react-redux"
import { browseTrespassing } from "../../actions/halo/trespassingAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    trespassingStore: state.trespassingStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseTrespassing:(objParam) => dispatch(browseTrespassing(objParam)),
  }
};


class TrespassingBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.trespassingStore.browseTrespassing!==undefined ? this.props.trespassingStore.browseTrespassing : {})
    this.props.browseTrespassing(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.trespassingStore.browseTrespassing!==undefined ? this.props.trespassingStore.browseTrespassing : {})
    this.props.browseTrespassing({search: {...search}, paging: paging})
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.trespassingStore.browseTrespassing!==undefined ? this.props.trespassingStore.browseTrespassing : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight-125}}>
        <div style={{flex: '1'}}>
        <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell width='1'>
                  <Header as='h5' color='blue'>
                    PERIOD
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>
                    SCOPE
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='1'>
                  <Header as='h5' color='blue'>
                    ATTEMPT
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='12'>
                  <Header as='h5' color='blue'>
                    WARNING
                  </Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  
                  const warning= item.warning;
                  // const warning= JSON.stringify(item.warning);
                  const warnings= warning.split('\n');
                  
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell textAlign='center'>
                        <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.trespassingId)}/>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.period}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5' color='red'>
                          <Header.Content>
                            <Header.Subheader><b>{item.network}</b></Header.Subheader>
                            {item.scope}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5' style={{margin: '0 0 .5em 0'}}>
                          <Header.Content>
                            {item.totAttempt}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        { warnings && warnings[0] && <div style={{marginBottom: '1.5em'}} dangerouslySetInnerHTML={{ __html: warnings[0] }}></div>}
                        { warnings && warnings[1] && <div style={{marginBottom: '1.5em'}} dangerouslySetInnerHTML={{ __html: warnings[1] }}></div>}
                        { warnings && warnings[1] && <div style={{marginBottom: '1.5em'}}> . . .</div>}
                        { warnings && warnings.length>2 && warnings[warnings.length-1] && <div style={{marginBottom: '1.5em'}} dangerouslySetInnerHTML={{ __html: warnings[warnings.length-1] }}></div>}
                        
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        
        {paging!=undefined && paging.count>0 &&
            <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
              <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
                defaultActivePage={paging.page}
                totalPages={Math.ceil(paging.count/paging.size)}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrespassingBrowse)
