import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'
import ModalContainer from '../../libraries/common/ModalContainer';

import OTPServiceBody from '../middleware/OTPServiceBody';
import CommServiceBody from '../middleware/CommServiceBody';
import APISandboxBody from '../middleware/APISandboxBody';

import APIServiceBody from '../middleware/APIServiceBody';
import APIInventoryBody from '../middleware/APIInventoryBody';
import ApilogBody from '../system/ApilogBody';

class AdminBodyMiddleware extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }
  
  render() {
    const { mode, module, screenWidth, screenHeight, mini, modal, params }= this.props

    return (
      <div>
           {mode==='OTP service' && <OTPServiceBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
           {mode==='Comm service' && <CommServiceBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
           {mode==='API sandbox' && <APISandboxBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
           
           {mode==='API inventory' && <APIInventoryBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
           {mode==='API service' && <APIServiceBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
           {mode==='API logger' && <ApilogBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
      </div>
    )
  }
}

export default AdminBodyMiddleware