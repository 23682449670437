import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Table } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'
import MenuTabular from '../common/MenuTabular'
import { ticketOptions, progressOptions, durationOptions, resultOptions, priorityOptions, actionOptions, yesnoOptions, ratingOptions }  from '../common/StaticMasterData'

import numeral from "numeral"
import { format, parse, addSeconds } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetHandling, getHandling,updateHandling, createHandling } from "../../actions/service/handlingAction"
import { listWorkgroup, listSegment, listParams } from "../../actions/halo/masterAction"
import { handlingTicket } from "../../actions/service/ticketAction"

//CONNECT REDUX STORE
const selector = formValueSelector('HandlingForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    ticketStore: state.ticketStore,

    handlingStore: state.handlingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listSegment:(objParam) => dispatch(listSegment(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    listParams:(objParam) => dispatch(listParams(objParam)),

    handlingTicket:(objParam) => dispatch(handlingTicket(objParam)),

    resetHandling:(objParam) => dispatch(resetHandling(objParam)),
    getHandling:(objParam) => dispatch(getHandling(objParam)),
    createHandling:(objParam) => dispatch(createHandling(objParam)),
    updateHandling:(objParam) => dispatch(updateHandling(objParam)),
  }
}

const panes = (param)=>{
  return([
    {
      menuItem: { key: '0', icon: 'ticket', content: 'Ticket' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '1', icon: 'wizard', content: 'Actions' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '2', icon: 'building outline', content: 'Customer' },
      render: () => 
      <></>
    },
  ])
};

class HandlingForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remarkResult: null,

      tab: 0,
    }
  }

  componentDidMount(){
    this.props.listParams({clientId: this.props.clientId!=undefined ? this.props.clientId : null})

    this.props.resetHandling(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getHandling({handlingId: this.props.handlingId})
    }

    // this.props.listSegment({search: {clientId: this.props.clientId}})
    this.props.listWorkgroup({search: {clientId: this.props.clientId}})
  }

  componentDidUpdate(prevProps) {
    const { mode } = this.props
    const { header }= (this.props.ticketStore.handlingTicket!==undefined ? this.props.ticketStore.handlingTicket : {})
    const { progress, code, text }= (mode==2 ? (this.props.handlingStore.updateHandling!=undefined ? this.props.handlingStore.updateHandling : {}) : (this.props.handlingStore.createHandling!=undefined ? this.props.handlingStore.createHandling : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }

    if (header && (!this.props.ticketDuration || !this.props.openStamp || !this.props.closeStamp)) {
      !this.props.ticketDuration && this.props.dispatch(change('HandlingForm', 'ticketDuration', header.ticketDuration));
      !this.props.openStamp && this.props.dispatch(change('HandlingForm', 'openStamp', header.openStamp));
      !this.props.closeStamp && this.props.dispatch(change('HandlingForm', 'closeStamp', format(new Date(), 'yyyy-MM-dd HH:mm:ss')));
    }
    
    if (this.props.handlingType) {
      this.props.segment && this.props.segment.segmentHandlings && this.props.segment.segmentHandlings.map((item, idx)=>{
        if (this.props.handlingType==item.key) {
          this.props.dispatch(change('HandlingForm', 'handlingParams', item.params));
        }
      })
    } else {
      this.props.dispatch(change('HandlingForm', 'handlingParams', []));
    }

    // if (this.props.segment) {
      if (this.props.remark=='Dispatch' && (!this.props.dispatches || this.props.dispatches.length==0)) {
        this.props.dispatch(change('HandlingForm', 'dispatches', this.props.segment.dispatches));
      }
      if (this.props.remark=='Disposition' && (!this.props.dispositions || this.props.dispositions.length==0)) {
        this.props.dispatch(change('HandlingForm', 'dispositions', this.props.segment.dispositions));
      }
      if (this.props.remark=='Escalation' && (!this.props.escalations || this.props.escalations.length==0)) {
        this.props.dispatch(change('HandlingForm', 'escalations', this.props.segment.escalations));
      }
    // }

    //UPDATE DURATION
    if (this.props.actionDuration) {
      if (!this.props.actionStamp) {
        this.props.dispatch(change('HandlingForm', 'actionStamp', format(new Date(), 'yyyy-MM-dd HH:mm:ss')));
        this.props.dispatch(change('HandlingForm', 'completeStamp', format(addSeconds(new Date(), this.props.actionDuration * 3600), 'yyyy-MM-dd HH:mm:ss')));
      } else {
        this.props.dispatch(change('HandlingForm', 'completeStamp', format(addSeconds(parse(this.props.actionStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), this.props.actionDuration * 3600), 'yyyy-MM-dd HH:mm:ss')));
      }
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { header }= (this.props.ticketStore.handlingTicket!==undefined ? this.props.ticketStore.handlingTicket : {})
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateHandling({...values}) : this.props.createHandling({...(!values.ticketId ? {ticketId: header.ticketId} : {}), ...values})
    
  }

  onChangeResult(v) {
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})

    this.props.dispatch(change('HandlingForm', 'progress', listRemark.progress[v]));
  }

  setMode(e, d) {
    // console.log(d.activeIndex)
    this.setState({tab: d.activeIndex})
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.handlingStore.getHandling!=undefined && this.props.handlingStore.getHandling.progress=='1' ? this.props.handlingStore.getHandling : {})
    const { header }= (this.props.ticketStore.handlingTicket!==undefined ? this.props.ticketStore.handlingTicket : {})
    const { progress, code, text }= (mode==2 ? (this.props.handlingStore.updateHandling!=undefined ? this.props.handlingStore.updateHandling : {}) : (this.props.handlingStore.createHandling!=undefined ? this.props.handlingStore.createHandling : {}))

    // const listSegment= (this.props.masterStore.listSegment!=undefined && this.props.masterStore.listSegment.progress=='1' ? this.props.masterStore.listSegment.data : [])
    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])

    const { svcCampaigns, svcRatios, svcChannels }= this.props.masterStore.listParams && this.props.masterStore.listParams.data ? this.props.masterStore.listParams.data : {}

    const channelOptions= svcChannels
    
    var handlingOptions= [], handlingParams= []
    // if (data && data.segment && data.segment.handlings) {
    //   data && data.segment && data.segment.handlings.map((item, i)=>{
    //     handlingOptions.push({
    //       key: i,
    //       value: item,
    //       text: item,
    //     })
    //   })

    //   this.props.handlingParams && this.props.handlingParams.map((item, idx)=>{
    //     handlingParams.push(item)
    //   })
    if (data && data.segment && data.segment.segmentHandlings) {
      data.segment.segmentHandlings.map((item, i)=>{
        handlingOptions.push({
          key: i,
          value: item.key,
          text: item.key,
          params: item.params,
        })
        if (item.key==this.props.handlingType) {
          item.params.map(item=>{
            handlingParams.push(item)
          })
        }
      })
    } else if (header && header.segmentHandlings) {
      header.segmentHandlings.map((item, i)=>{
        handlingOptions.push({
          key: i,
          value: item.key,
          text: item.key,
          params: item.params,
        })
        if (item.key==this.props.handlingType) {
          item.params.map(item=>{
            handlingParams.push(item)
          })
        }
      })
    }
    

    // // if (this.props.handlingParams) {
    //   this.props.handlingParams && this.props.handlingParams.map((item, idx)=>{
    //     handlingParams.push(item)
    //   })
    // // } else {
    //   // this.props.segment && this.props.segment.segmentHandlings && this.props.segment.segmentHandlings.map((item, idx)=>{
    //   //   if (this.props.handlingType==item.key) {
    //   //     handlingParams= item.params
    //   //   }
    //   // })
    // // }

    

    return (
      <div style={{padding: '1em 1em', ...style}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          {/* <Segment>
            <Form.Group widths='16'>
              <Form.Field width={8} />
              <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' />
            </Form.Group>
          </Segment> */}
          
          <Segment>
            <Form.Group widths='16'>
              <Field name='actionType' component={SelectField}
              width={4}
              options={actionOptions('ticket')}
              label='Action Type'
              placeholder='Action Type' />
              {(!this.props.actionType || this.props.actionType=='Handling') && <>
              <Field name='actionDuration' component={SelectField}
              options={durationOptions('ticket')}
              width={4}
              label='Duration'
              placeholder='Duration' />
              <Field name='actionStamp' component={DatePickerField}
              readOnly
              width={4}
              showTimeSelect={true}
              dateFormat={'HH:mm - dd / MMMM, yyyy'}
              label='Action Stamp'
              placeholder='Action Stamp' />
              <Field name='completeStamp' component={DatePickerField}
              readOnly
              width={4}
              showTimeSelect={true}
              dateFormat={'HH:mm - dd / MMMM, yyyy'}
              label='Complete Stamp'
              placeholder='Complete Stamp' />
              </>}

              {this.props.actionType=='Dispatch' &&
              <Field name='dispatches' component={SelectField}
              width={12}
              options={listWorkgroup}
              multiple
              label='Dispatches'
              placeholder='Dispatches' />}
              {this.props.actionType=='Dispose' &&
              <Field name='dispositions' component={SelectField}
              width={12}
              options={listWorkgroup}
              multiple
              label='Dispositions'
              placeholder='Dispositions' />}
              {this.props.actionType=='Escalate' &&
              <Field name='escalations' component={SelectField}
              width={12}
              options={listWorkgroup}
              multiple
              label='Escalations'
              placeholder='Escalations' />}
              {this.props.actionType=='Response' &&
              <Field name='actionChannels' component={SelectField}
              width={12}
              options={channelOptions}
              multiple
              label='Channels'
              placeholder='Channels' />}
            </Form.Group>
            {(this.props.actionType=='Dispatch' || this.props.actionType=='Dispose' || this.props.actionType=='Escalate' || this.props.actionType=='Response') &&
            <Form.Group widths='16'>
              <Field name='actionMessage' component={TextAreaField}
              width={16}
              label='Action Message'
              placeholder='Message / Information' />
            </Form.Group>}
          </Segment>

          {this.props.actionType=='Handling' && <>
          <Segment attached='top'>
            <Header as='h5'>
                <Icon name='paint brush' style={{fontSize: '1em'}} />
                <Header.Content>
                  Handling Activity 
                  {/* {this.props.handlingId} / {this.props.ticketId} / {this.props.clientId} */}
                </Header.Content>
              </Header>
            <Form.Group widths='16'>
              <Field name='handlingType' component={SelectField}
              width={8}
              options={handlingOptions}
              label='Handling Type'
              placeholder='Handling Type' />
              <Field name='handlingPerson' component={InputField}
              width={8}
              label='Handling Person'
              placeholder='Handling Person / Teammates' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='handlingActivity' component={TextAreaField}
              label='Activity'
              placeholder='Activity' />
            </Form.Group>
          </Segment>
          <Segment attached='bottom' style={{background: '#f7f6ea'}}>
            {this.props.handlingType && handlingParams && handlingParams.map((item, idx)=>{
              if (idx>0 && (idx+1)%4==0) {
                // console.log('SEGMENT PARAMS', this.props.segment.segmentHandlings);
                return(
                  <Form.Group key={idx}>
                    {
                      [3, 2, 1, 0].map(i=>{
                        if (handlingParams[idx-i]) {
                          return(
                            <Field key={idx-i} name={'handlingParams_'+ handlingParams[idx-i].key.replace(/ /g, '__')} 
                            component={handlingParams[idx-i].value=='Y/N' ? SelectField : InputField}
                            options={handlingParams[idx-i].value=='Y/N' ? yesnoOptions() : ratingOptions('quality')}
                            width={4}
                            label={handlingParams[idx-i].key}
                            placeholder='Assessment value here' />
                          )
                        } else {
                          return(
                            <Form.Field key={idx-i} width={4} />
                          )
                        }
                      })
                    }
                  </Form.Group>
                )
              } else if ((idx+1)==handlingParams.length) {
                return(
                  <Form.Group key={idx}>
                    {
                      [0, 1, 2, 3].map(i=>{
                        // console.log(handlingParams.length - (idx%4) + i, handlingParams.length)
                        if ((handlingParams.length - (idx%4) + i)<=handlingParams.length && handlingParams[handlingParams.length - (idx%4) + i - 1]) {
                          return(
                            <Field key={idx-i} name={'handlingParams_'+ handlingParams[idx-i].key.replace(/ /g, '__')}
                            component={handlingParams[idx-i].value=='Y/N' ? SelectField : InputField}
                            options={handlingParams[idx-i].value=='Y/N' ? yesnoOptions() : ratingOptions('quality')}
                            width={4}
                            label={handlingParams[idx-i].key}
                            placeholder='Assessment value here' />
                          )
                        } else {
                          return(
                            <Form.Field key={idx-i} width={4} />
                          )
                        }
                      })
                    }
                  </Form.Group>
                )
              }
            })}
          </Segment>
          </>}

          {(this.props.actionType=='Handling' || this.props.actionType=='Response') &&
          <Segment>
            <Header as='h5'>
              <Icon name='clipboard list' style={{fontSize: '1em'}} />
              <Header.Content>
                Ticket Remark / Result
              </Header.Content>
            </Header>
            
            <Divider hidden />
            <Form.Group widths='16'>
              <Field name='remark' component={SelectField} style={{fontWeight: 'bold'}}
              width={4}
              options={resultOptions('ticket')}
              label='Remark / Result'
              placeholder='Handling Remark / Result' />
              {/* {['Dispatch', 'Disposition', 'Escalation'].includes(this.props.remark) && 
              <Field name={this.props.remark=='Dispatch' ? this.props.remark.toLowerCase()+'es' : this.props.remark.toLowerCase()+'s'} component={SelectField}
              multiple
              width={12}
              options={listWorkgroup}
              label={this.props.remark + ' Contacts'}
              placeholder={this.props.remark + ' workgroup contacts'} />} */}
              
              <Field name='ticketDuration' component={InputField}
              readOnly
              width={4}
              // options={durationOptions('ticket')}
              label='Ticket Timer'
              placeholder='Ticket Timer' />
              <Field name='openStamp' component={DatePickerField}
              readOnly
              width={4}
              showTimeSelect={true}
              dateFormat={'HH:mm - dd / MMMM, yyyy'}
              label='Ticket Open'
              placeholder='Open Stamp' />
              <Field name='closeStamp' component={DatePickerField}
              readOnly
              width={4}
              showTimeSelect={true}
              dateFormat={'HH:mm - dd / MMMM, yyyy'}
              label='Ticket Current'
              placeholder='Current Stamp' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='reason' component={TextAreaField}
              width={16}
              label='Reason / Note'
              placeholder='Reason' />
            </Form.Group>
          </Segment>}

          {!this.props.ticketId && <>
            <MenuTabular panes={panes({})} style={{width: '100%', marginBottom: '-1.2em', marginTop: '1em'  }} renderActiveOnly={true} onTabChange={this.setMode.bind(this)}/>
            {this.state.tab==0 && <>
              <Segment>
                <Header as='h5'>
                  <Icon name='bookmark outline' style={{fontSize: '1em'}}/>
                  <Header.Content>Ticket Info</Header.Content>
                </Header>
                <Form.Group widths='16'>
                  <Field name='ticketRemark' component={SelectField}
                  readOnly
                  width={8}
                  options={resultOptions('ticket')}
                  label='Ticket Status / Remark'
                  placeholder='Ticket Status / Remark' />
                </Form.Group>
                <Form.Group widths='16'>
                  <Field name='ticketNo' component={InputField}
                  readOnly
                  width={4}
                  label='Ticket No'
                  placeholder='Ticket No' />
                  <Field name='ticketPriority' component={SelectField}
                  readOnly
                  width={4}
                  options={priorityOptions()}
                  label='Priority'
                  placeholder='Priority' />
                  <Field name='openStamp' component={DatePickerField}
                  readOnly
                  width={4}
                  showTimeSelect={true}
                  dateFormat={'HH:mm - dd / MMMM, yyyy'}
                  label='Ticket Stamp'
                  placeholder='Open Stamp' />
                  <Field name='recentStamp' component={DatePickerField}
                  readOnly
                  width={4}
                  showTimeSelect={true}
                  dateFormat={'HH:mm - dd / MMMM, yyyy'}
                  label='Recent Action'
                  placeholder='Recent Action' />
                </Form.Group>
              </Segment>
              
              <Segment attached='top'>
                <Form.Group widths='16'>
                  <Field name='ticketSubject' component={InputField}
                  readOnly
                  width={8}
                  label='Subject / Title'
                  placeholder='Subject / Title' />
                  <Field name='ticketSegment' component={InputField}
                  readOnly
                  width={8}
                  // options={listSegment}
                  label='Segmentation'
                  placeholder='Segmentation' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='ticketInformation' component={TextAreaField}
                  readOnly
                  label='Information'
                  placeholder='Information' />
                </Form.Group>
              </Segment>
              <Segment attached='bottom'>
                {this.props.segmentParams && this.props.segmentParams.length>0 && <>
                  <Header as='h4'>
                    <Icon name='edit outline' style={{fontSize: '1em' }} />
                    <Header.Content> Ticket Assessment</Header.Content>
                  </Header>
                </>}
                {this.props.segmentParams && this.props.segmentParams.map((item, idx)=>{
                  if (idx>0 && (idx+1)%4==0) {
                    // console.log('SEGMENT PARAMS', this.props.segmentParams);

                    return(
                      <Form.Group key={idx}>
                        {
                          [3, 2, 1, 0].map(i=>{
                            if (this.props.segmentParams[idx-i]) {
                              return(
                                <Field key={idx-i} name={'segmentParams_'+ this.props.segmentParams[idx-i].key.replace(/ /g, '__')}
                                component={this.props.segmentParams[idx-i].value=='Y/N' ? SelectField : InputField}
                                options={this.props.segmentParams[idx-i].value=='Y/N' ? yesnoOptions() : ratingOptions('quality')}
                                readOnly
                                width={4}
                                label={this.props.segmentParams[idx-i].key}
                                placeholder='Assessment value here' />
                              )
                            } else {
                              return(
                                <Form.Field key={idx-i} width={4} />
                              )
                            }
                          })
                        }
                      </Form.Group>
                    )
                  } else if ((idx+1)==this.props.segmentParams.length) {
                    return(
                      <Form.Group key={idx}>
                        {
                          [0, 1, 2, 3].map(i=>{
                            // console.log(this.props.segmentParams.length - (idx%4) + i, this.props.segmentParams.length)
                            if ((this.props.segmentParams.length - (idx%4) + i)<=this.props.segmentParams.length && this.props.segmentParams[this.props.segmentParams.length - (idx%4) + i - 1]) {
                              return(
                                <Field key={idx-i} name={'segmentParams_'+ this.props.segmentParams[idx-i].key.replace(/ /g, '__')} 
                                component={this.props.segmentParams[idx-i].value=='Y/N' ? SelectField : InputField}
                                options={this.props.segmentParams[idx-i].value=='Y/N' ? yesnoOptions() : ratingOptions('quality')}
                                readOnly
                                width={4}
                                label={item.key}
                                placeholder='Assessment value here' />
                              )
                            } else {
                              return(
                                <Form.Field key={idx-i} width={4} />
                              )
                            }
                          })
                        }
                      </Form.Group>
                    )
                  }
                })}
              </Segment>
            </>}
            {this.state.tab==1 && <>
              <Segment attached='top'>
                <Header as='h5'>
                  <Icon name='wizard' style={{fontSize: '1em'}}/>
                  <Header.Content>Last Action</Header.Content>
                </Header>
                <Table unstackable selectable columns='16' celled striped basic='very'>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>#</Table.HeaderCell>
                      <Table.HeaderCell width='8'>
                        <Header as='h5' color='blue'>
                          ACTION
                        </Header>
                      </Table.HeaderCell>
                      <Table.HeaderCell width='4'>
                        <Header as='h5' color='blue'>
                          USER / HANDLER
                        </Header>
                      </Table.HeaderCell>
                      <Table.HeaderCell width='4'>
                        <Header as='h5' color='blue'>
                          RESULT
                        </Header>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    { data && data.ticketHandlings ? data.ticketHandlings.map((item,i)=>{
                        return(
                          <Table.Row key={++i}>
                            <Table.Cell>{i}</Table.Cell>
                            <Table.Cell>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader><b>{item.actionType}</b> / {item.handlingType || 'Information'}</Header.Subheader>
                                  {item.actionType=='Handling' && <>{item.handlingPerson}, {item.handlingActivity}</>}
                                  {item.actionType!='Handling' && <>{item.actionMessage}</>}
                                </Header.Content>
                              </Header>
                            </Table.Cell>
                            <Table.Cell>
                              {item.actionType=='Handling' &&
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>{item.actionStamp!=null && format(parse(item.actionStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm, dd-MMM / yyyy')}</Header.Subheader>
                                  {item.handlingPerson}
                                </Header.Content>
                              </Header>}
                            </Table.Cell>
                            <Table.Cell>
                              {(item.actionType=='Handling' || item.actionType=='Response') &&
                              <Header as='h5'>
                                <div style={{background: (item.status=='1' && '#84dbff') || (item.status=='0' && '#ffb3b3') || '#ddd', textAlign: 'right', display: 'inline-block', width: '4em', padding: '.5em', marginRight: '.5em'}}>{item.progress || '-'} %</div>
                                <Header.Content>
                                  <b>{item.remark}</b>
                                  <Header.Subheader>{(item.reason && item.reason.length>30 ? item.reason.substring(0,20)+' ...' : item.reason) || '-'}</Header.Subheader>
                                </Header.Content>
                              </Header>}
                            </Table.Cell>
                          </Table.Row>
                        )
                      }) : <Table.Row><Table.Cell colSpan={3}></Table.Cell></Table.Row>
                    }
                  </Table.Body>
                </Table>
              </Segment>
            </>}
            {this.state.tab==2 && <>
              <Segment>
                <Header as='h5'>
                  <Icon name='user outline' style={{fontSize: '1em'}}/>
                  <Header.Content>Contact</Header.Content>
                </Header>
                <Form.Group widths='16'>
                  <Field name='custName' component={InputField}
                  readOnly
                  width={8}
                  label='Name'
                  placeholder='Full Name' />
                  <Field name='custMobile' component={InputField}
                  readOnly
                  width={4}
                  label='Mobile'
                  placeholder='Handphone' />
                  <Field name='custPhone' component={InputField}
                  readOnly
                  width={4}
                  label='Phone'
                  placeholder='Home / Office Phone' />
                </Form.Group>
                <Form.Group widths='16'>
                  <Field name='custEmail' component={InputField}
                  readOnly
                  width={4}
                  label='Email'
                  placeholder='Email' />
                  <Field name='custTwitter' component={InputField}
                  readOnly
                  width={4}
                  label='Twitter'
                  placeholder='Twitter' />
                  <Field name='custFacebook' component={InputField}
                  readOnly
                  width={4}
                  label='Facebook'
                  placeholder='Facebook' />
                  <Field name='custInstagram' component={InputField}
                  readOnly
                  width={4}
                  label='Instagram'
                  placeholder='Instagram' />
                </Form.Group>
              </Segment>
              
              <Segment>
                <Header as='h5'>
                  <Icon name='building outline' style={{fontSize: '1em'}}/>
                  <Header.Content>Corporate</Header.Content>
                </Header>
                <Form.Group widths='16'>
                  <Field name='company' component={InputField}
                  readOnly
                  width={8}
                  label='Name'
                  placeholder='Company' />
                  <Field name='brand' component={InputField}
                  readOnly
                  width={4}
                  label='Brand / Code'
                  placeholder='Brand / Code' />
                  <Field name='business' component={InputField}
                  readOnly
                  width={4}
                  label='Business Stream'
                  placeholder='Business Stream' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='address' component={InputField}
                  readOnly
                  label='Address'
                  placeholder='Address' />
                </Form.Group>
                <Form.Group widths='16'>
                  <Field name='city' component={InputField}
                  readOnly
                  width={4}
                  placeholder='City' />
                  <Field name='province' component={InputField}
                  readOnly
                  width={4}
                  placeholder='Province' />
                  <Field name='country' component={InputField}
                  readOnly
                  width={4}
                  placeholder='Country' />
                  <Field name='zipcode' component={InputField}
                  readOnly
                  width={4}
                  placeholder='Zipcode' />
                </Form.Group>
              </Segment>
            </>}
          </>}
          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Handling' : 'Insert Handling') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.handlingStore.getHandling!=undefined && state.handlingStore.getHandling.progress==1 ? state.handlingStore.getHandling.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      ticketDuration: selector(state, 'ticketDuration'),
      openStamp: selector(state, 'openStamp'),
      closeStamp: selector(state, 'closeStamp'),
      
      actionType: selector(state, 'actionType'),

      handlingType: selector(state, 'handlingType'),
      handlingParams: selector(state, 'handlingParams'),
      
      actionDuration: selector(state, 'actionDuration'),
      actionStamp: selector(state, 'actionStamp'),
      completeStamp: selector(state, 'completeStamp'),

      remark: selector(state, 'remark'),

      segment: selector(state, 'segment'),
      segmentParams: selector(state, 'segmentParams'),

      dispatches: selector(state, 'dispatches'),
      dispositions: selector(state, 'dispositions'),
      escalations: selector(state, 'escalations'),
    }),
    { load: getHandling }
  )
  (reduxForm({
    form: 'HandlingForm',	// a unique identifier for this form
  })(HandlingForm)
  )
)
