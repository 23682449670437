import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'

import { channelOptions, messagingOptions }  from '../common/StaticMasterData'
import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField, DatePickerField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { browseMessaging } from "../../actions/stream/messagingAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    messagingStore: state.messagingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseMessaging:(objParam) => dispatch(browseMessaging(objParam)),
  }
}


class MessagingSearch extends Component {
  constructor(props) {
    super(props)
    this.state= {
      searchMode: true,
    }
  }

  submitForm(values) {
    const { search }= this.state
    this.props.browseMessaging({search: {...values, ...search}})
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeDate(key, v) {
    const { search }= (this.props.messagingStore.browseMessaging!==undefined ? this.props.messagingStore.browseMessaging : {})
    var values= []
    values[key]= v
    this.props.browseMessaging({search: {...search, ...values}})
  }

  onChangeInput(e, v) {
    const { search }= (this.props.messagingStore.browseMessaging!==undefined ? this.props.messagingStore.browseMessaging : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.props.browseMessaging({search: {...search, keyword: v}})
      }
    }, 500)
  }

  onChangeSelect(k, e, v) {
    const { search }= (this.props.messagingStore.browseMessaging!==undefined ? this.props.messagingStore.browseMessaging : {})
    if (k=='status') {
      this.props.browseMessaging({search: {...search, status: v}})
    } else {
      this.props.browseMessaging({search: {...search, channelType: v}})
    }
  }
  
  render() {
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode }=this.props
    
    return (
      <div style={{position: 'static', width: '100%', zIndex: '1000'}}>
        <div style={{padding: '1em 1em', background: 'rgba(230, 230, 230, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
          <div style={{display: 'flex', padingTop: '1em'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' style={{marginTop: '.25em'}}>
                <Icon name='comment alternate outline' style={{fontSize: '1em'}}/>
                <Header.Content>
                  Messaging / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
                </Header.Content>
              </Header>
            </div>
            <div>
              {mode !=1 &&
              <Button size='mini' color='blue' onClick={this.props.setMode.bind(this,1)} icon style={{padding: '.5em'}}><Icon name='arrow left' /></Button>}
              {/* {mode !=2 && mode !=3 &&
              <Button size='mini' color='red' onClick={this.props.setMode.bind(this,3)} icon style={{padding: '.5em'}}><Icon name='plus' /></Button>} */}
              {mode ==1 &&
              <Button size='mini' color='grey' onClick={this.showSearch.bind(this)} icon style={{padding: '.5em'}}><Icon name={searchMode==true? 'angle up' : 'angle down'} /></Button>}
            </div>
          </div>

          {mode==1 && searchMode && 
          <div style={{margin: '1em 0 0 0', border: '0px solid rgba(100,100,100,.2)'}}>
            <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Form.Group widths='16'>
                    <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)}
                    width={8} 
                    placeholder= 'Search message ... <ENTER>' />
                    <Field name='transactDate' component={DatePickerField} onChange= {this.onChangeDate.bind(this, 'transactDate')}
                    width={4} 
                    type='date'
                    placeholder= 'Trx. Date' />
                    {/* <Field name='channelType' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'channelType')}
                    options={channelOptions('messaging')}
                    width={3} 
                    placeholder= 'Channel Type' /> */}
                    <Field name='status' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'status')}
                    options={messagingOptions}
                    width={4} 
                    placeholder= 'Status' />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.messagingStore.browseMessaging!=undefined && state.messagingStore.browseMessaging.progress==1 ? state.messagingStore.browseMessaging.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'MessagingSearch',	// a unique identifier for this form
  })(MessagingSearch)
  )
)