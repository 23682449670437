import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ClientBrowse from '../../libraries/halo/ClientBrowse'
import ClientForm from '../../libraries/halo/ClientForm'
import ClientSearch from '../../libraries/halo/ClientSearch'


class ClientBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        clientId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, clientId: id})
    }

    render() {
      const {mode, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
            <ClientSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <ClientBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
              {mode==2 && <ClientForm mode={mode} clientId={clientId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <ClientForm mode={mode} clientId={0} setMode={this.setMode.bind(this)} />}
            </div>
        </Container>
      )
    }
}

export default ClientBody
