import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'

import ModalContainer from '../../../libraries/common/ModalContainer';
import CredentialWidget from '../../../libraries/widget/CredentialWidget';

import ProfileBody from './ProfileBody'
import AccountBody from './AccountBody'
import MessageBody from './MessageBody'
import SettingBody from './SettingBody'
import AudittrailBody from '../system/AudittrailBody';

import TopupBody from './TopupBody';
import OnboardingBody from './OnboardingBody';



import RealmDashboardBody from './RealmDashboardBody';
import RealmOutboundBody from './RealmOutboundBody';
import RealmInboundBody from './RealmInboundBody';
import RealmInappBody from './RealmInappBody';
import RealmOfficeBody from './RealmOfficeBody';
import RealmMiddlewareBody from './RealmMiddlewareBody';
import RealmAgentModeBody from './RealmAgentModeBody';


import { connect } from "react-redux"
import { checkToken } from "../../../actions/auth/authAction"
import OmniwidgetVideo from '../../../libraries/omniwidget/OmniwidgetVideo';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),
  }
}

class RealmBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
      mode: 'dashboard',
      
      onboarding: false,
      topupalert: false,
    }
  }

  componentDidMount() {
    this.props.checkToken();
  }

  componentDidUpdate() {
    const { code, data }= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken : {}
    data && code=='00' && data.isConfigured=='0' && this.state.onboarding==false && this.setState({
      onboarding: true,
    })

    data && code=='00' && data.billingType=='prepaid' && parseFloat(data.depositCredit)<=0 && this.state.topupalert==false && this.setState({
      topupalert: true,
    })
  }

  render() {
    const { data }= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken : {}
    const { onboarding, topupalert }= this.state
    const { mode, module, screenWidth, screenHeight, mini, modal, params }= this.props
    
    // console.log('CHECKTOKEN', code, data)
    return (
      <div style={{marginTop: '2.8em'}}>
        {module=='home' && <RealmDashboardBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} mini={mini} module={module} setModule={this.props.setModule} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
        {module=='outbound' && <RealmOutboundBody screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow} mode={mode} mini={mini} module={module} setModule={this.props.setModule} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
        {module=='inbound' && <RealmInboundBody screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow} mode={mode} mini={mini} module={module} setModule={this.props.setModule} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
        {module=='inapp' && <RealmInappBody screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow} mode={mode} mini={mini} module={module} setModule={this.props.setModule} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
        {module=='office' && <RealmOfficeBody screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow} mode={mode} mini={mini} module={module} setModule={this.props.setModule} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
        {module=='middleware' && <RealmMiddlewareBody screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow} mode={mode} mini={mini} module={module} setModule={this.props.setModule} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
        {module=='agent' && <RealmAgentModeBody screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow} mode={mode} mini={mini} module={module} setModule={this.props.setModule} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}

        {onboarding && <OnboardingBody dialog />}
        {!onboarding && topupalert && <ModalContainer size='small' content={<TopupBody screenWidth={screenWidth} />} closeModal={this.props.closeModal.bind(this)} /> }

        {modal==='audit' && <ModalContainer size='large' content={<AudittrailBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal.bind(this)} />} closeModal={this.props.closeModal.bind(this)} /> }

        {modal==='account' && <ModalContainer size='small' content={<AccountBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal.bind(this)} />} closeModal={this.props.closeModal.bind(this)} /> }
        {modal==='setting' && <ModalContainer size='large' content={<SettingBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal.bind(this)} />} closeModal={this.props.closeModal.bind(this)} /> }
        {modal==='profile' && <ModalContainer size='small' content={<ProfileBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal.bind(this)} />} closeModal={this.props.closeModal.bind(this)} /> }
        {modal==='message' && <ModalContainer size='small' content={<MessageBody screenWidth={screenWidth} screenHeight={screenHeight} />} closeModal={this.props.closeModal.bind(this)} /> }
        
        <div style={{padding: '1em 7.5em', top: (screenHeight-70), position: 'fixed', background: 'rgb(246, 245, 244)', borderTop: '1px solid #d5d5d5', width: '100%'}}>
          <CredentialWidget />
        </div>

        {/* <div id='omniwidgetVideo' style={{_display: 'none', position: 'fixed', left: 20, bottom: 70, zIndex: 9999, background: '#ff0'}}>
          <OmniwidgetVideo width={222} height={180} />
        </div> */}
      </div>
    )
  }
}

// export default RealmBody
export default connect(mapStateToProps, mapDispatchToProps)(RealmBody)