import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import TelesalesBrowse from '../../libraries/stream/TelesalesBrowse'
import TelesalesForm from '../../libraries/stream/TelesalesForm'
import TelesalesSearch from '../../libraries/stream/TelesalesSearch'


class TelesaleBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        transactId: 0,
      };
    }

    setMode(mode, id, campaignId, clientId) {
      this.setState({mode: mode, transactId: id, campaignId: campaignId, clientId: clientId})
    }

    render() {
      const {mode, transactId, campaignId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div>
            <TelesalesSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <TelesalesBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}/>}
              {mode==2 && <TelesalesForm mode={mode} transactId={transactId} campaignId={campaignId} clientId={clientId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <TelesalesForm mode={mode} transactId={0} setMode={this.setMode.bind(this)} />}
            </div>
          </div>
        </Container>
      )
    }
}

export default TelesaleBody
