import React from 'react'

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';
import Whatsapp from "./Whatsapp"

class WhatsappContainer extends React.Component {
    
  render() {
    return(
      <WebSocketProvider>
        <Whatsapp />
      </WebSocketProvider>
    )
  }
} 
export default WhatsappContainer
