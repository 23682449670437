import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function perfReset(objParam) {
  return {
      type: 'PERF_RESET',
      payload: objParam
  }
}

export function perfCampaign(objParam) {
  return {
      type: 'CAMPAIGN_PERF',
      payload:
        axios.post(
          urlAPI + 'performance/campaign',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function perfRecent(objParam) {
  return {
      type: 'RECENT_PERF',
      payload:
        axios.post(
          urlAPI + 'performance/recent',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function perfAgent(objParam) {
  return {
      type: 'AGENT_PERF',
      payload:
        axios.post(
          urlAPI + 'performance/agent',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function perfProduct(objParam) {
  return {
      type: 'PRODUCT_PERF',
      payload:
        axios.post(
          urlAPI + 'performance/product',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function excelAgent(objParam) {
  return {
      type: 'AGENT_EXCEL',
      payload:
        axios.post(
          urlAPI + 'performance/excelagent',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
