import React, { Component } from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Label, ButtonGroup } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';
import SignoutConfirmation from '../../libraries/agent/SignoutConfirmation';
import CampaignConfirmation from '../../libraries/agent/CampaignConfirmation';


import { Link } from 'react-router-dom'
import {withRouter} from "react-router-dom"

import dialtone from '../../sound/tone.mp3'

import { connect } from "react-redux"
import { signOut } from "../../actions/auth/authAction"
import { statAssignment } from "../../actions/halo/statisticAction"

import { socketStatus, socketAgentStatus, socketAgentStatistic, socketAgentNotification } from "../../actions/socket/socketAction"

const dial= new Audio(dialtone);
class DialTone {
  play() {
    console.log('!!! PLAY DIAL TONE')
    dial.loop= true
    // dial.muted= false;
    dial.play();  
  }
  
  stop() {
    dial.pause();
  }

  mute() {
    dial.muted= true;
  }
}

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
    socketStore: state.socketStore,
    statisticStore: state.statisticStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut:(objParam) => dispatch(signOut(objParam)),

    socketStatus:(objParam) => dispatch(socketStatus(objParam)),
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),
    socketAgentStatistic:(objParam) => dispatch(socketAgentStatistic(objParam)),
    socketAgentNotification:(objParam) => dispatch(socketAgentNotification(objParam)),
    
    statAssignment:(objParam) => dispatch(statAssignment(objParam)),
  }
}

const stylePopup = {
  borderRadius: '0',
  opacity: 1,
  padding: '.5em .8em',
  fontSize: '1em',
  background: '#000',
  color: '#fff'
}
const styleButton={
  margin: '.3em 0em',
  border: 'none',
}

class AgentHeader extends Component {
  constructor(props) {
    super(props);
    this.dial= new DialTone();
    this.state={
      signoutModal: false,
      signoutReasong: null,
      socketStatus: {
      },
    }
  }

  onUnload = e => { // the method that will be used for both add and remove event
    const agentStatus= (this.props.socketStore.socketAgentStatus!==undefined ? this.props.socketStore.socketAgentStatus.data : undefined)
    const { status }= (agentStatus && agentStatus.payload.data) || {status: 'connect'}
    if (status=='callback' || status=='online' || status=='predialed' || status=='locked' && 'red') {
      e.preventDefault();
      e.returnValue= '';
    }
  }

  componentDidMount() {
    const tokenAuth= localStorage.getItem('tokenAuth');
    if (tokenAuth==undefined || tokenAuth=='') {
      setTimeout(()=>{
        this.props.history.push('/signin');
      }, 1000)
    }

    window.addEventListener("beforeunload", this.onUnload);
  }

  componentDidUpdate(prevProps) {
    if (localStorage.getItem('tokenAuth')=='') {
      setTimeout(()=>{
        this.props.history.push('/signin');
      }, 1000)
    }

    const socketStatus= (this.props.socketStore.socketStatus!==undefined ? this.props.socketStore.socketStatus.data : undefined)
    if (socketStatus && !socketStatus.isConnected && (!this.state.socketStatus || socketStatus.isConnected!=this.state.socketStatus.isConnected)) {
      this.setState({
        socketStatus: socketStatus,
      })
    }

    const agentStatusPrev= (prevProps.socketStore.socketAgentStatus!==undefined ? prevProps.socketStore.socketAgentStatus.data.payload.data : undefined)
    const agentStatus= (this.props.socketStore.socketAgentStatus!==undefined ? this.props.socketStore.socketAgentStatus.data.payload.data : undefined)

    if (agentStatus && agentStatus.status!='occupied' && agentStatus.dialings && agentStatus.dialings.length>0 && agentStatus.performance && agentStatus.performance.totDialing>0) {
      if (!agentStatusPrev || (agentStatusPrev && agentStatusPrev.status=='occupied') || (agentStatusPrev && agentStatusPrev.performance && agentStatusPrev.performance.totDialing==0)) {
        this.dial.play();
      }
    } else if ((agentStatus && agentStatus.status=='occupied') || (agentStatusPrev && agentStatusPrev.performance.totDialing>0)) {
      this.dial.stop();
    }

    const statAssignmentPrev= (prevProps.statisticStore.statAssignment ? prevProps.statisticStore.statAssignment.data : null)
    const statAssignmentCurr= (this.props.statisticStore.statAssignment ? this.props.statisticStore.statAssignment.data : null)
    
    if ((!statAssignmentPrev && (statAssignmentCurr && statAssignmentCurr.totLocked=='0' && statAssignmentCurr.totFloating=='0')) || 
      (statAssignmentPrev && (statAssignmentPrev.totLocked!=0 || statAssignmentPrev.totFloating!=0) && statAssignmentCurr && statAssignmentCurr.totLocked==0 && statAssignmentCurr.totFloating==0)) {
      this.setState({
        emptyModal: true,
      })
    }
  }
  
  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  openDrawer() {
    this.props.openDrawer()
  }

  openWindow(value) {
    this.props.openWindow(value)
  }

  closeModal(refreshBrowser) {
    this.setState({
      emptyModal: false,
      signoutModal: false,
      signoutReason: null,
    })
    this.props.closeModal();
    if (refreshBrowser) {
      window.location.reload();
    } 
  }

  doSignOut(values) {
    this.props.signOut(values)
    setTimeout(()=>{
      this.props.history.push('/signin');
    }, 1000)
  }

  onChangeSelect(e, v) {
    // console.log(v.value)
    this.setState({
      signoutReason: v.value,
    })
  }
  
  render() {
    const { screenWidth,  screenHeight, mini, mode, module }= this.props
    const socketStatus= (this.props.socketStore.socketStatus!==undefined ? this.props.socketStore.socketStatus.data : undefined)
    const agentStatus= (this.props.socketStore.socketAgentStatus!==undefined ? this.props.socketStore.socketAgentStatus.data.payload.data : undefined)
    const agentStatistic= (this.props.socketStore.socketAgentStatistic!==undefined ? this.props.socketStore.socketAgentStatistic.data : undefined)
    
    const { status }= socketStatus && socketStatus.isConnected && agentStatus && agentStatus.status ? {status: agentStatus.status || 'offline'} : agentStatus && agentStatus.status ? {status: 'NO ACCESS'} : {status: 'SERVER UNREACHABLE'};
    // console.log('!!! AGENT HEADER', agentStatus)
    // console.log('!!! AGENT HEADER', socketStatus)
    return (
      <div>
        {agentStatus && agentStatus.status!='occupied' && agentStatus.dialings && agentStatus.dialings.length>0 && agentStatus.performance && agentStatus.performance.totDialing>0 && <div style={{top: screenHeight/2 - 100, left: screenWidth/2 - 100, position: 'fixed', padding: '1.5em 0', background: 'rgba(217, 184, 0, 52%)', width: 200, zIndex: 99999, borderRadius: '4em', textAlign: 'center', border: '2px solid #ffd900'}}>
          <Label size='small' color='red' style={{fontSize: '1.2em', borderRadius: '3em', padding: '.5em 1em .6em .7em'}}><Icon name='circle notch' loading style={{margin: '0 .3em 0  0'}} /><div style={{display: 'inline-block', minWidth: '1.3em', borderRadius: '2em', fontSize: '1.2em', background: '#000', padding: '.1em .2em .2em .2em'}}>{agentStatus.performance.totDialing}</div> Dialing</Label>
        </div>}
        <Menu fixed='top'  style={{padding: '0', border: 'none', boxShadow: 'none', background : 'none'}}>
          <div style={{display: 'flex', width: '100%', background : '#fcbd28', minHeight: '3.3em'}}>
            <div style={{padding: '.3em 0', textAlign: 'center'}}>
              <Label as='a' style={{background: 'none', padding: '.2 .75em', border: '0px solid rgba(100, 100, 100, .3)'}} onClick={this.openDrawer.bind(this)}>
                <Icon name='bars' style={{fontSize: '1.75em', margin: '0'}} color={mini==true ? 'black' : 'red'} />
              </Label>
            </div>
            <div style={{padding: '1em 0 0 1em'}}>
              <Header as='h5' color='black'>
                <Header.Content>
                  <b>Agent</b> / 2.3.01
                </Header.Content>
              </Header>
            </div>
            <div style={{padding: '.5em 0 0 1em'}}>
              <Label size='large' style={{padding: '.5em 0 .5em .5em'}} 
              color={
                (status=='connect' && 'blue') ||
                (status=='callback' && 'red') ||
                (status=='online' && 'blue') ||
                (status=='occupied' && 'red') ||
                (status=='predialed' && 'orange') ||
                (status=='locked' && 'red') || 'red'} >
                  <div style={{display: 'flex'}}>
                    <div>
                    <Icon name={
                    (status=='connect' && 'plug') ||
                    (status=='callback' && 'phone volume') ||
                    (status=='online' && 'microphone') ||
                    (status=='occupied' && 'phone') ||
                    (status=='predialed' && 'grid layout') ||
                    (status=='locked' && 'lock') || 'warning'
                  } style={{fontSize: '1em', paddingRight: '.3em'}}/>
                    </div>
                    <div style={{flex: '1'}}>
                      <span style={{marginRight: '.5em', textTransform: 'capitalize'}}>{(status=='connect' && 'connected') || status}</span> 
                    </div>
                    {agentStatus && 
                    <div style={{flex: '1'}}>
                      <span style={{background: 'rgba(50, 50, 50, .2)', padding: '.5em .5em', textTransform: 'capitalize'}}>{agentStatus.mode}</span>
                    </div>}
                  </div>
              </Label>
            </div>
            <div style={{flex: '1'}}>

            </div>
            <div style={{flex: '2', marginRight: '1em'}}>
              <div style={{display: 'flex'}}>
                {(mini || true) && <>
                  <div style={{padding: '.3em 0 0 0', flex: '3'}}>
                    <Divider horizontal style={{margin: '.1em 0'}}>
                        <Button.Group>
                          <Popup style={stylePopup} content='Omnichannel Conversation.'  position= 'bottom center'
                          trigger={
                            <Button color={(module=='inbound' && 'black') || null}style={{width: '7em'}} onClick={this.props.setModule.bind(this, 'inbound')}>Inbound</Button>
                          }
                          inverted />
                          <Button.Or></Button.Or>
                          <Popup style={stylePopup} content='Outbound Conversation.'  position= 'bottom center'
                          trigger={
                            <Button color={(module=='outbound' && 'black') || null} onClick={this.props.setModule.bind(this, 'outbound')} style={{width: '7em'}}>Outbound</Button>
                          }
                          inverted />
                        </Button.Group>
                    </Divider>
                  </div>
                  <div style={{padding: '.3em 0 0 0', flex: '1'}}>
                    <Divider horizontal style={{margin: '.1em 0'}}>
                      <Popup style={stylePopup} header='Dashboard' content='Your realtime performance monitoring.'  position= 'bottom center'
                      trigger={
                        <Icon color={mode=='statistic' ? 'red' : 'yellow'} name='chart bar' circular inverted style={{fontSize: '1.3em', cursor: 'pointer'}} onClick={this.props.openWindow.bind(this,'statistic')}/>
                      }
                      inverted />
                    </Divider>
                  </div>
                  <div style={{padding: '.3em 0 0 0', textAlign: 'right', flex: '1'}}>
                    <Divider horizontal style={{margin: '.1em 0'}}>
                      <Popup style={stylePopup} header='Target' content='List contacts of campaigns target which available.'  position= 'bottom center'
                      trigger={
                        <Icon color={mode=='target' ? 'red' : 'yellow'} name='list alternate outline' circular inverted style={{fontSize: '1.3em', cursor: 'pointer'}} onClick={this.props.openWindow.bind(this,'target')}/>
                      }
                      inverted />
                    </Divider>
                  </div>
                  <div style={{padding: '.3em 0 0 0', textAlign: 'right', flex: '1'}}>
                    <Divider horizontal style={{margin: '.1em 0'}}>
                      <Popup style={stylePopup} header='Logger' content='Agent activity and performance log.'  position= 'bottom center'
                      trigger={
                        <Icon color={mode=='logger' ? 'red' : 'yellow'} name='chart line' circular inverted style={{fontSize: '1.3em', cursor: 'pointer'}} onClick={this.props.openWindow.bind(this,'logger')}/>
                      }
                      inverted />
                    </Divider>
                  </div>
                </>}
              </div>
            </div>
            <div style={{paddingTop: '.25em', marginRight: '1em', textAlign: 'left'}}>
            
              <Popup style={stylePopup} position= 'bottom right'
                trigger={<Button onClick={this.props.openModal.bind(this,'profile')} basic={this.props.modal!='profile'} color={this.props.modal=='profile'? 'yellow' : null} style={styleButton} icon><Icon name='user' style={{color: this.props.modal=='profile' ? '#000' : '#fff'}}/></Button>}
                content='My Account'
                inverted
                on={['hover']}
              />
              <Popup style={stylePopup} position= 'bottom right'
                trigger={<Button onClick={this.props.openModal.bind(this,'message')} basic={this.props.modal!='message'} color={this.props.modal=='message'? 'yellow' : null} style={styleButton} icon><Icon name='bell' style={{color: this.props.modal=='message' ? '#000' : '#fff'}} /> <span style={{color: this.props.modal=='message' ? '#000' : '#fff'}}></span></Button>}
                content='Notification'
                inverted
                on={['hover']}
              />
              {/* <Popup style={stylePopup}
                trigger={<Button onClick={this.openWindow.bind(this,'setting')} basic={this.props.mode!='setting'} color={this.props.mode=='setting' ? 'yellow' : null} style={styleButton} icon><Icon name='setting' style={{color: this.props.mode=='setting' ? '#000' : '#fff'}} /></Button>}
                content='Setting'
                inverted
                on={['hover']}
              /> */}
              {/* <Popup style={stylePopup}
                trigger={<Button onClick={this.openWindow.bind(this,'provider')} basic={this.props.mode!='provider'} color={this.props.mode=='provider'? 'yellow' : null} style={styleButton} icon><Icon name='heart outline'/></Button>}
                content='Provider'
                inverted
                on={['hover']}
              />
              <Popup style={stylePopup}
                trigger={<Button onClick={this.openWindow.bind(this,'api')} basic={this.props.mode!='api'} color={this.props.mode=='api'? 'yellow' : null} style={styleButton} icon><Icon name='rocket' /></Button>}
                content='API'
                inverted
                on={['hover']}
              /> */}
              <Popup style={stylePopup} position= 'bottom right'
                trigger={
                  <Button 
                  // onClick={status=='connect' ? ()=>{this.doSignOut()} : null}  
                  disabled={(status!='connect' && status!='NO ACCESS' && status!='SERVER UNREACHABLE')}
                  onClick={(status=='connect' || status=='NO ACCESS' || status=='SERVER UNREACHABLE') ? ()=>this.setState({signoutModal: true}) : null} 
                  color={this.props.mode=='signout'? 'red' : 'brown'} style={{...styleButton, marginLeft: '3em'}} icon><Icon name='sign-out' style={{color: this.props.mode=='signout' ? '#fff' : '#fff'}} /></Button>
                }
                content={(status!='connect' && status!='NO ACCESS' && status!='SERVER UNREACHABLE') ? 'Not allowed to signout while on active session' : 'Click to Signout'}
                inverted
                on={['hover']}
              />
              {/* <Button onClick={()=>this.doSignOut()} style={{marginRight: '1.15em'}} size='tiny' color='red'>Sign Out</Button> */}
            </div>
          </div>
        </Menu>
        {this.state.signoutModal && <ModalContainer size='mini' content={
          <SignoutConfirmation onClick={()=>this.doSignOut({actionReason: this.state.signoutReason})} onChangeSelect={this.onChangeSelect.bind(this)} state={this.state}/>
        } closeModal={this.closeModal.bind(this)} /> }

        {this.state.socketStatus && this.state.socketStatus.isConnected==false && <ModalContainer size='small' 
        title='Network Error' 
        content={
        <Header as='h2' style={{margin: '1.5em .5em'}} color='red'>
          <Icon name='bug' style={{fontSize: '2em'}} inverted circular color='yellow' />
          <Header.Content>
            <b>Disconnected !</b>
            <Header.Subheader style={{color: '#333'}}>{<><>Your device is not connected, problem has been detected!, browser will be refreshed.</><b>{this.state.socketStatus.reason ? ' Reason=> '+ this.state.socketStatus.reason : ''}</b></>}</Header.Subheader>
          </Header.Content>
        </Header>}  closeModal={this.closeModal.bind(this, true)} /> }

        {module=='outbound' && this.state.emptyModal && <ModalContainer size='mini' content={
          <CampaignConfirmation onClick={()=>this.doSignOut({actionReason: this.state.signoutReason})} onChangeSelect={this.onChangeSelect.bind(this)} state={this.state}/>
        } closeModal={this.closeModal.bind(this)} /> }
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AgentHeader))