import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'

import { reportOptions }  from '../common/StaticMasterData'
import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField, DatePickerField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { browseReport } from "../../actions/stream/reportAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    reportStore: state.reportStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseReport:(objParam) => dispatch(browseReport(objParam)),
  }
}


class ReportingSearch extends Component {
  constructor(props) {
    super(props)
    this.state= {
      searchMode: true,
    }
  }

  submitForm(values) {
    const { search }= this.state
    this.props.browseReport({search: {...values, ...search}})
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeDate(key, v) {
    const { search }= (this.props.reportStore.browseReport!==undefined ? this.props.reportStore.browseReport : {})
    var values= []
    values[key]= v
    this.props.browseReport({search: {...search, ...values}})
  }

  onChangeInput(e, v) {
    const { search }= (this.props.reportStore.browseReport!==undefined ? this.props.reportStore.browseReport : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.props.browseReport({search: {...search, keyword: v}})
      }
    }, 500)
  }

  onChangeSelect(e, v) {
    const { search }= (this.props.reportStore.browseReport!==undefined ? this.props.reportStore.browseReport : {})
    this.props.browseReport({search: {...search, status: v}})
  }
  
  render() {
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode }=this.props
    
    return (
      <div style={{position: 'static', width: '100%', zIndex: '1000'}}>
        <div style={{padding: '1em 1em', background: 'rgba(230, 230, 230, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
          <div style={{display: 'flex', padingTop: '1em'}}>
            <div style={{flex: '1'}}>
              {/* <Header as='h4' style={{marginTop: '.25em'}}>
                <Icon name='file alternate outline' style={{fontSize: '1em'}}/>
                <Header.Content>
                  Reporting / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
                </Header.Content>
              </Header> */}
            </div>
            <div>
              {/* <Button size='mini' onClick={this.props.setMode.bind(this,3)} icon labelPosition='left' color='red'><Icon name='plus' /> CREATE  </Button> */}
              {/* <Button size='mini' color='grey' onClick={this.showSearch.bind(this)} icon style={{padding: '.5em'}}><Icon name={searchMode==true? 'angle up' : 'angle down'} /></Button> */}
            </div>
          </div>

          {searchMode && 
          <div style={{margin: '1em 0 0 0', border: '0px solid rgba(100,100,100,.2)'}}>
            <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Form.Group widths='16'>
                    <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)}
                    width={12} 
                    placeholder= 'Search account ... <ENTER>' />
                    <Field name='transactDate' component={DatePickerField} onChange= {this.onChangeDate.bind(this, 'transactDate')}
                    width={4} 
                    type='date'
                    placeholder= 'Trx. Date' />
                    {/* <Field name='status' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'status')}
                    options={reportOptions}
                    width={4} 
                    placeholder= 'Status' /> */}
                    {/* <Form.Field>
                      <Button size='mini' onClick={this.props.setMode.bind(this,3)} icon style={{padding: '1em 1em'}} color='red'><Icon style={{fontSize: '1.2em'}} name='plus' /></Button>
                    </Form.Field> */}
                  </Form.Group>
                </div>
                <div>
                  <Button size='mini' onClick={this.props.setMode.bind(this,3)} icon style={{marginLeft: '1em', padding: '1em 1em'}} color='red'><Icon style={{fontSize: '1.2em'}} name='plus' /></Button>
                </div>
              </div>
            </Form>
          </div>}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.reportStore.browseReport!=undefined && state.reportStore.browseReport.progress==1 ? state.reportStore.browseReport.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'ReportingSearch',	// a unique identifier for this form
  })(ReportingSearch)
  )
)