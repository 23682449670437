import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Statistic, Icon, Label, Table, Progress } from 'semantic-ui-react'

import PieRechart from '../chart/PieRechart';
import RadialRechart from '../chart/RadialRechart';
import BarRechart from '../chart/BarRechart';

import DismissableMessage from '../common/DismissableMessage'
import {hourOptions, yesnoOptions, redistOptions, delimiterOptions, fieldTargetOptions}  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField, NumberField, TextAreaField, DatePickerField, DataUploadField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetForm, checkToken, setOnboarding} from "../../actions/auth/authAction"
import { listWorkgroup, listTemplate, listProduct, listCampaign, collectionStage, restructuringStage, telesaleStage, verificationStage, listParams } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('ScriptForm')

const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
    masterStore: state.masterStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),
    setOnboarding:(objParam) => dispatch(setOnboarding(objParam)),

    listParams:(objParam) => dispatch(listParams(objParam)),

    resetForm:(objParam) => dispatch(resetForm(objParam)),
  }
}

class CampaignPerformance extends Component {
  constructor(props) {
    super(props)
    this.state= {
      view: 0,
    }
  }

  componentDidMount(){
    this.props.resetForm('setOnboarding')
    // this.props.checkToken();
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= this.props.authStore.setOnboarding!=undefined ? this.props.authStore.setOnboarding : {}

    if (progress==1 && code=='00') {
      setTimeout(()=>{
        this.setState({
          view: 4,
        });
    }, 1000)
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  submitForm(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    if (this.state.view==1 || this.state.view==2 || this.state.view==3) {
      this.setState({
        view: 0,
      })  
    } else if (this.state.view==0) {
      this.setState({
        view: 6,
      })  
    } else if (this.state.view<10) {
      this.setState({
        view: this.state.view+1,
      })  
    } else {
      // this.props.setOnboarding({...values})
    }
  }

  clickView(v) {
    this.setState({
      view: v
    })
  }

  closeModal() {
    this.props.closeModal();
    // window.location.reload();
  }

  render() {
    const { item }= this.props;
    
    const pieDelivery = [
      { name: 'Target', value: item && item.totTarget },
      { name: 'Sent', value: item && item.totAttempt },
      { name: 'Delivered', value: item && item.totDelivered }, 
      { name: 'Read', value: item && item.totEngaged },
      // { name: 'Clicked', value: 3 },
    ];
    const radialDelivery = [
      {name: 'TARGET', value: item && item.totTarget, fill: '#61A5E0'},
      {name: 'SENT', value: item && item.totAttempt, fill: '#b5cc18'},
      {name: 'DLVRD', value: item && item.totDelivered, fill: '#21ba45'},
      {name: 'READ', value: item && item.totEngaged, fill: '#fbbd08'},
      // {name: 'Clicked', value: 5, fill: '#f2711c'},
    ];
    const legendDelivery = [
      {id: 'value', name: 'Progress'}, 
    ];
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Header as='h4'>
          <Header.Content>
            Progress
            <Header.Subheader>Content delivery progress.</Header.Subheader>
          </Header.Content>
        </Header>
        <div>
          <Statistic.Group size='tiny' widths={4}>
            <Statistic>
              <Statistic.Value>{item.totTarget}</Statistic.Value>
              <Statistic.Label>TARGET</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{item.totAttempt}</Statistic.Value>
              <Statistic.Label>Sent</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{item.totDelivered}</Statistic.Value>
              <Statistic.Label>Dlvrd</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{item.totEngaged}</Statistic.Value>
              <Statistic.Label>Read</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </div>

        <Progress indicating size='medium' percent={item && item.totTarget>0 ? Math.round((item.totComplete / item.totTarget) * 100) : 0} progress />
        <Header as='h4'>
          <Header.Content>
            Impression
            <Header.Subheader>Customer's impression sent, delivered, read and clicked*.</Header.Subheader>
          </Header.Content>
        </Header>

        {/* <PieRechart data={pieDelivery} height={200} fill='#555'/> */}
        {/* <RadialRechart data={radialDelivery} legend={legendDelivery} height={250}/> */}
        <BarRechart mode='shape' height={450} yAxis={false} dashed={true} label={true} data={radialDelivery} legend={legendDelivery}/>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.authStore.checkToken!=undefined && state.authStore.checkToken.progress==1 ? state.authStore.checkToken.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    }),
    { load: checkToken }
  )
  (reduxForm({
    form: 'CampaignPerformance',	// a unique identifier for this form
  })(CampaignPerformance)
  )
)
