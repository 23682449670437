import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Grid, Progress, Statistic, Label } from 'semantic-ui-react'

import numeral from "numeral"

import { connect } from "react-redux"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus, socketSystemHeartbeat, socketSystemOccupancy, socketSystemMonitoring } from "../../../actions/socket/socketAction"
import WebSocketProvider, { WebSocketContext } from '../../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketSystemMonitoring:(objParam) => dispatch(socketSystemMonitoring(objParam)),
  }
}

class MessagingWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      expand: true,
      isUsage: false,
      isDeposit: false,
    };
    this.billings= {};
  }

  expandContent(v) {
    if (v=='usage') {
      this.setState({
        isUsage: !this.state.isUsage
      })
    } else {
      this.setState({
        isDeposit: !this.state.isDeposit
      })
    }
  }

  openModal(v) {
    this.setState({
      expand: !this.state.expand
    })
    if (this.props.openModal) {
      this.props.openModal(this.state.expand ? v +'Outbound' : v +'Inbound')
    }
  }
  
  render() {
    const { isUsage, isDeposit }= this.state
    const { screenWidth,  screenHeight}= this.props
    const { agents, omnis, providers, billings }= this.props.socketStore.socketSystemMonitoring!=undefined && this.props.socketStore.socketSystemMonitoring.data && this.props.socketStore.socketSystemMonitoring.data.payload && this.props.socketStore.socketSystemMonitoring.data.payload.data ? this.props.socketStore.socketSystemMonitoring.data.payload.data: {};
    
    if (billings) {
      this.billings= billings;
    } 
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <div style={{display: 'flex'}}>
          <div style={{flex: '1'}}>
            <Header as='h5' style={{margin: '0'}}>
              <Icon name='fire' style={{fontSize: '1em'}} />
              <Header.Content>
                Messaging Flow
                <Header.Subheader></Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div>
            <Popup  content='Expand incoming or outgoing message window as your wish.' position='left center'
              trigger={
                <Icon name={this.state.expand ? 'arrow circle left' : 'arrow circle right'} onClick={this.openModal.bind(this, 'expand')}  style={{fontSize: '1.35em', cursor: 'pointer'}} color={this.state.expand ? 'red' : 'blue'} />
              }
              inverted
              on={['hover']}
            />
          </div>
        </div>
      </Segment>
      <Segment attached='bottom'>
        <div style={{display: 'flex', textAlign: 'center'}}>
          <div style={{flex: '1', background: '#fafafa', padding: '1em 0'}}>
            <Popup  header='Incoming Messages' content='Total received message in this month.' position='left center'
              trigger={
              <Header as='h2' style={{margin: '0', cursor: 'pointer'}}>
                <Header.Content>
                  {this.billings && this.billings.prepaid && (this.billings.prepaid.depositTelco>1000 ? numeral(this.billings.prepaid.depositTelco).format(this.billings.prepaid.depositTelco%1000==0 ? '0a' : '0.0a') : numeral(this.billings.prepaid.depositTelco).format('0,0'))}
                  <Header.Subheader style={{fontWeight: 'bold', color: '#666'}}>Incoming</Header.Subheader>
                </Header.Content>
              </Header>
              }
              inverted
              on={['hover']}
            />
          </div>
          <div style={{flex: '1', background: '#fafafa', padding: '1em 0', borderLeft: '1px solid #ccc'}}>
            <Popup  header='Outgoing Messages' content='Total sent message in this month.' position='left center'
              trigger={
                <Header as='h2' style={{margin: '0', cursor: 'pointer'}}>
                  <Header.Content>
                    {this.billings && this.billings.prepaid && (this.billings.prepaid.depositWhatsapp>1000 ? numeral(this.billings.prepaid.depositWhatsapp).format(this.billings.prepaid.depositWhatsapp%1000==0 ? '0a' : '0.0a') : numeral(this.billings.prepaid.depositWhatsapp).format('0,0'))}
                    <Header.Subheader style={{fontWeight: 'bold', color: '#666'}}>Outgoing</Header.Subheader>
                  </Header.Content>
                </Header>
              }
              inverted
              on={['hover']}
            />
          </div>
        </div>
      </Segment>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagingWidget)