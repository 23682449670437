import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

import EmulatorHeader from "./EmulatorHeader"
import EmulatorFooter from "./EmulatorFooter"
import EmulatorBody from "./EmulatorBody"

class Emulator extends React.Component {
    constructor(props) {
      super(props)
      this.state= {
        screenHeight: 800,
        screenWidth: 1024,
      }
      this.updateDimension = this.updateDimension.bind(this)
    }

    componentDidMount() {
      this.updateDimension()
      window.addEventListener('resize', this.updateDimension)
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimension)
    }
    
    updateDimension() {
      this.setState({ ...this.state, screenWidth: window.innerWidth, screenHeight: window.innerHeight })
    }

    render() {
      const {screenHeight, screenWidth, mode, openDrawer}= this.state
      
      return(
        <Container fluid>
          <EmulatorHeader screenHeight={screenHeight} screenWidth={screenWidth}/>
          <EmulatorBody screenHeight={screenHeight} screenWidth={screenWidth} />
          {/* <EmulatorFooter screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.openWindow.bind(this)}/> */}
        </Container>
      )
    }
} 

export default Emulator
