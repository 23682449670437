import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
  ModalDescription,
  Modal,
} from 'semantic-ui-react'


class EmulatorHeader extends Component {
  constructor(props){
    super(props)
  	this.state = {
    }
  }

  render() {
    const { screenWidth, screenHeight, dialMode, phoneMode, uiMode, layout, sipAccount, mode, status, responsive }= this.props
    return (
      <Segment attached='top' style={{padding: '1em .5em', background: 'rgb(51, 51, 51)', border: 'none', borderBottom: '1px solid rgba(200,200,200,.2)', cursor: 'grab'}}  className="handle">
        <div style={{display: 'flex'}}>
          <div style={{flex: '1'}}>
            {phoneMode=='occupied' && 
            // {(status=='online' || status=='occupied') &&
            <Header as='h4' inverted style={{padding: '.1em 0em', margin: '0'}}>
              <Icon name='microphone' style={{fontSize: '1em'}}/>
              <Header.Content>
                {mode=='predictive' ? 'Online' : 'Oncall'} / {layout!='floating' ? '...' : sipAccount}
              </Header.Content>
            </Header>}
            
            {phoneMode=='registered' && 
            // {status=='connect' &&
            <Header as='h4' inverted style={{padding: '.1em 0em', margin: '0'}}>
              <Icon name='linkify' style={{fontSize: '1em'}}/>
              <Header.Content>
                Connected  / {layout!='floating' ? '...' : sipAccount}
              </Header.Content>
            </Header>}

            {phoneMode=='incoming' && 
            // {status=='callback' &&
            <Header as='h4' style={{padding: '.1em 0em', margin: '0'}} inverted>
              <Icon name='phone volume' style={{fontSize: '1em', color: '#fff'}}/>
              <Header.Content>
                Incoming  / {layout!='floating' ? '...' : sipAccount}
              </Header.Content>
            </Header>}

            {phoneMode=='offline' &&
            <Header as='h4' style={{padding: '.1em 0em', margin: '0'}} color='grey'>
              <Icon name='unlink' style={{fontSize: '1em', color: '#fff'}}/>
              <Header.Content>
                Disconnect  / {layout!='floating' ? '...' : sipAccount}
              </Header.Content>
            </Header>}
          </div>
        </div>
      </Segment>
    );
  }
}

export default EmulatorHeader