import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Icon } from 'semantic-ui-react'

import { competencyOptions, groupOptions, genderOptions,educationOptions,protoOptions, channelOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { ImageUploadField, LabelInputField, NumberField, InputField, CheckboxField, SelectField, DatePickerField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
import { connect } from "react-redux"
import { resetAgent, getAgent,updateAgent, createAgent } from "../../actions/halo/agentAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    agentStore: state.agentStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetAgent:(objParam) => dispatch(resetAgent(objParam)),
    getAgent:(objParam) => dispatch(getAgent(objParam)),
    createAgent:(objParam) => dispatch(createAgent(objParam)),
    updateAgent:(objParam) => dispatch(updateAgent(objParam)),
  }
}

class AgentForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    this.props.resetAgent(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getAgent({agentId: this.props.agentId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.agentStore.updateAgent!=undefined ? this.props.agentStore.updateAgent : {}) : (this.props.agentStore.createAgent!=undefined ? this.props.agentStore.createAgent : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, agentId } = this.props
    mode==2 ? this.props.updateAgent({...values}) : this.props.createAgent({...values})
  }
  

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.agentStore.updateAgent!=undefined ? this.props.agentStore.updateAgent : {}) : (this.props.agentStore.createAgent!=undefined ? this.props.agentStore.createAgent : {}))

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='equal' >
              <Form.Field>
                <Field name='avatar' component={ImageUploadField}
                width={300} 
                height={300} 
                aspectRation={3/3} 
                />
              </Form.Field>
            </Form.Group>
            <Divider hidden/>
            <Form.Group widths='16'>
              <Field name='isEnabled' component={CheckboxField} style={{marginTop: '2.5em'}}
              toggle
              width={4}
              label='Enable Now !'
              placeholder='Status enable/disabled' />
              <Field name='isSuspended' component={CheckboxField} style={{marginTop: '2.5em'}}
              toggle
              width={4}
              label='Suspend Now !'
              placeholder='Status suspended' />
              <Field name='isDeleted' component={CheckboxField} style={{marginTop: '2.5em'}}
              toggle
              width={4}
              label='Deleted Now !'
              placeholder='Status deleted' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' />
            </Form.Group>
          </Segment>
          <Segment attached='top'>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              width={4}
              label='Full Name'
              placeholder='Full Name' />
              <Field name='quote' component={InputField}
              width={4}
              label='Tagline'
              placeholder='Quote / Tagline' />
              <Field name='mobile' component={InputField}
              width={4}
              label='Mobile'
              icon='square outline'
              placeholder='Handphone' />
              <Field name='email' component={InputField}
              width={4}
              label='Email'
              icon='envelope outline'
              placeholder='Email' />
            </Form.Group>
          </Segment>
          <Segment attached>
            <Form.Group widths='equal'>
              <Field name='account' component={InputField}
              readOnly= {mode==2? true : false}
              label= 'Account'
              placeholder='Account'
              validate={[required]} />
              <Field name='groupId' component={InputField}
              readOnly= {mode==2? true : false}
              label= 'User Group'
              placeholder='Role / Group'
              options={groupOptions('halopagi')}
              validate={[required]} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='tagger' component={InputField}
              label='Tagger (Mapping Key for Customer Call Dispatch)'
              placeholder='TAG1, TAG2, TAG3' />
              <Field name='channel' component={SelectField}
              label= 'Channel'
              options={channelOptions('channel')}
              placeholder='Default is audio only.' />
            </Form.Group>
          </Segment>
          <Segment attached='bottom'>
            <Form.Group widths='equal'>
              <Field name='monthlyRate' component={NumberField}
              fluid
              label='Monthly Rate'
              placeholder='Rp. / Month' />
              <Field name='gender' component={SelectField}
              fluid
              label='Gender'
              options={genderOptions}
              placeholder='L/P' />
              <Field name='birthPlace' component={InputField}
              label='Birth Place'
              placeholder='Birth Place' />
              <Field name='birthDate' component={DatePickerField}
              label='Birth Date'
              validate={[required]}
              placeholder='Birth Date' />
            </Form.Group>
          </Segment>
          
          <Segment>
            <Header as='h5'>
              <Icon name='map marker alternate' style={{fontSize: '1em'}}/>
              <Header.Content>Location</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='address' component={InputField}
              fluid
              label='Address'
              placeholder='Address' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='city' component={InputField}
              width={8}
              placeholder='City' />
              <Field name='province' component={InputField}
              width={4}
              placeholder='Province' />
              <Field name='country' component={InputField}
              width={4}
              placeholder='Country' />
            </Form.Group>
          </Segment>
          
          <Segment>
            <Header as='h5'>
              <Icon name='image outline' style={{fontSize: '1em'}}/>
              <Header.Content>Expertise</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='educationInstitute' component={InputField}
              width={8}
              label='School / Institute / University' />
              <Field name='educationDepartment' component={InputField}
              width={4}
              label='Major / Department' />
              <Field name='educationLevel' component={SelectField}
              fluid
              width={4}
              label='Level'
              options={educationOptions}
              placeholder='S1 / S2 / S3' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='competencies' component={SelectField}
              width={16}
              multiple
              search
              options={competencyOptions}
              label='Professional Competency' />
            </Form.Group>
          </Segment>

          <Segment>
            <Header as='h5'>
              <Icon name='qrcode' style={{fontSize: '1em'}}/>
              <Header.Content>
                Credential
                <Header.Subheader>Set to blank for auto asigned by system, account will not be assigned permanently</Header.Subheader>
              </Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='voipAccount' component={InputField}
              width={4}
              label='Account' />
              <Field name='voipPasswd' component={InputField}
              width={4}
              label='Password' />
              <Field name='voipRealm' component={InputField}
              width={8}
              label='Realm' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='voipHost' component={InputField}
              width={8}
              label='Host / Server' />
              <Field name='voipPort' component={InputField}
              width={4}
              label='Port Number'/>
              <Field name='voipProto' component={SelectField}
              width={4}
              options={protoOptions}
              label='Protocol' />
            </Form.Group>
          </Segment>

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Agent' : 'Insert Agent') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.agentStore.getAgent!=undefined && state.agentStore.getAgent.progress==1 ? state.agentStore.getAgent.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'AgentForm',	// a unique identifier for this form
  })(AgentForm)
  )
)