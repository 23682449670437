import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Table } from 'semantic-ui-react'

import { competencyOptions, genderOptions,educationOptions, provinceOptions, countryOptions }  from '../../common/StaticMasterData'
import DismissableMessage from '../../common/DismissableMessage'
import MenuTabular from '../../common/MenuTabular'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField, DatePickerField, TextAreaField, NumberField } from '../../validation/componentrsui'
import { required, number, minValue, email } from '../../validation/validation'
import { connect } from "react-redux"
import { resetProduct, getProduct,updateProduct, createProduct } from "../../../actions/halo/productAction"
import { listProduct } from "../../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('ProductForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    productStore: state.productStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetProduct:(objParam) => dispatch(resetProduct(objParam)),
    getProduct:(objParam) => dispatch(getProduct(objParam)),
    createProduct:(objParam) => dispatch(createProduct(objParam)),
    updateProduct:(objParam) => dispatch(updateProduct(objParam)),

    listProduct:(objParam) => dispatch(listProduct(objParam)),
  }
}

const panes = (param)=>{
  return([
    {
      menuItem: { key: '1', icon: 'copy outline', content: 'Costings' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '2', icon: 'file alternate outline', content: 'Q & A / Knowledge' },
      render: () => 
      <></>
    },
    // {
    //   menuItem: { key: '3', icon: 'building outline', content: 'Customer' },
    //   render: () => 
    //   <></>
    // },
  ])
};

class ProductForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      tab: 0,
    }
  }

  componentDidMount(){
    this.props.resetProduct(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getProduct({productId: this.props.productId})
    }
  }

  componentDidUpdate() {
    const { mode, modal } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.productStore.updateProduct!=undefined ? this.props.productStore.updateProduct : {}) : (this.props.productStore.createProduct!=undefined ? this.props.productStore.createProduct : {}))
    
    if (!modal && progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
    if (modal && progress==1 && code=='00') {
      this.props.closeModal('hide');
      this.props.listProduct({search: {clientId: this.props.clientId || null}})
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    
    mode==2 ? this.props.updateProduct({...values}) : this.props.createProduct({...values, ...{clientId: this.props.clientId}})
  }

  qaItems(qty) {
    var qaItems= this.props.formValues!=undefined && this.props.formValues.qaItems!=undefined ? this.props.formValues.qaItems : []
    var items= [];
    for (var i=0; i<qty; i++) {
      items[i]= {
        item: qaItems[i]!=undefined ? qaItems[i].item : null,
        price: qaItems[i]!=undefined ? qaItems[i].price : null,
        discount: qaItems[i]!=undefined ? qaItems[i].discount : null,
        subtotal: qaItems[i]!=undefined ? qaItems[i].subtotal : null,
      };
    }

    return (
      <>
        {
          items.map((item, index)=>{
            return(
              <Table.Row key={index}>
                <Table.Cell>{(index+1)}</Table.Cell>
                <Table.Cell>
                  <Form.Group>
                    <Field name={'question_'+ (index+1)} component={LabelInputField}
                    width={16}
                    label='Q'
                    placeholder='Question' />
                  </Form.Group>
                  <Form.Group>
                    <Field name={'answer_'+ (index+1)} component={LabelInputField}
                    width={16}
                    label='A'
                    placeholder='Answer' />
                  </Form.Group>
                </Table.Cell>
                <Table.Cell>
                  <Form.Group>
                    <Field name={'keyword_'+ (index+1)} component={TextAreaField}
                    width={16}
                    rows={4}
                    // label='Keywords'
                    placeholder='Keywords separated by"," keyword_1, keyword_2' />
                  </Form.Group>
                </Table.Cell>
              </Table.Row>
            )
          })
        }
      </>
    )
  }

  incrQaQty(isIncrease) {
    // console.log('BILL QTY', this.props.qaQty)

    if (isIncrease) {
      // console.log('BILL QTY++', (this.props.qaQty+1))
      if (this.props.qaQty<10) {
        this.props.dispatch(change('ProductForm', 'qaQty', ((isNaN(this.props.qaQty) ? 0 : parseInt(this.props.qaQty)) +1)))
      }
    } else {
      // console.log('BILL QTY--', (this.props.qaQty-1))
      if (this.props.qaQty>1) {
        this.props.dispatch(change('ProductForm', 'qaQty', ((isNaN(this.props.qaQty) ? 0 : parseInt(this.props.qaQty)) -1)))
      }
    }
  }

  costItems(qty) {
    var costItems= this.props.formValues!=undefined && this.props.formValues.costItems!=undefined ? this.props.formValues.costItems : []
    var items= [];
    for (var i=0; i<qty; i++) {
      items[i]= {
        item: costItems[i]!=undefined ? costItems[i].item : null,
        price: costItems[i]!=undefined ? costItems[i].price : null,
        discount: costItems[i]!=undefined ? costItems[i].discount : null,
        subtotal: costItems[i]!=undefined ? costItems[i].subtotal : null,
      };
    }

    return (
      <>
        {
          items.map((item, index)=>{
            return(
              <Table.Row key={index}>
                <Table.Cell>{(index+1)}</Table.Cell>
                <Table.Cell>
                  <Form.Group>
                    <Field name={'item_'+ (index+1)} component={LabelInputField}
                    width={16}
                    label='Item'
                    placeholder='Cost Item' />
                  </Form.Group>
                  <Form.Group>
                    <Field name={'descript_'+ (index+1)} component={LabelInputField}
                    width={16}
                    label='Desc'
                    placeholder='Description' />
                  </Form.Group>
                </Table.Cell>
                <Table.Cell>
                  <Form.Group>
                    <Field name={'price_'+ (index+1)} component={LabelInputField}
                    width={16}
                    type='number'
                    label='Rp.'
                    placeholder='Default Price' />
                  </Form.Group>
                </Table.Cell>
              </Table.Row>
            )
          })
        }
      </>
    )
  }
  
  incrCostQty(isIncrease) {
  // console.log('BILL QTY', this.props.costQty)

  if (isIncrease) {
    // console.log('BILL QTY++', (this.props.costQty+1))
    if (this.props.costQty<10) {
      this.props.dispatch(change('ProductForm', 'costQty', ((isNaN(this.props.costQty) ? 0 : parseInt(this.props.costQty)) +1)))
    }
  } else {
    // console.log('BILL QTY--', (this.props.costQty-1))
    if (this.props.costQty>1) {
      this.props.dispatch(change('ProductForm', 'costQty', ((isNaN(this.props.costQty) ? 0 : parseInt(this.props.costQty)) -1)))
    }
  }
  }

  setMode(e, d) {
    // console.log(d.activeIndex)
    this.setState({tab: d.activeIndex})
  }

  render() {
    const { tab }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode, modal } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.productStore.updateProduct!=undefined ? this.props.productStore.updateProduct : {}) : (this.props.productStore.createProduct!=undefined ? this.props.productStore.createProduct : {}))

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          
          <Segment>
            <Form.Group widths='equal' >
              <Form.Field>
                <Field name='avatar' component={ImageUploadField}
                width={300} 
                height={400} 
                aspectRation={4/3} 
                />
              </Form.Field>
            </Form.Group>
            
            <Form.Group widths='16' style={{marginTop: '2em'}}>
              <Field name='isEnabled' component={CheckboxField}
              toggle
              width={8}
              caption='Enabled'
              label='Enable Now !'
              placeholder='Status enable/disabled' />
              {/* <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              width={4}
              readOnly
              label='Client'
              placeholder='Client' /> */}
            </Form.Group>
            
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              width={16}
              validate={[required]}
              label='Name'
              placeholder='Name' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='descript' component={TextAreaField}
              width={16}
              rows={6}
              label='Description'
              placeholder='Description' />
            </Form.Group>

            <Header color='blue' as='h5'>
              <Icon name='envelope open outline' style={{fontSize: '1em'}}/>
              <Header.Content>Pricing & Discount Rate</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='grossAmount' component={NumberField}
              fluid
              prefix='Rp. '
              label='Price Amount'
              placeholder='Gross Amount' />
              <Field name='discountAmount' component={NumberField}
              fluid
              prefix='Rp. '
              label='Discount Amount'
              placeholder='Discount Amount' />
              <Field name='nettAmount' component={NumberField}
              fluid
              prefix='Rp. '
              label='Nett Price Amount'
              placeholder='Nett Amount' />
            </Form.Group>
          </Segment>
          
          <Divider hidden />
          <MenuTabular panes={panes({})} style={{width: '100%', marginBottom: '-1.2em', marginTop: '1em'  }} renderActiveOnly={true} onTabChange={this.setMode.bind(this)}/>
          {tab==0 && <Segment>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Header as='h5' style={{margin: '0'}}>
                  {/* <Icon name='file alternate outline' style={{fontSize: '1em'}}/> */}
                  <Header.Content>
                    Product / Service's Cost Structure
                    <Header.Subheader>Availabe default value cost components of products.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div>
                <Button type='button' icon='minus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrCostQty.bind(this,false)}/>
              </div>
              <div>
                  <Field name='costQty' component={InputField} size='small' style={{height: '2.2em', width: '3em', borderRadius: '0'}}
                  type='number'
                  fluid
                  />
              </div>
              <div>
                <Button type='button' icon='plus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrCostQty.bind(this,true)}/>
              </div>
            </div>
            <Table unstackable selectable columns='16' celled striped basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell width='12'>
                    <Header as='h5' color='blue'>
                      <b>Cost Component</b>
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='4'>
                    <Header as='h5' color='blue'>
                      <b>Default Price / Cost</b>
                    </Header>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  this.costItems(this.props.costQty||0)
                }
              </Table.Body>
            </Table>
          </Segment>}

          {tab==1 && <Segment>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Header as='h5' style={{margin: '0'}}>
                  {/* <Icon name='file alternate outline' style={{fontSize: '1em'}}/> */}
                  <Header.Content>
                    Question and Answer
                    <Header.Subheader>List available simple question answer which accessible from agent.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div>
                <Button type='button' icon='minus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrQaQty.bind(this,false)}/>
              </div>
              <div>
                  <Field name='qaQty' component={InputField} size='small' style={{height: '2.2em', width: '3em', borderRadius: '0'}}
                  type='number'
                  fluid
                  />
              </div>
              <div>
                <Button type='button' icon='plus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrQaQty.bind(this,true)}/>
              </div>
            </div>
            <Table unstackable selectable columns='16' celled striped basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell width='12'>
                    <Header as='h5' color='blue'>
                      <b>Question + Answer</b>
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='4'>
                    <Header as='h5' color='blue'>
                      <b>Searchable Keywords</b>
                    </Header>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  this.qaItems(this.props.qaQty||0)
                }
              </Table.Body>
            </Table>
          </Segment>}

          {tab==2 && <Segment>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Header as='h5' style={{margin: '0'}}>
                  {/* <Icon name='file alternate outline' style={{fontSize: '1em'}}/> */}
                  <Header.Content>
                    Recent Customers
                    <Header.Subheader>Recent customers with related transactions.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div>
                <Button type='button' icon='minus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrQaQty.bind(this,false)}/>
              </div>
              <div>
                  <Field name='qaQty' component={InputField} size='small' style={{height: '2.2em', width: '3em', borderRadius: '0'}}
                  type='number'
                  fluid
                  />
              </div>
              <div>
                <Button type='button' icon='plus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrQaQty.bind(this,true)}/>
              </div>
            </div>
            <Table unstackable selectable columns='16' celled striped basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell width='6'>
                    <Header as='h5' color='blue'>
                      <b>Customer</b>
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='4'>
                    <Header as='h5' color='blue'>
                      <b>Period</b>
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='6'>
                    <Header as='h5' color='blue'>
                      <b>Trx. Value</b>
                    </Header>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  // this.qaItems(this.props.qaQty||0)
                }
              </Table.Body>
            </Table>
          </Segment>}

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Product' : 'Insert Product') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.productStore.getProduct!=undefined && state.productStore.getProduct.progress==1 ? {...{isEnabled: 1, qaQty: '0'}, ...state.productStore.getProduct.data} : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,

      costQty: selector(state, 'costQty'),
      qaQty: selector(state, 'qaQty'),
    }),
  )
  (reduxForm({
    form: 'ProductForm',	// a unique identifier for this form
  })(ProductForm)
  )
)
