import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed } from 'semantic-ui-react'

import numeral from "numeral"

import { connect } from "react-redux"
import { statAsset } from "../../actions/halo/statisticAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    statisticStore: state.statisticStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    statAsset:(objParam) => dispatch(statAsset(objParam)),
  }
}


class StatisticWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {};
  }

  componentDidMount() {
    this.props.statAsset() 
  }
  
  render() {
    const { screenWidth,  screenHeight}= this.props
    const statAsset= (this.props.statisticStore.statAsset!=undefined && this.props.statisticStore.statAsset.progress=='1' ? this.props.statisticStore.statAsset.data : {})
    
    return (
      <div style={{display: 'flex'}}>
        <div style={{flex: '1', marginRight: '.7em'}}>
          <Segment attached='top' style={{padding: '1em .5em', background: '#000'}}>
            <Header as='h5' inverted>
              <Icon name='chart bar outline' style={{fontSize: '1em'}} />
              <Header.Content>
                Statistic
              </Header.Content>
            </Header>
          </Segment>
          <Segment attached='bottom' style={{padding: '1em', background: '#d63400'}}>
            <Header as='h4' inverted>
              <Icon name='building outline' style={{}} />
              <Header.Content>
                {numeral((statAsset.clients && statAsset.clients.provider) || 0).format('0a')}
                <Header.Subheader>Provider</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <Header as='h4' inverted>
              <Icon name='calendar alternate outline' style={{}} />
              <Header.Content>
                {numeral((statAsset.campaigns && statAsset.campaigns.campaign) || 0).format('0a')}
                <Header.Subheader>Campaign</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <Header as='h4' inverted style={{margin: '0'}}>
              <Icon name='user outline' style={{}} />
              <Header.Content>
                {numeral((statAsset.users && statAsset.users.account) || 0).format('0a')}
                <Header.Subheader>Account</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <Header as='h4' inverted style={{margin: '0'}}>
              <Icon name='user circle' style={{}} />
              <Header.Content>
                {numeral((statAsset.traffics && statAsset.traffics.totTarget) || 0).format('0a')}
                <Header.Subheader>Target</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <Header as='h4' inverted style={{margin: '0'}}>
              <Icon name='square outline' style={{}} />
              <Header.Content>
                {numeral((statAsset.traffics && statAsset.traffics.totAttempt) || 0).format('0a')}
                <Header.Subheader>Attempt</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <Header as='h4' inverted style={{margin: '0'}}>
              <Icon name='check square outline' style={{}} />
              <Header.Content>
                {numeral((statAsset.traffics && statAsset.traffics.totDelivered) || 0).format('0a')}
                <Header.Subheader>Delivered</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <Header as='h4' inverted style={{margin: '0'}}>
              <Icon name='comments outline' style={{}} />
              <Header.Content>
                {numeral((statAsset.traffics && statAsset.traffics.totEngaged) || 0).format('0a')}
                <Header.Subheader>Contacted</Header.Subheader>
              </Header.Content>
            </Header>
          </Segment>
      </div>
      <div style={{flex: '1', marginLeft: '.7em'}}>
          <Segment attached='top' style={{padding: '1em .5em', background: '#000'}}>
            <Header as='h5' inverted>
              <Icon name='chart bar outline' style={{fontSize: '1em'}} />
              <Header.Content>
                Revenue
              </Header.Content>
            </Header>
          </Segment>
          <Segment attached='bottom' style={{padding: '1em', background: '#d63400'}}>
            <Header as='h4' inverted>
              <Icon name='file alternate outline' style={{}} />
              <Header.Content>
                {numeral((statAsset.revenues && statAsset.revenues.totBillAmount) || 0).format('0a')}
                <Header.Subheader>Bills Amount</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <Header as='h4' inverted>
              <Icon name='cut' style={{}} />
              <Header.Content>
                {numeral((statAsset.revenues && statAsset.revenues.totDiscountAmount + statAsset.revenues.totVoucherAmount) || 0).format('0a')}
                <Header.Subheader>Discount Amount</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <Header as='h4' inverted>
              <Icon name='file alternate' style={{}} />
              <Header.Content>
                {numeral((statAsset.revenues && statAsset.revenues.totNettAmount) || 0).format('0a')}
                <Header.Subheader>Nett Amount</Header.Subheader>
              </Header.Content>
            </Header>
          </Segment>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticWidget)