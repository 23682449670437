import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'


// import ModalContainer from '../../../libraries/common/ModalContainer';
import MenuHorizontal from '../../libraries/common/MenuHorizontal';

import WebwidgetBody from '../talk/WebwidgetBody';
import AndroidwidgetBody from '../talk/AndroidwidgetBody';
import IOSwidgetBody from '../talk/IOSwidgetBody';

const options= (module)=>{
  return([
    {
      key: '1',
      icon: 'chrome',
      caption: 'Web Widget',
      hint:{
        header: 'Web Widget',
        // content: 'Monitor your current active and recent campaign, click to make sure you know the details.'
      }
    },
    {
      key: '2',
      icon: 'apple',
      caption: 'IOS Widget',
      hint:{
        header: 'Tunnel / MAU Occupancy',
        // content: 'Capacity tunnel and accounts which available and already occupied.'
      }
    },
    {
      key: '3',
      icon: 'android',
      caption: 'Android Widget',
      hint:{
        header: 'Android Widget',
        // content: 'Agent activity statistic performance, available for one month performance.'
      }
    },
  ])
}

class WidgetBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        // widgetId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, widgetId: id})
    }

    render() {
      const {mode, widgetId}= this.state
      const {screenWidth, screenHeight, module}= this.props

      return(
        <Container fluid>
          <div style={{margin: '0em 1em 0 1em'}}>
            <MenuHorizontal screenWidth={screenWidth} onClick={this.setMode.bind(this)} options= {options(module)} color='red'/>
          </div>
          <div>
          {mode==1 && <WebwidgetBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
          {mode==2 && <AndroidwidgetBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
          {mode==3 && <IOSwidgetBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
          </div>
        </Container>
      )
    }
}

export default WidgetBody
