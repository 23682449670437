export default function masterReducer (state = {}, action) {
  switch (action.type) {

    case 'CAMPAIGN_LIST_PENDING': {
        return({...state, listCampaign: {progress: 0}})
    }
    case 'CAMPAIGN_LIST_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, listCampaign: {progress: 2, payload: action.payload}})
        } else {
            return({...state, listCampaign: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CAMPAIGN_LIST_REJECTED': {
        return({...state, listCampaign: {progress: 2, payload: action.payload}})
    }

    case 'CLIENT_LIST_PENDING': {
      return({...state, listClient: {progress: 0}})
    }
    case 'CLIENT_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listClient: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listClient: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'CLIENT_LIST_REJECTED': {
      return({...state, listClient: {progress: 2, payload: action.payload}})
    }

    case 'AGENT_LIST_PENDING': {
      return({...state, listAgent: {progress: 0}})
    }
    case 'AGENT_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listAgent: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listAgent: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'AGENT_LIST_REJECTED': {
      return({...state, listAgent: {progress: 2, payload: action.payload}})
    }
    
    case 'TEAMLEADER_LIST_PENDING': {
      return({...state, listTeamleader: {progress: 0}})
    }
    case 'TEAMLEADER_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listTeamleader: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listTeamleader: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'TEAMLEADER_LIST_REJECTED': {
      return({...state, listTeamleader: {progress: 2, payload: action.payload}})
    }

    case 'SUPERVISOR_LIST_PENDING': {
      return({...state, listSupervisor: {progress: 0}})
    }
    case 'SUPERVISOR_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listSupervisor: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listSupervisor: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'SUPERVISOR_LIST_REJECTED': {
      return({...state, listSupervisor: {progress: 2, payload: action.payload}})
    }

    case 'CONTROLLER_LIST_PENDING': {
      return({...state, listController: {progress: 0}})
    }
    case 'CONTROLLER_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listController: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listController: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'CONTROLLER_LIST_REJECTED': {
      return({...state, listController: {progress: 2, payload: action.payload}})
    }

    case 'MANAGER_LIST_PENDING': {
      return({...state, listManager: {progress: 0}})
    }
    case 'MANAGER_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listManager: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listManager: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'MANAGER_LIST_REJECTED': {
      return({...state, listManager: {progress: 2, payload: action.payload}})
    }

    case 'TEMPLATE_LIST_PENDING': {
      return({...state, listTemplate: {progress: 0}})
    }
    case 'TEMPLATE_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listTemplate: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listTemplate: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'TEMPLATE_LIST_REJECTED': {
      return({...state, listTemplate: {progress: 2, payload: action.payload}})
    }

    case 'PRODUCT_LIST_PENDING': {
      return({...state, listProduct: {progress: 0}})
    }
    case 'PRODUCT_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listProduct: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listProduct: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'PRODUCT_LIST_REJECTED': {
      return({...state, listProduct: {progress: 2, payload: action.payload}})
    }

    case 'WORKGROUP_LIST_PENDING': {
      return({...state, listWorkgroup: {progress: 0}})
    }
    case 'WORKGROUP_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listWorkgroup: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listWorkgroup: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'WORKGROUP_LIST_REJECTED': {
      return({...state, listWorkgroup: {progress: 2, payload: action.payload}})
    }

    case 'SEGMENT_LIST_PENDING': {
      return({...state, listSegment: {progress: 0}})
    }
    case 'SEGMENT_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listSegment: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listSegment: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'SEGMENT_LIST_REJECTED': {
      return({...state, listSegment: {progress: 2, payload: action.payload}})
    }

    case 'COLLECTIONSTAGE_LIST_PENDING': {
      return({...state, collectionStage: {progress: 0}})
    }
    case 'COLLECTIONSTAGE_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, collectionStage: {progress: 2, payload: action.payload}})
      } else {
          return({...state, collectionStage: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'COLLECTIONSTAGE_LIST_REJECTED': {
      return({...state, collectionStage: {progress: 2, payload: action.payload}})
    }
    
    case 'RESTRUCTURINGSTAGE_LIST_PENDING': {
      return({...state, restructuringStage: {progress: 0}})
    }
    case 'RESTRUCTURINGSTAGE_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, restructuringStage: {progress: 2, payload: action.payload}})
      } else {
          return({...state, restructuringStage: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'RESTRUCTURINGSTAGE_LIST_REJECTED': {
      return({...state, restructuringStage: {progress: 2, payload: action.payload}})
    }
    
    case 'TELESALESTAGE_LIST_PENDING': {
      return({...state, telesaleStage: {progress: 0}})
    }
    case 'TELESALESTAGE_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, telesaleStage: {progress: 2, payload: action.payload}})
      } else {
          return({...state, telesaleStage: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'TELESALESTAGE_LIST_REJECTED': {
      return({...state, telesaleStage: {progress: 2, payload: action.payload}})
    }
    
    case 'VERIFICATIONSTAGE_LIST_PENDING': {
      return({...state, verificationStage: {progress: 0}})
    }
    case 'VERIFICATIONSTAGE_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, verificationStage: {progress: 2, payload: action.payload}})
      } else {
          return({...state, verificationStage: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'VERIFICATIONSTAGE_LIST_REJECTED': {
      return({...state, verificationStage: {progress: 2, payload: action.payload}})
    }
    
    case 'REMARK_LIST_PENDING': {
      return({...state, listRemark: {progress: 0}})
    }
    case 'REMARK_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listRemark: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listRemark: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'REMARK_LIST_REJECTED': {
      return({...state, listRemark: {progress: 2, payload: action.payload}})
    }
        
    case 'CAMPAIGN_SUMMARY_PENDING': {
      return({...state, summaryCampaign: {progress: 0}})
    }
    case 'CAMPAIGN_SUMMARY_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, summaryCampaign: {progress: 2, payload: action.payload}})
      } else {
          return({...state, summaryCampaign: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'CAMPAIGN_SUMMARY_REJECTED': {
      return({...state, summaryCampaign: {progress: 2, payload: action.payload}})
    }

    case 'PARAMS_LIST_PENDING': {
      return({...state, listParams: {progress: 0}})
    }
    case 'PARAMS_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listParams: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listParams: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'PARAMS_LIST_REJECTED': {
      return({...state, listParams: {progress: 2, payload: action.payload}})
    }

    case 'CLOSING_LIST_PENDING': {
      return({...state, listClosing: {progress: 0}})
    }
    case 'CLOSING_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listClosing: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listClosing: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'CLOSING_LIST_REJECTED': {
      return({...state, listClosing: {progress: 2, payload: action.payload}})
    }

    case 'HANDLINGS_LIST_PENDING': {
      return({...state, listHandlings: {progress: 0}})
    }
    case 'HANDLINGS_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listHandlings: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listHandlings: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'HANDLINGS_LIST_REJECTED': {
      return({...state, listHandlings: {progress: 2, payload: action.payload}})
    }
    
    case 'SEGMENTS_LIST_PENDING': {
      return({...state, listSegments: {progress: 0}})
    }
    case 'SEGMENTS_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listSegments: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listSegments: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'SEGMENTS_LIST_REJECTED': {
      return({...state, listSegments: {progress: 2, payload: action.payload}})
    }
    
    case 'CUSTOMER_LIST_PENDING': {
      return({...state, listCustomer: {progress: 0}})
    }
    case 'CUSTOMER_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listCustomer: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listCustomer: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'CUSTOMER_LIST_REJECTED': {
      return({...state, listCustomer: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}