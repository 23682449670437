import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Breadcrumb } from 'semantic-ui-react'
import ModalContainer from '../../libraries/common/ModalContainer';

import MenuLeft from '../../libraries/halo/MenuLeft'

//RESOURCE
import AccountBody from './AccountBody'
import MessageBody from './MessageBody'
import ProfileBody from './ProfileBody'
import ClientBody from './ClientBody'
import RatesBody from './RatesBody'
import SettingBody from './SettingBody'

// import AdminBodyTicket from './AdminBodyTicket';
// import AdminBodyKnowledge from './AdminBodyKnowledge';
// import AdminBodyCustomer from './AdminBodyCustomer';

import AdminBodyHalo from './AdminBodyHalo';
import AdminBodyDashboard from './AdminBodyDashboard';
import AdminBodyOutbound from './AdminBodyOutbound';
import AdminBodyInbound from './AdminBodyInbound';
import AdminBodyInappvoice from './AdminBodyInappvoice';
import AdminBodyOfficephone from './AdminBodyOfficephone';
import AdminBodyMiddleware from './AdminBodyMiddleware';


class AdminBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { mode, module, screenWidth, screenHeight, mini, modal, params }= this.props

    return (
      <div>
        <div style={{marginTop: '3em'}}>
            <div style={{display: 'flex'}}>
              <div style={{background: 'linear-gradient(92deg, rgb(255, 162, 23) 0%, rgb(255, 189, 34) 25%, rgb(218, 205, 5) 100%)', minHeight: screenHeight, borderRight: '1px solid #fff'}}>
                <MenuLeft openWindow={this.props.openWindow.bind(this)} mode={this.props.mode} screenHeight={screenHeight} screenWidth={screenWidth} module={module} mini={mini}/>
              </div>
              <div style={{flex: '1', marginRight: '2px',padding: '.85em .5em'}}>
                <div style={{position: 'fixed', left: '0', top: '2.4em', width: '100%', zIndex: 999, textAlign: 'center', textTransform: 'capitalize'}}>
                  <div style={{display: 'inline', padding: '.5em 1em', background: 'rgba(210, 180, 0, .9)', borderRadius: '1em'}}>
                    <Breadcrumb>
                      <Breadcrumb.Section active>{module || '-'}</Breadcrumb.Section>
                      <Breadcrumb.Divider icon='right arrow' />
                      <Breadcrumb.Section active>{mode || '-'}</Breadcrumb.Section>
                    </Breadcrumb>
                  </div>
                </div>

                {/* SYSTEM */}
                {modal==='account' && <ModalContainer size='large' content={<AccountBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal.bind(this)} />} closeModal={this.props.closeModal.bind(this)} />}
                {modal==='message' && <ModalContainer size='large' content={<MessageBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal.bind(this)} />} closeModal={this.props.closeModal.bind(this)} />}
                {modal==='profile' && <ModalContainer size='small' content={<ProfileBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal.bind(this)} />} closeModal={this.props.closeModal.bind(this)} />}


                {modal==='rates' && <ModalContainer size='fullscreen' content={<RatesBody modal screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal.bind(this)} />} closeModal={this.props.closeModal.bind(this)} />}
                {modal==='client' && <ModalContainer size='large' content={<ClientBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal.bind(this)} />} closeModal={this.props.closeModal.bind(this)} />}
                {modal==='setting' && <ModalContainer size='fullscreen' content={<SettingBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal.bind(this)} />} closeModal={this.props.closeModal.bind(this)} />}

                {module=='dashboard' && <AdminBodyDashboard screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow.bind(this)} module={module} mode={mode} mini={mini} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} modal={modal}  params={params} />}
                {module=='stream' && <AdminBodyOutbound screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow.bind(this)} module={module} mode={mode} mini={mini} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} modal={modal}  params={params} />}
                {module=='flow' && <AdminBodyInbound screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow.bind(this)} module={module} mode={mode} mini={mini} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} modal={modal}  params={params} />}
                {module=='talk' && <AdminBodyInappvoice screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow.bind(this)} module={module} mode={mode} mini={mini} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} modal={modal}  params={params} />}
                {module=='ring' && <AdminBodyOfficephone screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow.bind(this)} module={module} mode={mode} mini={mini} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} modal={modal}  params={params} />}
                {module=='middleware' && <AdminBodyMiddleware screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow.bind(this)} module={module} mode={mode} mini={mini} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} modal={modal}  params={params} />}

                {/* {module=='ticket' && <AdminBodyTicket screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow.bind(this)} module={module} mode={mode} mini={mini} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} modal={modal}  params={params} />} */}
                {/* {module=='knowledge' && <AdminBodyKnowledge screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow.bind(this)} module={module} mode={mode} mini={mini} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} modal={modal}  params={params} />} */}
                {/* {module=='customer' && <AdminBodyCustomer screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow.bind(this)} module={module} mode={mode} mini={mini} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} modal={modal}  params={params} />} */}

                {module=='halo' && <AdminBodyHalo screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow.bind(this)} module={module} mode={mode} mini={mini} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} modal={modal}  params={params} />}
              </div>
            </div>
        </div>
      </div>
    )
  }
}

export default AdminBody