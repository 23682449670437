import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Table } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import DismissableMessage from '../common/DismissableMessage'
import {genderOptions, maritalOptions, occupationOptions, revenueOptions, expenseOptions, homestayOptions, vehicleOptions, customerOptions, identificationOptions}  from '../common/StaticMasterData'
import MenuTabular from '../common/MenuTabular'

import { SubmissionError, Field, reduxForm, getFormValues, formValueSelector } from 'redux-form'
import {ImageUploadField, LabelSelectField, LabelInputField, InputField, CheckboxField, NumberField, DatePickerField, SelectField, TextAreaField } from '../validation/componentrsui'
import { required, confirmedPassword, phoneNumberID, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetCustomer, getCustomer,updateCustomer, createCustomer } from "../../actions/halo/customerAction"

//CONNECT REDUX STORE
const selector = formValueSelector('CustomerForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    customerStore: state.customerStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetCustomer:(objParam) => dispatch(resetCustomer(objParam)),
    getCustomer:(objParam) => dispatch(getCustomer(objParam)),
    createCustomer:(objParam) => dispatch(createCustomer(objParam)),
    updateCustomer:(objParam) => dispatch(updateCustomer(objParam)),
  }
}

const panes = (param)=>{
  const menu= [
    {
      menuItem: { key: '4', icon: 'envelope open outline', content: 'Billing Address' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '5', icon: 'clone outline', content: 'Subscription' },
      render: () => 
      <></>
    },
    {
      menuItem: { key: '6', icon: 'file alternate outline', content: 'Transaction' },
      render: () => 
      <></>
    },
    // {
    //   menuItem: { key: '4', icon: 'snowflake outline', content: 'Behaviour' },
    //   render: () => 
    //   <></>
    // },
  ]
  if (param.custType=='Personal') {
    return([
      {
        menuItem: { key: '1', icon: 'user circle', content: 'Personal' },
        render: () => 
        <></>
      },
      ...menu
    ])
  } else if (param.custType=='Corporate') {
    return([
      {
        menuItem: { key: '2', icon: 'building outline', content: 'Corporate' },
        render: () => 
        <></>
      },
      ...menu
    ])
  } else if (param.custType=='Merchant') {
    return([
      {
        menuItem: { key: '1', icon: 'user circle', content: 'Personal' },
        render: () => 
        <></>
      },
      {
        menuItem: { key: '3', icon: 'cart plus', content: 'Merchant' },
        render: () => 
        <></>
      },
      ...menu
    ])
  } else {
    return([
      {
        menuItem: { key: '1', icon: 'user circle', content: 'Personal' },
        render: () => 
        <></>
      },
      {
        menuItem: { key: '2', icon: 'building outline', content: 'Corporate' },
        render: () => 
        <></>
      },
      {
        menuItem: { key: '3', icon: 'cart plus', content: 'Merchant' },
        render: () => 
        <></>
      },
      ...menu
    ])
  }
};

class CustomerForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      tab: 0,
    }
  }

  componentDidMount(){
    this.props.resetCustomer(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getCustomer({customerId: this.props.customerId})
    }
  }

  componentDidUpdate(prev) {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.customerStore.updateCustomer!=undefined ? this.props.customerStore.updateCustomer : {}) : (this.props.customerStore.createCustomer!=undefined ? this.props.customerStore.createCustomer : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
    
    if (prev.custType!=this.props.custType) {
      this.setState({
        tab: 0
      })
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    mode==2 ? this.props.updateCustomer({...values}) : this.props.createCustomer({...values})
    
  }

  setMode(e, d) {
    // console.log(d.activeIndex)
    this.setState({tab: d.activeIndex})
  }

  render() {
    const { tab }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.customerStore.updateCustomer!=undefined ? this.props.customerStore.updateCustomer : {}) : (this.props.customerStore.createCustomer!=undefined ? this.props.customerStore.createCustomer : {}))
    const { data }= this.props.customerStore.getCustomer!=undefined ? this.props.customerStore.getCustomer : {}
    
    return (
      <div style={{padding: '1em 1em', ...style}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            {false && <div>
            <Form.Group widths='equal' >
              <Form.Field>
                <Field name='avatar' component={ImageUploadField}
                width={200} 
                height={200} 
                aspectRation={1}
                />
              </Form.Field>
            </Form.Group>
            <Divider hidden/>
            </div>
            }
            {/* <Form.Group widths='16'>
              <Field name='isEnabled' component={CheckboxField}
              width={8}
              toggle
              caption='Enabled'
              label='Enable Now !'
              placeholder='Status enable/disabled' />
              <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' />
            </Form.Group> */}
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              width={4}
              label='Customer Name'
              placeholder='Customer Name' />
              <Field name='custRef' component={InputField}
              width={4}
              label='CIF / Cust. Ref'
              placeholder='Cust. Ref. ID' />
              <Form.Field width={4} />
              <Field name='isEnabled' component={CheckboxField}
              width={4}
              toggle
              caption='Enabled'
              label='Enable Now !'
              placeholder='Status enable/disabled' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='contractRef' component={InputField}
              width={4}
              label='Contract Ref'
              placeholder='Cust. Contract Ref.' />
              <Field name='effectiveDate' component={DatePickerField}
              width={4}
              label='Effective Date'
              placeholder='Effective Date' />
              <Field name='dataOrigin' component={InputField}
              width={4}
              label='Data Source'
              placeholder='Data Source' />
              <Field name='dataMaturity' component={InputField}
              width={4}
              fluid
              label='Data Maturity'
              placeholder='Data Maturity' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='descript' component={TextAreaField}
              width={16}
              label='Contact Description'
              placeholder='Contact Description / Information' />
            </Form.Group>
          </Segment>
          <Segment style={{background: '#faf5ec'}}>
            <Form.Group widths='equal'>
              <Form.Field width={12}>
                <Header as='h5'>
                  <Icon name='fax' style={{fontSize: '1em'}}/>
                  <Header.Content>Contact</Header.Content>
                </Header>
              </Form.Field>
              <Field name='custType' component={LabelSelectField} 
              width={4}
              options={customerOptions()}
              label='Type'
              placeholder='Customer Type' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='whatsapp' component={InputField} 
              validate={[phoneNumberID]}
              label='Whatsapp'
              placeholder='Whatsapp' />
              <Field name='mobile' component={InputField}
              validate={[phoneNumberID]}
              label='Mobile'
              placeholder='Handphone' />
              <Field name='homePhone' component={InputField}
              validate={[phoneNumberID]}
              label='Home Phone'
              placeholder='Home Phone' />
              <Field name='officePhone' component={InputField}
              validate={[phoneNumberID]}
              label='Office Phone'
              placeholder='Office Phone' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='email' component={InputField}
              label='Email'
              placeholder='Email' />
              <Field name='twitter' component={InputField}
              fluid
              label='Twitter'
              placeholder='Twitter' />
              <Field name='instagram' component={InputField}
              fluid
              label='Instagram'
              placeholder='Instagram' />
              <Field name='facebook' component={InputField}
              fluid
              label='Facebook'
              placeholder='Facebook' />
            </Form.Group>
            {/* <Form.Group widths='equal'>
              <Field name='telegram' component={InputField}
              label='Telegram'
              placeholder='Telegram' />
            </Form.Group> */}
            {(!this.props.custType || this.props.custType=='Personal') && <>
              <Header as='h5' style={{fontWeight: 'bold'}}>
                <Header.Content>Emergency / Relatives</Header.Content>
              </Header>
              <Form.Group widths='equal'>
                <Field name='emergencyName' component={InputField}
                label='Emergency Contact / Name'
                placeholder='Emergency Contact / Name' />
                <Field name='otherName' component={InputField}
                label='Other Contact / Name'
                placeholder='Other Contact / Name' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='emergencyPhone' component={InputField}
                validate={[phoneNumberID]}
                label='Emergency Phone'
                placeholder='Emergency Phone' />
                <Field name='emergencyRelationship' component={InputField}
                label='Emergency Relationship'
                placeholder='Emergency contact relationship' />
                <Field name='otherPhone' component={InputField}
                validate={[phoneNumberID]}
                label='Other Phone'
                placeholder='Other Phone' />
                <Field name='otherRelationship' component={InputField}
                label='Other Relationship'
                placeholder='Other contact relationship' />
              </Form.Group>
            </>}
          </Segment>

          <Divider hidden />
          <MenuTabular activeIndex={tab} panes={panes({custType: this.props.custType})} style={{width: '100%', marginBottom: '-1.2em', marginTop: '1em'  }} renderActiveOnly={true} onTabChange={this.setMode.bind(this)} />

          {(!this.props.custType || this.props.custType=='Personal' || this.props.custType=='Merchant') && tab==0 && <>
            <Segment>
              <Header as='h5'>
                <Icon name='user circle' style={{fontSize: '1em'}}/>
                <Header.Content>Personal</Header.Content>
              </Header>
              <Form.Group widths='equal'>
                <Field name='gender' component={SelectField}
                fluid
                options={genderOptions}
                label='Gender'
                placeholder='L/P' />
                <Field name='birthDate' component={DatePickerField}
                label='Birth Date'
                placeholder='Birth Date' />
                <Field name='children' component={InputField}
                label='Children'
                placeholder='Children' />
                <Field name='marital' component={SelectField}
                fluid
                options={maritalOptions}
                label='Marital'
                placeholder='Marital' />
              </Form.Group>
            </Segment>
            <Segment>
              <Header as='h5'>
                <Icon name='map marker alternate' style={{fontSize: '1em'}}/>
                <Header.Content>Address</Header.Content>
              </Header>
              <Form.Group widths='equal'>
                <Field name='address' component={InputField}
                fluid
                label='Address'
                placeholder='Address' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='city' component={InputField}
                fluid
                placeholder='City' />
                <Field name='province' component={InputField}
                fluid
                placeholder='Province' />
                <Field name='country' component={InputField}
                fluid
                placeholder='Country' />
                <Field name='zipcode' component={InputField}
                fluid
                placeholder='Zipcode' />
              </Form.Group>
            </Segment>
            <Segment>
              <Header as='h5'>
                <Icon name='closed captioning outline' style={{fontSize: '1em'}}/>
                <Header.Content>Financial</Header.Content>
              </Header>
              <Form.Group widths='equal'>
                <Field name='occupation' component={SelectField}
                options={occupationOptions}
                label='Occupation'
                placeholder='Occupation' />
                <Field name='jobTitle' component={InputField}
                fluid
                label='Job Title'
                placeholder='Job Title' />
                <Field name='jobDepartment' component={InputField}
                fluid
                label='Department'
                placeholder='Department' />
                <Field name='jobCompany' component={InputField}
                fluid
                label='Company'
                placeholder='Company' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='revenue' component={SelectField}
                options={revenueOptions}
                label='Revenue'
                placeholder='Revenue' />
                <Field name='expense' component={SelectField}
                options={expenseOptions}
                label='Expense'
                placeholder='Expense' />
                <Field name='homestay' component={SelectField}
                options={homestayOptions}
                label='Homestay'
                placeholder='Homestay' />
                <Field name='vehicle' component={SelectField}
                options={vehicleOptions}
                label='Vehicle'
                placeholder='Vehicle' />
                {/* <Field name='creditcard' component={InputField}
                label='Credit Card'
                placeholder='Credit Card' /> */}
              </Form.Group>
            </Segment>
          </>}

          {((!this.props.custType && tab==1) || (this.props.custType=='Corporate' && tab==0)) && <>
            <Segment>
              <Header as='h5'>
                <Icon name='building outline' style={{fontSize: '1em'}}/>
                <Header.Content>Corporate</Header.Content>
              </Header>
              <Form.Group widths='16'>
                <Field name='company' component={InputField}
                width={8}
                label='Corporate / Name'
                placeholder='Company' />
                <Field name='brand' component={InputField}
                width={4}
                label='Brand / Code'
                placeholder='Brand / Code' />
                <Field name='business' component={InputField}
                width={4}
                label='Business Stream'
                placeholder='Business Stream' />
              </Form.Group>
            </Segment>
            <Segment>
              <Header as='h5'>
                <Icon name='map marker alternate' style={{fontSize: '1em'}}/>
                <Header.Content>Address</Header.Content>
              </Header>
              <Form.Group widths='equal'>
                <Field name='address' component={InputField}
                fluid
                label='Address'
                placeholder='Address' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='city' component={InputField}
                fluid
                placeholder='City' />
                <Field name='province' component={InputField}
                fluid
                placeholder='Province' />
                <Field name='country' component={InputField}
                fluid
                placeholder='Country' />
                <Field name='zipcode' component={InputField}
                fluid
                placeholder='Zipcode' />
              </Form.Group>
            </Segment>
          </>}

          {((!this.props.custType && tab==2) || (this.props.custType=='Merchant' && tab==1)) && <>
            <Segment>
              <Header as='h5'>
                <Icon name='cart plus' style={{fontSize: '1em'}}/>
                <Header.Content>Merchant</Header.Content>
              </Header>
              

              <Form.Group widths='equal'>
                <Field name='merchantCode' component={InputField}
                label='Merchant Code'
                placeholder='Merchant Code' />
                <Field name='merchantName' component={InputField}
                fluid
                label='Merchant Name'
                placeholder='Merchant Name' />
                <Field name='merchantWarehouse' component={InputField}
                fluid
                label='Merchant Warehouse'
                placeholder='Merchant Warehouse' />
                <Field name='merchantAcquisition' component={InputField}
                fluid
                label='Merchant Acquisition'
                placeholder='Merchant Acquisition' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='previousAov' component={InputField}
                label='Previous AOV'
                placeholder='Previous Average Order Value' />
                <Field name='previousFreqOrder' component={InputField}
                fluid
                label='Previous Freq. Order'
                placeholder='Previous Freq. Order' />
                <Field name='currentAov' component={InputField}
                fluid
                label='Current AOV'
                placeholder='Previous Freq. Order' />
                <Field name='currentFreqOrder' component={InputField}
                fluid
                label='Current Freq. Order'
                placeholder='Current Freq. Order' />
              </Form.Group>
            </Segment>
            <Segment attached={true}>
              <Header as='h5'>
                <Icon name='tag' style={{fontSize: '1em'}}/>
                <Header.Content>Retention</Header.Content>
              </Header>
              <Form.Group widths='equal'>
                <Field name='project' component={InputField}
                fluid
                label='Project'
                placeholder='Project' />
                <Field name='retentionGmvStatus' component={InputField}
                fluid
                label='Retention GMV'
                placeholder='Retention Gross Merchant Value' />
                <Field name='retentionOrderStatus' component={InputField}
                fluid
                label='Retention Order Status'
                placeholder='Retention Order Status' />
                <Field name='retentionAppStatus' component={InputField}
                fluid
                label='Retention App Status'
                placeholder='Retention App Status' />
              </Form.Group>
            </Segment>
            <Segment attached='bottom'>
              <Form.Group widths='equal'>
                <Field name='retentionPhysicalSegment' component={InputField}
                fluid
                label='Retention Physical Segment'
                placeholder='Retention Physical Segment' />
                <Field name='retentionJourney' component={InputField}
                fluid
                label='Retention Journey'
                placeholder='Retention Journey' />
                <Field name='retentionAccess' component={InputField}
                fluid
                label='Retention Access'
                placeholder='Retention Access' />
                <Field name='retentionSegment' component={InputField}
                fluid
                label='Retention Segment'
                placeholder='Retention Segment' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='retentionCountTrx' component={InputField}
                fluid
                label='Retention Count Trx'
                placeholder='Retention Count Trx' />
                <Field name='retentionGmvAccumulated' component={InputField}
                fluid
                label='Retention Accumulated GMV'
                placeholder='Retention Accumulated Gross Merchant Value' />
                <Field name='retentionCountTrxUngroup' component={InputField}
                fluid
                label='Retention Count Trx Ungroup'
                placeholder='Retention Count Trx Ungroup' />
                <Field name='retentionUninstallStatus' component={InputField}
                fluid
                label='Retention Uninstall Status'
                placeholder='Retention Uninstall Status' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='retentionAppEngine' component={InputField}
                fluid
                label='Retention App Engine'
                placeholder='Retention App Engine' />
                <Field name='retentionOutboundCall' component={InputField}
                fluid
                label='Retention Outbound Called'
                placeholder='Retention Outbound Called' />
                <Field name='retentionRfm' component={InputField}
                fluid
                label='Retention RFM'
                placeholder='Retention Recency, Frequency, and Monetary' />
                <Field name='retentionChurnProgress' component={InputField}
                fluid
                label='Retention Churn Progress'
                placeholder='Retention Churn Progress' />
              </Form.Group>
            </Segment>
          </>}

          {((!this.props.custType && tab==3) || (this.props.custType=='Merchant' && tab==2) || (this.props.custType!='Merchant' && tab==1)) && <>
          <Segment>
            <Header as='h5'>
              <Icon name='file alternate outline' style={{fontSize: '1em'}}/>
              <Header.Content>Tax Information</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='taxIdentification' component={SelectField}
              width={4}
              options={identificationOptions}
              label='Tax Identification'
              placeholder='NPWP / NIK' />
              {this.props.taxIdentification=='NPWP' && 
              <Field name='taxNPWP' component={InputField}
              width={4}
              label='No. NPWP'
              placeholder='NPWP' />}
              {this.props.taxIdentification=='NIK' && 
              <Field name='taxNIK' component={InputField}
              width={4}
              label='No. NIK'
              placeholder='NIK' />}
            </Form.Group>

            <Header as='h5'>
              <Icon name='address card outline' style={{fontSize: '1em'}}/>
              <Header.Content>Billing Contact</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='billName' component={InputField}
              width={8}
              label='Name'
              placeholder='Full Name' />
              <Field name='billPosition' component={InputField}
              width={4}
              label='Job Position'
              placeholder='Job Position' />
              <Field name='billCorporate' component={InputField}
              width={4}
              label='Company'
              placeholder='Company' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='billAddress' component={InputField}
              label='Address'
              placeholder='Address' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='billCity' component={InputField}
              width={4}
              placeholder='City' />
              <Field name='billProvince' component={InputField}
              width={4}
              placeholder='Province' />
              <Field name='billCountry' component={InputField}
              width={4}
              placeholder='Country' />
              <Field name='billZipcode' component={InputField}
              width={4}
              placeholder='Zipcode' />
            </Form.Group>
          </Segment>
          </>}

          {((!this.props.custType && tab==4) || (this.props.custType=='Merchant' && tab==3) || (this.props.custType!='Merchant' && tab==2)) && <>
            <Segment>
              <Header as='h5'>
                <Icon name='clone outline' style={{fontSize: '1em'}}/>
                <Header.Content>Subscription</Header.Content>
              </Header>
              <Table unstackable selectable columns='16' celled striped basic='very'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>#</Table.HeaderCell>
                    <Table.HeaderCell width='8'>
                      <Header as='h5' color='blue'>
                        PRODUCT / SERVICE
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='4'>
                      <Header as='h5' color='blue'>
                        SUBSCRIPTION
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='4'>
                      <Header as='h5' color='blue'>
                        TOTAL VALUE
                      </Header>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  { data && data.subscriptions ? data.subscriptions.map((item,i)=>{
                      return(
                        <Table.Row key={++i}>
                          <Table.Cell>{i}</Table.Cell>
                          <Table.Cell>
                            <Header as='h5'>
                              <Header.Content>
                                {item.product && item.product.name}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell>
                            <Header as='h5'>
                              <Header.Content>
                                {item.subsDate!=null ? format(parse(item.subsDate, 'yyyy-MM-dd', new Date()), 'dd / MMM, yyyy') : ''}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell style={{textAlign: 'right'}}>
                            <Header as='h5'>
                              <Header.Content>
                                {}
                                Rp. {numeral(item.amount || 0).format('0,0')}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                        </Table.Row>
                      )
                    }) : <Table.Row><Table.Cell colSpan={3}></Table.Cell></Table.Row>
                  }
                </Table.Body>
              </Table>
            </Segment>
          </>}

          {((!this.props.custType && tab==5) || (this.props.custType=='Merchant' && tab==4) || (this.props.custType!='Merchant' && tab==3)) && <>
            <Segment>
              <Header as='h5'>
                <Icon name='file alternate outline' style={{fontSize: '1em'}}/>
                <Header.Content>Transaction</Header.Content>
              </Header>
              <Table unstackable selectable columns='16' celled striped basic='very'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>#</Table.HeaderCell>
                    <Table.HeaderCell width='5'>
                      <Header as='h5' color='blue'>
                        CAMPAIGN
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='4'>
                      <Header as='h5' color='blue'>
                        DELIVERY
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='4'>
                      <Header as='h5' color='blue'>
                        TOT. ATTEMPT
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='3'>
                      <Header as='h5' color='blue'>
                        RESULT
                      </Header>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  { data && data.transacts ? data.transacts.map((item,i)=>{
                      return(
                        <Table.Row key={++i}>
                          <Table.Cell>{i}</Table.Cell>
                          <Table.Cell>
                            <Header as='h5'>
                              <Header.Content>
                                <Header.Subheader>{item.campaign.campaignType}</Header.Subheader>
                                {item.campaign.name}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell>
                          <Header as='h5'>
                            <Header.Content>
                              <Header.Subheader>{item.campaign.startTime}-{item.campaign.endTime}</Header.Subheader>
                              {format(parse(item.campaign.startDate, 'yyyy-MM-dd', new Date()), 'dd / MMM')} - {format(parse(item.campaign.endDate, 'yyyy-MM-dd', new Date()), 'dd / MMM, yyyy')}
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                          <Table.Cell>
                            <Header as='h5'>
                              <Header.Content>
                                {item.totAttempt==0 ? '0%' : (item.totEngaged / item.totAttempt)} CAR
                                <Header.Subheader>{item.totAttempt} Attempts, {item.totDelivered} Delivered, {item.totEngaged} Contacted</Header.Subheader>
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell>
                            <Header as='h5'>
                              <Header.Content>
                                {item.result}
                                <Header.Subheader>{item.transactStamp!=null ? format(parse(item.subsDate, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM, yyyy') : 'No recent date'}</Header.Subheader>
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                        </Table.Row>
                      )
                    }) : <Table.Row><Table.Cell colSpan={3}></Table.Cell></Table.Row>
                  }
                </Table.Body>
              </Table>
            </Segment>
          </>}

          {/* {tab==4 && <>
          <Segment>
            <Header as='h5'>
              <Icon name='snowflake outline' style={{fontSize: '1em'}}/>
              <Header.Content>Behaviour</Header.Content>
            </Header>
            
          </Segment>
          </>} */}

          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Customer' : 'Insert Customer') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.customerStore.getCustomer!=undefined && state.customerStore.getCustomer.progress==1 ? state.customerStore.getCustomer.data : {}),
      formValues: getFormValues('CustomerForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      custType: selector(state, 'custType'),

      taxIdentification: selector(state, 'taxIdentification'),
    }),
    { load: getCustomer }
  )
  (reduxForm({
    form: 'CustomerForm',	// a unique identifier for this form
  })(CustomerForm)
  )
)
