import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider } from 'semantic-ui-react'

import SettingForm from '../../libraries/halo/SettingForm'
import SettingSearch from '../../libraries/halo/SettingSearch';
import SettingBrowse from '../../libraries/halo/SettingBrowse';


class SettingBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 2, //1: browse, 2: edit, 3: insert, 4: delete
        settingId: 1,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, settingId: id})
    }

    render() {
      const {mode, settingId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div style={{display: 'flex'}}>
            <div style={{width: 250, alignItems: 'stretch', background: '#fafafa', marginTop: '.5em'}}>
              <SettingSearch />
              <SettingBrowse screenHeight={screenHeight} settingId={settingId} setMode={this.setMode.bind(this)} />
            </div>
            <div style={{flex: '1'}}>
              <div style={{margin: '0 0 0 0', padding: '1em 1em', background: 'rgba(240, 240, 240, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
                <Header as='h4' style={{marginTop: '.25em'}}>
                  <Icon name='cog' style={{fontSize: '1em'}} />
                  <Header.Content>
                    Realm / Client Setting
                  </Header.Content>
                </Header>
              </div>
              <div style={{margin: '1em 1em 1em 1em'}}>
                <SettingForm group='admin' mode={mode} screenWidth={screenWidth} screenHeight={screenHeight} settingId={settingId} setMode={this.setMode.bind(this)} />
              </div>
            </div>
          </div>
        </Container>
      )
    }
}

export default SettingBody
