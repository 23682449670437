import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider } from 'semantic-ui-react'

import InformationBoard from '../../libraries/whatsapp/InformationBoard';


class InformationBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 2, //1: browse, 2: edit, 3: insert, 4: delete
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, coachId: id})
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container style={{padding: '1.5em', color:'#fff', background: this.props.mode ? '#333333' : '#0078d4'}}>
          <InformationBoard mode={this.props.mode}/>
        </Container>
      )
    }
}

export default InformationBody
