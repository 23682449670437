import React from 'react';
import ReactDOM from 'react-dom';

import { Link } from 'react-router-dom';
import { Accordion, Tab, Input, Card, Form, Select, Radio, Label, Container, Divider, Dropdown, Grid, Header, Image, List, Menu, Segment, Button, Icon, Table, Popup } from 'semantic-ui-react'

import { sessionOptions, lockedOptions, resultOptions, limitOptions, delimiterOptions, cdrOptions, transactionOptions, sequenceOptions, periodOptions, fieldTargetOptions, fieldCdrOptions, fieldMdrOptions, fieldTrxOptions, gatewayOptions, dispatchOptions, ratioOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'
import MenuTabular from '../common/MenuTabular'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { ImageUploadField, AudioUploadField, InputField, LabelInputField, CheckboxField, SelectField, RadioField, TextAreaField, WysiwygField, NumberField, SliderField } from '../validation/componentrsui'
import { required, phoneNumberID, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { listParams } from "../../actions/halo/masterAction"
import { resetForm, echoWhatsapp} from "../../actions/auth/authAction"
import { resetSetting, getSetting, updateSetting } from "../../actions/halo/settingAction"

//CONNECT REDUX STORE
const selector = formValueSelector('SettingForm')

const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
    masterStore: state.masterStore,
    settingStore: state.settingStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    echoWhatsapp:(objParam) => dispatch(echoWhatsapp(objParam)),
    
    resetForm:(objParam) => dispatch(resetForm(objParam)),

    listParams:(objParam) => dispatch(listParams(objParam)),

    resetSetting:(objParam) => dispatch(resetSetting(objParam)),
    getSetting:(objParam) => dispatch(getSetting(objParam)),
    updateSetting:(objParam) => dispatch(updateSetting(objParam)),
  }
};

const panesChannel = (param)=>{
  return([
    // {
    //   menuItem: { key: '0', icon: 'microphone', content: 'Voice' },
    //   render: () => 
    //   <></>
    // },
    // {
    //   menuItem: { key: '1', icon: 'volume down', content: 'IVR' },
    //   render: () => 
    //   <></>
    // },
    // {
    //   menuItem: { key: '2', icon: 'sticky note outline', content: 'SMS' },
    //   render: () => 
    //   <></>
    // },
    // {
    //   menuItem: { key: '3', icon: 'envelope outline', content: 'Email' },
    //   render: () => 
    //   <></>
    // },
    {
      menuItem: { key: '7', icon: 'whatsapp', content: 'Whatsapp' },
      render: () => 
      <></>
    },
    // {
    //   menuItem: { key: '8', icon: 'telegram', content: 'Telegram' },
    //   render: () => 
    //   <></>
    // },
    // {
    //   menuItem: { key: '4', icon: 'twitter', content: 'Twitter' },
    //   render: () => 
    //   <></>
    // },
    // {
    //   menuItem: { key: '5', icon: 'facebook', content: 'Facebook' },
    //   render: () => 
    //   <></>
    // },
    // {
    //   menuItem: { key: '6', icon: 'instagram', content: 'Instagram' },
    //   render: () => 
    //   <></>
    // },
  ])
};

class SettingForm extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      tabChannel: 7,
      tabReport: 0,
      tabModule: 0,
      settingId: 1,

      // showModule: 0,
      showChannel: 1,
      showSending: 0,
      showApi: 0,

      expandMobile: false,
    }
  }

  componentDidMount() {
    //console.log('!!! DID MOUNT PROPS: ', this.props)
    this.props.resetSetting();
    this.props.getSetting({settingId: this.props.settingId})

    this.props.listParams({clientId: this.props.settingId})
  }

  componentDidUpdate(prevProps) {
    const { settingId }= this.state
    const { progress }= (this.props.authStore.echoWhatsapp!=undefined ? this.props.authStore.echoWhatsapp : {})
    
    if (progress==1 || progress==2) {
      setTimeout(()=>{
        this.props.resetForm('echoWhatsapp')
      }, 5000)
    }

    if (settingId!=this.props.settingId) {
      this.setState({
        settingId: this.props.settingId
      })
      // console.log(data.settingId, this.props.settingId)
      this.props.getSetting({settingId: this.props.settingId})
      this.props.listParams({clientId: this.props.settingId})
    }
    
  }

  resetForm() {
    const { reset, mode } = this.props
    reset();
  }

  onSubmit(values) {
    const { mode} = this.props
    
    if(mode==2) { //UPDATE
      this.props.echoWhatsapp({
        boxOriginating: values['boxOriginating'],
        boxTerminating: values['boxTerminating'],
        boxContent: values['boxContent'],
      });

      console.log('!!! SENDING MESSAGE', {
      boxOriginating: values['boxOriginating'],
      boxTerminating: values['boxTerminating'],
      boxContent: values['boxContent'],
      })
    }
  }

  showMode(v) {
    if (v=='channel') {
      this.setState({
        showChannel: this.state.showChannel==1 ? 0 : 1,
        showModule: 0,
        showApi: 0,
        showSending: 0,
      })
    } else if (v=='module') {
      this.setState({
        showChannel: 0,
        showModule: this.state.showModule==1 ? 0 : 1,
        showApi: 0,
        showSending: 0,
      })
    } else if (v=='api') {
      this.setState({
        showChannel: 0,
        showModule: 0,
        showApi: this.state.showApi==1 ? 0 : 1,
        showSending: 0,
      })
    } else if (v=='sending') {
      this.props.dispatch(change('SettingForm', 'boxOriginating', this.props.waMobile_1))
      this.props.dispatch(change('SettingForm', 'boxTerminating', this.props.waMobile_1))
      this.setState({
        showChannel: 0,
        showModule: 0,
        showApi: 0,
        showSending: this.state.showSending==1 ? 0 : 1,
      })
    }
  }

  expandMobile() {
    this.setState({
      expandMobile: !this.state.expandMobile
    })
  }

  render() {
    const { tabChannel, tabReport, tabModule, showModule, showChannel, showApi, showSending }= this.state
    const { error, handleSubmit, pristine, reset, submitting, group, mode, screenWidth, screenHeight }=this.props
    const { progress, code, text }= (this.props.authStore.echoWhatsapp!=undefined ? this.props.authStore.echoWhatsapp : {})

    const { svcCampaigns, svcRatios, svcChannels }= this.props.masterStore.listParams && this.props.masterStore.listParams.data ? this.props.masterStore.listParams.data : {}

    return(
      <div style={{marginTop: '-1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          {/* <Segment>
            <Form.Group widths='16'>
              <Field name='appName' width={8} component={InputField}
              readOnly= {group!='admin'}
              label='Application'
              placeholder='Application' />
              <Field name='appVersion' width={4} component={InputField}
              readOnly= {group!='admin'}
              label='Version'
              placeholder='Version' />
              <Field name='appRelease' width={4} component={InputField}
              readOnly= {group!='admin'}
              label='Release'
              placeholder='Release' />
            </Form.Group>
          </Segment> */}
          <div style={{display: 'flex', marginBottom: '1em'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' style={{fontWeight: 'bold', marginTop: '1em'}} color={(showChannel==1 && 'blue') || 'black'}>
                <Header.Content>
                  Channel / Media Delivery
                  <Header.Subheader>Channel stream configuration</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div style={{marginTop: '1.5em', marginLeft: '1em'}}>
              <Icon name={(showChannel==1 && 'toggle on') || 'toggle off'} color={(showChannel==1 && 'blue') || 'grey'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.showMode.bind(this, 'channel')} />
            </div>
          </div>
          <Divider />
          {showChannel==1 && <>
            {group=='admin' || true && <>
            <Segment>
              <Header as='h5'>
                Gateway Configuration
              </Header>
              <Form.Group widths='16'>
                <Field name='waGateway' component={SelectField}
                readOnly
                width={8}
                options={gatewayOptions('wa')}
                label='WA Gateway'
                placeholder='Gateway' />
                <Field name='waCredential' component={InputField}
                fluid
                readOnly
                width={8}
                label='WA Credential'
                placeholder='Credential / Secret' />
              </Form.Group>
              {this.props.waGateway=='Mobile' && <>
                {/* <Form.Group widths='equal'>
                  <Field name='waAttemptMax' component={InputField}
                  type= 'number'
                  label='Max. Sent Attempt'
                  placeholder='Max. attempt for sending message' />
                  <Field name='waDailyMax' component={InputField}
                  type= 'number'
                  label='Max. Daily Sent'
                  placeholder='Max daily sent from same sender' />
                  <Field name='waSentDelay' component={InputField}
                  type= 'number'
                  label='Delay of Sending (Sec)'
                  placeholder='Delay foreach sending message' />
                  <Field name='waClearDelay' component={InputField}
                  type= 'number'
                  label='Delay of Chat Clearance (Sec)'
                  placeholder='Delete chat after read or failed' />
                </Form.Group> */}

                <Form.Group widths='equal'>
                  <Field name='waMobile_1' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #1'
                  validate= {[phoneNumberID]}
                  placeholder='Mobile number' />
                  <Field name='waMobile_2' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #2'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                  <Field name='waMobile_3' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #3'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                  <Field name='waMobile_4' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #4'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                </Form.Group>
                <Divider horizontal><Icon circular inverted color='yellow' style={{cursor: 'pointer'}} name={this.state.expandMobile ? 'angle up' : 'angle down'} onClick={this.expandMobile.bind(this)}/></Divider>
                {this.state.expandMobile && <>
                <Form.Group widths='equal'>
                  <Field name='waMobile_5' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #5'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                  <Field name='waMobile_6' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #6'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                  <Field name='waMobile_7' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #7'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                  <Field name='waMobile_8' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #8'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='waMobile_9' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #9'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                  <Field name='waMobile_10' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #10'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                  <Field name='waMobile_11' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #11'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                  <Field name='waMobile_12' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #12'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='waMobile_13' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #13'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                  <Field name='waMobile_14' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #14'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                  <Field name='waMobile_15' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #15'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                  <Field name='waMobile_16' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #16'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='waMobile_17' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #17'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                  <Field name='waMobile_18' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #18'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                  <Field name='waMobile_19' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #19'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                  <Field name='waMobile_20' component={InputField}
                  fluid
                  readOnly
                  label='Mobile Sender #20'
                  validate= {[phoneNumberID]}
                  placeholder='Optional number' />
                </Form.Group>
                </>}

                <Divider hidden />
                <Form.Group widths='equal'>
                  <Field name='waMobileFarmServer' component={TextAreaField}
                  readOnly
                  label='Mobile Farm Server (6281390001001, 6281390001002, ...)'
                  placeholder='Optional number from Halo Pagi! farm server, delimited by comma' />
                </Form.Group>
              </>}
              {this.props.waGateway=='Gateway' && 
              <Form.Group widths='equal'>
                <Field name='waEndpoint' component={InputField}
                label='Provider Endpoint'
                placeholder='Provider Endpoint' />
                <Field name='waWebhook' component={InputField}
                label='Webhook Endpoint'
                placeholder='Webhook Endpoint' />
              </Form.Group>}
            </Segment>
            </>}
            {group!='admin' && false && <>
            <Segment style={{paddingBottom: '7em'}}>
              <Header as='h5'>
                <Icon name='warning sign' />
                <Header.Content>
                  Your configuration is handled by system.
                  <Header.Subheader>Currently there is no custom configuration required for Whatsapp delivery.</Header.Subheader>
                </Header.Content>
              </Header>
            </Segment>
            </>}
          </>}

          <div style={{display: 'flex', marginBottom: '1em'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' style={{fontWeight: 'bold', marginTop: '1em'}} color={showApi==1 ? 'blue' : 'black'}>
                <Header.Content>
                  API Connection
                  <Header.Subheader>Module's behaviour and environment</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div style={{marginTop: '1.5em', marginLeft: '1em'}}>
              <Icon name={(showApi==1 && 'toggle on') || 'toggle off'} color={(showApi==1 && 'blue') || 'grey'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.showMode.bind(this, 'api')} />
            </div>
          </div>
          <Divider />
          {showApi==1 && <>
            {(this.props.group=='admin' || this.props.apiKey!=null) && 
            <>
              <Segment>
                <Header as='h5'>
                  <Icon name='code' style={{fontSize: '1em'}}/>
                  <Header.Content>API Integration</Header.Content>
                </Header>
                <Form.Group widths='equal'>
                  <Field name='apiKey' component={InputField}
                  readOnly={this.props.group!='admin'}
                  label='API Key' />
                  <Field name='apiSecret' component={InputField}
                  readOnly
                  label='API Secret' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='apiEndpoint' component={InputField}
                  readOnly={this.props.group!='admin'}
                  label= 'Api Callback / Endpoint (/callback | /webhook)'
                />
                </Form.Group>
                
                {/* <Divider />
                <Header as='h5'>
                  <Icon name='code' style={{fontSize: '1em'}}/>
                  <Header.Content>API Behaviour</Header.Content>
                </Header>
                <Form.Group widths='equal'>
                  <Field name='isPopup' component={CheckboxField}
                  readOnly={this.props.group!='admin'}
                  label='Popup Data as Local Relay on Agent Dashboard'/>
                  <Field name='isCallback' component={CheckboxField}
                  readOnly={this.props.group!='admin'}
                  label='Callback Event (Dial, Pickedup, Hangup)'/>
                  <Field name='isIntegrated' component={CheckboxField}
                  readOnly={this.props.group!='admin'}
                  label='Check When Client API Was Integrated'/>
                </Form.Group> */}
              </Segment>
            </>}
            {(this.props.group!='admin' && this.props.apiKey==null) && 
            <Segment>
              <Header as='h3' color='red'>
                <Icon name='warning sign'/>
                <Header.Content>API Connect Not Available!
                  <Header.Subheader>Whether your Admin is not allowed or configuration has not been granted!</Header.Subheader>
                </Header.Content>
              </Header>
            </Segment>
            }
          </>}

          <div style={{display: 'flex', marginBottom: '1em'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' style={{fontWeight: 'bold', marginTop: '1em'}} color={(showSending==1 && 'blue') || 'black'}>
                <Header.Content>
                  Echoing Message Box
                  <Header.Subheader>Sending message manually for testing purpose</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div style={{marginTop: '1.5em', marginLeft: '1em'}}>
              <Icon name={(showSending==1 && 'toggle on') || 'toggle off'} color={(showSending==1 && 'blue') || 'grey'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.showMode.bind(this, 'sending')} />
            </div>
          </div>
          <Divider />
          {showSending==1 && <>
            <Segment>
              <Header as='h5'>
                Sending Echo Message
              </Header>
              <Form.Group widths='16'>
                <Field name='boxType' component={InputField}
                width={4}
                readOnly
                label='Message Type'
                placeholder='Message Type' />
                <Field name='boxOriginating' component={InputField}
                readOnly
                width={6}
                label='Originating'
                placeholder='Sender / Originating' />
                <Field name='boxTerminating' component={InputField}
                readOnly
                width={6}
                label='Terminating'
                placeholder='Destination / Terminating' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='boxContent' component={TextAreaField}
                rows={5}
                label='Content / Message'
                placeholder='Message content / information' />
              </Form.Group>

              {(progress==1 || progress==2)  && <DismissableMessage style={{margin: '2em 0 0 0'}} mode={this.props.mode} header={'Sending Echo' + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
              <div style={{display: 'flex', marginTop: '1em'}}>
                <div style={{flex: '1'}}>
                  <Button type='reset' size='small' content=' RESET ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
                </div>
                <div>
                  <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} SEND</Button>
                </div>
              </div>
            </Segment>
          </>}
        </Form >
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.settingStore.getSetting!=undefined && state.settingStore.getSetting.progress==1 ? {...state.settingStore.getSetting.data, boxType: 'echo'} : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      userId: selector(state, 'userId'),
      apiKey: selector(state, 'apiKey'),
      apiEndpoint: selector(state, 'apiEndpoint'),
      isPopup: selector(state, 'isPopup'),
      isCallback: selector(state, 'isCallback'),
      isIntegrated: selector(state, 'isIntegrated'),
      
      collectionStageQty: selector(state, 'collectionStageQty'),
      restructuringStageQty: selector(state, 'restructuringStageQty'),
      telesaleStageQty: selector(state, 'telesaleStageQty'),
      verificationStageQty: selector(state, 'verificationStageQty'),
      reportCdrPeriod: selector(state, 'reportCdrPeriod'),
      reportMdrPeriod: selector(state, 'reportMdrPeriod'),
      reportTrxPeriod: selector(state, 'reportTrxPeriod'),
      smsGateway: selector(state, 'smsGateway'),
      waGateway: selector(state, 'waGateway'),
      mailGateway: selector(state, 'mailGateway'),

      waMobile_1: selector(state, 'waMobile_1'),
      boxOriginating: selector(state, 'boxOriginating'),
      boxTerminating: selector(state, 'boxTerminating'),
      boxContent: selector(state, 'boxContent'),
    }),
    { load: getSetting }
  )
  (reduxForm({
    form: 'SettingForm',	// a unique identifier for this form
  })(SettingForm)
  )
)
