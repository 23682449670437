import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Table,
  Label,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"
import { targetStatus } from "../../../libraries/common/StaticMasterData"

import { connect } from "react-redux"
import { browseTarget, statusTarget, requeueTarget } from "../../../actions/stream/targetAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    targetStore: state.targetStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseTarget:(objParam) => dispatch(browseTarget(objParam)),
    statusTarget:(objParam) => dispatch(statusTarget(objParam)),
    requeueTarget:(objParam) => dispatch(requeueTarget(objParam)),
  }
};


class TargetBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {
      remark: false,
    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})
    this.props.browseTarget(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})
    this.props.campaignId && this.props.browseTarget({search: {...(this.props.campaignId ? {campaignId: this.props.campaignId} : {})}})
    !this.props.campaignId && this.props.browseTarget({search: {eventDate: format(new Date(), 'yyyy-MM-dd'), ...search, ...(this.props.campaignId ? {campaignId: this.props.campaignId} : {campaignId: undefined})}, paging: paging})
  }

  onChangeStatus(id, status) {
    this.props.statusTarget({targetId:id, isEnabled: status})
  }

  requeueTarget(id, contact) {
    const { search, paging }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})

    this.props.requeueTarget({targetId:id, contact: contact})
    setTimeout(()=>{
      this.props.browseTarget(
      {
        paging: {...paging},
        search: search
      }
    )}, 1000);
  }

  setRemark() {
    const { remark }= this.state
    this.setState({
      remark: !remark
    })
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { remark }= this.state

    const { data, search, paging }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1'}}>
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                {this.props.setMode!=undefined && <>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </>}
                <Table.HeaderCell width='4'>
                  <Header as='h5' color='blue'>TARGET / CAMPAIGN</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='3' style={{background: '#fffff2'}}>
                  <Header as='h5' color='blue'>LINKED AGENT</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='3'>
                  <Header as='h5' color='blue'>CONTACT</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>PROGRESS</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={4} style={{background: remark ? '#e7feff' : '#fff2f2'}}>
                  <Header as='h5' color='blue'><Icon name={remark ? 'toggle on' : 'toggle off'} color={remark ? 'blue' : 'red'} style={{fontSize: '1.5em', cursor: 'pointer'}} onClick={this.setRemark.bind(this)}/><Header.Content>{remark ? 'REMARK' : 'RESULT'}</Header.Content></Header>
                </Table.HeaderCell>
                {/* <Table.HeaderCell>
                  <Header as='h5' color='blue'></Header>
                </Table.HeaderCell> */}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!=undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      {this.props.setMode!=undefined && <>
                        <Table.Cell textAlign='center'>
                          <Popup header='Edit' trigger={<Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.targetId)}/>} />
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                          <Popup header='Requeue' trigger={<Icon color='red' style={{cursor: 'pointer'}} name='share square' onClick={this.requeueTarget.bind(this, item.targetId, (item.mobile || item.whatsapp || item.email || item.homePhone || item.officePhone))}/>} />
                        </Table.Cell>
                      </>}
                      <Table.Cell style={{_background: '#f4ffff'}}>
                        <Popup inverted header='Engagement' content='Click to show detail engagement of current target.' trigger={
                          <Header as='h5' style={{cursor: 'pointer', fontWeight: 'bold'}} onClick={this.props.openModal.bind(this, 'engagement', item)}>
                            <Icon 
                            color={(item.isEnabled=='0' && 'grey') || (item.status<0 && 'red') || (item.isAchieved && 'blue') || (item.isDelivered && 'teal') || (item.isPrepared && 'yellow') || 'green'} 
                            name='circle thin' 
                            style={{fontSize: '1.1em'}} />
                            <Header.Content>
                              {item.name || 'No Name!'}
                              {item.campaign!=null && <Header.Subheader><span style={{textDecoration: item.campaign.isEnabled=='0' || item.campaign.isExpired!='0' ? 'line-through' : 'none'}}>{item.campaign && item.campaign.name && item.campaign.name.length>50 ? item.campaign.name.substr(0,50) +' ...' : item.campaign.name}</span> 
                              {(item.campaign.isEnabled=='0' && <i> (Disabled)</i>) || (item.campaign.isExpired=='-1' && <i> (Upcoming)</i>) || (item.campaign.isExpired=='1' && <i> (Expired)</i>)}</Header.Subheader>}
                            </Header.Content>
                          </Header>
                        } 
                        position='right center'
                        />
                      </Table.Cell>
                      <Table.Cell style={{background: '#fffff2'}}>
                        <Header as='h5'>
                          <Header.Content>
                            {/* {item.gender} / {item.occupation || 'No info'}  */}
                            <b>{(item.agentId && 'Locked') || (item.tagger ?  'Tags:'+ item.tagger : 'Floating')}</b>
                            <Header.Subheader>{(item.agent && item.agent.name) || item.client || '-'}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          {item.campaign && item.campaign.channels &&
                            <Header.Content>
                              {(item.campaign.channels[0]=='Voice' || item.campaign.channels[0]=='IVR') && ('Phone: '+ (item.mobile || item.homePhone || item.officePhone  || '-'))}
                              {item.campaign.channels[0]=='SMS' && ('SMS: '+ (item.mobile  || '-'))}
                              {item.campaign.channels[0]=='Email' && ('Email: '+ (item.email  || '-'))}
                              {item.campaign.channels[0]=='Whatsapp' && ('WA: '+ (item.whatsapp  || '-'))}
                              {item.campaign.channels[0]=='Telegram' && ('Telegram: '+ (item.telegram  || '-'))}
                              {item.campaign.channels[0]=='Twitter' && ('Twitter: '+ (item.twitter  || '-'))}
                              {item.campaign.channels[0]=='Facebook' && ('FB: '+ (item.facebook  || '-'))}
                              {item.campaign.channels[0]=='Instagram' && ('IG: '+ (item.instagram  || '-'))}
                              <Header.Subheader style={{whiteSpace: 'nowrap'}}>
                                {item.campaign.channels[1] && <>
                                  {(item.campaign.channels[1]=='Voice' || item.campaign.channels[1]=='IVR') && ('Phone: '+ (item.mobile || item.homePhone || item.officePhone || '-'))}
                                  {item.campaign.channels[1]=='SMS' && ('SMS: '+ (item.mobile || '-'))}
                                  {item.campaign.channels[1]=='Email' && ('Email: '+ (item.email || '-'))}
                                  {item.campaign.channels[1]=='Whatsapp' && ('WA: '+ (item.whatsapp || '-'))}
                                  {item.campaign.channels[1]=='Telegram' && ('Telegram: '+ (item.telegram || '-'))}
                                  {item.campaign.channels[1]=='Twitter' && ('Twitter: '+ (item.twitter || '-'))}
                                  {item.campaign.channels[1]=='Facebook' && ('FB: '+ (item.facebook || '-'))}
                                  {item.campaign.channels[1]=='Instagram' && ('IG: '+ (item.instagaram || '-'))}
                                </>}
                                {item.campaign && item.campaign.channels && (item.campaign.channels.join(', ') +' channel only')}
                              </Header.Subheader>
                              <Header.Subheader>
                                <b>{item.jobTitle}</b> {item.jobCompany}
                              </Header.Subheader>
                            </Header.Content>
                          }
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          {/* <Icon name='history' /> */}
                          <Header.Content>
                            <b>{item.isEnabled=='0' && <i>Disabled</i> || (item.totAttempt>=item.maxAttempt && <i>Exceeded</i>) || 'Available'}</b>
                            <Header.Subheader>{item.totAttempt} of {item.maxAttempt} Attempts</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      {remark && 
                      <Table.Cell style={{background: '#e7feff'}}>
                        <Header as='h5'>
                          <div style={{background: (item.status=='1' && '#84dbff') || (item.status=='0' && '#ffb3b3') || '#ddd', textAlign: 'right', display: 'inline-block', width: '4em', padding: '.5em', marginRight: '.5em'}}>{item.progress} %</div>
                          <Header.Content>
                            {item.remark || 'No remark !'}
                            <Header.Subheader>Exp. on <i>{item.expired!=null ? format(parse(item.expired, 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy') : 'none'}</i></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>}
                      {!remark && 
                      <Table.Cell style={{background: '#fff2f2'}}>
                        <Header as='h5'>
                          <Popup size='small' content={item.reason || '-'} inverted position='left center'
                          trigger={
                            <Icon name={(item.dropStamp && 'exclamation circle') || (item.status<0 && 'times circle outline') || (item.status>0 && 'check circle') || 'circle outline'} color={(item.dropStamp && 'orange') || (item.status<0 && 'red') || (item.status>0 && 'blue') || 'teal'} style={{cursor: 'pointer', fontSize: '1.25em'}}/> 
                          } />
                          <Header.Content>
                            {(item.dropStamp && ' Dropped') || targetStatus[item.status] || 'No result'}
                            <Header.Subheader>{(item.reason && (item.reason.length>25 ? item.reason.substr(0,25) +' ...' : item.reason)) || '-'}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>}
                      {/* <Table.Cell>
                        <Header as='h5'>
                          <Icon name={item.isEnabled ? 'toggle on' : 'toggle off'} color={item.isEnabled ? 'teal' : 'grey'} style={{fontSize: '1.5em'}}/>
                        </Header>
                      </Table.Cell> */}
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
            {/* <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='8' style={{fontSize: '1em'}}>
                  <span style={{marginRight: '1em'}}><Icon name='window close outline' color='grey' style={{fontSize: '1.2em'}}/> Undelivered</span>
                  <span style={{marginRight: '1em'}}><Icon name='check square outline' color='blue' style={{fontSize: '1.2em'}}/> Delivered</span>
                  <span style={{marginRight: '1em'}}><Icon name='check square' color='blue' style={{fontSize: '1.2em'}}/> Achieved</span>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer> */}
          </Table>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetBrowse)
