import React, { Component } from 'react'

class EmulatorFooter extends Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }
  
  render() {
    return (
      <div style={{minHeight: 80}}>
        
      </div>
    )
  }
}

export default EmulatorFooter