import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'
import { runningOptions, channelOptions, attemptOptions }  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, DatePickerField, NumberField, SelectField, LabelNumberField, LabelSelectField, TextAreaField } from '../validation/componentrsui'
import { required, phoneNumberID, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { listTemplate, listClient } from "../../actions/halo/masterAction"
import { commMiddleware, resetMiddleware } from "../../actions/middleware/middlewareAction"

//CONNECT REDUX STORE
const selector = formValueSelector('CommServiceForm')
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    middlewareStore: state.middlewareStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listClient:(objParam) => dispatch(listClient(objParam)),
    listTemplate:(objParam) => dispatch(listTemplate(objParam)),

    resetMiddleware:(objParam) => dispatch(resetMiddleware(objParam)),
    commMiddleware:(objParam) => dispatch(commMiddleware(objParam)),
  }
}

class CommServiceForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
    } 
  }
  
  componentDidMount() {
    //console.log('!!! DID MOUNT PROPS: ', this.props)
    this.props.resetMiddleware();
    this.props.listClient();
    this.props.listTemplate();
  }
  
  componentDidUpdate(prevProps) {
    const listTemplate= (this.props.masterStore.listTemplate!=undefined && this.props.masterStore.listTemplate.progress=='1' ? this.props.masterStore.listTemplate.data : [])

    if (prevProps.template!=this.props.template) {
      listTemplate.map(item=>{
        if (item.value==this.props.template) {
          this.props.dispatch(change('CommServiceForm', 'content', item.script))
        }
      })
    }
  }

  resetMiddleware() {
    const { reset, mode } = this.props
    reset();
  }

  onSubmit(values) {
    const { mode } = this.props

    this.props.commMiddleware({...values})
  }


  render() {
    const {  } = this.state
    const { screenWidth, error, handleSubmit, pristine, reset, submitting, mode }=this.props
    const { progress, code, text }= (this.props.middlewareStore.commMiddleware!=undefined ? this.props.middlewareStore.commMiddleware : {})
    const listTemplate= (this.props.masterStore.listTemplate!=undefined && this.props.masterStore.listTemplate.progress=='1' ? this.props.masterStore.listTemplate.data : [])
    const listClient= (this.props.masterStore.listClient && this.props.masterStore.listClient.progress=='1' ? this.props.masterStore.listClient.data : [])

    const filteredListTemplate= [{ key: 0, value: null, text: 'No Template', icon: 'code'}];
    if (this.props.channelType && listTemplate.length>0) {
      listTemplate.map(item=>{
        item.media==this.props.channelType && filteredListTemplate.push(item);
      })
    }

    return (
      <div>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            {/* <Header as='h5'>
              <Icon name='user outline' style={{fontSize: '1em'}}/>
              <Header.Content>Personal</Header.Content>
            </Header> */}
            <Form.Group widths='16'>
              <Field name='environment' component={LabelSelectField}
              width={4}
              validate={[required]}
              options={runningOptions('middleware')}
              label='Mode'
              caption='Environment'
              placeholder='Running Mode' />
              <Field name='communication' component={LabelSelectField}
              width={4}
              validate={[required]}
              options={channelOptions('comm')}
              label='Comm'
              caption='Communication'
              placeholder='Communication' />
              <Field name='clientId' component={SelectField}
              width={8}
              label='Client Realm'
              options= {listClient}
              validate={[required]}
              placeholder= 'Client' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='channelType' component={LabelSelectField}
              width={4}
              options={channelOptions(this.props.communication)}
              validate={[required]}
              label='Media'
              caption='Channel Type'
              placeholder='Channel Type' />

              {(!this.props.communication || this.props.communication=='Telephony' || (this.props.communication=='Messaging'&& this.props.channelType!='Email')) && <>
                <Field name='terminating' component={LabelInputField}
                width={4}
                validate={[required, phoneNumberID]}
                label='Phone'
                caption='Dest. Number'
                placeholder='Terminating (628131000XXXX)' />
                <Field name='originating' component={LabelInputField}
                width={4}
                validate={[phoneNumberID]}
                label='Phone'
                caption='Src. / Out. Number'
                placeholder='Originating (628131000XXXX)' />
              </>}

              {this.props.communication=='Messaging' && this.props.channelType=='Email' && <>
                <Field name='terminating' component={LabelInputField}
                width={8}
                validate={[required, email]}
                label='Email'
                caption='Dest. Email Address'
                placeholder='Terminating' />
              </>}

              {(this.props.communication=='Socmed' && this.props.channelType=='Twitter') && <>
                <Field name='terminating' component={LabelInputField}
                width={8}
                validate={[required]}
                label='Twitter'
                caption='Dest. Twitter Account'
                placeholder='Terminating' />
              </>}

              <Field name='maxAttempt' component={LabelSelectField}
              width={4}
              readOnly
              options={attemptOptions}
              validate={[required]}
              label='Attempt'
              caption='Max. Attempt'
              placeholder='Max. Attempt' />
            </Form.Group>
            
            {this.props.communication=='Telephony' && false &&
              <Form.Group widths='16'>
                <Field name='originatings' component={LabelInputField}
                width={16}
                label='Phones'
                caption='Domain Src. / Out. Numbers'
                placeholder='Originating Domain Numbers Number_1, Number_2, Number_3' />
              </Form.Group>
            }
          </Segment>
          
          {(this.props.channelType=='IVR' || this.props.communication=='Messaging' || this.props.communication=='Socmed') && 
            <Segment>
              <Header as='h5'>
                <Icon name='image outline' style={{fontSize: '1em'}}/>
                <Header.Content>Content / Message</Header.Content>
              </Header>
              <Form.Group widths='16'>
                <Field name='template' component={LabelSelectField}
                width= '16'
                options={filteredListTemplate}
                label= 'Script'
                caption= 'Script / Template Reff.'
                placeholder='Template Script' />
              </Form.Group>
              <Form.Group widths='16'>
                <Field name='content' component={TextAreaField}
                width= '16'
                validate={[required]}
                label= 'Content / Message'
                rows={2}
                placeholder='Content' />
              </Form.Group>

              {/* {this.props.template && 
                <Form.Group widths='16'>
                  <Field name='content' component={TextAreaField}
                  width= '16'
                  validate={[required]}
                  label= 'Content / Message'
                  rows={2}
                  placeholder='Content' />
                  <Form.Field width={8}>
                  </Form.Field>
                </Form.Group>
              } */}
            </Segment>
          }

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage hide='auto' style={{marginBottom: '1em'}} mode={this.props.mode} header={'Comm. Service Request - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button size='small' type='reset' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetMiddleware.bind(this)} />
              <Button size='small' type='submit' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: ({environment: 'Live', communication: 'Telephony', maxAttempt: '1', terminating: '62999333001'}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      template: selector(state, 'template'),
      channelType: selector(state, 'channelType'),
      communication: selector(state, 'communication'),
    }),
    { load: commMiddleware }
  )
  (reduxForm({
    form: 'CommServiceForm',	// a unique identifier for this form
  })(CommServiceForm)
  )
)