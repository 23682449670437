import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'

import { accountOptions}  from '../common/StaticMasterData'

import ButtonGroup from '../common/ButtonGroup'
import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, DatePickerField, MaskedField, SelectField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
import { monthlyOptions, viewOptions } from '../../libraries/common/StaticMasterData';

import { connect } from "react-redux"
import { perfAgent } from "../../actions/halo/performanceAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    performanceStore: state.performanceStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    perfAgent:(objParam) => dispatch(perfAgent(objParam)),
  }
};


class PerformanceAgentSearch extends Component {
  constructor(props) {
    super(props);
    this.state= {
      searchMode: true,
      lastChange: null,
    }
  }

  submitForm(values) {
    const { search }= this.state
    this.props.perfAgent({search: {...values, ...search}})
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeInput(e, v) {
    // console.log(e, v, e.target.name, v.substr(3,7)+v.substr(0,2))
    const { search }= (this.props.performanceStore.perfAgent!==undefined ? this.props.performanceStore.perfAgent : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    if (e.target.name=='period' && !isNaN(v.substr(3,7)) && !isNaN(v.substr(0,2))) {
      setTimeout(()=>{
        if (new Date().getTime()-parent.state.lastChange>=1000) {
          parent.props.perfAgent({search: {...search, period: v.substr(3,7)+v.substr(0,2),month: v}})
        }
      }, 1000)
    } else if (e.target.name=='keyword') {
      setTimeout(()=>{
        if (new Date().getTime()-parent.state.lastChange>=1000) {
          parent.props.perfAgent({search: {...search, keyword: v}})
        }
      }, 1000)
    }
  }

  onChangeDate(key, v) {
    const { search }= (this.props.performanceStore.perfAgent!==undefined ? this.props.performanceStore.perfAgent : {})
    var values= []
    values[key]= v.substr(0,8).replace(/-/g,'');
    values['date']= v;
    this.props.perfAgent({search: {...search, ...values}})
  }
  onChangeSelect(e, v) {
    const { search }= (this.props.performanceStore.perfAgent!==undefined ? this.props.performanceStore.perfAgent : {})

    if (e=='availability') {
      this.props.perfAgent({search: {...search, availability: v}})
    } else if (e=='period') {
      this.props.perfAgent({search: {...search, period: v}})
    } else {
      this.props.perfAgent({search: {...search, view: v}})
    }
  }
  
  render() {
    // console.log(this.props)
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode }=this.props

    const { search }= (this.props.performanceStore.perfAgent!==undefined ? this.props.performanceStore.perfAgent : {})
    
    return (
      <div>
        <div style={{padding: '0', background: 'rgba(230, 230, 230, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
          <div style={{margin: '1em 0', border: '0px solid rgba(100,100,100,.2)'}}>
            <Form onSubmit={handleSubmit(this.submitForm.bind(this))} >
              <Form.Group>
                <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)}
                width={7} 
                placeholder= 'Search agent ... <ENTER>' />
                {/* <Field name='period' component={MaskedField} onChange= {this.onChangeInput.bind(this)}
                width={4} 
                mask="99/2099" 
                // maskChar=" "
                placeholder= 'Monthly Period (mm/yyyy' /> */}
                <Field name='availability' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'availability')}
                options= {accountOptions()}
                width={3} 
                placeholder= 'Availability' />
                {search && search.view!='Daily' && 
                <Field name='period' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'period')}
                width={3} 
                options={monthlyOptions()}
                placeholder= 'Period' />}
                {search && search.view=='Daily' && 
                <Field name='date' component={DatePickerField} onChange= {this.onChangeDate.bind(this, 'period')}
                width={3} 
                options={monthlyOptions()}
                placeholder= 'Period' />}
                <Field name='view' component={SelectField} onChange= {this.onChangeSelect.bind(this)}
                width={3} 
                options={viewOptions}
                placeholder= 'Daily / Monthly' />
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.performanceStore.perfAgent!=undefined && state.performanceStore.perfAgent.progress==1 ? state.performanceStore.perfAgent.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'PerformanceAgentSearch',	// a unique identifier for this form
  })(PerformanceAgentSearch)
  )
)