export default function omniphoneReducer (state = {}, action) {
  switch (action.type) {
    case 'OMNIPHONE_RESET': {
        return({            
            ...state,
            remarkOmniphone: {},
            hangupOmniphone: {},
        })
    }

    case 'OMNIPHONE_ACCOUNT_PENDING': {
        return({...state, accountOmniphone: {progress: 0}})
    }
    case 'OMNIPHONE_ACCOUNT_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, accountOmniphone: {progress: 2, payload: action.payload}})
        } else {
            return({...state, accountOmniphone: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIPHONE_ACCOUNT_REJECTED': {
        return({...state, accountOmniphone: {progress: 2, payload: action.payload}})
    }

    case 'OMNIPHONE_CONTACT_PENDING': {
      return({...state, contactOmniphone: {progress: 0}})
    }
    case 'OMNIPHONE_CONTACT_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, contactOmniphone: {progress: 2, payload: action.payload}})
        } else {
            return({...state, contactOmniphone: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIPHONE_CONTACT_REJECTED': {
        return({...state, contactOmniphone: {progress: 2, payload: action.payload}})
    }

    case 'OMNIPHONE_REMARK_PENDING': {
      return({...state, remarkOmniphone: {progress: 0}})
    }
    case 'OMNIPHONE_REMARK_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, remarkOmniphone: {progress: 2, payload: action.payload}})
        } else {
            return({...state, remarkOmniphone: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIPHONE_REMARK_REJECTED': {
        return({...state, remarkOmniphone: {progress: 2, payload: action.payload}})
    }

    case 'OMNIPHONE_MUTE_PENDING': {
      return({...state, muteOmniphone: {progress: 0}})
    }
    case 'OMNIPHONE_MUTE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, muteOmniphone: {progress: 2, payload: action.payload}})
        } else {
            return({...state, muteOmniphone: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIPHONE_MUTE_REJECTED': {
        return({...state, muteOmniphone: {progress: 2, payload: action.payload}})
    }

    case 'OMNIPHONE_CALL_PENDING': {
      return({...state, callOmniphone: {progress: 0}})
    }
    case 'OMNIPHONE_CALL_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, callOmniphone: {progress: 2, payload: action.payload}})
        } else {
            return({...state, callOmniphone: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIPHONE_CALL_REJECTED': {
        return({...state, callOmniphone: {progress: 2, payload: action.payload}})
    }

    case 'OMNIPHONE_HANGUP_PENDING': {
      return({...state, hangupOmniphone: {progress: 0}})
    }
    case 'OMNIPHONE_HANGUP_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, hangupOmniphone: {progress: 2, payload: action.payload}})
        } else {
            return({...state, hangupOmniphone: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIPHONE_HANGUP_REJECTED': {
        return({...state, hangupOmniphone: {progress: 2, payload: action.payload}})
    }

    case 'OMNIPHONE_KNOWLEDGE_PENDING': {
      return({...state, knowledgeOmniphone: {progress: 0}})
    }
    case 'OMNIPHONE_KNOWLEDGE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, knowledgeOmniphone: {progress: 2, payload: action.payload}})
        } else {
            return({...state, knowledgeOmniphone: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIPHONE_KNOWLEDGE_REJECTED': {
        return({...state, knowledgeOmniphone: {progress: 2, payload: action.payload}})
    }

    case 'OMNIPHONE_PREDICTIVE_PENDING': {
        return({...state, predictiveOmniphone: {progress: 0}})
    }
    case 'OMNIPHONE_PREDICTIVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, predictiveOmniphone: {progress: 2, payload: action.payload}})
        } else {
            return({...state, predictiveOmniphone: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIPHONE_PREDICTIVE_REJECTED': {
        return({...state, predictiveOmniphone: {progress: 2, payload: action.payload}})
    }

    case 'OMNIPHONE_CHAT_PENDING': {
    return({...state, chatOmniphone: {progress: 0}})
    }
    case 'OMNIPHONE_CHAT_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, chatOmniphone: {progress: 2, payload: action.payload}})
        } else {
            return({...state, chatOmniphone: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIPHONE_CHAT_REJECTED': {
        return({...state, chatOmniphone: {progress: 2, payload: action.payload}})
    }
    
    case 'OMNIPHONE_MESG_PENDING': {
    return({...state, mesgOmniphone: {progress: 0}})
    }
    case 'OMNIPHONE_MESG_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, mesgOmniphone: {progress: 2, payload: action.payload}})
        } else {
            return({...state, mesgOmniphone: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIPHONE_MESG_REJECTED': {
        return({...state, mesgOmniphone: {progress: 2, payload: action.payload}})
    }

    case 'OMNIPHONE_SPY_PENDING': {
    return({...state, spyAgent: {progress: 0}})
    }
    case 'OMNIPHONE_SPY_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, spyAgent: {progress: 2, payload: action.payload}})
        } else {
            return({...state, spyAgent: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIPHONE_SPY_REJECTED': {
        return({...state, spyAgent: {progress: 2, payload: action.payload}})
    }

    case 'OMNIPHONE_WHISPER_PENDING': {
    return({...state, whisperAgent: {progress: 0}})
    }
    case 'OMNIPHONE_WHISPER_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, whisperAgent: {progress: 2, payload: action.payload}})
        } else {
            return({...state, whisperAgent: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIPHONE_WHISPER_REJECTED': {
        return({...state, whisperAgent: {progress: 2, payload: action.payload}})
    }
    
    case 'OMNIPHONE_DTMF_PENDING': {
    return({...state, dtmfOmniphone: {progress: 0}})
    }
    case 'OMNIPHONE_DTMF_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, dtmfOmniphone: {progress: 2, payload: action.payload}})
        } else {
            return({...state, dtmfOmniphone: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIPHONE_DTMF_REJECTED': {
        return({...state, dtmfOmniphone: {progress: 2, payload: action.payload}})
    }
      
    default: {
      return state
    }
  }
}