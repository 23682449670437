import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
} from 'semantic-ui-react'

import { format, parse } from "date-fns"


import {withRouter} from "react-router-dom"

import { connect } from "react-redux"
import { browseClient, statusClient, realmClient } from "../../actions/halo/clientAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    clientStore: state.clientStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    statusClient:(objParam) => dispatch(statusClient(objParam)),
    browseClient:(objParam) => dispatch(browseClient(objParam)),

    realmClient:(objParam) => dispatch(realmClient(objParam)),
  }
};


class ClientBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.clientStore.browseClient!==undefined ? this.props.clientStore.browseClient : {})
    this.props.browseClient(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.clientStore.browseClient!==undefined ? this.props.clientStore.browseClient : {})
    
    this.props.browseClient({search: {...search, groupId: 'Client'}, paging: paging})
  }

  componentDidUpdate() {
    const tokenAuth= localStorage.getItem('tokenAuth');
    const { code, text, progress, data }= this.props.clientStore.realmClient ? this.props.clientStore.realmClient : {}
    
    if (progress!=undefined && progress==1 && data.groupId=='Admin' && tokenAuth!='') {
        setTimeout(()=>{this.props.history.push('/admin')}, 1000)
    } else if (progress!=undefined && progress==1 && data.groupId=='Agent' && tokenAuth!='') {
      setTimeout(()=>{this.props.history.push('/agent')}, 1000)
    } else if (progress!=undefined && progress==1 && data.groupId=='Client' && tokenAuth!='') {
      setTimeout(()=>{this.props.history.push('/realm')}, 1000)
    } else if (progress!=undefined && progress==1 && (data.groupId=='Supervisor' || data.groupId=='Team Leader') && tokenAuth!='') {
      setTimeout(()=>{this.props.history.push('/spv')}, 1000)
    } else if (progress!=undefined && progress==1 && data.groupId=='Emulator' && tokenAuth!='') {
      setTimeout(()=>{this.props.history.push('/emulator')}, 1000)
    } else if (progress!=undefined && progress==1 && data.groupId=='Whatsapp' && tokenAuth!='') {
      setTimeout(()=>{this.props.history.push('/wasap')}, 1000)
    }
  }

  onChangeStatus(id, status) {
    this.props.statusClient({clientId:id, isEnabled: status})
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.clientStore.browseClient!==undefined ? this.props.clientStore.browseClient : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight-125}}>
        <div style={{flex: '1'}}>
        <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                {this.props.setMode!=undefined &&
                <Table.HeaderCell></Table.HeaderCell>
                }
                <Table.HeaderCell width='4'>
                  <Header as='h5' color='blue'>CLIENT</Header>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Header as='h5' color='blue'></Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='5'>
                  <Header as='h5' color='blue'>CONTRACT</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='7'>
                  <Header as='h5' color='blue'>SERVICE</Header>
                </Table.HeaderCell>
                <Table.HeaderCell>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={5} style={{padding: '1em 2em'}}><Label color='yellow' style={{fontSize: '1.25em', padding: '.5em 1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!=undefined ? data.map((item,key)=>{
                  const dialers= [];
                  item.svcPredialed==1 && dialers.push('Predialed')
                  item.svcProgressive==1 && dialers.push('Progressive')
                  item.svcPredictive==1 && dialers.push('Predictive')
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      {this.props.setMode!=undefined &&
                        <Table.Cell textAlign='center'>
                          <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.clientId)}/>
                        </Table.Cell>
                      }
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            <b>{item.company || <i>Company has not been set!</i>}</b>
                            <Header.Subheader>{item.city || '-'}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        {/* <Form.Checkbox name='contactStatus_1' 
                        toggle
                        defaultChecked={item.isEnabled=='1' ? true : false}
                        onChange={this.onChangeStatus.bind(this, item.clientId,(item.isEnabled=='1' ? '0' : '1'))}
                        placeholder='Contact status' /> */}
                        <Icon name={item.isEnabled ? 'toggle on' : 'toggle off'} color={item.isEnabled ? 'teal' : 'grey'} style={{fontSize: '1.5em'}}/>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.packageId || <b><i>Package has not been set !</i></b>}
                            <Header.Subheader>{item.contractDate!=null && format(parse(item.contractDate, 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy')} {item.contractExpired!=null && '-'} {item.contractExpired!=null && format(parse(item.contractExpired, 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy')}, 
                            {item.contractDuration && (item.contractDuration + 'Month[s]')}  {(item.contractRecurring && 'Auto Recurring') || 'Single period'}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {(dialers && dialers.join(' + ')) || <b><i>Service has not been set !</i></b>}
                            <Header.Subheader>{(item.svcChannels && item.svcChannels.join(' + ')) || '-'}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      {/* <Table.Cell>
                        {item.mobile!=null ? item.mobile : ''} / {item.email!=null ? item.email : ''}
                      </Table.Cell> */}
                      <Table.Cell>
                        <Button size='big' icon onClick={this.props.realmClient.bind(this, {clientId: item.clientId})}><Icon name='rocket' color='blue' style={{transform: 'rotate(-45deg)'}} /></Button>
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        
        {paging!=undefined && paging.count>0 &&
            <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
              <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
               
                defaultActivePage={paging.page}
                totalPages={Math.ceil(paging.count/paging.size)}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          }
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientBrowse))
