import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Grid, Progress, Statistic, Label } from 'semantic-ui-react'

import numeral from "numeral"

import { connect } from "react-redux"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus, socketSystemHeartbeat, socketSystemOccupancy, socketSystemMonitoring } from "../../../actions/socket/socketAction"
import WebSocketProvider, { WebSocketContext } from '../../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketSystemMonitoring:(objParam) => dispatch(socketSystemMonitoring(objParam)),
  }
}

class CampaignWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      isUsage: false,
      isDeposit: false,
    };
    this.billings= {};
  }

  expandContent(v) {
    if (v=='usage') {
      this.setState({
        isUsage: !this.state.isUsage
      })
    } else {
      this.setState({
        isDeposit: !this.state.isDeposit
      })
    }
  }

  openModal(v) {
    if (this.props.openModal) {
      this.props.openModal(v)
    }
  }


  
  render() {
    const { isUsage, isDeposit }= this.state
    const { screenWidth,  screenHeight}= this.props
    const { agents, omnis, providers, billings }= this.props.socketStore.socketSystemMonitoring!=undefined && this.props.socketStore.socketSystemMonitoring.data && this.props.socketStore.socketSystemMonitoring.data.payload && this.props.socketStore.socketSystemMonitoring.data.payload.data ? this.props.socketStore.socketSystemMonitoring.data.payload.data: {};
    
    if (billings) {
      this.billings= billings;
    } 
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <div style={{display: 'flex'}}>
          <div style={{flex: '1'}}>
            <Header as='h5' style={{margin: '0'}}>
              <Icon name='fire' style={{fontSize: '1em'}} />
              <Header.Content>
                Campaign Summary
                <Header.Subheader></Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div>
            <Popup  content='Create new campaign through step by step wizard.' position='left center'
              trigger={
                <Icon name='plus' onClick={this.props.openModal.bind(this, 'wizard')}  style={{fontSize: '1.2em', cursor: 'pointer'}} />
              }
              inverted
              on={['hover']}
            />
          </div>
        </div>
        
      </Segment>
      <Segment attached='bottom'>
        <div style={{display: 'flex'}}>
          <div style={{textAlign: 'center', paddingRight: '.1em'}}>
            {/* <Header as='h4' style={{margin: '0'}} color='blue'>
              <Header.Content style={{fontWeight: 'bold', fontSize: '1.5em'}}>
                0
                <Header.Subheader>Campaigns</Header.Subheader>
              </Header.Content>
            </Header> */}
            <Label as='a' color='green' size='large'>
              <Icon name='calendar alternate outline' />
              <Label.Detail>20</Label.Detail>
            </Label>
          </div>
          <div style={{flex: '1'}}>
            <div style={{padding: '.16em .2em.16em .2em', background: '#6ac15b', borderRadius: '.2em'}}>
              <Progress  size='medium' indicating color='olive' />
            </div>
          </div>
        </div>
      </Segment>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignWidget)