import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function listCampaign(objParam) {
  return {
      type: 'CAMPAIGN_LIST',
      payload:
        axios.post(
          urlAPI + 'master/campaign',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listClient(objParam) {
  return {
      type: 'CLIENT_LIST',
      payload:
        axios.post(
          urlAPI + 'master/client',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listAgent(objParam) {
  return {
      type: 'AGENT_LIST',
      payload:
        axios.post(
          urlAPI + 'master/agent',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listTeamleader(objParam) {
  return {
      type: 'TEAMLEADER_LIST',
      payload:
        axios.post(
          urlAPI + 'master/agent',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listSupervisor(objParam) {
  return {
      type: 'SUPERVISOR_LIST',
      payload:
        axios.post(
          urlAPI + 'master/agent',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listController(objParam) {
  return {
      type: 'CONTROLLER_LIST',
      payload:
        axios.post(
          urlAPI + 'master/agent',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listManager(objParam) {
  return {
      type: 'MANAGER_LIST',
      payload:
        axios.post(
          urlAPI + 'master/agent',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listTemplate(objParam) {
  return {
      type: 'TEMPLATE_LIST',
      payload:
        axios.post(
          urlAPI + 'master/template',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listProduct(objParam) {
  return {
      type: 'PRODUCT_LIST',
      payload:
        axios.post(
          urlAPI + 'master/product',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listWorkgroup(objParam) {
  return {
      type: 'WORKGROUP_LIST',
      payload:
        axios.post(
          urlAPI + 'master/workgroup',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listSegment(objParam) {
  return {
      type: 'SEGMENT_LIST',
      payload:
        axios.post(
          urlAPI + 'master/segment',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function collectionStage(objParam) {
  return {
      type: 'COLLECTIONSTAGE_LIST',
      payload:
        axios.post(
          urlAPI + 'master/collection',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function restructuringStage(objParam) {
  return {
      type: 'RESTRUCTURINGSTAGE_LIST',
      payload:
        axios.post(
          urlAPI + 'master/restructuring',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function telesaleStage(objParam) {
  return {
      type: 'TELESALESTAGE_LIST',
      payload:
        axios.post(
          urlAPI + 'master/telesale',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function verificationStage(objParam) {
  return {
      type: 'VERIFICATIONSTAGE_LIST',
      payload:
        axios.post(
          urlAPI + 'master/verification',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listRemark(objParam) {
  return {
      type: 'REMARK_LIST',
      payload:
        axios.post(
          urlAPI + 'master/remark',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function summaryCampaign(objParam) {
  return {
      type: 'CAMPAIGN_SUMMARY',
      payload:
        axios.post(
          urlAPI + 'master/summary',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listParams(objParam) {
  return {
      type: 'PARAMS_LIST',
      payload:
        axios.post(
          urlAPI + 'master/params',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listClosing(objParam) {
  return {
      type: 'CLOSING_LIST',
      payload:
        axios.post(
          urlAPI + 'master/closing',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listHandlings(objParam) {
  return {
      type: 'HANDLINGS_LIST',
      payload:
        axios.post(
          urlAPI + 'master/handlings',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listSegments(objParam) {
  return {
      type: 'SEGMENTS_LIST',
      payload:
        axios.post(
          urlAPI + 'master/segments',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listCustomer(objParam) {
  return {
      type: 'CUSTOMER_LIST',
      payload:
        axios.post(
          urlAPI + 'master/customer',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}