import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import LinkBrowse from '../../libraries/talk/LinkBrowse'
import LinkSearch from '../../libraries/talk/LinkSearch'
import LinkForm from '../../libraries/talk/LinkForm'


class LinkBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      linkId: 0,
    };
  }

  setMode(mode, id, params) {
    this.setState({mode: mode, linkId: id, params: params})
  }

  closeModal() {
    this.setState({
      mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      linkId: 0,
      params: null,
    });
  }

  render() {
    const {mode, linkId}= this.state
    const {screenWidth, screenHeight, params}= this.props
    
    return(
      <Container fluid>
          <LinkSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
          <div>
            {mode==1 && <LinkBrowse setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} closeModal={this.props.closeModal.bind(this)} />}
            {mode==2 && <LinkForm mode={mode} linkId={linkId} setMode={this.setMode.bind(this)} params={params} />}
            {mode==3 && <LinkForm mode={mode} linkId={0} setMode={this.setMode.bind(this)} params={params} />}
          </div>
      </Container>
    )
  }
}

export default LinkBody
