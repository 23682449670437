import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider } from 'semantic-ui-react'

import StatisticWidget from '../../libraries/widget/StatisticWidget'
import CustomerWidget from '../../libraries/widget/CustomerWidget';
import SubscriptionWidget from '../../libraries/widget/SubscriptionWidget';
import ProgressWidget from '../../libraries/widget/ProgressWidget';
import RecentWidget from '../../libraries/widget/RecentWidget';
import NotificationWidget from '../../libraries/widget/NotificationWidget'
import AppointmentWidget from '../../libraries/widget/AppointmentWidget'

class StatisticBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      userId: 0,
    };
  }

  setMode(mode, id) {
    this.setState({mode: mode, userId: id})
  }

  render() {
    const {mode, userId}= this.state
    const {screenWidth, screenHeight}= this.props
    
    return(
      <Container fluid>
        <div style={{display: 'flex'}}>
          <div style={{flex: '1', padding: '.8em 0em 1em 0em'}}>
            <div style={{padding: '1em'}}>
              <ProgressWidget screenWidth={screenWidth} screenHeight={screenHeight}/>
            </div>
          
            <div style={{padding: '1em'}}>
              <CustomerWidget screenWidth={screenWidth} screenHeight={screenHeight}/>
            </div>

            <div style={{padding: '1em'}}>
              <SubscriptionWidget />
            </div>
            <div style={{padding: '1em'}}>
              <RecentWidget openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} screenWidth={screenWidth} />
            </div>
          </div>
          <div style={{width: '300px', minHeight: screenHeight, padding: '.8em 0em 1em 0em'}}>
            <div style={{padding: '1em'}}>
              {/* <ConversationWidget />
              <ProductWidget /> */}
              <NotificationWidget />
              {/* <Divider hidden />
              <AppointmentWidget /> */}
            </div>
            <div style={{padding: '1em', background: '#eee'}}>
              <StatisticWidget />
            </div>
            <div style={{padding: '1em'}}>
              {/* <ConversationWidget />
              <ProductWidget /> */}
              <AppointmentWidget />
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

export default StatisticBody
