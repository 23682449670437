import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Pagination,
  Label,
  Table,
  Popup,
} from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import numeral from "numeral"
import { format, parse } from "date-fns"

import { connect } from "react-redux"
import { callOmniphone } from "../../actions/stream/omniphoneAction"
import { browseTransact } from "../../actions/stream/transactAction"
import { socketAgentStatus } from "../../actions/socket/socketAction"

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,

    omniphoneStore: state.omniphoneStore,
    transactStore: state.transactStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),

    callOmniphone:(objParam) => dispatch(callOmniphone(objParam)),
    browseTransact:(objParam) => dispatch(browseTransact(objParam)),
  }
}

class TransactBrowse extends Component {
  constructor(props) {
    super(props)
    this.state= {
      calling: 0,
      color: 'teal',
      targetId: null,
    }
  }
  
  componentDidMount() {
    this.props.browseTransact({search: {transactDate: format(new Date(), 'yyyy-MM-dd 00:00:00')}})
  }

  componentDidUpdate() {
    const { code, data }= (this.props.omniphoneStore.callOmniphone!==undefined ? this.props.omniphoneStore.callOmniphone : {})
    if (data && data.targetId!=this.state.targetId && this.state.targetId!=null) {
      this.setState({
        targetId: data.targetId,
        calling: (code=='01' && 5) || this.state.calling
      })
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.transactStore.browseTransact!==undefined ? this.props.transactStore.browseTransact : {})
    this.props.browseTransact(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onChangeStatus(id) {
    this.props.statusTransact({userId:id})
  }

  toggle(status,id) {
    this.props.statusTransact({userId:id, activeStatus: status})
  }

  callOmniphone(targetId, campaignId) {
    this.setState({
      calling:5,
      color: 'teal',
      targetId: 0,
    })
    this.props.callOmniphone({targetId: targetId, campaignId: campaignId})
    this.callingCounter()
  }

  callingCounter() {
    const parent= this;
    const timer= setInterval(()=>{
      parent.setState({
        calling: parent.state.calling - 1,
        color: parent.state.calling%2==0 ? 'orange' : 'teal',
      })
      if (parent.state.calling<1) {
        clearInterval(timer);
      }
    }, 1000)
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { calling, color }= this.state
    const { data, search, paging }= (this.props.transactStore.browseTransact!==undefined ? this.props.transactStore.browseTransact : {})
    const callResult= (this.props.omniphoneStore.callOmniphone!==undefined ? this.props.omniphoneStore.callOmniphone : null)
    const agentStatus= (this.props.socketStore.socketAgentStatus!==undefined ? this.props.socketStore.socketAgentStatus.data : undefined)
    const { dialings }= (agentStatus && agentStatus.payload.data) || []

    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    // console.log(callResult, this.state)
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight-125}}>
        <ModalContainer size='small' loader={
          <div style={{textAlign: 'center'}}>
            <Header as='h5' color='yellow' inverted style={{display: 'inline-block', textAlign: 'left', padding: '1em 1em', background: '#000', border: '1px dashed #888'}}>
              <Icon name='circle notch' loading style={{fontSize: '2em'}} color={color}/>
              <Header.Content>
                Dialing ... {calling}
                <Header.Subheader>{callResult && callResult.code=='00' && 'OK'} {callResult && callResult.code!='00' && 'NOK'} {(callResult && callResult.data && this.state.targetId==callResult.data.targetId && callResult.text) || 'Dialing targets number.'}</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          } open={calling>0} />

        <div style={{flex: '1'}}>
          <Card.Group itemsPerRow={1}>
            { 
              data && data.map((item,key)=>{
                return(
                  <Card fluid>
                    <Card.Content style={{padding: '1em .7em'}}>
                      <div style={{display: 'flex'}}>
                        <div>
                          <Label color='teal' style={{textAlign:'center', padding: '.7em .5em', margin: '0 .5em 0 0', width: '2.5em'}}>{++seq}.</Label>
                        </div>
                        <div style={{flex: '1'}}>
                          <Header as='h4' style={{margin: '0'}} color='blue'>
                            <Header.Content>
                              {(item.target!=null && item.target.name) || 'No target identified'}
                              {item.campaign!=null && <Header.Subheader>{item.campaign.name} ({item.campaign.isEnabled=='1'? <i>Enabled</i> : <i>Disabled</i>})</Header.Subheader>}
                            </Header.Content>
                          </Header>
                          <Divider />
                          {/* <Header as='h5' style={{margin: '.5em 0'}}>
                            <Header.Content>
                              {item.target!=null && (item.target.mobile || item.target.homePhone || item.target.officePhone) || 'No phone'} 
                              <Header.Subheader>
                                <span style={{marginRight: '1em'}}>{item.target!=null && (item.target.whatsapp||item.target.telegram||item.target.email||item.target.mobile) ? <><Icon name='check' />Messaging</> : <><Icon name='close' />Messaging</>}</span>
                                <span style={{marginRight: '0em'}}>{item.target!=null && (item.target.twitter||item.target.instagram||item.target.facebook) ? <><Icon name='check' />Socmed</> : <><Icon name='close' />Socmed</>}</span>
                              </Header.Subheader>
                            </Header.Content>
                          </Header> */}
                          <Header as='h5' style={{margin: '0 0 .5em 0'}}>
                            <div style={{background: (item.status=='1' && '#84dbff') || (item.status=='0' && '#ffb3b3') || '#ddd', textAlign: 'right', display: 'inline-block', width: '4em', padding: '.5em', marginRight: '.5em'}}>{item.progress} %</div>
                            <Header.Content>
                              {item.remark || 'No remark !'}
                              <Header.Subheader>valid until <i>{item.expired!=null ? format(parse(item.expired, 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy') : 'none'}</i></Header.Subheader>
                            </Header.Content>
                          </Header>
                          <Header as='h5' style={{margin: '0'}} color='red'>
                            <Header.Content>
                              {item.reason || 'No information defined'}
                            </Header.Content>
                          </Header>
                        </div>
                      </div>
                    </Card.Content>
                  </Card>
                )
              })
            }
          </Card.Group>
        </div>

        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination size='mini' onPageChange={this.doPaging.bind(this)}
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
        </div>}
      </div>
    )
  }
}

TransactBrowse.contextType= WebSocketContext;

export default connect(mapStateToProps, mapDispatchToProps)(TransactBrowse)
