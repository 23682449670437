import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Label, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react'

import { channelOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'

import { SubmissionError, Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form'
import { ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField, DatePickerField, TextAreaField, NumberField, ToggleField, WysiwygField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
import { connect } from "react-redux"
import { resetBlogging, getBlogging,updateBlogging, createBlogging } from "../../actions/halo/bloggingAction"
import { listTemplate } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('BloggingForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    bloggingStore: state.bloggingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetBlogging:(objParam) => dispatch(resetBlogging(objParam)),
    getBlogging:(objParam) => dispatch(getBlogging(objParam)),
    createBlogging:(objParam) => dispatch(createBlogging(objParam)),
    updateBlogging:(objParam) => dispatch(updateBlogging(objParam)),

    listTemplate:(objParam) => dispatch(listTemplate(objParam)),
  }
}

class BloggingForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    this.props.resetBlogging(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getBlogging({bloggingId: this.props.bloggingId})
    }
  }

  componentDidUpdate() {
    const { mode, modal } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.bloggingStore.updateBlogging!=undefined ? this.props.bloggingStore.updateBlogging : {}) : (this.props.bloggingStore.createBlogging!=undefined ? this.props.bloggingStore.createBlogging : {}))
    if (!modal && progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
    if (modal && progress==1 && code=='00') {
      this.props.closeModal('hide');
      this.props.listTemplate({search: {clientId: this.props.clientId || null}})
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    mode==2 ? this.props.updateBlogging({...values}) : this.props.createBlogging({...values, ...{clientId: this.props.clientId}})
  }
  
  insertVariable(v) {
    // console.log(v)
    this.props.dispatch(change('BloggingForm', 'blogging', (this.props.blogging||'') +' '+ v +' '));
    // e.target.focus()
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, modal } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.bloggingStore.updateBlogging!=undefined ? this.props.bloggingStore.updateBlogging : {}) : (this.props.bloggingStore.createBlogging!=undefined ? this.props.bloggingStore.createBlogging : {}))

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='equal'>
              <Field name='pinned' component={ToggleField}
              label='Pinned'
              placeholder='Pinned' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='author' component={InputField}
              width={8}
              label='Author'
              placeholder='Author' />
              <Field name='writeStamp' component={DatePickerField}
              width={4}
              showTimeSelect={true}
              dateFormat={'dd / MMMM, yyyy h:mm aa'}
              label='Composed At'
              placeholder='Composed At' />
              <Field name='publishStamp' component={DatePickerField}
              width={4}
              showTimeSelect={true}
              dateFormat={'dd / MMMM, yyyy h:mm aa'}
              label='Published At'
              placeholder='Published At' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='category' component={SelectField}
              width={8}
              options={channelOptions()}
              label='Category'
              placeholder='Category' />
              <Field name='totLike' component={NumberField}
              width={4}
              label='Tot. Like'
              placeholder='Tot. Like' />
              <Field name='totDislike' component={NumberField}
              width={4}
              label='Tot. Dislike'
              placeholder='Tot. Dislike' />
            </Form.Group>
          </Segment>
          <Segment>
            <Form.Group widths='16'>
              <Field name='title' component={InputField}
              width={16}
              validate={[required]}
              label='Title'
              placeholder='Title' />
            </Form.Group>

            <Header color='blue' as='h5'>
              <Icon name='envelope open outline' style={{fontSize: '1em'}}/>
              <Header.Content>Metadata</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='keywords' component={InputField}
              fluid
              label='Keywords'
              placeholder='Keywords' />
              <Field name='descript' component={InputField}
              fluid
              label='Description'
              placeholder='Description' />
            </Form.Group>
            <Form.Group widths='16'>
              {(this.props.title || this.props.mode==3) &&
              <Field name='content'  component={WysiwygField}
              mode='wysiwyg'
              width='16'
              rows='15'
              validate={[required]}
              label='Content' />}
            </Form.Group>
            <Form.Group widths='16' style={{marginTop: '2em'}}>
              <Form.Field width={16}>
                <Field name='image' component={ImageUploadField} style={{marginTop: '1.5em'}}
                width={640} 
                height={180} 
                aspectRatio= '1:1' />
              </Form.Field>
            </Form.Group>
            {/* <Form.Group widths='16'>
              <Field name='video' component={TextAreaField}
              width='16'
              label='Video Attachment' />
            </Form.Group> */}
            <Divider hidden />
          </Segment>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Blogging' : 'Insert Blogging') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.bloggingStore.getBlogging!=undefined && state.bloggingStore.getBlogging.progress==1 ? state.bloggingStore.getBlogging.data : {isEnabled: 1}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
      media: selector(state, 'media'),
      title: selector(state, 'title'),
      blogging: selector(state, 'blogging'),
    }),
  )
  (reduxForm({
    form: 'BloggingForm',	// a unique identifier for this form
  })(BloggingForm)
  )
)